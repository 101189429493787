
import{
    /***************************************************************************/
    CUSTSURV_SET_DIALOG,
    CUSTSURV_SET_SHOWCARGANDO,
    CUSTSURV_GET_ENCUESTADATA,
    CUSTSURV_GET_ENCUESTADATA_SUCCESS,
    CUSTSURV_GET_ENCUESTADATA_FAIL,
    CUSTSURV_SET_DIALOGCONFIRMAR,
    CUSTSURV_POST_ENCUESTA,
} from './Constants';
/***************************************************************************/
export const CustSurv_Set_Dialog= (payload) => { return { type: CUSTSURV_SET_DIALOG, payload: payload }; };
export const CustSurv_Set_ShowCargando= (payload) => { return { type: CUSTSURV_SET_SHOWCARGANDO, payload: payload }; };
/***************************************************************************/
export const CustSurv_Get_EncuestaData = (payload) => {
    return {
        type: CUSTSURV_GET_ENCUESTADATA,
        payload: payload
    };
};
export const CustSurv_Get_EncuestaData_Success = (payload) => {
    return {
        type: CUSTSURV_GET_ENCUESTADATA_SUCCESS,
        payload: payload
    };
};
export const CustSurv_Get_EncuestaData_Fail = (payload) => {
    return {
        type: CUSTSURV_GET_ENCUESTADATA_FAIL,
        payload: payload
    };
};
export const CustSurv_Set_DialogConfirmar= (payload) => { 
    return { 
        type: CUSTSURV_SET_DIALOGCONFIRMAR, 
        payload: payload 
    }; 
};
export const CustSurv_Post_Encuesta= (payload) => { 
    return { 
        type: CUSTSURV_POST_ENCUESTA, 
        payload: payload 
    }; 
};