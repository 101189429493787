import React, { useEffect, useState, Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lodash from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import {
  globalChangeStatesCotizadorCliente,
  getComunasCotizadorCliente,
  showMessageCotizadorCliente,
  validateRutCotizador
} from '../../../modules/cotizador/Actions';

import {
  Button,
  InputLabel,
  TextField,
  FormControlLabel,
  makeStyles,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControl,
  Tooltip
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import { validate, clean, format, getCheckDigit } from 'rut.js'

import {
  payloadCotizacionUsuarioEmpresa,
  payloadCotizacionUsuarioPersona,
  validationErrorsCotizacion
} from '../../../models';


// Tooltip MUI //
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#263238',
    color: '#fff',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
// Tooltip MUI //

const useStyles = makeStyles((theme) => ({
  ButtonNav: {
    padding: '0',
    height: '13px',
    width: '2%',
    color: "#8F8F8F",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#44403f",
    }
  },
  icon: {
    fontSize: "16px",
    marginLeft: "-30px"
  },
  label: {
    color: "#8F8F8F"
  },
  inputAdornment: {
    "& .MuiTypography-body1": {
      fontSize: "12px"
    }
  },
}));

let rgxValidarEmail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
let rgxMayusculas = /([A-Z])/;
let rgxMinusculas = /([a-z])/;
let rgxCaracteresEspeciales = /[\@\#\$\%\^\&\*\(\)\_\+\!\'\"]/;
let rgxNumeroCaracteres = /.{8,}/;
let rgxNumero = /([0-9])/;
const CardTwo = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    regiones,
    comunas,
    validations,
    rutExists
  } = useSelector(({ CotizadorCliente }) => CotizadorCliente);
  const [controls, setControls] = useState(props.payload);
  const [usuarioEmpresa, setUsuarioEmpresa] = useState(payloadCotizacionUsuarioEmpresa);
  const [usuarioPersona, setUsuarioPersona] = useState(payloadCotizacionUsuarioPersona);
  const [errorEmpresaRut,setErrorEmpresaRut] = useState(false);
  const [errorTextEmpresaRut,setErrorTextEmpresaRut] = useState(false);
  const [errorPersonaRut,setErrorPersonaRut] = useState(false);
  const [errorTextPersonaRut,setErrorTextPersonaRut] = useState(false);
  useEffect(() => {
    if (rutExists.status === true) {
      if (rutExists.origin === "empresa") {
        let newValidate = lodash.cloneDeep(validations);
        setErrorEmpresaRut(true);
        setErrorTextEmpresaRut('Este Rut ya se encuentra registrado.');
        controls.empresa.rut = '';
        setControls(controls);
        //dispatch(globalChangeStatesCotizadorCliente('payload', controls))
      } else if (rutExists.origin === "persona") {
        setErrorPersonaRut(true);
        setErrorTextPersonaRut('Este Rut ya se encuentra registrado.');
        controls.persona.rut = '';
        setControls(controls);
        //dispatch(globalChangeStatesCotizadorCliente('payload', controls))
       // dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
        //dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
        /*setErrorsPersona({
          ...errorsPersona,
          rut: "Este Rut ya se encuentra registrado.",
        });*/
      }
    }
  }, [rutExists]);

  const onChange = (event) => {
    const obj = lodash.cloneDeep(controls);
    const newPayload = {
      ...obj,
      usuario: {
        ...obj.usuario,
        [event.target.id ? event.target.id : event.target.name]: event.target.value
      }
    }
    setControls(newPayload);
  }

  const onChangeType = (event, type) => {
    if (type === 'empresa') {
      const obj = lodash.cloneDeep(usuarioEmpresa);
      const newPayload = {
        ...obj,
        [event.target.id ? event.target.id : event.target.name]: event.target.value
      }
      setUsuarioEmpresa(newPayload);
    } else if (type === 'persona') {
      const obj = lodash.cloneDeep(usuarioPersona);
      const newPayload = {
        ...obj,
        [event.target.id ? event.target.id : event.target.name]: event.target.value
      }
      setUsuarioPersona(newPayload);
    }
  }

  const onChangeRpl = (event) => {
    const obj = lodash.cloneDeep(usuarioEmpresa);
    const newPayload = {
      ...obj,
      representante_legal: {
        ...obj.representante_legal,
        [event.target.id ? event.target.id : event.target.name]: event.target.value
      }
    }
    setUsuarioEmpresa(newPayload);
  }

  const onBlur = (event) => {
    if (value == '1') {
      controls.empresa = usuarioEmpresa;
      controls.persona = null;
      if (event.target.id === 'rut') {
        if (validate(format(event.target.value.toUpperCase().trim())) == true) {
          var rut = format(event.target.value.toUpperCase().trim());
          controls.empresa.rut = rut;
          setControls(controls);
          dispatch(validateRutCotizador({ rut: rut, origin: "empresa" }));
          dispatch(globalChangeStatesCotizadorCliente('payload', controls))
        }
        else if (event.target.value.toUpperCase().trim().length > 0) {
          var rut = '';
          //dispatch(showMessageCotizadorCliente({ type: 'error', text: 'Rut inválido' }));
          setErrorEmpresaRut(true);
          setErrorTextEmpresaRut('Rut inválido');
          controls.empresa.rut = rut;
          setControls(controls);
          dispatch(globalChangeStatesCotizadorCliente('payload', controls))
        }
      } else if (event.target.id === 'email') {
        if (!rgxValidarEmail.test(event.target.value)) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.empresa.email.error = true;
          newValidate.empresa.email.helperText = 'El formato del email es incorrecto';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
          controls.empresa.email = '';
          setControls(controls);
          dispatch(globalChangeStatesCotizadorCliente('payload', controls));
        } else {
          dispatch(globalChangeStatesCotizadorCliente('validations', validationErrorsCotizacion));
          dispatch(globalChangeStatesCotizadorCliente('payload', controls))
        }
      } if (event.target.name === 'email_usuario') {
        if (!rgxValidarEmail.test(event.target.value)) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.usuario.email.error = true;
          newValidate.usuario.email.helperText = 'El formato del email es incorrecto';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
          controls.usuario.email = '';
          setControls(controls);
          dispatch(globalChangeStatesCotizadorCliente('payload', controls));
        } else {
          dispatch(globalChangeStatesCotizadorCliente('validations', validationErrorsCotizacion));
          dispatch(globalChangeStatesCotizadorCliente('payload', controls))
        }
      } else if (event.target.name === 'email_rep_legal') {
        if (!rgxValidarEmail.test(event.target.value)) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.empresa.representante_legal.email.error = true;
          newValidate.empresa.representante_legal.email.helperText = 'El formato del email es incorrecto';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
          controls.empresa.representante_legal.email = '';
          setControls(controls);
          dispatch(globalChangeStatesCotizadorCliente('payload', controls));
        } else {
          dispatch(globalChangeStatesCotizadorCliente('validations', validationErrorsCotizacion));
          dispatch(globalChangeStatesCotizadorCliente('payload', controls))
        }
      } else if (event.target.name === 'telefono_rep_legal') {
        if (!rgxNumero.test(event.target.value)) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.empresa.representante_legal.telefono.error = true;
          newValidate.empresa.representante_legal.telefono.helperText = 'El teléfono solo debe contener números';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
          controls.empresa.representante_legal.telefono = '';
          setControls(controls);
          dispatch(globalChangeStatesCotizadorCliente('payload', controls));

        } else if (event.target.value.toString().length != 8) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.empresa.representante_legal.telefono.error = true;
          newValidate.empresa.representante_legal.telefono.helperText = 'No es un teléfono válido';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
          controls.empresa.representante_legal.telefono = '';
          setControls(controls);
          dispatch(globalChangeStatesCotizadorCliente('payload', controls));
        } else {
          dispatch(globalChangeStatesCotizadorCliente('validations', validationErrorsCotizacion));
          dispatch(globalChangeStatesCotizadorCliente('payload', controls))
        }
      } else if (event.target.name === 'telefono') {
        if (!rgxNumero.test(event.target.value)) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.empresa.telefono.error = true;
          newValidate.empresa.telefono.helperText = 'El teléfono solo debe contener números';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
          controls.empresa.telefono = '';
          setControls(controls);
          dispatch(globalChangeStatesCotizadorCliente('payload', controls));

        } else if (event.target.value.toString().length != 8) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.empresa.telefono.error = true;
          newValidate.empresa.telefono.helperText = 'No es un teléfono válido';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
          controls.empresa.telefono = '';
          setControls(controls);
          dispatch(globalChangeStatesCotizadorCliente('payload', controls));
        } else {
          dispatch(globalChangeStatesCotizadorCliente('validations', validationErrorsCotizacion));
          dispatch(globalChangeStatesCotizadorCliente('payload', controls))
        }
      } else {
        dispatch(globalChangeStatesCotizadorCliente('payload', controls))
      }
    } else if (value == '2') {
      controls.empresa = null;
      controls.persona = usuarioPersona;
      if (event.target.id === 'rut') {
        if (validate(format(event.target.value.toUpperCase().trim())) == true) {
          var rut = format(event.target.value.toUpperCase().trim());
          controls.persona.rut = rut;
          setControls(controls);
          dispatch(validateRutCotizador({ rut: rut, origin: "persona" }));
          dispatch(globalChangeStatesCotizadorCliente('payload', controls))
        }
        else if (event.target.value.toUpperCase().trim().length > 0) {
          var rut = '';
         // dispatch(showMessageCotizadorCliente({ type: 'error', text: 'Rut inválido' }));
         setErrorPersonaRut(true);
         setErrorTextPersonaRut('Rut inválido');
          controls.persona.rut = rut;
          setControls(controls);
          dispatch(globalChangeStatesCotizadorCliente('payload', controls))
        }
      } else if (event.target.id === 'email') {
        if (!rgxValidarEmail.test(event.target.value)) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.persona.email.error = true;
          newValidate.persona.email.helperText = 'El formato del email es incorrecto';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
          controls.persona.email = '';
          setControls(controls);
          dispatch(globalChangeStatesCotizadorCliente('payload', controls));
        } else {
          dispatch(globalChangeStatesCotizadorCliente('validations', validationErrorsCotizacion));
          dispatch(globalChangeStatesCotizadorCliente('payload', controls))
        }

      } else if (event.target.id === 'telefono') {
        if (!rgxNumero.test(event.target.value)) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.persona.telefono.error = true;
          newValidate.persona.telefono.helperText = 'El teléfono solo debe contener números';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
          controls.persona.telefono = '';
          setControls(controls);
          dispatch(globalChangeStatesCotizadorCliente('payload', controls));

        } else if (event.target.value.toString().length != 8) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.persona.telefono.error = true;
          newValidate.persona.telefono.helperText = 'No es un teléfono válido';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
          controls.persona.telefono = '';
          setControls(controls);
          dispatch(globalChangeStatesCotizadorCliente('payload', controls));
        } else {
          dispatch(globalChangeStatesCotizadorCliente('validations', validationErrorsCotizacion));
          dispatch(globalChangeStatesCotizadorCliente('payload', controls))
        }
      } else if (event.target.name === 'email_usuario') {
        if (!rgxValidarEmail.test(event.target.value)) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.usuario.email.error = true;
          newValidate.usuario.email.helperText = 'El formato del email es incorrecto';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
          controls.usuario.email = '';
          setControls(controls);
          dispatch(globalChangeStatesCotizadorCliente('payload', controls));
        } else {
          dispatch(globalChangeStatesCotizadorCliente('validations', validationErrorsCotizacion));
          dispatch(globalChangeStatesCotizadorCliente('payload', controls))
        }
      } else {
        dispatch(globalChangeStatesCotizadorCliente('payload', controls))
      }

    }
  }

  const onBlurRplRut = (event) => {
    if (event.target.id === 'rut') {
      if (validate(format(event.target.value.toUpperCase().trim())) == true) {
        var rut = format(event.target.value.toUpperCase().trim());
        usuarioEmpresa.representante_legal.rut = rut;
        controls.empresa = usuarioEmpresa;
        setControls(controls);
        dispatch(globalChangeStatesCotizadorCliente('payload', controls))
      }
      else if (event.target.value.toUpperCase().trim().length > 0) {
        var rut = '';
        alert('RUT INVALIDO');
        usuarioEmpresa.representante_legal.rut = rut;
        controls.empresa = usuarioEmpresa;
        setControls(controls);
        dispatch(globalChangeStatesCotizadorCliente('payload', controls))
      }
    } else {
      dispatch(globalChangeStatesCotizadorCliente('payload', controls))
    }
  }

  const onBlurPassword = (event) => {
    let password = event.target.value;
    let error = null;
    if (!password.trim()) {
      error = "Campo obligatorio";
    } else if (!rgxMayusculas.test(password)) {
      error = "Debe tener Mayuscula.";
    } else if (!rgxMinusculas.test(password)) {
      error = "Debe tener Minuscula.";
    } else if (!rgxNumero.test(password)) {
      error = "Debe tener Numeros.";
    } else if (rgxCaracteresEspeciales.test(password)) {
      error = "No debe tener @#$%^&*()_+!'\".";
    } else if (!rgxNumeroCaracteres.test(password)) {
      error = "Debe ser de minimo 8 caracteres.";
    }

    if (error != null) {
      let newValidate = lodash.cloneDeep(validations);
      newValidate.usuario.password.error = true;
      newValidate.usuario.password.helperText = error;
      dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
      controls.usuario.password = '';
      setControls(controls);
      dispatch(globalChangeStatesCotizadorCliente('payload', controls));
    } else {
      dispatch(globalChangeStatesCotizadorCliente('validations', validationErrorsCotizacion));
      dispatch(globalChangeStatesCotizadorCliente('payload', controls))
    }
  }



  const onBlurConfirmPass = (event) => {
    if (event.target.id === 'passwordConfirm') {
      const obj = lodash.cloneDeep(controls);
      if (obj.usuario.password === event.target.value) {
        dispatch(globalChangeStatesCotizadorCliente('payload', obj))
      } else {
        alert("Las contraseñas deben ser iguales");
        const newPayload = {
          ...obj,
          usuario: {
            ...obj.usuario,
            passwordConfirm: ''
          }
        };
        setControls(newPayload);
        dispatch(globalChangeStatesCotizadorCliente('payload', newPayload))
      }
    }
  }


  // Radio Button
  const [value, setValue] = useState('1');
  const handleChange = (event) => {
    setValue(event.target.value);
    setValueRegion('');
    setInputValueRegion('');
    setValueComuna('');
    setInputValueComuna('');
    if (event.target.value == '1') {
      usuarioEmpresa.fk_region = '';
      usuarioEmpresa.fk_comuna = '';
      controls.empresa = usuarioEmpresa;
      controls.persona = null;
      controls.tipo = 1;
    } else if (event.target.value == '2') {
      controls.empresa = null;
      usuarioPersona.fk_region = '';
      usuarioPersona.fk_comuna = '';
      controls.persona = usuarioPersona;
      controls.tipo = 2;
    }
    dispatch(globalChangeStatesCotizadorCliente('payload', controls))
  };

  // input pass para mostrar contraseña
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowRepeatPassword = () => setShowRepeatPassword(!showRepeatPassword);
  const handleMouseDownRepeatPassword = () => setShowRepeatPassword(!showRepeatPassword);
  const [valueRegion, setValueRegion] = React.useState('');
  const [inputValueRegion, setInputValueRegion] = React.useState('');
  const [valueComuna, setValueComuna] = React.useState('');
  const [inputValueComuna, setInputValueComuna] = React.useState('');
  // input pass para mostrar contraseña

  // sidebar fixed
  const [show, setShow] = useState(false);
  const handleShow = () => {
    const seccion = document.getElementsByClassName('aside');
    if (typeof window !== seccion) {
      if (window.pageYOffset > 278) {
        if (!show) {
          console.log("set to true");
          setShow(true);
        }
      }
      if (window.pageYOffset < 278) {
        console.log("set to false");
        setShow(false);
      }
    }
  };

  React.useEffect(() => {
    const seccion = document.getElementsByClassName('aside');
    if (typeof window !== seccion) {
      window.addEventListener("scroll", handleShow);
    }
    return () => {
      if (typeof window !== seccion) {
        window.removeEventListener("scroll", handleShow);
      }
    };
  }, []);
  // sidebar fixed


  // efecto top
  useEffect(() => {
    const body = document.querySelector('#top');

    body.scrollIntoView({
      behavior: 'smooth'
    }, 500)

  }, []);

  const [showTooltip, setShowTooltip] = useState(false);


  return (
    <div className="main-flex" id="top">
      {/* aside */}
      {/* <aside className={show ? "aside aside-fixed" : "aside"}>
          <h3 className="title-h3 font-weight-700 mb-3">Registrate</h3>
          <p className="text font-weight-400">
            Para crear tu cuenta necesitamos los siguiente datos, Solo te tomará
            unos minutos.
          </p>
          <div className="border my-4"></div>
          <h5 className="text-small font-weight-500 mb-3">
            Al crear tu cuenta en WS Cargo podrás:
          </h5>

          <div className="title-icon">
            <div className="cards-icon icon-success  icon-large mr-3">
              <LabelImportantIcon />
            </div>
            <p className="text-small font-weight-400">
              Realizar nuevas cotizaciones
            </p>
          </div>

          <div className="title-icon my-3">
            <div className="cards-icon icon-success icon-large mr-3">
              <LabelImportantIcon />
            </div>
            <p className="text-small font-weight-400">
              Darle seguimiento detallado a tus importaciones
            </p>
          </div>
        </aside> */}
      {/* fin aside */}

      {/* contenido right */}
      <section className="app-section-register">
        <div className='pl-3'>
          <h3 className="title-h3 font-weight-700 mb-2">Regístrate</h3>
          <p className="text font-weight-400 mb-4"> Para crear tu cuenta necesitamos los siguiente datos, solo te tomará unos minutos...</p>
        </div>
      
        {/* Radio Button */}

        {/* datos usuaerio */}
        <div className="form-content input-material-ui">
          <div className="row">
            <div className="col-12 mb-2">
              <h4 className="text font-weight-700 mb-2 text-capitalize">
                Perfil Usuario
              </h4>
            </div>

            {/* input */}
            <div className="col-12 col-lg-4">
              <InputLabel className="Label_Input_Format">Email</InputLabel>
              <TextField
                id="email"
                name="email_usuario"
                value={controls.usuario.email}
                onChange={onChange}
                onBlur={onBlur}
                placeholder="email@wscargo.cl"
                type="email"
                error={validations.usuario.email.error}
                helperText={validations.usuario.email.helperText}
              />
            </div>

            {/* input */}
            <div className="col-12 col-lg-4 my-3 my-md-0">
              <InputLabel className="Label_Input_Format">
                Contraseña
                {/* Tooltip */}
                <HtmlTooltip
                  open={showTooltip}
                  onOpen={() => setShowTooltip(true)}
                  onClose={() => setShowTooltip(false)}
                  title={
                    <>
                      <ol>
                        <li>Debe tener Mayuscula.</li>
                        <li>Debe tener Minuscula.</li>
                        <li>Debe tener Numeros.</li>
                        <li>No debe tener @#$%^&*()_+!'\.</li>
                        <li>Debe ser de minimo 8 caracteres.</li>
                      </ol>
                    </>
                  }
                >
                  <Button 
                    className={classes.ButtonNav}
                    onClick={() => setShowTooltip(!showTooltip)}
                    >
                    <HelpIcon className={classes.icon} />
                  </Button>
                </HtmlTooltip>
                {/* fin Tooltip */}
              </InputLabel>
              <TextField
                id="password"
                name="password"
                value={controls.usuario.password}
                onChange={onChange}
                onBlur={onBlurPassword}
                placeholder="Contraseña"
                type={showPassword ? "text" : "password"}
                error={validations.usuario.password.error}
                helperText={validations.usuario.password.helperText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>

            {/* input */}
            <div className="col-12 col-lg-4">
              <InputLabel className="Label_Input_Format">
                Repetir Contraseña
              </InputLabel>
              <TextField
                id="passwordConfirm"
                name="passwordConfirm"
                value={controls.usuario.passwordConfirm}
                onChange={onChange}
                onBlur={onBlurConfirmPass}
                placeholder="Repetir Contraseña"
                type={showRepeatPassword ? "text" : "password"}
                error={validations.usuario.passwordConfirm.error}
                helperText={validations.usuario.passwordConfirm.helperText}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowRepeatPassword}
                        onMouseDown={handleMouseDownRepeatPassword}
                      >
                        {showRepeatPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>

        <div className="align-item-center my-3">
          <h4 className="text-small font-weight-700 mr-3">Tipo de cuenta</h4>
          <FormControl className="input-material-ui">
            <RadioGroup
              row
              aria-label="gender"
              name="gender1"
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Empresa"
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Persona"
              />
            </RadioGroup>
          </FormControl>
        </div>

        {// valur 1, perfil persona
          value === "2" ? (
            <div className="form-content input-material-ui mb-3">
              <div className="row">
                <div className="col-12 mb-2">
                  <h4 className="text font-weight-700 mb-2">
                    Datos del importador
                  </h4>
                </div>

                {/* input */}
                <div className="col-12 col-lg-4">
                  <InputLabel className="Label_Input_Format">Rut</InputLabel>
                  <TextField
                    id="rut"
                    name="rut"
                    value={usuarioPersona.rut}
                    onChange={(event) => {
                      onChangeType(event, "persona");
                    }}
                    onBlur={onBlur}
                    placeholder="17.678-909-k"
                    error={errorPersonaRut}
                    helperText={errorTextPersonaRut}
                  />
                </div>

                {/* input */}
                <div className="col-12 col-lg-4 my-3 my-md-0">
                  <InputLabel className="Label_Input_Format">Nombre</InputLabel>
                  <TextField
                    id="nombre"
                    name="nombre"
                    value={usuarioPersona.nombre}
                    onChange={(event) => {
                      onChangeType(event, "persona");
                    }}
                    onBlur={onBlur}
                    placeholder="Ingresa tu nombre"
                    error={validations.persona.nombre.error}
                    helperText={validations.persona.nombre.helperText}
                  />
                </div>

                {/* input */}
                <div className="col-12 col-lg-4">
                  <InputLabel className="Label_Input_Format">Email</InputLabel>
                  <TextField
                    id="email"
                    name="email"
                    value={usuarioPersona.email}
                    onChange={(event) => {
                      onChangeType(event, "persona");
                    }}
                    onBlur={onBlur}
                    placeholder="Email"
                    type="email"
                    error={validations.persona.email.error}
                    helperText={validations.persona.email.helperText}
                  />
                </div>

                {/* input */}
                <div className="col-12 col-lg-4 mt-3">
                  <InputLabel className="Label_Input_Format">
                    Teléfono
                  </InputLabel>
                  <TextField
                    id="telefono"
                    name="telefono"
                    placeholder="90234567"
                    value={usuarioPersona.telefono}
                    onChange={(event) => {
                      onChangeType(event, "persona");
                    }}
                    onBlur={onBlur}
                    size="small"
                    type="tel"
                    error={validations.persona.telefono.error}
                    helperText={validations.persona.telefono.helperText}
                    className={classes.inputAdornment}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+569</InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="col-12">
                  <div className="border my-4"></div>
                </div>

                <div className="col-12 mb-2">
                  <h4 className="text font-weight-700 mb-2">
                    Dirección del importador
                  </h4>
                </div>
                {/* input */}
                <div className="col-12 col-lg-4 mb-4">
                  <InputLabel className="Label_Input_Format">Región</InputLabel>
                  <Autocomplete
                    id="fk_region"
                    name="fk_region"
                    options={regiones}
                    getOptionLabel={(options) => options.nombre}
                    value={valueRegion}
                    onChange={(event, newValue) => {
                      setValueRegion(newValue);
                      if (newValue != null && newValue.id != undefined) {
                        const obj = lodash.cloneDeep(usuarioPersona);
                        const newPayload = {
                          ...obj,
                          fk_region: newValue.id,
                        };
                        setUsuarioPersona(newPayload);
                        dispatch(
                          getComunasCotizadorCliente({ id: newValue.id })
                        );
                      }
                    }}
                    inputValue={inputValueRegion}
                    onInputChange={(event, inputValueRegion) => {
                      setInputValueRegion(inputValueRegion);
                      if (
                        inputValueRegion != null &&
                        inputValueRegion.id != undefined
                      ) {
                        const obj = lodash.cloneDeep(usuarioPersona);
                        const newPayload = {
                          ...obj,
                          fk_region: inputValueRegion.id,
                        };
                        setUsuarioPersona(newPayload);
                      }
                    }}
                    onBlur={onBlur}
                    placeholder="Ingresa tu región"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="Input_Number_Format"
                        placeholder='Ingresa tu región'
                        error={validations.persona.fk_region.error}
                        helperText={validations.persona.fk_region.helperText}
                      />
                    )}
                  />
                </div>

                {/* input */}
                <div className="col-12 col-lg-4 mb-3">
                  <InputLabel className="Label_Input_Format mt-4 mt-md-0">
                    Comuna
                  </InputLabel>
                  <Autocomplete
                    id="fk_comuna"
                    name="fk_comuna"
                    options={comunas}
                    disabled={comunas.length > 0 ? false : true}
                    getOptionLabel={(options) => options.nombre}
                    value={valueComuna}
                    onChange={(event, newValue) => {
                      setValueComuna(newValue);
                      if (newValue != null && newValue.id != undefined) {
                        const obj = lodash.cloneDeep(usuarioPersona);
                        const newPayload = {
                          ...obj,
                          fk_comuna: newValue.id,
                        };
                        setUsuarioPersona(newPayload);
                      }
                    }}
                    inputValue={inputValueComuna}
                    onInputChange={(event, inputValueComuna) => {
                      setInputValueComuna(inputValueComuna);
                      if (
                        inputValueComuna != null &&
                        inputValueComuna.id != undefined
                      ) {
                        const obj = lodash.cloneDeep(usuarioPersona);
                        const newPayload = {
                          ...obj,
                          fk_comuna: inputValueComuna.id,
                        };
                        setUsuarioPersona(newPayload);
                      }
                    }}
                    onBlur={onBlur}
                    placeholder="Ingresa tu comuna"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="Input_Number_Format"
                        placeholder='Ingresa tu comuna'
                        error={validations.persona.fk_comuna.error}
                        helperText={validations.persona.fk_comuna.helperText}
                      />
                    )}
                  />
                </div>

                {/* input */}
                <div className="col-12 col-lg-4">
                  <InputLabel className="Label_Input_Format mt-4 mt-md-0">
                    Calle
                  </InputLabel>
                  <TextField
                    id="calle"
                    name="calle"
                    value={usuarioPersona.calle}
                    onChange={(event) => {
                      onChangeType(event, "persona");
                    }}
                    onBlur={onBlur}
                    placeholder="Nombre de la calle o avenida"
                    error={validations.persona.calle.error}
                    helperText={validations.persona.calle.helperText}
                  />
                </div>

                {/* input */}
                <div className="col-12 col-lg-4">
                  <InputLabel className="Label_Input_Format mt-4 mt-md-3">
                    Número / Depto
                  </InputLabel>
                  <TextField
                    id="numero_depto"
                    name="numero_depto"
                    value={usuarioPersona.numero_depto}
                    onChange={(event) => {
                      onChangeType(event, "persona");
                    }}
                    onBlur={onBlur}
                    placeholder="Numero o departamento"
                    className={classes.inputAdornment}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">#</InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}

        {// valur 2, perfil empresa
          value === "1" ? (
            <>
              {/* datos tributarios */}
              <div className="form-content input-material-ui my-3">
                <div className="row">
                  <div className="col-12 mb-2">
                    <h4 className="text font-weight-700 mb-2">
                      Datos Tributarios
                    </h4>
                  </div>
                  {/* input */}
                  <div className="col-12 col-lg-4">
                    <InputLabel className="Label_Input_Format">Rut</InputLabel>
                    <TextField
                      id="rut"
                      name="rut"
                      value={usuarioEmpresa.rut}
                      onChange={(event) => {
                        onChangeType(event, "empresa");
                      }}
                      onBlur={onBlur}
                      placeholder="17.678-909-k"
                      error={errorEmpresaRut}
                      helperText={errorTextEmpresaRut}
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-lg-4 my-3 my-md-0">
                    <InputLabel className="Label_Input_Format">
                      Razón Social
                    </InputLabel>
                    <TextField
                      id="razonSocial"
                      name="razonSocial"
                      value={usuarioEmpresa.razonSocial}
                      onChange={(event) => {
                        onChangeType(event, "empresa");
                      }}
                      onBlur={onBlur}
                      placeholder="Describe tu razón social"
                      error={validations.empresa.razonSocial.error}
                      helperText={validations.empresa.razonSocial.helperText}
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-lg-4">
                    <InputLabel className="Label_Input_Format">Giro</InputLabel>
                    <TextField
                      id="giro"
                      name="giro"
                      value={usuarioEmpresa.giro}
                      onChange={(event) => {
                        onChangeType(event, "empresa");
                      }}
                      onBlur={onBlur}
                      placeholder="Detalle giro"
                      size="small"
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-lg-4 mt-3">
                    <InputLabel className="Label_Input_Format">
                      Email
                    </InputLabel>
                    <TextField
                      id="email"
                      name="email"
                      value={usuarioEmpresa.email}
                      onChange={(event) => {
                        onChangeType(event, "empresa");
                      }}
                      onBlur={onBlur}
                      placeholder="Email"
                      type="email"
                      error={validations.empresa.email.error}
                      helperText={validations.empresa.email.helperText}
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-lg-4 mt-3">
                    <InputLabel className="Label_Input_Format">
                      Teléfono
                    </InputLabel>
                    <TextField
                      id="telefono"
                      name="telefono"
                      placeholder="90234567"
                      size="small"
                      type="tel"
                      value={usuarioEmpresa.telefono}
                      onChange={(event) => {
                        onChangeType(event, "empresa");
                      }}
                      onBlur={onBlur}
                      error={validations.empresa.telefono.error}
                      helperText={validations.empresa.telefono.helperText}
                      className={classes.inputAdornment}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+569</InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div className="col-12">
                    <div className="border my-4"></div>
                  </div>

                  <div className="col-12 mb-2">
                    <h4 className="text font-weight-700 mb-2 text-capitalize">
                      Dirección Tributaria
                    </h4>
                  </div>

                  {/* input */}
                  <div className="col-12 col-lg-4 mb-4">
                    <InputLabel className="Label_Input_Format">
                      Región
                    </InputLabel>
                    <Autocomplete
                      id="fk_region"
                      name="fk_region"
                      options={regiones}
                      getOptionLabel={(options) => options.nombre}
                      value={valueRegion}
                      onChange={(event, newValue) => {
                        setValueRegion(newValue);
                        if (newValue != null && newValue.id != undefined) {
                          const obj = lodash.cloneDeep(usuarioEmpresa);
                          const newPayload = {
                            ...obj,
                            fk_region: newValue.id,
                          };
                          setUsuarioEmpresa(newPayload);
                          dispatch(
                            getComunasCotizadorCliente({ id: newValue.id })
                          );
                        }
                      }}
                      inputValue={inputValueRegion}
                      onInputChange={(event, inputValueRegion) => {
                        setInputValueRegion(inputValueRegion);
                        if (
                          inputValueRegion != null &&
                          inputValueRegion.id != undefined
                        ) {
                          const obj = lodash.cloneDeep(usuarioEmpresa);
                          const newPayload = {
                            ...obj,
                            fk_region: inputValueRegion.id,
                          };
                          setUsuarioEmpresa(newPayload);
                        }
                      }}
                      onBlur={onBlur}
                      placeholder="Ingresa tu región"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="Input_Number_Format"
                          placeholder='Ingresa tu región'
                          error={validations.empresa.fk_region.error}
                          helperText={validations.empresa.fk_region.helperText}
                        />
                      )}
                    />
                  </div>
                  {/* input */}
                  <div className="col-12 col-lg-4 mb-4">
                    <InputLabel className="Label_Input_Format mt-4 mt-md-0">
                      Comuna
                    </InputLabel>
                    <Autocomplete
                      id="fk_comuna"
                      name="fk_comuna"
                      options={comunas}
                      disabled={comunas.length > 0 ? false : true}
                      getOptionLabel={(options) => options.nombre}
                      value={valueComuna}
                      onChange={(event, newValue) => {
                        setValueComuna(newValue);
                        if (newValue != null && newValue.id != undefined) {
                          const obj = lodash.cloneDeep(usuarioEmpresa);
                          const newPayload = {
                            ...obj,
                            fk_comuna: newValue.id,
                          };
                          setUsuarioEmpresa(newPayload);
                        }
                      }}
                      inputValue={inputValueComuna}
                      onInputChange={(event, inputValueComuna) => {
                        setInputValueComuna(inputValueComuna);
                        if (
                          inputValueComuna != null &&
                          inputValueComuna.id != undefined
                        ) {
                          const obj = lodash.cloneDeep(usuarioEmpresa);
                          const newPayload = {
                            ...obj,
                            fk_comuna: inputValueComuna.id,
                          };
                          setUsuarioEmpresa(newPayload);
                        }
                      }}
                      onBlur={onBlur}
                      placeholder="Ingresa tu comuna"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="Input_Number_Format"
                          placeholder='Ingresa tu comuna'
                          error={validations.empresa.fk_comuna.error}
                          helperText={validations.empresa.fk_comuna.helperText}
                        />
                      )}
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-lg-4">
                    <InputLabel className="Label_Input_Format mt-4 mt-md-0">
                      Calle
                    </InputLabel>
                    <TextField
                      id="calle"
                      name="calle"
                      value={usuarioEmpresa.calle}
                      onChange={(event) => {
                        onChangeType(event, "empresa");
                      }}
                      onBlur={onBlur}
                      placeholder="Nombre de la calle o avenida"
                      error={validations.empresa.calle.error}
                      helperText={validations.empresa.calle.helperText}
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-lg-4">
                    <InputLabel className="Label_Input_Format mt-4 mt-md-3">
                      Número / Depto
                    </InputLabel>
                    <TextField
                      id="numero_depto"
                      name="numero_depto"
                      value={usuarioEmpresa.numero_depto}
                      onChange={(event) => {
                        onChangeType(event, "empresa");
                      }}
                      onBlur={onBlur}
                      placeholder="Numero o departamento"
                      className={classes.inputAdornment}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">#</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* representante legal */}
              <div className="form-content input-material-ui my-3">
                <div className="row">
                  <div className="col-12 mb-2">
                    <h4 className="text font-weight-700 mb-2">
                      Representante legal
                    </h4>
                  </div>
                  {/* input */}
                  <div className="col-12 col-lg-4 mb-0 mb-md-3">
                    <InputLabel className="Label_Input_Format">Rut</InputLabel>
                    <TextField
                      id="rut"
                      name="rut"
                      value={usuarioEmpresa.representante_legal.rut}
                      onChange={onChangeRpl}
                      onBlur={onBlurRplRut}
                      placeholder="17.678-909-k"
                    />
                  </div>

                  {/* input  */}
                  <div className="col-12 col-lg-4 mt-3 mt-md-0">
                    <InputLabel className="Label_Input_Format">
                      Nombre
                    </InputLabel>
                    <TextField
                      id="nombre"
                      name="nombre"
                      value={usuarioEmpresa.representante_legal.nombre}
                      onChange={onChangeRpl}
                      onBlur={onBlur}
                      placeholder="Nombre del representante legal"
                    />
                  </div>

                  {/* input  */}
                  <div className="col-12 col-lg-4 mt-3 mt-md-0">
                    <InputLabel className="Label_Input_Format">
                      Apellido
                    </InputLabel>
                    <TextField
                      id="apellido"
                      name="apellido"
                      value={usuarioEmpresa.representante_legal.apellido}
                      onChange={onChangeRpl}
                      onBlur={onBlur}
                      placeholder="Nombre del representante legal"
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-lg-4 mt-3">
                    <InputLabel className="Label_Input_Format">
                      Email
                    </InputLabel>
                    <TextField
                      id="email"
                      name="email_rep_legal"
                      value={usuarioEmpresa.representante_legal.email}
                      onChange={onChangeRpl}
                      onBlur={onBlur}
                      placeholder="Email@escargo.cl"
                      type="email"
                      error={
                        validations.empresa.representante_legal.email.error
                      }
                      helperText={
                        validations.empresa.representante_legal.email.helperText
                      }
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-lg-4 mt-3">
                    <InputLabel className="Label_Input_Format">
                      Teléfono
                    </InputLabel>
                    <TextField
                      id="telefono"
                      name="telefono_rep_legal"
                      placeholder="90234567"
                      size="small"
                      type="tel"
                      value={usuarioEmpresa.representante_legal.telefono}
                      onChange={onChangeRpl}
                      onBlur={onBlur}
                      error={
                        validations.empresa.representante_legal.telefono.error
                      }
                      helperText={
                        validations.empresa.representante_legal.telefono
                          .helperText
                      }
                      className={classes.inputAdornment}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+569</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
      </section>
    </div>
  );
};

export default CardTwo;