export const SHOW_MESSAGE_DASHBOARD='SHOW_MESSAGE_DASHBOARD';
export const HIDE_MESSAGE_DASHBOARD='HIDE_MESSAGE_DASHBOARD';
export const SET_LOADER_DASHBOARD='SET_LOADER_DASHBOARD';
export const GET_COTIZACIONES_DASHBOARD='GET_COTIZACIONES_DASHBOARD';
export const GET_COTIZACIONES_DASHBOARD_SUCCESS='GET_COTIZACIONES_DASHBOARD_SUCCESS';
export const GET_SERVICIOS_DASHBOARD='GET_SERVICIOS_DASHBOARD';
export const GET_SERVICIOS_DASHBOARD_SUCCESS='GET_SERVICIOS_DASHBOARD_SUCCESS';
export const GET_ZONASTARIFARIAS_DASHBOARD='GET_ZONASTARIFARIAS_DASHBOARD';
export const GET_ZONASTARIFARIAS_DASHBOARD_SUCCESS='GET_ZONASTARIFARIAS_DASHBOARD_SUCCESS';
export const GET_PROPUESTABASE_DASHBOARD='GET_PROPUESTABASE_DASHBOARD';
export const GET_PROPUESTABASE_DASHBOARD_SUCCESS='GET_PROPUESTABASE_DASHBOARD_SUCCESS';
export const POST_COTIZACION_DASHBOARD='POST_COTIZACION_DASHBOARD';
export const POST_COTIZACION_DASHBOARD_SUCCESS='POST_COTIZACION_DASHBOARD_SUCCESS';
export const DELETE_COTIZACION_DASHBOARD='DELETE_COTIZACION_DASHBOARD';
export const GET_EMPRESAS_DASHBOARD='GET_EMPRESAS_DASHBOARD';
export const GET_EMPRESAS_DASHBOARD_SUCCESS='GET_EMPRESAS_DASHBOARD_SUCCESS';
export const GET_REGIONES_DASHBOARD='GET_REGIONES_DASHBOARD';
export const GET_REGIONES_DASHBOARD_SUCCESS='GET_REGIONES_DASHBOARD_SUCCESS';
export const GET_COMUNAS_DASHBOARD='GET_COMUNAS_DASHBOARD';
export const GET_COMUNAS_DASHBOARD_SUCCESS='GET_COMUNAS_DASHBOARD_SUCCESS';
export const POST_SERVICIO_DASHBOARD='POST_SERVICIO_DASHBOARD';
export const POST_SERVICIO_DASHBOARD_SUCCESS='POST_SERVICIO_DASHBOARD_SUCCESS';
export const SET_PAYLOAD_SERVICIO_DASHBOARD='SET_PAYLOAD_SERVICIO_DASHBOARD';
export const POST_TRACKINGS_INCOMPLETE_DASHBOARD='POST_TRACKINGS_INCOMPLETE_DASHBOARD';
export const POST_TRACKINGS_INCOMPLETE_DASHBOARD_SUCCESS='POST_TRACKINGS_INCOMPLETE_DASHBOARD_SUCCESS';
export const GET_COMERCIALES_DASHBOARD='GET_COMERCIALES_DASHBOARD';
export const GET_COMERCIALES_DASHBOARD_SUCCESS='GET_COMERCIALES_DASHBOARD_SUCCESS';
export const GET_HISTORY_SERVICES_DASHBOARD='GET_HISTORY_SERVICES_DASHBOARD';
export const GET_HISTORY_SERVICES_DASHBOARD_SUCCESS='GET_HISTORY_SERVICES_DASHBOARD_SUCCESS';
export const GET_STATES_COMPLETE_SERVICES_DASHBOARD='GET_STATES_COMPLETE_SERVICES_DASHBOARD';
export const GET_STATES_COMPLETE_SERVICES_DASHBOARD_SUCCESS='GET_STATES_COMPLETE_SERVICES_DASHBOARD_SUCCESS';
export const SET_RESPONSE_POST_DASHBOARD='SET_RESPONSE_POST_DASHBOARD';
export const PUT_REFERENCIA_DASHBOARD='PUT_REFERENCIA_DASHBOARD';
export const PUT_REFERENCIA_DASHBOARD_SUCCESS='PUT_REFERENCIA_DASHBOARD_SUCCESS';
export const GET_IS_FIRST_ACCESS_DASHBOARD = 'GET_IS_FIRST_ACCESS_DASHBOARD';
export const GET_IS_FIRST_ACCESS_DASHBOARD_SUCCESS = 'GET_IS_FIRST_ACCESS_DASHBOARD_SUCCESS';
export const CLEAR_IS_FIRST_ACCESS_DASHBOARD = 'CLEAR_IS_FIRST_ACCESS_DASHBOARD';
export const GET_CLIENTS_SUPERADMIN_DASHBOARD = 'GET_CLIENTS_SUPERADMIN_DASHBOARD';
export const GET_CLIENTS_SUPERADMIN_DASHBOARD_SUCCESS = 'GET_CLIENTS_SUPERADMIN_DASHBOARD_SUCCESS';
export const GET_SERVICES_SUPERADMIN_DASHBOARD = 'GET_SERVICES_SUPERADMIN_DASHBOARD';
export const GET_SERVICES_SUPERADMIN_DASHBOARD_SUCCESS = 'GET_SERVICES_SUPERADMIN_DASHBOARD_SUCCESS';
export const GET_FECHAS_ESTIMADAS = 'GET_FECHAS_ESTIMADAS';
export const GET_FECHAS_ESTIMADAS_SUCCESS = 'GET_FECHAS_ESTIMADAS_SUCCESS';
export const GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE = 'GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE';
export const GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE_SUCCESS = 'GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE_SUCCESS';
export const SET_CLIENTE_ACTIVO = 'SET_CLIENTE_ACTIVO';
export const CLEAR_CLIENTE_ACTIVO = 'CLEAR_CLIENTE_ACTIVO';
export const GET_EMPRESA_DASHBOARD_BY_FKCLIENTE = 'GET_EMPRESAS_DASHBOARD_BY_FKCLIENTE';
export const GET_EMPRESA_DASHBOARD_BY_FKCLIENTE_SUCCESS = 'GET_EMPRESAS_DASHBOARD_BY_FKCLIENTE_SUCCESS';
export const GET_COTIZACIONES_BY_FKCLIENTE_DASHBOARD='GET_COTIZACIONES_BY_FKCLIENTE_DASHBOARD';
export const GET_COTIZACIONES__BY_FKCLIENTE_DASHBOARD_SUCCESS='GET_COTIZACIONES__BY_FKCLIENTE_DASHBOARD_SUCCESS';

export const CLEAR_ALL_EXPDIG_DASHBOAR_SUCCESS='CLEAR_ALL_EXPDIG_DASHBOAR_SUCCESS';
export const GET_PROVEEDORES_CNSLD_RAPIDA_DASHBOARD='GET_PROVEEDORES_CNSLD_RAPIDA_DASHBOARD';
export const GET_PROVEEDORES_CNSLD_RAPIDA_DASHBOARD_SUCCESS='GET_PROVEEDORES_CNSLD_RAPIDA_DASHBOARD_SUCCESS';
export const SET_MODAL_CNSLD_RAPIDO_DASHBOARD='SET_MODAL_CNSLD_RAPIDO_DASHBOARD';

export const GETENCUESTASPENDIENTES='GETENCUESTASPENDIENTES';
export const GETENCUESTASPENDIENTES_SUCCESS='GETENCUESTASPENDIENTES_SUCCESS';

export const HOME_SETMOSTRARMODALPAGARSERVICIO='HOME_SETMOSTRARMODALPAGARSERVICIO';
export const HOME_MODALPAGARSERVICIO_GETINFO='HOME_MODALPAGARSERVICIO_GETINFO';
export const HOME_MODALPAGARSERVICIO_GETINFO_SUCCESS='HOME_MODALPAGARSERVICIO_GETINFO_SUCCESS';
export const HOME_REALIZARPAGOSERVICIO_SUCCESS='HOME_REALIZARPAGOSERVICIO_SUCCESS';
export const HOME_REALIZARPAGOSERVICIO='HOME_REALIZARPAGOSERVICIO';
export const HOME_SETMOSTRARLOADINGPAGOS = 'HOME_SETMOSTRARLOADINGPAGOS';

export const GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE_INTERVALS='GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE_INTERVALS';

export const DASHBOARD_SEND_INVITATION_PROVIDER='DASHBOARD_SEND_INVITATION_PROVIDER';

export const GET_TIMELINE_DASHBOARD='GET_TIMELINE_DASHBOARD';
export const GET_TIMELINE_DASHBOARD_SUCCESS='GET_TIMELINE_DASHBOARD_SUCCESS';