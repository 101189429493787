import React from 'react';

const  NotFound = (props) => {
    return (
        <div>
            <h1>404 NOT FOUND</h1> 
        </div>
    );
}

export default NotFound;