import {all,spawn,fork} from 'redux-saga/effects';
import authSagas from './Auth';
import userMaintainer from '../modules/usuarios/Saga';
import Empresas_Maintainer from '../modules/empresas/Sagas.js'
//import Roles_Maintainer from '../modules/roles/Sagas.js'
import Configuracion from '../modules/configuracion/Saga';
import DashboardSaga from '../modules/dashboard/Saga';
import CotizadorClienteSaga from '../modules/cotizador/Saga';
import EmpresaImportadorSaga from '../modules/empresaImportador/Saga';
import DetalleServicioSaga from '../modules/detalleServicio/Saga';
import NotificationsSaga from '../modules/notificaciones/Saga';
import TrackSaga from './Track';
import UserSaga from '../modules/usuario/Saga';
import CustomerSurveySaga from '../modules/customer_survey/Saga.js';
import UploadDocsExpressSaga from 'modules/uploadDocsExpress/Saga';
import SolicAgendamientoSaga from 'modules/solicitudAgendamiento/Saga';
import PagoOnlineDetalleSaga from 'modules/detalle_pago_online/Saga';



export default function* rootSaga(getState) {
        yield all([
                fork(PagoOnlineDetalleSaga),
                fork(Empresas_Maintainer),
                fork(authSagas),
                fork(userMaintainer),
                //fork(Roles_Maintainer),
                fork(Configuracion),
                fork(DashboardSaga),
                fork(CotizadorClienteSaga),
                fork(EmpresaImportadorSaga),
                fork(DetalleServicioSaga),
                fork(NotificationsSaga),
                fork(TrackSaga),
                fork(UserSaga),
                fork(CustomerSurveySaga),
                fork(UploadDocsExpressSaga),
                fork(SolicAgendamientoSaga)
        ])
}
