import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import DoneOutline from '@material-ui/icons/DoneOutline';
import { motion } from "framer-motion"
import { withStyles } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import envConfig from '../../envConfig';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import lodash, { stubTrue, truncate } from 'lodash';
import { FaCheck } from "react-icons/fa";
import Nav from 'containers/AppLayout/Vertical/Nav';
import Header from 'containers/AppLayout/Vertical/Header';
import { Alert, AlertTitle } from '@material-ui/lab';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import {
    PAGONLINE_Get_Detalle_Pago
} from './Actions';
import Box from '@material-ui/core/Box';
import {
    Avatar,
    Button,
    Hidden,
    makeStyles,
    Tooltip,
    Menu,
    MenuItem,
    Typography
} from "@material-ui/core";
import { element } from 'prop-types';
import ReactHtmlParser from "react-html-parser";

import querystring from 'querystring';

const StyledTableCell1 = withStyles((theme) => ({
    head: {
      backgroundColor: '#273746',
      color: theme.palette.common.white,
    },
    body: {
      backgroundColor: '#273746',
      fontSize: 14,
      color:'#ffffff',
    },
  }))(TableCell);
  
  const StyledTableCell2 = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    inputAdornment: {
        "& .MuiTypography-body1": {
            fontSize: "12px"
        }
    },
    iconServicio: {
        width: "80px",
        height: "72px",

        "& .MuiSvgIcon-root": {
            fontSize: "32px"
        }
    },
    breadcrumbs: {
        paddingLeft: "48px",
        marginBottom: "24px"
    }
}));


// Tooltip MUI //
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#263238',
        color: '#fff',
        maxWidth: 300,
        padding: "12px 16px",
        "& .MuiTypography-h5": {
            fontSize: "14px"
        },
        "& .MuiTypography-h6": {
            fontSize: "10px"
        }
    },
}))(Tooltip);


const DetallePagoOnline = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const {
        DetallePago
    } = useSelector(({ PagoOnlineDetalle_Maintainer }) => PagoOnlineDetalle_Maintainer);

    const [Detalle, SetDetalle] = useState(props.match.params.id);

    return (
        <div className={`app-container new-template fixed-drawer`}>
            <div className='app-main-container'>
                <div className="app-main-container" style={{ backgroundColor: 'red !important' }}>
                    <div className='app-header-cotizador'>
                        <div className='main-title'>
                            <h1 className="title-h2 text-light font-weight-700">RESPUESTA DE PASARELA DE PAGO</h1>
                        </div>
                    </div>
                </div>
                <div className='container-fuid paddingContainer' style={{'marginTop':'30px'}}>
                    {
                        <Alert severity="error">
                            <AlertTitle><h1>ERROR</h1></AlertTitle>
                            <h2>Tu pago ha presentado errores!</h2>
                        </Alert>
                    }

                    <Card className={classes.root+' p-3'} style={{boxShadow:'3'}}>
                        <CardHeader
                            avatar={
                            <Avatar aria-label="recipe" style={{width:'50px',height:'50px', backgroundColor:'#E74C3C'}}>
                                <CheckIcon style={{fontSize: 40}}/>
                            </Avatar>
                            }
                            titleTypographyProps={{variant:'h4' }}
                            title={Detalle}
                            subheaderTypographyProps={{variant:'subtitle1' }}
                        />
                         <br></br>
                            <div className="row mt-3">
                                <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <Button
                                    size="large"
                                    className="button button-gray col-12 mb-1"
                                    onClick={()=>{
                                        window.location.href = envConfig.enviroment.urlFrontCliente
                                    }}
                                    >VOLVER A WSCARGO</Button>
                                </div>
                            </div>
                    </Card>

                   
                </div>
            </div>
        </div>
    )
};

export default DetallePagoOnline;