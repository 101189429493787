import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import Alert from "@material-ui/lab/Alert";
import { motion } from "framer-motion";
import HelpIcon from '@material-ui/icons/Help';
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Avatar,
  // Button,
  // Breadcrumbs,
  Typography,
  Hidden,
  makeStyles,
  Tooltip,
  // Menu,
  // MenuItem,
  // Typography,
  withStyles,
} from "@material-ui/core";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
import DoneOutline from "@material-ui/icons/DoneOutline";
import moment from 'moment';

import lodash from "lodash";

import Nav from "./AppLayout/Vertical/Nav/index";
import Header from "./header/Header";
import { FaCheck } from "react-icons/fa";
import { getTrackListModifyCnsld } from "../actions/Track";
import '../components/timeline/styles.scss';

const useStyles = makeStyles((theme) => ({
  inputAdornment: {
    "& .MuiTypography-body1": {
      fontSize: "12px",
    },
  },
  iconServicio: {
    width: "80px",
    height: "72px",

    "& .MuiSvgIcon-root": {
      fontSize: "32px",
    },
  },
  breadcrumbs: {
    paddingLeft: "48px",
    marginBottom: "24px",
  },
  root: {
    padding: "0 80px",
    "@media screen and (min-width: 1900px)": {
      padding: "0 312px !important",
    },
    "@media screen and (max-width: 576px)": {
      padding: "0 24px",
    },
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
  },
  border: {
    borderLeft: "solid 6px  #EA5259",
  },
  bgMessage: {
    height:'80vh',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',

  },
}));

// Tooltip MUI //
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#263238",
    color: "#fff",
    maxWidth: 300,
    padding: "12px 16px",
    "& .MuiTypography-h5": {
      fontSize: "14px",
    },
    "& .MuiTypography-h6": {
      fontSize: "10px",
    },
  },
}))(Tooltip);

/*
const consolidados=[{
fk_cliente:2500,
razon_social:'RAZON SOCIAL PRUEBA EVIDAL 1',
nombre:'',
fk_consolidado:7070,
volumen:7,
peso:70,
bultos:7
},{
    fk_cliente:2500,
razon_social:'RAZON SOCIAL PRUEBA EVIDAL 1',
nombre:'',
    fk_consolidado:7071,
    volumen:20,
    peso:100,
    bultos:10
}]*/

const formatMiles = (input) => {
    var num = input.toString().replace(/\./g, '');
    var formato = ""
    if (!isNaN(num)) {
      num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
      num = num.split('').reverse().join('').replace(/^[\.]/, '');
      formato = num;
    }
    else {
      formato = formato.replace(/[^\d\.]*/g, '');
    }

    return formato;
  }

const Cnsldmodifylist = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { consolidados } = useSelector(({ Track }) => Track);

  //const { track, history, documents,fechasEstimadas } = useSelector(({ Track }) => Track);
  const divRef = useRef();
  const [list,setList] =useState(consolidados);
  const [tag, setTags] = useState('todos');

  useEffect(() => {
    let hash=props.match.params.id;
    dispatch(getTrackListModifyCnsld({ hash: hash }));
  }, []);

  useEffect(() => {
   setList(consolidados);
  }, [consolidados]);

  const classT = (tag) => {
    if (tag == "acción requerida") {
      return classes.border2;
    } else if (tag == "activo") {
      return classes.border3;
    } else if (tag == "alerta") {
      return classes.borderDanger;
    } else if (tag == "terminado") {
      return classes.border3;
    } else {
      return classes.border4;
    }
  }

  // class card servicio
  const classIcon = (tag) => {
    if (tag == "acción requerida") {
      return "icon-warning";
    } else if (tag == "activo") {
      return "icon-success"
    } else if (tag == "alerta") {
      return "icon-danger"
    } else if (tag == "terminado") {
      return "icon-success"
    } else {
      return "icon-default"
    }
  }

  return (
    <>
      <div className="app-container new-template">
        {/* <Tour />
            <SideBar2 /> */}
        <div
          className="app-main-container"
          style={{ backgroundColor: "red !important" }}
        >
          <main className="app-main-content-wrapper">
            <Nav />
            <div className="app-main-content template">
              <div className="app-wrapper">
                <Header />
                <div className={classes.root}>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5, duration: 1.3 }}
                  //className="app-registro"
                  >
                    <div className='row justify-content-md-center'>
                        <div className="col-12 col-md-12 col-lg-10 col-xl-8">
                            <div className="cards mb-2">
                                <div className='cards-header'>
                                    <div className='align-item-center'>
                                        <h4 className="text font-weight-500">Listado consolidados modificados</h4>
                                    </div>
                                    <div className="border-title"></div>
                                </div>
                                {list && list.length>0 && list.map(function(item,index){
                                     let styleLine = { display: '' }; let tag = 'activo';
                                        return(
                                            <>
                <div className={`cards app-cards ${classT(tag)}`} key={index} style={styleLine}>
                  <div className="cards-body content-space-between py-md-3">

                    {/* Info general del servicio */}
                    <div className="card-data-service align-item-center">

                      <div className={`cards-icon icon-xl mr-3 mr-md-4 ${classIcon(tag)}`}>
                          <DirectionsBoatIcon fontSize="small" />
                      </div>

                      <div className="card-item">
                        <h4 className="text-small font-weight-700">
                          Nº {item.fk_consolidado} {item.referencia != null && item.referencia != '' ? ` | ${item.referencia} ` : ''}
                          <Hidden smDown>
                            {item.razon_social != null && (item.razon_social.length > 43 || item.nombre.length > 43) ?
                              (<span className="font-weight-400"> ({item.fk_cliente} - {item.razon_social != null ? item.razon_social.toUpperCase().slice(0, 43) : (`${item.nombre.toUpperCase().slice(0, 43)}`)}...)</span>)
                              :
                              (<span className="font-weight-400"> ({item.fk_cliente} - {item.razon_social != null ? item.razon_social.toUpperCase() : item.nombre.toUpperCase()})</span>)
                            }
                          </Hidden>
                        </h4>

                        {/* nombre versión mobile */}
                        <Hidden smUp>
                          {item.razon_social != null && (item.razon_social.length > 33 || item.nombre.length > 33) ?
                            (<h4 className="text-small font-weight-500"> {item.fk_cliente} - {item.razon_social != null ? item.razon_social.toUpperCase().slice(0, 33) : (`${item.nombre.toUpperCase().slice(0, 33)}`)}...</h4>)
                            :
                            (<h4 className="text-small font-weight-500"> {item.fk_cliente} -  {item.razon_social != null ? item.razon_social.toUpperCase() : item.nombre.toUpperCase()}</h4>)
                          }
                        </Hidden>

                        <div className='align-item-center'>
                          <h5 className="text-small font-weight-400">
                            Bultos: {item.bultos_completos}
                            <span className='slash'>de</span>
                            {item.bultos == '' ? "0" : `${item.bultos}`}
                          </h5>

                          {/* toolpip  */}
                          <Hidden smDown>
                            <HtmlTooltip
                              title={
                                <React.Fragment>
                                  <Typography variant="h6" color="inherit">Hemos recepcionado {item.bultos} de {item.bultos} de tus bultos.</Typography>
                                </React.Fragment>
                              }
                            >
                              <HelpIcon className={`ml-1 ${classes.icon}`} />
                            </HtmlTooltip>

                            <h5 className="text-small font-weight-400 ml-4">Vol. Final: {item.volumen == '' ? "S/I" : `${item.volumen}M³`}</h5>
                            <h5 className="text-small font-weight-400 mx-4">Peso Final: {item.peso == '' ? "S/I" : `${item.peso}kg`}</h5>
                            <h5 className="text-small font-weight-400">{item.total==0?'USD $'+formatMiles(item.total):item.total}</h5>
                          </Hidden>

                          {/* valor servicio mobile */}
                          <Hidden smUp>
                            <h5 className="text-small font-weight-400 ml-3">{item.total==0?'USD $'+formatMiles(item.total):item.total}</h5>
                          </Hidden>
                        </div>
                      </div>
                    </div>
                    </div>
                    </div>
                    </>)})}
                    {list && list.length==0 &&
                     <>
                     <div className={`cards app-cards ${classT('activo')}`} >
                       <div className="cards-body content-space-between py-md-3">
     
                         {/* Info general del servicio */}
                         <div className="card-data-service align-item-center text-center">
                                <h4>Sin registros</h4>
                          </div>
                        </div>
                      </div>
                      </>
                    }
                            </div>
                            </div>
                            </div>  
                  </motion.div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Cnsldmodifylist;
