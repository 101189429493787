import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import imgBox from "./placeholder.jpg";
import { validate, format } from "rut.js";
import { motion } from "framer-motion"
import Message from "../components/message/Menssage/Index";
import ReCAPTCHA from "react-google-recaptcha";
import lodash from 'lodash';

// import {
//   NotificationContainer,
//   NotificationManager,
// } from "react-notifications";
// import IntlMessages from "util/IntlMessages";
import Autocomplete from "@material-ui/lab/Autocomplete";
import envConfig from '../envConfig';
// import {
//     hideMessage,
//     showAuthLoader,
//     userFacebookSignIn,
//     userGithubSignIn,
//     userGoogleSignIn,
//     userSignUp,
//     userTwitterSignIn
// } from 'actions/Auth';

import {
  InputLabel,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  TextField,
  makeStyles,
  IconButton,
  InputAdornment,
  CircularProgress,
  Hidden,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
  Tooltip,
  Typography,
  withStyles,
  FormHelperText,
  FormGroup
} from "@material-ui/core";

import {
  getRegionesRegistrarCliente,
  getComunasRegistrarCliente,
  postRegistrarClienteEmpresa,
  postRegistrarClientePersona,
  clearRegistros,
  validarEmailRegistroCliente,
  getComercialesRegistrarCliente,
  postReserveIdRegistrarCliente,
  clearEmailIsValid,
  validateRutRegistroCliente,
  postDataContact
} from "../actions";
import { gridColumnLookupSelector } from "@mui/x-data-grid";
import ImageIcon from '@material-ui/icons/Image';
import HelpIcon from '@material-ui/icons/Help';
// const LightTooltip = withStyles((theme) => ({
//   tooltip: {
//     backgroundColor: '#263238',
//     color: '#fff',
//     padding: "6px 8px",
//     fontSize: 11,
//   },
// }))(Tooltip);


const HtmlTooltip4 = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#263238',
    color: '#fff',
    maxWidth: 600,
    fontSize: theme.typography.pxToRem(12),
  },
}))(Tooltip);


const useStyles = makeStyles((theme) => ({
  link: {
    fontSize: "14px",
    color: "#263238",
    fontWeight: "500",
    marginRight: "16px",
    "&:hover": {
      color: "#8F8F8F",
    },
  },
  titleLogin: {
    paddingLeft: "12px",
    marginBottom: "16px",
  },
  avatarXxl: {
    width: "150px",
    height: "150px",
    margin: "0 auto",
  },
  dialogStyle: {
    color: "RED",
    "& .MuiDialogActions-root ": {
      justifyContent: "center",
    },
  },
  iconButtom: {
    "&:hover": {
      background: "transparent",
    },
  },
  ButtonNav: {
    padding: '0',
    height: '13px',
    width: '10px',
    color: "#8F8F8F",
    fontSize: "14px",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#44403f",
    }
  },
  icon: {
    fontSize: "16px",
    marginLeft: "-30px"
  },
}));
////
const initializeForm = {
  rutTributario: "",
  giroTributario: "",
  telTributario: "",
  razonSocial: "",
  emailTributario: "",
};
const account = {
  nombre_contacto: "",
  apellido_contacto: "",
  telefono_contacto: "",
  email: "",
  password: "",
  repetPassword: "",
};

const usuarioEmpresa = {
  id: "",
  rut: "",
  tipo: "1",
  razon_social: "",
  giro: "",
  email: "",
  telefono: "",
  calle: "",
  numero_depto: "",
  rp_rut: "",
  rp_nombre: "",
  rp_apellido: "",
  rp_email: "",
  rp_telefono: "",
  estado: "",
  fk_comuna: "",
  fk_region: "",
  fk_comercial: 0,
  fk_cliente: 0
};

const usuarioPersona = {
  id: "",
  rut: "",
  tipo: "2",
  nombre: "",
  apellido:"",
  email: "",
  telefono: "",
  calle: "",
  numero_depto: "",
  estado: "",
  fk_comuna: "",
  fk_region: "",
  fk_comercial: 0,
  fk_cliente: 0
};

const SignUp = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    regiones,
    comunas,
    registroEmpresa,
    registroPersona,
    emailIsValid,
    comerciales,
    reserveId,
    rutExiste
  } = useSelector(({ auth }) => auth);

  // input pass para mostrar contraseña
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [parametros,setParametros] = useState(null);

  // mostrar contraseña
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowRepeatPassword = () =>
    setShowRepeatPassword(!showRepeatPassword);
  const handleMouseDownRepeatPassword = () =>
    setShowRepeatPassword(!showRepeatPassword);

  // Radio Button
  const [value, setValue] = useState("1");
  const handleChange = (event) => {
    setValueRegion("");
    setInputValueRegion("");
    setValueComuna("");
    setInputValueComuna("");
    setValue(event.target.value);
    if (event.target.value == "1") {
      setCaptchaEmpresaValido(null);
      setUsuarioEmpresaLocal({
        ...usuarioEmpresaLocal,
        tipo: "1",
        fk_comuna: "",
        fk_region: "",
      });
    } else {
      setCaptchaPersonaValido(null);
      setUsuarioPersonaLocal({
        ...usuarioPersonaLocal,
        tipo: "2",
        fk_comuna: "",
        fk_region: "",
      });
    }
  };

  // select perfil persona
  // Comuna
  const [comuna, setComuna] = useState("");
  const handleChangeComuna = (event) => {
    setComuna(event.target.value);
  };
  // region
  const [region, setRegion] = useState("");
  const handleChangeRegion = (event) => {
    setRegion(event.target.value);
  };

  // select perfil empresa
  // Comuna tributaria
  const [comunaTributaria, setComunaTributaria] = useState("");
  const handleChangeComunaTributaria = (event) => {
    setComunaTributaria(event.target.value);
  };
  // region tributaria
  const [regionTributaria, setRegionTributaria] = useState("");
  const handleChangeRegionTributaria = (event) => {
    setRegionTributaria(event.target.value);
  };

  //  dialog de confrimación de registro
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);


  const prueba = () => {
    setOpen(true)
  }
  const handleClickOpen = () => {
    setErrorsAccount(validateAccount(accountLocal));
    //inciar el loading

    if (value == "1") {
      setErrorsEmpresa(validateEmpresa(usuarioEmpresaLocal));
      //limpiar formulario persona
      setErrorsPersona({
        errorDefault: "Error default",
      });

      if (Object.keys(errorsEmpresa).length === 0 && Object.keys(errorsAccount).length === 0) {
        //envio el formualrio account y empresa
        setLoading(true);
        dispatch(postRegistrarClienteEmpresa({ accountLocal, usuarioEmpresaLocal, captcha:captchaEmpresa.current.getValue()}));
      }
    } else if (value == "2") {
      setErrorsPersona(validatePersona(usuarioPersonaLocal));
      //limpiar formulario empresa
      setErrorsEmpresa({errorDefault: "Error default",});
      if (Object.keys(errorsPersona).length === 0 && Object.keys(errorsAccount).length === 0) {
        //envio el formulario account y usuarioPersonaLocal
        setLoading(true);
        dispatch(postRegistrarClientePersona({ accountLocal, usuarioPersonaLocal, captcha:captchaPersona.current.getValue()}));
      }
    }
  };

  const [response, setResponse] = useState({});

  useEffect(() => {
    if (Object.keys(registroEmpresa).length != 0) {
      setLoading(false)
      setOpen(true);
      setResponse(registroEmpresa);
    } else if (Object.keys(registroPersona).length != 0) {
      setLoading(false)
      setOpen(true);
      setResponse(registroPersona);
    }
  }, [registroEmpresa, registroPersona]);

  const handleClose = () => {
    dispatch(clearRegistros({}));
    setOpen(false);
    history.push("/signin");
  };

  const [regionesLocal, setRegionesLocal] = useState([]);
  const [comunasLocal, setComunasLocal] = useState([]);
  const [valueRegion, setValueRegion] = useState("");
  const [inputValueRegion, setInputValueRegion] = useState("");
  const [valueComuna, setValueComuna] = useState("");
  const [inputValueComuna, setInputValueComuna] = useState("");
  const [comercialesLocal, setComercialesLocal] = useState([]);

  const [accountLocal, setAccountLocal] = useState(account);
  const [errorsAccount, setErrorsAccount] = useState({
    errorDefault: "Error Default",
  });

  const [usuarioPersonaLocal, setUsuarioPersonaLocal] = useState(
    usuarioPersona
  );
  const [errorsPersona, setErrorsPersona] = useState({
    errorDefault: "Error Default",
  });

  const [usuarioEmpresaLocal, setUsuarioEmpresaLocal] = useState(
    usuarioEmpresa
  );
  const [errorsEmpresa, setErrorsEmpresa] = useState({
    errorDefault: "Error Default",
  });

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search);
    let email=queryParameters.get("email");
    let nombre=queryParameters.get("nombre");
    let apellidos=queryParameters.get("apellidos");
    let telefono=queryParameters.get("telefono");
    if(telefono!=null && telefono.length>0){
      telefono=telefono.replace('+569','');debugger;
    }
    
    let comercial=queryParameters.get("ejecutivo");
    if(email==null && nombre==null && apellidos==null && telefono==null){

    }else{
      setParametros({
        email,
        nombre,
        apellidos,
        telefono,
        comercial
      });

      setAccountLocal({...account,
        nombre_contacto:nombre,
        telefono_contacto: telefono,
        email: email
      });

      setUsuarioPersonaLocal({
        ...usuarioPersonaLocal,
        nombre:nombre,
        email:email,
        telefono:telefono
      });

      setUsuarioEmpresaLocal({
        ...usuarioEmpresaLocal,
        razon_social:nombre,
        email:email,
        telefono:telefono
      });
/*
      setErrorsAccount(validateAccount({...account,
        nombre:nombre,
        nombre_contacto:nombre,
        telefono_contacto: telefono,
        email: email
      }));
      setErrorsEmpresa(validateEmpresa({
        ...usuarioEmpresaLocal,
        razon_social:nombre,
        email:email,
        telefono:telefono
      }));
      setErrorsPersona(validatePersona({
        ...usuarioPersonaLocal,
        nombre:nombre,
        email:email,
        telefono:telefono
      }));*/
    }
  }, []);

  useEffect(() => {
    setRegionesLocal(regiones);
  }, [regiones]);

  useEffect(() => {
    setComunasLocal(comunas);
  }, [comunas]);

  useEffect(() => {
    dispatch(getRegionesRegistrarCliente({}));
    dispatch(getComercialesRegistrarCliente());
  }, []);

  useEffect(() => {
    if (comerciales.length > 0) {
       let fk_comercial = 0;

      if(parametros!=null && parametros.comercial && parametros.comercial!=null){
        for(let i=0;i<comerciales.length;i++){
          let nombreCompleto=comerciales[i].nombre+' '+comerciales[i].apellidos;
          if(nombreCompleto.toUpperCase()==parametros.comercial.toUpperCase()){
            fk_comercial=comerciales[i].id;
          }
        }
      }else{
        fk_comercial = asignarComercial();
      }
      
      setUsuarioEmpresaLocal({
        ...usuarioEmpresaLocal,
        fk_comercial: fk_comercial
        //fk_cliente: reserveId.id,
      });
      setUsuarioPersonaLocal({
        ...usuarioPersonaLocal,
        fk_comercial: fk_comercial
        //fk_cliente: reserveId.id,
      });
      setAccountLocal({
        ...accountLocal,
        fk_comercial: fk_comercial
      });
    }
  }, [comerciales]);

  useEffect(() => {
    if (emailIsValid.status) {
      if (emailIsValid.origin === "account") {
        setErrorsAccount({
          ...errorsAccount,
          email: "Este mail ya se encuentra registrado.",
        });
      } else if (emailIsValid.origin === "persona") {
        setErrorsPersona({
          ...errorsPersona,
          email: "Este mail ya se encuentra registrado.",
        });
      } else if (emailIsValid.origin === "empresa") {
        setErrorsEmpresa({
          ...errorsEmpresa,
          email: "Este mail ya se encuentra registrado.",
        });
      } else if (emailIsValid.origin === "rp") {
        setErrorsEmpresa({
          ...errorsEmpresa,
          rp_email: "Este mail ya se encuentra registrado.",
        });
      }
    }
  }, [emailIsValid]);

  useEffect(() => {
    if (Object.keys(reserveId).length > 0) {
      //let item = random_item(comerciales);
      let item = {};
      if (reserveId.fk_comercial >= 2) {
        item = comerciales.find((x) => {
          return x.id === reserveId.fk_comercial;
        });
      } else {
        while (true) {
          item = random_item(comerciales);
          if (item.id != 1) {
            break;
          }
        }
      }

      if (item != null || item != undefined) {
        if (value == "1") {
          setUsuarioEmpresaLocal({
            ...usuarioEmpresaLocal,
            fk_comercial: item.id,
            fk_cliente: reserveId.id,
          });
        } else {
          setUsuarioPersonaLocal({
            ...usuarioPersonaLocal,
            fk_comercial: item.id,
            fk_cliente: reserveId.id,
          });
        }
      }
    }
  }, [reserveId]);

  const onChangeAccount = (e) => {
    const { name, value } = e.target;
    setAccountLocal({
      ...accountLocal,
      [name]: value,
    });
  };

  useEffect(() => {
    if (rutExiste.status) {
      if (rutExiste.origin === "empresa") {
        setErrorsEmpresa({
          ...errorsEmpresa,
          rut: "Este Rut ya se encuentra registrado.",
        });
      } else if (rutExiste.origin === "persona") {
        setErrorsPersona({
          ...errorsPersona,
          rut: "Este Rut ya se encuentra registrado.",
        });
      }
    }
  }, [rutExiste]);

  const onBlurAccount = (e) => {
    setErrorsAccount(validateAccount(accountLocal));
    if(Object.keys(errorsAccount).length === 0){
      dispatch(postDataContact(accountLocal));
    }
    
  };

  const validateAccount = (form) => {
    const {
      nombre_contacto,
      apellido_contacto,
      telefono_contacto,
      email,
      password,
      repetPassword,
    } = form;
    let error = {};
    let rgxValidarEmail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    let rgxMayusculas = /([A-Z])/;
    let rgxMinusculas = /([a-z])/;
    let rgxNumero = /([0-9])/;
    let rgxCaracteresEspeciales = /[\@\#\$\%\^\&\*\(\)\_\+\!\'\"]/;
    let rgxNumeroCaracteres = /.{8,}/;

    if (!nombre_contacto.trim()) {
      error.nombre_contacto = "Campo obligatorio";
    }

    if (!apellido_contacto.trim()) {
      error.apellido_contacto = "Campo obligatorio";
    }

    if (!telefono_contacto.trim()) {
      error.telefono_contacto = "Campo obligatorio";
    } else if (!rgxNumero.test(telefono_contacto)) {
      error.telefono_contacto = "Solo numeros";
    } else if (telefono_contacto.toString().length != 8) {
      error.telefono_contacto = "No es un telefono valido.";
    }

    if (!email.trim()) {
      error.email = "Campo obligatorio";
    } else if (!rgxValidarEmail.test(email)) {
      error.email = "No es un email valido.";
    } else {
      dispatch(validarEmailRegistroCliente({ email: accountLocal.email, origin: "account" }));
    }

    if (!password.trim()) {
      error.password = "Campo obligatorio";
    } else if (!rgxMayusculas.test(password)) {
      error.password = "Debe tener Mayuscula.";
    } else if (!rgxMinusculas.test(password)) {
      error.password = "Debe tener Minuscula.";
    } else if (!rgxNumero.test(password)) {
      error.password = "Debe tener Numeros.";
    } else if (rgxCaracteresEspeciales.test(password)) {
      error.password = "No debe tener @#$%^&*()_+!'\".";
    } else if (!rgxNumeroCaracteres.test(password)) {
      error.password = "Debe ser de minimo 8 caracteres.";
    }

    if (!repetPassword.trim()) {
      error.repetPassword = "Campo obligatorio";
    } else if (password != repetPassword) {
      error.repetPassword = "Las contraseñas deben ser iguales";
    }

    return error;
  };

  const onChangeEmpresa = (e) => {
    e.persist();
    let { name, value } = e.target;

    if (name == "rut" || name == "rp_rut") {
      value = format(value);

      setUsuarioEmpresaLocal({
        ...usuarioEmpresaLocal,
        [name]: value,
      });
    } else {
      setUsuarioEmpresaLocal({
        ...usuarioEmpresaLocal,
        [name]: value,
      });
    }
  };

  const onBlurEmpresa = (e) => {
    setErrorsEmpresa(validateEmpresa(usuarioEmpresaLocal));
  };

  const validateEmpresa = (form) => {
    const {
      rut,
      calle,
      email,
      telefono,
      fk_comuna,
      fk_region,
      giro,
      id,
      numero_depto,
      razon_social,
      rp_apellido,
      rp_email,
      rp_nombre,
      rp_rut,
      rp_telefono,
      rp_tipo,
    } = form;
    let error = {};
    let rgxValidarEmail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    let rgxMayusculas = /([A-Z])/;
    let rgxMinusculas = /([a-z])/;
    let rgxNumero = /([0-9])/;
    let rgxCaracteresEspeciales = /[\@\#\$\%\^\&\*\(\)\_\+\!\'\"]/;
    //let rgxNumeroCaracteres = /{8,8}/;

    if (!rut.trim()) {
      error.rut = "Campo obligatorio";
    } else if (!validate(rut)) {
      error.rut = "No es un rut valido.";
    } else {
      //validar si el rut ya esta registrado
      dispatch(validateRutRegistroCliente({ rut: rut, origin: "empresa" }))
    }

    if (!razon_social.trim()) {
      error.razon_social = "Campo obligatorio";
    }

    if (!giro.trim()) {
      error.giro = "Campo obligatorio";
    } else if (rgxNumero.test(giro)) {
      error.giro = "Solo se admiten letras";
    }

    if (!email.trim()) {
      error.email = "Campo obligatorio";
    } else if (!rgxValidarEmail.test(email)) {
      error.email = "No es un email valido.";
    } else {
      //aqui debe ir la validacion del email 
      dispatch(validarEmailRegistroCliente({ email: email, origin: "empresa" }));
    }

    if (!telefono.trim()) {
      error.telefono = "Campo obligatorio";
    } else if (!rgxNumero.test(telefono)) {
      error.telefono = "Solo numeros";
    } else if (telefono.toString().length != 8) {
      error.telefono = "no es un telefono valido";
    }

    if (!calle.trim()) {
      error.calle = "Campo obligatorio";
    }

    if (!numero_depto.trim()) {
      error.numero_depto = "Campo obligatorio";
    } else if (!rgxNumero.test(numero_depto)) {
      error.telefono = "Solo numeros";
    }

    if (!fk_region.toString().trim()) {
      error.fk_region = "Campo obligatorio";
    }

    if (!fk_comuna.toString().trim()) {
      error.fk_comuna = "Campo obligatorio";
    }

    if (!rp_rut.trim()) {
      error.rp_rut = "Campo obligatorio";
    } else if (!validate(rp_rut)) {
      error.rp_rut = "No es un rut valido.";
    } else {
      //validar si el rut ya esta registrado
    }

    if (!rp_nombre.trim()) {
      error.rp_nombre = "Campo obligatorio";
    }

    if (!rp_apellido.trim()) {
      error.rp_apellido = "Campo obligatorio";
    }

    if (!rp_email.trim()) {
      error.rp_email = "Campo obligatorio";
    } else if (!rgxValidarEmail.test(rp_email)) {
      error.rp_email = "No es un email valido.";
    } else {
      //aqui debe ir la validacion del email 
      dispatch(validarEmailRegistroCliente({ email: rp_email, origin: "rp" }));

    }

    if (!rp_telefono.trim()) {
      error.rp_telefono = "Campo obligatorio";
    } else if (!rgxNumero.test(rp_telefono)) {
      error.rp_telefono = "Solo numeros";
    } else if (rp_telefono.toString().length != 8) {
      error.rp_telefono = "no es un telefono valido";
    }

    if(captchaEmpresaValido === null){
      error.captchaEmpresa = "Campo obligatorio";
    }

    return error;
  };

  const onChangePersona = (e) => {
    let { name, value } = e.target;

    if (name === "rut") {
      value = format(value);
    }

    setUsuarioPersonaLocal({
      ...usuarioPersonaLocal,
      [name]: value,
    });
  };

  const onBlurPersona = (e) => {
    setErrorsPersona(validatePersona(usuarioPersonaLocal));
  };

  const validatePersona = (form) => {
    const {
      rut,
      nombre,
      apellido,
      email,
      telefono,
      calle,
      fk_comuna,
      fk_region,
      id,
      numero_depto,
    } = form;
    let error = {};
    let rgxValidarEmail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    let rgxMayusculas = /([A-Z])/;
    let rgxMinusculas = /([a-z])/;
    let rgxNumero = /([0-9])/;
    let rgxCaracteresEspeciales = /[\@\#\$\%\^\&\*\(\)\_\+\!\'\"]/;
    //let rgxNumeroCaracteres = /{8,8}/;

    if (!rut.trim()) {
      error.rut = "Campo obligatorio";
    } else if (!validate(rut)) {
      error.rut = "No es un rut valido.";
    } else {
      //validar si el rut ya esta registrado
      dispatch(validateRutRegistroCliente({ rut: rut, origin: "persona" }))
    }

    if (!nombre.trim()) {
      error.nombre = "Campo obligatorio";
    } else if (rgxNumero.test(nombre)) {
      error.nombre = "Solo letras.";
    }

    if (!apellido.trim()) {
      error.apellido = "Campo obligatorio";
    } else if (rgxNumero.test(nombre)) {
      error.apellido = "Solo letras.";
    }

    if (!email.trim()) {
      error.email = "Campo obligatorio";
    } else if (!rgxValidarEmail.test(email)) {
      error.email = "No es un email valido.";
    } else {
      //aqui debe ir la validacion del email 
      dispatch(validarEmailRegistroCliente({ email: email, origin: "persona" }));

    }
    if (!telefono.trim()) {
      error.telefono = "Campo obligatorio";
    } else if (!rgxNumero.test(telefono)) {
      error.telefono = "Solo numeros";
    } else if (telefono.toString().length != 8) {
      error.telefono = "No es un telefono valido";
    }

    if (!calle.trim()) {
      error.calle = "Campo obligatorio";
    }

    if (!numero_depto.trim()) {
      error.numero_depto = "Campo obligatorio";
    } else if (!rgxNumero.test(numero_depto)) {
      error.telefono = "Solo numeros";
    }

    if (!fk_region.toString().trim()) {
      error.fk_region = "Campo obligatorio";
    }

    if (!fk_comuna.toString().trim()) {
      error.fk_comuna = "Campo obligatorio";
    }

    console.log(captchaPersonaValido);
    if(captchaPersonaValido === false || captchaPersonaValido === null ){
      error.captchaPersona = "Campo obligatorio";
    }

    return error;
  };

  const random_item = (items) => items[Math.floor(Math.random() * items.length)];
  const asignarComercial = () => {
    let item = {};
    let control = true;
    if(comerciales.length > 0){
      let comercialesZoho = [7, 8, 27, 29, 96, 97]; // [lucylla, elisa, igor, cesar]
      let tempComerciales = comerciales.filter((item) => comercialesZoho.includes(item.id));
      //console.log(tempComerciales);
      /*while (control) {
        item = random_item(tempComerciales);
        if (item.id >= 2 && item.fk_rol === 2) {
          control = false;
        }
      }*/
      item = random_item(tempComerciales);
      return item.id;
    }
    
  };

  const [showTooltip, setShowTooltip] = useState(false);

  //Agregar Captcha
  const captchaEmpresa = useRef(null);
  const [captchaEmpresaValido, setCaptchaEmpresaValido] = useState(null);
  const onChangeCaptchaEmpresa = () => {
   
    if(captchaEmpresa.current.getValue()){
      setCaptchaEmpresaValido(true);
    }else{
      setCaptchaEmpresaValido(false);
    }

  }

  const captchaPersona= useRef(null);
  const [captchaPersonaValido, setCaptchaPersonaValido] = useState(false);
  const onChangeCaptchaPersona = () => {
    if(captchaPersona.current.getValue()){
      setCaptchaPersonaValido(true);
    }else{
      setCaptchaPersonaValido(false);
    }
  }

  
  return (
    <div className="app-login-container">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 1.3 }}
        className="app-registro"
      >
        <div className="app-login-content-registro">
          <div className="align-item-center mb-3">
            <Link to="/signin" className={classes.link}>
              <ArrowBackIcon />
            </Link>
            <h1 className="title-h1 font-weight-500">Crear Cuenta</h1>
          </div>

          <h2 className="title-h5 font-weight-500">
            Para crear tu cuenta necesitamos los siguiente datos
          </h2>
          <div className="border my-4"></div>

          <form className="app-login-form">
            <h4 className={`text font-weight-500 ${classes.titleLogin}`}>
              Datos de Contacto
            </h4>

            <div className="row input-material-ui">
              <div className="col-12 col-md-6 col-lg-6 mb-3">
                <InputLabel className="Label_Input_Format">Nombre</InputLabel>
                <TextField
                  id="nombre_contacto"
                  name="nombre_contacto"
                  placeholder="Ingresa tu nombre"
                  value={accountLocal.nombre}
                  onChange={(e) => onChangeAccount(e)}
                  onBlur={onBlurAccount}
                  error={errorsAccount.nombre_contacto && true}
                  helperText={
                    errorsAccount.nombre_contacto &&
                    errorsAccount.nombre_contacto
                  }
                />
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-3">
                <InputLabel className="Label_Input_Format">Apellido</InputLabel>
                <TextField
                  id="apellido_contacto"
                  name="apellido_contacto"
                  placeholder="Ingresa tu apellido"
                  value={accountLocal.apellido}
                  onChange={(e) => onChangeAccount(e)}
                  onBlur={onBlurAccount}
                  error={errorsAccount.apellido_contacto && true}
                  helperText={
                    errorsAccount.apellido_contacto &&
                    errorsAccount.apellido_contacto
                  }
                />
              </div>

              {/* input */}
              <div className="col-12 col-md-6 col-lg-6 mb-3">
                <InputLabel className="Label_Input_Format">Telefono</InputLabel>
                <TextField
                  id="telefono_contacto"
                  name="telefono_contacto"
                  placeholder="Telefono"
                  type="tel"
                  value={accountLocal.telefono_contacto}
                  onChange={(e) => onChangeAccount(e)}
                  onBlur={onBlurAccount}
                  error={errorsAccount.telefono_contacto && true}
                  helperText={
                    errorsAccount.telefono_contacto &&
                    errorsAccount.telefono_contacto
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        className={classes.number}
                        position="start"
                      >
                        +569
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {/* input */}
              <div className="col-12 col-md-6 col-lg-6 mb-3">
                <InputLabel className="Label_Input_Format">Email</InputLabel>
                <TextField
                  id="email"
                  name="email"
                  placeholder="email@wscargo.cl"
                  type="email"
                  value={accountLocal.email}
                  onChange={(e) => onChangeAccount(e)}
                  onBlur={onBlurAccount}
                  error={errorsAccount.email && true}
                  helperText={errorsAccount.email && errorsAccount.email}
                />
              </div>

              {/* input */}
              <div className="col-12 col-md-6 col-lg-6 mb-3">
                <InputLabel className="Label_Input_Format">
                  Contraseña
                </InputLabel>
                <TextField
                  id="password"
                  name="password"
                  placeholder="Contraseña"
                  type={showPassword ? "text" : "password"}
                  value={accountLocal.password}
                  onChange={(e) => onChangeAccount(e)}
                  onBlur={onBlurAccount}
                  error={errorsAccount.password && true}
                  helperText={errorsAccount.password && errorsAccount.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          //onMouseDown={handleMouseDownPassword}
                          className={classes.iconButtom}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              {/* input */}
              <div className="col-12 col-md-6 col-lg-6">
                <InputLabel className="Label_Input_Format">
                  Repetir Contraseña
                </InputLabel>
                <TextField
                  id="repetPassword"
                  name="repetPassword"
                  placeholder="Repetir Contraseña"
                  type={showRepeatPassword ? "text" : "password"}
                  value={accountLocal.repetPassword}
                  onChange={(e) => onChangeAccount(e)}
                  onBlur={onBlurAccount}
                  error={errorsAccount.repetPassword && true}
                  helperText={
                    errorsAccount.repetPassword && errorsAccount.repetPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowRepeatPassword}
                          //onMouseDown={handleMouseDownRepeatPassword}
                          className={classes.iconButtom}
                        >
                          {showRepeatPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

              <div className="col-12">
                <h3 className="text-small font-weight-500">
                  Tu Contraseña debe tener:
                </h3>
                <div className="align-item-center">
                  <p className="text-small">Mayúscula</p>
                  <span className="slash">/</span>
                  <p className="text-small">Minuscula</p>
                  <span className="slash">/</span>
                  <p className="text-small">Números</p>
                  <span className="slash">/</span>
                  <p className="text-small">Min. 8 caracteres</p>
                </div>
              </div>
            </div>

            {/* Radio button version desktop */}
            {/* <Hidden smDown>
            </Hidden> */}

            <div className="border my-4"></div>
            <h3 className="title-h5 font-weight-500">
              Con estos datos ya puedes comenzar a importar.
            </h3>
            <div className="border my-4"></div>

            <div className="input-radio-button mb-4">
              <div className="input-radio-title">
                <h4 className="text-small font-weight-500">Tipo de cuenta</h4>

                <HtmlTooltip4
                  open={showTooltip}
                  onOpen={() => setShowTooltip(true)}
                  onClose={() => setShowTooltip(false)}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  title={
                    <>
                      <Typography color="inherit">
                        "Seleccione una opción si su importación quedará a
                        nombre de una empresa (razón social de persona jurídica)
                        o su nombre como persona natural. Recuerde que estos
                        datos serán utilizados para procesar su importación. 
                      </Typography>
                      <Typography color="inherit" className="my-3">
                        Si aún no ha creado su empresa, puede ingresar sus datos
                        personales y posteriormente agregar los datos de su
                        empresa.
                      </Typography>
                      <Typography color="inherit">
                        Cualquier duda recuerde que cuenta con su ejecutivo
                        comercial asignado."
                      </Typography>
                    </>
                  }
                >
                  <Button
                    className={classes.ButtonNav}
                    onClick={() => setShowTooltip(!showTooltip)}
                  >
                    <HelpIcon className={classes.icon} />
                  </Button>
                </HtmlTooltip4>
              </div>

              <FormControl className="input-material-ui">
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender1"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Empresa"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Persona"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            {/* Radio button version mobile */}
            {/* <Hidden smUp>
              <div className="border my-4"></div>
              <h4
                className={`text-small font-weight-500 ${classes.titleLogin}`}
              >
                Tipo de cuenta
              </h4>
              <FormControl className="mb-4">
                <RadioGroup
                  row
                  aria-label="gender"
                  name="gender1"
                  value={value}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="1"
                    control={<Radio />}
                    label="Empresa"
                  />
                  <FormControlLabel
                    value="2"
                    control={<Radio />}
                    label="Persona"
                  />
                </RadioGroup>
              </FormControl>
            </Hidden> */}

            {// valor 2, perfil persona
            value === "2" ? (
              <div className="row input-material-ui">
                <div className="col-12">
                  <h4 className={`text font-weight-400 ${classes.titleLogin}`}>
                    Datos del importador
                  </h4>
                </div>

                {/* input */}
                <div className="col-12 col-md-6 col-lg-6 mb-3">
                  <InputLabel className="Label_Input_Format">Rut</InputLabel>
                  <TextField
                    id="rut"
                    name="rut"
                    placeholder="17.678.909-k"
                    value={usuarioPersonaLocal.rut}
                    onChange={(e) => onChangePersona(e)}
                    onBlur={onBlurPersona}
                    error={errorsPersona.rut && true}
                    helperText={errorsPersona.rut && errorsPersona.rut}
                  />
                </div>

                {/* input */}
                <div className="col-12 col-md-6 col-lg-6 mb-3">
                  <InputLabel className="Label_Input_Format">Nombre</InputLabel>
                  <TextField
                    id="nombre"
                    name="nombre"
                    placeholder="Ingresa tu nombre"
                    value={usuarioPersonaLocal.nombre}
                    onChange={(e) => onChangePersona(e)}
                    onBlur={onBlurPersona}
                    error={errorsPersona.nombre && true}
                    helperText={errorsPersona.nombre && errorsPersona.nombre}
                  />
                </div>

                <div className="col-12 col-md-6 col-lg-6 mb-3">
                  <InputLabel className="Label_Input_Format">Apellido</InputLabel>
                  <TextField
                    id="apellido"
                    name="apellido"
                    placeholder="Ingresa tu apellido"
                    value={usuarioPersonaLocal.apellido}
                    onChange={(e) => onChangePersona(e)}
                    onBlur={onBlurPersona}
                    error={errorsPersona.apellido && true}
                    helperText={errorsPersona.apellido && errorsPersona.apellido}
                  />
                </div>

                {/* input */}
                <div className="col-12 col-md-6 col-lg-6 mb-3">
                  <InputLabel className="Label_Input_Format">Email</InputLabel>
                  <TextField
                    id="email"
                    name="email"
                    placeholder="Email"
                    type="email"
                    value={usuarioPersonaLocal.email}
                    onChange={(e) => onChangePersona(e)}
                    onBlur={onBlurPersona}
                    error={errorsPersona.email && true}
                    helperText={errorsPersona.email && errorsPersona.email}
                  />
                </div>

                {/* input */}
                <div className="col-12 col-md-6 col-lg-6">
                  <InputLabel className="Label_Input_Format">
                    Teléfono
                  </InputLabel>
                  <TextField
                    id="telefono"
                    name="telefono"
                    placeholder="90234567"
                    size="small"
                    type="tel"
                    value={usuarioPersonaLocal.telefono}
                    onChange={(e) => onChangePersona(e)}
                    onBlur={onBlurPersona}
                    error={errorsPersona.telefono && true}
                    helperText={
                      errorsPersona.telefono && errorsPersona.telefono
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          className={classes.number}
                          position="start"
                        >
                          +569
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="col-12">
                  <div className="border my-4"></div>
                </div>

                <div className="col-12">
                  <h4 className={`text font-weight-400 ${classes.titleLogin}`}>
                    Dirección del importador
                  </h4>
                </div>

                {/* input */}
                <div className="col-12 col-md-6 col-lg-6 mb-5">
                  <InputLabel className="Label_Input_Format">Región</InputLabel>
                  <Autocomplete
                    id="fk_region"
                    name="fk_region"
                    options={regionesLocal}
                    getOptionLabel={(options) => {
                      return options.nombre;
                    }}
                    value={valueRegion}
                    onChange={(event, newValue) => {
                      setValueRegion(newValue);
                      if (newValue != null && newValue.id != undefined) {
                        setUsuarioPersonaLocal({
                          ...usuarioPersonaLocal,
                          fk_region: newValue.id,
                        });

                        dispatch(
                          getComunasRegistrarCliente({ id: newValue.id })
                        );
                      }
                    }}
                    inputValue={inputValueRegion}
                    onInputChange={(event, inputValueRegion) => {
                      setInputValueRegion(inputValueRegion);
                      if (
                        inputValueRegion != null &&
                        inputValueRegion.id != undefined
                      ) {
                        setUsuarioPersonaLocal({
                          ...usuarioPersonaLocal,
                          fk_region: inputValueRegion.id,
                        });
                      }
                    }}
                    onBlur={onBlurPersona}
                    placeholder="Ingresa tu región"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="Input_Number_Format"
                        placeholder="Ingresa su Región"
                        error={errorsPersona.fk_region && true}
                        helperText={
                          errorsPersona.fk_region && errorsPersona.fk_region
                        }
                      />
                    )}
                  />
                </div>

                {/* input */}
                <div className="col-12 col-md-6 col-lg-6 mb-5">
                  <InputLabel className="Label_Input_Format">Comuna</InputLabel>
                  <Autocomplete
                    id="fk_comuna"
                    name="fk_comuna"
                    options={comunasLocal}
                    getOptionLabel={(options) => {
                      return options.nombre;
                    }}
                    value={valueComuna}
                    disabled={comunasLocal.length > 0 ? false : true}
                    onChange={(event, newValue) => {
                      setValueComuna(newValue);
                      if (newValue != null && newValue.id != undefined) {
                        setUsuarioPersonaLocal({
                          ...usuarioPersonaLocal,
                          fk_comuna: newValue.id,
                        });
                      }
                    }}
                    inputValue={inputValueComuna}
                    onInputChange={(event, inputValueComuna) => {
                      setInputValueComuna(inputValueComuna);
                      if (
                        inputValueComuna != null &&
                        inputValueComuna.id != undefined
                      ) {
                        setUsuarioPersonaLocal({
                          ...usuarioPersonaLocal,
                          fk_comuna: inputValueComuna.id,
                        });
                      }
                    }}
                    onBlur={onBlurPersona}
                    placeholder="Ingresa tu comuna"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className="Input_Number_Format"
                        placeholder="Ingresa su Comuna"
                        error={errorsPersona.fk_comuna && true}
                        helperText={
                          errorsPersona.fk_comuna && errorsPersona.fk_comuna
                        }
                      />
                    )}
                  />
                </div>

                {/* input */}
                <div className="col-12 col-md-6 col-lg-6 mb-3">
                  <InputLabel className="Label_Input_Format">Calle</InputLabel>
                  <TextField
                    id="calle"
                    name="calle"
                    placeholder="Nombre de la calle o avenida"
                    value={usuarioPersonaLocal.calle}
                    onChange={(e) => onChangePersona(e)}
                    onBlur={onBlurPersona}
                    error={errorsPersona.calle && true}
                    helperText={errorsPersona.calle && errorsPersona.calle}
                  />
                </div>

                {/* input */}
                <div className="col-12 col-md-6 col-lg-6 mb-3">
                  <InputLabel className="Label_Input_Format">
                    Número y/o Depto
                  </InputLabel>
                  <TextField
                    id="numero_depto"
                    name="numero_depto"
                    placeholder="Numero o departamento"
                    value={usuarioPersonaLocal.numero_depto}
                    onChange={(e) => onChangePersona(e)}
                    onBlur={onBlurPersona}
                    error={errorsPersona.numero_depto && true}
                    helperText={
                      errorsPersona.numero_depto && errorsPersona.numero_depto
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment
                          className={classes.number}
                          position="start"
                        >
                          #
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                <div className="col-12 mt-2">
                  <ReCAPTCHA
                    ref={captchaPersona}
                    sitekey={envConfig.enviroment.siteKeyCaptcha}
                    onChange={onChangeCaptchaPersona}
                    onBlur={onBlurPersona}
                  />
                  {
                    errorsPersona && errorsPersona.captchaPersona && ( 
                      <div className=" mt-1">
                          <FormGroup>
                            <FormHelperText
                              error={true}
                              >
                              { errorsPersona.captchaPersona &&  errorsPersona.captchaPersona }
                            </FormHelperText>
                          </FormGroup>
                        </div>
                      )
                  }
              </div>


              </div>

            ) : null}

            {// valur 2, perfil empresa
            value === "1" ? (
              <>
                {/* datos tributarios */}
                <div className="row input-material-ui">
                  <div className="col-12">
                    <h4
                      className={`text font-weight-400 ${classes.titleLogin}`}
                    >
                      Datos tributarios
                    </h4>
                  </div>
                  {/* input */}
                  <div className="col-12 col-md-6 col-lg-6 mb-0 mb-md-3">
                    <InputLabel className="Label_Input_Format">Rut</InputLabel>
                    <TextField
                      id="rut"
                      name="rut"
                      placeholder="17.678.909-k"
                      value={usuarioEmpresaLocal.rut}
                      onChange={(e) => onChangeEmpresa(e)}
                      onBlur={onBlurEmpresa}
                      error={errorsEmpresa.rut && true}
                      helperText={errorsEmpresa.rut && errorsEmpresa.rut}
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-md-6 col-lg-6 my-3 mt-md-0">
                    <InputLabel className="Label_Input_Format">
                      Razón Social
                    </InputLabel>
                    <TextField
                      id="razon_social"
                      name="razon_social"
                      placeholder="Describe tu razón social"
                      value={usuarioEmpresaLocal.razon_social}
                      onChange={(e) => onChangeEmpresa(e)}
                      onBlur={onBlurEmpresa}
                      error={errorsEmpresa.razon_social && true}
                      helperText={
                        errorsEmpresa.razon_social && errorsEmpresa.razon_social
                      }
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <InputLabel className="Label_Input_Format">Giro</InputLabel>
                    <TextField
                      id="giro"
                      name="giro"
                      placeholder="Detalle giro"
                      size="small"
                      value={usuarioEmpresaLocal.giro}
                      onChange={(e) => onChangeEmpresa(e)}
                      onBlur={onBlurEmpresa}
                      error={errorsEmpresa.giro && true}
                      helperText={errorsEmpresa.giro && errorsEmpresa.giro}
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <InputLabel className="Label_Input_Format">
                      Email
                    </InputLabel>
                    <TextField
                      id="email"
                      name="email"
                      placeholder="Email"
                      type="email"
                      value={usuarioEmpresaLocal.email}
                      onChange={(e) => onChangeEmpresa(e)}
                      onBlur={onBlurEmpresa}
                      error={errorsEmpresa.email && true}
                      helperText={errorsEmpresa.email && errorsEmpresa.email}
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <InputLabel className="Label_Input_Format">
                      Teléfono
                    </InputLabel>
                    <TextField
                      id="telefono"
                      name="telefono"
                      placeholder="90234567"
                      size="small"
                      type="tel"
                      value={usuarioEmpresaLocal.telefono}
                      onChange={(e) => onChangeEmpresa(e)}
                      onBlur={onBlurEmpresa}
                      error={errorsEmpresa.telefono && true}
                      helperText={
                        errorsEmpresa.telefono && errorsEmpresa.telefono
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            className={classes.number}
                            position="start"
                          >
                            +569
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div className="col-12">
                    <div className="border my-4"></div>
                  </div>

                  <div className="col-12">
                    <h4
                      className={`text font-weight-400 ${classes.titleLogin}`}
                    >
                      Dirección Tributaria
                    </h4>
                  </div>

                  {/* input */}
                  <div className="col-12 col-md-6 col-lg-6 mb-5">
                    <InputLabel className="Label_Input_Format">
                      Región
                    </InputLabel>
                    <Autocomplete
                      id="fk_region"
                      name="fk_region"
                      options={regionesLocal}
                      getOptionLabel={(options) => {
                        return options.nombre;
                      }}
                      value={valueRegion}
                      onChange={(event, newValue) => {
                        setValueRegion(newValue);
                        if (newValue != null && newValue.id != undefined) {
                          setUsuarioEmpresaLocal({
                            ...usuarioEmpresaLocal,
                            fk_region: newValue.id,
                          });

                          dispatch(
                            getComunasRegistrarCliente({ id: newValue.id })
                          );
                        }
                      }}
                      inputValue={inputValueRegion}
                      onInputChange={(event, inputValueRegion) => {
                        setInputValueRegion(inputValueRegion);
                        if (
                          inputValueRegion != null &&
                          inputValueRegion.id != undefined
                        ) {
                          setUsuarioEmpresaLocal({
                            ...usuarioEmpresaLocal,
                            fk_region: inputValueRegion.id,
                          });
                        }
                      }}
                      onBlur={onBlurEmpresa}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="Input_Number_Format"
                          onBlur={onBlurEmpresa}
                          placeholder="Ingresa su región"
                          error={errorsEmpresa.fk_region && true}
                          helperText={
                            errorsEmpresa.fk_region && errorsEmpresa.fk_region
                          }
                        />
                      )}
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-md-6 col-lg-6 mb-5">
                    <InputLabel className="Label_Input_Format">
                      Comuna
                    </InputLabel>
                    <Autocomplete
                      id="fk_comuna"
                      name="fk_comuna"
                      options={comunasLocal}
                      getOptionLabel={(options) => {
                        return options.nombre;
                      }}
                      value={valueComuna}
                      disabled={comunasLocal.length > 0 ? false : true}
                      onChange={(event, newValue) => {
                        setValueComuna(newValue);
                        if (newValue != null && newValue.id != undefined) {
                          setUsuarioEmpresaLocal({
                            ...usuarioEmpresaLocal,
                            fk_comuna: newValue.id,
                          });
                        }
                      }}
                      inputValue={inputValueComuna}
                      onInputChange={(event, inputValueComuna) => {
                        setInputValueComuna(inputValueComuna);
                        if (
                          inputValueComuna != null &&
                          inputValueComuna.id != undefined
                        ) {
                          setUsuarioEmpresaLocal({
                            ...usuarioEmpresaLocal,
                            fk_comuna: inputValueComuna.id,
                          });
                        }
                      }}
                      onBlur={onBlurEmpresa}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className="Input_Number_Format"
                          error={errorsEmpresa.fk_comuna && true}
                          placeholder="Ingresa su Comuna"
                          helperText={
                            errorsEmpresa.fk_comuna && errorsEmpresa.fk_comuna
                          }
                        />
                      )}
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <InputLabel className="Label_Input_Format">
                      Calle
                    </InputLabel>
                    <TextField
                      id="calle"
                      name="calle"
                      placeholder="Nombre de la calle o avenida"
                      value={usuarioEmpresaLocal.calle}
                      onChange={(e) => onChangeEmpresa(e)}
                      onBlur={onBlurEmpresa}
                      error={errorsEmpresa.calle && true}
                      helperText={errorsEmpresa.calle && errorsEmpresa.calle}
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-md-6 col-lg-6">
                    <InputLabel className="Label_Input_Format">
                      Número / Depto
                    </InputLabel>
                    <TextField
                      id="numero_depto"
                      name="numero_depto"
                      placeholder="Numero o departamento"
                      value={usuarioEmpresaLocal.numero_depto}
                      onChange={(e) => onChangeEmpresa(e)}
                      onBlur={onBlurEmpresa}
                      error={errorsEmpresa.numero_depto && true}
                      helperText={
                        errorsEmpresa.numero_depto && errorsEmpresa.numero_depto
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            className={classes.number}
                            position="start"
                          >
                            #
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>

                  <div className="col-12">
                    <div className="border my-4 "></div>
                  </div>
                </div>

                {/* representante legal */}
                <div className="row input-material-ui">
                  <div className="col-12">
                    <h4
                      className={`text font-weight-400 ${classes.titleLogin}`}
                    >
                      Representante legal
                    </h4>
                  </div>
                  {/* input */}
                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <InputLabel className="Label_Input_Format">Rut</InputLabel>
                    <TextField
                      id="rp_rut"
                      name="rp_rut"
                      placeholder="17.678.909-k"
                      value={usuarioEmpresaLocal.rp_rut}
                      onChange={(e) => onChangeEmpresa(e)}
                      onBlur={onBlurEmpresa}
                      error={errorsEmpresa.rp_rut && true}
                      helperText={errorsEmpresa.rp_rut && errorsEmpresa.rp_rut}
                    />
                  </div>

                  {/* input  */}
                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <InputLabel className="Label_Input_Format">
                      Nombre
                    </InputLabel>
                    <TextField
                      id="rp_nombre"
                      name="rp_nombre"
                      placeholder="Nombre del representante legal"
                      value={usuarioEmpresaLocal.rp_nombre}
                      onChange={(e) => onChangeEmpresa(e)}
                      onBlur={onBlurEmpresa}
                      error={errorsEmpresa.rp_nombre && true}
                      helperText={
                        errorsEmpresa.rp_nombre && errorsEmpresa.rp_nombre
                      }
                    />
                  </div>

                  {/* input  */}
                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <InputLabel className="Label_Input_Format">
                      Apellido
                    </InputLabel>
                    <TextField
                      id="rp_apellido"
                      name="rp_apellido"
                      placeholder="Nombre del representante legal"
                      value={usuarioEmpresaLocal.rp_apellido}
                      onChange={(e) => onChangeEmpresa(e)}
                      onBlur={onBlurEmpresa}
                      error={errorsEmpresa.rp_apellido && true}
                      helperText={
                        errorsEmpresa.rp_apellido && errorsEmpresa.rp_apellido
                      }
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <InputLabel className="Label_Input_Format">
                      Email
                    </InputLabel>
                    <TextField
                      id="rp_email"
                      name="rp_email"
                      placeholder="Email@escargo.cl"
                      type="email"
                      value={usuarioEmpresaLocal.rp_email}
                      onChange={(e) => onChangeEmpresa(e)}
                      onBlur={onBlurEmpresa}
                      error={errorsEmpresa.rp_email && true}
                      helperText={
                        errorsEmpresa.rp_email && errorsEmpresa.rp_email
                      }
                    />
                  </div>

                  {/* input */}
                  <div className="col-12 col-md-6 col-lg-6 mb-3">
                    <InputLabel className="Label_Input_Format">
                      Teléfono
                    </InputLabel>
                    <TextField
                      id="rp_telefono"
                      name="rp_telefono"
                      placeholder="90234567"
                      size="small"
                      type="tel"
                      value={usuarioEmpresaLocal.rp_telefono}
                      onChange={(e) => onChangeEmpresa(e)}
                      onBlur={onBlurEmpresa}
                      error={errorsEmpresa.rp_telefono && true}
                      helperText={
                        errorsEmpresa.rp_telefono && errorsEmpresa.rp_telefono
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            className={classes.number}
                            position="start"
                          >
                            +569
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className="col-12 mt-2">
                    <ReCAPTCHA
                      ref={captchaEmpresa}
                      sitekey={envConfig.enviroment.siteKeyCaptcha}
                      onChange={onChangeCaptchaEmpresa}
                      onBlur={onBlurEmpresa}
                    />
                    {
                      errorsEmpresa && errorsEmpresa.captchaEmpresa && ( 
                        <div className="mt-1">
                          <FormGroup>
                            <FormHelperText
                              error={true}
                              >
                              { errorsEmpresa.captchaEmpresa && errorsEmpresa.captchaEmpresa }
                            </FormHelperText>
                          </FormGroup>
                        </div>
                      )
                    }
                  </div>
                </div>
              </>
            ) : null}
            {/* <div className="border my-4"></div> */}

            <Button
              disabled={loading ? true : false}
              onClick={() => {
                handleClickOpen();
              }}
              className={`button button-outline-danger button-100-mobile mt-4 mb-0 mb-md-5`}
            >
              {loading ? (
                <CircularProgress color="secondary" />
              ) : (
                "Crear Cuenta"
              )}
            </Button>
          </form>
        </div>
      </motion.div>

      <div className="app-logo-content justify-center">
        <Link className="logo-lg" to="/" title="Jambo">
          <img
            src={require("../assets/images/wscargologo.png")}
            alt="jambo"
            title="jambo"
          />
        </Link>
      </div>

      {/* dialog de confirmación para la creación de usuario */}
      {/* <Message 
        title={response.status} 
        content={response.message}
        action
        open={open}
        onClose={handleClose}
        icon="iconSuccess"
        response={response}
        
      /> */}

      <Message
        title="prueba"
        content="conetndiog jajajaa"
        open={open}
        onClose={handleClose}
        icon="iconSuccess"
        className={classes.dialogStyle}
        response={response}
      />

      {response.status == "success" ? (
        <Message
          title="¡Listo!"
          content="Tu cuenta ha sido creada con éxito, revise su bandeja de entrada de tu correo para activarla."
          open={open}
          onClose={handleClose}
          icon="iconSuccess"
          className={classes.dialogStyle}
          response={response}
        />
      ) : (
        <Message
          title="¡Atencion!"
          content="Lo sentimos, en estos momentos nos encontramos con problemas, vuelve a interlar más tarde."
          open={open}
          onClose={handleClose}
          icon="alertDanger"
          className={classes.dialogStyle}
          response={response}
        />
      )}

      {/* 
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialogStyle}
        response={response}
      >
        <DialogContent className="mt-3">
          <DialogContentText>
            <Avatar
              alt="Remy Sharp"
              src={imgBox}
              className={classes.avatarXxl}
            />
          </DialogContentText>
        </DialogContent>

        <DialogContent className="text-center mb-3">
          <DialogContentText className={classes.titleDialog}>
            {response.status == "success" ? (
              <>¡Listo!</>
            ) : (
              <>¡Atencion </>
            )}
          </DialogContentText>
          <DialogContentText className="px-0 px-md-5">
            {response.message && response.message}
          </DialogContentText>
        </DialogContent>

        <DialogActions className="mb-3">
          <Button className="button button-danger" onClick={handleClose}>
            ok
          </Button>
        </DialogActions>
      </Dialog> */}
    </div>
  );
};

export default SignUp;
