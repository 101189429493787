import {all,fork,call, put,takeLatest,spawn} from "redux-saga/effects";
import {
    postRequest,
    getRequest,
    getErrorRequest,
    deleteRequest,
    putRequest
} from '../../util/apiFetch';

import{
    GET_TYPES_NOTIFICATIONS,
    GET_GLOBAL_LIST_NOTIFICATIONS,
    GET_LIST_BY_USER_NOTIFICATIONS,
    POST_USER_NOTIFICATIONS,
    GET_USER_ALL_NOTIFICATIONS,
    PUT_VISTAS_NOTIFICATIONS
} from './Constants';

import{
    getTypesNotificationsSuccess,
    getGlobalListNotificationsSuccess,
    getListByUserNotificationsSuccess,
    getUserAllNotificationsSuccess
} from './Actions';



function* getSagaTypesNotificationsExecute({payload}){
    const requestURL = `notificaciones/tipos`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageCotizadorCliente({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getTypesNotificationsSuccess(obj));
    }
}

function* getSagaGlobalListNotificationsExecute({payload}){
    const requestURL = `notificaciones`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageCotizadorCliente({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getGlobalListNotificationsSuccess(obj));
    }
}

function* getSagaListByUserNotificationsExecute(){
    const requestURL = `notificaciones/configuracion`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageCotizadorCliente({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getListByUserNotificationsSuccess(obj));
    }
}

function* postSagaUserNotificationsExecute({payload}){
    const requestURL = `notificaciones/configuracion`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageCotizadorCliente({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield getSagaListByUserNotificationsExecute();
    }
}

function* getSagaUserAllNotificationsExecute({payload}){
    const requestURL = `notificaciones/usuario/${payload.fk_usuario}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageCotizadorCliente({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getUserAllNotificationsSuccess(obj));
    }
}

function* putSagaVistasNotificationsExecute({payload}){
    const requestURL = `notificaciones/usuario/${payload.fk_usuario}`;
    const response= yield call(putRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageCotizadorCliente({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield getSagaUserAllNotificationsExecute({payload:{fk_usuario:payload.fk_usuario}});
        //yield put(getUserAllNotificationsSuccess(obj));
    }
}




export function* getSagaTypesNotifications(){
    yield takeLatest(GET_TYPES_NOTIFICATIONS, getSagaTypesNotificationsExecute);
}

export function* getSagaGlobalListNotifications(){
    yield takeLatest(GET_GLOBAL_LIST_NOTIFICATIONS, getSagaGlobalListNotificationsExecute);
}

export function* getSagaListByUserNotifications(){
    yield takeLatest(GET_LIST_BY_USER_NOTIFICATIONS, getSagaListByUserNotificationsExecute);
}

export function* postSagaUserNotifications(){
    yield takeLatest(POST_USER_NOTIFICATIONS, postSagaUserNotificationsExecute);
}

export function* getSagaUserAllNotifications(){
    yield takeLatest(GET_USER_ALL_NOTIFICATIONS, getSagaUserAllNotificationsExecute);
}

export function* putSagaVistasNotifications(){
    yield takeLatest(PUT_VISTAS_NOTIFICATIONS, putSagaVistasNotificationsExecute);
}

export default function* rootSaga() {
    yield all([
        fork(getSagaTypesNotifications),
        fork(getSagaGlobalListNotifications),
        fork(getSagaListByUserNotifications),
        fork(postSagaUserNotifications),
        fork(getSagaUserAllNotifications),
        fork(putSagaVistasNotifications)
    ]);
}