export const GLOBAL_CHANGE_STATES_COTIZADOR_CLIENTE='GLOBAL_CHANGE_STATES_COTIZADOR_CLIENTE';
export const POST_COTIZADOR_CLIENTE='POST_COTIZADOR_CLIENTE';
export const POST_COTIZADOR_CLIENTE_SUCCESS='POST_COTIZADOR_CLIENTE_SUCCESS';
export const GET_COMERCIALES_COTIZADOR_CLIENTE='GET_COMERCIALES_COTIZADOR_CLIENTE';
export const GET_COMERCIALES_COTIZADOR_CLIENTE_SUCCESS='GET_COMERCIALES_COTIZADOR_CLIENTE_SUCCESS';
export const GET_PROPUESTA_BASE_COTIZADOR_CLIENTE='GET_PROPUESTA_BASE_COTIZADOR_CLIENTE';
export const GET_PROPUESTA_BASE_COTIZADOR_CLIENTE_SUCCESS='GET_PROPUESTA_BASE_COTIZADOR_CLIENTE_SUCCESS';
export const GET_REGIONES_COTIZADOR_CLIENTE='GET_REGIONES_COTIZADOR_CLIENTE';
export const GET_REGIONES_COTIZADOR_CLIENTE_SUCCESS='GET_REGIONES_COTIZADOR_CLIENTE_SUCCESS';
export const GET_COMUNAS_COTIZADOR_CLIENTE='GET_COMUNAS_COTIZADOR_CLIENTE';
export const GET_COMUNAS_COTIZADOR_CLIENTE_SUCCESS='GET_COMUNAS_COTIZADOR_CLIENTE_SUCCESS';
export const POST_ID_CLIENTE_COTIZADOR_CLIENTE='POST_ID_CLIENTE_COTIZADOR_CLIENTE';
export const POST_ID_CLIENTE_COTIZADOR_CLIENTE_SUCCESS='POST_ID_CLIENTE_COTIZADOR_CLIENTE_SUCCESS';
export const SHOW_MESSAGE_COTIZADOR_CLIENTE='SHOW_MESSAGE_COTIZADOR_CLIENTE';
export const HIDE_MESSAGE_COTIZADOR_CLIENTE='SHOW_MESSAGE_COTIZADOR_CLIENTE';
export const GET_EXISTS_USER_COTIZADOR_CLIENTE='GET_EXISTS_USER_COTIZADOR_CLIENTE';
export const GET_EXISTS_USER_COTIZADOR_CLIENTE_SUCCESS='GET_EXISTS_USER_COTIZADOR_CLIENTE_SUCCESS';
export const GET_ZONAS_TARIFARIAS_COTIZADOR_CLIENTE='GET_ZONAS_TARIFARIAS_COTIZADOR_CLIENTE';
export const GET_ZONAS_TARIFARIAS_COTIZADOR_CLIENTE_SUCCESS='GET_ZONAS_TARIFARIAS_COTIZADOR_CLIENTE_SUCCESS';
export const VALIDATE_RUT_COTIZADOR='VALIDATE_RUT_COTIZADOR';
export const VALIDATE_RUT_COTIZADOR_SUCCESS='VALIDATE_RUT_COTIZADOR_SUCCESS';
export const DATA_COTIZACION = 'DATA_COTIZACION';

export const CLEAR_ALL_EXPDIG_COTIZADOR_SUCCESS = 'CLEAR_ALL_EXPDIG_COTIZADOR_SUCCESS';