import React from 'react'
import { motion } from "framer-motion"


const CardThree = ({hideMap}) => {

    return (
        <>
            { hideMap ? 
                <motion.div 
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: .3, duration: 1.1}}
                    className="app-maps hide">
                </motion.div> : 
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ type:'spring', delay: .3, duration: 1}}
                    className="app-maps"
                >
                </motion.div>}
        </>
    )
}

export default CardThree