import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import Box from '@material-ui/core/Box';
import CheckIcon from '@material-ui/icons/Check';
import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Button,
  DialogTitle
} from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
    Home_SetMostrarModalPagarServicio
    , Home_RealizarPagoServicio
    , Home_SetMostrarLoadingPagos
  } from '../Actions';

  import {
Tck_SetMostrarModalPagarServicio
} from '../../../../src/actions/Track.js';

import { width } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import lodash from 'lodash';
import Cargando from '../../../components/loading/Loading';
import HtmlParser from "react-html-parser";
import { FINDETAIL_SetMostrarModalPagarServicio } from "modules/detalleServicio/Actions";

const useStyles = makeStyles((theme) => ({
  iconSuccess: {
      width: "80px",
      height: "80px",
      border: "solid 3px #6FCF97",
      color: "#6FCF97",
      margin: "0 auto",
      borderRadius: "100%",
      fontsize: "400px",

      "& .MuiSvgIcon-root": {
        width: "48px",
        height: "48px",
      }
  },
  iconDanger: {
    width: "80px",
    height: "80px",
    border: "solid 3px #EA5259",
    color: "#EA5259",
    margin: "0 auto",
    borderRadius: "100%",
    fontsize: "400px",

    "& .MuiSvgIcon-root": {
      width: "48px",
      height: "48px",
    }
},
}));

const ModalPagarServicio = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const {
    EnviarPagoServicio
    , MostrarLoadingPagos
} = useSelector(({ Dashboard }) => Dashboard);

  const SesionUsuario = JSON.parse(localStorage.getItem('userSession'));
  const [OpenModalPagarServicio, SetOpenModalPagarServicio] = useState(props.OpenModalServicio)
  const [ValorPagar, SetValorPagar] = useState(0);
  const [IdPagarNc, SetIdPagarNc] = useState(0);
  const [IdPagarCarpeta, SetIdPagarCarpeta] = useState('');
  const [ValorPagarInicial, SetValorPagarInicial] = useState(0);
  const [ValorPagarFinal, SetValorPagarFinal] = useState(0);
  const [ValorComision, SetValorComision] = useState(0);
  const [ValorComisionNeto, SetValorComisionNeto] = useState(0);
  const [ValorComisionIva, SetValorComisionIva] = useState(0);
  const [ValorComisionFijo, SetValorComisionFijo] = useState(0);
  const [ValorComisionFijoIva, SetValorComisionFijoIva] = useState(0);
  const [ComisionMedioPago, SetComisionMedioPago] = useState(0);
  const [TextoMedioPago, SetTextoMedioPago] = useState('');
  const [BordeKhipu, SetBordeKhipu] = useState('');
  const [BordeWebpay, SetBordeWebpay] = useState('');
  const [UsuarioRut, SetUsuarioRut] = useState('');
  const [MensajeDeErrorRut, SetMensajeDeErrorRut] = useState('');
  const [UsuarioEmail, SetUsuarioEmail] = useState('');
  const [UsuarioId, SetUsuarioId] = useState('0');
  const [MensajeDeError, SetMensajeDeError] = useState('');
  const [Loading, SetLoading] = useState(MostrarLoadingPagos);
  const [TextoComision, SetTextoComision] = useState('');
  const [TextoNoCerrar, SetTextoNoCerrar] = useState('');
  const [TextoComisionSelect, SetTextoComisionSelect] = useState('Seleccionar');
  const [MostrarComisionSelect, SetMostrarComisionSelect] = useState('');
  const [MostrarOpcionDebito, SetMostrarOpcionDebito] = useState(false);
  const [MostrarOpcionCredito, SetMostrarOpcionCredito] = useState(false);
  
  const [openImgKhipu, setOpenImgKhipu] = React.useState(false);

  const handleCloseImgKhipu = () => {
      setOpenImgKhipu(false);
  };

  useEffect(() => {
    if (props.info.length>0)
    {
        if(SesionUsuario!==null)
        {
            SetUsuarioEmail((SesionUsuario.usuario.email).toString());
            SetUsuarioId((SesionUsuario.usuario.id).toString());
        }
        SetIdPagarNc(props.info[0].nc_id);
        SetIdPagarCarpeta(props.info[0].carpeta);
        SetValorPagar(Math.round(props.info[0].total_a_pagar));
        SetValorPagarInicial(Math.round(props.info[0].total_a_pagar));
        SetValorPagarFinal(Math.round(props.info[0].total_a_pagar));
        SetUsuarioRut(props.info[0].cli_rut);
        SetUsuarioEmail(props.info[0].cli_email);
    }
    else
    {
        SetValorPagar(0);
        SetValorPagarInicial(0);
        SetValorPagarFinal(0);
    }

  }, [props])

  
  useEffect(() => {

    SetLoading(MostrarLoadingPagos);

  }, [MostrarLoadingPagos])

  useEffect(() => {

    CalcularTotalPagar();

  }, [ComisionMedioPago])

  useEffect(() => {

    CalcularTotalPagar();

  }, [ValorPagar])

  const formatMiles = (input) => {
    var num = input.toString().replace(/\./g, '');
    var formato = ""
    if (!isNaN(num)) {
      num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
      num = num.split('').reverse().join('').replace(/^[\.]/, '');
      formato = num;
    }
    else
    {
      formato = formato.replace(/[^\d\.]*/g, '');
    }

    return formato;
  }

  const OnChangeValorPagar = (event) => {
    var value = event.target.value;
    value = value.toString().replace(/\./g, '');
    console.log('SET EL VALOR A PAGAR '+value);
    SetValorPagar(value);
  }

    const OnChangeRut = (event) => {
        
        var rut = event.target.value;
        
        if (validarRut(rut)) 
        {
            var rutFormateado = formatearRut(rut);
            SetUsuarioRut(rutFormateado);
            SetMensajeDeErrorRut('');
        } 
        else 
        {
            var rutFormateado = formatearRut(rut);
            SetUsuarioRut(rutFormateado);
            SetMensajeDeErrorRut('Debe ingresar un rut válido');
        }
    }

    

    function validarRut(rut) {
        rut = rut.replace(/\./g, '');
        console.log('rut 1 '+rut);
        rut = rut.replace(/\-/g, '');
        console.log('rut 2 '+rut);
    
        var cuerpo = rut.slice(0, -1);
        var dv = rut.slice(-1).toUpperCase();
    
        var suma = 0;
        var multiplo = 2;
    
        for (var i = cuerpo.length - 1; i >= 0; i--) {
            suma += parseInt(cuerpo.charAt(i)) * multiplo;
            multiplo = multiplo === 7 ? 2 : multiplo + 1;
        }
    
        var resultado = suma % 11 === 0 ? 0 : 11 - (suma % 11);
    
        if(dv.toUpperCase()==='K')
        {
            dv = 10;
        }

        return resultado === parseInt(dv);
    }
    
    function formatearRut(rut) {
        rut = rut.replace(/\./g, "");
        rut = rut.replace(/-/g, "");
    
        var cuerpo = rut.slice(0, -1);
        var dv = rut.slice(-1).toUpperCase();
    
        cuerpo = cuerpo.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    
        return cuerpo + "-" + dv;
    }
    
       
    

  const OnChangeEmail = (event) => {
    var value = event.target.value;
    SetUsuarioEmail(value);
  }

  function CalcularTotalPagar()
  {
    var ComisionMedioPagoAux = ComisionMedioPago;
    var ValorPagarAux = ValorPagar;
    var ValorComisionNetoAux = 0;
    var ValorComisionIvaAux = 0;
    var ValorComisionFijoAux = 0;
    var ValorComisionFijoIvaAux = 0;
    var ValorComisionTotalAux = 0;
    var ValorTotalPagarAux = 0;

    if(Number(ComisionMedioPago)!==Number(0) || Number(ComisionMedioPago)>0)
    {
        /* 61 */ 
        ValorComisionFijoAux = Math.round(ValorComisionFijo);
        
        ValorComisionFijoIvaAux = Math.round(ValorComisionFijoAux*0.19);

        /* ( (100 + 61) * 1.8 ) / 100 */
        ValorComisionNetoAux = Math.round(( (Number(ValorPagarAux)+Number(ValorComisionFijoAux)) * ComisionMedioPagoAux)/100);

        /* NETO COMISION * 0.19 */
        ValorComisionIvaAux = Math.round(ValorComisionNetoAux*0.19);

        /* COMISION TOTAL NETO + IVA */
        ValorComisionTotalAux = Math.round(Number(ValorComisionFijo)+Number(ValorComisionFijoIvaAux)+Number(ValorComisionNetoAux)+Number(ValorComisionIvaAux));

        /* 100 + TOTAL CON IVA DE COMISION */ 
        ValorTotalPagarAux = Math.round(Number(ValorPagarAux)+Number(ValorComisionTotalAux));

        SetValorComisionFijoIva(ValorComisionFijoIvaAux);
        SetValorComision(ValorComisionTotalAux);
        SetValorComisionIva(ValorComisionIvaAux);
        SetValorComisionNeto(ValorComisionNetoAux)
        SetValorPagarFinal(ValorTotalPagarAux);
    }
    else
    {
        ValorTotalPagarAux = ValorPagarAux;
        SetValorComision(ValorComisionTotalAux);
        SetValorComisionIva(ValorComisionIvaAux);
        SetValorComisionNeto(ValorComisionNetoAux)
        SetValorPagarFinal(ValorTotalPagarAux);
        SetValorComisionFijoIva(ValorComisionFijoIvaAux);
    }

    console.log('');
    console.log('');
    console.log('INICIAL '+ValorPagarInicial);
    console.log('PAGAR '+ValorPagarAux);
    console.log('COMISION % '+ComisionMedioPagoAux);
    console.log('COMISION VALOR FIJO '+ValorComisionFijoAux);
    console.log('COMISION VALOR FIJO IVA '+ValorComisionFijoIvaAux);
    console.log('COMISION VALOR NETO '+ValorComisionNetoAux);
    console.log('COMISION VALOR IVA '+ValorComisionIvaAux);
    console.log('COMISION VALOR TOTAL '+ValorComisionTotalAux);
    console.log('TOTAL FINAL '+ValorTotalPagarAux);
  }

  function DefinirPago(Metodo)
  {
    var MostrarComisionSelectAux = false;
    var TextoComisionAux = '';
    var ComisionMedioPagoAux = 0;
    var TextoMedioPagoAux = '';
    var ValorComisionFijoAux = 0;

    if(Metodo==='Khipu')
    {
        setOpenImgKhipu(true);
        SetTextoNoCerrar(`
        <span className="mt-5">Al momento de realizar el pago con la plataforma de KHIPU es recomendable que no cierre la página de <strong>KHIPU</strong> hasta que se valide la transferencia.</span>
        <br></br>
        <span className="mt-5">Recuerde que en la plataforma de <strong>KHIPU</strong> debe ingresar el número de <strong>RUT</strong> de la cuenta bancaria desde donde ustede desea hacer a transferencia.</span>
        `)
        TextoMedioPagoAux = 'KHIPU';
        
        if( props.info[0].kip_com_deb>0 && props.info[0].kip_com_deb>=props.info[0].kip_com_cre )
        {
            ComisionMedioPagoAux = props.info[0].kip_com_deb;
        }
        else if( props.info[0].kip_com_cre>0 && props.info[0].kip_com_cre>=props.info[0].kip_com_deb )
        {
            ComisionMedioPagoAux = props.info[0].kip_com_cre;
        }
        
        if( props.info[0].kip_com_deb_fij>0 && props.info[0].kip_com_deb_fij>=props.info[0].kip_com_cre_fij )
        {
            ValorComisionFijoAux = props.info[0].kip_com_deb_fij;
        }
        else if( props.info[0].kip_com_cre_fij>0 && props.info[0].kip_com_cre_fij>=props.info[0].kip_com_deb_fij )
        {
            ValorComisionFijoAux = props.info[0].kip_com_cre_fij;
        }

        SetBordeKhipu('5px solid #FC565C');
        SetBordeWebpay('');
    }
    else if(Metodo==='Webpay')
    {
        SetTextoNoCerrar('');
        TextoMedioPagoAux = 'WEBPAY';

        if( props.info[0].web_com_deb>0 && props.info[0].web_com_deb>=props.info[0].web_com_cre )
        {   
            ComisionMedioPagoAux = props.info[0].web_com_deb;
        }
        else if( props.info[0].web_com_cre>0 && props.info[0].web_com_cre>=props.info[0].web_com_deb )
        {
            ComisionMedioPagoAux = props.info[0].web_com_cre;
        }
        
        if( props.info[0].web_com_deb_fij>0 && props.info[0].web_com_deb_fij>=props.info[0].web_com_cre_fij )
        {
            ValorComisionFijoAux = props.info[0].web_com_deb_fij;
        }
        else if( props.info[0].web_com_cre_fij>0 && props.info[0].web_com_cre_fij>=props.info[0].web_com_deb_fij )
        {
            ValorComisionFijoAux = props.info[0].web_com_cre_fij;
        }

        SetBordeWebpay('5px solid #FC565C');
        SetBordeKhipu('');
    }
    else
    {
        SetComisionMedioPago(0);
        SetBordeWebpay('');
        SetBordeKhipu('');
        SetTextoMedioPago('');
        SetTextoComision('');
    }

    if( MostrarComisionSelectAux===false )
    {
        SetComisionMedioPago(ComisionMedioPagoAux);
    }

    if(ComisionMedioPagoAux!==0 && ValorComisionFijoAux!==0)
    {
        TextoComisionAux += '<span className="mt-5">Tiene una comisión de ( fija de $'+ValorComisionFijoAux+' + el '+ComisionMedioPagoAux+'% ) + iva</span><br>';
    }
    else if(ComisionMedioPagoAux!==0)
    {
        TextoComisionAux += '<span className="mt-5">Tiene una comisión del '+ComisionMedioPagoAux+'% + iva</span><br>';
    }

    console.log('COMISION FIJA DEFINIDA '+ValorComisionFijoAux);
    SetValorComisionFijo(ValorComisionFijoAux);
    SetComisionMedioPago(ComisionMedioPagoAux);
    SetTextoComision(TextoComisionAux);
    SetTextoMedioPago(TextoMedioPagoAux);
  }

  function CancelarPago()
  {
    SetIdPagarNc(0);
    SetIdPagarCarpeta('');
    SetOpenModalPagarServicio(false);
    SetValorPagar(0);
    SetValorPagarInicial(0);
    SetValorPagarFinal(0);
    SetValorComision(0);
    SetComisionMedioPago(0);
    SetTextoMedioPago(0);
    SetBordeKhipu('');
    SetBordeWebpay('');
    dispatch(FINDETAIL_SetMostrarModalPagarServicio(false));
    dispatch(Home_SetMostrarModalPagarServicio(false));
    dispatch(Tck_SetMostrarModalPagarServicio(false));
    SetLoading(false);
  }

    function ValidarCorreo() {
        // Expresión regular para validar el formato de correo electrónico
        var formatoCorreo = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Validar el formato del correo
        if (formatoCorreo.test(UsuarioEmail)) {
        return true;
        } else {
        return false;
        }
    }

    function RealizarPago()
    {
        var Error = '';

        if( MensajeDeErrorRut.length>0 ) { Error += '\n* Debe ingresar un rut válido.';}
        if( Number(ValorPagarInicial)<Number(ValorPagar) ) { Error += '\n* No puede ingresar un monto mayor al monto pendiente de pago.'; }
        if( TextoMedioPago==='' ) { Error += '\n* Debe seleccionar un medio de pago.'; }
        if( MostrarComisionSelect===true && TextoComisionSelect==='Seleccionar' ) { Error += '\n* Debe seleccionar un tipo de pago.'; }
        if( IdPagarNc.length<=0 ) { Error += '\n* #405 - No se detecto una nota de cobro asociada.'; }
        if( IdPagarCarpeta.length<=0 ) { Error += '\n* #406 - No se detecto una carpeta asociada.'; }
        if( Number(ComisionMedioPago)>0 && Number(ValorPagar)===Number(ValorPagarFinal) ) { Error += '\n* #407 - No se logro calcular bien la comision del medio de pago, por favor actualizar la pagina.'; }

        if(Error!=='')
        {
            SetMensajeDeError(Error);
        }
        else
        {
            dispatch(Home_SetMostrarLoadingPagos(true));

            let payloadFinal = lodash.cloneDeep(EnviarPagoServicio);
            payloadFinal.Nc = IdPagarNc;
            payloadFinal.Carpeta = IdPagarCarpeta;
            payloadFinal.MedioPago = TextoMedioPago;
            payloadFinal.MedioPagoComision = ComisionMedioPago;
            payloadFinal.ValorInicial = ValorPagarInicial;
            payloadFinal.ValorPago = ValorPagar;
            payloadFinal.ValorComision = ValorComision;
            payloadFinal.ValorComisionFijo = ValorComisionFijo;
            payloadFinal.ValorComisionFijoIva = ValorComisionFijoIva;
            payloadFinal.ValorComisionNeto = ValorComisionNeto;
            payloadFinal.ValorComisionIva = ValorComisionIva;
            payloadFinal.ValorTotal = ValorPagarFinal;
            payloadFinal.Estado = 'PENDIENTE';
            payloadFinal.Usuario = UsuarioId;
            payloadFinal.Email = UsuarioEmail;
            payloadFinal.Rut = UsuarioRut;
            console.log('\n\n INFO PARA PAGO '+JSON.stringify(payloadFinal));
            SetMensajeDeError('');
            dispatch(Home_RealizarPagoServicio(payloadFinal));
        }
    }



  return (
    <>
    {Loading && <Cargando />}
    
    <Dialog
      onClose={(event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
          handleCloseImgKhipu();
        }
      }}
      open={openImgKhipu}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xl"
    >
      <DialogTitle id="idImgKhipu">
        <Box display="flex" justifyContent="flex-end">
          <IconButton
            className="button button-danger"
            onClick={handleCloseImgKhipu}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className="pb-4">
        <img
          style={{
              height:'600px'
          }}
          src="/AvisoKhipu.png"
        />
      </DialogContent>
    </Dialog>

    <Dialog
        open={OpenModalPagarServicio}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xl"
        scroll="paper"
    >
            <DialogTitle id="id">
                <Box display="flex" alignItems="center">
                    <Box flexGrow={1} >POR FAVOR VERIFICA EL MONTO Y SELECCIONA EL MÉTODO DE PAGO</Box>
                    <Box
                    >
                        <IconButton 
                        className="button button-danger"
                        onClick={()=>{
                            CancelarPago();
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>

                  <DialogContent className="pb-4">

                        <Alert className="mt-3" severity="warning">
                            <AlertTitle>Monto pendiente</AlertTitle>
                            <strong>${formatMiles(ValorPagarInicial)}</strong>
                        </Alert>

                        <Box
                            display="flex"
                            p={1}
                            className="row col-12"
                        >
                           
                            <Box
                            sx={{width:20}}
                            mt={1}
                            mr={2}
                            p={0}
                            alignItems="center"
                            >
                                <div className={`cards-icon icon-xl align-middle bg-danger`} >
                                    <h1 className="mt-2 text-white">1</h1>
                                </div>
                            </Box>
                            <Box 
                            className="col-10 col-sm-6 col-md-3 col-lg-2 col-xl-2"
                            mt={1}
                            mr={2}
                            p={0}
                            alignItems="left"
                            >
                                <h3>Ingrese un monto a pagar: </h3><h5>(Por defecto colocamos tu saldo pendiente)</h5>
                            </Box>
                            <Box 
                            className="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8"
                            mt={1}
                            p={0}
                            alignItems="left"
                            >
                                <TextField
                                m={0}
                                className="col-12"
                                value={formatMiles(ValorPagar)}
                                variant="outlined"
                                size="small"
                                onChange={OnChangeValorPagar}
                                />
                            </Box >
                        </Box>

                        <hr></hr>

                        <Box
                            display="flex"
                            p={1}
                            className="row col-12"
                        >
                           
                            <Box
                            sx={{width:20}}
                            mt={1}
                            mr={2}
                            p={0}
                            alignItems="center"
                            >
                                <div className={`cards-icon icon-xl align-middle bg-danger`} >
                                    <h1 className="mt-2 text-white">2</h1>
                                </div>
                            </Box>
                            <Box 
                            className="col-7 col-sm-6 col-md-3 col-lg-2 col-xl-2"
                            mt={1}
                            p={0}
                            alignItems="left"
                            >
                                <h3>Seleccionar forma de pago: </h3>
                            </Box>
                            <Box 
                            className="col-12 col-sm-12 col-md-6 col-lg-8 col-xl-8 row"
                            mt={1}
                            p={0}
                            pl={1}
                            alignItems="center"
                            display="flex"
                            >
                               <Box
                                className={`mr-1 text-center`}
                                style={{
                                    border:`${BordeKhipu}`
                                    , cursor:'pointer'
                                    , width:'215px'
                                }}
                                onClick={()=>{ DefinirPago('Khipu');  }}
                                >
                                    <img
                                    style={{
                                        width:'205px'
                                        , height:'80px'
                                    }}
                                    src="/kiphu.png"
                                    />
                                    <Box fontWeight="fontWeightBold" m={1}>TRANFERENCIAS</Box>
                                </Box >
                                <Box
                                className="mr-1 text-center"
                                style={{
                                    border:`${BordeWebpay}`
                                    , cursor:'pointer'
                                    , width:'215px'
                                }}
                                onClick={()=>{ DefinirPago('Webpay');  }}
                                >
                                    <img
                                    style={{
                                        width:'205px'
                                        , height:'80px'
                                    }}
                                    src="/webpay.png"
                                    />
                                    <Box fontWeight="fontWeightBold" m={1}>DEBITO/CREDITO</Box>
                                </Box >
                            </Box >
                        </Box>


                        {TextoComision!==''?

                        <Box
                        display="flex"
                        p={1}
                        className="row col-12"
                        >
                            <Box 
                            className="col-12"
                            mt={1}
                            p={0}
                            alignItems="left"
                            style={{fontSize: "15px",}}
                            >
                                {
                                TextoComision!==''?HtmlParser(TextoComision)
                                :null
                                }
                            </Box >
                        </Box>

                        :null}

                        {TextoNoCerrar!==''?
                        <Box
                        display="flex"
                        p={1}
                        className="row col-12"
                        >

                            <Box 
                            className="col-12"
                            mt={1}
                            p={0}
                            alignItems="left"
                            style={{fontSize: "15px",}}
                            >
                                {
                                TextoNoCerrar!==''?HtmlParser(TextoNoCerrar)
                                :null
                            }
                            </Box >
                        </Box>
                        :null}
                        <hr></hr>
                        
                        <h2>
                        {
                            ValorPagarFinal>0?
                            <strong className="ml-2">TOTAL: $ {formatMiles(ValorPagarFinal)}</strong>
                            :null
                        }
                        </h2>
                        <hr></hr>

                        {
                            Number(ValorPagarInicial)-Number(ValorPagar)>0?
                            <Alert severity="info" className="mb-3"><h3><strong>IMPORTANTE:</strong> Con el monto a pagar ingresado <strong>no podrás realizar el despacho o retiro de tu carga</strong>, ya que quedara un saldo pendiente de <strong>${formatMiles(Number(ValorPagarInicial)-Number(ValorPagar))}</strong>!</h3></Alert>
                            :null
                        }

                        {
                            MensajeDeError.length>0?
                            <Alert severity="error" className="mb-3" style={{ whiteSpace: 'pre-line' }}><h3>ERROR: <strong>{MensajeDeError}</strong>!</h3></Alert>
                            :null
                        }

                        <Alert severity="warning" className="mb-3"><h3><strong>IMPORTANTE:</strong> El pago debe ser realizado en los próximos 30 minutos para que sea validado por nuestro sistema!</h3></Alert>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <Button
                            size="large"
                            className="button button-danger col-12 mb-1"
                            onClick={()=>{
                                RealizarPago();
                            }}
                            >PAGAR</Button>
                            </div>
                            <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <Button
                            size="large"
                            className="button button-gray col-12 mb-1"
                            onClick={()=>{
                                CancelarPago();
                            }}
                            >CANCELAR</Button>
                            </div>
                        </div>
                        {
                        /*
                        <h5>
                        <br></br><hr></hr>
                        {'PAGAR $ '+formatMiles(ValorPagar)}
                        <br></br><hr></hr>
                        {
                            ValorComisionFijo>0?
                            'COMISION FIJO $ '+formatMiles(ValorComisionFijo)
                            :null
                        }
                        <br></br><hr></hr>
                        {
                            ValorComisionFijoIva>0?
                            'COMISION FIJO IVA $ '+formatMiles(ValorComisionFijoIva)
                            :null
                        }
                        <br></br><hr></hr>
                        {
                            ComisionMedioPago>0?
                            'COMISION % '+ComisionMedioPago
                            :null
                        }
                        <br></br><hr></hr>
                        {
                            ValorComisionNeto>0?
                            'COMISION NETO ( (PAGAR + COMISION FIJA) * % ) / 100 ) $ '+formatMiles(ValorComisionNeto)
                            :null
                        }
                        <br></br><hr></hr>
                        {
                            ValorComisionIva>0?
                            'COMISION IVA $ '+formatMiles(ValorComisionIva)
                            :null
                        }
                        <br></br><hr></hr>
                        {
                            ValorComision>0?
                            'COMISION TOTAL ( FIJO IVA + NETO + NETO IVA )$ '+formatMiles(ValorComision)
                            :null
                        }
                        <br></br><hr></hr>
                        {'VALOR TOTAL $ '+formatMiles(ValorPagarFinal)}
                        <br></br><hr></hr>
                        </h5>
                        */}
                  </DialogContent>
              </Dialog>
              </>
  )
}

export default ModalPagarServicio