export const GET_TYPES_NOTIFICATIONS='GET_TYPES_NOTIFICATIONS';
export const GET_TYPES_NOTIFICATIONS_SUCCESS='GET_TYPES_NOTIFICATIONS_SUCCESS';
export const GET_GLOBAL_LIST_NOTIFICATIONS='GET_GLOBAL_LIST_NOTIFICATIONS';
export const GET_GLOBAL_LIST_NOTIFICATIONS_SUCCESS='GET_GLOBAL_LIST_NOTIFICATIONS_SUCCESS';
export const GET_LIST_BY_USER_NOTIFICATIONS='GET_LIST_BY_USER_NOTIFICATIONS';
export const GET_LIST_BY_USER_NOTIFICATIONS_SUCCESS='GET_LIST_BY_USER_NOTIFICATIONS_SUCCESS';
export const POST_USER_NOTIFICATIONS='POST_USER_NOTIFICATIONS';
export const GET_USER_ALL_NOTIFICATIONS='GET_USER_ALL_NOTIFICATIONS';
export const GET_USER_ALL_NOTIFICATIONS_SUCCESS='GET_USER_ALL_NOTIFICATIONS_SUCCESS';
export const PUT_VISTAS_NOTIFICATIONS='PUT_VISTAS_NOTIFICATIONS';

export const CLEAR_ALL_EXPDIG_NOTIFICACIONES_SUCCESS='CLEAR_ALL_EXPDIG_NOTIFICACIONES_SUCCESS';
