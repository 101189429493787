export const EMP_SET_PAYLOADEMPRESAS= 'EMP_SET_PAYLOADEMPRESAS';
export const EMP_SHOW_MESSAGE_EMPRESAS= 'EMP_SHOW_MESSAGE_EMPRESAS';
export const EMP_SHOW_MESSAGE_EMPRESAS_SUCCESS= 'EMP_SHOW_MESSAGE_EMPRESAS_SUCCESS';
export const EMP_HIDE_MESSAGE_EMPRESAS= 'EMP_HIDE_MESSAGE_EMPRESAS';
export const EMP_GET_EMPRESASLIST= 'EMP_GET_EMPRESASLIST';
export const EMP_GET_EMPRESASLIST_SUCCESS= 'EMP_GET_EMPRESASLIST_SUCCESS';
export const EMP_POST_EMPRESAS= 'EMP_POST_EMPRESAS';
export const EMP_POST_EMPRESAS_SUCCESS= 'EMP_POST_EMPRESAS_SUCCESS';
export const EMP_GET_EMPRESAS= 'EMP_GET_EMPRESAS';
export const EMP_GET_EMPRESAS_SUCCESS= 'EMP_GET_EMPRESAS_SUCCESS';
export const EMP_PUT_EMPRESAS= 'EMP_PUT_EMPRESAS';
export const EMP_PUT_EMPRESAS_SUCCESS= 'EMP_PUT_EMPRESAS_SUCCESS';  
export const EMP_DELETE_EMPRESAS= 'EMP_DELETE_EMPRESAS';  
export const EMP_DELETE_EMPRESAS_SUCCESS= 'EMP_DELETE_EMPRESAS_SUCCESS';  
export const EMP_SET_MESSAGE_ELIMINAR_EMPRESAS= 'EMP_SET_MESSAGE_ELIMINAR_EMPRESAS';  
export const EMPTY_DATA_INIT_EMPRESAS='EMPTY_DATA_INIT_EMPRESAS';

export const CLEAR_ALL_EXPDIG_EMPRESAS_SUCCESS='CLEAR_ALL_EXPDIG_EMPRESAS_SUCCESS';
