import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import DoneOutline from '@material-ui/icons/DoneOutline';
import { motion } from "framer-motion"
import { withStyles } from '@material-ui/core/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import envConfig from '../../envConfig';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import lodash, { stubTrue, truncate } from 'lodash';
import { FaCheck } from "react-icons/fa";
import Nav from 'containers/AppLayout/Vertical/Nav';
import Header from 'containers/AppLayout/Vertical/Header';
import { Alert, AlertTitle } from '@material-ui/lab';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import {
    PAGONLINE_Get_Detalle_Pago
} from './Actions';
import Box from '@material-ui/core/Box';
import {
    Avatar,
    Button,
    Hidden,
    makeStyles,
    Tooltip,
    Menu,
    MenuItem,
    Typography
} from "@material-ui/core";
import { element } from 'prop-types';
import ReactHtmlParser from "react-html-parser";

import querystring from 'querystring';

const StyledTableCell1 = withStyles((theme) => ({
    head: {
      backgroundColor: '#273746',
      color: theme.palette.common.white,
    },
    body: {
      backgroundColor: '#273746',
      fontSize: 14,
      color:'#ffffff',
    },
  }))(TableCell);
  
  const StyledTableCell2 = withStyles((theme) => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

const useStyles = makeStyles((theme) => ({
    inputAdornment: {
        "& .MuiTypography-body1": {
            fontSize: "12px"
        }
    },
    iconServicio: {
        width: "80px",
        height: "72px",

        "& .MuiSvgIcon-root": {
            fontSize: "32px"
        }
    },
    breadcrumbs: {
        paddingLeft: "48px",
        marginBottom: "24px"
    }
}));


// Tooltip MUI //
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#263238',
        color: '#fff',
        maxWidth: 300,
        padding: "12px 16px",
        "& .MuiTypography-h5": {
            fontSize: "14px"
        },
        "& .MuiTypography-h6": {
            fontSize: "10px"
        }
    },
}))(Tooltip);


const DetallePagoOnline = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const Usuario = JSON.parse(localStorage.getItem("userSession"));

    const {
        DetallePago
    } = useSelector(({ PagoOnlineDetalle_Maintainer }) => PagoOnlineDetalle_Maintainer);

    const [IdPago, SetIdPago] = useState(props.match.params.id);
    const [InfoDetalle, SetInfoDetalle] = useState(DetallePago);

    const formatMiles = (input) => {
        if(typeof input!=='undefined')
        {
            var num = input.toString().replace(/\./g, '');
            var formato = ""
            if (!isNaN(num)) {
                num = num.toString().split('').reverse().join('').replace(/(?=\d*\.?)(\d{3})/g, '$1.');
                num = num.split('').reverse().join('').replace(/^[\.]/, '');
                formato = num;
            }
            else {
                formato = formato.replace(/[^\d\.]*/g, '');
            }
    
            return formato;
        }
    }

    useEffect(() => {
        if ( typeof IdPago!=='undefined' && IdPago!=='' && IdPago.length>0 && InfoDetalle.length<=0 )
        {
            dispatch(PAGONLINE_Get_Detalle_Pago(IdPago));
        }
        else
        {
            SetIdPago('-1');
            dispatch(PAGONLINE_Get_Detalle_Pago('-1'));
        }
    }, [IdPago]);

    useEffect(() => {
        SetInfoDetalle(DetallePago);
    }, [DetallePago]);

    return (
        <div className={`app-container new-template fixed-drawer`}>
            <div className='app-main-container'>
                <div className="app-main-container" style={{ backgroundColor: 'red !important' }}>
                    <div className='app-header-cotizador'>
                        <div className='main-title'>
                            <h1 className="title-h2 text-light font-weight-700">RESPUESTA DE PASARELA DE PAGO</h1>
                        </div>
                    </div>
                </div>
                <div className='container-fuid paddingContainer' style={{'marginTop':'30px'}}>
                    {
                        typeof InfoDetalle==='string' && InfoDetalle==='No se encontró detalle de pago.'?
                        <Alert severity="error">
                            <AlertTitle><h1>ERROR</h1></AlertTitle>
                            <h2>{InfoDetalle}</h2>
                        </Alert>
                        :null
                    }

                    {
                        typeof InfoDetalle==='object'?
                        <>
                        <Card className={classes.root+' p-3'} style={{boxShadow:'3'}}>
                            <CardHeader
                                avatar={
                                <Avatar aria-label="recipe" style={{width:'50px',height:'50px', backgroundColor:InfoDetalle.estado==='APROBADA'?'#28B463':'#E74C3C'}}>
                                    <CheckIcon style={{fontSize: 40}}/>
                                </Avatar>
                                }
                                titleTypographyProps={{variant:'h4' }}
                                title={InfoDetalle.estado==='APROBADA'?'¡Tu pago fue realizado con éxito!':'¡Tu pago ha presentado errores!'}
                                subheaderTypographyProps={{variant:'subtitle1' }}
                            />
                            <Box className="p-3 text-left">
                                <Typography variant="body2">La respuesta fue recibida el {InfoDetalle.respuesta_fecha}</Typography>
                            </Box>
                            <hr></hr>
                            <Box
                            display="flex"
                            alignItems="center"
                            className="row"
                            >
                                <Box className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center">
                                    <Typography variant="subtitle2">Medio de pago</Typography>
                                    {
                                        InfoDetalle.medio_pago==='KHIPU'?
                                        <img
                                        style={{
                                            width:'102px'
                                            , height:'40px'
                                        }}
                                        src="/kiphu.png"
                                        />
                                        :InfoDetalle.medio_pago==='WEBPAY'?
                                        <img
                                        style={{
                                            width:'102px'
                                            , height:'40px'
                                        }}
                                        src="/webpay.png"
                                        />
                                        :null
                                    }
                                </Box>
                                <Box className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-center">
                                    <Typography variant="subtitle2">Monto de pago</Typography>
                                    <Typography variant="h3">$ {formatMiles(InfoDetalle.valor_total)}</Typography>
                                </Box>
                            </Box>
                            <hr></hr>
                            { typeof InfoDetalle.respuesta_detalle_json==='object'?
                            Object.keys(InfoDetalle.respuesta_detalle_json).map((key, index) => (
                                key!=='Estado2'?
                                <Box
                                display="flex"
                                alignItems="left"
                                className="row"
                                >
                                    <Box className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 text-left">
                                        <Typography variant="subtitle2">{ key }</Typography>
                                    </Box>
                                    <Box className="col-12 col-sm-7 col-md-8 col-lg-8 col-xl-8 text-left">
                                    <Typography variant="subtitle1"> 
                                        { 
                                        key==='Nº cuenta'? 
                                        InfoDetalle.respuesta_detalle_json[key].substring(0, 10)+'...'
                                        :key==='Monto'? 
                                        '$ '+formatMiles(InfoDetalle.respuesta_detalle_json[key]*1)
                                        :InfoDetalle.respuesta_detalle_json[key]
                                        }
                                    </Typography>
                                    </Box>
                                </Box>:null
                            )):null }
                            
                            {
                                InfoDetalle.medio_pago_comision!=0?
                                <>
                                <hr></hr>
                                <Box
                                display="flex"
                                alignItems="left"
                                className="row"
                                >
                                    <Box className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 text-left">
                                        <Typography variant="subtitle2">Valor pagado del servicio</Typography>
                                    </Box>
                                    <Box className="col-12 col-sm-7 col-md-8 col-lg-8 col-xl-8 text-left">
                                    <Typography variant="subtitle1">$ {formatMiles(InfoDetalle.valor_pago)}</Typography>
                                    </Box>
                                </Box>
                                <Box
                                display="flex"
                                alignItems="left"
                                className="row"
                                >
                                    <Box className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 text-left">
                                        <Typography variant="subtitle2">Valor comision fija</Typography>
                                    </Box>
                                    <Box className="col-12 col-sm-7 col-md-8 col-lg-8 col-xl-8 text-left">
                                    <Typography variant="subtitle1">$ {formatMiles(InfoDetalle.valor_comision_fijo)}</Typography>
                                    </Box>
                                </Box> 
                                <Box
                                display="flex"
                                alignItems="left"
                                className="row"
                                >
                                    <Box className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 text-left">
                                        <Typography variant="subtitle2">Valor comision fija IVA</Typography>
                                    </Box>
                                    <Box className="col-12 col-sm-7 col-md-8 col-lg-8 col-xl-8 text-left">
                                    <Typography variant="subtitle1">$ {formatMiles(InfoDetalle.valor_comision_fijo_iva)}</Typography>
                                    </Box>
                                </Box>                                                                
                                <Box
                                display="flex"
                                alignItems="left"
                                className="row"
                                >
                                    <Box className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 text-left">
                                        <Typography variant="subtitle2">Comisión medio de pago</Typography>
                                    </Box>
                                    <Box className="col-12 col-sm-7 col-md-8 col-lg-8 col-xl-8 text-left">
                                    <Typography variant="subtitle1">{InfoDetalle.medio_pago_comision} %</Typography>
                                    </Box>
                                </Box>
                                <Box
                                display="flex"
                                alignItems="left"
                                className="row"
                                >
                                    <Box className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 text-left">
                                        <Typography variant="subtitle2">Valor comisión neto</Typography>
                                    </Box>
                                    <Box className="col-12 col-sm-7 col-md-8 col-lg-8 col-xl-8 text-left">
                                    <Typography variant="subtitle1">$ {formatMiles(InfoDetalle.valor_comision_neto)}</Typography>
                                    </Box>
                                </Box>
                                <Box
                                display="flex"
                                alignItems="left"
                                className="row"
                                >
                                    <Box className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 text-left">
                                        <Typography variant="subtitle2">Valor comisión iva</Typography>
                                    </Box>
                                    <Box className="col-12 col-sm-7 col-md-8 col-lg-8 col-xl-8 text-left">
                                    <Typography variant="subtitle1">$ {formatMiles(InfoDetalle.valor_comision_iva)}</Typography>
                                    </Box>
                                </Box>
                                <Box
                                display="flex"
                                alignItems="left"
                                className="row"
                                >
                                    <Box className="col-12 col-sm-4 col-md-3 col-lg-3 col-xl-3 text-left">
                                        <Typography variant="subtitle2">Valor total transacción</Typography>
                                    </Box>
                                    <Box className="col-12 col-sm-7 col-md-8 col-lg-8 col-xl-8 text-left">
                                    <Typography variant="subtitle1">$ {formatMiles(InfoDetalle.valor_total)}</Typography>
                                    </Box>
                                </Box>
                                </>
                                :<></>
                            }

                            <br></br>
                            <div className="row mt-3">
                                <div className="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                                    <Button
                                    size="large"
                                    className="button button-gray col-12 mb-1"
                                    onClick={()=>{
                                        window.location.href = envConfig.enviroment.urlFrontCliente
                                    }}
                                    >VOLVER A WSCARGO</Button>
                                </div>
                            </div>
                        </Card>
                        </>
                        :null
                    }

                </div>
            </div>
        </div>
    )
};

export default DetallePagoOnline;