export const SET_MESSAGE_SOLIC_AGENDAMIENTO='SET_MESSAGE_SOLIC_AGENDAMIENTO';
export const GET_SERVICE_SOLIC_AGENDAMIENTO='GET_SERVICE_SOLIC_AGENDAMIENTO';
export const GET_SERVICE_SOLIC_AGENDAMIENTO_SUCCESS='GET_SERVICE_SOLIC_AGENDAMIENTO_SUCCESS';
export const GET_DIRECCIONES_SOLIC_AGENDAMIENTO='GET_DIRECCIONES_SOLIC_AGENDAMIENTO';
export const GET_DIRECCIONES_SOLIC_AGENDAMIENTO_SUCCESS='GET_DIRECCIONES_SOLIC_AGENDAMIENTO_SUCCESS';
export const GET_REGIONES_SOLIC_AGENDAMIENTO='GET_REGIONES_SOLIC_AGENDAMIENTO';
export const GET_REGIONES_SOLIC_AGENDAMIENTO_SUCCESS='GET_REGIONES_SOLIC_AGENDAMIENTO_SUCCESS';
export const GET_COMUNAS_SOLIC_AGENDAMIENTO='GET_COMUNAS_SOLIC_AGENDAMIENTO';
export const GET_COMUNAS_SOLIC_AGENDAMIENTO_SUCCESS='GET_COMUNAS_SOLIC_AGENDAMIENTO_SUCCESS';
export const GET_CONFIG_DESPACHO_SOLIC_AGENDAMIENTO='GET_CONFIG_DESPACHO_SOLIC_AGENDAMIENTO';
export const GET_CONFIG_DESPACHO_SOLIC_AGENDAMIENTO_SUCCESS='GET_CONFIG_DESPACHO_SOLIC_AGENDAMIENTO_SUCCESS';
export const GET_CONFIG_RETIRO_SOLIC_AGENDAMIENTO='GET_CONFIG_RETIRO_SOLIC_AGENDAMIENTO';
export const GET_CONFIG_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS='GET_CONFIG_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS';
export const POST_DESPACHO_RETIRO_SOLIC_AGENDAMIENTO='POST_DESPACHO_RETIRO_SOLIC_AGENDAMIENTO';
export const GET_DISPONIBILIDAD_DESPACHO_SOLIC_AGENDAMIENTO='GET_DISPONIBILIDAD_DESPACHO_SOLIC_AGENDAMIENTO';
export const GET_DISPONIBILIDAD_DESPACHO_SOLIC_AGENDAMIENTO_SUCCESS='GET_DISPONIBILIDAD_DESPACHO_SOLIC_AGENDAMIENTO_SUCCESS';
export const GET_BLOQUEOS_RETIRO_SOLIC_AGENDAMIENTO='GET_BLOQUEOS_RETIRO_SOLIC_AGENDAMIENTO';
export const GET_BLOQUEOS_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS='GET_BLOQUEOS_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS';
export const GET_DISPONIBILIDAD_RETIRO_SOLIC_AGENDAMIENTO='GET_DISPONIBILIDAD_RETIRO_SOLIC_AGENDAMIENTO';
export const GET_DISPONIBILIDAD_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS='GET_DISPONIBILIDAD_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS';
