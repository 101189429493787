import React from 'react'
import { Link } from 'react-router-dom'
import { CircularProgressbar } from 'react-circular-progressbar';
import { Badge } from 'reactstrap';
import 'react-circular-progressbar/dist/styles.css';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from "@material-ui/core";

const estapasSeguimiento = [
    { id: '1', etapa: 'Carga Recibida', estado: 'active' },
    { id: '2', etapa: 'Salida Puerto', estado: 'pendiente' },
    { id: '3', etapa: 'Retiro Puerto', estado: 'pendiente' },
    { id: '4', etapa: 'Disponible Entrega', estado: 'pendiente' },
    { id: '5', etapa: 'Entregado', estado: 'pendiente' }
]


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "left",
        flexWrap: "nowrap",
        listStyle: "none",
        padding: theme.spacing(0.5),
        margin: 0,
        overflowY: "scroll",
        scrollbarWidth: "none",

        // justifyContent: 'center',
        // flexWrap: 'wrap',
        '& > *': {
            marginRight: "16px",
        },
        '& .MuiChip-root': {
            padding:"18px 12px",
            backgroundColor:"#cfcec",

            '.MuiChip-label': {
                fontSize: "5px !import",
            }
        },
        
    },
    estado0: {
        background:'#6FCF9'
    },
    estado1: {
        background:'#EA5259'
    },
    estado2: {
        background:'#F8C96A'
    },

}));

const ProgresBarMobile = () => {
    const classes = useStyles();


    // estpas de seguimiento
    const etapaActual =  {
        etapa: 'Carga Recibida',
        estado:'2'
    }


    const proximaEtapa = 'Salida Puerto';

    const percentage = 1;

    // class para cambiar el color de la linea de tiempo, segun el estado del servicio
    const classEstado = (estado) => {
        if (estado == "0") {
            return classes.estado0;
        } else if (estado == "1") {
            return classes.estado1;
        } else if (estado == "2") {
            return classes.estado2;
        }
    }

    

    return (
        <div className="row mt-4">
            <div className='col-5'>
                <CircularProgressbar
                    value={20}
                    // text={`${percentage} / 5`}
                    // className={classes.progressbar}
                    strokeWidth={8}

                    styles={{
                        path: {
                            // 
                            // stroke:`${classEstado(etapaActual.estado)}`,

                            stroke: '#6FCF97', 
                            // strokeLinecap: 'round',
                            transition: 'stroke-dashoffset 0.5s ease 0s',
                        },
                        // Customize the circle behind the path, i.e. the "total progress"
                        trail: {
                            // Trail color
                            stroke: '#cfcece',
                            strokeLinecap: 'round',
                        },
                        // text
                        text: {
                            // Text color
                            fill: '#cfcece',
                            // Text size
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }
                    }}
                />
            </div>

            <div className='col-7 align-item-center'>
                <div className="etapa">
                    <h4 className="text-small font-weight-400">Etapa Actual</h4>
                    <h5 className="title-h5 font-weight-700 mb-2">{`${etapaActual.etapa}`}</h5>
                    <h5 className="text-xs font-weight-400">Próxima Etapa</h5>
                    <h5 className="text-small font-weight-400">{`${proximaEtapa}`}</h5>
                </div>
            </div>

            <div className='col-12'>
                <div className="border my-4"></div>
                <h5 class="text-small font-weight-400 mb-2 text-center">Próximas Etapas</h5>

                <div className={classes.root}>
                    {
                        estapasSeguimiento.map((item, index) => (
                            <Chip label={item.etapa} index={index} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default ProgresBarMobile
