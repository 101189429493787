import{
    SHOW_MESSAGE_DETALLE_SERVICIO,
    HIDE_MESSAGE_DETALLE_SERVICIO,
    GET_INFO_SERVICIO_DETALLE_SERVICIO,
    GET_INFO_SERVICIO_DETALLE_SERVICIO_SUCCESS,
    GET_DOCUMENTS_CLIENT_DETALLE_SERVICIO,
    GET_DOCUMENTS_CLIENT_DETALLE_SERVICIO_SUCCESS,
    POST_PAGO_DETALLE_SERVICIO,
    POST_PAGO_DETALLE_SERVICIO_SUCCESS,
    GET_PAGOS_DETALLE_SERVICIO,
    GET_PAGOS_DETALLE_SERVICIO_SUCCESS,
    GET_HISTORY_DETALLE_SERVICIO,
    GET_HISTORY_DETALLE_SERVICIO_SUCCESS,
    GET_DATA_COSTOS_DETALLE_SERVICIO,
    GET_DATA_COSTOS_DETALLE_SERVICIO_SUCCESS,
    GET_PROPUESTA_COMERCIAL_DETALLE_SERVICIO,
    GET_PROPUESTA_COMERCIAL_DETALLE_SERVICIO_SUCCESS,
    GET_DIRECCIONES_CLIENTE_DETALLE_SERVICIO,
    GET_DIRECCIONES_CLIENTE_DETALLE_SERVICIO_SUCCESS,
    POST_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO,
    POST_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO_SUCCESS,
    GET_REGIONES_DETALLE_SERVICIO,
    GET_REGIONES_DETALLE_SERVICIO_SUCCESS,
    GET_COMUNAS_DETALLE_SERVICIO,
    GET_COMUNAS_DETALLE_SERVICIO_SUCCESS,
    GET_RETIRO_DESPACHO_DETALLE_SERVICIO,
    GET_RETIRO_DESPACHO_DETALLE_SERVICIO_SUCCESS,
    PUT_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO,
    PUT_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO_SUCCESS,
    GET_PAGOS_QIAO_DETALLE_SERVICIO,
    GET_PAGOS_QIAO_DETALLE_SERVICIO_SUCCESS,
    CLEAN_STATES_DETALLE_SERVICIO,
    GET_COMERCIALES_DETALLE_SERVICIO,
    GET_COMERCIALES_DETALLE_SERVICIO_SUCCESS,
    CLOSE_PAGO_SUCCESS_DETALLE_SERVICIO,
    CLOSE_DESPACHO_RETIRO_SUCCESS_DETALLE_SERVICIO,
    PUT_UPLOAD_DOCUMENTS_DETALLE_SERVICIO,
    CLEAR_DATA_DETALLE_SERVICIO,
    CLEAR_ALL_EXPDIG_DETALLESERVICIO_SUCCESS,
    GET_CONFIG_DESPACHO_DETALLE_SERVICIO,
    GET_CONFIG_DESPACHO_DETALLE_SERVICIO_SUCCESS,
    GET_CONFIG_RETIRO_DETALLE_SERVICIO,
    GET_CONFIG_RETIRO_DETALLE_SERVICIO_SUCCESS,
    GET_BLOQUEOS_RETIRO_BY_DAY_DETALLE_SERVICIO,
    GET_BLOQUEOS_RETIRO_BY_DAY_DETALLE_SERVICIO_SUCCESS,
    GET_DISPONIBILIDAD_DESPACHO_CALENDARIO_DETALLE_SERVICIO,
    GET_DISPONIBILIDAD_DESPACHO_CALENDARIO_DETALLE_SERVICIO_SUCCESS,
    POST_AGENDA_RETIRO_BY_MONTH_DETALLE_SERVICIO,
    POST_AGENDA_RETIRO_BY_MONTH_DETALLE_SERVICIO_SUCCESS,

    FINDETAIL_SETMOSTRARMODALPAGARSERVICIO,
    FINDETAIL_MODALPAGARSERVICIO_GETINFO,
    FINDETAIL_MODALPAGARSERVICIO_GETINFO_SUCCESS,
    FINDETAIL_REALIZARPAGOSERVICIO_SUCCESS,
    FINDETAIL_REALIZARPAGOSERVICIO,
    FINDETAIL_SETMOSTRARLOADINGPAGOS,
    GET_TIMELINE_DETALLE_SERVICIO,
    GET_TIMELINE_DETALLE_SERVICIO_SUCCESS
} from './Constants';

export const FINDETAIL_SetMostrarLoadingPagos= (payload) => {
    return {
        type: FINDETAIL_SETMOSTRARLOADINGPAGOS,
        payload: payload,
    };
};

export const FINDETAIL_RealizarPagoServicio_Success= (payload) => {
    return {
        type: FINDETAIL_REALIZARPAGOSERVICIO_SUCCESS,
        payload: payload,
    };
};

export const FINDETAIL_RealizarPagoServicio= (payload) => {
    return {
        type: FINDETAIL_REALIZARPAGOSERVICIO,
        payload: payload,
    };
};

export const FINDETAIL_ModalPagarServicio_GetInfo_Success= (payload) => {
    return {
        type: FINDETAIL_MODALPAGARSERVICIO_GETINFO_SUCCESS,
        payload: payload,
    };
};

export const FINDETAIL_ModalPagarServicio_GetInfo= (payload) => {
    return {
        type: FINDETAIL_MODALPAGARSERVICIO_GETINFO,
        payload: payload,
    };
};

export const FINDETAIL_SetMostrarModalPagarServicio= (payload) => {
    return {
        type: FINDETAIL_SETMOSTRARMODALPAGARSERVICIO,
        payload: payload,
    };
};

export const Clear_All_ExpDig_DetalleServicio_Success = () => {
    return {
        type: CLEAR_ALL_EXPDIG_DETALLESERVICIO_SUCCESS,
    };
};

export const showMessageDetalleServicio= (payload) => {
    return {
        type: SHOW_MESSAGE_DETALLE_SERVICIO,
        payload: payload
    };
};

export const hideMessageDetalleServicio= (payload) => {
    return {
        type: HIDE_MESSAGE_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getInfoServicioDetalleServicio= (payload) => {
    return {
        type: GET_INFO_SERVICIO_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getInfoServicioDetalleServicioSuccess= (payload) => {
    return {
        type: GET_INFO_SERVICIO_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const getDocumentsClientDetalleServicio= (payload) => {
    return {
        type: GET_DOCUMENTS_CLIENT_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getDocumentsClientDetalleServicioSuccess= (payload) => {
    return {
        type: GET_DOCUMENTS_CLIENT_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const postPagoDetalleServicio= (payload) => {
    return {
        type: POST_PAGO_DETALLE_SERVICIO,
        payload: payload
    };
};

export const postPagoDetalleServicioSuccess= (payload) => {
    return {
        type: POST_PAGO_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const getPagosDetalleServicio= (payload) => {
    return {
        type: GET_PAGOS_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getPagosDetalleServicioSuccess= (payload) => {
    return {
        type: GET_PAGOS_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const getHistoryDetalleServicio= (payload) => {
    return {
        type: GET_HISTORY_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getHistoryDetalleServicioSuccess= (payload) => {
    return {
        type: GET_HISTORY_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const getDataCostosDetalleServicio= (payload) => {
    return {
        type: GET_DATA_COSTOS_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getDataCostosDetalleServicioSuccess= (payload) => {
    return {
        type: GET_DATA_COSTOS_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};


export const getPropuestaComercialDetalleServicio= (payload) => {
    return {
        type: GET_PROPUESTA_COMERCIAL_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getPropuestaComercialDetalleServicioSuccess= (payload) => {
    return {
        type: GET_PROPUESTA_COMERCIAL_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const getDireccionesClienteDetalleServicio= (payload) => {
    return {
        type: GET_DIRECCIONES_CLIENTE_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getDireccionesClienteDetalleServicioSuccess= (payload) => {
    return {
        type: GET_DIRECCIONES_CLIENTE_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const postServicioDespachoRetiroDetalleServicio= (payload) => {
    return {
        type: POST_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO,
        payload: payload
    };
};

export const postServicioDespachoRetiroDetalleServicioSuccess= (payload) => {
    return {
        type: POST_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const getRegionesDetalleServicio= (payload) => {
    return {
        type: GET_REGIONES_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getRegionesDetalleServicioSuccess= (payload) => {
    return {
        type: GET_REGIONES_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const getComunasDetalleServicio= (payload) => {
    return {
        type: GET_COMUNAS_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getComunasDetalleServicioSuccess= (payload) => {
    return {
        type: GET_COMUNAS_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const getRetiroDespachoDetalleServicio= (payload) => {
    return {
        type: GET_RETIRO_DESPACHO_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getRetiroDespachoDetalleServicioSuccess= (payload) => {
    return {
        type: GET_RETIRO_DESPACHO_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const putServicioDespachoRetiroDetalleServicio= (payload) => {
    return {
        type: PUT_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO,
        payload: payload
    };
};

export const putServicioDespachoRetiroDetalleServicioSuccess= (payload) => {
    return {
        type: PUT_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const getPagosQiaoDetalleServicio= (payload) => {
    return {
        type: GET_PAGOS_QIAO_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getPagosQiaoDetalleServicioSuccess= (payload) => {
    return {
        type: GET_PAGOS_QIAO_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const cleanStatesDetalleServicio= (payload) => {
    return {
        type: CLEAN_STATES_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getComercialesDetalleServicio= (payload) => {
    return {
        type: GET_COMERCIALES_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getComercialesDetalleServicioSuccess= (payload) => {
    return {
        type: GET_COMERCIALES_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const closePagoSuccessDetalleServicio= (payload) => {
    return {
        type: CLOSE_PAGO_SUCCESS_DETALLE_SERVICIO,
        payload: payload
    };
};

export const closeDespachoRetiroSuccessDetalleServicio= (payload) => {
    return {
        type: CLOSE_DESPACHO_RETIRO_SUCCESS_DETALLE_SERVICIO,
        payload: payload
    };
};

export const putUploadDocumentsDetalleServicio= (payload) => {
    return {
        type: PUT_UPLOAD_DOCUMENTS_DETALLE_SERVICIO,
        payload: payload
    };
};

export const clearDataDetalleServicio= (payload) => {
    return {
        type: CLEAR_DATA_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getConfigDespachoDetalleServicio= (payload) => {
    return {
        type: GET_CONFIG_DESPACHO_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getConfigDespachoDetalleServicioSuccess= (payload) => {
    return {
        type: GET_CONFIG_DESPACHO_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const getConfigRetiroDetalleServicio= (payload) => {
    return {
        type: GET_CONFIG_RETIRO_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getConfigRetiroDetalleServicioSuccess= (payload) => {
    return {
        type: GET_CONFIG_RETIRO_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const getBloqueosRetiroByDayDetalleServicio= (payload) => {
    return {
        type: GET_BLOQUEOS_RETIRO_BY_DAY_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getBloqueosRetiroByDayDetalleServicioSuccess= (payload) => {
    return {
        type: GET_BLOQUEOS_RETIRO_BY_DAY_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const getDisponibilidadDespachoCalendarioDetalleServicio= (payload) => {
    return {
        type: GET_DISPONIBILIDAD_DESPACHO_CALENDARIO_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getDisponibilidadDespachoCalendarioDetalleServicioSuccess= (payload) => {
    return {
        type: GET_DISPONIBILIDAD_DESPACHO_CALENDARIO_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const postAgendaRetiroByMonthDetalleServicio= (payload) => {
    return {
        type: POST_AGENDA_RETIRO_BY_MONTH_DETALLE_SERVICIO,
        payload: payload
    };
};

export const postAgendaRetiroByMonthDetalleServicioSuccess= (payload) => {
    return {
        type: POST_AGENDA_RETIRO_BY_MONTH_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};

export const getTimeLineDetalleServicio= (payload) => {
    return {
        type: GET_TIMELINE_DETALLE_SERVICIO,
        payload: payload
    };
};

export const getTimeLineDetalleServicioSuccess= (payload) => {
    return {
        type: GET_TIMELINE_DETALLE_SERVICIO_SUCCESS,
        payload: payload
    };
};















    













    
    