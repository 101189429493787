import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Modal, ModalHeader, ModalBody } from "reactstrap";
// import CloseIcon from "@material-ui/icons/Close";
// import IntlMessages from "util/IntlMessages";
// import IconButton from "@material-ui/core/IconButton";
// import MaterialTable from "material-table";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
import "./StyleModal.scss";
// import Button from "@material-ui/core/Button";
// import { size } from "lodash";
import { QRCode } from "react-qr-svg";
import moment from 'moment';
import envConfig from '../../envConfig';

// import { element } from "prop-types";
const EtiquetaPdf = (props) => {
  const dispatch = useDispatch();
  const { payload, comunas, regiones, comerciales } = useSelector(
    ({ CotizadorCliente }) => CotizadorCliente
  );
  const componentRef = useRef();
  const fecha = moment().format('DD-MM-YYYY HH:mm');

  let direccion = "";
  if (payload.tipo === 1) {
    const findComuna = comunas.find((x) => x.id === payload.empresa.fk_comuna);
    direccion += payload.empresa.calle;
    if (payload.empresa.numero_depto.length > 0) {
      direccion += ", " + payload.empresa.numero_depto;
    }
    if (findComuna) {
      direccion += ", " + findComuna.nombre;
    }
  } else {
    const findComuna = comunas.find((x) => x.id === payload.persona.fk_comuna);
    direccion += payload.persona.calle;
    if (payload.persona.numero_depto.length > 0) {
      direccion += ", " + payload.persona.numero_depto;
    }
    if (findComuna) {
      direccion += ", " + findComuna.nombre;
    }
  }

  let comercial = "";

  comercial = comerciales.find((element) => element.id === payload.fk_comercial);

  return (
    <div className="prueba-etiqueta">

      {/* page */}
      <div className="Etiqueta_Page" style={{"backgroundColor":"white"}}>
        {/* cabecera */}
        <div className="justify-start mb-4">
          <img width="100px" src={require("../../assets/images/wscargologo_old.png")} />
          <div className="text-cabecera">
            <h3 className="text-xs fw-bold">WS Cargo SPA</h3>
            <h4 className="text-xs">Oficina: Av Andrés Bello 2299, Of. 202 Providencia</h4>
            <h4 className="text-xs">Bodega: Lincon 3158, bodega 12, Santiago</h4>
          </div>
        </div>
        {/* header etiqueta */}
        <div className="row mb-3 bg-danger">
          <div className="col-12 d-flex align-items-center py-2">
            <h1 className="title-h2 fw-bold text-light">BODEGA GUANGZHOU | 货物信息</h1>
          </div>
          {/* <div className="col-6 d-flex justify-content-end">
            <img width="140px" src="../../LogoBlanco.png" />
          </div> */}
        </div>

        {/* Datos del importador */}
        <div className="row">
          <div className="col-12">
            <h3 className="title-h3 fw-bold mb-3"><u>收货人信息</u></h3>
            <h4 className='title-h4 fw-regular'><span className="fw-bold mr-2">收货人公司名:</span>{payload.tipo === 1 ? payload.empresa.razonSocial : payload.persona.nombre}</h4>
            <h4 className='title-h4 fw-regular'><span className="fw-bold mr-2">税号:</span>{payload.tipo === 1 ? payload.empresa.rut : payload.persona.rut}</h4>
            <h4 className='title-h4 fw-regular'><span className="fw-bold mr-2">地址:</span>{direccion}</h4>
            <h4 className='title-h4 fw-regular'>
              <span className="fw-bold mr-2">电话:</span>
              {
                payload.tipo === 1 ? payload.empresa.telefono.length === 8 ? `+569${payload.empresa.telefono}` : payload.empresa.telefono
                  : payload.persona.telefono.length === 8 ? `+569${payload.persona.telefono}` : payload.persona.telefono
              }
            </h4>
          </div>
        </div>

        <div className='my-3 border'></div>

        <div className="row mb-3">
          <div className="col-7">
            <h4 className="title-h5 fw-bold">-装箱单 (中文, 英文/西文)</h4>
            <h4 className="title-h5 fw-bold">-发票 (中文, 英文/西文)</h4>
          </div>
          <div className="col-5 etiqueta_link d-flex justify-content-end">
            <a href={envConfig.enviroment.urlApi3021+"files_get_packinglist"}>
              <strong>点击下载装箱单</strong>
            </a>
          </div>
        </div>

        {/* <div className="mt-1 mt-5 col-12 text-left Etiqueta_Page_Contenido"> */}
        <div className="Etiqueta_Page_Contenido">
          <ul className='m-0'>
          <li className='text'>务必在每个外箱正、反面<span className='text-danger'>贴上客户标签(</span>标签格式在第2页)</li>
          <li className='text'>电子版装箱单和发票需在「发货前」发给客户，送货后发给仓库收货人</li>
          <li className='text'>需要报关退税请在发货前告知收货人，报关费700元/票， 并必须另外再贴一张 “<span className='text-danger'>TS</span>” 标签在外箱</li>
          <li className='text'><span className='text-danger'>危险品产品</span>（含电池产品、化学品、液体、气体等）需在送货前联系收货仓库，确认可以正常走货后再送货</li>
          <li className='text'>尽量同一客户的货一次寄出，收货仓库不负责卸货, 如需叉车卸货费用自理，费用由外包叉车司机收取, 到货后费用协商完成安排卸货</li>
          </ul>
        </div>

        <div className='my-3 border'></div>

        {/* información bodega */}
        <div className="row mb-3">
          <div className='col-12'>
            <h3 className="title-h3 fw-bold mb-3">联系人姓名:</h3>
            <p className='text'><span className='fw-bold mr-2'>广州骏和小冯:</span>15889958757</p>
            <p className='text'><span className="fw-bold mr-2">广州骏和小王:</span> 15889950282</p>
          </div>
        </div>

        <div className='my-3 border'></div>

        <div className="row mb-3">
          <div className='col-12'>
            <h3 className="title-h3 fw-bold mb-3">仓库地址:</h3>
            <p className='text'>广州市白云区太和镇大沥村111 号（广州奔力物流园6 号仓）导航到广州奔力物流有限公司</p>
          </div>
        </div>

        <div className='my-3 border'></div>

        {/* mapa de la bodega */}
        <div className="row">
          <div className="col-12 text-center mb-3">
            <img width="370px" src="../../MapaEtiqueta2.png" />
          </div>
        </div>

        <div className='my-3 border border-none'></div>
      </div>

      <div className="salto_pagina"></div>

      {/* Etiqueta */}
      <div className="Etiqueta_Page" style={{"backgroundColor":"white"}}>
        {/* cabecera */}
        <div className="justify-start mb-4">
          <img width="100px" src={require("../../assets/images/wscargologo_old.png")} />
          <div className="text-cabecera">
            <h3 className="text-xs fw-bold">WS Cargo SPA</h3>
            <h4 className="text-xs">Oficina: Av Andrés Bello 2299, Of. 202, Providencia</h4>
            <h4 className="text-xs">Bodega: Lincon 3158, bodega 12, Santiago</h4>
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center mb-3">
            <h3 className='title-h3 fw-bold'>请把以下标签贴在每一个箱子上</h3>
          </div>
        </div>

        {/* 1 */}
        <div className="Etiqueta_Page_Boder_Box Etiqueta_Page_Contenid p-3 mb-4">
          {/* QR - cliente - bodega */}
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div style={{ width: '64px' }} className="mr-2">
                <QRCode
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="Q"
                  style={{ width: 64 }}
                  value={"wsc_" + payload.fk_cliente}
                />
              </div>
              <img style={{ width: '90px' }} src="../../Logo.jpg" />
            </div>

            <div className="ml-1 text-center">
              {/* <strong className="mr-1">{item.id} - {item.codigo}</strong> */}
              <h3 className="title-h4 fw-bold">
                {payload.fk_cliente} - {" "}
                {payload.tipo === 1
                  ? payload.empresa.razonSocial
                  : payload.persona.nombre
                }
              </h3>
              <p className='text-small fw-bold'>业务 (Ejecutivo(a)): {comercial.nombre} {comercial.apellidos}</p>
            </div>

            <h3 className="title-h4 fw-bold">标签 - GUANGZHOU</h3>
          </div>

          {/* información cliente */}
          <div className="row my-3">
            <div className="col-6">
              <p className='text-small'>
                <strong className="mr-2">Razón social:</strong>
                {payload.tipo === 1 ? payload.empresa.razonSocial : payload.persona.nombre}
              </p>
              <p className='text-small'>
                <strong className="mr-2">Rut:</strong>
                {payload.tipo === 1 ? payload.empresa.rut : payload.persona.rut}
              </p>
              <p className='text-small'><strong className="mr-2">Dirección:</strong>{direccion}</p>
              <p className='text-small'>
                <strong className="mr-2">Teléfono:</strong>
                {payload.tipo === 1 ? payload.empresa.telefono : payload.persona.telefono}
              </p>
            </div>

            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <p className='text-small'><strong>第几箱</strong></p>
                  <div className="border-2">
                    <br></br>
                    <br></br>
                  </div>
                </div>
                <div className="col-6">
                  <p className='text-small'><strong>共几箱</strong></p>
                  <div className="border-2">
                    <br></br>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-2">
              <p className="text-small"><strong>发货工厂中文名 / 英文名</strong></p>
            </div>
            <div className="col-10">
              <div className="border-2" style={{ height: "48px" }}>
                <br></br>
              </div>
            </div>
          </div>
          <div className="row" style={{'marginBottom':'-8px'}}>
            <div className="col-12 text-right">
              <p>Fecha de impresión: {fecha}</p>
            </div>
          </div>
        </div>

        {/* 2 */}
        <div className="Etiqueta_Page_Boder_Box Etiqueta_Page_Contenid p-3 mb-4">
          {/* QR - cliente - bodega */}
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div style={{ width: '64px' }} className="mr-2">
                <QRCode
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="Q"
                  style={{ width: 64 }}
                  value={"wsc_" + payload.fk_cliente}
                />
              </div>
              <img style={{ width: '90px' }} src="../../Logo.jpg" />
            </div>

            <div className="ml-1 text-center">
              {/* <strong className="mr-1">{item.id} - {item.codigo}</strong> */}
              <h3 className="title-h4 fw-bold">
                {payload.fk_cliente} - {" "}
                {payload.tipo === 1
                  ? payload.empresa.razonSocial
                  : payload.persona.nombre
                }
              </h3>
              <p className='text-small fw-bold'>业务 (Ejecutivo(a)): {comercial.nombre} {comercial.apellidos}</p>
            </div>

            <h3 className="title-h4 fw-bold">标签 - GUANGZHOU</h3>
          </div>

          {/* información cliente */}
          <div className="row my-3">
            <div className="col-6">
              <p className='text-small'>
                <strong className="mr-2">Razón social:</strong>
                {payload.tipo === 1 ? payload.empresa.razonSocial : payload.persona.nombre}
              </p>
              <p className='text-small'>
                <strong className="mr-2">Rut:</strong>
                {payload.tipo === 1 ? payload.empresa.rut : payload.persona.rut}
              </p>
              <p className='text-small'><strong className="mr-2">Dirección:</strong>{direccion}</p>
              <p className='text-small'>
                <strong className="mr-2">Teléfono:</strong>
                {payload.tipo === 1 ? payload.empresa.telefono : payload.persona.telefono}
              </p>
            </div>

            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <p className='text-small'><strong>第几箱</strong></p>
                  <div className="border-2">
                    <br></br>
                    <br></br>
                  </div>
                </div>
                <div className="col-6">
                  <p className='text-small'><strong>共几箱</strong></p>
                  <div className="border-2">
                    <br></br>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-2">
              <p className="text-small"><strong>发货工厂中文名 / 英文名</strong></p>
            </div>
            <div className="col-10">
              <div className="border-2" style={{ height: "48px" }}>
                <br></br>
              </div>
            </div>
          </div>
          <div className="row" style={{'marginBottom':'-8px'}}>
            <div className="col-12 text-right">
              <p>Fecha de impresión: {fecha}</p>
            </div>
          </div>
        </div>

        {/* 3 */}
        <div className="Etiqueta_Page_Boder_Box Etiqueta_Page_Contenid p-3 mb-4">
          {/* QR - cliente - bodega */}
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <div style={{ width: '64px' }} className="mr-2">
                <QRCode
                  bgColor="#FFFFFF"
                  fgColor="#000000"
                  level="Q"
                  style={{ width: 64 }}
                  value={"wsc_" + payload.fk_cliente}
                />
              </div>
              <img style={{ width: '90px' }} src="../../Logo.jpg" />
            </div>

            <div className="ml-1 text-center">
              {/* <strong className="mr-1">{item.id} - {item.codigo}</strong> */}
              <h3 className="title-h4 fw-bold">
                {payload.fk_cliente} - {" "}
                {payload.tipo === 1
                  ? payload.empresa.razonSocial
                  : payload.persona.nombre
                }
              </h3>
              <p className='text-small fw-bold'>业务 (Ejecutivo(a)): {comercial.nombre} {comercial.apellidos}</p>
            </div>

            <h3 className="title-h4 fw-bold">标签 - GUANGZHOU</h3>
          </div>

          {/* información cliente */}
          <div className="row my-3">
            <div className="col-6">
              <p className='text-small'>
                <strong className="mr-2">Razón social:</strong>
                {payload.tipo === 1 ? payload.empresa.razonSocial : payload.persona.nombre}
              </p>
              <p className='text-small'>
                <strong className="mr-2">Rut:</strong>
                {payload.tipo === 1 ? payload.empresa.rut : payload.persona.rut}
              </p>
              <p className='text-small'><strong className="mr-2">Dirección:</strong>{direccion}</p>
              <p className='text-small'>
                <strong className="mr-2">Teléfono:</strong>
                {payload.tipo === 1 ? payload.empresa.telefono : payload.persona.telefono}
              </p>
            </div>

            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <p className='text-small'><strong>第几箱</strong></p>
                  <div className="border-2">
                    <br></br>
                    <br></br>
                  </div>
                </div>
                <div className="col-6">
                  <p className='text-small'><strong>共几箱</strong></p>
                  <div className="border-2">
                    <br></br>
                    <br></br>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-2">
              <p className="text-small"><strong>发货工厂中文名 / 英文名</strong></p>
            </div>
            <div className="col-10">
              <div className="border-2" style={{ height: "48px" }}>
                <br></br>
              </div>
            </div>
          </div>
          <div className="row" style={{'marginBottom':'-8px'}}>
            <div className="col-12 text-right">
              <p>Fecha de impresión: {fecha}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="salto_pagina"></div>

      <div className="Etiqueta_Page"  style={{"backgroundColor":"white"}}>
                    <div className="justify-start mb-5">
                      <img width="100px" src="../../Logo.jpg" />
                      <div className="text-cabecera">
                        <h3 className="text-xs fw-bold">WS Cargo SPA</h3>
                        <h4 className="text-xs">Oficina: Av Andrés Bello 2299, Of. 202, Providencia</h4>
                        <h4 className="text-xs">Bodega: Camino el Noviciado #1945, Pudahuel</h4>
                      </div>
                    </div>
                    <img src="../../hojas_extra/he1.jpg" style={{'width':'100%'}}/>
                    <img src="../../hojas_extra/he2.jpg" style={{'width':'100%'}} />
                  </div>

      <div className="salto_pagina"></div>

      <div className="Etiqueta_Page"  style={{"backgroundColor":"white"}}>
                    <div className="justify-start mb-5">
                      <img width="100px" src="../../Logo.jpg" />
                      <div className="text-cabecera">
                        <h3 className="text-xs fw-bold">WS Cargo SPA</h3>
                        <h4 className="text-xs">Oficina: Av Andrés Bello 2299, Of. 202, Providencia</h4>
                        <h4 className="text-xs">Bodega: Camino el Noviciado #1945, Pudahuel</h4>
                      </div>
                    </div>
                    <img src="../../hojas_extra/he3.jpg" style={{'width':'100%'}} />
                    <img src="../../hojas_extra/he4.jpg" style={{'width':'100%'}} />
                  </div>
                  <div className="salto_pagina"></div>

<div className="Etiqueta_Page" style={{"backgroundColor":"white"}}>
  <div className="justify-start mb-5">
    <img width="100px" src="../../Logo.jpg" />
    <div className="text-cabecera">
      <h3 className="text-xs fw-bold">WS Cargo SPA</h3>
      <h4 className="text-xs">Oficina: Av Andrés Bello 2299, Of. 202, Providencia</h4>
      <h4 className="text-xs">Bodega: Camino el Noviciado #1945, Pudahuel</h4>
    </div>
  </div>
  <div className='text-center' style={{"width":"100%"}}>
    <img src="../../hojas_extra/guangzhou01.png" style={{"width":"80%"}}/>
  </div>
</div>

      <div className='space-page'>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </div>
    </div>
  );
};
export default EtiquetaPdf;
