import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userSignOut } from '../../../../actions/Auth.js';
import { useHistory } from 'react-router-dom';
import logoWsCargo from './logoWsCargo.svg'
import './nav.scss'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import CancelIcon from '@material-ui/icons/Cancel';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { motion } from "framer-motion"
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import Badge from '@material-ui/core/Badge';
import { Theme, withStyles, createStyles } from '@material-ui/core/styles';
import { Data } from './Data';
import Message from '../Message'
import ContactSupportIcon from '@material-ui/icons/ContactSupport';

import {
    getUserAllNotifications
} from '../../../../modules/notificaciones/Actions';

import {
    clearPasswordUser
} from '../../../../modules/usuario/Actions';

import {
    getComercialesRegistrarCliente
} from '../../../../actions';
import moment from 'moment';

import {
    Button,
    IconButton,
    Collapse,
    Menu,
    MenuItem,
    makeStyles,
    Hidden,
    Avatar,
    Divider,
    Typography,
    ListItem,
    ListItemText,
    ListItemAvatar,
    List,
    AppBar,
    Toolbar,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    appBar: {
        background: "transparent",
        boxShadow: "none",
    },
    title: {
        flexGrow: 1,
    },
    inline: {
        display: 'block',
    },
    inlineActive: {
        display: 'block',
        opacity: "0.4"
    },
    appDropdown: {

        "& a": {
            color: "rgba(0, 0, 0, 0.87)"
        },

        "& .MuiListItemIcon-root": {
            minWidth: "auto",
            margin: "0",
        },
        "& .MuiPaper-root": {
            width: "264px",
        },
        "& .MuiTypography-overline": {
            lineHeight: "18px"
        },
        "& .MuiListItem-root": {

            "&:hover": {
                background: "#F9F9F9"
            },
            "&:first-child": {
                background: "#fff"
            }
        }
    },
    icon: {
        fontSize: "28px",
        "@media screen and (max-width: 576px)": {
            fontSize: "36px",
        }
    },


}));


const StyledBadge = withStyles((theme) =>
    createStyles({
        badge: {
            right: -3,
            top: 13,
            background: "#43ADF3",
            // border: `2px solid ${theme.palette.background.paper}`,
            padding: '0 4px',
        },
    }),
)(Badge);



const Index = (props) => {
    const Usuario = JSON.parse(localStorage.getItem('userSession'));
    const classes = useStyles();
    const history = useHistory();

    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const [notifications, setNotifications] = useState([]);
    const {
        dataList
    } = useSelector(({ Notificaciones }) => Notificaciones);
    const {
        comercialesList,
        comerciales,
        roles,
    } = useSelector(({ auth }) => auth);

    const {
        clienteActivo
    } = useSelector(({ Dashboard }) => Dashboard);

    const [comercialesLocal, setComercialesLocal] = useState(comerciales);
    const [selectedComercial, setSelectedComercial] = useState(null);

    useEffect(() => {
        if (Usuario && Usuario.usuario && Usuario.usuario.id) {
            dispatch(getUserAllNotifications({ fk_usuario: Usuario.usuario.id }))
        }
    }, []);

    /*useEffect(() => {
        if(comercialesList!=null && comercialesList.length>0 && Usuario && Usuario.usuario){
            let find=comercialesList.find(x=>x.id==Usuario.usuario.fk_comercial);
            if(find){
                setSelectedComercial(find);
            }
        }
    }, [comercialesList,Usuario]);*/
    useEffect(() => {
        if (comerciales && comerciales.length > 0 && Usuario && Usuario.usuario) {
            let find = comerciales.find(x => x.id == Usuario.usuario.fk_comercial);
            if (find) {
                setSelectedComercial(find);
            }
        }
    }, [comerciales]);

    useEffect(() => {
        if (comerciales && comerciales.length === 0) {
            dispatch(getComercialesRegistrarCliente());
        }
    }, []);

    useEffect(() => {
        if (dataList != null && dataList.length > 0) {
            let filter = dataList.filter(x => x.visto === false);
            setNotifications(filter);
        }
    }, [dataList]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // dropdown nav principal
    const [anchorEl2, setAnchorEl2] = useState(null);
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    /// item collapse ejecutivo version mobile 
    const [open, setOpen] = useState(false);
    const handleClick3 = () => {
        setOpen(!open);
    };


    // dropdown notidicaciones
    const [notificaciones, setNotificacones] = useState(null);
    const [clickNotificciones, setClickNotificciones] = useState(false)
    const handleOpenNotificaciones = (event) => {
        setNotificacones(event.currentTarget);
    };
    const handleCloseNotificaciones = () => {
        setNotificacones(null);
        setClickNotificciones(true)
    };

    ///
    // dialog form registrar pago
    const [openDialog, setOpenDialog] = useState(null);
    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <motion.AppBar
            initial={{ y: -80 }}
            animate={{ y: -1 }}
            transition={{ delay: 0.2, type: "spring", stiffness: 500 }}
            className={classes.appBar} position="static">
            <Toolbar className="app-toolbar">

                {/* logo WSC*/}
                <Link className={`app-logo ${classes.title}`} to="/app/inicio">
                    <img src={logoWsCargo} alt="" draggable="false" />
                </Link>


                {/* Link Envios */}
                {
                    localStorage.getItem('userSession') ? (
                        <>
                            <Hidden smDown>
                                <Link to="/app/inicio" aria-label="Envios" className="app-link">
                                    <DirectionsBoatIcon fontSize="small" />
                                    Home
                                </Link>
                            </Hidden>

                            <Hidden smDown>
                                <div
                                    aria-label="Ejecutivo"
                                    aria-controls="simple-menu2"
                                    aria-haspopup="true"
                                    onClick={handleClick2}
                                    className="app-link"
                                    data-tour="tres"
                                >
                                    <RecordVoiceOverIcon fontSize="small" />
                                    Ejecutivo
                                </div>
                            </Hidden>
                            <Menu
                                id="simple-menu2"
                                anchorEl={anchorEl2}
                                keepMounted
                                open={Boolean(anchorEl2)}
                                onClose={handleClose2}
                                className="app-dropdown"
                                variant="selectedMenu"
                            >
                                <ListItem
                                    button
                                    className="app-avatar-ejecutivo">
                                    <ListItemAvatar>
                                        <Avatar>{selectedComercial != null ? selectedComercial.nombre.slice(0, 1) : ''} {/*selectedComercial!=null ? selectedComercial.apellidos.slice(0,1):''*/}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={selectedComercial != null ? selectedComercial.nombre + ' ' + selectedComercial.apellidos : ''} secondary="Ejecutivo Comercial" />
                                </ListItem>

                                <Divider className="mt-2" />

                                {/* 1 */}
                                <MenuItem onClick={handleClose2}>
                                    <a href={selectedComercial != null ? `mailto:${selectedComercial.email}` : ''} className="app-link align-item-center">
                                        <ListItemIcon>
                                            <EmailIcon fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="inherit" >{selectedComercial != null ? selectedComercial.email : ''}</Typography>
                                    </a>
                                </MenuItem>

                                {/* 2 */}
                                <MenuItem onClick={handleClose2}>
                                    <a href={selectedComercial !== null ? selectedComercial.telefono.length === 8 ? `tel:+569${selectedComercial.telefono}` : `tel:${selectedComercial.telefono}` : ''} className="app-link align-item-center">
                                        <ListItemIcon>
                                            <PhoneIcon fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="inherit">{selectedComercial != null ? selectedComercial.telefono.length === 8 ? `+569${selectedComercial.telefono}` : `${selectedComercial.telefono}` : ''}</Typography>
                                    </a>
                                </MenuItem>

                                {/* 3 */}
                                <MenuItem onClick={handleClose2}>
                                    {/*<a href={//selectedComercial !=null && "https://wa.me/"+selectedComercial.telefono} target="_blank">*/}
                                    <a href={selectedComercial != null ? selectedComercial.telefono.length === 8 ? `https://wa.me/+569${selectedComercial.telefono}` : `https://wa.me/${selectedComercial.telefono}` : ''} target="_blank">
                                        <ListItemIcon>
                                            <WhatsAppIcon fontSize="small" className="icon-whatsApp" />
                                        </ListItemIcon>
                                        <Typography variant="inherit" className="text-whatsApp">WhatsApp</Typography>
                                    </a>
                                </MenuItem>
                            </Menu>
                            {/* Dropdown ejecutivo */}


                            {/* notificaciones */}
                            {/*
                            <IconButton
                                className="app-link-nav mx-3"
                                onClick={handleOpenNotificaciones}
                            >
                                <StyledBadge badgeContent={notifications.length > 0 ? notifications.length : null} color="secondary" data-tour="dos">
                                    <NotificationsActiveIcon className={classes.icon} />
                                </StyledBadge>
                            </IconButton>

                            <Menu
                                id="simple-menu"
                                anchorEl={notificaciones}
                                keepMounted
                                open={Boolean(notificaciones)}
                                onClose={handleCloseNotificaciones}
                                className={`app-dropdown ${classes.appDropdown}`}
                                variant="selectedMenu"
                            >
                                <ListItem component={Link} to="/app/notificaciones" onClick={handleCloseNotificaciones}>
                                   
                                    <ListItemAvatar className="app-badge">
                                        <Badge color="secondary" badgeContent={notifications.length > 0 ? notifications.length : null} showZero>
                                            <NotificationsActiveIcon />
                                        </Badge>
                                    </ListItemAvatar>

                                    <ListItemText primary="Notificaciones" className="pr-2" secondary="Configurar notificaciones" />
                                </ListItem>

                                <Divider className="mt-2" />
                                {
                                    notifications.length > 0 ?
                                        <>
                                            {
                                                notifications.map((item, index) => (
                                                    <ListItem
                                                        component={Link} to="/app/notificaciones"
                                                        onClick={handleCloseNotificaciones}
                                                    >
                                                        <ListItemText
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography
                                                                        component="span"
                                                                        variant="body2"
                                                                        className={classes.inline}
                                                                        // className={ clickNotificciones ? `${classes.inlineActive}`: `${classes.inline}`}
                                                                        color="textPrimary"
                                                                    >
                                                                        {item.texto}
                                                                    </Typography>
                                                                    

                                                                    <Typography
                                                                        component="span"
                                                                        variant="overline"
                                                                        className={classes.inline}
                                                                        color="textSecondary"
                                                                    >
                                                                        {moment(item.fecha).format('DD/MM/YYYY')} {item.fk_consolidado != null ? `- Servicio Nº ${item.fk_consolidado}` : ''}
                                                                    </Typography>

                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </ListItem>
                                                ))
                                            }
                                        </>
                                        :
                                        <MenuItem onClick={handleCloseNotificaciones}>
                                            <Typography variant="inherit" className="py-2">No tienes notificaciones</Typography>
                                        </MenuItem>
                                }

                                <ListItem component={Link} to="/app/notificaciones" onClick={handleCloseNotificaciones}>
                                    <ListItemText
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    // className={classes.inline}
                                                    className={`text-danger ${classes.inline}`}

                                                // color="textPrimary"
                                                >
                                                    Ver todas las notificaciones
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                    </Menu>*/}

                            {/* Dropdown Nav Principal */}
                            <IconButton
                                data-tour="uno"
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                className="app-link-nav ml-3"
                            >
                                <Avatar className="text-uppercase font-weight-700">{JSON.parse(localStorage.getItem('userSession')).usuario.email.slice(0, 1)}</Avatar>

                                <MoreVertIcon />
                            </IconButton>

                            <Menu
                                id="simple-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                className="app-dropdown"
                                variant="selectedMenu"
                            >
                                <ListItem>
                                    <ListItemAvatar className="app-avatar-user">
                                        <Avatar>{JSON.parse(localStorage.getItem('userSession')).usuario.email.slice(0, 1)}</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={`Usuario (${clienteActivo !== null ? clienteActivo.nombre.toUpperCase() : roles[0].nombre.toUpperCase()}) `} className="pr-2" secondary={
                                        <>
                                            {JSON.parse(localStorage.getItem('userSession')).usuario.email}
                                        </>
                                    } />

                                </ListItem>

                                <Divider className="mt-2" />

                                <MenuItem component={Link} to="/app/inicio" onClick={handleClose}>
                                    <ListItemIcon>
                                        <DashboardIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Home</Typography>
                                </MenuItem>


                                <MenuItem component={Link} to="/app/perfil" onClick={() => {
                                    dispatch(clearPasswordUser());
                                    handleClose();
                                }}>
                                    <ListItemIcon>
                                        <AccountCircleIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit">Perfil</Typography>
                                </MenuItem>

                                { 
                                    roles && roles.length > 0 ? (
                                        clienteActivo !== null && clienteActivo.fk_rol > 6 ?
                                            (
                                                clienteActivo.settings.some((el) => el.id_nivel === 4 && el.estado === true) ? (
                                                    <>
                                                        <MenuItem
                                                            component={Link}
                                                            to="/app/registro-empresa-importador"
                                                            onClick={() => {
                                                                //dispatch(cleanStatesEmpresaImportador());
                                                                handleClose();
                                                            }}
                                                        >
                                                            <ListItemIcon>
                                                                <BusinessCenterIcon fontSize="small" />
                                                            </ListItemIcon>
                                                            <Typography variant="inherit"> Reg. Empresa / importador</Typography>
                                                        </MenuItem>
                                                    </>
                                                ) : ('')
                                            ) : (
                                                clienteActivo !== null && clienteActivo.fk_rol === 4 ? (
                                                    <>
                                                        <MenuItem
                                                            component={Link}
                                                            to="/app/registro-empresa-importador"
                                                            onClick={() => {
                                                                //dispatch(cleanStatesEmpresaImportador());
                                                                handleClose();
                                                            }}
                                                        >
                                                            <ListItemIcon>
                                                                <BusinessCenterIcon fontSize="small" />
                                                            </ListItemIcon>
                                                            <Typography variant="inherit"> Reg. Empresa / importador</Typography>
                                                        </MenuItem>
                                                    </>
                                                ) : (' ')

                                            )
                                    ) : (
                                        Usuario && Usuario.usuario && Usuario.usuario.fk_rol === 4  ? (

                                            <>
                                                <MenuItem
                                                    component={Link}
                                                    to="/app/registro-empresa-importador"
                                                    onClick={() => {
                                                        //dispatch(cleanStatesEmpresaImportador());
                                                        handleClose();
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <BusinessCenterIcon fontSize="small" />
                                                    </ListItemIcon>
                                                    <Typography variant="inherit"> Reg. Empresa / importador</Typography>
                                                </MenuItem>
                                            </>

                                        ) : ('')
                                    )
                                }

                                <MenuItem component={Link} to="#"
                                    onClick={() => {
                                        handleClose();
                                        handleOpenDialog();
                                    }}
                                >

                                    <ListItemIcon>
                                        <ContactSupportIcon fontSize="small" />
                                    </ListItemIcon>
                                    <Typography variant="inherit"> Atención cliente</Typography>
                                </MenuItem>

                                <Hidden smUp>
                                    <ListItem button onClick={handleClick3} className="app-item-ejecutivo">
                                        <ListItemIcon>
                                            <RecordVoiceOverIcon fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText primary="Ejecutivo" />

                                        {open ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                </Hidden>

                                <Collapse in={open} timeout="auto" unmountOnExit className="app-item-ejecutivo">
                                    <ListItem className="app-avatar-ejecutivo">
                                        <ListItemAvatar>
                                            <Avatar>{selectedComercial != null ? selectedComercial.nombre.slice(0, 1) : ''} {selectedComercial != null ? selectedComercial.apellidos.slice(0, 1) : ''}</Avatar>
                                        </ListItemAvatar>
                                        <ListItemText primary={selectedComercial != null ? selectedComercial.nombre + ' ' + selectedComercial.apellidos : ''} secondary="Ejecutivo Comercial" />
                                    </ListItem>

                                    <Divider className="mt-2" />

                                    <MenuItem onClick={handleClose}>
                                        <ListItemIcon>
                                            <EmailIcon fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="inherit" className="pr-2">{selectedComercial != null ? selectedComercial.email : ''}</Typography>
                                    </MenuItem>

                                    {/* 2 */}
                                    <MenuItem onClick={handleClose}>
                                        <a href={selectedComercial != null && "tel:" + selectedComercial.telefono} className="app-link align-item-center">
                                            <ListItemIcon>
                                                <PhoneIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit">{selectedComercial != null ? selectedComercial.telefono : ''}</Typography>
                                        </a>
                                    </MenuItem>

                                    {/* 3 */}
                                    <MenuItem onClick={handleClose}>
                                        <a href={selectedComercial != null && "https://wa.me/" + selectedComercial.telefono} target="_blank" className="align-item-center">
                                            <ListItemIcon>
                                                <WhatsAppIcon fontSize="small" className="icon-whatsApp" />
                                            </ListItemIcon>
                                            <Typography variant="inherit" className="text-whatsApp">WhatsApp</Typography>
                                        </a>
                                    </MenuItem>
                                </Collapse>

                                <MenuItem
                                    onClick={() => {
                                        handleClose();
                                        dispatch(userSignOut())
                                    }}>
                                    <ListItemIcon>
                                        <CancelIcon fontSize="small" className="icon-color-danger" />
                                    </ListItemIcon>
                                    <Typography variant="inherit" className="text-danger"> Cerrar Sesión</Typography>
                                </MenuItem>
                            </Menu>
                        </>
                    ) : (

                        <Button
                            className="button button-outline-light"
                            component={Link}
                            to={Usuario ? "/app/inicio" : "/signin"}
                            color="default"
                        >
                            Iniciar Sesión
                        </Button>
                    )
                }



                {/* Dropdown Nav Principal */}
                <div className="ellipse-shape" />
            </Toolbar>

            {/* Revisar iconos disponibles en el componente message */}
            <Message
                open={openDialog}
                onClose={handleCloseDialog}
                tituloMensaje="Tú nos importas"
                icon="alertWarning"
                mensaje="Desde ahora, podrás ingresar tu sugerencia, reclamo o felicitación a través de nuestro formulario online. Una vez ingresado nuestro equipo se pondrá en contacto contigo para mantenerte informado del progreso y resolución de tu solicitud"
            />
        </motion.AppBar>
    );
};

export default withRouter(Index);
