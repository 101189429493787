import {all, call, fork, put, takeEvery,takeLatest,spawn} from "redux-saga/effects";
import {
    auth,
    facebookAuthProvider,
    githubAuthProvider,
    googleAuthProvider,
    twitterAuthProvider
} from "../firebase/firebase";
import {
    SIGNIN_FACEBOOK_USER,
    SIGNIN_GITHUB_USER,
    SIGNIN_GOOGLE_USER,
    SIGNIN_TWITTER_USER,
    SIGNIN_USER,
    SIGNOUT_USER,
    SIGNUP_USER,
    VALIDATE_EMAIL,
    PUT_PASSWORD,
    GET_REGIONES_REGISTRAR_CLIENTE,
    GET_COMUNAS_REGISTRAR_CLIENTE,
    POST_REGISTRAR_CLIENTE_EMPRESA,
    POST_REGISTRAR_CLIENTE_PERSONA,
    GET_ACTIVAR_ACCOUNT,
    VALIDATE_EMAIL_REGISTRO_CLIENTE,
    GET_COMERCIALES_REGISTRAR_CLIENTES,
    POST_RESERVE_ID_REGISTRAR_CLIENTES,
    VALIDATE_RUT_REGISTRO_CLIENTE,
    POST_DATA_CONTACT,
    SET_ERROR_EMAIL,
} from "../constants/ActionTypes";
import { showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess} from "../actions/Auth";
import {
    userFacebookSignInSuccess,
    userGithubSignInSuccess,
    userGoogleSignInSuccess,
    userTwitterSignInSuccess,
    validateEmailSuccess,
    putPasswordSuccess,
    getRegionesRegistrarClienteSuccess,
    getComunasRegistrarClienteSuccess,
    postRegistrarClienteEmpresaSuccess,
    postRegistrarClientePersonaSuccess,
    getActivarAccountSuccess,
    validateEmailRegistroClienteSuccess,
    getComercialesRegistrarClienteSuccess,
    postReserveIdRegistrarClienteSuccess,
    setGlobalComercialesAuth,
    validateRutRegistroClienteSuccess,
    showLoadingRestorePassword,
    Set_Error_Email,
} from "../actions/Auth";

import { clearClienteActivo  } from "../modules/dashboard/Actions"; 
import {
    postRequest,
    getRequest,
    getErrorRequest
} from '../util/apiFetch';
import { get } from "lodash";

import { Clear_All_ExpDig_DetalleServicio_Success } from '../../src/modules/detalleServicio/Actions';
import { Clear_All_ExpDig_Dashboar_Success } from '../../src/modules/dashboard/Actions';
import { Clear_All_ExpDig_Notificaciones_Success } from '../../src/modules/notificaciones/Actions';
import { Clear_All_ExpDig_Empresas_Success } from '../../src/modules/empresas/Actions';
import { Clear_All_ExpDig_Importador_Success } from '../../src/modules/empresaImportador/Actions';
import { Clear_All_ExpDig_Cotizador_Success } from '../../src/modules/cotizador/Actions';


const createUserWithEmailPasswordRequest = async (email, password) =>
    await auth.createUserWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);


export function* signInUserWithEmailPasswordRequest(user, password){
    /*await auth.signInWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);*/
}

const signOutRequest = async () =>
    await auth.signOut()
        .then(authUser => authUser)
        .catch(error => error);


const signInUserWithGoogleRequest = async () =>
    await auth.signInWithPopup(googleAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithFacebookRequest = async () =>
    await auth.signInWithPopup(facebookAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithGithubRequest = async () =>
    await auth.signInWithPopup(githubAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

const signInUserWithTwitterRequest = async () =>
    await auth.signInWithPopup(twitterAuthProvider)
        .then(authUser => authUser)
        .catch(error => error);

function* createUserWithEmailPassword({payload}) {
    const {email, password} = payload;
    try {
        const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userSignUpSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signInUserWithGoogle() {
    try {
        const signUpUser = yield call(signInUserWithGoogleRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userGoogleSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithFacebook() {
    try {
        const signUpUser = yield call(signInUserWithFacebookRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userFacebookSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithGithub() {
    try {
        const signUpUser = yield call(signInUserWithGithubRequest);
        if (signUpUser.message) {
            yield put(showAuthMessage(signUpUser.message));
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userGithubSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* signInUserWithTwitter() {
    try {
        const signUpUser = yield call(signInUserWithTwitterRequest);
        if (signUpUser.message) {
            if (signUpUser.message.length > 100) {
                yield put(showAuthMessage('Your request has been canceled.'));
            } else {
                yield put(showAuthMessage(signUpUser.message));
            }
        } else {
            localStorage.setItem('user_id', signUpUser.user.uid);
            yield put(userTwitterSignInSuccess(signUpUser.user.uid));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signInUserWithEmailPassword({payload}) {
    const {email, password} = payload;
    const requestURL = `token`;
    const payloadObj={
        usuario:email,
        password:password
    };
    const response= yield call(postRequest,requestURL,payloadObj);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showAuthMessage(message));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        localStorage.removeItem('user_id');
        localStorage.removeItem('userSession');
        localStorage.removeItem('comerciales');
        localStorage.removeItem('clienteActivo');
        localStorage.removeItem('userRoles');

        localStorage.setItem('user_id', obj.usuario.id);
        localStorage.setItem('user_id', obj.usuario.id);
        localStorage.setItem('user_id', obj.usuario.id);
        localStorage.setItem('user_id', obj.usuario.id);
        localStorage.setItem('user_id', obj.usuario.id);
        localStorage.setItem('user_id', obj.usuario.id);
        localStorage.setItem('user_id', obj.usuario.id);
        localStorage.setItem('user_id', obj.usuario.id);
        localStorage.setItem('clienteActivo', null);
        localStorage.setItem('clienteActivo', null);
        localStorage.setItem('clienteActivo', null);
        localStorage.setItem('clienteActivo', null);
        localStorage.setItem('clienteActivo', null);
        localStorage.setItem('clienteActivo', null);
        localStorage.setItem('clienteActivo', null);

        localStorage.setItem('userSession', JSON.stringify(obj));
        localStorage.setItem('userRoles', JSON.stringify(obj.roles));

        yield put(userSignInSuccess({authUser:obj.usuario.id, roles : obj.roles}));
        
        yield getSagaComercialesDashboardExecute();
    }
}

function* getSagaComercialesDashboardExecute(){
    const requestURL = `clientes_get_list_comerciales_public`;
    const response= yield call(getRequest,requestURL,null,false);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showAuthMessage(message));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        localStorage.setItem('comerciales', JSON.stringify(obj));
        //yield put(setGlobalComercialesAuth(obj));
    }
}

function* signOut() {
    try {
        const signOutUser = yield call(signOutRequest);
        if (signOutUser === undefined) {
            localStorage.removeItem('user_id');
            localStorage.removeItem('userSession');
            localStorage.removeItem('comerciales');
            localStorage.removeItem('clienteActivo');
            localStorage.removeItem('userRoles');

            yield put(Clear_All_ExpDig_DetalleServicio_Success());
            yield put(Clear_All_ExpDig_Dashboar_Success());
            yield put(Clear_All_ExpDig_Notificaciones_Success());
            yield put(Clear_All_ExpDig_Empresas_Success());
            yield put(Clear_All_ExpDig_Importador_Success());
            yield put(Clear_All_ExpDig_Cotizador_Success());

            localStorage.setItem('clienteActivo', null);
            localStorage.setItem('clienteActivo', null);
            localStorage.setItem('clienteActivo', null);
            localStorage.setItem('clienteActivo', null);
            localStorage.setItem('clienteActivo', null);
            localStorage.setItem('clienteActivo', null);
            localStorage.setItem('clienteActivo', null);
            
            yield put(clearClienteActivo(null));
            yield put(userSignOutSuccess(signOutUser));
        } else {
            yield put(showAuthMessage(signOutUser.message));
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* validateEmail({payload}) {
    const requestURL = `recover-password/validate-email`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error)
    {
        yield put(Set_Error_Email(true));
        yield put(showLoadingRestorePassword(0));
    }
    else
    {
        const respTxt = yield response.text();
        yield put(validateEmailSuccess(JSON.parse(respTxt)))
        yield put(showLoadingRestorePassword(0));
    }
}

function* putPassword({payload}) {
    const requestURL = `recover-password/restore-password`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        //yield put(showAuthMessage(message));
        console.log("ha ocurrio un error en Saga putPassword() : "+error);
    }else{
        const respTxt = yield response.text();
        yield put(putPasswordSuccess(JSON.parse(respTxt)))
    }
}

function* getRegiones({payload}) {
    const requestURL = `region_get_list`;
    const response= yield call(getRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        //yield put(showAuthMessage(message));
        console.log("ha ocurrio un error en Saga getRegiones() : "+error);
    }else{
        const respTxt = yield response.text();
        yield put(getRegionesRegistrarClienteSuccess(JSON.parse(respTxt)))
    }
}

function* getComunas({payload}) {
    const requestURL = `comuna_get_comunaregion/${payload.id}`;
    const response= yield call(getRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        //yield put(showAuthMessage(message));
        console.log("ha ocurrio un error en Saga getComunas() : "+error);
    }else{
        const respTxt = yield response.text();
        yield put(getComunasRegistrarClienteSuccess(JSON.parse(respTxt)))
    }
}

function* postClienteEmpresa({payload}) {
    const requestURL = `registrar-cliente/empresa`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        //yield put(showAuthMessage(message));
        console.log("ha ocurrio un error en Saga postClienteEmpresa() : "+error);
    }else{
        const respTxt = yield response.text();
        yield put(postRegistrarClienteEmpresaSuccess(JSON.parse(respTxt)))
    }
}

function* postClientePersona({payload}) {
    const requestURL = `registrar-cliente/persona`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        //yield put(showAuthMessage(message));
        console.log("ha ocurrio un error en Saga postClientePersona() : "+error);
    }else{
        const respTxt = yield response.text();
        yield put(postRegistrarClientePersonaSuccess(JSON.parse(respTxt)))
    }
}

function* getActivarAccount({payload}) {
    const requestURL = `registrar-cliente/activar/${payload.hash}`;
    const response= yield call(getRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        //yield put(showAuthMessage(message));
        console.log("ha ocurrio un error en Saga getActivarAccount() : "+error);
    }else{
        const respTxt = yield response.text();
        yield put(getActivarAccountSuccess(JSON.parse(respTxt)))
    }
}

function* validateEmailRegistroCliente({payload}) {
    const requestURL = `registrar-cliente/validate-email`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        //yield put(showAuthMessage(message));
        console.log("ha ocurrio un error en Saga validarEmailRegistroCliente() : "+error);
    }else{
        const respTxt = yield response.text();
        yield put(validateEmailRegistroClienteSuccess(JSON.parse(respTxt)))
    }
}

function* getComercialesRegistrarClientes({payload}) {
    const requestURL = `clientes_get_list_comerciales_public`;
    const response= yield call(getRequest,requestURL,null,false);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageUserAux({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getComercialesRegistrarClienteSuccess(obj));
    }
}

function* postReserveIdRegistrarCliente({payload}) {
    const requestURL = `clientes/reserve_id`;
    const response= yield call(postRequest,requestURL,payload,true);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageUserAux({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(postReserveIdRegistrarClienteSuccess(obj));
        //yield getSagaExistsUserCotizadorClienteExecute(payload);
    }
}

function* validateRutRegistroCliente({payload}) {
    const requestURL = `registrar-cliente/validate-rut`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageUserAux({type:'error',text:message}));
        console.log(error);
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(validateRutRegistroClienteSuccess(obj));
        //yield getSagaExistsUserCotizadorClienteExecute(payload);
    }
}

function* postSagaDataContact({payload}) {
    const requestURL = `zoho/data-contact`;
    const response= yield call(postRequest,requestURL,payload,true);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageUserAux({type:'error',text:message}));
        console.log(error);
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        console.log(obj);
    }
}

export function* createUserAccount() {
    yield takeLatest(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
    yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithFacebook() {
    yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
    yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
    yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
    yield takeLatest(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeLatest(SIGNOUT_USER, signOut);
}

export function* validateEmailExecute() {
    yield takeLatest(VALIDATE_EMAIL, validateEmail);
}

export function* putPasswordExecute() {
    yield takeLatest(PUT_PASSWORD, putPassword);
}

export function* getRegionesExecute() {
    yield takeLatest(GET_REGIONES_REGISTRAR_CLIENTE, getRegiones);
}

export function* getComunasExecute() {
    yield takeLatest(GET_COMUNAS_REGISTRAR_CLIENTE, getComunas);
}

export function* postClienteEmpresaExecute() {
    yield takeLatest(POST_REGISTRAR_CLIENTE_EMPRESA, postClienteEmpresa);
}

export function* postClientePersonaExecute() {
    yield takeLatest(POST_REGISTRAR_CLIENTE_PERSONA, postClientePersona);
}

export function* getActivarAccountExecute() {
    yield takeLatest(GET_ACTIVAR_ACCOUNT, getActivarAccount);
}

export function* validateEmailRegistroClienteExecute() {
    yield takeLatest(VALIDATE_EMAIL_REGISTRO_CLIENTE, validateEmailRegistroCliente);
}

export function* getComercialesRegistrarClienteExecute() {
    yield takeLatest(GET_COMERCIALES_REGISTRAR_CLIENTES, getComercialesRegistrarClientes);
}

export function* postReserveIdRegistrarClienteExecute() {
    yield takeLatest(POST_RESERVE_ID_REGISTRAR_CLIENTES, postReserveIdRegistrarCliente);
}

export function* validateRutRegistroClienteExecute() {
    yield takeLatest(VALIDATE_RUT_REGISTRO_CLIENTE, validateRutRegistroCliente);
}

export function* postSagaDataContactExecute() {
    yield takeLatest(POST_DATA_CONTACT, postSagaDataContact);
}


export default function* rootSaga() {
    yield spawn(signInUser);
    yield spawn(createUserAccount);
    yield spawn(signInWithGoogle);
    yield spawn(signInWithFacebook);
    yield spawn(signInWithTwitter);
    yield spawn(signInWithGithub);
    yield spawn(signOutUser);
    yield spawn(validateEmailExecute);
    yield spawn(putPasswordExecute);
    yield spawn(getRegionesExecute);
    yield spawn(getComunasExecute);
    yield spawn(postClienteEmpresaExecute);
    yield spawn(postClientePersonaExecute);
    yield spawn(getActivarAccountExecute);
    yield spawn(validateEmailRegistroClienteExecute);
    yield spawn(getComercialesRegistrarClienteExecute);
    yield spawn(postReserveIdRegistrarClienteExecute);
    yield spawn(validateRutRegistroClienteExecute);
    yield spawn(postSagaDataContactExecute);
    /*yield all([fork(signInUser),
        fork(createUserAccount),
        fork(signInWithGoogle),
        fork(signInWithFacebook),
        fork(signInWithTwitter),
        fork(signInWithGithub),
        fork(signOutUser)]);*/
}