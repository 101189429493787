import React from 'react'
import './header.scss';

const Header = () => {  

    return (
        <>
            <div className={`header`}>
                <div className="container">
                   
                </div>
            </div>
        </>
    )
}

export default Header
