import {all, call, fork, put, takeEvery,takeLatest,spawn} from "redux-saga/effects";
import { putRequest, postRequest, getRequest, getErrorRequest, deleteRequest,uploadFilesRequest } from '../../util/apiFetch';
import {
    postUploadDocumentsExpressSuccess,
    postConsolidadoRapidoExpressSuccess,
    getInfoTrackConsolidadoRapidoExpressSuccess,
    updateStateNoConsolidadoRapidoExpressSuccess
} from './Actions';
import {
    POST_UPLOAD_DOCUMENTS_EXPRESS,
    POST_CONSOLIDADO_RAPIDO_EXPRESS,
    GET_INFO_TRACK_CONSOLIDADO_RAPIDO_EXPRESS,
    UPDATE_STATE_NO_CONSOLIDADO_RAPIDO_EXPRESS
} from './Constants';

function* postSagaUploadDocsExpressExecute({payload}){
    const fd=new FormData();
    if(payload.file1!=null){
        fd.append('packingList1',payload.file1,payload.file1.name);
    }

    if(payload.file2!=null){
        fd.append('invoice1',payload.file2,payload.file2.name);
    }

    const requestURL = `tracking/upload_docs_express/${payload.hash}`;
        const response= yield call(uploadFilesRequest,requestURL,fd);
        const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(postUploadDocumentsExpressSuccess(false));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(postUploadDocumentsExpressSuccess(true));
        
    }
}

function* postSagaConsolidadoRapidoExpressExecute({payload}){
    const requestURL = `consolidado_rapido`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
       // yield put(postUploadDocumentsExpressSuccess(false));
       if(message){
        yield put(postConsolidadoRapidoExpressSuccess({success:false,message:message}));
       }else{
        yield put(postConsolidadoRapidoExpressSuccess({success:false,message:'LOS PROVEEDORES HAN SIDO MODIFICADOS Y PROCESADOS, NO SE PUEDE REALIZAR LA CONSOLIDACIÓN RÁPIDA'}));
       }
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(postConsolidadoRapidoExpressSuccess(obj));
        
    }
}

function* getSagaInfoTrackConsolidadoRapidoExpressExecute({payload}){
    const requestURL = `get_info_track_consolidado_rapido/${payload.hash}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
       // yield put(postUploadDocumentsExpressSuccess(false));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getInfoTrackConsolidadoRapidoExpressSuccess(obj));   
    }
}


function* putSagaStateNoConsolidadoRapidoExpressExecute({payload}){
    const requestURL = `consolidado_rapido_no`;
    const response= yield call(putRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
       // yield put(postUploadDocumentsExpressSuccess(false));
       yield put(updateStateNoConsolidadoRapidoExpressSuccess({success:false,message:'LOS PROVEEDORES HAN SIDO MODIFICADOS Y PROCESADOS, NO SE PUEDE REALIZAR LA CONSOLIDACIÓN RÁPIDA'}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(updateStateNoConsolidadoRapidoExpressSuccess(obj));
    }
}



export function* postSagaUploadDocsExpress(){
    yield takeLatest(POST_UPLOAD_DOCUMENTS_EXPRESS, postSagaUploadDocsExpressExecute);
}

export function* postSagaConsolidadoRapidoExpress(){
    yield takeLatest(POST_CONSOLIDADO_RAPIDO_EXPRESS, postSagaConsolidadoRapidoExpressExecute);
}

export function* getSagaInfoTrackConsolidadoRapidoExpress(){
    yield takeLatest(GET_INFO_TRACK_CONSOLIDADO_RAPIDO_EXPRESS, getSagaInfoTrackConsolidadoRapidoExpressExecute);
}

export function* putSagaStateNoConsolidadoRapidoExpress(){
    yield takeLatest(UPDATE_STATE_NO_CONSOLIDADO_RAPIDO_EXPRESS, putSagaStateNoConsolidadoRapidoExpressExecute);
}

export default function* rootSaga() {
    yield spawn(postSagaUploadDocsExpress);    
    yield spawn(postSagaConsolidadoRapidoExpress); 
    yield spawn(getSagaInfoTrackConsolidadoRapidoExpress);
    yield spawn(putSagaStateNoConsolidadoRapidoExpress);
}