import React, { useEffect, useState, useMemo } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match'
import CardDesktop from './cards/CardOne'
import CardMobile from './cards/CardTwo'
import AccionRequerida from './form/AccionRequerida'


import {
  Button,
  Chip,
  Typography,
  IconButton,
  Hidden,
  makeStyles,
  Menu,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Fade,
  TextField,
  Box
} from "@material-ui/core";

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import LaunchIcon from '@material-ui/icons/Launch';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import HelpIcon from '@material-ui/icons/Help';

// Tooltip MUI //
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#263238',
    color: '#fff',
    maxWidth: 300,
    padding: "12px 16px",
    "& .MuiTypography-h5": {
      fontSize: "14px"
    },
    "& .MuiTypography-h6": {
      fontSize: "10px"
    }
  },
}))(Tooltip);
// 

const imagesInital = [
  {
    nuemeroServicio: "10",
    bultosEsperados: "8",
    bultosRecibidos: "8",
    volumenFinal: "2",
    pesoFinal: "34",
    primeraRecepcion: "23/89/2022",
    ultimaRecepcion: "30/02/2022",
    valorFinalServicio: "20.000.000",
    valorWsCargo: "15.000.000",
    valorImpuesto: "3.500.000",
    otroValor: "1.500.000",
    tags: "activo"

  },
  {
    nuemeroServicio: "50",
    bultosEsperados: "5",
    bultosRecibidos: "",
    volumenFinal: "2",
    pesoFinal: "",
    primeraRecepcion: "02/01/2022",
    ultimaRecepcion: "",
    valorFinalServicio: "1.000.0000",
    valorWsCargo: "740.000",
    valorImpuesto: "200.000",
    otroValor: "60.000",
    tags: "acción requerida"
  },
  {
    nuemeroServicio: "72",
    bultosEsperados: "7",
    bultosRecibidos: "1",
    volumenFinal: "2",
    pesoFinal: "59",
    primeraRecepcion: "23/89/2022",
    ultimaRecepcion: "30/02/2022",
    valorFinalServicio: "850.000",
    valorWsCargo: "350.000",
    valorImpuesto: "500.000",
    otroValor: "",
    tags: "alerta"
  }
];

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: "flex",
    flexDirection: "column"
  },
  border: {
    borderLeft: "solid 6px  #EF939B"
  },
  borderDanger: {
    borderLeft: "solid 6px  red"
  },
  border2: {
    borderLeft: "solid 6px  #FFCA6B"
  },
  border3: {
    borderLeft: "solid 6px  #6FCF97"
  },
  border4: {
    borderLeft: "solid 6px  #bdbdbd"
  },
  inputAdornment: {
    "& .MuiTypography-body1": {
      fontSize: "12px"
    }
  },
  icon: {
    fontSize: "14px",
    color: "#8F8F8F",
  }
}));

// Button tag
const TagButton = ({ name, handleSetTags, tagActive }) => {
  return (
    // <Button
    //   className={`button button-small ${tagActive ? 'button-border-danger' : 'button-text'}`}
    //   onClick={() => handleSetTags(name)}
    // >
    //   {name}
    // </Button>

    <Chip
      label={name}
      onClick={() => handleSetTags(name)}
      variant={tagActive ? 'outlined' : 'default'}
    />
  )
}

const Servicios = () => {
  const classes = useStyles();
  const [tag, setTags] = useState('todos')
  const [fiteredImages, setFiteredImages] = useState([])
  const [serviceDetail, setServiceDetail] = useState(null)
  const [inputSearch, setImputSerach] = useState(false)
  const [checked, setChecked] = useState(false);
  const [dropdown, setDropdown] = useState(null);
  const [openAccion, setOpenAccion] = useState(false);

  // dialog accion requerida
  const handleOpenAccion = () => {
      setOpenAccion(true);
  };
  const handleCloseAccion = () => {
      setOpenAccion(false);
  };


  // efecto tags
  useEffect(() => {
    tag === 'todos' ? setFiteredImages(imagesInital) : setFiteredImages(imagesInital.filter(imagesInital => imagesInital.tags === tag))
    setServiceDetail(null)
  }, [tag])

  const hideSerach = () => {
    setImputSerach(!inputSearch)
    setChecked((prev) => !prev);
  }

  const showDetail = (index) => {
    if (serviceDetail === index) {
      return setServiceDetail(null)
    }
    setServiceDetail(index);
  }

  // dropdown cards
  const handleClick = (event) => {
    setDropdown(event.currentTarget);
    console.log(setDropdown(event.currentTarget))
  };
  const handleClose = () => {
    setDropdown(null);
  };

  // class card servicio
  const classT = (tag) => {
    if (tag == "acción requerida") {
      return classes.border2;
    } else if (tag == "activo") {
      return classes.border3;
    } else if (tag == "alerta") {
      return classes.borderDanger;
    } else {
      return classes.border4;
    }
  }

  // class card servicio
  const classIcon = (tag) => {
    if (tag == "acción requerida") {
      return "icon-warning";
    } else if (tag == "activo") {
      return "icon-success"
    } else if (tag == "alerta") {
      return "icon-danger"
    } else {
      return "icon-default"
    }
  }


  return (
    <>
      {/* Titulo y buscador version mobile */}
      <Hidden smUp>
        <div className='app-tags'>
          <div className="app-search">
            <h4 className="title-h4 font-weight-700">Servicios</h4>

            <div className={inputSearch ? 'app-input-content show' : 'app-input-content'}>
              {
                inputSearch ?
                  (
                    <Fade in={checked}>
                      <div className="app-input-search input-material-ui">
                        <Autocomplete
                          options={imagesInital}
                          getOptionLabel={(option) => 'Nº servicio' + ' ' + option.nuemeroServicio}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Buscar Servicio" />
                          )}
                          noOptionsText={'No hay resultado...'}
                          renderOption={(option, { inputValue }) => {
                            const matches = match(option.nuemeroServicio, inputValue);
                            const parts = parse(option.nuemeroServicio, matches);

                            return (
                              <div>
                                {parts.map((part, index) => (
                                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                    Nº servicio {part.text}
                                  </span>
                                ))}
                              </div>
                            );
                          }}
                        />
                      </div>
                    </Fade>
                  ) : ""
              }
              <IconButton
                aria-label="agregar cotizacion"
                className="icon-button icon-button-danger icon-button-small ml-3"
                onClick={hideSerach}
              >
                {inputSearch ? <CloseIcon /> : <SearchIcon />}
              </IconButton>
            </div>
          </div>
        </div>
      </Hidden>


      {/* Tags y buscador */}
      <div className='app-buttons mt-2 mb-4' data-tour="cuatro">
        <div className="align-item-center">
          <TagButton name="todos" handleSetTags={setTags} tagActive={tag == "todos" ? true : false} />
          <TagButton name="acción requerida" handleSetTags={setTags} tagActive={tag == "acción requerida" ? true : false} />
          <TagButton name="activo" handleSetTags={setTags} tagActive={tag == "activo" ? true : false} />
          <TagButton name="alerta" handleSetTags={setTags} tagActive={tag == "alerta" ? true : false} />
        </div>

        {/* buscador versión desktop */}
        <Hidden smDown>
          <div className="align-item-center input-material-ui">
            {
              inputSearch ?
                (
                  <Fade in={checked}>
                    <Autocomplete
                      className="ml-3"
                      options={imagesInital}
                      getOptionLabel={(option) => 'Nº servicio' + ' ' + option.nuemeroServicio}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Buscar Servicio" />
                      )}
                      noOptionsText={'No hay resultado...'}
                      renderOption={(option, { inputValue }) => {
                        const matches = match(option.nuemeroServicio, inputValue);
                        const parts = parse(option.nuemeroServicio, matches);

                        return (
                          <div>
                            {parts.map((part, index) => (
                              <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                Nº servicio {part.text}
                              </span>
                            ))}
                          </div>
                        );
                      }}
                    />
                  </Fade>
                ) : ""
            }

            <IconButton
              aria-label="buscar servicios"
              className="icon-button icon-button-danger icon-button-small ml-3"
              onClick={hideSerach}
            >
              {inputSearch ? <CloseIcon /> : <SearchIcon />}
            </IconButton>
          </div>
        </Hidden>
      </div>

      {
        fiteredImages.map((item, index) => (
          <>
            <div className={`cards app-cards ${classT(item.tags)}`} key={index} data-tour="cinco">
              <div className="cards-body content-space-between py-md-3">

                {/* Info general del servicio */}
                <div className="card-data align-item-center" onClick={() => showDetail(index)} >
                  {/* { serviceDetail === index ?
                    <ArrowUpwardIcon className="mr-1 icon-color-default" /> 
                    :
                    <ArrowDownwardIcon className="mr-1 icon-color-default" />
                  } */}

                  <div className={`cards-icon icon-xl mr-3 ${classIcon(item.tags)}`}>
                    { item.tags == "alerta" || item.tags == "acción requerida" ?
                      <ReportProblemIcon fontSize="small" /> 
                      :
                      <DirectionsBoatIcon fontSize="small" />
                    }
                  </div>

                  <div className="card-item">
                    <h4 className="text-small font-weight-700">Nº Servicio {item.nuemeroServicio}</h4>
                    <div className='align-item-center'>
                      <h5 className="text-small font-weight-400">
                        Bultos: {item.bultosRecibidos == '' ? "0" : `${item.bultosRecibidos}`}
                        <span className='slash'>de</span>
                        {item.bultosEsperados == '' ? "0" : `${item.bultosEsperados}`}
                      </h5>

                      {/* toolpip  */}
                      <Hidden smDown>
                        <HtmlTooltip
                          title={
                            <React.Fragment>
                              <Typography variant="h6" color="inherit">Hemos recepcionado {item.bultosRecibidos} de {item.bultosEsperados} de tul bultos.</Typography>
                            </React.Fragment>
                          }
                        >
                          <HelpIcon className={`ml-1 ${classes.icon}`} />
                        </HtmlTooltip>
                      </Hidden>

                      <Hidden smDown>
                        <h5 className="text-small font-weight-400 mx-4">
                          Vol. Final: {item.volumenFinal == '' ? "S/I" : `${item.bultosEsperados} M³`}
                        </h5>

                        <h5 className="text-small font-weight-400">
                          Peso Final: {item.pesoFinal == '' ? "S/I" : `${item.pesoFinal} kg`}
                        </h5>
                      </Hidden>
                    </div>
                  </div>
                </div>
                {/* Fin - Info general del servicio */}


                {/* Acción menu (ver detalle y pagar), Precio del servicio y tooltip */}
                <div className="card-item justify-end">
                  {/* toolpip desktop */}
                  <Hidden smDown>
                    <Box className="align-item-center mr-2">
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography variant="h5" className="mb-1" color="inherit">Detalle de pago</Typography>
                            <Typography variant="h6" color="inherit">Ws Cargo: {item.valorWsCargo}</Typography>
                            <Typography variant="h6" color="inherit">Impuesto: {item.valorImpuesto}</Typography>
                            <Typography variant="h6" color="inherit">Otros: {item.otroValor}</Typography>
                          </React.Fragment>
                        }
                      >
                        {/* <div className={`cards-icon icon-small mr-2 ${classIcon(item.tags)}`}><AttachMoneyIcon fontSize="small" /></div> */}
                        <div className="cards-icon icon-xs icon-default mr-2">
                          <AttachMoneyIcon fontSize="small" />
                        </div>

                      </HtmlTooltip>
                      <p className="title-h5 font-weight-700">{item.valorFinalServicio}</p>
                    </Box>
                  </Hidden>

                  {/* Icon menu */}
                  <IconButton
                    aria-label="Ejecutivo"
                    aria-controls="simple-menu2"
                    aria-haspopup="true"
                    onClick={handleClick}
                    className="icon-button"
                  >
                    <MoreVertIcon fontSize="small" />
                  </IconButton>

                  <Menu
                    id="simple-menu2"
                    anchorEl={dropdown}
                    keepMounted
                    open={Boolean(dropdown)}
                    onClose={handleClose}
                    className="app-dropdown mt-5"
                    variant="selectedMenu"
                  >
                    {/* <MenuItem component={Link} to="#" onClick={handleClose}>
                      <ListItemIcon>
                        <CreditCardIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="inherit">Pagar</Typography>
                    </MenuItem> */}

                    <MenuItem component={Link} to="#" onClick={handleClose}>
                      {/* <ListItemIcon>
                        <LaunchIcon fontSize="small" />
                      </ListItemIcon> */}
                      <Typography variant="inherit">Descargar Etiquetas</Typography>
                    </MenuItem>

                    <MenuItem component={Link} to="#" onClick={handleClose}>
                      {/* <ListItemIcon>
                        <LaunchIcon fontSize="small" />
                      </ListItemIcon> */}
                      <Typography variant="inherit">Ver detalle</Typography>
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>

            {/* Detalle del servicio */}
            <div className={serviceDetail === index ? "card-detail show" : "card-detail"} >
              <div className="cards-body cards-padding pt-0">
                <Hidden smDown>
                  <CardDesktop 
                    item={item} 
                    handleOpenAccion={handleOpenAccion}

                  />
                </Hidden>

                <Hidden smUp>
                  <CardMobile 
                    item={item}
                    handleOpenAccion={handleOpenAccion}
                  />
                </Hidden>
              </div>
            </div>
          </>
        ))
      }

      {/* Dialog para visualizar las acciones pendiente del servicio */}
      <AccionRequerida
        openAccion={openAccion}
        handleCloseAccion={handleCloseAccion}
      />
    </>
  )
}

export default Servicios