import {all, call, fork, put, takeEvery,takeLatest,spawn} from "redux-saga/effects";
import { putRequest, postRequest, getRequest, getErrorRequest, deleteRequest,uploadFilesRequest } from '../../util/apiFetch';
import lodash from 'lodash';
import envConfig from '../../envConfig';

import {
GET_COTIZACIONES_DASHBOARD,
GET_ZONASTARIFARIAS_DASHBOARD,
GET_PROPUESTABASE_DASHBOARD,
POST_COTIZACION_DASHBOARD,
DELETE_COTIZACION_DASHBOARD,
GET_EMPRESAS_DASHBOARD,
GET_REGIONES_DASHBOARD,
GET_COMUNAS_DASHBOARD,
GET_SERVICIOS_DASHBOARD,
POST_SERVICIO_DASHBOARD,
POST_TRACKINGS_INCOMPLETE_DASHBOARD,
GET_COMERCIALES_DASHBOARD,
GET_HISTORY_SERVICES_DASHBOARD,
GET_STATES_COMPLETE_SERVICES_DASHBOARD,
PUT_REFERENCIA_DASHBOARD,
GET_IS_FIRST_ACCESS_DASHBOARD,
GET_CLIENTS_SUPERADMIN_DASHBOARD,
GET_SERVICES_SUPERADMIN_DASHBOARD,
GET_FECHAS_ESTIMADAS,
GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE,
GET_EMPRESA_DASHBOARD_BY_FKCLIENTE,
GET_COTIZACIONES_BY_FKCLIENTE_DASHBOARD,
GET_PROVEEDORES_CNSLD_RAPIDA_DASHBOARD,
GETENCUESTASPENDIENTES,
HOME_MODALPAGARSERVICIO_GETINFO,
HOME_REALIZARPAGOSERVICIO,
GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE_INTERVALS,
DASHBOARD_SEND_INVITATION_PROVIDER,
GET_TIMELINE_DASHBOARD
} from './Constants';

import {
   showMessageDashboard,
   getCotizacionesDashboardSuccess,
   getZonasTarifariasDashboardSuccess,
   getPropuestaBaseDashboardSuccess,
   postCotizacionDashboardSuccess,
   getEmpresasDashboardSuccess,
   getRegionesDashboardSuccess,
   getComunasDashboardSuccess,
   getServiciosDashboardSuccess,
   postServicioDashboardSuccess,
   postTrackingsIncompleteDashboardSuccess,
   getComercialesDashboardSuccess,
   getHistoryServicesDashboardSuccess,
   getStatesCompleteServicesDashboardSuccess,
   putReferenciaDashboardSuccess,
   getIsFirstAccessDashboardSuccess,
   getClientsSuperadminDashboardSuccess,
   getServicesSuperadminDashboardSuccess,
   getFechasEstimadasSuccess,
   getServiciosDashboardByFkClienteSuccess,
   getEmpresaDashboardByFkClienteSuccess,
   getCotizacionesDashboardByFkClienteSuccess,
   getProveedoresCnsldRapidaDashboardSuccess,
   GetEncuestasPendientes_Success,
   Home_ModalPagarServicio_GetInfo_Success,
   Home_RealizarPagoServicio_Success,
   Home_SetMostrarLoadingPagos,
   getTimelimeDashboardSuccess
} from './Actions';

import { SpaceBarRounded } from "@material-ui/icons";


function* getSagaCotizacionesDashboardExecute({payload}){
   const requestURL = `cotizaciones`;
   const response= yield call(getRequest,requestURL);
   const { error, message } =yield call(getErrorRequest,response);
   if(error){
       yield put(showMessageDashboard({type:'error',text:message}));
   }else{
       const respTxt=yield response.text();
       const obj=JSON.parse(respTxt);
       yield put(getCotizacionesDashboardSuccess(obj));
   }
}


function* getSagaZonasTarifariasDashboardExecute({payload}){
   const requestURL = `propuestas_get_zonastarifarias`;
   const response= yield call(getRequest,requestURL,null,false);
   const { error, message } =yield call(getErrorRequest,response);

   if(error){
       yield put(showMessageDashboard({type:'error',text:message}));
   }else{
       const respTxt=yield response.text();
       const obj=JSON.parse(respTxt);
       yield put(getZonasTarifariasDashboardSuccess(obj));
   }
}

function* getSagaPropuestaBaseDashboardExecute({payload}){
   const requestURL = `propuestas_get_propuestabase`;
   const response= yield call(postRequest,requestURL,payload,false);
   const { error, message } =yield call(getErrorRequest,response);

   if(error){
       yield put(showMessageDashboard({type:'error',text:message}));
   }else{
       const respTxt=yield response.text();
       const obj=JSON.parse(respTxt);
       if(obj.length==0){
           //yield put(showMessageCotizadorCliente({type:'warning',text:'NO EXISTE TARIFA PARA DICHAS ZONAS'}));
       }
       yield put(getPropuestaBaseDashboardSuccess(obj));
   }
}

function* postSagaCotizacionDashboardExecute({payload}){
   const requestURL = `cotizador_simple`;
   const response= yield call(postRequest,requestURL,payload);
   const { error, message } =yield call(getErrorRequest,response);

   if(error){
       yield put(showMessageDashboard({type:'error',text:message}));
   }else{
       const respTxt=yield response.text();
       const obj=JSON.parse(respTxt);
       if(obj.length==0){
           //yield put(showMessageCotizadorCliente({type:'warning',text:'NO EXISTE TARIFA PARA DICHAS ZONAS'}));
       }
       //yield getSagaCotizacionesDashboardExecute({payload:null});
       yield put(showMessageDashboard({type:'success',text:'COTIZACIÓN HA SIDO CREADA CORRECTAMENTE'}));
       yield getSagaCotizacionesByFkClienteDashboardExecute({ payload:{ id :payload.fk_cliente }})
       yield put(postCotizacionDashboardSuccess());
   }
}

function* deleteSagaCotizacionDashboardExecute({payload}){

    const requestURL = `cotizador_simple/${payload.id}`;
    const response= yield call(deleteRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
       yield put(showMessageDashboard({type:'error',text:message}));
    }else{
       const respTxt=yield response.text();
       const obj=JSON.parse(respTxt);
       yield put(showMessageDashboard({type:'success',text:'COTIZACIÓN ELIMINADA CORRECTAMENTE'}));
       //yield getSagaCotizacionesDashboardExecute({payload:null});   
       yield getSagaCotizacionesByFkClienteDashboardExecute({payload:{ id: payload.fk_cliente }})
    } 
}

function* getSagaEmpresasDashboardExecute({payload}){
    //alert(JSON.stringify(localStorage.getItem('user_id')));
    const requestURL = `registro_cliente/listado/`+localStorage.getItem('user_id');
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getEmpresasDashboardSuccess(obj));
    }
}

function* getSagaRegionesDashboardExecute({payload}){
    const requestURL = `region_get_list`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getRegionesDashboardSuccess(obj));
    }
}

function* getSagaComunasDashboardExecute({payload}){
    const requestURL = `comuna_get_list`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getComunasDashboardSuccess(obj));
    }
}

function* getSagaServiciosDashboardExecute({payload}){
    const requestURL = `servicios`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getServiciosDashboardSuccess(obj));
        yield getSagaHistorServicesDashboardExecute(obj);
        yield getSagaStatesCompleteServicesDashboardExecute(obj);
    }
}

const clearPayloadFiles =(payload)=>{
    let files=[];
    let newPayload=lodash.cloneDeep(payload);
    if(newPayload.servicio_proveedores && newPayload.servicio_proveedores.length>0){
        for(let i=0;i<newPayload.servicio_proveedores.length;i++){
            if(newPayload.servicio_proveedores[i].packingList1!=null || newPayload.servicio_proveedores[i].packingList2!=null){
                let obj={index:i,packingList1:null,packingList2:null};
                if(newPayload.servicio_proveedores[i].packingList1!=null){
                    obj.packingList1=newPayload.servicio_proveedores[i].packingList1
                }
                
                if(newPayload.servicio_proveedores[i].packingList2!=null){
                    obj.packingList2=newPayload.servicio_proveedores[i].packingList2
                }
                files.push(obj);
            }
            delete newPayload.servicio_proveedores[i].packingList1;
            delete newPayload.servicio_proveedores[i].packingList2;
        }
    }
    return [newPayload,files];
}

function* uploadFiles(dataPost,files){
    let proveedores=[];
    if(dataPost.servicio_proveedores && dataPost.servicio_proveedores.length>0){
        for(let i=0;i<dataPost.servicio_proveedores.length;i++){
            let filterFiles=files.filter(x=>x.index===dataPost.servicio_proveedores[i].index);
            if(filterFiles && filterFiles.length>0){
                proveedores.push({id:dataPost.servicio_proveedores[i].id,packingList1:filterFiles[0].packingList1,packingList2:filterFiles[0].packingList2});
            }
        }
    }
    yield all(proveedores.map(item=>call(uploadDocsServices,item)));
}

function* uploadDocsServices(item){
    if(item){
        const fd=new FormData();

        if(item.packingList1!=null){
            fd.append('packingList1',item.packingList1,item.packingList1.name);
        }

        if(item.packingList2!=null){
            fd.append('packingList2',item.packingList2,item.packingList2.name);
        }

        const requestURL = `servicios/upload_docs/${item.id}`;
        const response= yield call(uploadFilesRequest,requestURL,fd);
        const { error, message } =yield call(getErrorRequest,response);
        if(error){
           // yield put(showMessageTrackingFullEdicion({type:'error',text:message}));
        }else{
           // alert('Archivos subidos correctamente');
            //yield getTrackingManualDataExecute({flagModal:true,id:action.payload.id});
        }
    }
}

function* postSagaServicioDashboardExecute({payload}){
    console.log(payload);
    const requestURL = `servicios`;
    let data=clearPayloadFiles(payload);
    const response= yield call(postRequest,requestURL,data[0]);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        if(data[1].length>0){
            yield uploadFiles(obj,data[1]);
        }
        yield put(showMessageDashboard({type:'success',text:'SERVICIO CREADO CORRECTAMENTE'}));
        //yield getSagaServiciosDashboardExecute({payload:null});
        yield getSagaServiciosDashboardByFkClienteExecute({ payload : { id: payload.fk_cliente }})
        yield put(postServicioDashboardSuccess());
        
    }
}

function* postSagaTrackingsIncompleteDashboardExecute({payload}){
    const requestURL = `get_trackings_incomplete`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(postTrackingsIncompleteDashboardSuccess(obj));
    }
}


function* getSagaComercialesDashboardExecute({payload}){
    const requestURL = `clientes_get_list_comerciales_public`;
    const response= yield call(getRequest,requestURL,null,false);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getComercialesDashboardSuccess(obj));
    }
}

function* getSagaHistorServicesDashboardExecute(services){
    const requestURL = `servicios/get_service_history`;
    const response= yield call(postRequest,requestURL,{servicios:services});
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getHistoryServicesDashboardSuccess(obj))
    }
}

function* getSagaStatesCompleteServicesDashboardExecute(services){
    const requestURL = `servicios/get_service_states_complete`;
    const response= yield call(postRequest,requestURL,{servicios:services});
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getStatesCompleteServicesDashboardSuccess(obj))
    }
}

function* putSagaReferenciaDashboardExecute({payload}){
    console.log(payload);
    const requestURL = `servicios/${payload.id}/referencia`;
    const response= yield call(putRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(showMessageDashboard({type:'success',text:'SERVICIO ACTUALIZADO CORRECTAMENTE'}));
        yield put(putReferenciaDashboardSuccess(obj));
        console.log({ id: payload.fk_cliente });
        yield getSagaServiciosDashboardByFkClienteExecute({ payload : { id: payload.fk_cliente }})
        //yield getSagaServiciosDashboardExecute({payload:null});
    }
}

function* getSagaIsFirstAccessDashboardExecute({payload}){
    const requestURL = `user/${payload.id}/is-first-access`;
    const response= yield call(getRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getIsFirstAccessDashboardSuccess(obj));
    }
}

function* getSagaClientsSuperAdminDashboardExecute(){
    const requestURL = `superadmin/clients`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getClientsSuperadminDashboardSuccess(obj));
    }
}

function* getSagaServicesSuperAdminDashboardExecute({payload}){
    const requestURL = `superadmin/services/${payload.id}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getServicesSuperadminDashboardSuccess(obj));
        if(obj && obj.length>0){
            yield getSagaHistorServicesDashboardExecute(obj);
            yield getSagaStatesCompleteServicesDashboardExecute(obj);
        }
    }
}

function* getSagaFechasEstimadasExecute(){
    const requestURL = `fechas-estimadas`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getFechasEstimadasSuccess(obj));
    }
}

function* getSagaServiciosDashboardByFkClienteExecute({payload}){
    const requestURL = `servicios/fk_cliente/${payload.id}/${payload.estado}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getServiciosDashboardByFkClienteSuccess(obj));
        yield getSagaHistorServicesDashboardExecute(obj);
        yield getSagaStatesCompleteServicesDashboardExecute(obj);
    }
}

function* getSagaEmpresaDashboardByFkClienteExecute({payload}){
    const requestURL = `registro/${payload.id}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getEmpresaDashboardByFkClienteSuccess(obj));
    }
}

function* getSagaCotizacionesByFkClienteDashboardExecute({payload}){
    //alert(localStorage.getItem('user_id'));
    const requestURL = `cotizaciones/`+localStorage.getItem('user_id');
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getCotizacionesDashboardSuccess(obj));
    }
}

function* getSagaProveedoresCnsldRapidaDashboardExecute({payload}){
    const requestURL = `trackings/consolidacion-rapida/${payload.fk_cliente}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getProveedoresCnsldRapidaDashboardSuccess(obj));
    }
}

function* GetEncuestasPendientes_Execute(payload){
    const requestURL = `getencuestaspendientes/`+payload.payload;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error)
    {
        yield put(showMessageDashboard({type:'error',text:message}));
    }
    else
    {
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(GetEncuestasPendientes_Success(obj));
    }
}

function* Home_ModalPagarServicio_GetInfoExecute(payload){
    const requestURL = `getinfopagoservicio/`+payload.payload;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error)
    {
        yield put(showMessageDashboard({type:'error',text:message}));
    }
    else
    {
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(Home_ModalPagarServicio_GetInfo_Success(obj));
    }
}

function* Home_RealizarPagoServicioExecute({payload}){

    const requestURL = `realizar_pago_servicio`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error)
    {
        yield put(Home_SetMostrarLoadingPagos(false));
        yield put(showMessageDashboard({type:'error',text:message}));
    }
    else
    {
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);

        if( payload.MedioPago==='WEBPAY')
        {
            var form = document.createElement("form");
            var element1 = document.createElement("input"); 
            form.method = "POST";
            form.action = obj.message.url;
            element1.value=obj.message.token;
            element1.type='hidden';
            element1.name="token_ws";
            form.appendChild(element1);  
            document.body.appendChild(form);
            form.submit();
        }
        else if( payload.MedioPago==='KHIPU')
        {
            window.location.href = obj.message;
        }
        yield put(Home_SetMostrarLoadingPagos(false));
    }
}

function* getSagaServiciosDashboardByFkClienteIntervalsExecute({payload}){
    const requestURL = `servicios/fk_cliente/${payload.id}/${payload.estado}/${payload.desde}/${payload.hasta}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getServiciosDashboardByFkClienteSuccess(obj));
        yield getSagaHistorServicesDashboardExecute(obj);
        yield getSagaStatesCompleteServicesDashboardExecute(obj);
    }
}

function* dashboardSagaSendInvitationProviderExecute({payload}){
    const requestURL = `proveedores/send_invitation_provider`;
    const response= yield call(postRequest,requestURL,payload,true);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(showMessageDashboard({type:'success',text:'EMAIL ENVIADO CORRECTAMENTE'}));
    }
}

function* dashboardSagaGetTimelineExecute(action){
    const requestURL = `servicios/get-timeline`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDashboard({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getTimelimeDashboardSuccess(obj));   
    }
}

export function* GetEncuestasPendientes(){
    yield takeLatest(GETENCUESTASPENDIENTES, GetEncuestasPendientes_Execute);
 }

export function* getSagaCotizacionesDashboard(){
   yield takeLatest(GET_COTIZACIONES_DASHBOARD, getSagaCotizacionesDashboardExecute);
}

export function* getSagaZonasTarifariasDashboard(){
   yield takeLatest(GET_ZONASTARIFARIAS_DASHBOARD, getSagaZonasTarifariasDashboardExecute);
}

export function* getSagaPropuestaBaseDashboard(){
   yield takeLatest(GET_PROPUESTABASE_DASHBOARD, getSagaPropuestaBaseDashboardExecute);
}

export function* postSagaCotizacionDashboard(){
   yield takeLatest(POST_COTIZACION_DASHBOARD, postSagaCotizacionDashboardExecute);
}

export function* deleteSagaCotizacionDashboard(){
    yield takeLatest(DELETE_COTIZACION_DASHBOARD, deleteSagaCotizacionDashboardExecute);
 }

 export function* getSagaEmpresasDashboard(){
    yield takeLatest(GET_EMPRESAS_DASHBOARD, getSagaEmpresasDashboardExecute);
 }

 export function* getSagaRegionesDashboard(){
    yield takeLatest(GET_REGIONES_DASHBOARD, getSagaRegionesDashboardExecute);
 }

 export function* getSagaComunasDashboard(){
    yield takeLatest(GET_COMUNAS_DASHBOARD, getSagaComunasDashboardExecute);
 }

 export function* getSagaServiciosDashboard(){
    yield takeLatest(GET_SERVICIOS_DASHBOARD, getSagaServiciosDashboardExecute);
 }

 export function* postSagaServicioDashboard(){
    yield takeLatest(POST_SERVICIO_DASHBOARD, postSagaServicioDashboardExecute);
 }

 export function* postSagaTrackingsIncompleteDashboard(){
    yield takeLatest(POST_TRACKINGS_INCOMPLETE_DASHBOARD, postSagaTrackingsIncompleteDashboardExecute);
 }

 export function* getSagaComercialesDashboard(){
    yield takeLatest(GET_COMERCIALES_DASHBOARD, getSagaComercialesDashboardExecute);
 }

 export function* getSagaHistorServicesDashboard(){
    yield takeLatest(GET_HISTORY_SERVICES_DASHBOARD, getSagaHistorServicesDashboardExecute);
 }

 export function* getSagaStatesCompleteServicesDashboard(){
    yield takeLatest(GET_STATES_COMPLETE_SERVICES_DASHBOARD, getSagaStatesCompleteServicesDashboardExecute);
 }

 export function* putSagaReferenciaDashboard(){
    yield takeLatest(PUT_REFERENCIA_DASHBOARD, putSagaReferenciaDashboardExecute);
 }
 
 export function* getSagaIsFirstAccessDashboard(){
    yield takeLatest(GET_IS_FIRST_ACCESS_DASHBOARD, getSagaIsFirstAccessDashboardExecute);
 }

 export function* getSagaClientsSuperAdminDashboard(){
    yield takeLatest(GET_CLIENTS_SUPERADMIN_DASHBOARD, getSagaClientsSuperAdminDashboardExecute);
 }

 export function* getSagaServicesSuperAdminDashboard(){
    yield takeLatest(GET_SERVICES_SUPERADMIN_DASHBOARD, getSagaServicesSuperAdminDashboardExecute);
 }

 export function* getSagaFechasEstimadas(){
    yield takeLatest(GET_FECHAS_ESTIMADAS, getSagaFechasEstimadasExecute);
 }
 
 export function* getSagaServiciosDashboardByFkCliente(){
    yield takeLatest(GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE, getSagaServiciosDashboardByFkClienteExecute);
 }
 
 export function* getSagaEmpresaDashboardByFkCliente(){
    yield takeLatest(GET_EMPRESA_DASHBOARD_BY_FKCLIENTE, getSagaEmpresaDashboardByFkClienteExecute);
 }

 export function* getSagaCotizacionesByFkClienteDashboard(){
    yield takeLatest(GET_COTIZACIONES_BY_FKCLIENTE_DASHBOARD, getSagaCotizacionesByFkClienteDashboardExecute);
 }

 export function* getSagaProveedoresCnsldRapidaDashboard(){
    yield takeLatest(GET_PROVEEDORES_CNSLD_RAPIDA_DASHBOARD, getSagaProveedoresCnsldRapidaDashboardExecute);
 }

 export function* getSagaServiciosDashboardByFkClienteIntervals(){
    yield takeLatest(GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE_INTERVALS, getSagaServiciosDashboardByFkClienteIntervalsExecute);
 }

 export function* Home_ModalPagarServicio_GetInfo(){
    yield takeLatest(HOME_MODALPAGARSERVICIO_GETINFO, Home_ModalPagarServicio_GetInfoExecute);
 }
 
 export function* Home_RealizarPagoServicio(){
    yield takeLatest(HOME_REALIZARPAGOSERVICIO, Home_RealizarPagoServicioExecute);
 }

 export function* dashboardSagaSendInvitationProvider(){
    yield takeLatest(DASHBOARD_SEND_INVITATION_PROVIDER, dashboardSagaSendInvitationProviderExecute);
 }

 export function* dashboardSagaGetTimeline(){
    yield takeLatest(GET_TIMELINE_DASHBOARD, dashboardSagaGetTimelineExecute);
 }

 
 
export default function* rootSaga() {
    
   yield spawn(Home_RealizarPagoServicio);
   yield spawn(Home_ModalPagarServicio_GetInfo);
   yield spawn(GetEncuestasPendientes);
   yield spawn(getSagaCotizacionesDashboard);
   yield spawn(getSagaZonasTarifariasDashboard);
   yield spawn(getSagaPropuestaBaseDashboard);
   yield spawn(postSagaCotizacionDashboard);
   yield spawn(deleteSagaCotizacionDashboard);
   yield spawn(getSagaEmpresasDashboard);
   yield spawn(getSagaRegionesDashboard);
   yield spawn(getSagaComunasDashboard);
   yield spawn(getSagaServiciosDashboard);
   yield spawn(postSagaServicioDashboard);
   yield spawn(postSagaTrackingsIncompleteDashboard);
   yield spawn(getSagaComercialesDashboard);
   yield spawn(getSagaHistorServicesDashboard);
   yield spawn(getSagaStatesCompleteServicesDashboard);
   yield spawn(putSagaReferenciaDashboard);
   yield spawn(getSagaIsFirstAccessDashboard);
   yield spawn(getSagaClientsSuperAdminDashboard);
   yield spawn(getSagaServicesSuperAdminDashboard);
   yield spawn(getSagaFechasEstimadas);
   yield spawn(getSagaServiciosDashboardByFkCliente);
   yield spawn(getSagaEmpresaDashboardByFkCliente);
   yield spawn(getSagaCotizacionesByFkClienteDashboard);
   yield spawn(getSagaProveedoresCnsldRapidaDashboard);
   yield spawn(getSagaServiciosDashboardByFkClienteIntervals);
   yield spawn(dashboardSagaSendInvitationProvider);
   yield spawn(dashboardSagaGetTimeline);
}