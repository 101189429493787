
import{
    POST_COTIZADOR_CLIENTE,
    POST_COTIZADOR_CLIENTE_SUCCESS,
    GLOBAL_CHANGE_STATES_COTIZADOR_CLIENTE,
    GET_COMERCIALES_COTIZADOR_CLIENTE,
    GET_COMERCIALES_COTIZADOR_CLIENTE_SUCCESS,
    GET_PROPUESTA_BASE_COTIZADOR_CLIENTE,
    GET_PROPUESTA_BASE_COTIZADOR_CLIENTE_SUCCESS,
    GET_REGIONES_COTIZADOR_CLIENTE,
    GET_REGIONES_COTIZADOR_CLIENTE_SUCCESS,
    GET_COMUNAS_COTIZADOR_CLIENTE,
    GET_COMUNAS_COTIZADOR_CLIENTE_SUCCESS,
    POST_ID_CLIENTE_COTIZADOR_CLIENTE,
    POST_ID_CLIENTE_COTIZADOR_CLIENTE_SUCCESS,
    SHOW_MESSAGE_COTIZADOR_CLIENTE,
    HIDE_MESSAGE_COTIZADOR_CLIENTE,
    GET_EXISTS_USER_COTIZADOR_CLIENTE,
    GET_EXISTS_USER_COTIZADOR_CLIENTE_SUCCESS,
    GET_ZONAS_TARIFARIAS_COTIZADOR_CLIENTE,
    GET_ZONAS_TARIFARIAS_COTIZADOR_CLIENTE_SUCCESS,
    VALIDATE_RUT_COTIZADOR,
    VALIDATE_RUT_COTIZADOR_SUCCESS,
    DATA_COTIZACION,
    CLEAR_ALL_EXPDIG_COTIZADOR_SUCCESS
} from './Constants';

export const Clear_All_ExpDig_Cotizador_Success = () => {
    return {
        type: CLEAR_ALL_EXPDIG_COTIZADOR_SUCCESS,
    };
};

export const postCotizadorCliente = (payload) => {
    return {
        type: POST_COTIZADOR_CLIENTE,
        payload:payload
    };
};

export const postCotizadorClienteSuccess = (payload) => {
    return {
        type: POST_COTIZADOR_CLIENTE_SUCCESS,
        payload:payload
    };
};

export const  globalChangeStatesCotizadorCliente= (atributte,payload) => {
    return {
        type: GLOBAL_CHANGE_STATES_COTIZADOR_CLIENTE,
        atributte:atributte,
        payload: payload
    };
};

export const getComercialesCotizadorCliente = (payload) => {
    return {
        type: GET_COMERCIALES_COTIZADOR_CLIENTE,
        payload:payload
    };
};

export const getComercialesCotizadorClienteSuccess = (payload) => {
    return {
        type: GET_COMERCIALES_COTIZADOR_CLIENTE_SUCCESS,
        payload:payload
    };
};

export const getPropuestaBaseCotizadorCliente = (payload) => {
    return {
        type: GET_PROPUESTA_BASE_COTIZADOR_CLIENTE,
        payload:payload
    };
};

export const getPropuestaBaseCotizadorClienteSuccess = (payload) => {
    return {
        type: GET_PROPUESTA_BASE_COTIZADOR_CLIENTE_SUCCESS,
        payload:payload
    };
};

export const getRegionesCotizadorCliente = (payload) => {
    return {
        type: GET_REGIONES_COTIZADOR_CLIENTE,
        payload:payload
    };
};

export const getRegionesCotizadorClienteSuccess = (payload) => {
    return {
        type: GET_REGIONES_COTIZADOR_CLIENTE_SUCCESS,
        payload:payload
    };
};

export const getComunasCotizadorCliente = (payload) => {
    return {
        type: GET_COMUNAS_COTIZADOR_CLIENTE,
        payload:payload
    };
};

export const getComunasCotizadorClienteSuccess = (payload) => {
    return {
        type: GET_COMUNAS_COTIZADOR_CLIENTE_SUCCESS,
        payload:payload
    };
};

export const postIdClienteCotizadorCliente = (payload) => {
    return {
        type: POST_ID_CLIENTE_COTIZADOR_CLIENTE,
        payload:payload
    };
};

export const postIdClienteCotizadorClienteSuccess = (payload) => {
    return {
        type: POST_ID_CLIENTE_COTIZADOR_CLIENTE_SUCCESS,
        payload:payload
    };
};

export const showMessageCotizadorCliente = (payload) => {
    return {
        type: SHOW_MESSAGE_COTIZADOR_CLIENTE,
        payload:payload
    };
};

export const hideMessageCotizadorCliente = (payload) => {
    return {
        type: HIDE_MESSAGE_COTIZADOR_CLIENTE,
        payload:payload
    };
};

export const getExistsUserCotizadorCliente = (payload) => {
    return {
        type: GET_EXISTS_USER_COTIZADOR_CLIENTE,
        payload:payload
    };
};

export const getExistsUserCotizadorClienteSuccess = (payload) => {
    return {
        type: GET_EXISTS_USER_COTIZADOR_CLIENTE_SUCCESS,
        payload:payload
    };
};

export const getZonasTarifariasCotizadorCliente = (payload) => {
    return {
        type: GET_ZONAS_TARIFARIAS_COTIZADOR_CLIENTE,
        payload:payload
    };
};

export const getZonasTarifariasCotizadorClienteSuccess = (payload) => {
    return {
        type: GET_ZONAS_TARIFARIAS_COTIZADOR_CLIENTE_SUCCESS,
        payload:payload
    };
};

export const validateRutCotizador = (payload) => {
    return {
        type: VALIDATE_RUT_COTIZADOR,
        payload:payload
    };
};


export const validateRutCotizadorSuccess = (payload) => {
    return {
        type: VALIDATE_RUT_COTIZADOR_SUCCESS,
        payload:payload
    };
};

export const postDataCotizacion = (payload) => {
    return {
        type: DATA_COTIZACION,
        payload:payload
    };
};









































