import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validate, clean, format, getCheckDigit } from 'rut.js';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ModalRetiro from './ModalRetiro';
import {Popover, PopoverHeader, PopoverBody  } from 'reactstrap';
import {Paper, Grid} from "@material-ui/core";
// import DatePicker from 'material-ui/DatePicker';
// import DatePicker from "react-datepicker";

// import DatePicker, { registerLocale } from "react-datepicker";
// import es from 'date-fns/locale/es'; // the locale you want
// registerLocale("es", es); // register it with the name you want



import "react-datepicker/dist/react-datepicker.css";
// import { registerLocale, setDefaultLocale,DatePicker  } from  "react-datepicker";
// import es from 'date-fns/locale/es';
// registerLocale('es', es)


import {
  makeStyles,
  Button,
  InputLabel,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Hidden
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import moment from "moment";
import { payloadServicioDespachoRetiro } from '../../models';

import {
  postDespachoRetiroSolicAgendamiento,
  setMessageSolicAgendamiento,
  getBloqueosRetiroSolicAgendamiento,
  getDisponibilidadRetiroSolicAgendamiento
 /* putServicioDespachoRetiroDetalleServicio,
  showMessageDetalleServicio,
  postAgendaRetiroByMonthDetalleServicio,
  getBloqueosRetiroByDayDetalleServicio*/
} from './Actions';
import lodash, { set } from 'lodash';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from "date-fns/locale/es";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
registerLocale('es', es);

// lista de bodegas
const bodegas = [
  { id: '1', nombre: 'Bodega Noviciado. Camino a noviciado' },
];

const useStyles = makeStyles((theme) => ({

  listItem: {
    display: "flex",
    flexDirection: "column"
  },

  border: {
    borderLeft: "solid 6px  #EA5259"
  },
  border2: {
    borderLeft: "solid 6px #F8C96A"
  },
  icon: {
    fontSize: "16px",
    color: "8F8F8F",
    marginRight: "8px"
  },
  notInThisMonthDayPaper: {
    width: "35px",
    height: "35px",
    backgroundColor: "#eeeeee",
    margin: "3px",
    boxShadow: "none",
    borderRadius: "10%",
    padding: "1px",
},
normalDayPaper: {
    width: "35px",
    height: "35px",
    backgroundColor: "#e8f5e9",
    margin: "3px",
    boxShadow: "none",
    borderRadius: "10%",
    padding: "1px",
    cursor: "pointer",
},
enabledDayPaper: {
    width: "35px",
    height: "35px",
    backgroundColor: "#2BD82B",
    margin: "3px",
    boxShadow: "none",
    borderRadius: "10%",
    padding: "1px",
    cursor: "pointer",
},
weekendDayPaper:{
  width: "35px",
    height: "35px",
    backgroundColor: "#D8D8D8",
    margin: "3px",
    boxShadow: "none",
    borderRadius: "10%",
    padding: "1px",
    cursor: "pointer",
},
blockedDayPaper: {
    width: "35px",
    height: "35px",
    backgroundColor: "#FD564F",
    margin: "3px",
    boxShadow: "none",
    borderRadius: "10%",
    padding: "1px",
    cursor: "pointer",
},
warningDayPaper: {
    width: "35px",
    height: "35px",
    backgroundColor: "#F0F924",
    margin: "3px",
    boxShadow: "none",
    borderRadius: "10%",
    padding: "1px",
    cursor: "pointer",
},
selectedDayPaper: {
    width: "35px",
    height: "35px",
    backgroundColor: "#1B8FEA",
    margin: "3px",
    boxShadow: "none",
    borderRadius: "10%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#1B8FEA",
    padding: "1px",
    cursor: "pointer",
    color:"white"
},
todayPaper: {
    width: "35px",
    height: "35px",
    backgroundColor: "#46BD02",
    margin: "3px",
    boxShadow: "none",
    borderRadius: "10%",
    padding: "1px",
    cursor: "pointer",
    color: " white",
}
}));


// const initialForm = {
//   fk_region: '',
//   fk_comuna: '',
//   direccion: '',
//   numero: '',
//   fecha: moment().add(48, 'h').format("YYYY-MM-DD").toString(),
//   fk_direcciones: '',
//   observaciones: '',
// }

const FormRetiro = ({ setLoading, setResponse, fk_servicio, retiroDespacho, visible,config_retiro,bloqueos_horario,bloques_ocupados,bloqueos,selectedItem }) => {
  const classes = useStyles();
  const divRef = useRef();
  const dispatch = useDispatch();
  const [form, setForm] = useState({ ...payloadServicioDespachoRetiro, horario: "", ho: "" })
  const [valueRetiro, setValueRetiro] = useState(null);
  const [openInfoAdicional, setOpenInfoAdicional] = React.useState(false);
  const [errors, setErrors] = useState({
    error: "errorDefault"
  });
  const [openRetiro,setOpenRetiro] = useState(false);
  const [horario,setHorario] = useState(null);

  const [inputValue, setInputValue] = useState(moment(new Date()));

  // console.log('p')
  // console.log(form)

  // Radio Button

  //   const [dateRange, setDateRange] = useState({
  //     startDate: new Date(moment().startOf("isoweek").utc()),
  //     endDate: new Date(moment().endOf("week").utc())
  // });


  const [selectedDate, setSelectedDate] = useState(moment(new Date()));
  const [fechaMinima,setFechaMinima] = useState(moment(new Date()));

  // const datePickerId.max = new Date().toISOString().split("T")[0];

  const [startDate, setStartDate] = useState(null);

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };



  // function disableWeekends(date) {
  //   return date.getDay() === 0 || date.getDay() === 6;
  // }

  // function disableRandomDates() {
  //   return Math.random() > 0.7;
  // }

  //   $('.datepicker').datepicker({

  //     daysOfWeekDisabled: [0,6]

  // });

  // const picker = document.getElementById('date1');
  // picker.addEventListener('input', function(e){
  //   var day = new Date(this.value).getUTCDay();
  //   if([6,0].includes(day)){
  //     e.preventDefault();
  //     this.value = '';
  //     alert('Weekends not allowed');
  //   }
  // });

  // const onDateChange = (value, date) => {
  //   setDate(value);
  //   setInputValue(value);
  // };

  // const dateFormatter = str => {
  //   return str;
  // };
  //
  /////
  //  const [dateRange, setDateRange] = useState({startDate: moment().startOf('week').format('YYYY-MM-DD'), endDate: moment().startOf('week').format('YYYY-MM-DD')})

  //  const handleDateValueChange = (ev) => {
  //    setDateRange({...this.props.dateRange, [ev.target.name]: moment(ev.target.value).format('YYYY-MM-DD')});
  //  }

  ////


  ///


  // setForm({
  //   ...form,
  //   fecha: moment(startDate).format('YYYYMMDD 090000')
  // });

  //console.log("form-prueba", errors)

  useEffect(() => {
   // handleReset()
  }, [visible])
/*
  useEffect(() => {
    if(config_retiro!=null && config_retiro.hora_limite && config_retiro.hora_limite!=null){
      let now=moment().format('YYYY-MM-DD HH:mm');
      let hora=moment(config_retiro.hora_limite).format('HH:mm');
      let day=moment(selectedDate).format('YYYY-MM-DD '+hora);
      let Nowx=new Date(now);let Dayx=new Date(day);
      if(Dayx<Nowx){
        setSelectedDate(moment(new Date()).add(2,'d'));
        setFechaMinima(moment(new Date()).add(2,'d'));
      }
    }
   }, [config_retiro])*/
  

  const postRetiro = () => {
    const payload = lodash.cloneDeep(form);
    setErrors(validationsForm(form));

    if (Object.keys(errors).length === 0 ) {
      if(horario!=null){
        setLoading(true)
        payload.fecha=moment(horario.start).format('YYYYMMDD HH:mm')
        payload.fecha_fin=moment(horario.end).format('YYYYMMDD HH:mm');
        // payload.fecha = moment(form.fecha).format('YYYYMMDD 090000');
        // payload.fecha_fin = moment(form.fecha).format('YYYYMMDD 130000');
      
        payload.fk_servicio = parseInt(selectedItem.fk_servicio);
        payload.fk_cliente = selectedItem.fk_cliente;
        payload.fk_consolidado=selectedItem.id;
        payload.fk_proforma=selectedItem.fk_proforma;
        payload.fk_bodega = 1;
        payload.preagendamiento=false;
        if (retiroDespacho != null) {
          payload.id = retiroDespacho.id;
        // dispatch(putServicioDespachoRetiroDetalleServicio(payload));
          handleReset()
        } else {
          dispatch(postDespachoRetiroSolicAgendamiento(payload));
          handleReset()
        }
      }else{
        dispatch(setMessageSolicAgendamiento({show:true,message:{type:'error',text:'DEBE ELEGIR UN HORARIO DE RETIRO'}}));
      }
      
    }
  }

  const onChange = (e) => {
    let { name, value } = e.target;
    if (name == "rut_retiro") {
      value = format(value)
    }

    setForm({
      ...form,
      [name]: value, horario: form.horario
    });
  }

  const [horarioRetiro, setHorarioRetiro] = useState('');

  const handleChange = (event) => {
    setHorarioRetiro(event.target.value);
    setForm({
      ...form,
      horario: event.target.value,
    })
  };

  const validationsForm = (form) => {
    const {
      rut_retiro,
      nombre_retiro,
      patente_retiro,
      fecha,
      fk_bodega,
      horario
    } = form;

    let errors = {}
    var rgxSoloNumeros = /^[0-9]+$/;
    //console.log("from fecha", fecha)

    if (!rut_retiro.trim()) {
      errors.rut_retiro = "Campo obligatorio";
    } else if (!validate(rut_retiro)) {
      errors.rut_retiro = "No es un rut valido.";
    }

    if (!nombre_retiro.trim()) {
      errors.nombre_retiro = "Campo obligatorio";
    }

    if (!patente_retiro.trim()) {
      errors.patente_retiro = "Campo obligatorio";
    } else if (patente_retiro.toString().length != "6") {
      errors.patente_retiro = "La Patente debe tener 6 caracteres";
    }

    if (horario==null) {
      errors.horario = "Debes seleccionar un horario";
    }

    if (valueRetiro === null) {
      errors.fk_bodega = "Campo obligatorio";
    }

    // if (!fecha.trim()) {
    //   errors.fecha = "Campo obligatorio";
    // } else if (fecha < moment().add(48, 'h').format('YYYY-MM-DD')) {
    //   errors.fecha = "Fecha debe ser mayor a 48hrs";
    // }
    
    // else if (fecha < moment().add(48, 'h').format('YYYY-MM-DD')) {
    //   errors.fecha = "Fecha debe ser mayor a 48hrs";
    // }

    return errors;
  }

  const handleBlur = (e) => {
    setErrors(validationsForm(form));
  }

  const handleReset = () => {
    setHorarioRetiro('')
    setValueRetiro(null)
    setErrors({ error: "errorDefault" })
    setForm({ ...payloadServicioDespachoRetiro, horario: "" })
    setSelectedDate(moment().format('YYYY-MM-DD'))
    // setInputValue(moment().add(48, 'h').format('YYYYMMDD'))
    setStartDate(null)
  }

  useEffect(() => {
    if(startDate !== null){
      setForm({ ...form, fecha: startDate });
    }
    setErrors(validationsForm(form));
  }, [startDate]);

  const onCloseRetiro=()=>{
    setOpenRetiro(false);
  }

  const getDayElement=(day, selectedDate, isInCurrentMonth, dayComponent) =>{
    //generate boolean 
    const today = new Date(); // just Date object of today
   // const sunnyDays = [1, 6, 10, 24, 15]
    //const isSunny = sunnyDays.includes(day.getDate()); 
    const isSelected = day.getDate() === selectedDate.getDate();
    const isToday = day.getDate() === today.getDate() && day.getMonth() === today.getMonth();
  
    let dateTile
    if (isInCurrentMonth) { 
        let porc=0;
        let dayx=day.getDate()<10 ? '0'+day.getDate():day.getDate();
        let month=day.getMonth()<10 ? '0'+(parseInt(day.getMonth())+1):parseInt(day.getMonth())+1;
        let dateString=dayx+'-'+month;
        let find=bloqueos.find(x=>x.fecha==dateString);
        if(find){
            porc=(parseFloat(find.servicios)*100)/parseInt(find.disponibilidad);
        }
  
        porc=parseFloat(porc);
        if(isSelected){
            dateTile = (
                <Paper className={classes.selectedDayPaper}>   
                  <Grid item> {day.getDate()}</Grid>
              </Paper>)
        }else{
          if(today>day){
            dateTile = (
              <Paper className={classes.weekendDayPaper}>   
              < Grid item> {day.getDate()}</Grid>
            </Paper>)
          }else{
            if(day.getDay()!=0 && day.getDay()!=6){// distinto de sábados y domingos
              if(porc>=0 && porc<=70){
                dateTile = (
                    <Paper className={classes.enabledDayPaper}>   
                    <Grid item> {day.getDate()}</Grid>
                </Paper>)
              }else if(porc>70 && porc<=99.99){
                  dateTile = (
                      <Paper className={classes.warningDayPaper}>   
                      
                      <Grid item> {day.getDate()}</Grid>
                  </Paper>)
              }else{
                  dateTile = (
                      <Paper className={classes.blockedDayPaper}>   
                      <Grid item> {day.getDate()}</Grid>
                  </Paper>)
              }
            }else{
              dateTile = (
                <Paper className={classes.weekendDayPaper}>   
                < Grid item> {day.getDate()}</Grid>
              </Paper>)
            }
          }
            
            
        }
           /* dateTile = (
              <Paper className={isSelected ? classes.selectedDayPaper : isToday ? classes.todayPaper : classes.blockedDayPaper}>   
              
                <Grid item> {day.getDate()}</Grid>
            </Paper>)*/
    } else {
        dateTile = (<Paper className={classes.notInThisMonthDayPaper}>
            <Grid item><br/></Grid>
            <Grid item style={{color: "lightGrey"}}>
                {day.getDate()}
            </Grid>
        </Paper>)
    }
    return dateTile
  }
  function disableWeekends(date) {
    return date.getDay() === 0 || date.getDay() === 6;
  }

  const onChangeMonth=(value)=>{
    dispatch(getDisponibilidadRetiroSolicAgendamiento({fecha:moment(value).format('YYYYMMDD')}))
  }

  const dateFormatter = str => {
    return str;
  };

  const onDateChange = (value, date) => {
    let aux=moment(value).format('DD-MM');
        let find=bloqueos.find(x=>x.fecha==aux);
        let next=true;
        let x=selectedItem;
       /* if(find){
            if(find.disponibilidad<selectedItem.volumen){
                dispatch(showMessageDetalleServicio({type:'error',text:'NO ES POSIBLE AGENDAR EN DICHA FECHA, NO EXISTE DISPONIBILIDAD'}));
                next=false;
                dispatch(postAgendaRetiroByMonthDetalleServicio({fecha:moment(selectedDate).format('YYYYMMDD')}))
            }
        }*/

        if(next==true){
          setSelectedDate(moment(value));
          setInputValue(moment(value));
        dispatch(getBloqueosRetiroSolicAgendamiento({fecha1:moment(value).format('YYYYMMDD 00:00:00'),fecha2:moment(value).format('YYYYMMDD 23:59:59')}));
        }
  };

  const onKeyDown = (e) => {
    e.preventDefault();
 };
  return (
    <div className="cards-body px-4 px-md-5">
      <form className="input-material-ui">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-6 mb-3 mb-md-0">
            <InputLabel className="Label_Input_Format">Punto de retiro</InputLabel>
            <Autocomplete
              options={bodegas}
              value={valueRetiro}
              onChange={(event, newValue) => {
                setValueRetiro(newValue);
              }}
              onBlur={handleBlur}
              getOptionLabel={(option) => option.nombre}
              renderInput={(params) => <TextField {...params} placeholder="Bodega" error={errors.fk_bodega && true} helperText={errors.fk_bodega} />}
              noOptionsText={'No hay resultado...'}
            />
          </div>

          <div className="col-12 col-md-6 col-lg-6">

            <InputLabel className="Label_Input_Format">Fecha</InputLabel>
            <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                format="dd-MM-yy"
                value={selectedDate}
                onChange={onDateChange}
                minDate={fechaMinima}
                rifmFormatter={dateFormatter}
                onMonthChange={onChangeMonth}
                shouldDisableDate={disableWeekends}
                onKeyDown={onKeyDown}
                renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => getDayElement(day, selectedDate, isInCurrentMonth, dayComponent)}
                className={classes.inputAdornment}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
            {/*<DatePicker
              locale="es"
              name="fecha"
              dateFormat="dd/MM/yy"
              selected={startDate}

              onChange={(date) => {
                setStartDate(date);
              }}
              filterDate={isWeekday}
              placeholderText={"Fecha Retiro"}
              minDate={moment().add(48, 'h').toDate()}

              onBlur={handleBlur}
              className={errors.startDate ? "error" : ''}
            />*/}

            {/*<FormHelperText>{errors.startDate && errors.startDate}</FormHelperText>*/}


            {/* <InputLabel className="Label_Input_Format">Fecha</InputLabel>
            <TextField
              id="date1"
              name="fecha"
              type="date"
              format="DD-MM-YY"
              onChange={onChange}
              className="datepicker"
              InputProps={{ inputProps: { min: selectedDate } }}
              value={form.fecha && form.fecha}
              onBlur={handleBlur}
              helperText={errors.fecha && errors.fecha}
              required
              error={errors.fecha && true}

            /> */}

            {/* <DatePicker
      selected={new Date(dateRange.startDate)}
      onChange={(date) => setDateRange({ ...dateRange, startDate: date })}
      name="startDate"
      filterDate={(date) => date.getDay() === 1}
      placeholderText="Select a Monday"
    /> */}
            {/* <InputLabel className="Label_Input_Format">Fecha</InputLabel>

            

            <MuiPickersUtilsProvider libInstance={moment} locale="es" utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                format="DD-MM-YY"
                value={selectedDate}
                // onChange={onDateChange}
                minDate={selectedDate}
                // rifmFormatter={dateFormatter} 
                className={classes.inputAdornment}
                shouldDisableDate={disableWeekends}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider> */}
          </div>
        </div>

        <div className='border my-4'></div>

        <div className="row">
          <div className="col-12">
            <h4 className="text-small font-weight-500 mb-3">Seleciona un horario de retiro</h4>

            <FormControl className="prueba-check">
            <Button
                onClick={()=>{setOpenRetiro(true)}}
                className="button button-outline button-small">
                <i className="zmdi zmdi-timer" /> Elegir horario
            </Button>
            <h4 className="text-small font-weight-500 mb-3">
              {horario!=null ? moment(horario.start).format('DD-MM-YYYY HH:mm')+'/'+ moment(horario.end).format('HH:mm'):''}
            </h4>
              {/*<RadioGroup
                row
                aria-label="gender"
                name="horario"
                value={horarioRetiro}
                onChange={handleChange}
                onBlur={handleBlur}
                className="bg-chack"
              >
                <FormControlLabel
                  value="1"
                  control={<Radio />}
                  className={` ${horarioRetiro == 1 ? "active" : ''} `}

                  label="AM 09:00 a 13:00 hrs"
                />

                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  className={` ${horarioRetiro == 2 ? "active" : ''} `}
                  label="PM 13:00 a 19:00 hrs"
                />
          </RadioGroup>*/}
            </FormControl>

            <FormHelperText >
              {errors.horario && errors.horario}
            </FormHelperText>
          </div>
        </div>

        <div className='border my-4'></div>

        <div className="row pb-2">
          <div className="col-12 mb-3">
            <h4 className="text-small font-weight-500">¿Quién lo retira?</h4>
          </div>

          <div className="col-12 col-md-4 col-lg-4">
            <InputLabel className="Label_Input_Format">Rut</InputLabel>
            <TextField
              id="rut_retiro"
              name="rut_retiro"
              placeholder="17.678-909-k"
              value={form.rut_retiro}
              onChange={onChange}
              onBlur={handleBlur}
              error={errors.rut_retiro && true}
              helperText={errors.rut_retiro}
            />
          </div>

          <div className="col-12 col-md-4 ol-lg-4 my-3 my-md-0">
            <InputLabel className="Label_Input_Format">Nombre</InputLabel>
            <TextField
              id="nombre_retiro"
              name="nombre_retiro"
              placeholder="Ingresa tu nombre"
              value={form.nombre_retiro}
              onChange={onChange}
              onBlur={handleBlur}
              error={errors.nombre_retiro && true}
              helperText={errors.nombre_retiro}
            />
          </div>

          <div className="col-12 col-md-4 col-lg-4">
            <InputLabel className="Label_Input_Format">Patente</InputLabel>
            <TextField
              id="patente_retiro"
              name="patente_retiro"
              placeholder="Ingresa la patente"
              value={form.patente_retiro}
              onChange={onChange}
              onBlur={handleBlur}
              error={errors.patente_retiro && true}
              helperText={errors.patente_retiro}
            />
          </div>
        </div>

        <div className='border my-4'></div>

        <div className="row">
          <div className="col-12" onClick={() => { setOpenInfoAdicional(!openInfoAdicional) }}>
            <div className="align-item-center">
              {openInfoAdicional ? <ExpandMoreIcon className="icon-color-default" /> : <ChevronRightIcon className="icon-color-default" />}
              <h4 className="text-small font-weight-500">Información adicional</h4>
            </div>
          </div>
        </div>

        {openInfoAdicional &&
          <div className="row">
            <div className="col-12 mt-3">
              <TextareaAutosize
                id="observaciones"
                name="observaciones"
                aria-label="minimum height"
                placeholder="Ingresa tu comentario"
                onChange={onChange}
                value={form.observaciones}
              />
            </div>
          </div>
        }

        <Button
          onClick={() => { postRetiro() }}
          className="button button-outline-danger button-small button-100-mobile mt-5"
        >
          Confirmar Retiro
        </Button>
      </form>
      <ModalRetiro open={openRetiro} onToggle={onCloseRetiro} day={selectedDate} bloqueos={bloqueos_horario} setHorario={setHorario} config_retiro={config_retiro} bloques_ocupados={bloques_ocupados}/>
    </div>
  )
}

export default FormRetiro