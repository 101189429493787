import React, { useEffect, useState, useMemo } from 'react'
import CardMap from './cards/CardThree'
import Cargando from './loading/Loading'
import Cotizacion from './form/Cotizacion'
import Messege from './Message'
import Servicios from './Servicios'
import Cotizaciones from './Cotizaciones'
import NuevoServicio from './form/NuevoServicio'
import { motion } from "framer-motion"
import { useHistory } from 'react-router-dom';
import { useTour } from "@reactour/tour";

import {
    Button,
    makeStyles,
    Typography,
    Hidden,
    Menu,
    MenuItem,
    ListItemIcon,
    Divider
} from "@material-ui/core";

import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import PostAddIcon from '@material-ui/icons/PostAdd';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';


const useStyles = makeStyles((theme) => ({
    listItem: {
        display: "flex",
        flexDirection: "column"
    }
}));

const Form = ({setStep}) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState(null)
    const [hideMap, setHideMap] = useState(false);
    const [openThree, setOpenThree] = useState(false);
    const [nuevoServicio, setNuevoServicio] = useState(false);
    const [dropdown, setDropdown] = useState(null);
    const [visible, setVisible] = useState({
        servicios: false,
        cotizaciones: true,
    });

    const [tour, setTour] = useState(false);


    const { setIsOpen } = useTour();
    // const redirect = useNavigate();
    const history = useHistory();



    // mostrar mapa
    const hideMaps = () => {
        setHideMap(!hideMap)
    }
    // dialog nueva cotización
    const handleClickOpenThree = () => {
        setOpenThree(true);
    };
    const handleCloseThree = () => {
        setOpenThree(false);
    };
    // dialog nuevo servicio
    const handleNuevoServicio = () => {
        setNuevoServicio(true);
    };
    const handleCloseNuevoServicio = () => {
        setNuevoServicio(false);
    };
    // dropdown cards
    const handleClick = (event) => {
        setDropdown(event.currentTarget);
        console.log(setDropdown(event.currentTarget))
    };
    const handleClose = () => {
        setDropdown(null);
    };
    
    // mostar componente
    const handleChange = (newValue) => {
        switch (newValue) {
            case 1:
                setVisible({
                    servicios: false,
                    cotizaciones: true,
                });
                // setChecked((prev) => !prev);
                break;
            case 2:
                setVisible({
                    servicios: true,
                    cotizaciones: false,
                });
                break;
        }
    };


    useEffect( () => {
        console.log('render!')
        setTour(true);
    }, [])


    // dialog comenzar tour sitio
    const handlekCloseTour = () => {
        setTour(false);
    };

    const startTour = () => {
        setStep(0);
        setIsOpen(true);
        // history.push("/");
    };

    return (
        <div className="container-fuid paddingContainer">
            <CardMap hideMap={hideMap} />

            {/* Button principal */}
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: .5, duration: 1.3 }}
                className='app-tags'
            >
                <div className="app-buttons">
                    <Button
                        className={`button button-small ${visible.servicios == false ? 'button-danger' : 'button-gray'}`}
                        onClick={(e) => handleChange(1)}
                    >
                        Servicios
                    </Button>

                    <Button
                        className={`button button-small mx-2 ${visible.cotizaciones == false ? 'button-danger' : 'button-gray'}`}
                        onClick={(e) => handleChange(2)}
                    >
                        Cotizaciones
                    </Button>
                </div>


                <div className="app-buttons">
                    {/* buttons versión desktop */}
                    <Hidden smDown>
                        <Button
                            onClick={hideMaps}
                            className="button button-small button-outline-gray mx-2"
                        >
                            {hideMap ? <VisibilityIcon className="mr-1" /> : <VisibilityOffIcon className="mr-1" />}
                            {hideMap ? "Mostrar mapa" : "Ocultar mapa"}
                        </Button>

                        <Button
                            onClick={handleNuevoServicio}
                            className="button button-small button-outline-danger"
                        >
                            nuevo servicio 
                        </Button>

                        {/* <Button
                            onClick={handleClickOpenThree}
                            className="button button-small button-outline-danger"
                        >
                            nueva cotización
                        </Button> */}
                    </Hidden>

                    {/* buttons versión mobile */}
                    <Hidden smUp>
                        <Button
                            className="button button-outline-danger button-small px-0"
                            onClick={handleClick}
                        >
                            <MoreHorizIcon fontSize="small" />
                        </Button>
                        <Menu
                            id="simple-menu2"
                            anchorEl={dropdown}
                            keepMounted
                            open={Boolean(dropdown)}
                            onClose={handleClose}
                            className="app-dropdown mt-5"
                            variant="selectedMenu"
                        >
                            <MenuItem
                                onClick={() => {
                                    hideMaps();
                                    handleClose();
                                }}
                            >
                                <ListItemIcon>
                                    {hideMap ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </ListItemIcon>
                                <Typography variant="inherit">{hideMap ? "Mostrar mapa" : "Ocultar mapa"}</Typography>
                            </MenuItem>

                            <MenuItem
                                onClick={() => {
                                    handleClickOpenThree();
                                    handleClose();
                                }}
                            >
                                <ListItemIcon>
                                    <PostAddIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Cotización</Typography>
                            </MenuItem>

                            <MenuItem
                                onClick={() => {
                                    handleNuevoServicio();
                                    handleClose();
                                }}
                            >
                                <ListItemIcon>
                                    <DirectionsBoatIcon fontSize="small" />
                                </ListItemIcon>
                                <Typography variant="inherit">Nuevo Servicio</Typography>
                            </MenuItem>
                        </Menu>
                    </Hidden>
                </div>
            </motion.div>

            {/* border que esta abajo del menu pricnpial */}
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1, duration: 1.3 }}
                className='border my-4'>
            </motion.div>

            {/* componente servicios */}
            <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1, duration: 1.5}}
                hidden={visible.servicios}
            >
                <Servicios />
            </motion.div>

            {/* componente cotizaciones */}
            <div hidden={visible.cotizaciones}>
                <Cotizaciones handleClickOpenThree={handleClickOpenThree} />
            </div>
            
            {/* Form cotizacion */}
            <Cotizacion
                setResponse={setResponse}
                setLoading={setLoading}
                setOpenThree={setOpenThree}
                openThree={openThree}
                handleCloseThree={handleCloseThree}
            />

            {loading && <Cargando />}

            {response &&
                <Messege
                    response={response}
                    tituloMensaje="Cotización Exitosa"
                    icon="iconSuccess"
                    mensaje="Has registrodo con exito tu nueva cotización, Revisa tu propuesta comercial y descarga tus etiquetas."
                />
            }
            {/* fin Form cotizacion */}

            {/* nuevo servicio */}
            <NuevoServicio
            // setResponse={setResponse}
            // setLoading={setLoading}
            // setNuevoServicio={setNuevoServicio}
            nuevoServicio={nuevoServicio}
            handleCloseNuevoServicio={handleCloseNuevoServicio} 
            />

            {/* Dialog para comenzar tour por el sitio */}
            <Messege
                response={tour}
                tour={tour}
                onClose={handlekCloseTour}
                startTour={startTour}
                icon="iconSuccess"
                tituloMensaje="Bienvenido"
                mensaje="Te invitamos a conocer nuestra nueva plataforma..."
            />
        </div>
    )
};

export default Form;