import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import {
  postConsolidadoRapidoExpress,
  updateStateNoConsolidadoRapidoExpress
} from './uploadDocsExpress/Actions'; 

import {
    InputLabel,
    Button,
    TextField,
    makeStyles,
    IconButton,
    InputAdornment,
    CircularProgress,
    FormControl,
    FormHelperText,
    Hidden
  } from "@material-ui/core";

  const useStyles = makeStyles((theme) => ({
    link: {
      fontSize: "14px",
      color: "#8F8F8F",
      fontWeight: "500",
      "&:hover": {
        color: "#FC565C"
      }
    },
    titleLogin: {
      paddingLeft: "12px",
      margin: "16px 0"
    },
    iconButtom: {
      "&:hover": {
        background: "transparent"
      }
    }
  }));
const ConsolidadoRapido=(props)=>{
    const classes = useStyles();
    const [query, setQuery] = useState(props.match.params.query);
    const [hash, setHash] = useState(props.match.params.hash);
    const {success2} = useSelector(({ UploadDocumentsExpress }) => UploadDocumentsExpress);
    const dispatch = useDispatch();
    useEffect(() => {
      if(query=='si' && hash!=null && hash.length>0){
        dispatch(postConsolidadoRapidoExpress({hash:hash}));
      }else if(query=='no' && hash!=null && hash.length>0){
        dispatch(updateStateNoConsolidadoRapidoExpress({hash:hash}));
      }
   }, [hash]);

    return (
        <div className="app-login-container">
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: .5, duration: 1.3 }}
            className="app-login"
        >
            <div className="app-login-content">
            {success2!=null && success2.success==true && query=='si' &&
            <h2 className={`text font-weight-500 ${classes.titleLogin}`}>Gracias por confirmar que deseas despachar todas estas cargas a Chile, procederemos una vez toda la documentación esté aprobada</h2>}
            {success2!=null && success2.success==true && query=='no' &&
            <h2 className={`text font-weight-500 ${classes.titleLogin}`}>Gracias por confirmar que deseas esperar más carga, te notificaremos cuando recibamos carga nuevamente.</h2>}
            {success2!=null && success2.success==false && 
            <h2 className={`text font-weight-500 ${classes.titleLogin}`}>{success2.message}</h2>}
            
            </div>
        </motion.div>
        <div className="app-logo-content justify-center">
        <Link className="logo-lg" to="/" title="Jambo">
          <img
            src={require("../assets/images/wscargologo.png")}
            alt="jambo"
            title="jambo"
          />
        </Link>
        </div>
        </div>
    );
}

export default ConsolidadoRapido;