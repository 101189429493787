import {all,fork,call, put,takeLatest,spawn, takeEvery} from "redux-saga/effects";

import {
    postRequest,
    getRequest,
    getErrorRequest,
    deleteRequest,
    uploadFilesRequest,
    putRequest
} from '../../util/apiFetch';

import {
    GET_REI1,
    GET_REI2,
    GET_REI3,
    POST_REI4,
    PUT_REI5,
    GET_REI6,
    GET_REI7,
    PUT_REI8,
    RUT_EXISTE,
    EMAIL_EXISTE,
    GET_ROLES_AGREGAR_CONTACTO,
    GET_MODULOS,
    POST_ROLES,
    PUT_ROLES,
    DELETE_ROLES,
    VALIDATE_NAME_ROLES
} from './Constants';

import {
    showMessageEmpresaImportador,
    getREI1Success,
    getREI2Success,
    getREI3Success,
    putREI5Success,
    getREI6Success,
    getREI7Success,
    validateRutEmpresaImportadorSuccess,
    validateEmailEmpresaImportadorSuccess,
    getRolesAgregarContactoSuccess,
    getModulosSuccess,
    validateNameRolesSuccess
} from './Actions';



function* getSagaREI1Execute({payload}){
    //alert(localStorage.getItem('user_id'));
    const requestURL = `registro_cliente/listado/`+localStorage.getItem('user_id');
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageEmpresaImportador({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getREI1Success(obj));
    }
}

function* getSagaREI2Execute({payload}){
    const requestURL = `region_get_list`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageEmpresaImportador({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getREI2Success(obj));
    }
}

function* getSagaREI3Execute({payload,edit}){
    const requestURL = `comuna_get_comunaregion/${payload.id}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageEmpresaImportador({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getREI3Success(obj,edit));
    }
}

function* postSagaREI4Execute({payload}){
    const requestURL = `registrar-cliente/only-empresa-persona`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageEmpresaImportador({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(showMessageEmpresaImportador({type:'success',text:'Registro creado correctamente'}));
        yield getSagaREI1Execute({payload:{id:payload.fk_usuario}});
    }
}

function* putSagaREI5Execute({payload,fk_usuario}){
    const requestURL = `registrar-cliente/only-empresa-persona/${payload.id}`;
    const response= yield call(putRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageEmpresaImportador({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(showMessageEmpresaImportador({type:'success',text:'Registro actualizado correctamente'}));
        yield put(putREI5Success(obj));
        console.log({payload:{id:fk_usuario}});
        yield getSagaREI1Execute({payload:{id:fk_usuario}})
    }
}

function* getSagaREI6Execute({payload}){
    const requestURL = `comuna_get_list`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageEmpresaImportador({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getREI6Success(obj));
    }
}

function* getSagaREI7Execute({payload}){
    const requestURL = `clientes/get-info-documents/${payload.id}`;
    const response= yield call(getRequest,requestURL,null,false);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageEmpresaImportador({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getREI7Success(obj));
    }
}

function* putSagaREI8Execute({payload}){
    const requestURL = `clientes/upload-documents/${payload.fk_cliente}`;
    const fd=new FormData();
    if(payload.cedula_1 && payload.cedula_1!==null){
        fd.append('cedula_1',payload.cedula_1,payload.cedula_1.name);
    }
    if(payload.cedula_2 && payload.cedula_2!==null){
        fd.append('cedula_2',payload.cedula_2,payload.cedula_2.name);
    }
    if(payload.podersimple_1 && payload.podersimple_1!==null){
        fd.append('podersimple_1',payload.podersimple_1,payload.podersimple_1.name);
    }
    const response= yield call(uploadFilesRequest,requestURL,fd,true);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageEmpresaImportador({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(showMessageEmpresaImportador({type:'success',text:'ARCHIVOS SUBIDOS CORRECTAMENTE'}));
        yield getSagaREI7Execute({payload:{id:payload.fk_cliente}});
    }
}

function* validateSagaRutEmpresaImportadorExecute({payload}) {
    const requestURL = `registrar-cliente/validate-rut`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageUserAux({type:'error',text:message}));
        console.log("ha ocurrio un error en Saga validateSagaRutEmpresaImportadorExecute() : "+error);
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(validateRutEmpresaImportadorSuccess(obj));
        //yield getSagaExistsUserCotizadorClienteExecute(payload);
    }
}

function* validateSagaEmailEmpresaImportadorExecute({payload}) {
    const requestURL = `registrar-cliente/validate-email`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        //yield put(showAuthMessage(message));
        console.log("ha ocurrio un error en Saga validateSagaEmailEmpresaImportadorExecute() : "+error);
    }else{
        const respTxt = yield response.text();
        yield put(validateEmailEmpresaImportadorSuccess(JSON.parse(respTxt)))
    }
}

function* getSagaListaRolesExecute({payload}) {
    const requestURL = `roles/${payload.id}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        //yield put(showAuthMessage(message));
        console.log("ha ocurrio un error en Saga getSagaListaRolesExecute() : "+error+ "  " + message);
        yield put(showMessageEmpresaImportador({type:'error',text:message}));
    }else{
        const respTxt = yield response.text();
        yield put(getRolesAgregarContactoSuccess(JSON.parse(respTxt)))
    }
}

function* getSagaListaModulosExecute({payload}) {
    const requestURL = `modulos`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageEmpresaImportador({type:'error',text:message}));
        console.log("ha ocurrio un error en Saga getSagaListaModulosExecute() : "+error+ "  " + message);
    }else{
        const respTxt = yield response.text();
        yield put(getModulosSuccess(JSON.parse(respTxt)))
    }
}

function* postSagaRolesExecute({payload}) {
    const requestURL = `roles`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageEmpresaImportador({type:'error',text:message}));
        console.log("ha ocurrio un error en Saga postSagaRolesExecute() : "+error+ "  " + message);
    }else{
        const respTxt = yield response.text(); 
        const obj = JSON.parse(respTxt);
        yield put(showMessageEmpresaImportador({type:'success',text:obj.message}));
        yield getSagaREI1Execute({payload:{id:parseInt(payload.fk_usuario)}});
        yield put(putREI5Success(obj.obj));
    }
}

function* putSagaRolesExecute({payload}) {
    const requestURL = `roles/${payload.id}`;
    const response= yield call(putRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageEmpresaImportador({type:'error',text:message}));
        console.log("ha ocurrio un error en Saga putSagaRolesExecute() : "+error+ "  " + message);
    }else{
        const respTxt = yield response.text(); 
        const obj = JSON.parse(respTxt);
        yield put(showMessageEmpresaImportador({type:'success',text:obj.message}));
        yield getSagaREI1Execute({payload:{id:parseInt(payload.fk_usuario)}});
        yield put(putREI5Success(obj.obj));
    }
}

function* deleteSagaRolesExecute({payload}) {
    const requestURL = `roles/${payload.id}`;
    const response= yield call(deleteRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageEmpresaImportador({type:'error',text:message}));
        console.log("ha ocurrio un error en Saga deletetSagaRolesExecute() : "+error+ "  " + message);
    }else{
        const respTxt = yield response.text(); 
        const obj = JSON.parse(respTxt);
        if(obj.success === true){
            yield put(showMessageEmpresaImportador({type:'success',text:obj.message}));
            yield getSagaREI1Execute({payload:{id:parseInt(payload.fk_usuario)}});
            yield put(putREI5Success(obj.obj));
        }else{
            yield put(showMessageEmpresaImportador({type:'error',text:obj.message}));
        }
        
    }
}

function* postSagaValidateNameRolesExecute({payload}) {
    const requestURL = `roles/validate/`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        //yield put(showAuthMessage(message));
        console.log("ha ocurrio un error en Saga postSagaValidateNameRolesExecute() : "+error+ "  " + message);
    }else{
        const respTxt = yield response.text(); 
        const obj = JSON.parse(respTxt);
        console.log(obj);
        yield put(validateNameRolesSuccess(obj));
        /*yield put(showMessageEmpresaImportador({type:'success',text:obj.message}));
        yield getSagaREI1Execute({payload:{id:parseInt(payload.fk_usuario)}});
        yield put(putREI5Success(obj.obj));*/
    }
}

//clientes/get-info-documents/{id}

export function* getSagaREI1(){
    yield takeEvery(GET_REI1, getSagaREI1Execute);
}

export function* getSagaREI2(){
    yield takeLatest(GET_REI2, getSagaREI2Execute);
}

export function* getSagaREI3(){
    yield takeLatest(GET_REI3, getSagaREI3Execute);
}

export function* postSagaREI4(){
    yield takeLatest(POST_REI4, postSagaREI4Execute);
}

export function* putSagaREI5(){
    yield takeLatest(PUT_REI5, putSagaREI5Execute);
}

export function* getSagaREI6(){
    yield takeLatest(GET_REI6, getSagaREI6Execute);
}

export function* getSagaREI7(){
    yield takeLatest(GET_REI7, getSagaREI7Execute);
}

export function* putSagaREI8(){
    yield takeLatest(PUT_REI8, putSagaREI8Execute);
}
export function* validateSagaRutEmpresaImportador() {
    yield takeLatest(RUT_EXISTE, validateSagaRutEmpresaImportadorExecute);
}
export function* validateSagaEmailEmpresaImportador() {
    yield takeLatest(EMAIL_EXISTE, validateSagaEmailEmpresaImportadorExecute);
}
export function* getSagaListaRoles() {
    yield takeLatest(GET_ROLES_AGREGAR_CONTACTO, getSagaListaRolesExecute);
}
export function* getSagaListaModulos() {
    yield takeLatest(GET_MODULOS, getSagaListaModulosExecute);
}
export function* postSagaRoles() {
    yield takeLatest(POST_ROLES, postSagaRolesExecute);
}
export function* putSagaRoles() {
    yield takeLatest(PUT_ROLES, putSagaRolesExecute);
}
export function* deleteSagaRoles() {
    yield takeLatest(DELETE_ROLES, deleteSagaRolesExecute);
}
export function* postSagaValidateNameRoles() {
    yield takeLatest(VALIDATE_NAME_ROLES, postSagaValidateNameRolesExecute);
}

export default function* rootSaga() {
    yield all([
        fork(getSagaREI1),
        fork(getSagaREI2),
        fork(getSagaREI3),
        fork(postSagaREI4),
        fork(putSagaREI5),
        fork(getSagaREI6),
        fork(getSagaREI7),
        fork(putSagaREI8),
        fork(validateSagaRutEmpresaImportador),
        fork(validateSagaEmailEmpresaImportador),
        fork(getSagaListaRoles),
        fork(getSagaListaModulos),
        fork(postSagaRoles),
        fork(putSagaRoles),
        fork(deleteSagaRoles),
        fork(postSagaValidateNameRoles),
    ]);
}