import {
    GET_TYPES_NOTIFICATIONS,
    GET_TYPES_NOTIFICATIONS_SUCCESS,
    GET_GLOBAL_LIST_NOTIFICATIONS,
    GET_GLOBAL_LIST_NOTIFICATIONS_SUCCESS,
    GET_LIST_BY_USER_NOTIFICATIONS,
    GET_LIST_BY_USER_NOTIFICATIONS_SUCCESS,
    POST_USER_NOTIFICATIONS,
    GET_USER_ALL_NOTIFICATIONS,
    GET_USER_ALL_NOTIFICATIONS_SUCCESS,
    PUT_VISTAS_NOTIFICATIONS,
    CLEAR_ALL_EXPDIG_NOTIFICACIONES_SUCCESS
} from './Constants';
export const Clear_All_ExpDig_Notificaciones_Success = () => {
    return {
        type: CLEAR_ALL_EXPDIG_NOTIFICACIONES_SUCCESS,
    };
};
export const getTypesNotifications= (payload) => {
    return {
        type: GET_TYPES_NOTIFICATIONS,
        payload: payload
    };
};

export const getTypesNotificationsSuccess= (payload) => {
    return {
        type: GET_TYPES_NOTIFICATIONS_SUCCESS,
        payload: payload
    };
};

export const getGlobalListNotifications= (payload) => {
    return {
        type: GET_GLOBAL_LIST_NOTIFICATIONS,
        payload: payload
    };
};

export const getGlobalListNotificationsSuccess= (payload) => {
    return {
        type: GET_GLOBAL_LIST_NOTIFICATIONS_SUCCESS,
        payload: payload
    };
};

export const getListByUserNotifications= (payload) => {
    return {
        type: GET_LIST_BY_USER_NOTIFICATIONS,
        payload: payload
    };
};

export const getListByUserNotificationsSuccess= (payload) => {
    return {
        type: GET_LIST_BY_USER_NOTIFICATIONS_SUCCESS,
        payload: payload
    };
};

export const postUserNotifications= (payload) => {
    return {
        type: POST_USER_NOTIFICATIONS,
        payload: payload
    };
};

export const getUserAllNotifications= (payload) => {
    return {
        type: GET_USER_ALL_NOTIFICATIONS,
        payload: payload
    };
};

export const getUserAllNotificationsSuccess= (payload) => {
    return {
        type: GET_USER_ALL_NOTIFICATIONS_SUCCESS,
        payload: payload
    };
};

export const putVistasNotifications= (payload) => {
    return {
        type: PUT_VISTAS_NOTIFICATIONS,
        payload: payload
    };
};










