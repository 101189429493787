import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { helpHttp } from '../helpers/helpHttp';


import {
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  InputLabel,
  TextField,
  Tooltip,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Button,
  Checkbox,
  Typography,
  IconButton,
  Avatar,
  Stepper,
  Step,
  StepLabel

} from "@material-ui/core";
import HelpIcon from '@material-ui/icons/Help';


const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#263238',
    color: '#fff',
    maxWidth: 300,
    padding: "12px 16px",
    "& .MuiTypography-h5": {
      fontSize: "14px"
    },
    "& .MuiTypography-h6": {
      fontSize: "10px"
    }
  }
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  inputAdornment: {
    "& .MuiTypography-body1": {
      fontSize: "12px"
    }
  },
  icon: {
    fontSize: "16px",
  },
}));

const initialForm = {
  volume: '',
  weight: '',
  description: '',
  puertoOrigen: '',
  puertoDestino: ''
}

// origen y destino cargar
const originCarga = [
  { id: '1', puerto: 'uno' },
  { id: '2', puerto: 'dos' }
];

const destinoCarga = [
  { id: '1', puerto: 'Santiago' },
  { id: '2', puerto: 'San Antonio' }
];


const One = () => {
  const classes = useStyles();
  const [form, setForm] = useState(initialForm)
  const [errors, setErrors] = useState({
    error: "errorDefault"
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validationsForm(form))
    console.log(Object.keys(errors).length === 0)
    if (Object.keys(errors).length === 0) {
      // setNuevoServicio(false);
      // setLoading(true)
      helpHttp()
        .post("https://formsubmit.co/ajax/alonsotrina22@gmail.com", {
          body: form,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          }
        })
        .then((res) => {
          // setLoading(false)
          // setResponse(true)
          // setTimeout(() => setResponse(false), 4000);
          setForm(initialForm)
        });
    }
    return;

  }

  const handleBlur = (e) => {
    handleChange(e)
    setErrors(validationsForm(form))
  }

  const handleResetForm = (e) => {
    setErrors({ error: "errorDefault" })
  }

  const validationsForm = (form) => {
    const {
      volume,
      weight,
      description,
      puertoOrigen,
      puertoDestino,

    } = form;
    let errors = {}

    if (!volume.trim()) {
      errors.volume = "Campo obligatorio";
    } else if (volume <= 0) {
      errors.volume = "El volumen debe ser mayor que cero";
    }

    if (!weight.trim()) {
      errors.weight = "Campo obligatorio";
    } else if (weight <= 0) {
      errors.weight = "El peso debe ser mayor que cero"
    }

    if (!description.trim()) {
      errors.description = "Campo obligatorio";
    }

    if (!puertoOrigen.trim()) {
      errors.puertoOrigen = "Campo obligatorio";
    }

    if (!puertoDestino.trim()) {
      errors.puertoDestino = "Campo obligatorio";
    }

    return errors;
  }
  return (
    <form className="input-material-ui" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-12 mb-3">
          <h5 className="text font-weight-500">Datos de carga</h5>
          <p className="text-small font-weight-400">Para valorizar el servicio, debes ingresar el volumen, peso y tipo de producto a importar.</p>
        </div>
        <div className="col-6">
          <InputLabel className="Label_Input_Format">Bodega Origen</InputLabel>
          <Autocomplete
            // id="combo-box-demo"
            options={originCarga}
            getOptionLabel={(option) => option.puerto}
            renderInput={(params) => <TextField {...params} placeholder="Puerto Origen" />}
            noOptionsText={'No hay resultado...'}
          />
        </div>

        <div className="col-6">
          <InputLabel className="Label_Input_Format">Cuidad Destino</InputLabel>
          <Autocomplete
            // id="combo-box-demo"
            options={destinoCarga}
            getOptionLabel={(option) => option.puerto}
            renderInput={(params) => <TextField {...params} placeholder="Puerto Destino" />}
            noOptionsText={'No hay resultado...'}
          />
        </div>
        
        {/* input */}
        <div className="col-12 col-lg-6 mt-3">
          <InputLabel className="Label_Input_Format">Volumen M3</InputLabel>
          <TextField
            name="volume"
            placeholder="80"
            type="number"
            value={form.volume}
            onBlur={handleBlur}
            onChange={handleChange}
            error
            helperText={
              errors.volume && errors.volume
            }
            className={classes.inputAdornment}
            InputProps={{
              startAdornment: <InputAdornment position="start">M3</InputAdornment>
            }}
          />
        </div>

        {/* input  */}
        <div className="col-12 col-lg-6 mt-3">
          <InputLabel className="Label_Input_Format">Peso Kg</InputLabel>
          <TextField
            name="weight"
            placeholder="80"
            type="number"
            value={form.weight}
            onBlur={handleBlur}
            onChange={handleChange}
            error
            helperText={
              errors.weight && errors.weight
            }
            className={classes.inputAdornment}
            InputProps={{
              startAdornment: <InputAdornment position="start">Kg</InputAdornment>
            }}
          />
        </div>

        {/* input */}
        <div className="col-12 my-3">
          <InputLabel className="Label_Input_Format">
            Descripción producto
            {/* Tooltip */}
            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography variant="h5" color="inherit">Los productos importados no pueden ser de marca, replica o similar sin autorización de la marca registrada en Chile.</Typography>
                </React.Fragment>
              }
            >

              <HelpIcon className={`ml-1  ${classes.icon}`} />
            </HtmlTooltip>
          </InputLabel>
          <TextField
            name="description"
            placeholder="Describe la carga"
            value={form.description}
            onBlur={handleBlur}
            onChange={handleChange}
            error
            helperText={
              errors.description && errors.description
            }
          />
        </div>

        <div className="col-12">
          <FormGroup>
            <FormControlLabel className={classes.label} control={<Checkbox />} label="Declara que los productos a importar no son de marca, replica o similar sin autorización de la marca registrada en Chile." />
          </FormGroup>
        </div>
      </div>
    </form>
  )
}

export default One