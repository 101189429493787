import React, { useEffect, useState, useParams } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';

import {
    makeStyles,
    Button,
    LinearProgress
} from "@material-ui/core";

import {
    getActivarAccount,
    clearIsActive,
    activeLoading,
} from '../actions';

const useStyles = makeStyles((theme) => ({
    link: {
        fontSize: "14px",
        color: "#263238",
        fontWeight: "500",
        marginRight: "16px",
        "&:hover": {
            color: "#8F8F8F"
        }
    }
}));



const ActivarCuenta = (props) => {
    const dispatch = useDispatch();

    const { isActive, loading } = useSelector(({ auth }) => auth);
    const [id, setId] = useState(props.match.params.id)

    useEffect(() => {
        dispatch(activeLoading({}));
        dispatch(getActivarAccount({ hash: id }));
    }, []);

    return (
        <div className="app-login-container">
            <div className="app-registro">
                <div className="app-login-content-registro">
                    <h1 className="title-h1 font-weight-600">Activar cuenta</h1>

                    {
                        loading ?
                            // <LoadingSpinner /> 
                            <>
                                <h2 className="title-h3 font-weight-500 mt-3 mb-4">Estamos Validando tu cuenta....</h2>
                                <LinearProgress color="secondary" />
                            </>
                            :
                            (
                                <>
                                    <Alert severity={`${isActive.status}`} className="my-4" >{isActive.message}</Alert>

                                    <Button
                                        className="button button-outline-danger button-100-mobile"
                                        component={Link} to="/"
                                        color="default"
                                        onClick={ ()=> {
                                            dispatch(clearIsActive());
                                        }}
                                    >
                                        Iniciar Sesión
                                    </Button>
                                </>
                            )
                    }
                </div>
            </div>

            <div className="app-logo-content justify-center">
                <Link className="logo-lg" to="/" title="Jambo">
                    <img src={require("../assets/images/wscargologo.png")} alt="jambo" title="jambo" />
                </Link>
            </div>
        </div>
    )
};


export default ActivarCuenta;
