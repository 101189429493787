import{
    POST_UPLOAD_DOCUMENTS_EXPRESS,
    POST_UPLOAD_DOCUMENTS_EXPRESS_SUCCESS,
    POST_CONSOLIDADO_RAPIDO_EXPRESS,
    POST_CONSOLIDADO_RAPIDO_EXPRESS_SUCCESS,
    GET_INFO_TRACK_CONSOLIDADO_RAPIDO_EXPRESS,
    GET_INFO_TRACK_CONSOLIDADO_RAPIDO_EXPRESS_SUCCESS,
    UPDATE_STATE_NO_CONSOLIDADO_RAPIDO_EXPRESS,
    UPDATE_STATE_NO_CONSOLIDADO_RAPIDO_EXPRESS_SUCCESS
} from './Constants';

export const postUploadDocumentsExpress= (payload) => {
    return {
        type: POST_UPLOAD_DOCUMENTS_EXPRESS,
        payload: payload
    };
};

export const postUploadDocumentsExpressSuccess= (payload) => {
    return {
        type: POST_UPLOAD_DOCUMENTS_EXPRESS_SUCCESS,
        payload: payload
    };
};

export const postConsolidadoRapidoExpress= (payload) => {
    return {
        type: POST_CONSOLIDADO_RAPIDO_EXPRESS,
        payload: payload
    };
};

export const postConsolidadoRapidoExpressSuccess= (payload) => {
    return {
        type: POST_CONSOLIDADO_RAPIDO_EXPRESS_SUCCESS,
        payload: payload
    };
};

export const getInfoTrackConsolidadoRapidoExpress= (payload) => {
    return {
        type: GET_INFO_TRACK_CONSOLIDADO_RAPIDO_EXPRESS,
        payload: payload
    };
};

export const getInfoTrackConsolidadoRapidoExpressSuccess= (payload) => {
    return {
        type: GET_INFO_TRACK_CONSOLIDADO_RAPIDO_EXPRESS_SUCCESS,
        payload: payload
    };
};

export const updateStateNoConsolidadoRapidoExpress= (payload) => {
    return {
        type: UPDATE_STATE_NO_CONSOLIDADO_RAPIDO_EXPRESS,
        payload: payload
    };
};

export const updateStateNoConsolidadoRapidoExpressSuccess= (payload) => {
    return {
        type: UPDATE_STATE_NO_CONSOLIDADO_RAPIDO_EXPRESS_SUCCESS,
        payload: payload
    };
};




