import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Uno from './stepperTwo/One';
import Dos from './stepperTwo/Two'
import Tres from './stepperTwo/Three'
//import imgBox from './pp.jpeg';
import imgBox from '../placeholder.jpg';
import img7 from '../../../assets/images/ejecutivos/7.jpg'
import img8 from '../../../assets/images/ejecutivos/8.jpg'
import img27 from '../../../assets/images/ejecutivos/27.jpg'
import img29 from '../../../assets/images/ejecutivos/29.jpg'
import img30 from '../../../assets/images/ejecutivos/30.jpg'
import CloseIcon from '@material-ui/icons/Close';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab'
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { validationErrorsCotizacion } from '../../../models';
import {
    Avatar,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    Button,
    Paper,
    Typography,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    DialogContentText,
    makeStyles
} from "@material-ui/core";

import { ToastContainer, toast } from 'react-toastify';
import { globalChangeStatesCotizadorCliente, postIdClienteCotizadorCliente, showMessageCotizadorCliente,postDataCotizacion } from '../../../modules/cotizador/Actions';

import lodash from 'lodash';
// import { ContactSupportOutlined } from '@material-ui/icons';
/*
import {
    showBtnStepper,
    hiddenBtnStepper,
    mostrarFormUser,
    ocultarFormUser
} from '../Actions.js';*/

const useStyles = makeStyles((theme) => ({
    stepper: {
        background: 'transparent',
        padding: '0'
    },
    stepLabel: {
        background: '#fff',
    },
    iconLabel: {
        background: 'rgba(248, 201, 106, 0.471)',
        color: '#F8C96A',
        padding: '16px',
        marginRight: '16px'
    },
    iconLabelAtive: {
        background: 'rgba(111, 207, 151, 0.471)',
        color: '#6FCF97',
        padding: '16px',
        marginRight: '16px'
    },
    ButtonNav: {
        borderRadius: "100%",
        padding: '6px 12px',
        color: "#8F8F8F",
        fontSize: "20px",
        "&:hover": {
            backgroundColor: "transparent",
            color: "#44403f",
        }
    },
    icon: {
        fontSize: "16px"
    },
    avatarXl: {
        width: "80px",
        height: "80px",
        marginRight: "16px",
    },
    avatarXxl: {
        width: "180px",
        height: "180px",
        margin: "0 auto",

    },
    contentAvatar: {
        width: "100%"
    },
    paddingBottom: {
        padding: "0 24px",
        '@media screen and (max-width: 767px)': {
            padding: "0"
        }
    },
    closeButton: {
        position: 'absolute',
        right: "12px",
        top: "12px",
        color: theme.palette.grey[500],
    },
    titleDialog: {
        lineHeight: "24px",
        color: "#263238",
    }
}));

function getSteps() {
    return ['Datos de contacto', 'Datos de carga', 'Revisa tu cotización'];
}



const CardOne = (props) => {
    const classes = useStyles();

    const dispatch = useDispatch();

    const {
        comerciales,
        propuestaBase,
        validations
    } = useSelector(({ CotizadorCliente }) => CotizadorCliente);

    const [activeStep, setActiveStep] = React.useState(0);
    const [selectedComercial, setSelectedComercial] = React.useState(0);
    const steps = getSteps();

    function random_item(items) {

        return items[Math.floor(Math.random() * items.length)];

    }

    function calcularTarifa(datos) {
        var volumenEstimado = datos.volumen;
        var pesoEstimado = datos.peso;
        var valorUnitarioUsd = propuestaBase.valorUnitarioUsd;
        var valorBaseUsd = propuestaBase.valorBaseUsd;
        var cmbPeso = propuestaBase.cmbPeso;
        //var Pb_unidadesACobrar = propuestaBase.Pb_unidadesACobrar;
        var Pb_unidadesACobrar = propuestaBase.Pb_unidadesACobrar <= 0 ? propuestaBase.unidadesACobrar : propuestaBase.Pb_unidadesACobrar;
        if (!volumenEstimado) { volumenEstimado = 0; }
        if (!pesoEstimado) { pesoEstimado = 0; }
        if (!valorUnitarioUsd) { valorUnitarioUsd = 0; }
        if (!valorBaseUsd) { valorBaseUsd = 0; }

        var Peso = Math.round(parseFloat(pesoEstimado) / parseFloat(cmbPeso) * 100) / 100;

        if (Number(volumenEstimado) == Number(Peso)) { var Unidades = volumenEstimado; }
        else if (Number(volumenEstimado) > Number(Peso)) { var Unidades = volumenEstimado; }
        else if (Number(Peso) > Number(volumenEstimado)) { var Unidades = Peso; }
        else { var Unidades = 0; }

        if (Number(Unidades) < 1) { Unidades = 1; }

        var tarifaUsd = Number(Unidades) * parseFloat(valorUnitarioUsd);

        if (Number(Unidades) < Number(Pb_unidadesACobrar)) { tarifaUsd = Number(tarifaUsd) + Number(valorBaseUsd); }
        tarifaUsd = Math.round(tarifaUsd);
        return [tarifaUsd, Unidades];
    }

    const getStepContent=(stepIndex, props)=>{
        switch (stepIndex) {
            case 0:
                return <Uno payload={props.payload} />;
            case 1:
                return <Dos payload={props.payload} />;
            case 2:
                return <Tres payload={props.payload} handleNext={props.handleNext}/>;
            default:
                return 'Unknown stepIndex';
        }
    }

    const handleNext = () => {
        if (activeStep === 0) {
            if (props.payload.nombre.length === 0) {
                let newValidate = lodash.cloneDeep(validations);
                newValidate.nombre.error = true;
                newValidate.nombre.helperText = 'El nombre es obligatorio';
                dispatch(globalChangeStatesCotizadorCliente('validations', newValidate))
            } else if (props.payload.email.length === 0) {
                let newValidate = lodash.cloneDeep(validations);
                newValidate.email.error = true;
                newValidate.email.helperText = 'El email es obligatorio';
                dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                dispatch(globalChangeStatesCotizadorCliente('validations', validationErrorsCotizacion));
                dispatch(postIdClienteCotizadorCliente({ email: props.payload.email, nombre: props.payload.nombre, telefono: props.payload.telefono  }));
            }
        } else if (activeStep === 1) {
            // dispatch(mostrarFormUser(null))
            const obj = lodash.cloneDeep(props.payload);
            if (obj.volumen <= 0) {
                let newValidate = lodash.cloneDeep(validations);
                newValidate.volumen.error = true;
                newValidate.volumen.helperText = 'El volumen debe ser mayor que cero';
                dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
            } else if (obj.peso <= 0) {
                let newValidate = lodash.cloneDeep(validations);
                newValidate.peso.error = true;
                newValidate.peso.helperText = 'El peso debe ser mayor que cero';
                dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
            } else if (obj.descripcion.length === 0) {
                let newValidate = lodash.cloneDeep(validations);
                newValidate.descripcion.error = true;
                newValidate.descripcion.helperText = 'La descripción debe ser obligatoria';
                dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
            } else if (props.payload.marca === false) {
                toast['error']('Debe declarar que los productos no son de marca', { toastId: 1 })
            } else if (propuestaBase == null) {
                toast['error']('No existen valores para dichas zonas tarifarias', { toastId: 1 })
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                let item = random_item(comerciales);
                if (obj.fk_comercial != null && obj.fk_comercial > 0) {//1
                    item = comerciales.find(x => x.id == obj.fk_comercial);//NO LO ENCONTRE
                    if (item === undefined) {
                        let comercialesZoho = [7, 8, 27, 29]; // [lucylla, elisa, igor, cesar]
                        let tempComerciales = comerciales.filter((item) => comercialesZoho.includes(item.id));
                        item = random_item(tempComerciales);//BUSCA AL AZAR Y SE LO ASIGNA
                    }
                }
                setSelectedComercial(item);

                let valores = calcularTarifa(obj);
                const newPayload = {
                    ...obj,
                    fk_comercial: item.id,
                    tarifa: propuestaBase.valorUnitarioUsd,
                    total: valores[0],
                    unidadesACobrar: valores[1]
                }

                dispatch(globalChangeStatesCotizadorCliente('validations', validationErrorsCotizacion));
                dispatch(globalChangeStatesCotizadorCliente('payload', newPayload));
                dispatch(postDataCotizacion(newPayload));
                handleClickOpen()
            }


        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };
    const handleStep = (step) => () => {
        setActiveStep(step);

        if (step <= 1) {
            //dispatch(ocultarFormUser({}))
        }
    };

    // // variable local
    //  dialog
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // sidebar fixed
    const [show, setShow] = useState(false);
    const handleShow = () => {
        const seccion = document.getElementsByClassName('aside');
        //console.log(window.scrollY);
        if (typeof window !== seccion) {
            if (window.pageYOffset > 278) {
                if (!show) {
                    console.log("set to true");
                    setShow(true);
                }
            }
            if (window.pageYOffset < 278) {
                console.log("set to false");
                setShow(false);
            }
        }
    };

    React.useEffect(() => {
        const seccion = document.getElementsByClassName('sidenav1');
        if (typeof window !== seccion) {
            window.addEventListener("scroll", handleShow);
        }
        return () => {
            if (typeof window !== "undefined") {
                window.removeEventListener("scroll", handleShow);
            }
        };
    }, []);
    // sidebar fixed

    const getAvatar = (comercial) => {
        let img = "";
        switch (comercial.id) {
            case 7:
                img = img7;
                break;
            case 8:
                img = img8;
                break;
            case 27:
                img = img27;
                break;
            case 29:
                img = img29;
                break;
            case 30:
                img = img30;
                break;
            default:
                img = imgBox;
        }

        return img;
    }

    return (
        <>
            <div className="main-flex">
                {/* aside */}
                <aside className={show ? "aside aside-fixed mb-3" : "aside mb-3"}>
                    {/* <h3 className="title-h3 font-weight-700 mb-3">Simula tu cotización para iniciar tu importación</h3>
                    <p className="text font-weight-400">Solo necesitas el volumen (m³), peso y los productos a importar.</p>

                    <div className="border my-4"></div> */}

                    <div className="cards">
                        <div className="cards-body">
                            <h4 className="title-h4 font-weight-600 mb-3"><strong>Proceso fácil y rápido.</strong></h4>

                            <div className="align-item-center">
                                <CheckIcon className='icon-color-success mr-2' />
                                <h4 className="text-small font-weight-500">Realiza nuevas cotizaciones.</h4>
                            </div>

                            <div className="align-item-center my-3">
                                <CheckIcon className='icon-color-success mr-2' />
                                <h4 className="text-small font-weight-500">Regístrate.</h4>
                            </div>

                            <div className="align-item-center">
                                <CheckIcon className='icon-color-success mr-2' />
                                <h4 className="text-small font-weight-500">Descarga tus etiquetas.</h4>
                            </div>
                        </div>
                    </div>

                    {
                        activeStep === 2 &&
                        <div className="cards">
                            {/* <div className='cards-header px-4'>
                                <h5 className="text-small font-weight-500">Tu ejecutivo(a) comercial designado</h5>
                            </div>
                            */}
                            <div className="cards-body d-flex px-3 cards-body-cotizador">
                                <Avatar alt="Remy Sharp" src={getAvatar(selectedComercial)} className={classes.avatarXl} />

                                <div className={`card-data ${classes.contentAvatar}`}>
                                    <h4 className="text-sx">Ejecutivo comercial</h4>
                                    <h4 className="text font-weight-500 text-capitalize">{selectedComercial != null ? selectedComercial.nombre + ' ' + selectedComercial.apellidos : ''}</h4>
                                    <h5 className="text-small font-weight-400">{selectedComercial != null ? selectedComercial.email : ''}</h5>
                                    <div className="border my-2"></div>
                                    <div className="align-item-center">
                                        <a href={selectedComercial != null ? selectedComercial.telefono.length === 8 ? `https://wa.me/+569${selectedComercial.telefono}` : `https://wa.me/${selectedComercial.telefono}` : `https://wa.me/${selectedComercial.telefono}` } className="link-whatsApp mr-2" target="_blank"><WhatsAppIcon /></a>
                                        <a href={selectedComercial != null ? selectedComercial.telefono.length === 8 ? `https://wa.me/+569${selectedComercial.telefono}` : `https://wa.me/${selectedComercial.telefono}` : '' } className="link-phone" target="_blank">{selectedComercial != null ? selectedComercial.telefono.length === 8 ? `+569${selectedComercial.telefono}` : `${selectedComercial.telefono}` : ''}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }


                </aside>
                {/* fin aside */}

                {/* contenido right */}
                <section className="section">
                    <h3 className="title-h3 font-weight-700 mb-2">Simula tu cotización para iniciar tu importación</h3>
                    <p className="text font-weight-400 mb-4">Solo necesitas el volumen (m³), peso y los productos a importar.</p>

                    <div className="stepper-cotizar">
                        <Stepper activeStep={activeStep} className={classes.stepper} orientation="vertical">
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel className={classes.stepLabel}>
                                        <div className="justify-space-between">
                                            <div className="align-item-center">
                                                <div className={activeStep < index + 1 ? (classes.iconLabel) : (classes.iconLabelAtive)}>
                                                    <CheckCircleIcon />
                                                </div>

                                                {label}
                                            </div>

                                            {
                                                activeStep < index + 1 ?
                                                    ''
                                                    :
                                                    (
                                                        <IconButton
                                                            onClick={handleStep(index)}
                                                            className={classes.ButtonNav}
                                                            aria-label="editar"
                                                        >
                                                            <EditIcon className={classes.icon} />
                                                        </IconButton>
                                                    )
                                            }
                                        </div>
                                    </StepLabel>

                                    <StepContent>
                                        {getStepContent(index, props)}
                                        <div className={`my-3 ${classes.paddingBottom}`} >
                                            {/* {activeStep === 1 ? (() => dispatch(mostrarFormUser({}))): (handleNext())} */}
                                            {
                                                activeStep === steps.length - 1 ?
                                                    ''
                                                    :
                                                    (
                                                        <Button className="button button-warning button-100-mobile" onClick={handleNext}>
                                                            <KeyboardTabIcon className="mr-2" /> {activeStep === 1 ? "Generar cotización" : "Siguiente"}
                                                        </Button>
                                                    )
                                            }

                                        </div>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>

                        {activeStep === steps.length && (
                            <Paper square elevation={0} className={classes.resetContainer}>
                                <Typography>All steps completed - you&apos;re finished</Typography>
                                <Button onClick={handleReset} className={classes.button}>
                                    Reset
                                </Button>
                            </Paper>
                        )}
                    </div>
                </section>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className="mt-3 pb-0 justify-end" id="alert-dialog-title">
                        <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText>
                            <Avatar alt="Remy Sharp" src={getAvatar(selectedComercial)} className={classes.avatarXxl} />
                        </DialogContentText>
                    </DialogContent>

                    <DialogContent className="text-center mb-3">
                        <DialogTitle className='px-0'>¡Hola, soy <strong>{selectedComercial != null ? selectedComercial.nombre + ' ' + selectedComercial.apellidos : ''}</strong>!</DialogTitle>
                        <DialogContentText>
                            Yo seré quien te acompañe en tu proceso de importación, si tienes dudas sobre el servicio puedes contactarte conmigo.
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                {/* contenido right */}
            </div>
        </>

    )
};

export default CardOne;