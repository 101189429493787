import React, { useEffect, useState, useMemo } from 'react';
import ProgresBarMobile from './ProgresBarMobile';
import PropTypes from 'prop-types';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

import {
    Button,
    makeStyles,
    Tab,
    Tabs,
    AppBar,
} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({ 
    listItem: {
        display: "flex",
        flexDirection: "column"
    },
    icon: {
        fontSize: "16px",
        color: "8F8F8F",
        marginRight:"8px"
    }
}));

// Function Tabs MUI //
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <div p={3}>
                    {children}
                </div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
// Function Tabs MUI //

const CardTwo = ({item, handleOpenAccion}) => {
    const classes = useStyles();
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(true);

    // ver detalle del valor servicio
    const handleClick = () => {
        setOpen(!open);
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className="tabs-material-ui">
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Detalle Carga" {...a11yProps(0)} />
                    { item.tags == "cotización" ? "" :  <Tab label="Estado Carga" {...a11yProps(1)} />}
                </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
                <div className="list_item-border mt-3">
                    <h5 className="text-small font-weight-400">
                        Volumen Final { item.volumenFinal == '' ? "S/I" : `${item.bultosEsperados} M³` }
                    </h5>
                </div>

                <div className="list_item-border">
                    <h5 className="text-small font-weight-400">
                        Peso Final { item.pesoFinal == '' ? "S/I": `${item.pesoFinal} kg` }
                    </h5>
                </div>

                <div className="list_item-border">
                    <div className="list-data">
                        <h4 className="text-small font-weight-600">Recepción Bultos</h4>
                        <h5 className="text-small font-weight-400">
                            Prim. {item.primeraRecepcion == '' ? "S/I" : `${item.primeraRecepcion}` } 
                            <span className='slash mx-1'>/</span>
                            Últ. {item.ultimaRecepcion == '' ? "S/I" : `${item.ultimaRecepcion}` }  
                        </h5>
                    </div>
                </div>

                <div className={`list_item-border ${classes.listItem}`}  onClick={handleClick}>
                    <div className="list-data justify-space-between mb-2">
                        <div className="align-item-center">
                            {open ? (<ExpandMoreIcon className={classes.icon} />) : (<ExpandLessIcon className={classes.icon} />)}
                            <h4 className="text-small font-weight-600">Pago</h4>
                        </div>
                        <h5 className="text font-weight-600">$ {item.valorFinalServicio}</h5>
                    </div>

                    {
                        open ? '' :
                        <div className="list-data">
                            <h5 className="text-small font-weight-400">
                                Ws Cargo: {item.valorWsCargo == '' ? "S/I" : `$${item.valorWsCargo}`} 
                                <span className='slash mx-1'>/</span>
                                Impuesto: {item.valorImpuesto== '' ? "S/I" : `$${item.valorImpuesto}`} 
                            </h5>
                            <h5 className="text-small font-weight-400">
                                Otro: {item.otroValor == '' ? "S/I" : `$ ${item.otroValor}` } 
                            </h5> 
                        </div>
                    }
                </div>
                

                { item.tags == "acción requerida" ? 
                    <Button 
                            className='button  button-small'
                            onClick={handleOpenAccion}
                    >
                            <VisibilityIcon className="icon-color-warnin mr-2" />
                            Revisar Acción Requerida
                    </Button>
                    : ""
                }

                { item.tags == "alerta" ? 
                    <Button 
                        className='button  button-small'
                        onClick={handleOpenAccion}
                    >
                            <VisibilityIcon className="icon-color-warnin mr-2" />
                            Revisar Acción Urgente
                    </Button>
                    : ""
                }
            </TabPanel>

            <TabPanel value={value} index={1}>
                <ProgresBarMobile />
            </TabPanel>
        </div>
    )
}

export default CardTwo