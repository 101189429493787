import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import IniciarServicio from './form/IniciarServicio';
import Cargando from './loading/Loading'
import Messege from './Message'

import {
    Button,
    Typography,
    IconButton,
    Hidden,
    makeStyles,
    Menu,
    MenuItem,
    ListItemIcon,
    Tooltip
} from "@material-ui/core";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import GetAppIcon from '@material-ui/icons/GetApp';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import PostAddIcon from '@material-ui/icons/PostAdd';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import envConfig from '../../../envConfig';


const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#263238',
        color: '#fff',
        maxWidth: 300,
        padding: "12px 16px",
        "& .MuiTypography-h5": {
            fontSize: "14px"
        },
        "& .MuiTypography-h6": {
            fontSize: "10px"
        }
    },
}))(Tooltip);

const imagesInital = [
    {
        nuemeroServicio: "1",
        bultosEsperados: "",
        bultosRecibidos: "",
        volumenFinal: "6",
        pesoFinal: "4",
        primeraRecepcion: "",
        ultimaRecepcion: "",
        valorFinalServicio: "500.000",
        valorWsCargo: "400.000",
        valorImpuesto: "",
        otroValor: "",
        tags: "cotización"
    }
];

const useStyles = makeStyles((theme) => ({
    listItem: {
        display: "flex",
        flexDirection: "column"
    },
    border4: {
        borderLeft: "solid 6px  #bdbdbd"
    },
    icon: {
        fontSize: "16px",
        color: "#8F8F8F",
    },
    iconDanger: {
        fontSize: "16px",
        color: "#EA5259",
    },
    iconWarning: {
        fontSize: "16px",
        color: "#F8C96A",
    },
}));
const Cotizaciones = ({ handleClickOpenThree }) => {

    const CryptoJS = require("crypto-js");
    const clave = 'ClaveSecreta';

    function encriptarTexto(texto) {
        const textoEncriptado = CryptoJS.AES.encrypt(texto, clave).toString();
        const textoEncriptadoBase64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(textoEncriptado));
        const textoEncriptadoURL = encodeURIComponent(textoEncriptadoBase64);
        return textoEncriptadoURL;
    }

    const classes = useStyles();
    const [cardCotizaciones, setCardCotizaciones] = useState(imagesInital)
    const [serviceDetail, setServiceDetail] = useState(null)
    const [dropdown, setDropdown] = useState(null);
    const [iniciarServicio, setIniciarServicio] = useState(false);
    const [loading, setLoading] = useState(false)
    const [response, setResponse] = useState(null)

    const showDetail = (index) => {
        if (serviceDetail === index) {
            return setServiceDetail(null)
        }
        setServiceDetail(index);
    }

    const handleClick = (event) => {
        setDropdown(event.currentTarget);
        console.log(setDropdown(event.currentTarget))
    };
    const handleClose = () => {
        setDropdown(null);
    };

    // dialog nuevo servicio
    const handleIniciarServicio = () => {
        setIniciarServicio(true);
    };
    const handleCloseIniciarServicio = () => {
        setIniciarServicio(false);
    };

    return (
        <>
            <div className="app-tags justify-space-between mb-4">
                <div>
                    <h4 className="title-h4 font-weight-700">Cotizaciones</h4>
                    <Hidden smDown>
                        <p className="text-small font-weight-500">Recuerda que cotizaciones tiene una vigencia de 7 días.</p>
                    </Hidden>
                    <Hidden smUp>
                        <p className="text-small font-weight-500">Realiza nuevas cotizaciones</p>
                    </Hidden>
                </div>

                {/* mensaje versión desktop */}
                <Hidden smDown>
                    <Button
                        onClick={handleClickOpenThree}
                        className="button button-small button-gray"
                    >
                        nueva cotización
                    </Button>
                </Hidden>

                {/* buscador versión mobile */}
                <Hidden smUp>
                    <IconButton
                        aria-label="agregar cotizacion"
                        className="icon-button icon-button-danger icon-button-small"
                        onClick={handleClickOpenThree}
                    >
                        <AddIcon fontSize="small" />
                    </IconButton>
                </Hidden>
            </div>


            {
                cardCotizaciones.map((item, index) => (
                    <>
                        <div className={`cards app-cards  ${classes.border4}`} key={index}>
                            <div className="cards-body content-space-between py-md-3">
                                {/* Info general del servicio */}
                                <div className="card-data align-item-center" onClick={() => showDetail(index)}>

                                    <Hidden smUp>
                                        {
                                            serviceDetail === index ?
                                                <ArrowUpwardIcon className="mr-2 icon-color-default" /> :
                                                <ArrowDownwardIcon className="mr-2 icon-color-default" />
                                        }
                                    </Hidden>

                                    <div className="cards-icon icon-xl mr-3 mr-md-4 icon-default">
                                        <DirectionsBoatIcon fontSize="small" />
                                    </div>

                                    <div className="card-item">
                                        <h4 className="text-small font-weight-700">Cotización Nº {item.nuemeroServicio}</h4>
                                        <div className='align-item-center'>
                                            <Hidden smDown>
                                                <h5 className="text-small font-weight-400">
                                                    Vol. {item.volumenFinal} M³
                                                </h5>
                                                <h5 className="text-small font-weight-400 mx-4">
                                                    Peso {item.pesoFinal} kg
                                                </h5>

                                                {/* tooltip */}
                                                <HtmlTooltip
                                                    title={
                                                        <React.Fragment>
                                                            <Typography variant="h5" className="mb-1" color="inherit">Detalle de pago</Typography>
                                                            <Typography variant="h6" color="inherit">Tarifa 405 USD/m3</Typography>
                                                        </React.Fragment>
                                                    }
                                                >
                                                    <MonetizationOnIcon className={`mr-1 ${classes.icon}`} fontSize="small" />
                                                </HtmlTooltip>
                                            </Hidden>
                                            <h5 className="text-small font-weight-400">{item.valorFinalServicio}</h5>

                                            <Hidden smDown>
                                                <ReportProblemIcon className={`ml-4 mr-1 ${classes.iconWarning}`} />
                                                <h5 className="text-small font-weight-400">Cotización expira en 5 días.</h5>
                                            </Hidden>
                                        </div>
                                    </div>
                                </div>
                                {/* Fin - Info general del servicio */}


                                {/* Acción menu(descargar cotizacón y etiquetas) */}
                                <div className="card-item justify-end">
                                    <Hidden smDown>
                                        <Button 
                                            onClick={() => handleIniciarServicio()} 
                                            className="button button-outline-gray button-small"
                                        >

                                            Iniciar servicio
                                        </Button>
                                    </Hidden>

                                    {/* Icon menu */}
                                    <IconButton
                                        aria-label="Ejecutivo"
                                        aria-controls="simple-menu2"
                                        aria-haspopup="true"
                                        onClick={handleClick}
                                        className="icon-button ml-2"
                                    >
                                        <MoreVertIcon fontSize="small" />
                                    </IconButton>

                                    <Menu
                                        id="simple-menu2"
                                        anchorEl={dropdown}
                                        keepMounted
                                        open={Boolean(dropdown)}
                                        onClose={handleClose}
                                        className="app-dropdown"
                                        variant="selectedMenu"
                                    >
                                        <MenuItem component={Link} to="#" 
                                        onClick={() => { 
                                            window.open( envConfig.enviroment.urlApiExternal+"get_pdf_cotizacion/" + encriptarTexto(item.nuemeroServicio.toString(), "codigo secreto"));
                                        }}
                                        /*
                                        onClick={handleClose}
                                        */
                                        >
                                            <ListItemIcon>
                                                <PostAddIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit">Descargar Cotización</Typography>
                                        </MenuItem>

                                        <MenuItem component={Link} to="#" onClick={handleClose}>
                                            <ListItemIcon>
                                                <LocalOfferIcon fontSize="small" />
                                            </ListItemIcon>
                                            <Typography variant="inherit">Descargar Etiquetas</Typography>
                                        </MenuItem>

                                        <MenuItem component={Link} to="#" onClick={handleClose}>
                                            <ListItemIcon>
                                                <DeleteIcon fontSize="small" className="icon-color-danger" />
                                            </ListItemIcon>
                                            <Typography variant="inherit" className="text-danger">Eliminar</Typography>
                                        </MenuItem>
                                    </Menu>
                                </div>
                            </div>
                        </div>

                        {/* detalle servión mobile */}
                        <Hidden smUp>
                            <div className={serviceDetail === index ? "card-detail show" : "card-detail"}>
                                <div className="cards-body">
                                    <div className="list_item mt-3">
                                        <h5 className="text-small font-weight-400">
                                            Volumen {item.volumenFinal}M³<span className="slash">/</span>Peso {item.pesoFinal}kg
                                        </h5>
                                    </div>

                                    <div className={`list_item ${classes.listItem}`}>
                                        <div className="list-data justify-space-between mb-2">
                                            <h4 className="text-small font-weight-600">Pago</h4>
                                            <h5 className="text font-weight-600">$ {item.valorFinalServicio}</h5>
                                        </div>
                                        <div className="list-data">
                                            <h5 className="text-small font-weight-400">Tarifa 405 USD/m3</h5>
                                        </div>
                                    </div>

                                    <div className="list_item justify-space-between">
                                        <div className="list-data align-item-center">
                                            <GetAppIcon className="icon-color-default mr-2" />
                                            <h4 className="text-small font-weight-500">Cotización</h4>
                                        </div>
                                        <Link to="#" className='text-sx link'>Descaragar</Link>
                                    </div>

                                    <div className="list_item justify-space-between">
                                        <div className="list-data align-item-center">
                                            <GetAppIcon className="icon-color-default mr-2" />
                                            <h4 className="text-small font-weight-500">Etiquetas</h4>
                                        </div>
                                        <Link to="#" className='text-sx link'>Descaragar</Link>
                                    </div>

                                    <div className="list_item">
                                        <ReportProblemIcon className="icon-color-warning mr-2" />
                                        <h4 className="text-small font-weight-500">Cotización expira en 5 días.</h4>
                                    </div>

                                    <Button
                                        onClick={() => handleIniciarServicio()} 
                                        className="button button-outline-gray button-small button-100-mobile mt-4">
                                        Iniciar servicio
                                    </Button>
                                </div>
                            </div>
                        </Hidden>
                    </>
                ))
            }

           


            {/* Form cotizacion */}
        
            <IniciarServicio
                setResponse={setResponse}
                setLoading={setLoading}
                iniciarServicio={iniciarServicio}
                setIniciarServicio={setIniciarServicio}
                handleCloseIniciarServicio={handleCloseIniciarServicio}
            />
            {loading && <Cargando />}

            {response &&

                // iconSuccess
                // iconDanger
                <Messege
                    response={response}
                    tituloMensaje="Has iniciado servicio"
                    icon="iconSuccess"
                    mensaje="Has iniciado con exito tu servicio, Revisa tu el estado y descarga tus etiquetas."
                />
            }
        </>
    )
}

export default Cotizaciones