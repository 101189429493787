import lodash from 'lodash';
import{
    GLOBAL_CHANGE_STATES_COTIZADOR_CLIENTE,
    GET_COMERCIALES_COTIZADOR_CLIENTE_SUCCESS,
    GET_PROPUESTA_BASE_COTIZADOR_CLIENTE_SUCCESS,
    GET_REGIONES_COTIZADOR_CLIENTE_SUCCESS,
    GET_COMUNAS_COTIZADOR_CLIENTE_SUCCESS,
    POST_ID_CLIENTE_COTIZADOR_CLIENTE_SUCCESS,
    SHOW_MESSAGE_COTIZADOR_CLIENTE,
    HIDE_MESSAGE_COTIZADOR_CLIENTE,
    GET_EXISTS_USER_COTIZADOR_CLIENTE_SUCCESS,
    GET_ZONAS_TARIFARIAS_COTIZADOR_CLIENTE_SUCCESS,
    VALIDATE_RUT_COTIZADOR_SUCCESS,
    CLEAR_ALL_EXPDIG_COTIZADOR_SUCCESS
} from './Constants';
import {payloadCotizacion,validationErrorsCotizacion} from '../../models';

const INIT_STATE = {
    loader: false,
    showMessage:false,
    alertMessage:{type:'',text:''},
    controlShow: false,
    controlMostar: true,
    payload:payloadCotizacion,
    comerciales:[],
    propuestaBase:null,
    regiones:[],
    comunas:[],
    validations:validationErrorsCotizacion,
    modalPdf:{
        open:false
    },
    userExists:false,
    lectura:false,
    zonasTarifarias:[],
    rutExists:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_ALL_EXPDIG_COTIZADOR_SUCCESS:{
            return{
                ...INIT_STATE
            }
        }
        case GLOBAL_CHANGE_STATES_COTIZADOR_CLIENTE:{
            const newState = lodash.cloneDeep(state);
            const [key] = Object.keys(action.payload);
            newState[action.atributte] = { ...newState[action.atributte], ...action.payload};
            return{
                ...newState,
            }
        }
        case GET_COMERCIALES_COTIZADOR_CLIENTE_SUCCESS:{
            return{
                ...state,
                comerciales:action.payload
            }
        }
        case GET_PROPUESTA_BASE_COTIZADOR_CLIENTE_SUCCESS:{
            let newPayload=lodash.cloneDeep(state.payload);
            newPayload.tarifa=action.payload.valorUnitarioUsd;
            newPayload.valorBaseUsd=action.payload.valorBaseUsd;
            newPayload.unidadesACobrar=action.payload.unidadesACobrar;
            newPayload.cmbPeso=action.payload.cmbPeso;
            if(action.payload.length>0){
                return{
                    ...state,
                    propuestaBase:action.payload[0]
                }
            }else{
                return{
                    ...state,
                    propuestaBase:null
                }
            }
            
        }
        case GET_REGIONES_COTIZADOR_CLIENTE_SUCCESS:{
            return{
                ...state,
                regiones:action.payload
            }
        }
        case GET_COMUNAS_COTIZADOR_CLIENTE_SUCCESS:{
            return{
                ...state,
                comunas:action.payload
            }
        }
        case POST_ID_CLIENTE_COTIZADOR_CLIENTE_SUCCESS:{
            return{
                ...state,
                payload:{
                    ...state.payload,
                    fk_cliente:action.payload.id,
                    fk_comercial:action.payload.fk_comercial
                }
            }
        }
        case SHOW_MESSAGE_COTIZADOR_CLIENTE:{
            return{
                ...state,
                showMessage:true,
                alertMessage:action.payload
            }
        }
        case HIDE_MESSAGE_COTIZADOR_CLIENTE:{
            return{
                ...state,
                showMessage:false,
                alertMessage:{type:'',text:''}
            }
        }
        case GET_EXISTS_USER_COTIZADOR_CLIENTE_SUCCESS:{
            let exists=false;
            if(action.payload && action.payload.id && action.payload.id>0){
                exists=true;
            }
            return{
                ...state,
                userExists:exists
            }
        }
        case GET_ZONAS_TARIFARIAS_COTIZADOR_CLIENTE_SUCCESS:{
            return{
                ...state,
                zonasTarifarias:action.payload
            }
        }
        case VALIDATE_RUT_COTIZADOR_SUCCESS:{
            return{
                ...state,
                rutExists:action.payload
            }
        }
        default:
            return state;
    }
}