import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardOne from './stepper/one';
import CardTwo from './stepper/two';
import CardThree from './stepper/three';
import imgBox from './placeholder.jpg'
import Header from './header/Header';
import EditIcon from '@material-ui/icons/Edit';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardTabIcon from '@material-ui/icons/KeyboardTab';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import './styles.scss';
import lodash from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import LogoWscargo from '../../assets/images/wscargologo_old.png';

import {
  Avatar,
  Stepper,
  Step,
  StepLabel,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,

} from "@material-ui/core";

import {
  postCotizadorCliente,
  getComercialesCotizadorCliente,
  getPropuestaBaseCotizadorCliente,
  getRegionesCotizadorCliente,
  hideMessageCotizadorCliente,
  globalChangeStatesCotizadorCliente,
  getZonasTarifariasCotizadorCliente
} from './Actions';
import { validationErrorsCotizacion } from '../../models';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  // instructions: {
  //   marginTop: "80px",
  //   marginBottom: theme.spacing(1),
  // },
  // bgStepper: {
  //   background: 'transparente',
  //   padding: '24px 100px 24px 100px'
  // },
  avatarXxl: {
    width: "180px",
    height: "180px",
    margin: "0 auto",

  },
  iconLabel: {
    color: 'red',
    padding: '8px',
  },
  iconLabelAtive: {
    color: "#fff",
  },
}));

function getSteps() {
  return ['Cotizar', 'Registrar', 'Etiquetas'];
}



const Cotizador = (props) => {
  const dispatch = useDispatch();

  const {
    controlShow,
    controlMostar,
    comerciales,
    propuestaBase,
    regiones,
    showMessage,
    alertMessage,
    validations,
    userExists,
    lectura,
    zonasTarifarias
  } = useSelector(({ CotizadorCliente }) => CotizadorCliente);


  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [prueba, setPrueba] = useState(3);
  const steps = getSteps();
  // variable local
  const [viuwForm, setViuwForm] = useState(false);

  /******useEffects******* */
  useEffect(() => {
    if (showMessage) {
      toast[`${alertMessage.type}`](alertMessage.text);
      setTimeout(() => {
        dispatch(hideMessageCotizadorCliente());
      }, 100);
    }
  }, [showMessage]);

  useEffect(() => {
    setViuwForm(controlMostar);
  }, [controlMostar]);


  useEffect(() => {
    if (comerciales.length === 0) {
      dispatch(getComercialesCotizadorCliente());
    }
  }, []);

  useEffect(() => {
    if (zonasTarifarias.length === 0) {
      dispatch(getZonasTarifariasCotizadorCliente());
    }
  }, []);

  useEffect(() => {
    if (regiones.length === 0) {
      dispatch(getRegionesCotizadorCliente());
    }
  }, []);

  useEffect(() => {
    if (propuestaBase == null) {
      dispatch(getPropuestaBaseCotizadorCliente({ fk_zonaAlmacenaje: 1, fk_zonaDestino: 1, fk_zonaOrigen: 2 }));
    }
  }, []);



  const handleNext = () => {
    if (activeStep === 1) {

      //guardar
      let payloadFinal = lodash.cloneDeep(props.payload);
      payloadFinal.cmbPeso = propuestaBase.cmbPeso;
      // payloadFinal.unidadesACobrar=propuestaBase.unidadesACobrar;
      payloadFinal.valorBaseUsd = propuestaBase.valorBaseUsd;

      if (payloadFinal.usuario.email.length === 0) {
        let newValidate = lodash.cloneDeep(validations);
        newValidate.usuario.email.error = true;
        newValidate.usuario.email.helperText = 'El email es obligatorio';
        dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
      } else if (payloadFinal.usuario.password.length === 0) {
        let newValidate = lodash.cloneDeep(validations);
        newValidate.usuario.password.error = true;
        newValidate.usuario.password.helperText = 'El password es obligatorio';
        dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
      } else if (payloadFinal.usuario.passwordConfirm.length === 0) {
        let newValidate = lodash.cloneDeep(validations);
        newValidate.usuario.passwordConfirm.error = true;
        newValidate.usuario.passwordConfirm.helperText = 'La confirmación del password es obligatorio';
        dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
      } else if (props.payload.tipo === 1) {
        if (props.payload.empresa.rut.length == 0) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.empresa.rut.error = true;
          newValidate.empresa.rut.helperText = 'El rut es obligatorio';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
        } else if (props.payload.empresa.razonSocial.length == 0) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.empresa.razonSocial.error = true;
          newValidate.empresa.razonSocial.helperText = 'La razón social es obligatoria';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
        } else if (props.payload.empresa.email.length == 0) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.empresa.email.error = true;
          newValidate.empresa.email.helperText = 'El email es obligatorio';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
        } else if (props.payload.empresa.calle.length == 0) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.empresa.calle.error = true;
          newValidate.empresa.calle.helperText = 'La calle es obligatoria';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
        } else if (props.payload.empresa.fk_region == '') {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.empresa.fk_region.error = true;
          newValidate.empresa.fk_region.helperText = 'La región es obligatoria';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
        } else if (props.payload.empresa.fk_comuna == '') {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.empresa.fk_comuna.error = true;
          newValidate.empresa.fk_comuna.helperText = 'La comuna es obligatoria';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
        } else {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          handleClickOpen();
          dispatch(globalChangeStatesCotizadorCliente('validations', validationErrorsCotizacion));
          dispatch(postCotizadorCliente(payloadFinal));
        }
      } else if (props.payload.tipo === 2) {
        if (props.payload.persona.rut.length == 0) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.persona.rut.error = true;
          newValidate.persona.rut.helperText = 'El rut es obligatorio';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
        } else if (props.payload.persona.nombre.length == 0) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.persona.nombre.error = true;
          newValidate.persona.nombre.helperText = 'El nombre es obligatorio';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
        } else if (props.payload.persona.email.length == 0) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.persona.email.error = true;
          newValidate.persona.email.helperText = 'El email es obligatorio';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
        } else if (props.payload.persona.calle.length == 0) {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.persona.calle.error = true;
          newValidate.persona.calle.helperText = 'La calle es obligatoria';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
        } else if (props.payload.persona.fk_region == '') {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.persona.fk_region.error = true;
          newValidate.persona.fk_region.helperText = 'La región es obligatoria';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
        } else if (props.payload.persona.fk_comuna == '') {
          let newValidate = lodash.cloneDeep(validations);
          newValidate.persona.fk_comuna.error = true;
          newValidate.persona.fk_comuna.helperText = 'La comuna es obligatoria';
          dispatch(globalChangeStatesCotizadorCliente('validations', newValidate));
        } else {

          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          handleClickOpen();
          dispatch(globalChangeStatesCotizadorCliente('validations', validationErrorsCotizacion));
          dispatch(postCotizadorCliente(payloadFinal));
        }

      }

    } else if (activeStep == 0 && userExists == true) {
      let payloadFinal = lodash.cloneDeep(props.payload);
      payloadFinal.cmbPeso = propuestaBase.cmbPeso;
      // payloadFinal.unidadesACobrar=propuestaBase.unidadesACobrar;
      payloadFinal.valorBaseUsd = propuestaBase.valorBaseUsd;
      payloadFinal.tipo = 0;
      setActiveStep((prevActiveStep) => prevActiveStep + 2);
      handleClickOpen();
      dispatch(globalChangeStatesCotizadorCliente('validations', validationErrorsCotizacion));
      dispatch(postCotizadorCliente(payloadFinal));
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    setPrueba(prueba - 1)
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    setPrueba(prueba + 1)
    if (step === 0) {
      //dispatch(ocultarFormUser(null))
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setPrueba(prueba + 1)
  };

  const handleReset = () => {
    setActiveStep(0);
    setPrueba(3)
  };

  //  dialog
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getStepContent=(stepIndex, props, handleNext)=>{
    switch (stepIndex) {
      case 0:
        return <CardOne payload={props.payload} handleNext={handleNext}/>;
      case 1:
        return <CardTwo payload={props.payload} />;
      case 2:
        return <CardThree payload={props.payload} />;
      default:
        return 'Unknown stepIndex';
    }
  }

  return (
    <>
      {/* <Header /> */}
      <div className='app-header-cotizador'>
        <div className='main-title'>
          {activeStep === 0 && <h1 className="title-h2 text-light font-weight-700">Estás a 3 pasos para comenzar tu importación...</h1>}
          {activeStep === 1 && <h1 className="title-h2 text-light font-weight-700">Estás a 2 pasos para comenzar tu importación...</h1>}
          {activeStep === 2 && <h1 className="title-h2 text-light font-weight-700">Ya estamos en el paso final..</h1>}
        </div>
        {/* <h2 className="title-h2 text-light font-weight-700">Estas a {prueba} pasos para comenzar tu importación...</h2> */}
      </div>

      <div className="container-fuid paddingContainer">

        {/* <div className="text-center"><h2 className="title-h2 font-weight-700">Estas a {prueba} pasos para comenzar tu importación...</h2></div> */}

        <div className="stepper stepper-cotizador">
          <Stepper activeStep={activeStep} className="app-stepper">
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel>
                  {/* <div className="justify-center-center" onClick={activeStep < index + 1 ? "" : (handleStep(index))}> */}

                  <div className="app-stepper-label">
                    {
                      activeStep === 2 ?
                        // step descargar etiquetas
                        <div className="icon-label-active">
                          <CheckCircleIcon />
                        </div>
                        :
                        // step cotizar - regitrarse
                        <div className={activeStep < index + 1 ? "icon-label" : "icon-label-active"}>
                          {activeStep < index + 1 ? (<FiberManualRecordIcon />) : (<CheckCircleIcon />)}
                        </div>
                    }

                    {label}
                  </div>
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <div className="stepper-content">{getStepContent(activeStep, props, handleNext)}</div>
          {/* <div>
            {activeStep === steps.length ? (
              <div>
                <Typography className={classes.instructions}>All steps completed</Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button variant="contained" color="primary" 
                    onClick={handleNext}
                    onClick={() => { handleNext(); dispatch(mostrarFormUser({}));}}
                  >
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next LLLL'}
                  </Button>
                </div>
              </div>
            )}
          </div> */}
          {
            // viuwForm && ((activeStep===0 && lectura) || (activeStep===1))  ?
            viuwForm && ((activeStep === 0) || (activeStep === 1)) ?
              (
                <div className={`justify-end mb-5 mr-2 ${activeStep === 1 ? 'app-section-register pb-5' : ''}`}>
                  {/* justify-end mb-5 mr-2"   */}
                  {activeStep === 2 && "etiquetas"}
                  {activeStep!=0 &&
                  <Button
                    className={`button button-primary button-100-mobile ${lectura ? '' : 'button-disable'}`}
                    onClick={handleNext
                    }>
                    <KeyboardTabIcon className="mr-2" /> {activeStep === steps.length - 1 ? 'Iniciar Inportación' : 'Siguiente Etapa'}
                  </Button>
                  }
                </div>
              )
              : null
          }
        </div>


        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle className="pb-0 pt-2 justify-end" id="alert-dialog-title">
            <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent className="text-center">
            <DialogContentText className="text-center">
              <img src={LogoWscargo} className="app-avatar-img" />
            </DialogContentText>
          </DialogContent>
          <DialogContent className="text-center mb-3">

            <DialogTitle id="simple-dialog-title">¡Bienvenido a WS Cargo!</DialogTitle>

            <DialogContentText className="px-0 px-md-5">
              Hola <strong>{props.payload.nombre}</strong>, te has registrado con éxito y puedes comenzar con tu importación, recuerda revisar la bandeja de entrada de tu correo para activar tu cuenta.
            </DialogContentText>
          </DialogContent>
        </Dialog>

      </div>
      
      <ToastContainer />
    </>

  )
};

export default Cotizador;