export const GET_TRACK = 'GET_TRACK';
export const GET_TRACK_SUCCESS = 'GET_TRACK_SUCCESS';
export const GET_TRACK_HISTORY = 'GET_TRACK_HISTORY';
export const GET_TRACK_HISTORY_SUCCESS = 'GET_TRACK_HISTORY_SUCCESS';
export const GET_TRACK_DOCUMENTS = 'GET_TRACK_DOCUMENTS';
export const GET_TRACK_DOCUMENTS_SUCCESS = 'GET_TRACK_DOCUMENTS_SUCCESS';
export const GET_TRACK_FECHAS_ESTIMADAS='GET_TRACK_FECHAS_ESTIMADAS';
export const GET_TRACK_FECHAS_ESTIMADAS_SUCCESS='GET_TRACK_FECHAS_ESTIMADAS_SUCCESS';
export const TCK_SETMOSTRARMODALPAGARSERVICIO='TCK_SETMOSTRARMODALPAGARSERVICIO';
export const TCK_MODALPAGARSERVICIO_GETINFO='TCK_MODALPAGARSERVICIO_GETINFO';
export const TCK_MODALPAGARSERVICIO_GETINFO_SUCCESS='TCK_MODALPAGARSERVICIO_GETINFO_SUCCESS';
export const TCK_SETMOSTRARLOADINGPAGOS='TCK_SETMOSTRARLOADINGPAGOS';
export const TCK_SETMOSTRARLOADINGPAGOS_SUCCESS='TCK_SETMOSTRARLOADINGPAGOS_SUCCESS';
export const TCK_REALIZARPAGOSERVICIO='TCK_REALIZARPAGOSERVICIO';
export const GET_TRACK_LIST_MODIFY_CNSLD='GET_TRACK_LIST_MODIFY_CNSLD';
export const GET_TRACK_LIST_MODIFY_CNSLD_SUCCESS='GET_TRACK_LIST_MODIFY_CNSLD_SUCCESS';
export const GET_TIME_LINE = 'GET_TIME_LINE';
export const GET_TIME_LINE_SUCCESS = 'GET_TIME_LINE_SUCCESS';
