import lodash from 'lodash';
/*****************************************************************/
import{
    CUSTSURV_SET_SHOWCARGANDO,
    CUSTSURV_SET_DIALOG,
    
    CUSTSURV_GET_ENCUESTADATA_SUCCESS,
    CUSTSURV_GET_ENCUESTADATA_FAIL,
    CUSTSURV_SET_DIALOGCONFIRMAR,

} from './Constants';
/*****************************************************************/

/*****************************************************************/
const INIT_STATE = {
    CUSTSURV_ShowCargando:0,
    CUSTSURV_Dialog:{
        id:0,
        background:'bg-gray',
        content:'label.SoloUnPunto',
        title:'label.SoloUnPunto',
        show:0,
    },

    CUSTSURV_EncuestaCabecera:{
        id:0,
        nombre:'',
        descripcion:'',
        ejecucion:0,
        id_ejecucion:'',
    },

    CUSTSURV_Encuesta1Detalle:{
        id:0,
        satisfaccion:0,
        mensaje:'',
    },

    CUSTSURV_Encuesta2Detalle:{
        id:0,
        p1r1:'',
        p2r1:'',
        p2r2:'',
        p2r3:'',
        p2r4:'',
        p2r5:'',
        p2r6:'',
        p3r1:'',
        p3r2:'',
        p4r1:'',
        p5r1:'',
    },

    CUSTSURV_DialogConfirmar:{
        id:0,
        background:'bg-gray',
        content:'label.SoloUnPunto',
        title:'label.SoloUnPunto',
        show:0,
    },
};
/*****************************************************************/

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CUSTSURV_SET_SHOWCARGANDO:{ return { ...state, CUSTSURV_ShowCargando: action.payload } }
        case CUSTSURV_SET_DIALOG:{ return { ...state, CUSTSURV_Dialog: action.payload } }

        case CUSTSURV_GET_ENCUESTADATA_SUCCESS: {
            return {
                ...state,
                CUSTSURV_EncuestaCabecera:{
                    id:action.payload[0].cabe_id,
                    nombre:action.payload[0].cabe_nombre,
                    descripcion:action.payload[0].cabe_descripcion,
                    ejecucion:action.payload[0].ejec_id_ejecucion,
                },
            }
        }
        case CUSTSURV_GET_ENCUESTADATA_FAIL: {
            return {
                ...state,
                CUSTSURV_EncuestaCabecera:{
                    id:0,
                    nombre:'',
                    descripcion:'',
                },
            }
        }

        case CUSTSURV_SET_DIALOGCONFIRMAR:{ return { ...state, CUSTSURV_DialogConfirmar: action.payload } }
        
        default:
            return state;
    }
}