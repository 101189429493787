
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import lodash from 'lodash';
import CloseIcon from '@material-ui/icons/Close';
import imgBox from '../../placeholder.jpg';

import {
    Avatar,
    Button,
    InputLabel,
    TextField,
    FormControlLabel,
    Checkbox,
    FormGroup,
    makeStyles,
    Tooltip,
    Typography,
    InputAdornment,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
} from "@material-ui/core";
import Select from '@material-ui/core/Select';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import MenuItem from '@material-ui/core/MenuItem';
import { globalChangeStatesCotizadorCliente, getPropuestaBaseCotizadorCliente } from '../../../../modules/cotizador/Actions';

// Tooltip MUI //
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#263238',
        color: '#fff',
        maxWidth: 400,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

const HtmlTooltip4 = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#263238',
        color: '#fff',
        maxWidth: 800,
        fontSize: theme.typography.pxToRem(12),
    },
}))(Tooltip);
// Tooltip MUI //

const useStyles = makeStyles((theme) => ({
    ButtonNav: {
        padding: '0',
        height: '13px',
        width: '2%',
        color: "#8F8F8F",
        fontSize: "14px",
        "&:hover": {
            backgroundColor: "transparent",
            color: "#44403f",
        }
    },
    icon: {
        fontSize: "16px",
        marginLeft: "-30px"
    },
    label: {
        color: "#8F8F8F"
    },
    inputAdornment: {
        "& .MuiTypography-body1": {
            fontSize: "12px"
        }
    },
    // iconLabel: {
    //     background: 'rgba(248, 201, 106, 0.471)',
    //     color: '#F8C96A',
    //     padding: '16px',
    //     marginRight: '16px'
    // },
    // iconLabelAtive: {
    //     background: 'rgba(111, 207, 151, 0.471)',
    //     color: '#6FCF97',
    //     padding: '16px',
    //     marginRight: '16px'
    // },
    // avatarXl: {
    //     width: "80px",
    //     height: "80px",
    //     marginRight: "16px",
    // },
    // avatarXxl: {
    //     width: "180px",
    //     height: "180px",
    //     margin: "0 auto",

    // },
    // contentAvatar: {
    //     width: "100%"
    // },
    // paddingBottom: {
    //     padding: "0 24px",
    //     '@media screen and (max-width: 767px)': {
    //         padding: "0"
    //     }
    // },
    closeButton: {
        position: 'absolute',
        right: "12px",
        top: "12px",
        color: theme.palette.grey[500],
    },
    // titleDialog: {
    //     lineHeight: "24px",
    //     color: "#263238",
    // },
    alertWarning: {
        color: "#F8C96A",
        margin: "0 auto",

        "& .MuiSvgIcon-root": {
            width: "64px",
            height: "64px",
        }
    },
}));

const Two = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [origen, setOrigen] = useState(2);
    const [destino, setDestino] = useState(1);
    const handleClose = () => {
        setOpen(false);
    };
    const {
        validations,
        userExists,
        zonasTarifarias
    } = useSelector(({ CotizadorCliente }) => CotizadorCliente);

    const [controls, setControls] = useState(props.payload);
    const [listDestino, setListDestino] = useState([]);
    const [listOrigen, setListOrigen] = useState([]);

    useEffect(() => {

        if (zonasTarifarias != null) {

            let tempOrigen = zonasTarifarias.filter((el) => el.id === 2 || el.id === 4);
            setListOrigen(tempOrigen);

            let tempDestino = zonasTarifarias.filter((el) => el.id === 1)
            setListDestino(tempDestino);
        }
    }, [zonasTarifarias]);

    useEffect(() => {
        if (userExists === true) {
            setOpen(true);
        }
    }, [userExists]);

    const onChange = (event) => {
        const obj = lodash.cloneDeep(controls);
        const newPayload = {
            ...obj,
            [event.target.id ? event.target.id : event.target.name]: event.target.value
        }
        setControls(newPayload);
    }

    const onChangeCheck = (event, isChecked, value) => {
        const obj = lodash.cloneDeep(controls);
        const newPayload = {
            ...obj,
            ['marca']: isChecked
        }
        setControls(newPayload);
    }

    const onBlur = (event) => {
        let obj = lodash.cloneDeep(props.payload);
        obj.peso = controls.peso;
        obj.volumen = controls.volumen;
        obj.descripcion = controls.descripcion;
        obj.marca = controls.marca;
        if (origen != '') {
            obj.fk_origen = origen;
        }
        if (destino != '') {
            obj.fk_destino = destino;
        }
        dispatch(globalChangeStatesCotizadorCliente('payload', obj))
    }

    const onChangeOrigenDestino = (event) => {
        let value = event.target.value;
        let name = event.target.name;
        if (name == 'origen') {
            setOrigen(value);
            if (value > 0 && destino > 0) {
                dispatch(getPropuestaBaseCotizadorCliente({
                    fk_zonaAlmacenaje: 1,
                    fk_zonaDestino: destino,
                    fk_zonaOrigen: value,
                }));
            }
        } else if (name == 'destino') {
            setDestino(value);
            if (origen > 0 && value > 0) {
                dispatch(getPropuestaBaseCotizadorCliente({
                    fk_zonaAlmacenaje: 1,
                    fk_zonaDestino: value,
                    fk_zonaOrigen: origen,
                }));
            }
        }
    }

    const [showTooltipDescripcion, setShowTooltipDescripcion] = useState(false);
    const [showTooltipOrigen, setShowTooltipOrigen] = useState(false);
    const [showTooltipDestino, setShowTooltipDestino] = useState(false);
    return (
        <>
            <div className="form-content input-material-ui">
                <div className="row">
                    <div className='col-12 mb-2'>
                        <h4 className="text font-weight-500 mb-2">Para valorizar el servicio, debes ingresar el <strong>volumen, peso y tipo de producto</strong> a importar.</h4>
                    </div>
                    {/* input */}
                    <div className="col-6 col-lg-4">
                        <InputLabel className="Label_Input_Format">Volumen M3</InputLabel>
                        <TextField
                            id="volumen"
                            name="volumen"
                            value={controls.volumen}
                            onBlur={onBlur}
                            placeholder="80"
                            type="number"
                            onChange={onChange}
                            error={validations.volumen.error}
                            helperText={validations.volumen.helperText}
                            className={classes.inputAdornment}
                            InputProps={{
                                startAdornment: <InputAdornment className={classes.number} position="start">M3</InputAdornment>
                            }}
                        />
                    </div>

                    {/* input  */}
                    <div className="col-6 col-lg-4">
                        <InputLabel className="Label_Input_Format">Peso Kg</InputLabel>
                        <TextField
                            id="peso"
                            name="peso"
                            value={controls.peso}
                            placeholder="80"
                            type="number"
                            onBlur={onBlur}
                            onChange={onChange}
                            error={validations.peso.error}
                            helperText={validations.peso.helperText}
                            className={classes.inputAdornment}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">Kg</InputAdornment>
                            }}
                        />
                    </div>

                    {/* input */}
                    <div className="col-12 col-lg-4 mt-3 mt-lg-0">
                        <InputLabel className="Label_Input_Format">
                            Descripción producto
                            {/* Tooltip */}
                            <HtmlTooltip
                                 open={showTooltipDescripcion}
                                 onOpen={() => setShowTooltipDescripcion(true)}
                                 onClose={() => setShowTooltipDescripcion(false)}
                                title={
                                    <React.Fragment>
                                        <Typography color="inherit">Los productos importados no pueden ser de marca, replica o similar sin autorización de la marca registrada en Chile.</Typography>
                                    </React.Fragment>
                                }
                            >
                                <Button 
                                    className={classes.ButtonNav}
                                    onClick={() => setShowTooltipDescripcion(!showTooltipDescripcion)}
                                >
                                    <HelpIcon className={classes.icon} />
                                </Button>
                            </HtmlTooltip>
                            {/* fin Tooltip */}
                        </InputLabel>
                        <TextField
                            id="descripcion"
                            name="descripcion"
                            value={controls.descripcion}
                            placeholder="Describe la carga"
                            size="small"
                            onBlur={onBlur}
                            onChange={onChange}
                            error={validations.descripcion.error}
                            helperText={validations.descripcion.helperText}
                        />
                    </div>
                    <div className="col-12 col-lg-4 mt-3">
                        <InputLabel className="Label_Input_Format">
                            Origen
                            {/* Tooltip */}
                            <HtmlTooltip4
                                open={showTooltipOrigen}
                                onOpen={() => setShowTooltipOrigen(true)}
                                onClose={() => setShowTooltipOrigen(false)}
                                title={
                                    <>
                                        <Typography color="inherit" className='mb-3'>
                                            YIWU: El mercado de Yiwu es conocido como la ciudad de productos básicos de China y es el mercado mayorista más grande del mundo, opera 26 categorías principales y 2,1 millones de productos básicos, incluidos juguetes, productos electrónicos, artículos para el hogar, joyas, artículos deportivos y otros productos diarios, además proporciona el mercado de insumos para la producción, muebles y ropa.
                                        </Typography>

                                        <Typography color="inherit">
                                            GUANGZHOU: Cantón (Guangzhou) en su nombre tradicional en español, es una ciudad del sur de China, capital de la Provincia de Guangdong. Por su excelente localización y larga historia, Guangzhou es el centro económico de las regiones manufactureras y comerciales más importantes de China. Además, es la ciudad en donde se celebra la Feria comercial más grande de mundo en 2 fechas de cada año.
                                        </Typography>
                                    </>
                                }
                            >
                                <Button 
                                    className={classes.ButtonNav}
                                    onClick={() => setShowTooltipOrigen(!showTooltipOrigen)}
                                >
                                    <HelpIcon className={classes.icon} />
                                </Button>
                            </HtmlTooltip4>
                            {/* fin Tooltip */}
                        </InputLabel>
                        <Select
                            id="origen"
                            name="origen"
                            value={zonasTarifarias && zonasTarifarias.length > 0 ? origen : 0}
                            defaultvalue={zonasTarifarias && zonasTarifarias.length > 0 ? origen : 0}
                            onChange={onChangeOrigenDestino}
                            onBlur={onBlur}
                        >
                            <MenuItem key={0} value={0}> NINGUNO </MenuItem>
                            {zonasTarifarias && zonasTarifarias.length > 0 &&
                                //zonasTarifarias.map(function(item,index){
                                listOrigen.map(function (item, index) {
                                    return (
                                        <MenuItem key={item.id} value={item.id}> {item.nombre} </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    <div className="col-12 col-lg-4 mt-3">
                        <InputLabel className="Label_Input_Format">
                            Destino
                            {/* Tooltip */}
                            <HtmlTooltip
                                open={showTooltipDestino}
                                onOpen={() => setShowTooltipDestino(true)}
                                onClose={() => setShowTooltipDestino(false)}
                                title={
                                    <>
                                        <Typography color="inherit">
                                            Para otros destinos dentro de Chile,  solicite valores adicional con el ejecutivo que se asignará al finalizar la cotización.
                                        </Typography>
                                    </>
                                }
                            >
                                <Button 
                                    className={classes.ButtonNav}
                                    onClick={() => setShowTooltipDestino(!showTooltipDestino)}
                                >
                                    <HelpIcon className={classes.icon} />
                                </Button>
                            </HtmlTooltip>
                            {/* fin Tooltip */}
                        </InputLabel>
                        <Select
                            id="destino"
                            name="destino"
                            onBlur={onBlur}
                            value={zonasTarifarias && zonasTarifarias.length > 0 ? destino : 0}
                            defaultvalue={zonasTarifarias && zonasTarifarias.length > 0 ? destino : 0}
                            onChange={onChangeOrigenDestino}
                        >
                            <MenuItem key="0" value={0}> NINGUNO </MenuItem>
                            {zonasTarifarias && zonasTarifarias.length > 0 &&
                                //zonasTarifarias.map(function(item,index){
                                listDestino.map(function (item, index) {
                                    return (
                                        <MenuItem key={item.id} value={item.id}> {item.nombre} </MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </div>
                    {/* check */}
                    <div className="col-12 mt-3">
                        <FormGroup>
                            <FormControlLabel className={classes.label} control={<Checkbox defaultChecked={controls.marca} onChange={onChangeCheck} onBlur={onBlur} />} label="Declara que los productos a importar no son de marca, replica o similar sin autorización de la marca registrada en Chile." />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className="mt-3 pb-0 justify-end" id="alert-dialog-title">
                    <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>

                {/* <DialogContent>
                    <DialogContentText>
                        <Avatar alt="Remy Sharp" src={imgBox} className={classes.avatarXxl} />
                    </DialogContentText>
                </DialogContent> */}

                <DialogContent className="mt-3">
                    <DialogContentText className="text-center mb-0">
                        <div className={`justify-center ${classes.alertWarning}`}>
                            <ReportProblemIcon />
                        </div>
                    </DialogContentText>
                </DialogContent>

                <DialogContent className="text-center mb-3">
                    <DialogTitle>¡Atención, hemos detectado que eres un <strong>usuario existente</strong>!</DialogTitle>
                    <DialogContentText>
                        Por lo tanto, procederemos a tomar tu cotización sin necesidad de que ingreses tu información tributaria. En caso de necesitar actualizar tu información, deberás iniciar sesión.
                    </DialogContentText>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default Two;
