import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from "framer-motion";

import { Link } from 'react-router-dom';
import {
    InputLabel,
    Button,
    TextField,
    makeStyles,
    IconButton,
    InputAdornment,
    CircularProgress,
    FormControl,
    FormHelperText,
    Hidden,
    Input
  } from "@material-ui/core";

import {postUploadDocumentsExpress} from './Actions';

  const useStyles = makeStyles((theme) => ({
    link: {
      fontSize: "14px",
      color: "#8F8F8F",
      fontWeight: "500",
      "&:hover": {
        color: "#FC565C"
      }
    },
    titleLogin: {
      paddingLeft: "12px",
      margin: "16px 0"
    },
    iconButtom: {
      "&:hover": {
        background: "transparent"
      }
    }
  }));
const UploadDocsExpress=(props)=>{
    const classes = useStyles();
    const dispatch = useDispatch();
    const { loader, success} = useSelector(({ UploadDocumentsExpress }) => UploadDocumentsExpress);
    const [keyArea1,setKeyArea1] = React.useState(1);
    const [keyArea2,setKeyArea2] = React.useState(1);
    const [file1,setFile1] = useState(null);
    const [file2,setFile2] = useState(null);
    const [error,setError] = useState(null);
    const pl1 = React.useRef();
    const pl2 = React.useRef();
    const [hash, setHash] = useState(props.match.params.hash);

    useEffect(() => {
      if(success!=null){
        setKeyArea1(keyArea1+1);
        setKeyArea2(keyArea1+2);
        setFile1(null);
        setFile2(null);
      }
   }, [success]);

    const onChangeFiles =(e)=>{
        if(e.target.name=='file1'){
          setFile1(e.target.files[0]);
        }else if(e.target.name=='file2'){
            setFile2(e.target.files[0]);
        }
    }

    const addDocuments=()=>{
        if(hash.length>0){
          if(file1!=null || file2!=null){
            setError(null);
            dispatch(postUploadDocumentsExpress({file1:file1,file2:file2,hash:hash}));
          }else{
            setError("Debe adjuntar a lo menos un documento");
          }
        }else{
          setError("El enlace no es válido");
        }
        
    }

    return (
        <div className="app-login-container">
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: .5, duration: 1.3 }}
            className="app-login"
        >
            <div className="app-login-content">
            <div className="border my-4"></div>

        {/* Documentos */}
        <div className="row">
          <div className="col-12 mb-3">
            <p className={`text-small font-weight-500 ${classes.title}`}>Cargar Documentos</p>
          </div>

          <div className="col-12 mb-3 input-file-ui">
            <InputLabel className="Label_Input_Format">Packing List</InputLabel>
  
            <Input
              className="my-0 input-file"
              type="file"
              onChange={onChangeFiles}
              ref={pl1}
              key={'file1_'+keyArea1}
              name="file1"
            />
          </div>

          <div className="col-12 input-file-ui">
            <InputLabel className="Label_Input_Format">Invoice</InputLabel>
            <Input
              className="my-0 input-file"
              type="file"
              onChange={onChangeFiles}
              ref={pl2}
              key={'file2_'+keyArea2}
              name="file2"
            />
          </div>
          <div className='col-12'>
            <Button
            className="button button-gray button-small mt-4"
            onClick={addDocuments}
            >
            {"Agregar documentos"}
            </Button>
          </div>
          {error!=null &&
          <div className='col-12 mt-2'>
            <div class="alert alert-danger" role="alert">
            <i className="zmdi zmdi-alert-triangle"/> {error}
            </div>
          </div>
          }
          {success==true &&
          <div className='col-12 mt-2'>
            <div class="alert alert-success" role="alert">
            <i className="zmdi zmdi-shield-check"/> Documentos subidos correctamente, debes esperar a que tu ejecutivo comercial los apruebe para confirmar el despacho a Chile
            </div>
          </div>
          }
          {success==false &&
          <div className='col-12 mt-2'>
            <div class="alert alert-danger" role="alert">
            <i className="zmdi zmdi-close-circle"/> Ha ocurrido un problema al subir la documentación, por favor intentarlo más tarde.
            </div>
          </div>
          }
        </div>
            </div>
        </motion.div>
        <div className="app-logo-content justify-center">
        <Link className="logo-lg" to="/" title="Jambo">
          <img
            src={require("../../assets/images/wscargologo.png")}
            alt="jambo"
            title="jambo"
          />
        </Link>
        </div>
        {loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
        </div>
    );
}

export default UploadDocsExpress;