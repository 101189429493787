import {all, call, fork, put, takeEvery,takeLatest,spawn} from "redux-saga/effects";
import {
    postRequest,
    getRequest,
    getErrorRequest
} from '../util/apiFetch';

import {
    GET_TRACK,
    GET_TRACK_HISTORY,
    GET_TRACK_DOCUMENTS,
    GET_TRACK_FECHAS_ESTIMADAS,

    TCK_MODALPAGARSERVICIO_GETINFO,
    TCK_REALIZARPAGOSERVICIO,
    GET_TRACK_LIST_MODIFY_CNSLD,
    GET_TIME_LINE
} from "../constants/Track";

import {
    getTrackSuccess,
    getTrackHistorySuccess,
    getTrackDocumentsSuccess,
    getTrackFechasEstimadasSuccess,
    Tck_SetMostrarLoadingPagos,
    Tck_ModalPagarServicio_GetInfo_Success,
    getTrackListModifyCnsldSuccess,
    getTimeLineSuccess
} from '../actions/Track';


function* getTrackSaga({payload}) {
    const requestURL = `servicios/track/${payload.hash}`;
    const response= yield call(getRequest,requestURL,payload);
    const rta = yield call(getErrorRequest,response);

    if(rta.error){
        //yield put(showMessageUserAux({type:'error',text:message}));
        console.log(rta);
        yield put(getTrackSuccess(rta));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getTrackSuccess(obj));
        //yield getSagaExistsUserCotizadorClienteExecute(payload);
    }
}

function* getTrackHistorySaga({payload}) {
    const requestURL = `servicios/track-history/${payload.hash}`;
    const response= yield call(getRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageUserAux({type:'error',text:message}));
        console.log(error);
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getTrackHistorySuccess(obj));
        //yield getSagaExistsUserCotizadorClienteExecute(payload);
    }
}

function* getTrackDocumentsSaga({payload}) {
    const requestURL = `servicios/track-get-info-documents/${payload.hash}`;
    const response= yield call(getRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageDetalleServicio({type:'error',text:message}));
        console.log(error);
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getTrackDocumentsSuccess(obj));
    }
}

function* getTrackFechasEstimadasSaga({payload}) {
    const requestURL = `fechas-estimadas`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageDetalleServicio({type:'error',text:message}));
        console.log(error);
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getTrackFechasEstimadasSuccess(obj));
    }
}

function* Tck_ModalPagarServicio_GetInfoExecute(payload){
    const requestURL = `getinfopagoservicio/`+payload.payload;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error)
    {
        alert(message);
        //yield put(showMessageDetalleServicio({type:'error',text:message}));
    }
    else
    {
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(Tck_ModalPagarServicio_GetInfo_Success(obj));
    }
}

function* Tck_RealizarPagoServicioExecute({payload}){

    const requestURL = `realizar_pago_servicio`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error)
    {
        yield put(Tck_SetMostrarLoadingPagos(false));
        //yield put(showMessageDetalleServicio({type:'error',text:message}));
        alert(message);
    }
    else
    {
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);

        if( payload.MedioPago==='WEBPAY')
        {
            var form = document.createElement("form");
            var element1 = document.createElement("input"); 
            form.method = "POST";
            form.action = obj.message.url;
            element1.value=obj.message.token;
            element1.type='hidden';
            element1.name="token_ws";
            form.appendChild(element1);  
            document.body.appendChild(form);
            form.submit();
        }
        else if( payload.MedioPago==='KHIPU')
        {
            window.location.href = obj.message;
        }
        yield put(Tck_SetMostrarLoadingPagos(false));
    }
}
function* getTrackListModifyCnsldSaga({payload}) {
    const requestURL = `servicios/modify-list-cnsld/${payload.hash}`;
    const response= yield call(getRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageUserAux({type:'error',text:message}));
        console.log(error);
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getTrackListModifyCnsldSuccess(obj));
        //yield getSagaExistsUserCotizadorClienteExecute(payload);
    }
}

function* getTimeLineSaga({payload}) {
    const requestURL = `servicios/get-timeline`;
    const response= yield call(getRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageDetalleServicio({type:'error',text:message}));
        console.log(error);
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getTimeLineSuccess(obj));
    }
}


export function* getTrackExecute() {
    yield takeLatest(GET_TRACK, getTrackSaga);
}

export function* getTrackHistoryExecute() {
    yield takeLatest(GET_TRACK_HISTORY, getTrackHistorySaga);
}

export function* getTrackDocumentsExecute() {
    yield takeLatest(GET_TRACK_DOCUMENTS, getTrackDocumentsSaga);
}

export function* getTrackFechasEstimadasExecute() {
    yield takeLatest(GET_TRACK_FECHAS_ESTIMADAS, getTrackFechasEstimadasSaga);
}

export function* Tck_ModalPagarServicio_GetInfo(){
    yield takeLatest(TCK_MODALPAGARSERVICIO_GETINFO, Tck_ModalPagarServicio_GetInfoExecute);
 }
 
 export function* Tck_RealizarPagoServicio(){
    yield takeLatest(TCK_REALIZARPAGOSERVICIO, Tck_RealizarPagoServicioExecute);
 }
export function* getTrackListModifyCnsldExecute() {
    yield takeLatest(GET_TRACK_LIST_MODIFY_CNSLD, getTrackListModifyCnsldSaga);
}

export function* getTimeLineExecute() {
    yield takeLatest(GET_TIME_LINE, getTimeLineSaga);   
}




export default function* rootSaga() {
    yield spawn(Tck_RealizarPagoServicio);
    yield spawn(Tck_ModalPagarServicio_GetInfo);
    yield spawn(getTrackExecute);
    yield spawn(getTrackHistoryExecute);
    yield spawn(getTrackDocumentsExecute);
    yield spawn(getTrackFechasEstimadasExecute);
    yield spawn(getTrackListModifyCnsldExecute);
    yield spawn(getTimeLineExecute);
}