import {all, call, fork, put, takeEvery,takeLatest,spawn} from "redux-saga/effects";
import { putRequest, postRequest, getRequest, getErrorRequest, deleteRequest,uploadFilesRequest } from '../../util/apiFetch';
import lodash from 'lodash';

import{
    PAGONLINE_GET_DETALLE_PAGO,
} from './Constants';

import{
    PAGONLINE_Get_Detalle_Pago_Success,
} from './Actions';


function* PAGONLINE_Get_Detalle_Pago_Execute(payload){
    const requestURL = `servicios/pago_servicio_get_detalle/${payload.payload}`;
    const response= yield call(getRequest,requestURL,payload.payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error===true)
    {
        yield put(PAGONLINE_Get_Detalle_Pago_Success(message));
    }
    else
    {
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(PAGONLINE_Get_Detalle_Pago_Success(obj[0]));
    }
 }

export function* PAGONLINE_Get_Detalle_Pago(){
    yield takeLatest(PAGONLINE_GET_DETALLE_PAGO, PAGONLINE_Get_Detalle_Pago_Execute);
}

export default function* rootSaga() {
    yield spawn(PAGONLINE_Get_Detalle_Pago);
}