/******************************************************/
export const CUSTSURV_SET_SHOWCARGANDO= 'CUSTSURV_SET_SHOWCARGANDO';
export const CUSTSURV_SET_DIALOG= 'CUSTSURV_SET_DIALOG';
/******************************************************/
export const CUSTSURV_SET_DIALOGCONFIRMAR= 'CUSTSURV_SET_DIALOGCONFIRMAR';

export const CUSTSURV_GET_ENCUESTADATA = 'CUSTSURV_GET_ENCUESTADATA';
export const CUSTSURV_GET_ENCUESTADATA_SUCCESS = 'CUSTSURV_GET_ENCUESTADATA_SUCCESS';
export const CUSTSURV_GET_ENCUESTADATA_FAIL = 'CUSTSURV_GET_ENCUESTADATA_FAIL';

export const CUSTSURV_POST_ENCUESTA = 'CUSTSURV_POST_ENCUESTA';
export const CUSTSURV_POST_ENCUESTA_SUCCESS = 'CUSTSURV_POST_ENCUESTA_SUCCESS';