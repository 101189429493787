import {
    POST_UPLOAD_DOCUMENTS_EXPRESS,
    POST_UPLOAD_DOCUMENTS_EXPRESS_SUCCESS,
    GET_INFO_TRACK_CONSOLIDADO_RAPIDO_EXPRESS_SUCCESS,
    POST_CONSOLIDADO_RAPIDO_EXPRESS_SUCCESS,
    UPDATE_STATE_NO_CONSOLIDADO_RAPIDO_EXPRESS_SUCCESS
} from './Constants';
import lodash from 'lodash';

const INIT_STATE = {
    loader:false,
    success:null,
    item:null,
    success2:null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case POST_UPLOAD_DOCUMENTS_EXPRESS:{
            return{
                ...state,
                loader:true
            }
        }
        case POST_UPLOAD_DOCUMENTS_EXPRESS_SUCCESS:{
            return{
                ...state,
                loader:false,
                success:action.payload
            }
        }
        case GET_INFO_TRACK_CONSOLIDADO_RAPIDO_EXPRESS_SUCCESS:{
            let item=lodash.cloneDeep(action.payload);
            if(item && item!=null){
                let grouped=[];
                    if(item.tracking_detalle && item.tracking_detalle.length>0){
                        item.tracking_detalle.map(function(el){
                            if(grouped.length===0){
                                grouped.push({...el,cantidad_bultos:1});
                            }else{
                                const find=grouped.findIndex(x=>(x.codigo_interno==el.codigo_interno && x.producto==el.producto));
                                if(find>=0){
                                    let newItem=lodash.cloneDeep(grouped[find]);
                                    newItem.peso= newItem.peso+el.peso;
                                    newItem.volumen= newItem.volumen+el.volumen;
                                    newItem.peso.toFixed(3);
                                    newItem.volumen.toFixed(3);
                                    newItem.cantidad_bultos= newItem.cantidad_bultos+1;
                                    grouped[find]=newItem;
                                }else{
                                    grouped.push({...el,cantidad_bultos:1});
                                }
                            }
                        })
                    }
                item.grouped=grouped;
            }
            return{
                ...state,
                loader:false,
                item:item
            }
        }
        case POST_CONSOLIDADO_RAPIDO_EXPRESS_SUCCESS:{
            return{
                ...state,
                loader:false,
                success2:action.payload
            }
        }
        case UPDATE_STATE_NO_CONSOLIDADO_RAPIDO_EXPRESS_SUCCESS:{
            return{
                ...state,
                loader:false,
                success2:action.payload
            }
        }
        default:
            return state;
    }
}