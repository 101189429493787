
import {
    SHOW_MESSAGE_DASHBOARD,
    HIDE_MESSAGE_DASHBOARD,
    GET_COTIZACIONES_DASHBOARD_SUCCESS,
    SET_LOADER_DASHBOARD,
    GET_ZONASTARIFARIAS_DASHBOARD_SUCCESS,
    GET_PROPUESTABASE_DASHBOARD_SUCCESS,
    POST_COTIZACION_DASHBOARD,
    GET_EMPRESAS_DASHBOARD_SUCCESS,
    GET_REGIONES_DASHBOARD_SUCCESS,
    GET_COMUNAS_DASHBOARD_SUCCESS,
    GET_SERVICIOS_DASHBOARD_SUCCESS,
    SET_PAYLOAD_SERVICIO_DASHBOARD,
    POST_TRACKINGS_INCOMPLETE_DASHBOARD_SUCCESS,
    GET_COMERCIALES_DASHBOARD_SUCCESS,
    GET_HISTORY_SERVICES_DASHBOARD_SUCCESS,
    GET_STATES_COMPLETE_SERVICES_DASHBOARD_SUCCESS,
    SET_RESPONSE_POST_DASHBOARD,
    POST_SERVICIO_DASHBOARD_SUCCESS,
    PUT_REFERENCIA_DASHBOARD_SUCCESS,
    GET_IS_FIRST_ACCESS_DASHBOARD_SUCCESS,
    CLEAR_IS_FIRST_ACCESS_DASHBOARD,
    GET_SERVICIOS_DASHBOARD,
    GET_CLIENTS_SUPERADMIN_DASHBOARD_SUCCESS,
    GET_SERVICES_SUPERADMIN_DASHBOARD_SUCCESS,
    GET_SERVICES_SUPERADMIN_DASHBOARD,
    GET_FECHAS_ESTIMADAS_SUCCESS,
    GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE,
    GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE_SUCCESS,
    SET_CLIENTE_ACTIVO,
    CLEAR_CLIENTE_ACTIVO,
    GET_EMPRESA_DASHBOARD_BY_FKCLIENTE_SUCCESS,
    CLEAR_ALL_EXPDIG_DASHBOAR_SUCCESS,
    GET_PROVEEDORES_CNSLD_RAPIDA_DASHBOARD_SUCCESS,
    SET_MODAL_CNSLD_RAPIDO_DASHBOARD,
    GETENCUESTASPENDIENTES_SUCCESS,
    HOME_SETMOSTRARMODALPAGARSERVICIO,
    HOME_MODALPAGARSERVICIO_GETINFO_SUCCESS,
    HOME_SETMOSTRARLOADINGPAGOS,
    GET_TIMELINE_DASHBOARD_SUCCESS
} from './Constants';
import {
    payloadServicio
} from '../../models';
import lodash from 'lodash';
import moment from 'moment';
const INIT_STATE = {
    MostrarModalPagarServicio:false,
    MostrarLoadingPagos:false,
    ModalPagarServicioInfo:[],
    EnviarPagoServicio:{
        Nc:0,
        Carpeta:'',
        MedioPago:'',
        MedioPagoComision:0,
        ValorInicial:0,
        ValorPago:0,
        ValorComision:0,
        ValorComisionFijo:0,
        ValorComisionNeto:0,
        ValorComisionIva:0,
        ValorTotal:0,
        Usuario:'',
        Email:'',
        Rut:'',
        Estado:'PENDIENTE',
    },
    loader:false,
    responsePost:false,
    showMessage:false,
    activeList:false,
    alertMessage:{type:'',text:''}, 
    cotizaciones:[],
    servicios:[],
    zonasTarifarias:[],
    propuestaBase:null,
    empresas:[],
    regiones:[],
    comunas:[],
    servicioPayload:payloadServicio,
    trackingsIncomplete:[],
    comerciales:[],
    historyServices:[],
    stateServices:[],
    isFirstAccess:null,
    clients:[],
    fechasEstimadas:[],
    clienteActivo: null,
    modalCnsldRapido:{
        open:false,
        data:null
    },
    trackingsCnsldR:null,
    EncuestasPendientes:[],
    timeline:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case HOME_MODALPAGARSERVICIO_GETINFO_SUCCESS:{
            return {
                ...state,
                ModalPagarServicioInfo: action.payload
            }
        }

        case HOME_SETMOSTRARLOADINGPAGOS:{
            return {
                ...state,
                MostrarLoadingPagos: action.payload
            }
        }
        
        case HOME_SETMOSTRARMODALPAGARSERVICIO:{
            return {
                ...state,
                MostrarModalPagarServicio: action.payload
            }
        }
        case CLEAR_ALL_EXPDIG_DASHBOAR_SUCCESS:{
            return{
                ...INIT_STATE
            }
        }
       case SHOW_MESSAGE_DASHBOARD:{
           return{
               ...state,
               alertMessage:action.payload,
               showMessage:true
           }
       }

       case GET_SERVICIOS_DASHBOARD:{
        if(action.payload.isFirstAccess && action.payload.isFirstAccess.success && action.payload.isFirstAccess.success===true){
            return{
                ...state,
            }
        }else{
            return{
                ...state,
                loader:true
            }
        }
       }

       case HIDE_MESSAGE_DASHBOARD:{
            return{
                ...state,
                alertMessage:{type:'',text:''},
                showMessage:false
            }
        }
        
        case SET_LOADER_DASHBOARD:{
            return{
                ...state,
                loader:action.payload
            }
        }
        case GET_COTIZACIONES_DASHBOARD_SUCCESS:{
            return{
                ...state,
                cotizaciones:action.payload
            }
        }
        case GET_ZONASTARIFARIAS_DASHBOARD_SUCCESS:{
            return{
                ...state,
                zonasTarifarias:action.payload
            }
        }
        case GET_PROPUESTABASE_DASHBOARD_SUCCESS:{
            let propuesta=null;
            if(action.payload && action.payload.length>0){
                propuesta=action.payload[0];
            }
            return{
                ...state,
                propuestaBase:propuesta
            }
        }
        case POST_COTIZACION_DASHBOARD:{
            return{
                ...state,
                loader:false,
                responsePost:true
            }
        }
        case GET_EMPRESAS_DASHBOARD_SUCCESS:{
            return{
                ...state,
                empresas:action.payload
            }
        }
        case GET_REGIONES_DASHBOARD_SUCCESS:{
            return{
                ...state,
                regiones:action.payload
            }
        }
        case GET_COMUNAS_DASHBOARD_SUCCESS:{
            return{
                ...state,
                comunas:action.payload
            }
        }
        case GET_SERVICIOS_DASHBOARD_SUCCESS:{
            let cloneS=lodash.cloneDeep(action.payload);
            if(cloneS && cloneS.length>0){
                cloneS.map(function(item){
                    item.visible_marker=true;
                })
            }
            return{
                ...state,
                servicios:cloneS
            }
        }
        case SET_PAYLOAD_SERVICIO_DASHBOARD:{
            return{
                ...state,
                servicioPayload:action.payload
            }
        }
        case POST_TRACKINGS_INCOMPLETE_DASHBOARD_SUCCESS:{
            return{
                ...state,
                trackingsIncomplete:action.payload
            }
        }
        case GET_COMERCIALES_DASHBOARD_SUCCESS:{
            return{
                ...state,
                comerciales:[]
            }
        }
        case GET_HISTORY_SERVICES_DASHBOARD_SUCCESS:{
            return{
                ...state,
                historyServices:action.payload
            }
        }
        case GET_STATES_COMPLETE_SERVICES_DASHBOARD_SUCCESS:{
            let aux=lodash.cloneDeep(action.payload);
            let final=[];
            if(aux && aux.length>0)
            {
                for(let i=0;i<aux.length;i++)
                {
                    let primera_recepcion=aux[i].recepciones.sort(function(a, b)
                    {
                                if(a.primera_recepcion==null && b.primera_recepcion==null)
                                {
                                    return null
                                }
                                else if(a.primera_recepcion==null && b.primera_recepcion!=null)
                                {
                                    return b.primera_recepcion
                                }
                                else if(a.primera_recepcion!=null && b.primera_recepcion==null)
                                {
                                    return a.primera_recepcion
                                }
                                else
                                {
                                    return moment.utc(b.primera_recepcion).diff(moment.utc(a.primera_recepcion))
                                }
                            });

                    let ultima_recepcion=aux[i].recepciones.sort(function(a, b) 
                    {
                        if(a.ultima_recepcion==null && b.ultima_recepcion==null)
                        {
                            return null
                        }
                        else if(a.ultima_recepcion==null && b.ultima_recepcion!=null)
                        {
                            return b.ultima_recepcion
                        }
                        else if(a.ultima_recepcion!=null && b.ultima_recepcion==null)
                        {
                            return a.ultima_recepcion
                        }
                        else
                        {
                            return moment.utc(b.ultima_recepcion).diff(moment.utc(a.ultima_recepcion))
                        }
                    });

                    let primera=null;let ultima=null;
                    if(primera_recepcion && primera_recepcion.length>0)
                    {
                        primera=primera_recepcion[primera_recepcion.length-1].primera_recepcion;
                    }

                    if(ultima_recepcion && ultima_recepcion.length>0)
                    {
                        ultima=ultima_recepcion[0].ultima_recepcion;
                    }

                    let bultos_completos=0;let bultos_problemas=0;let cedula_1=false;let cedula_2=false;let podersimple_1=false;
                    let cedula_1_estado=0;let cedula_2_estado=0;let podersimple_1_estado=0;

                    for(let j=0;j<aux[i].recepciones.length;j++)
                    {
                        bultos_completos+=parseInt(aux[i].recepciones[j].bultos_completos);
                        bultos_problemas+=parseInt(aux[i].recepciones[j].bultos_problemas);
                        cedula_1=aux[i].recepciones[j].cedula_1;
                        cedula_1_estado=aux[i].recepciones[j].cedula_1_estado;
                        cedula_2=aux[i].recepciones[j].cedula_2;
                        cedula_2_estado=aux[i].recepciones[j].cedula_2_estado;
                        podersimple_1=aux[i].recepciones[j].podersimple_1;
                        podersimple_1_estado=aux[i].recepciones[j].podersimple_1_estado;
                    }

                    final.push({fk_servicio:aux[i].fk_servicio,costos:aux[i].costos,viaje:aux[i].viaje,recepciones:{bultos_completos:bultos_completos,primera_recepcion:primera,ultima_recepcion:ultima,bultos_problemas:bultos_problemas,cedula_1,cedula_1_estado,cedula_2,cedula_2_estado,podersimple_1,podersimple_1_estado}});
                }
            }
            return{
                ...state,
                stateServices:final,
                loader:false
            }
        }
        case SET_RESPONSE_POST_DASHBOARD:{
            return{
                ...state,
                responsePost:action.payload
            }
        }

        case PUT_REFERENCIA_DASHBOARD_SUCCESS:{
            return{
                ...state,
                showMessage:true
            }
        }

        case GET_IS_FIRST_ACCESS_DASHBOARD_SUCCESS:{
            return{
                ...state,
                isFirstAccess:action.payload
            }
        }

        case CLEAR_IS_FIRST_ACCESS_DASHBOARD:{
            return{
                ...state,
                isFirstAccess:null
            }
        }

        case GET_CLIENTS_SUPERADMIN_DASHBOARD_SUCCESS:{
            return{
                ...state,
                clients:action.payload
            }
        }

        case GET_SERVICES_SUPERADMIN_DASHBOARD:{
            return{
                ...state,
                loader:true
            }
        }
        case GET_SERVICES_SUPERADMIN_DASHBOARD_SUCCESS:{
            return{
                ...state,
                loader:false,
                servicios:action.payload
            }
        }
        case GET_FECHAS_ESTIMADAS_SUCCESS:{
            return{
                ...state,
                fechasEstimadas:action.payload
            }
        }
        case GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE:{
            return{
                ...state,
                loader:true,
            }
        }
        case GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE_SUCCESS:{
            let cloneS=lodash.cloneDeep(action.payload);
            if(cloneS && cloneS.length>0){
                cloneS.map(function(item){
                    item.visible_marker=true;
                })
            }
            return{
                ...state,
                servicios:cloneS,
                //loader:false,
            }
        }

        case SET_CLIENTE_ACTIVO:{
            localStorage.setItem('clienteActivo', JSON.stringify(action.payload.id));
            return{
                ...state,
                clienteActivo:action.payload.id,
            }
        }

        case CLEAR_CLIENTE_ACTIVO:{
            return{
                ...state,
                servicios:[],
                clienteActivo:action.payload,
            }
        }

        case GET_EMPRESA_DASHBOARD_BY_FKCLIENTE_SUCCESS:{
            return{
                ...state,
                empresas:action.payload
            }
        }
        /*case POST_SERVICIO_DASHBOARD_SUCCESS:{
            return{
                ...state,
                responsePost:true
            }
        }*/
        case GET_PROVEEDORES_CNSLD_RAPIDA_DASHBOARD_SUCCESS:{
            return{
                ...state,
                trackingsCnsldR:action.payload,
                modalCnsldRapido:{
                    open:true,
                    data:action.payload
                }
            }
        }

        case GETENCUESTASPENDIENTES_SUCCESS:{
            return{
                ...state,
                EncuestasPendientes:action.payload
            }
        }

        case SET_MODAL_CNSLD_RAPIDO_DASHBOARD:{
            return{
                ...state,
                modalCnsldRapido:action.payload
            }
        }
        case GET_TIMELINE_DASHBOARD_SUCCESS:{
            return{
                ...state,
                timeline:action.payload
            }
        }
        default:
            return state;
    }
}