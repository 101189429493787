
/*import{

    SHOW_BUTTON_STEPPER,
    HIDDEN_BUTTON_STEPPER,
    LIST_USER_OCULTAR,
    LIST_USER_MOSTRAR
} from './Constants';

export const showBtnStepper = (payload) => {
    return {
        type: SHOW_BUTTON_STEPPER,
        payload:payload
    };
};

export const hiddenBtnStepper  = (payload) => {
    return {
        type: HIDDEN_BUTTON_STEPPER,
        payload:payload
    };
};

export const mostrarFormUser = (payload) => {
    return {
        type: LIST_USER_MOSTRAR,
        payload:payload
    };
};

export const ocultarFormUser  = (payload) => {
    return {
        type: LIST_USER_OCULTAR,
        payload:payload
    };
};*/

import {
    VALIDATE_CURRENT_PASSWORD,
    VALIDATE_CURRENT_PASSWORD_SUCCESS,
    PUT_PASSWORD_USER,
    PUT_PASSWORD_USER_SUCCESS,
    CLEAR_PASSWORD_USER,
} from './Constants';

export const validateCurrentPassword  = (payload) => {
    return {
        type: VALIDATE_CURRENT_PASSWORD,
        payload:payload
    };
};

export const validateCurrentPasswordSuccess  = (payload) => {
    return {
        type: VALIDATE_CURRENT_PASSWORD_SUCCESS,
        payload:payload
    };
};

export const putPasswordUser  = (payload) => {
    return {
        type: PUT_PASSWORD_USER,
        payload: payload
    };
};

export const putPasswordUserSuccess  = (payload) => {
    return {
        type: PUT_PASSWORD_USER_SUCCESS,
        payload: payload
    };
};

export const clearPasswordUser  = (payload) => {
    return {
        type: CLEAR_PASSWORD_USER,
        payload: payload
    };
};




























