import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import LaunchIcon from '@material-ui/icons/Launch';
import VisibilityIcon from '@material-ui/icons/Visibility';

import {
    Button,
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    IconButton
} from "@material-ui/core";

// etapas de seguimiento 
const estapasSeguimiento = [
    { id: '1', title: 'Carga Recibida', estado: '2' },
    { id: '2', title: 'Salida Puerto', estado: '2' },
    { id: '3', title: 'Retiro Puerto', estado: '' },
    { id: '4', title: 'Disponible Entrega', estado: '' },
    { id: '5', title: 'Entregado', estado: '' }
]

const useStyles = makeStyles((theme) => ({
    root: {
        // paddingLeft: "3%",
        marginBottom: "24px",
        // background:"yellow",
        // textAlign:'center'
    },
    listItem: {
        display: "flex",
        flexDirection: "column"
    },

}));

const initailNotificaciones = [
    { titulo_notificacion: 'Pago del servicio atrasado', descripcion: '', link: '' },
    { titulo_notificacion: 'Documentación faltante del cliente', descripcion: 'Cédula de identidad', link: '' },
    { titulo_notificacion: 'Documentación del cliente rechazada', descripcion: 'Verificar - Cédula de identidad', link: '' },
    { titulo_notificacion: 'Documentación del proveedor', descripcion: 'El "nombre" no ha sido aprobada, favor verificar - Packing List', link: '' },
    { titulo_notificacion: 'Factura del servicio disponible para descarga', descripcion: '', link: '' },
]

const estadoNotificacion = {
    notificacion: '1'
}

const CardOne = ({ item, handleOpenAccion }) => {
    const classes = useStyles();
    const etapaActual = 'Cargas Reibidas';


    // class para cambiar el color de la linea de tiempo, segun el estado del servicio
    const classEstado = (estado) => {
        if (estado == "0") {
            return "active";
        } else if (estado == "1") {
            return "active1"
        } else if (estado == "2") {
            return "active2"
        }
    }

    return (
        <>
            <div className={`card-data justify-center  ${classes.root}`}>
                <div className="align-item-cente justify-center">
                    {/* <div className='mr-5'>
                        <p className="text-xs font-weight-400">Etapa actual</p>
                        {item.tags == "cotización" ?
                            <h4 className="text-small font-weight-500 text-capitalize">S/I</h4> :
                            <h4 className="text-small font-weight-500 text-capitalize">{`${etapaActual}`}</h4>
                        }
                    </div> */}

                    {/* <div>
                        <h4 className="text-xs font-weight-400">Recepción Bultos</h4>
                        <p className="text-small font-weight-500">
                            Primera {item.primeraRecepcion == '' ? "S/I" : `${item.primeraRecepcion}`} - Útima {item.ultimaRecepcion == '' ? "S/I" : `${item.ultimaRecepcion}`}
                        </p>
                    </div> */}

                    {item.tags == "acción requerida" ?
                        <Button
                            className='button  button-small'
                            onClick={handleOpenAccion}>
                            <VisibilityIcon className="icon-color-warnin mr-2" />
                            Revisar Acción Requerida
                        </Button>
                        : ""
                    }

                    {item.tags == "alerta" ?
                        <Button
                            className='button  button-small'
                            onClick={handleOpenAccion}>
                            <VisibilityIcon className="icon-color-warnin mr-2" />
                            Revisar Acción Urgente
                        </Button>
                        : ""
                    }
                </div>
            </div>

            <ul className="progressbar">
                {
                    estapasSeguimiento.map(item => (
                        <li key={item.id}
                            className={`text-small font-weight-500 ${classEstado(item.estado)}`}
                        >
                            {item.title}
                        </li>
                    ))
                }
            </ul>
        </>
    )
}

export default CardOne