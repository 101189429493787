import React from 'react'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import perfil from '../../../assets/images/wscargologo_old.png'


import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle,
  Avatar
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconSuccess: {
      width: "80px",
      height: "80px",
      border: "solid 3px #6FCF97",
      color: "#6FCF97",
      margin: "0 auto",
      borderRadius: "100%",
      fontsize: "400px",

      "& .MuiSvgIcon-root": {
        width: "48px",
        height: "48px",
      }
  },
  iconDanger: {
    width: "80px",
    height: "80px",
    border: "solid 3px #EA5259",
    color: "#EA5259",
    margin: "0 auto",
    borderRadius: "100%",
    fontsize: "400px",

    "& .MuiSvgIcon-root": {
      width: "48px",
      height: "48px",
    },
    dialog:{
      "& .MuiDialogActions-root": {
        justifyContent: "center !import"
      }
    }
},
}));

const Message = ({response, tituloMensaje, mensaje, icon, onClose, startTour, tour}) => {    
  const classes = useStyles();

  return (
    // <Alert color={props.bgColor}>{props.msg}</Alert>
    <Dialog
      open={response}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onClose}
      className={classes.dialog}
    >
      <DialogContent className="mt-3">
        <DialogContentText className="text-center">

          { icon == "iconSuccess" ?
            <img src={perfil} className="app-avatar-img" />
            :""

            // <div className={`justify-center ${classes.iconSuccess}`}>
            //   <CheckIcon />
            // </div>: ""
          }

          { icon == "iconDanger" ?
            <div className={`justify-center ${classes.iconDanger}`}>
              <CloseIcon />
            </div>: ""
          }
          
        </DialogContentText>
      </DialogContent>

      <DialogContent className="text-center mb-3">
        <DialogTitle className='pt-0'>{tituloMensaje}</DialogTitle>
        <DialogContentText className="px-0 px-md-5">{mensaje}</DialogContentText>
        {/* { tour &&  <DialogContentText className="px-0 px-md-5">¿Deseas realizar el tour ?</DialogContentText>} */}

      </DialogContent>
      
      
      { 
        tour && 
        <DialogActions className="mb-3">
          {/* <Button 
              className="button button-gray mr-1 button-small" 
              onClick={onClose}
            >
              No
            </Button> */}
            <Button 
              className="button button-danger ml-1 button-small"
              onClick={() => {
                startTour();
                onClose();
              }}
            >
              Comenzar Tour
            </Button>
        </DialogActions>

        }
    </Dialog>
  )
}

export default Message