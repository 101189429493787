export default {

    enviroment:{
        urlApi:'http://clientes.wscargo.cl:3003/api/',
        urlApi3021:'http://qiao.wscargo.cl:3021/api/',
        urlApiExternal:'http://clientes.wscargo.cl:3001/api/',
        siteKeyCaptcha:'6LeTvKohAAAAAKEfKyOULojnuge5rLtuudBG4VBi',
        KHIPU_RECEIVER_ID:'453372',
        KHIPU_SECRET:'527ff7975a675ba2de2b93069bcbe13a816cbfb7',
        urlFrontCliente:'http://clientes.wscargo.cl/',
    },
    version:'1.0.0.0',
    development:{
        reduxLogger:false
    }
}; 