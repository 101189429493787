import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles
} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

const useStyles = makeStyles((theme) => ({
  dialogStyle: {
    color: "RED",
    "& .MuiDialogActions-root ": {
      justifyContent: "center",

    }
  },
  iconSuccess: {
    width: "80px",
    height: "80px",
    border: "solid 3px #6FCF97",
    color: "#6FCF97",
    margin: "0 auto",
    borderRadius: "100%",
    fontsize: "400px",

    "& .MuiSvgIcon-root": {
      width: "48px",
      height: "48px",
    }
  },
  iconDanger: {
    width: "80px",
    height: "80px",
    border: "solid 3px #EA5259",
    color: "#EA5259",
    margin: "0 auto",
    borderRadius: "100%",
    fontsize: "400px",

    "& .MuiSvgIcon-root": {
      width: "48px",
      height: "48px",
    }
  },
  alertDanger: {
    color: "#EA5259",
    margin: "0 auto",

    "& .MuiSvgIcon-root": {
      width: "64px",
      height: "64px",
    }
  },
  alertWarning: {
    color: "#F8C96A",
    margin: "0 auto",

    "& .MuiSvgIcon-root": {
      width: "64px",
      height: "64px",
    }
  },
  dialog: {
    "& .MuiDialogActions-root": {
      justifyContent: "center"
    }
  }
}));

const Index = ({ title, content, action, open, onClose, response, icon }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialogStyle}
      response={response}

    >
      <DialogContent className="mt-3">
        <DialogContentText className="text-center mb-0">
          {icon == "iconSuccess" ?
            <div className={`justify-center ${classes.iconSuccess}`}>
              <CheckIcon />
            </div> : ""
          }
          {/* {icon == "iconDanger" ?
            <div className={`justify-center ${classes.iconDanger}`}>
              <CloseIcon />
            </div> : ""
          }

          {icon == "alertWarning" ?
            <div className={`justify-center ${classes.alertWarning}`}>
              <ReportProblemIcon />
            </div> : ""
          } */}
          {icon == "alertDanger" ?
            <div className={`justify-center ${classes.alertDanger}`}>
              <ReportProblemIcon />
            </div> : ""
          }
        </DialogContentText>
      </DialogContent>

      <DialogContent className="text-center mx-0 mx-md-3">
        <DialogTitle className='pt-0'>{title}</DialogTitle>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>

      <DialogActions className="mb-3">
        <Button
          className="button button-danger"
          onClick={onClose}
        >
          ok
        </Button>
      </DialogActions>

    </Dialog>
  )
}

export default Index