import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_FACEBOOK_USER,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER,
    SIGNUP_USER_SUCCESS,
    VALIDATE_EMAIL,
    VALIDATE_EMAIL_SUCCESS,
    CLEAR_IS_VALID,
    PUT_PASSWORD,
    PUT_PASSWORD_SUCCESS,
    GET_REGIONES_REGISTRAR_CLIENTE,
    GET_REGIONES_REGISTRAR_CLIENTE_SUCCESS,
    GET_COMUNAS_REGISTRAR_CLIENTE,
    GET_COMUNAS_REGISTRAR_CLIENTE_SUCCESS,
    POST_REGISTRAR_CLIENTE_EMPRESA,
    POST_REGISTRAR_CLIENTE_EMPRESA_SUCCESS,
    POST_REGISTRAR_CLIENTE_PERSONA,
    POST_REGISTRAR_CLIENTE_PERSONA_SUCCESS,
    CLEAR_REGISTROS,
    GET_ACTIVAR_ACCOUNT,
    GET_ACTIVAR_ACCOUNT_SUCCESS,
    ACTIVE_LOADING,
    DEACTIVE_LOADING,
    VALIDATE_EMAIL_REGISTRO_CLIENTE,
    VALIDATE_EMAIL_REGISTRO_CLIENTE_SUCCESS,
    CLEAR_EMAIL_IS_VALID,
    GET_COMERCIALES_REGISTRAR_CLIENTES,
    GET_COMERCIALES_REGISTRAR_CLIENTES_SUCCESS,
    POST_RESERVE_ID_REGISTRAR_CLIENTES,
    POST_RESERVE_ID_REGISTRAR_CLIENTES_SUCCESS,
    SET_GLOBAL_COMERCIALES_AUTH,
    VALIDATE_RUT_REGISTRO_CLIENTE,
    VALIDATE_RUT_REGISTRO_CLIENTE_SUCCESS,
    CLEAR_RUT_EXISTE,
    CLEAR_IS_ACTIVE,
    POST_DATA_CONTACT,
    SHOW_LOADING_RESTORE_PASSWORD,
    SET_ERROR_EMAIL,
} from '../constants/ActionTypes';

export const Set_Error_Email = (payload) => {
    return {
        type: SET_ERROR_EMAIL,
        payload: payload
    };
};

export const userSignUp = (user) => {
    return {
        type: SIGNUP_USER,
        payload: user
    };
};
export const userSignIn = (user) => {
    return {
        type: SIGNIN_USER,
        payload: user
    };
};
export const userSignOut = () => {
    return {
        type: SIGNOUT_USER
    };
};
export const userSignUpSuccess = (authUser) => {
    return {
        type: SIGNUP_USER_SUCCESS,
        payload: authUser
    };
};

/*export const userSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload: authUser
    }
};*/
export const showLoadingRestorePassword = (payload) => {
    return {
        type: SHOW_LOADING_RESTORE_PASSWORD,
        payload: payload
    }
};
export const userSignInSuccess = (payload) => {
    return {
        type: SIGNIN_USER_SUCCESS,
        payload: payload
    }
};
export const userSignOutSuccess = () => {
    return {
        type: SIGNOUT_USER_SUCCESS,
    }
};

export const showAuthMessage = (message) => {
    return {
        type: SHOW_MESSAGE,
        payload: message
    };
};


export const userGoogleSignIn = () => {
    return {
        type: SIGNIN_GOOGLE_USER
    };
};
export const userGoogleSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_GOOGLE_USER_SUCCESS,
        payload: authUser
    };
};
export const userFacebookSignIn = () => {
    return {
        type: SIGNIN_FACEBOOK_USER
    };
};
export const userFacebookSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_FACEBOOK_USER_SUCCESS,
        payload: authUser
    };
};
export const setInitUrl = (url) => {
    return {
        type: INIT_URL,
        payload: url
    };
};
export const userTwitterSignIn = () => {
    return {
        type: SIGNIN_TWITTER_USER
    };
};
export const userTwitterSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_TWITTER_USER_SUCCESS,
        payload: authUser
    };
};
export const userGithubSignIn = () => {
    return {
        type: SIGNIN_GITHUB_USER
    };
};
export const userGithubSignInSuccess = (authUser) => {
    return {
        type: SIGNIN_GITHUB_USER_SUCCESS,
        payload: authUser
    };
};
export const showAuthLoader = () => {
    return {
        type: ON_SHOW_LOADER,
    };
};

export const hideMessage = () => {
    return {
        type: HIDE_MESSAGE,
    };
};
export const hideAuthLoader = () => {
    return {
        type: ON_HIDE_LOADER,
    };
};
export const validateEmail = (payload) => {
    return {
        type: VALIDATE_EMAIL,
        payload:payload
    };
};

export const validateEmailSuccess = (payload) => {
    return {
        type: VALIDATE_EMAIL_SUCCESS,
        payload:payload
    };
};

export const clearIsValid = () => {
    return {
        type: CLEAR_IS_VALID,
    };
};

export const putPassword = (payload) => {
    return {
        type: PUT_PASSWORD,
        payload:payload
    };
};

export const putPasswordSuccess = (payload) => {
    return {
        type: PUT_PASSWORD_SUCCESS,
        payload:payload
    };
};

export const getRegionesRegistrarCliente = (payload) => {
    return {
        type: GET_REGIONES_REGISTRAR_CLIENTE,
        payload:payload
    };
};

export const getRegionesRegistrarClienteSuccess = (payload) => {
    return {
        type: GET_REGIONES_REGISTRAR_CLIENTE_SUCCESS,
        payload:payload
    };
};

export const getComunasRegistrarCliente = (payload) => {
    return {
        type: GET_COMUNAS_REGISTRAR_CLIENTE,
        payload:payload
    };
};

export const getComunasRegistrarClienteSuccess = (payload) => {
    return {
        type: GET_COMUNAS_REGISTRAR_CLIENTE_SUCCESS,
        payload:payload
    };
};

export const postRegistrarClienteEmpresa = (payload) => {
    return {
        type: POST_REGISTRAR_CLIENTE_EMPRESA,
        payload:payload
    };
};

export const postRegistrarClienteEmpresaSuccess = (payload) => {
    return {
        type: POST_REGISTRAR_CLIENTE_EMPRESA_SUCCESS,
        payload:payload
    };
};

export const postRegistrarClientePersona = (payload) => {
    return {
        type: POST_REGISTRAR_CLIENTE_PERSONA,
        payload:payload
    };
};

export const postRegistrarClientePersonaSuccess = (payload) => {
    return {
        type: POST_REGISTRAR_CLIENTE_PERSONA_SUCCESS,
        payload:payload
    };
};

export const clearRegistros = (payload) => {
    return {
        type: CLEAR_REGISTROS,
        payload:payload
    };
};

export const getActivarAccount = (payload) => {
    return {
        type: GET_ACTIVAR_ACCOUNT,
        payload:payload
    };
};

export const getActivarAccountSuccess = (payload) => {
    return {
        type: GET_ACTIVAR_ACCOUNT_SUCCESS,
        payload:payload
    };
};

export const clearIsActive = (payload) => {
    return {
        type: CLEAR_IS_ACTIVE,
        payload:payload
    };
};

export const activeLoading = (payload) => {
    return {
        type: ACTIVE_LOADING,
        payload:payload
    };
};

export const deactiveLoading = (payload) => {
    return {
        type: DEACTIVE_LOADING,
        payload:payload
    };
};

export const validarEmailRegistroCliente = (payload) => {
    return {
        type: VALIDATE_EMAIL_REGISTRO_CLIENTE,
        payload:payload
    };
};

export const validateEmailRegistroClienteSuccess = (payload) => {
    return {
        type: VALIDATE_EMAIL_REGISTRO_CLIENTE_SUCCESS,
        payload:payload
    };
};

export const clearEmailIsValid = (payload) => {
    return {
        type: CLEAR_EMAIL_IS_VALID,
        payload:payload
    };
};

export const getComercialesRegistrarCliente = (payload) => {
    return {
        type: GET_COMERCIALES_REGISTRAR_CLIENTES,
        payload:payload
    };
};

export const getComercialesRegistrarClienteSuccess = (payload) => {
    return {
        type: GET_COMERCIALES_REGISTRAR_CLIENTES_SUCCESS,
        payload:payload
    };
};

export const postReserveIdRegistrarCliente = (payload) => {
    return {
        type: POST_RESERVE_ID_REGISTRAR_CLIENTES,
        payload:payload
    };
};

export const postReserveIdRegistrarClienteSuccess = (payload) => {
    return {
        type: POST_RESERVE_ID_REGISTRAR_CLIENTES_SUCCESS,
        payload:payload
    };
};

export const setGlobalComercialesAuth = (payload) => {
    return {
        type: SET_GLOBAL_COMERCIALES_AUTH,
        payload:payload
    };
};

export const validateRutRegistroCliente = (payload) => {
    return {
        type: VALIDATE_RUT_REGISTRO_CLIENTE,
        payload:payload
    };
};

export const validateRutRegistroClienteSuccess = (payload) => {
    return {
        type: VALIDATE_RUT_REGISTRO_CLIENTE_SUCCESS,
        payload:payload
    };
};

export const clearRutExiste= (payload) => {
    return {
        type: CLEAR_RUT_EXISTE,
        payload:payload
    };
};

export const postDataContact = (payload) => {
    return {
        type: POST_DATA_CONTACT,
        payload:payload
    };
}