import lodash from 'lodash';

import {
    GLOBAL_CHANGE_STATES_EMPRESA_IMPORTADOR,
    SHOW_MESSAGE_EMPRESA_IMPORTADOR,
    HIDE_MESSAGE_EMPRESA_IMPORTADOR,
    CLEAN_STATES_EMPRESA_IMPORTADOR,
    GET_REI1_SUCCESS,
    GET_REI2_SUCCESS,
    GET_REI3_SUCCESS,
    PUT_REI5_SUCCESS,
    GET_REI6_SUCCESS,
    GET_REI7_SUCCESS,
    RUT_EXISTE_SUCCESS,
    EMAIL_EXISTE_SUCCESS,
    LIST_OCULTAR,
    GET_ROLES_AGREGAR_CONTACTO_SUCCESS,
    GET_MODULOS_SUCCESS,
    POST_ROLES_SUCCESS,
    GET_ROLES_BY_FK_REGISTRO_SUCCESS,
    VALIDATE_NAME_ROLES_SUCCESS,
    CLEAR_ALL_EXPDIG_IMPORTADOR_SUCCESS
} from './Constants';

const INIT_STATE = {
    loader: false,
    showMessage:false,
    alertMessage:{type:'',text:''},
    dataList:[],
    regiones:[],
    comunas:[],
    comunasEdit:[],
    comunasAll:[],
    selectedItem:null,
    documents:null,
    rutExiste: {},
    emailExiste: {},
    listaRoles:[],
    listaModulos:[],
    nameExiste:{}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_ALL_EXPDIG_IMPORTADOR_SUCCESS:{
            return{
                ...INIT_STATE
            }
        }
        case GLOBAL_CHANGE_STATES_EMPRESA_IMPORTADOR:{
            const newState = lodash.cloneDeep(state);
            const [key] = Object.keys(action.payload);
            newState[action.atributte] = { ...newState[action.atributte], ...action.payload};
            return{
                ...newState,
            }
        }
        case SHOW_MESSAGE_EMPRESA_IMPORTADOR:{
            return{
                ...state,
                showMessage:true,
                alertMessage:action.payload
            }
        }
        case HIDE_MESSAGE_EMPRESA_IMPORTADOR:{
            return{
                ...state,
                showMessage:false,
                alertMessage:{type:'',text:''},
            }
        }
        case GET_REI1_SUCCESS:{
            return{
                ...state,
                dataList:action.payload
            }
        }
        case GET_REI2_SUCCESS:{
            return{
                ...state,
                regiones:action.payload
            }
        }
        case GET_REI3_SUCCESS:{
            if(action.edit){
                return{
                    ...state,
                    comunasEdit:action.payload
                }
            }else{
                return{
                    ...state,
                    comunas:action.payload
                }
            }
            
        }
        case PUT_REI5_SUCCESS:{
            return{
                ...state,
                selectedItem:action.payload
            }
        }

        case GET_REI6_SUCCESS:{
            return{
                ...state,
                comunasAll:action.payload
            }
        }

        case GET_REI7_SUCCESS:{
            return{
                ...state,
                documents:action.payload
            }
        }
        case RUT_EXISTE_SUCCESS:{
            return{
                ...state,
                rutExiste: action.payload
            }
        }
        case EMAIL_EXISTE_SUCCESS:{
            return{
                ...state,
                emailExiste: action.payload
            }
        }
        case CLEAN_STATES_EMPRESA_IMPORTADOR:{
            return{
                ...INIT_STATE
            }
        }

        case LIST_OCULTAR:{
            return{
                ...state,
                selectedItem: null
            }
        }

        case GET_ROLES_AGREGAR_CONTACTO_SUCCESS:{
            return{
                ...state,
                listaRoles: action.payload
            }
        }

        case GET_MODULOS_SUCCESS:{
            return{
                ...state,
                listaModulos: action.payload
            }
        }

        case POST_ROLES_SUCCESS:{
            return{
                ...state,
                listaModulos: action.payload
            }
        }

        case VALIDATE_NAME_ROLES_SUCCESS:{
            return{
                ...state,
                nameExiste: action.payload
            }
        }

        default:
            return state;
    }
}