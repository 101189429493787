import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import Alert from "@material-ui/lab/Alert";
import { motion } from "framer-motion";
// import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Avatar,
  // Button,
  // Breadcrumbs,
  Hidden,
  makeStyles,
  Tooltip,
  // Menu,
  // MenuItem,
  // Typography,
  withStyles,
  Button,

} from "@material-ui/core";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import InfoIcon from "@material-ui/icons/Info";
import DirectionsBoatIcon from "@material-ui/icons/DirectionsBoat";
import AppsIcon from "@material-ui/icons/Apps";
import DoneOutline from "@material-ui/icons/DoneOutline";
import moment from 'moment';

import lodash from "lodash";

import ProgressBar from "./ProgressBar";
import Historial from "../modules/detalleServicio/modulos/Logistica/Historial";
// import Resumen from "modules/detalleServicio/modulos/Logistica/Resumen";
import Nav from "./AppLayout/Vertical/Nav/index";
import Header from "./header/Header";
import { FaCheck } from "react-icons/fa";
import { 
  getTrack
  , getTrackDocuments
  , getTrackHistory
  , getTrackFechasEstimadas
  , getTimeLine
  , Tck_SetMostrarModalPagarServicio
  , Tck_ModalPagarServicio_GetInfo
  , Tck_SetMostrarLoadingPagos

 } from "../actions/Track";

 
import '../components/timeline/styles.scss';
import ModalPagarServicio from '../modules/dashboard/Components/ModalPagarServicio';
import StepperUp from "./StepperUp";
import StepperDown from "./StepperDown";
import { AttachMoney, Business, DataUsage, LineWeight, MonetizationOn, TrackChanges } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  inputAdornment: {
    "& .MuiTypography-body1": {
      fontSize: "12px",
    },
  },
  iconServicio: {
    width: "80px",
    height: "72px",

    "& .MuiSvgIcon-root": {
      fontSize: "32px",
    },
  },
  breadcrumbs: {
    paddingLeft: "48px",
    marginBottom: "24px",
  },
  root: {
    padding: "0 80px",
    "@media screen and (min-width: 1900px)": {
      padding: "0 312px !important",
    },
    "@media screen and (max-width: 576px)": {
      padding: "0 24px",
    },
  },
  listItem: {
    display: "flex",
    flexDirection: "column",
  },
  border: {
    borderLeft: "solid 6px  #EA5259",
  },
  bgMessage: {
    height:'80vh',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',

  },
}));

// Tooltip MUI //
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#263238",
    color: "#fff",
    maxWidth: 300,
    padding: "12px 16px",
    "& .MuiTypography-h5": {
      fontSize: "14px",
    },
    "& .MuiTypography-h6": {
      fontSize: "10px",
    },
  },
}))(Tooltip);

const initialDatoServicio = {
  numero_servicio: "",
  bultos_arrivados: "",
  bultos_esperados: "",
  peso_esperado: "",
  volumen_esperado: "",
  valor_carga: "",
  estado: "",
};
// etapas de seguimiento
const estapasSeguimiento = [
  { id: '1', title: 'Cargas recibidas', estado: '', fechaEstimada:'' },
  { id: '2', title: 'Salida Puerto', estado: '', fechaEstimada:' '},
  { id: '3', title: 'Aforo', estado: '-1', fechaEstimada:' '  },
  { id: '4', title: 'Retiro Puerto', estado: '', fechaEstimada:' '  },
  { id: '5', title: 'Disponible Entrega', estado: '', fechaEstimada:' '  },
  { id: '6', title: 'En ruta de despacho', estado: '', fechaEstimada: '' },
  { id: '7', title: 'Entregado', estado: '', fechaEstimada:''  }
];

const bultosInital = [
  {
    fecha: "22-01-2022",
    texto:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
  },
  {
    fecha: "25-01-2022",
    texto: "It is a long established fact that a reader will be distracted",
  },
  {
    fecha: "2-02-2022",
    texto:
      "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots",
  },
  {
    fecha: "27-02-2022",
    texto: "There are many variations of passages",
  },
  {
    fecha: "05-03-2022",
    texto:
      "text of the printing and typesetting industry. Lorem Ipsum has been the industry",
  },
];

const Track = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const Usuario = JSON.parse(localStorage.getItem("userSession"));
  /*const { servicio, documents, history } = useSelector(
        ({ DetalleServicio }) => DetalleServicio
    );*/
  const { track, history, documents,fechasEstimadas, MostrarModalPagarServicio, ModalPagarServicioInfo, timeline } = useSelector(({ Track }) => Track);
  const divRef = useRef();
  const [idService, setIdService] = useState();
  const [etapas, setEtapas] = useState(estapasSeguimiento);
  const [datoServico, setDatoServicio] = useState(initialDatoServicio);
  const [existeDano, setExisteDano] = useState(false);
  const [packingFaltante, setPackingFaltante] = useState(false);
  const [packingRechazado, setPackingRechazado] = useState(false);
  const [documentoFaltante, setDocumentoFaltante] = useState(false);
  const [documentoRechazado, setDocumentoRechazado] = useState(false);
  const [iconTerminado, setIconTerminado] = useState(false);

  let classProgress=`text-small font-weight-500 pointer `;
  let classProgress2=`text-small font-weight-500 pointer `;
  if(etapas.length<=6){
    classProgress=`text-small font-weight-500 pointer extended-progressbar2`;
    classProgress2=`text-small font-weight-500 pointer extended-progressbar2-date`;
  }

  if(etapas.length>6){
    classProgress=`text-small font-weight-500 pointer extended-progressbar3`;
    classProgress2=`text-small font-weight-500 pointer extended-progressbar3-date`;
  }

  useEffect(() => {
    console.log('\n\n');
    console.log('2 DETECTO CAMBIO DE VARIABLE ModalPagarServicioInfo '+ModalPagarServicioInfo);
    console.log('2 DETECTO CAMBIO DE VARIABLE ModalPagarServicioInfo JSON.STRINGIFY '+JSON.stringify(ModalPagarServicioInfo));
    console.log('2 DETECTO CAMBIO DE VARIABLE ModalPagarServicioInfo TYPEOF '+typeof ModalPagarServicioInfo);
    console.log('2 DETECTO CAMBIO DE VARIABLE ModalPagarServicioInfo LARGO '+ModalPagarServicioInfo.length);
    
    if ( ModalPagarServicioInfo!==null && ModalPagarServicioInfo.length>0 )
    { 
      console.log('si calcular');
      dispatch(Tck_SetMostrarModalPagarServicio(true));
    } 
    else
    {
      console.log('no calcular');
      dispatch(Tck_SetMostrarModalPagarServicio(false));
    }

  }, [ModalPagarServicioInfo])

  useEffect(() => {
    //dispatch(getInfoServicioDetalleServicio({ id: idService }));
    dispatch(getTrack({ hash: props.match.params.id }));
    dispatch(getTrackFechasEstimadas());
    dispatch(getTimeLine({ hash: props.match.params.id }));
    //dispatch(getTrackHistory({ hash:props.match.params.id }));
    //dispatch(getTrackDocuments({ hash:props.match.params.id }));
  }, []);

  useEffect(() => {
    if (track.error != undefined) {
      console.log("Ha ocurrido un error: " + track.message);
    } else if (track.id != undefined) {
      //console.log(Object.keys(track).length)
      //if(Object.keys(track).length > 0 ){
      setIdService(track.id);
      dispatch(getTrackHistory({ hash: props.match.params.id }));
      dispatch(getTrackDocuments({ hash: props.match.params.id }));
      //}
    }
  }, [track]);

  /*useEffect(() => {
        //setIdService(track.id);
        if (history.length > 0) {
            
        }
    }, [history]);*/

  useEffect(() => {
    if (track != null) {
      if (track.trackings && track.trackings.length > 0) {
        let dano = false;
        for (let i = 0; i < track.trackings.length; i++) {
          if (
            track.trackings[i].tracking_detalle &&
            track.trackings[i].tracking_detalle.length > 0
          ) {
            let filter = track.trackings[i].tracking_detalle.filter(
              (x) => x.estado_carga != null && x.estado_carga > 0
            );
            if (filter && filter.length > 0) {
              dano = true;
            }
          }
        }
        setExisteDano(dano);
      }
      if (track.servicio_proveedores && track.servicio_proveedores.length > 0) {
        for (let i = 0; i < track.servicio_proveedores.length; i++) {
          if (track.servicio_proveedores[i].packing_list1 == false) {
            setPackingFaltante(true);
          } else if (
            track.servicio_proveedores[i].packing_list1 == true &&
            track.servicio_proveedores[i].packing_list1_aprob == false
          ) {
            setPackingRechazado(true);
          }

          if (track.servicio_proveedores[i].packing_list2 == false) {
            setPackingFaltante(true);
          } else if (
            track.servicio_proveedores[i].packing_list2 == true &&
            track.servicio_proveedores[i].packing_list2_aprob == false
          ) {
            setPackingRechazado(true);
          }
        }
      }
      //dispatch(getDocumentsClientDetalleServicio({ id: track.fk_cliente }));
    }
  }, [track]);

  useEffect(() => {
    if (documents != null) {
      if (documents.cedula_1 == false) {
        setDocumentoFaltante(true);
      } else if (documents.cedula_1 == true && documents.cedula_1_estado == 2) {
        setDocumentoRechazado(true);
      } else if (documents.cedula_1 == true && documents.cedula_1_estado == 0) {
        setDocumentoFaltante(true);
      }

      if (documents.cedula_2 == false) {
        setDocumentoFaltante(true);
      } else if (documents.cedula_2 == true && documents.cedula_2_estado == 2) {
        setDocumentoRechazado(true);
      } else if (documents.cedula_2 == true && documents.cedula_2_estado == 0) {
        setDocumentoFaltante(true);
      }

      if (documents.podersimple_1 == false) {
        setDocumentoFaltante(true);
      } else if (
        documents.podersimple_1 == true &&
        documents.podersimple_1_estado == 2
      ) {
        setDocumentoRechazado(true);
      } else if (
        documents.podersimple_1 == true &&
        documents.podersimple_1_estado == 0
      ) {
        setDocumentoFaltante(true);
      }
    }
  }, [documents]);

  /*useEffect(() => {
        if (track != null) {
            dispatch(getHistoryDetalleServicio({ id: track.id }));
        }
    }, [track]);*/

  /*
        useEffect(() => {
            if (track != null) {
                dispatch(getDataCostosDetalleServicio({ id: track.id, fk_cliente: track.fk_cliente }));
            }
        }, [track]);
    
        useEffect(() => {
            if (servicio != null) {
                dispatch(getPropuestaComercialDetalleServicio({ id: servicio.id }));
            }
        }, [servicio]);
    
        useEffect(() => {
            if (servicio != null) {
                dispatch(getDireccionesClienteDetalleServicio({ fk_cliente: servicio.fk_cliente }));
            }
        }, [servicio]);
    
        useEffect(() => {
            // if (retiroDespacho==null) {
            //  dispatch(getRetiroDespachoDetalleServicio({id:idService}));
            // }
            dispatch(getRetiroDespachoDetalleServicio({ id: idService }));
        }, []);
    
        useEffect(() => {
            if (servicio != null) {
                dispatch(getPagosQiaoDetalleServicio({ id: servicio.id, fk_cliente: servicio.fk_cliente }));
            }
        }, [servicio]);
    */

  // useEffect(() => {
  //   if (history != null && history.length > 0 && timeline != null && timeline.length > 0) {
  //     let aux = lodash.cloneDeep(estapasSeguimiento);

  //           //VARIABLES PARA FECHA ESTIMADA
  //           let etapaAux = {};
  //           let estimacion = 0;
  //           let fechaEstimada = {};
  //           let existe = {};
  //           let fechaAux = '';
  //           let fecha;
  //           let existeAforo=history.findIndex(x => x.texto === 'Notificación de aforo');
  //           let existeEnRuta=history.findIndex(x => x.texto === 'Entrega en ruta');

  //     let f1 = history.findIndex(
  //       (x) => x.texto === "Recepción de carga en bodega"
  //     );
  //     if (f1 >= 0) {
  //       aux[0].estado = "1";
  //       etapaAux = history.find(x => x.texto === 'Recepción de carga en bodega');
  //       aux[0].fechaEstimada = moment(etapaAux.fecha).format('DD/MM/YY');
  //       //Se calcula la fecha estimada
  //       fechaAux = etapaAux.fecha;
  //       existe = fechasEstimadas.length > 0 ? fechasEstimadas.find((f) => f.title === 'Recepción de carga en bodega'): 'undefined';
  //       if(typeof existe !== 'undefined'){
  //           fechaEstimada = existe;
  //           estimacion = parseInt(fechaEstimada.dias);
  //           fecha = moment(fechaAux).add(estimacion,'days');
  //       }
  //     }else{
  //         aux[0].fechaEstimada = <p></p>
  //     }

  //     let f2 = history.findIndex(
  //       (x) => x.texto === "Salida de puerto de origen"
  //     );
  //     if (f2 >= 0) {
  //       aux[0].estado = "1";
  //       aux[1].estado = "1";
  //       etapaAux = history.find(x => x.texto === 'Salida de puerto de origen');
  //       aux[1].fechaEstimada = moment(etapaAux.fecha).format('DD/MM/YY');
  //       //Se calcula la fecha estimada
  //       fechaAux = etapaAux.fecha;
  //       existe = fechasEstimadas.length > 0 ? fechasEstimadas.find((f) => f.title === 'Salida de puerto de origen') : 'undefined';
  //       if(typeof existe !== 'undefined'){
  //           fechaEstimada = existe;
  //           estimacion = parseInt(fechaEstimada.dias);
  //           fecha = moment(fechaAux).add(estimacion,'days');
  //       }
  //     }else
  //     {
  //         aux[1].fechaEstimada = <p></p>
  //     }

  //     if(existeAforo>=0){
  //       aux[0].estado = '1';
  //       aux[1].estado = '1';
  //       aux[2].estado = '1';
  //       if(history[existeAforo].fecha!=null)
  //       {
  //           aux[2].fechaEstimada = moment(history[existeAforo].fecha).utc().format('DD/MM/YY');
  //           fechaAux = history[existeAforo].fecha;
  //           existe = fechasEstimadas.length > 0 ? fechasEstimadas.find((f) => f.title === 'Aforo') : undefined;
  //           if (typeof existe !== 'undefined') 
  //           {
  //               fechaEstimada = existe;
  //               estimacion = parseInt(fechaEstimada.dias);
  //               fecha = moment(fechaAux).utc().add(estimacion, 'days');
  //           }
  //       }
  //   }else{
  //     aux[2].fechaEstimada =<p></p>;
  //   }

  //     let f3 = history.findIndex((x) => x.texto === "Retiro de puerto");
  //     if (f3 >= 0) {
  //       aux[0].estado = "1";
  //       aux[1].estado = "1";
  //       aux[2].estado = "1";
  //       aux[3].estado = "1";
  //       etapaAux = history.find(x => x.texto === 'Retiro de puerto');
  //       aux[3].fechaEstimada = moment(etapaAux.fecha).format('DD/MM/YY');
  //       //Se calcula la fecha estimada
  //       fechaAux = etapaAux.fecha;
  //       existe =  fechasEstimadas.length > 0 ? fechasEstimadas.find((f) => f.title === 'Retiro de puerto') : 'undefined';
  //       if(typeof existe !== 'undefined'){
  //           fechaEstimada = existe;
  //           estimacion = parseInt(fechaEstimada.dias);
  //           fecha = moment(fechaAux).add(estimacion,'days');
  //       }
  //     }else
  //     {
  //         aux[3].fechaEstimada = <p></p>
  //     }

      
    

  //     let f4 = history.findIndex(
  //       (x) => x.texto === "Carga disponible para entrega"
  //     );
  //     if (f4 >= 0) {
  //       aux[0].estado = "1";
  //       aux[1].estado = "1";
  //       aux[2].estado = "1";
  //       aux[3].estado = "1";
  //       aux[4].estado = "1";
  //       etapaAux = history.find(x => x.texto === 'Carga disponible para entrega');
  //       aux[4].fechaEstimada = moment(etapaAux.fecha).format('DD/MM/YY');

  //       //Se calcula la fecha estimada
  //       fechaAux= etapaAux.fecha;
  //       existe = fechasEstimadas.length > 0 ? fechasEstimadas.find((f) => f.title === 'Carga disponible para entrega') : 'undefined';debugger;
  //       if(typeof existe !== 'undefined'){
  //           fechaEstimada = existe;
  //           estimacion = parseInt(fechaEstimada.dias);
  //           fecha = moment(fechaAux).add(estimacion,'days');
  //       }
  //     }else
  //     {
  //         aux[4].fechaEstimada = <p></p>
  //     }

  //     var f5 = history.findIndex(x => x.texto === 'Entrega en ruta');
  //     if (f5 >= 0) {
  //         aux[0].estado = '1';
  //         aux[1].estado = '1';
  //         aux[2].estado = '1';
  //         //if(existeAforo>=0){ aux[3].estado = '1'; } else { aux[3].estado = '-1'; }
  //         aux[3].estado = '1';
  //         aux[4].estado = '1';
  //         aux[5].estado = '1';
  //         etapaAux = history.find(x => x.texto === 'Entrega en ruta');
  //         aux[5].fechaEstimada = moment(etapaAux.fecha).format('DD/MM/YY');

  //         existe = fechasEstimadas.length > 0 ? fechasEstimadas.find((f) => f.title === 'En ruta de despacho') : 'undefined';
  //         if(typeof existe !== 'undefined'){
  //             fechaEstimada = existe;
  //             estimacion = parseInt(fechaEstimada.dias);
  //             fecha = moment(fechaAux).add(estimacion,'days');
  //         }
  //     }
  //     else
  //     {
  //         aux[5].fechaEstimada = <p></p>
  //     }

  //     let f6 = history.findIndex((x) => x.texto === "Carga entregada");
  //     if (f6 >= 0) {
  //       aux[0].estado = "1";
  //       aux[1].estado = "1";
  //       aux[2].estado = "1";
  //       aux[3].estado = "1";
  //       aux[4].estado = "1";
  //       aux[5].estado = "1";
  //       aux[6].estado = "1";
  //       etapaAux = history.find(x => x.texto === 'Carga entregada');
  //       aux[6].fechaEstimada = moment(etapaAux.fecha).format('DD/MM/YY');
  //       setIconTerminado(true);
  //     }else
  //     {
  //       aux[6].fechaEstimada = <p className='m-0 p-black'>{`entre ${ moment(fecha).subtract(fechaEstimada.rango_inferior,'days').format('DD/MM/YY') } - ${moment(fecha).add(fechaEstimada.rango_superior,'days').format('DD/MM/YY')}`  }</p>
  //     }
  //     var aux2=[];
  //     var NewPos=0;
  //           for (var i=0; i<aux.length; i++)
  //           {
  //               if(aux[i].estado!='-1')
  //               {
  //                   aux2[NewPos] = aux[i];
  //                   NewPos++;
  //               }
  //           }
  //     setEtapas(aux2);
  //   }
  // }, [history, timeline]);



  // class card servicio
  
  const [ stepCountH, setStepCountH ] = useState(-1);
  const [ stepCountMobile, setStepCountMobile ] = useState(0);
  useEffect(() => {
    if (history != null && history.length > 0 && timeline != null && timeline.length > 0) {
      
        let aux = [];
        let fechaEstimada = {};
        let estimacion = 0;
        let fecha = '';
        let stepCount = -1;
        let yaLlegoAunoSin = false;

        for (let i = 0; i < timeline.length; i++) {
          // Si no es el ultimo item
          if (i < timeline.length - 1) {
            let item = timeline[i];

            let icon = item.icon ? `data:image/${item.icon_ext || 'png'};base64,${Buffer.from(item.icon.data).toString('base64')}` : null;

            let existe = history.findIndex(x => x.texto.toUpperCase().trim() === item.history.toUpperCase().trim());
            let existeHistory = history.find(x => x.texto.toUpperCase().trim() === item.history.toUpperCase().trim());
            if (existe >= 0) {
              let existeFechasEstimadas = fechasEstimadas.length > 0 ? fechasEstimadas.find((f) => f.title === item.history) : 'undefined';

              if (existeFechasEstimadas !== 'undefined' && existeFechasEstimadas !== undefined) {
                fechaEstimada = existeFechasEstimadas;
                estimacion = parseInt(fechaEstimada.dias);
                fecha = moment(existeHistory.fecha).add(estimacion, 'days');
              }

              aux.push({
                id: item.id,
                texto: item.texto,
                estado: '1',
                fechaEstimada: moment(existeHistory.fecha).format('DD/MM/YY'),
                showMobile: false,
                icon: icon
              });
              stepCount = i;

            } else {
              // Obtener la fecha estimada, si fecha y fechaEstimada estan definidas
              let fecha_proxima = <p></p>;
              let fHitoSiguiente = fechasEstimadas.length > 0 ? fechasEstimadas.find((f) => f.title ===  item.history) : 'undefined';
              if (fHitoSiguiente !== 'undefined' && fHitoSiguiente !== undefined) {
                fechaEstimada=fHitoSiguiente;
              }
             // debugger;
              if (fecha !== '' && fechaEstimada !== {} && yaLlegoAunoSin===false) {
                //fecha_proxima = <p className='m-0 p-black'>{`Fecha estimada entre ${moment(fecha).subtract(fechaEstimada.rango_inferior, 'days').format('DD/MM/YY')} - ${moment(fecha).add(fechaEstimada.rango_superior, 'days').format('DD/MM/YY')}`}</p>;
              }
//debugger;
              let showMobile = yaLlegoAunoSin ? false : true;
              if (!yaLlegoAunoSin && i > 0) {
                // A este y al anterior debes colocarle showMobile true y quitarselo a todos los demas, es decir si hay 5 elementos y vamos
                // en el 3, entonces el 2 y el 3 deben tener showMobile true, y los demas false

                aux[i - 1].showMobile = true;

                for (let j = 0; j < aux.length; j++) {
                  if (j !== i - 1 && j !== i) {
                    aux[j].showMobile = false;
                  }
                }
              }
              yaLlegoAunoSin = true;

              aux.push({
                id: item.id,
                texto: item.texto,
                estado: '-1',
                fechaEstimada: fecha_proxima,
                showMobile: showMobile,
                icon: icon
              });
            }
          } else {
            let item = timeline[i];

            let icon = item.icon ? `data:image/${item.icon_ext || 'png'};base64,${Buffer.from(item.icon.data).toString('base64')}` : null;

            let existe = history.findIndex(x => x.texto.toUpperCase().trim() === item.history.toUpperCase().trim());

            if (existe >= 0) {
              let existeHistory = history.find(x => x.texto.toUpperCase().trim() === item.history.toUpperCase().trim());
              
              aux.push({
                id: item.id,
                texto: item.texto,
                estado: '1',
                fechaEstimada: moment(existeHistory.fecha).format('DD/MM/YY'),
                showMobile: true,
                icon: icon
              });
              stepCount = i;
              setIconTerminado(true);

              // A este debes colocarle showMobile true y quitarselo a todos los demas
              for (let j = 0; j < aux.length; j++) {
                if (j !== i) {
                  aux[j].showMobile = false;
                }
              }
            } else {

              let fecha_proxima = <p></p>;
              if (fecha !== '' && fechaEstimada !== {} /*&& !yaLlegoAunoSin*/) {
                fecha_proxima = <p className='m-0 p-black'>{`Fecha estimada entre ${moment(fecha).subtract(fechaEstimada.rango_inferior, 'days').format('DD/MM/YY')} - ${moment(fecha).add(fechaEstimada.rango_superior, 'days').format('DD/MM/YY')}`}</p>;
              }
              debugger;
              aux.push({
                id: item.id,
                texto: item.texto,
                estado: '-1',
                fechaEstimada: fecha_proxima,
                showMobile: aux[i - 1].estado === '1' ? true : false,
                icon: icon
              });

              if (!yaLlegoAunoSin && i > 0) {
                if (aux[i - 1].estado === '1') {
                  aux[i-1].showMobile = true;
                }

                for (let j = 0; j < aux.length; j++) {
                  if (j !== i && j !== i - 1) {
                    aux[j].showMobile = false;
                  }
                }
              }
            }
          }
        }

        setStepCountH(stepCount);
        
        // Obtener los items que tienen showMobile true y estado 1
        setStepCountMobile(aux.filter(x => x.showMobile === true && x.estado === '1').length - 1);
        setEtapas(aux);
    }
  }, [history, timeline]);
  
  const classEstado = (estado) => {
    if (estado == "0") {
      return "active";
    } else if (estado == "1") {
      return "active1";
    } else if (estado == "2") {
      return "active2";
    }
  };

  const formatMiles = (input) => {
    if (input) {
      var num = input.toString().replace(/\./g, "");
      var formato = null;
      if (!isNaN(num)) {
        num = num
          .toString()
          .split("")
          .reverse()
          .join("")
          .replace(/(?=\d*\.?)(\d{3})/g, "$1.");
        num = num
          .split("")
          .reverse()
          .join("")
          .replace(/^[\.]/, "");
        formato = num;
      } else {
        formato = formato.replace(/[^\d\.]*/g, "");
      }

      return formato;
    }
  };

  function CargarModalPagos(Servicio)
  {
      dispatch(Tck_ModalPagarServicio_GetInfo(Servicio));
  }

  return (
    <>
      <div className="app-container new-template">
        {/* <Tour />
            <SideBar2 /> */}
        <div
          className="app-main-container"
          style={{ backgroundColor: "red !important" }}
        >
          <main className="app-main-content-wrapper">
            <Nav />
            <div className="app-main-content template">
              <div className="app-wrapper">
                <Header />
                <div className={classes.root}>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.5, duration: 1.3 }}
                  //className="app-registro"
                  >
                    {track.error == undefined ? (
                      <>
                        {
                          ( !track || !track.valores || track.valores.length===0 )?
                            <div className="cards card-list mb-5">
                              <div className='cards-header'>
                                <h4 className="text font-weight-500">SALDO PENDIENTE</h4>
                                <div className="border-title"></div>
                                <h4 className="text font-weight-500">Las opciones de pago estarán disponibles cuando se genere la nota de cobro con los valores finales</h4>
                                <br></br>
                              </div>
                            </div>
                          :
                          ( track && track.valores && track.valores.length>0 && track.valores[0].pago_online_habilitar===true && track.valores[0].pago_online_fk_cliente===0 )
                          || ( track && track.valores && track.valores.length>0 && track.valores[0].pago_online_habilitar===true && track.valores[0].pago_online_fk_cliente===track.valores.fk_cliente )
                           ? Number(track.valores[0].calc_total_servicio)>0 && ( Number(track.valores[0].calc_total_servicio) - Number(track.valores[0].calc_total_pagos) ) > 500 ?
                            <div className="cards card-list mb-5">
                              <div className='cards-header'>
                                <h4 className="text font-weight-500">SALDO PENDIENTE: $ {formatMiles(Number(track.valores[0].calc_total_servicio) - Number(track.valores[0].calc_total_pagos))}</h4>
                                <div className="border-title"></div>
                              </div>
                              <div className="cards-body pt-0">
                                  <Button 
                                  className="button button-danger button-xs col-1 mb-1"
                                  onClick={()=>{ CargarModalPagos(track.valores[0].nc_id); }}
                                  >PAGAR</Button>
                              </div>
                            </div>
                          :null
                          :null
                        }
                        <div className="app-registro-empresa">
                          <Hidden smDown>
                            {!iconTerminado &&
                              !existeDano &&
                              !packingFaltante &&
                              !packingRechazado &&
                              !documentoFaltante &&
                              !documentoRechazado && (
                                <Avatar className="app-registro-avatar icon-success">
                                  <DirectionsBoatIcon />
                                </Avatar>
                              )}
                            {!iconTerminado &&
                              !packingRechazado &&
                              !documentoRechazado &&
                              (existeDano ||
                                packingFaltante ||
                                documentoFaltante) && (
                                <Avatar className="app-registro-avatar icon-warning">
                                  <InfoIcon fontSize="large" />
                                </Avatar>
                              )}
                            {!iconTerminado &&
                              (packingRechazado || documentoRechazado) && (
                                <Avatar className="app-registro-avatar icon-danger">
                                  <ReportProblemIcon />
                                </Avatar>
                              )}
                            {iconTerminado && (
                              <Avatar className="app-registro-avatar icon-success">
                                {/* <DoneOutline /> */}
                                <FaCheck />
                              </Avatar>
                            )}
                          </Hidden>
                          <div className="app-registro-content">
                            <div className="app-registro-data">
                              <div className="align-item-center">
                                <Hidden smUp>
                                  {!iconTerminado &&
                                    !existeDano &&
                                    !packingFaltante &&
                                    !packingRechazado &&
                                    !documentoFaltante &&
                                    !documentoRechazado && (
                                      <Avatar className="app-registro-avatar app-avatar-small icon-success mr-3">
                                        <DirectionsBoatIcon />
                                      </Avatar>
                                    )}
                                  {!iconTerminado &&
                                    !packingRechazado &&
                                    !documentoRechazado &&
                                    (existeDano ||
                                      packingFaltante ||
                                      documentoFaltante) && (
                                      <Avatar className="app-registro-avatar app-avatar-small icon-warning mr-3">
                                        <InfoIcon fontSize="large" />
                                      </Avatar>
                                    )}
                                  {!iconTerminado &&
                                    (packingRechazado ||
                                      documentoRechazado) && (
                                      <Avatar className="app-registro-avatar app-avatar-small icon-danger mr-3">
                                        <ReportProblemIcon />
                                      </Avatar>
                                    )}
                                  {iconTerminado && (
                                    <Avatar className="app-registro-avatar  app-avatar-small icon-success mr-3">
                                      {/* <DoneOutline /> */}
                                      <FaCheck />
                                    </Avatar>
                                  )}
                                </Hidden>
                                <h2 className="title-h2 font-weight-700">
                                  <DirectionsBoatIcon /> Nº Servicio{" "}
                                  {track != null ? track.fk_consolidado : ""}
                                  {track.referencia != null && track.referencia != '' ? ` | ${track.referencia.slice(0, 7)}... ` : ''}
                                </h2>
                              </div>

                              <div className="app-registro-item app-registro-item2">
                                <div className="buo">
                                  <div className="d-flex flex-wrap" style={{ textAlign: 'justify' }}>
                                    <h4 className="text font-weight-500">
                                    <Business /> Importador{" "}
                                      {track != null &&
                                        track.cliente_razon_social != null
                                        ? track.cliente_razon_social
                                        : track != null &&
                                          track.cliente_nombre
                                          ? track.cliente_nombre
                                          : ""}
                                    </h4>
                                    <p className="text mx-2">-</p>
                                    <h4 className="text font-weight-500">
                                      {track != null && track.cliente_rut}
                                    </h4>
                                  </div>
                                  <Hidden smDown>

                                    <div className="d-flex flex-wrap">
                                      <h4 className="text font-weight-500" style={{ width: '100%' }}>
                                        <AppsIcon />
                                        Bultos{" "}
                                        {track != null &&
                                          track.bultos_completos}{" "}
                                        de{" "}
                                        {track != null && track.cantidad_bultos}
                                      </h4>
                                      {/* <p className="text mx-2">/</p> */}
                                      <h4 className="text font-weight-500" style={{ width: '100%' }}>
                                        <DataUsage /> Vol. Final{" "}
                                        {track != null && track.volumen} M³
                                      </h4>
                                      {/* <p className="text mx-2">/</p> */}
                                      <h4 className="text font-weight-500" style={{ width: '100%' }}>
                                        <LineWeight /> Peso Final {track != null && track.peso}{" "}
                                        kg{" "}
                                      </h4>
                                      {/* <p className="text mx-2">/</p> */}
                                      {
                                        track && track.valores && track.valores.length>0 && Number(track.valores[0].calc_total_servicio)>0 ?
                                        <h4 className="text font-weight-500" style={{ width: '100%' }}>
                                        <MonetizationOn /> CLP $
                                        {track ? formatMiles(track.valores[0].calc_total_servicio) : 0}
                                      </h4>
                                        :
                                        <h4 className="text font-weight-500" style={{ width: '100%' }}>
                                        <MonetizationOn /> USD $
                                        {track ? formatMiles(track.total) : 0}
                                      </h4>
                                      }
                                      
                                    </div>
                                    {documents != null &&
                                      (documents.cedula_1_estado != 1 ||
                                        documents.cedula_2_estado != 1 ||
                                        documents.podersimple_1_estado !=
                                        1) && (
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            divRef.current.scrollIntoView({
                                              behavior: "smooth",
                                            });
                                          }}
                                          className="app-link text-small text-danger"
                                        >
                                          Revisar acción requerida
                                        </Link>
                                      )}
                                  </Hidden>
                                </div>

                                <Hidden smUp>
                                  {/* <h4 className="text font-weight-500" style={{ width: '100%' }}>
                                    <AppsIcon />
                                    Bultos{" "}
                                    {track != null && track.bultos_completos} de{" "}
                                    {track != null && track.cantidad_bultos}
                                  </h4>
                                  <h5 className="text font-weight-500" style={{ width: '100%' }}>
                                    <DataUsage />
                                    Vol. Final {track != null && track.volumen}{" "}
                                    M³
                                    <LineWeight />
                                    Peso Final {track != null &&
                                      track.peso} kg{" "}
                                  </h5> */}

                                    <div className="d-flex flex-wrap" style={{ textAlign: 'justify' }}>
                                      <h4 className="text font-weight-500" style={{ width: '100%' }}>
                                        <AppsIcon />
                                        Bultos{" "}
                                        {track != null &&
                                          track.bultos_completos}{" "}
                                        de{" "}
                                        {track != null && track.cantidad_bultos}
                                      </h4>
                                      {/* <p className="text mx-2">/</p> */}
                                      <h4 className="text font-weight-500" style={{ width: '100%' }}>
                                        <DataUsage /> Vol. Final{" "}
                                        {track != null && track.volumen} M³
                                      </h4>
                                      {/* <p className="text mx-2">/</p> */}
                                      <h4 className="text font-weight-500" style={{ width: '100%' }}>
                                        <LineWeight /> Peso Final {track != null && track.peso}{" "}
                                        kg{" "}
                                      </h4>
                                      {/* <p className="text mx-2">/</p> */}
                                      {
                                        track && track.valores && track.valores.length>0 && Number(track.valores[0].calc_total_servicio)>0 ?
                                        <h4 className="text font-weight-500" style={{ width: '100%' }}>
                                        <MonetizationOn /> CLP $
                                        {track ? formatMiles(track.valores[0].calc_total_servicio) : 0}
                                      </h4>
                                        :
                                        <h4 className="text font-weight-500" style={{ width: '100%' }}>
                                        <MonetizationOn /> USD $
                                        {track ? formatMiles(track.total) : 0}
                                      </h4>
                                      }
                                      
                                    </div>

                                  {documents != null &&
                                    (documents.cedula_1_estado != 1 ||
                                      documents.cedula_2_estado != 1 ||
                                      documents.podersimple_1_estado != 1) && (
                                      <Link
                                        to="#"
                                        onClick={() => {
                                          divRef.current.scrollIntoView({
                                            behavior: "smooth",
                                          });
                                        }}
                                        className="app-link text-small mt-3"
                                      >
                                        Revisar acción requerida
                                      </Link>
                                    )}
                                </Hidden>
                              </div>
                            </div>
                          </div>
                        </div>

                        <h4 className="title-h4 font-weight-700">
                          Estado del servicio
                        </h4>
                        <Hidden smDown>
                          {/* {
                            // Iterar etapas de maximo 7 en 7
                            etapas.map((item, index) => {
                              if (index % 7 === 0) {
                                return (
                                  <div key={index}>
                                    <ul className="progressbar2">
                                      {etapas.slice(index, index + 7).map((item) => (
                                        <li
                                          key={item.id}
                                          disabled={item.estado == '1' ? false : true}
                                          className={`${classProgress2} ${classEstado(item.estado)}`}
                                        >
                                          {item.fechaEstimada}
                                        </li>
                                      ))}
                                    </ul>
                                    <ul className="progressbar">
                                      {etapas.slice(index, index + 7).map((item) => (
                                        <li
                                          key={item.id}
                                          disabled={item.estado == '1' ? false : true}
                                          className={`${classProgress} ${classEstado(item.estado)}`}
                                        >
                                          {item.texto}
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                );
                              }
                            })
                          } */}
                          <StepperUp history={etapas} stepNow={stepCountH} />
                        </Hidden>

                        <Hidden mdUp>
                          <div className="border my-4"></div>
                        </Hidden>

                        <Hidden mdUp>
                          {/* <div style={{
                            width: '100%',
                            display: 'flex',
                            flexWrap: 'wrap',
                          }}>
                            <ul className="progressbar2-mobile">
                              {etapas.map((item) => (
                                <li
                                  key={item.id}
                                  disabled={item.estado == '1' ? false : true}
                                  className={`${classProgress2} ${classEstado(item.estado)}`}
                                  style={{
                                    padding: '0 20px',
                                  }}
                                >
                                  {item.fechaEstimada}
                                </li>
                              ))}
                            </ul>
                            <ul className="progressbar-mobile">
                              {etapas.map((item) => (
                                <li
                                  key={item.id}
                                  disabled={item.estado == '1' ? false : true}
                                  className={`${classProgress} ${classEstado(item.estado)}`}
                                >
                                  {item.texto}
                                </li>
                              ))}
                            </ul>
                          </div> */}
                          <StepperDown history={etapas} stepNow={stepCountH} stepNowMobile={stepCountMobile} />
                        </Hidden>

                        {/*<div className="border my-4"></div>*/}
                        {/* <Hidden smUp>
                          <ProgressBar
                            item={track != null && track}
                            history={history}
                            fechasEstimadas={fechasEstimadas}
                          />
                        </Hidden> */}

                        <Hidden lgUp>
                          <div className="border my-4"></div>
                        </Hidden>

                        <Hidden mdDown>
                        <div style={{ width: '100%', marginBottom: '40px' }}></div>
                        </Hidden>

                        {/*<Historial history={history} />*/}
                        {/* <div className="row">
                           <div className="col-12 col-lg-4 mb-2">
                            <div className={`cards ${classes.border}`}>
                              <div className="cards-body">
                                {!packingFaltante &&
                                  !packingRechazado &&
                                  documents == null &&
                                  "No tienes notificaciones pendientes"}
                                {!packingFaltante &&
                                  !packingRechazado &&
                                  documents != null &&
                                  documents.cedula_1_estado == 1 &&
                                  documents.cedula_2_estado == 1 &&
                                  documents.podersimple_1_estado == 1 &&
                                  "No tienes notificaciones pendientes"}
                                {(packingFaltante ||
                                  packingRechazado ||
                                  (documents != null &&
                                    (documents.cedula_1_estado != 1 ||
                                      documents.cedula_2_estado != 1 ||
                                      documents.podersimple_1_estado !=
                                      1))) && (
                                    <div className="justify-space-between mb-2">
                                      <div className="row">
                                        <div className="col-12">
                                          <h4 className="text font-weight-500">
                                            Acción requerida
                                          </h4>
                                        </div>
                                        {(packingFaltante ||
                                          (documents != null &&
                                            (documents.cedula_1_estado == 0 ||
                                              documents.cedula_2_estado == 0 ||
                                              documents.podersimple_1_estado ==
                                              0))) && (
                                            <div className="col-12">
                                              <div
                                                className={`list_item-border ${classes.listItem}`}
                                              >
                                                <div className="list-data justify-space-between">
                                                  {(packingFaltante ||
                                                    (documents != null &&
                                                      (documents.cedula_1_estado ==
                                                        0 ||
                                                        documents.cedula_2_estado ==
                                                        0 ||
                                                        documents.podersimple_1_estado ==
                                                        0))) && (
                                                      <h4 className="text-small font-weight-400 mr-2">
                                                        Documentación Faltante
                                                      </h4>
                                                    )}
                                                </div>
                                                <div className="list-data">
                                                  {documents != null &&
                                                    documents.cedula_1_estado ==
                                                    0 && (
                                                      <h5 className="text-xs font-weight-400">
                                                        Cédula de identidad frontal
                                                      </h5>
                                                    )}
                                                  {documents != null &&
                                                    documents.cedula_2_estado ==
                                                    0 && (
                                                      <h5 className="text-xs font-weight-400">
                                                        Cédula de identidad trasera
                                                      </h5>
                                                    )}
                                                  {documents != null &&
                                                    documents.podersimple_1_estado ==
                                                    0 && (
                                                      <h5 className="text-xs font-weight-400">
                                                        Poder simple
                                                      </h5>
                                                    )}
                                                  {packingFaltante && (
                                                    <h5 className="text-xs font-weight-400">
                                                      Documentación proveedores
                                                      faltantes
                                                    </h5>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        {(packingRechazado ||
                                          (documents != null &&
                                            (documents.cedula_1_estado == 2 ||
                                              documents.cedula_2_estado == 2 ||
                                              documents.podersimple_1_estado ==
                                              2))) && (
                                            <div className="col-12">
                                              <div
                                                className={`list_item-border ${classes.listItem}`}
                                              >
                                                <div className="list-data justify-space-between">
                                                  {documents != null &&
                                                    (documents.cedula_1_estado ==
                                                      2 ||
                                                      documents.cedula_2_estado ==
                                                      2 ||
                                                      documents.podersimple_1_estado ==
                                                      2) && (
                                                      <h4 className="text-small font-weight-400 mr-2">
                                                        Documentación rechazada
                                                      </h4>
                                                    )}
                                                </div>
                                                <div className="list-data">
                                                  {documents != null &&
                                                    documents.cedula_1_estado ==
                                                    2 && (
                                                      <h5 className="text-xs font-weight-400">
                                                        Cédula de identidad frontal
                                                      </h5>
                                                    )}
                                                  {documents != null &&
                                                    documents.cedula_2_estado ==
                                                    2 && (
                                                      <h5 className="text-xs font-weight-400">
                                                        Cédula de identidad trasera
                                                      </h5>
                                                    )}
                                                  {documents != null &&
                                                    documents.podersimple_1_estado ==
                                                    2 && (
                                                      <h5 className="text-xs font-weight-400">
                                                        Poder simple
                                                      </h5>
                                                    )}
                                                  {packingRechazado && (
                                                    <h5 className="text-xs font-weight-400">
                                                      Documentación proveedores sin
                                                      aprobar
                                                    </h5>
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <Historial history={history} />
                          </div>
                        </div> */}
                        {/* <div className="justify-center mt-5">

                          Usuario ? ('') : (

                          <Button
                            className="button button-outline-danger button-100-mobile"
                            component={Link}
                            to={Usuario ? "/app/inicio" : "/signin"}
                            color="default"
                          >
                            Iniciar Sesión
                          </Button>)
                        </div> */}
                      </>
                    ) : (
                      <>
                        <div className="row">
                          <div className={`col-12 ${classes.bgMessage}`}>
                            <div className="text-center prueba-img">
                              <img
                                src={require("../assets/images/recepcion/icon-rep-incompleta.png")}
                                alt="recepciones incompletas"
                                title="recepciones incompletas"
                              />
                              <h4 className="text font-weight-500 mt-2">
                                No se ha ingresado la informacion correcta.
                              </h4>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </motion.div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>

      {MostrarModalPagarServicio?
        <ModalPagarServicio
        info={ModalPagarServicioInfo}
        OpenModalServicio={MostrarModalPagarServicio}
        />
      :null}

    </>
  );
};

export default Track;
