import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from "../../constants/ActionTypes";
import Cotizador from './cotizador';
import ReactGA from 'react-ga';
const TRACKING_ID = "UA-174756199-5"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const CotizadorClienteForm = ({ title, match }) => {

    const {
        showMessage,
        alertMessage,
        payload
    } = useSelector(({ CotizadorCliente }) => CotizadorCliente);
    const { drawerType } = useSelector(({ settings }) => settings);
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? "fixed-drawer" : drawerType.includes(COLLAPSED_DRAWER) ? "collapsible-drawer" : "mini-drawer";

    const dispatch = useDispatch();

    // google Analytics
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, []);

    return (
        <div className={`app-container new-template ${drawerStyle}`}>
            {/* <Tour />
            <SideBar2 /> */}
            <div className="app-main-container" style={{ backgroundColor: 'red !important' }}>
                <main className="app-main-content-wrapper">
                    {/* <Nav /> */}
                    <div className="app-main-content template">
                        <div className="app-wrapper">
                            <Cotizador payload={payload} />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}

export default CotizadorClienteForm;