import {all,fork,call, put,takeLatest,spawn} from "redux-saga/effects";
import {
    postRequest,
    getRequest,
    getErrorRequest,
    deleteRequest,
    putRequest
} from '../../util/apiFetch';

import {
    POST_COTIZADOR_CLIENTE,
    GET_COMERCIALES_COTIZADOR_CLIENTE,
    GET_PROPUESTA_BASE_COTIZADOR_CLIENTE,
    GET_REGIONES_COTIZADOR_CLIENTE,
    GET_COMUNAS_COTIZADOR_CLIENTE,
    POST_ID_CLIENTE_COTIZADOR_CLIENTE,
    GET_EXISTS_USER_COTIZADOR_CLIENTE,
    GET_ZONAS_TARIFARIAS_COTIZADOR_CLIENTE,
    VALIDATE_RUT_COTIZADOR,
    DATA_COTIZACION
} from './Constants';

import{
    getComercialesCotizadorClienteSuccess,
    getPropuestaBaseCotizadorClienteSuccess,
    getRegionesCotizadorClienteSuccess,
    getComunasCotizadorClienteSuccess,
    postIdClienteCotizadorClienteSuccess,
    getExistsUserCotizadorClienteSuccess,
    getZonasTarifariasCotizadorClienteSuccess,
    showMessageCotizadorCliente,
    validateRutCotizadorSuccess
} from './Actions';
import { ContactSupportOutlined } from "@material-ui/icons";

function* postSagaCotizadorClienteExecute({payload}){
    const requestURL = `cotizador`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageCotizadorCliente({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
       // yield put(getRegisterUsersListSuccess(obj));
    }
}

function* getSagaComercialesCotizadorClienteExecute({payload}){
    const requestURL = `clientes_get_list_comerciales_public`;
    const response= yield call(getRequest,requestURL,null,false);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageCotizadorCliente({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getComercialesCotizadorClienteSuccess(obj));
    }
}

function* getSagaPropuestaBaseCotizadorClienteExecute({payload}){
    const requestURL = `propuestas_get_propuestabase`;
    const response= yield call(postRequest,requestURL,payload,false);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageCotizadorCliente({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        if(obj.length==0){
            //yield put(showMessageCotizadorCliente({type:'warning',text:'NO EXISTE TARIFA PARA DICHAS ZONAS'}));
        }
        yield put(getPropuestaBaseCotizadorClienteSuccess(obj));
    }
}

function* getSagaRegionesCotizadorClienteExecute({payload}){
    const requestURL = `region_get_list`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageCotizadorCliente({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getRegionesCotizadorClienteSuccess(obj));
    }
}

function* getSagaComunasCotizadorClienteExecute({payload}){
    const requestURL = `comuna_get_comunaregion/${payload.id}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageCotizadorCliente({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getComunasCotizadorClienteSuccess(obj));
    }
}

function* postSagaIdClienteCotizadorClienteExecute({payload}){
    const requestURL = `clientes/reserve_id`;
    const response= yield call(postRequest,requestURL,payload,true);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageCotizadorCliente({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(postIdClienteCotizadorClienteSuccess(obj));
        yield getSagaExistsUserCotizadorClienteExecute(payload);
    }
}

function* getSagaExistsUserCotizadorClienteExecute(payload){
    const requestURL = `usuario/exists_user_email/`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageCotizadorCliente({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getExistsUserCotizadorClienteSuccess(obj));
    }
}

function* getSagaZonasTarifariasCotizadorClienteExecute({payload}){
    const requestURL = `propuestas_get_zonastarifarias`;
    const response= yield call(getRequest,requestURL,null,false);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageCotizadorCliente({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getZonasTarifariasCotizadorClienteSuccess(obj));
    }
}

function* getSagaValidateRutCotizadorExecute({payload}) {
    const requestURL = `registrar-cliente/validate-rut`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageUserAux({type:'error',text:message}));
        console.log("ha ocurrio un error en Saga validateSagaRutEmpresaImportadorExecute() : "+error);
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(validateRutCotizadorSuccess(obj));
        //yield getSagaExistsUserCotizadorClienteExecute(payload);
    }
}

function* postSagaDataCotizacionExecute({payload}) {
    const requestURL = `zoho/data-cotizacion`;
    const response= yield call(postRequest,requestURL,payload,true);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageUserAux({type:'error',text:message}));
        console.log("ha ocurrio un error en Saga postSagaDataCotizacionExecute() : "+error);
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        console.log(obj);
        //yield put(validateRutCotizadorSuccess(obj));
        //yield getSagaExistsUserCotizadorClienteExecute(payload);
    }
}

export function* postSagaCotizadorCliente(){
    yield takeLatest(POST_COTIZADOR_CLIENTE, postSagaCotizadorClienteExecute);
    
}

export function* getSagaComercialesCotizadorCliente(){
    yield takeLatest(GET_COMERCIALES_COTIZADOR_CLIENTE, getSagaComercialesCotizadorClienteExecute);
    
}

export function* getSagaPropuestaBaseCotizadorCliente(){
    yield takeLatest(GET_PROPUESTA_BASE_COTIZADOR_CLIENTE, getSagaPropuestaBaseCotizadorClienteExecute);
}

export function* getSagaRegionesCotizadorCliente(){
    yield takeLatest(GET_REGIONES_COTIZADOR_CLIENTE, getSagaRegionesCotizadorClienteExecute);
}

export function* getSagaComunasCotizadorCliente(){
    yield takeLatest(GET_COMUNAS_COTIZADOR_CLIENTE, getSagaComunasCotizadorClienteExecute);
}

export function* postSagaIdClienteCotizadorCliente(){
    yield takeLatest(POST_ID_CLIENTE_COTIZADOR_CLIENTE, postSagaIdClienteCotizadorClienteExecute);
}

export function* getSagaExistsUserCotizadorCliente(){
    yield takeLatest(GET_EXISTS_USER_COTIZADOR_CLIENTE, getSagaExistsUserCotizadorClienteExecute);
}

export function* getSagaZonasTarifariasCotizadorCliente(){
    yield takeLatest(GET_ZONAS_TARIFARIAS_COTIZADOR_CLIENTE, getSagaZonasTarifariasCotizadorClienteExecute);
}

export function* getSagaValidateRutCotizador(){
    yield takeLatest(VALIDATE_RUT_COTIZADOR, getSagaValidateRutCotizadorExecute);
}

export function* postSagaDataCotizacion(){
    yield takeLatest(DATA_COTIZACION, postSagaDataCotizacionExecute);
}



export default function* rootSaga() {
    yield all([
        fork(postSagaCotizadorCliente),
        fork(getSagaComercialesCotizadorCliente),
        fork(getSagaPropuestaBaseCotizadorCliente),
        fork(getSagaRegionesCotizadorCliente),
        fork(getSagaComunasCotizadorCliente),
        fork(postSagaIdClienteCotizadorCliente),
        fork(getSagaExistsUserCotizadorCliente),
        fork(getSagaZonasTarifariasCotizadorCliente),
        fork(getSagaValidateRutCotizador),
        fork(postSagaDataCotizacion)
    ]);
}

