import {
    GET_TRACK_SUCCESS,
    GET_TRACK_HISTORY_SUCCESS,
    GET_TRACK_DOCUMENTS_SUCCESS,
    GET_TRACK_FECHAS_ESTIMADAS_SUCCESS,
    TCK_MODALPAGARSERVICIO_GETINFO_SUCCESS,
    TCK_SETMOSTRARLOADINGPAGOS,
    TCK_SETMOSTRARMODALPAGARSERVICIO,
    GET_TRACK_LIST_MODIFY_CNSLD_SUCCESS,
    GET_TIME_LINE_SUCCESS
} from "../constants/Track";

const INIT_STATE = {
    track: {},
    history:[],
    documents:{},
    fechasEstimadas:[],
    MostrarModalPagarServicio:false,
    MostrarLoadingPagos:false,
    ModalPagarServicioInfo:[],
    EnviarPagoServicio:{
        Nc:0,
        Carpeta:'',
        MedioPago:'',
        MedioPagoComision:0,
        ValorInicial:0,
        ValorPago:0,
        ValorComision:0,
        ValorComisionFijo:0,
        ValorComisionFijoIva:0,
        ValorComisionNeto:0,
        ValorComisionIva:0,
        ValorTotal:0,
        Usuario:'',
        Email:'',
        Estado:'PENDIENTE',
    },
    consolidados:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        
        case TCK_MODALPAGARSERVICIO_GETINFO_SUCCESS:{
            return {
                ...state,
                ModalPagarServicioInfo: action.payload
            }
        }

        case TCK_SETMOSTRARLOADINGPAGOS:{
            return {
                ...state,
                MostrarLoadingPagos: action.payload
            }
        }
        
        case TCK_SETMOSTRARMODALPAGARSERVICIO:{
            return {
                ...state,
                MostrarModalPagarServicio: action.payload
            }
        }

        case GET_TRACK_SUCCESS: {
            return {
                ...state,
                track: action.payload
            }
        }

        case GET_TRACK_HISTORY_SUCCESS: {
            return {
                ...state,
                history: action.payload
            }
        }

        case GET_TRACK_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                documents: action.payload
            }
        }

        case GET_TRACK_FECHAS_ESTIMADAS_SUCCESS: {
            return {
                ...state,
                fechasEstimadas: action.payload
            }
        }

        case GET_TRACK_LIST_MODIFY_CNSLD_SUCCESS:{
            return {
                ...state,
                consolidados: action.payload
            }
        }

        case GET_TIME_LINE_SUCCESS: {
            return {
                ...state,
                timeline: action.payload
            }
        }
       
        default:
            return state;
    }
}