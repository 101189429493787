export const SHOW_MESSAGE_RECEPCION_NEW='SHOW_MESSAGE_RECEPCION_NEW';
export const HIDE_MESSAGE_RECEPCION_NEW='HIDE_MESSAGE_RECEPCION_NEW';
export const EMPTY_DATA_INIT_RECEPCION_NEW='EMPTY_DATA_INIT_RECEPCION_NEW';
export const GET_CLIENTS_LIST_BY_TEXT_RECEPCION_NEW='GET_CLIENTS_LIST_BY_TEXT_RECEPCION_NEW';
export const GET_CLIENTS_LIST_BY_TEXT_RECEPCION_NEW_SUCCESS='GET_CLIENTS_LIST_BY_TEXT_RECEPCION_NEW_SUCCESS';
export const GET_CLIENTS_LIST_BY_ID_RECEPCION_NEW='GET_CLIENTS_LIST_BY_ID_RECEPCION_NEW';
export const SET_STEPPER_RECEPCION_NEW='SET_STEPPER_RECEPCION_NEW';
export const GET_CLIENT_TRACKING_RECEPCION_NEW='GET_CLIENT_TRACKING_RECEPCION_NEW';
export const GET_CLIENT_TRACKING_SUCCESS_RECEPCION_NEW='GET_CLIENT_TRACKING_SUCCESS_RECEPCION_NEW';
export const SET_CLIENTE_RECEPCION_NEW='SET_CLIENTE_RECEPCION_NEW';
export const SET_PROVEEDOR_RECEPCION_NEW='SET_PROVEEDOR_RECEPCION_NEW';
export const SHOW_BUTTON_STEPPER='SHOW_BUTTON_STEPPER';
export const HIDDEN_BUTTON_STEPPER='HIDDEN_BUTTON_STEPPER';
export const LIST_USER_MOSTRAR='LIST_USER_MOSTRAR';
export const LIST_USER_OCULTAR='LIST_USER_OCULTAR';