import {all,fork,call, put,takeLatest,spawn} from "redux-saga/effects";
/***************************************************************************/
import {
    postRequest,
    getRequest,
    getErrorRequest,
    deleteRequest,
    putRequest
} from '../../util/apiFetch';
/***************************************************************************/
import {
    CUSTSURV_GET_ENCUESTADATA,
    CUSTSURV_POST_ENCUESTA,
} from './Constants';
/***************************************************************************/
import{
    CustSurv_Set_ShowCargando,
    CustSurv_Set_Dialog,

    CustSurv_Get_EncuestaData_Success,
    CustSurv_Get_EncuestaData_Fail,
    CustSurv_Set_DialogConfirmar,
    CustSurv_Post_Encuesta_Success,
} from './Actions';
/***************************************************************************/
function* CustSurv_Get_EncuestaData_Execute(payload){ var isExecute = false; if( isExecute == false ) { isExecute = true;

    console.log("CustSurv_Get_EncuestaData_Execute "+Date.now());
    const response = yield call(postRequest,`custsurv_get_encuestadata`,payload);
    const { error, message } = yield call(getErrorRequest,response);

    yield put(CustSurv_Set_ShowCargando(0));

    if(error)
    {
        yield put(CustSurv_Get_EncuestaData_Fail());
        yield put(CustSurv_Set_Dialog({background:'bg-danger', content:message, title:'label.Error', show:1}));
    }
    else
    {
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(CustSurv_Get_EncuestaData_Success(obj));
        yield put(CustSurv_Set_Dialog({background:'bg-gray', content:'label.SoloUnPunto', title:'label.SoloUnPunto', show:0}));
    }

}}
/***************************************************************************/
function* CustSurv_Post_Encuesta_Execute(payload){ var isExecute = false; if( isExecute == false ) { isExecute = true;

    console.log("CustSurv_Post_Encuesta_Execute "+Date.now());
    const response = yield call(postRequest,`custsurv_post_encuesta`,payload);
    const { error, message } = yield call(getErrorRequest,response);

    yield put(CustSurv_Set_ShowCargando(0));

    if(error)
    {
        yield put(CustSurv_Set_Dialog({background:'bg-danger', content:message, title:'label.Error', show:1}));
    }
    else
    {
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(CustSurv_Set_Dialog({background:'bg-success', content:'ENCUESTA PROCESADA CORRECTAMENTE', title:'label.Felicitaciones', show:1}));
    }
}}
/***************************************************************************/
export function* CustSurv_Get_EncuestaData(){
    yield takeLatest(CUSTSURV_GET_ENCUESTADATA, CustSurv_Get_EncuestaData_Execute);
}
export function* CustSurv_Post_Encuesta(){
    yield takeLatest(CUSTSURV_POST_ENCUESTA, CustSurv_Post_Encuesta_Execute);
}
/***************************************************************************/
export default function* rootSaga() {
    yield all([
        fork(CustSurv_Get_EncuestaData),
        fork(CustSurv_Post_Encuesta),

    ]);
}
/***************************************************************************/