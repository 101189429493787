import React,{useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import lodash from 'lodash';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'
import esLocale from '@fullcalendar/core/locales/es';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
/*
import {
    showMessageDetalleServicio,
} from '../../Actions';*/

import {
    TextField,
    Button
  } from "@material-ui/core";
import moment from 'moment';


const ModalRetiro = (props) =>{
   // const [form,setForm]=useState(payload);
    const [localBloqueos,setLocalBloqueos]=useState([]);

    const [selectedEvent,setSelectedEvent]=useState(null);
    const [inicio,setInicio]=useState(null);
    const [termino,setTermino]=useState(null);

    useEffect(() => {
        if(props.config_retiro!=null){
            let now=moment().format('YYYY-MM-DD 00:00:00');
            let day=moment(props.day).format('YYYY-MM-DD 00:00:00');
            let Nowx=new Date(now);let Dayx=new Date(day);
            if(Nowx>Dayx || Nowx<Dayx){
                setInicio(moment(props.config_retiro.fecha_inicio).format('HH:mm'));
                setTermino(moment(props.config_retiro.fecha_termino).format('HH:mm'));
            }else{
                let Now2=moment().format('YYYY-MM-DD HH:mm');
                let Now2x=new Date(Now2);
                let dayHour=moment(props.day).format('YYYY-MM-DD '+moment(props.config_retiro.fecha_inicio).format('HH:mm'));
                let dayX=new Date(dayHour);
                if(Now2x>=dayX){
                    setInicio(moment(Now2x).add(3,'h').format('HH:mm'));
                }else{
                    setInicio(moment(props.config_retiro.fecha_inicio).add(3,'h').format('HH:mm'));
                }
            }
            setTermino(moment(props.config_retiro.fecha_termino).format('HH:mm'));    
        }
      },[props.config_retiro,props.day]);
    const dispatch=useDispatch();
    const handleSelect=(info)=>{
        const {start,end} =info;
        let ini=moment(start).format('YYYY-MM-DD');
        ini=ini+' '+inicio;
        ini=new Date(ini);
        let ter=moment(end).format('YYYY-MM-DD');
        ter=ter+' '+termino;
        ter=new Date(ter);
        let st=moment(start).toDate();
        let en=moment(end).toDate();
        let next=true;
        if(st>=ini && end<=ter){
            let aux=lodash.cloneDeep(localBloqueos);
            let ocu=aux.filter(x=>x.title=='OCUPADO');
                if(ocu.length>0){
                    let findIndex=ocu.findIndex(x=>x.start==moment(start).format('YYYY-MM-DD HH:mm'));
                    if(findIndex>=0){
                        next=false;
                    }
                }
                let bloqueado=aux.filter(x=>x.title=='Bloqueo');
                if(bloqueado.length>0){
                    let findIndex=bloqueado.findIndex(x=>x.start==moment(start).format('YYYY-MM-DD HH:mm'));
                    if(findIndex>=0){
                        next=false;
                    }
                }
            if(next==true){
                let filter=aux.filter(x=>x.title=='Bloqueo' || x.title=='OCUPADO');
                let obj={title:'Reserva',color:"#0CD806",new:true};
                let hours=moment(start).format('mm');
                if(hours=='00'){
                    obj.start=moment(start).format('YYYY-MM-DD HH:mm');
                    obj.end=moment(start).add(1,'h').format('YYYY-MM-DD HH:mm');
                }else{
                    obj.start=moment(start).subtract(30,'m').format('YYYY-MM-DD HH:mm');
                    obj.end=moment(start).add(30,'m').format('YYYY-MM-DD HH:mm');
                }
                filter.push(obj);
                setSelectedEvent(obj);
                setLocalBloqueos(filter);
                props.setHorario({start:moment(obj.start).format('YYYY-MM-DD HH:mm'),end:moment(obj.end).format('YYYY-MM-DD HH:mm')});
            }
        }else{
           // dispatch(showMessageDetalleServicio({type:'warning',text:'EL BLOQUEO DEBE ESTAR DENTRO DEL RANGO HORARIO'}));
        }
        //dispatch(addEventRetiroAgenda({title:'Reserva',start:moment(start).format('YYYY-MM-DD HH:mm'),end:moment(end).format('YYYY-MM-DD HH:mm'),color:"#fc565c",new:true}))
    }

    useEffect(() => {
        setLocalBloqueos([...props.bloqueos,...props.bloques_ocupados]);
      }, [props.bloqueos,props.bloques_ocupados]);
    
    const handleEventClickRetiro=(info)=>{
        const {start,end,_def} =info.event;
        const id=_def.publicId;
        const isNew=_def.extendedProps.new;
       // props.setSelectedRetiro({id:id,new:isNew,start:start,end:end});
        //props.setOpenDeleteRetiro(true);
    }
    
    return(
        <Dialog
          open={props.open}
          onClose={()=>{props.onToggle();}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth="sm"
        >
            <DialogTitle
            id="customized-dialog-title"
            >
                <h5>SELECCIONA BLOQUE HORARIO PARA RETIRO DEL DÍA {props.day!=null ? moment(props.day).format('DD-MM-YYYY'):''}</h5>
            </DialogTitle>
                <DialogContent>
                    <div className='row'>
                        <div className="col-12">
                        <FullCalendar
                            plugins={[ dayGridPlugin, interactionPlugin,timeGridPlugin ]}
                            initialDate={props.day && props.day!=null && moment(props.day).format('YYYY-MM-DD')}
                            initialView="timeGridDay"
                            events={localBloqueos}
                            businessHours={[{
                                daysOfWeek: [1, 2, 3, 4, 5],
                                startTime: inicio,
                                endTime: termino}
                                ]
                            }
                            //editable
                            selectable
                            locale={esLocale}
                            headerToolbar={{
                                start: '',
                                center: '',
                                end: ''
                              }}
                             select={handleSelect}
                             //eventClick={handleEventClickRetiro}
                        
                        />
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className='col-12'>
                            <Button variant="contained" className="bg-danger jr-btn jr-btn-md rounded-lg pl-1" onClick={()=>{
                                 setSelectedEvent(null);
                                 setLocalBloqueos([]);
                                 props.setHorario(null);
                                props.onToggle();
                            }}>
                                <i className="zmdi zmdi-close"/><span>Cerrar</span>
                            </Button>
                            <Button variant="contained" className="bg-success jr-btn jr-btn-md rounded-lg ml-2 pl-3" onClick={()=>{
                                props.onToggle();
                            }}>
                                <i className="zmdi zmdi-save"/><span>Guardar</span>
                            </Button>
                        </div>
                    </div>
                </DialogContent>
        </Dialog>
    )
}
export default ModalRetiro;