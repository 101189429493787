import React,{useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { makeStyles } from "@material-ui/core";
import lodash from 'lodash';
import moment from 'moment';
import Chip from '@material-ui/core/Chip';

const estapasSeguimiento = [
    { id: '1', etapa: 'Carga Recibida', estado: 'pendiente' },
    { id: '2', etapa: 'Salida Puerto', estado: 'pendiente' },
    { id: '3', etapa: 'Arribo Puerto', estado: 'pendiente' },
    { id: '4', etapa: 'Aforo', estado: 'pendiente' },
    { id: '5', etapa: 'Disponible Entrega', estado: 'pendiente' },
    { id: '6', etapa: 'Entregado', estado: 'pendiente' }
]


const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        justifyContent: "left",
        flexWrap: "nowrap",
        listStyle: "none",
        padding: theme.spacing(0.5),
        margin: 0,
        overflowY: "scroll",
        scrollbarWidth: "none",

        // justifyContent: 'center',
        // flexWrap: 'wrap',
        '& > *': {
            marginRight: "16px",
        },
        '& .MuiChip-root': {
            padding:"18px 12px",
            backgroundColor:"#cfcec",

            '.MuiChip-label': {
                fontSize: "5px !import",
            }
        },
        
    },
    progressbar: {
        width: "100px",
        height: "200px"
       
    }

}));

const ProgressBar = (props) => {
    const classes = useStyles();
    const fechasEstimadas=props.fechasEstimadas;

    const [etapas,setEtapas]= useState(estapasSeguimiento);
    const [etapaActual, setEtapaActual] = useState('Esperando recibir tu carga');
    const [porcentaje, setPorcentaje] =useState(0);
    const [proximasEtapas,setProximasEtapas] =useState([]);
    const [fecha, setFecha] = useState('');
    const [fechaEstimada, setFechaEstimada] = useState({
        fecha:'',
        rango_inferior:'',
        rango_superior:''
    });

    useEffect(() => {
        if (props.history!=null && props.history.length>0) {
            let aux=lodash.cloneDeep(estapasSeguimiento);
            let historyUnique=props.history;
            let f1=historyUnique.findIndex(x=>x.texto==='Recepción de carga en bodega');
            let existeAforo=historyUnique.findIndex(x => x.texto === 'Notificación de aforo');
            let porcU=16.6;
            if(existeAforo>=0){
                porcU=14.3;
            }
            //VARIABLES PARA FECHA ESTIMADA
            let etapaAux = {};
            let estimacion = 0;
            let fechaEstimada = {};
            let existe = {};
            let fechaAux = '';
            let fecha;
            if(f1>=0){
                aux[0].estado='active';
                etapaAux = historyUnique.find(x => x.texto === 'Recepción de carga en bodega');
                //Se calcula la fecha estimada
                fechaAux = etapaAux.fecha;
                existe = fechasEstimadas.length > 0 ?  fechasEstimadas.find((f) => f.title === 'Recepción de carga en bodega') : 'undefined';
                if(typeof existe !== 'undefined'){
                    fechaEstimada = existe;
                    estimacion = parseInt(fechaEstimada.dias);
                    fecha = moment(fechaAux).add(estimacion,'days');  
                }
                setFecha(moment(etapaAux.fecha).format('DD/MM/YY'))
                setEtapaActual('Cargas recibidas');
                setPorcentaje(porcU);
                let nextE=[];
                nextE.push(aux[1]);
                //nextE.push(aux[2]);
                //nextE.push(aux[3]);
                //nextE.push(aux[4]);
                setProximasEtapas(nextE);
            }

            let f2=historyUnique.findIndex(x=>x.texto==='Salida de puerto de origen');
            if(f2>=0){
                aux[1].estado='active';
                etapaAux = historyUnique.find(x => x.texto === 'Salida de puerto de origen');
                //Se calcula la fecha estimada
                fechaAux = etapaAux.fecha;
                existe = fechasEstimadas.length > 0 ?  fechasEstimadas.find((f) => f.title === 'Salida de puerto de origen') : 'undefined';
                if(typeof existe !== 'undefined'){
                    fechaEstimada = existe;
                    estimacion = parseInt(fechaEstimada.dias);
                    fecha = moment(fechaAux).add(estimacion,'days');  
                }
                setFecha(moment(etapaAux.fecha).format('DD/MM/YY'))
                setEtapaActual('Salida Puerto');
                setPorcentaje((porcU*2));
                let nextE=[];
                nextE.push(aux[2]);
                //nextE.push(aux[3]);
                //nextE.push(aux[4]);
                setProximasEtapas(nextE);
            }

            let f3=historyUnique.findIndex(x=>x.texto==='Retiro de puerto');
            if(f3>=0){
                aux[2].estado='active';
                etapaAux = historyUnique.find(x => x.texto === 'Retiro de puerto');
               //Se calcula la fecha estimada
                fechaAux = etapaAux.fecha;
                existe = fechasEstimadas.length > 0 ?  fechasEstimadas.find((f) => f.title === 'Retiro de puerto') : 'undefined';
                if(typeof existe !== 'undefined'){
                    fechaEstimada = existe;
                    estimacion = parseInt(fechaEstimada.dias);
                    fecha = moment(fechaAux).add(estimacion,'days');  
                }
                setFecha(moment(etapaAux.fecha).format('DD/MM/YY'))
                setEtapaActual('Arribo de puerto');
                setPorcentaje((porcU*3));
                let nextE=[];
                nextE.push(aux[3]);
                //nextE.push(aux[4]);
                setProximasEtapas(nextE);
            }

            if(existeAforo>=0){
                if(historyUnique[existeAforo].fecha!=null)
                {
                    aux[3].fechaEstimada = moment(historyUnique[existeAforo].fecha).utc().format('DD/MM/YY');
                    fechaAux = historyUnique[existeAforo].fecha;
                    existe = fechasEstimadas.length > 0 ? fechasEstimadas.find((f) => f.title === 'Aforo') : undefined;
                    if (typeof existe !== 'undefined') 
                    {
                        fechaEstimada = existe;
                        estimacion = parseInt(fechaEstimada.dias);
                        fecha = moment(fechaAux).utc().add(estimacion, 'days');
                    }
                    setFecha(moment(historyUnique[existeAforo].fecha).format('DD/MM/YY'))
                    setEtapaActual('Aforo');
                    setPorcentaje((porcU*4));
                    let nextE=[];
                    nextE.push(aux[4]);
                    nextE.push(aux[5]);
                    setProximasEtapas(nextE);
                }
            }else{
                aux[3].fechaEstimada = <p></p>
            }

            let f4=historyUnique.findIndex(x=>x.texto==='Carga disponible para entrega');
            if(f4>=0){
                aux[4].estado='active';
                etapaAux = historyUnique.find(x => x.texto === 'Carga disponible para entrega');
                //Se calcula la fecha estimada
                fechaAux = etapaAux.fecha;
                existe = fechasEstimadas.length > 0 ?  fechasEstimadas.find((f) => f.title === 'Carga disponible para entrega') : 'undefined';
                if(typeof existe !== 'undefined'){
                    fechaEstimada = existe;
                    estimacion = parseInt(fechaEstimada.dias);
                    fecha = moment(fechaAux).add(estimacion,'days');  
                }
                setFecha(moment(etapaAux.fecha).format('DD/MM/YY'))
                setEtapaActual('Disponible Entrega');
                if(existeAforo>=0){
                    setPorcentaje((porcU*5));
                }else{
                    setPorcentaje((porcU*4));
                }
                let nextE=[];
                nextE.push(aux[5]);
                setProximasEtapas(nextE);
            }

            let f5=historyUnique.findIndex(x=>x.texto==='Carga entregada');
            if(f5>=0){
                aux[5].estado='active';
                etapaAux = historyUnique.find(x => x.texto === 'Carga entregada');
                setFecha(moment(etapaAux.fecha).format('DD/MM/YY'))
                setEtapaActual('Entregado');
                setPorcentaje(100);
                setProximasEtapas([]);
            }else {
                //Se renderiza la fecha estiamda
                if( f1>=0 ){
                    if(typeof existe !== 'undefined'){
                        setFechaEstimada({
                            rango_inferior:moment(fecha).subtract(fechaEstimada.rango_inferior,'days').format('DD/MM/YY'),
                            rango_superior:moment(fecha).add(fechaEstimada.rango_superior,'days').format('DD/MM/YY')
                        });
                        aux[5].fechaEstimada = <p className='m-0'>{`entre ${ moment(fecha).subtract(fechaEstimada.rango_inferior,'days').format('DD/MM/YY') } - ${moment(fecha).add(fechaEstimada.rango_superior,'days').format('DD/MM/YY')}`  }</p>
                    } else{
                        aux[5].fechaEstimada = <p></p>
                    }  
                }
            }
            setEtapas(aux);
        }
    }, [props.history,props.item, fechasEstimadas]);
    


    return (
        <div className="row">
            <div className='col-5'>
                <CircularProgressbar
                    value={porcentaje}
                    strokeWidth={8}

                    styles={{
                        path: {
                            // 
                            stroke: '#6FCF97',
                            transition: 'stroke-dashoffset 0.5s ease 0s',
                        },
                        trail: {
                            
                            stroke: '#cfcece',
                            strokeLinecap: 'round',
                        },
                        // text
                        text: {
                            // Text color
                            fill: '#cfcece',
                            // Text size
                            fontSize: '18px',
                            fontWeight: 'bold'
                        }
                    }}
                />
            </div>

            <div className='col-7 align-item-center'>
                <div className="etapa">
                    <h4 className="text-small font-weight-400">Estado de carga</h4>
                    <h5 className="title-h5 font-weight-700">{`${etapaActual}`}</h5>
                    <h5 className="text font-weight-500 mb-2">{`${fecha}`}</h5>                    
                    {/*<div className={classes.root}>
                        {
                            proximasEtapas && proximasEtapas.length > 0 ? proximasEtapas.map((item, index) => (
                                <Chip label={item.etapa} index={index} />
                            )) : <Chip label={"Carga Recibida"} index={0} />
                        }
                    </div>
                    */}
                    {proximasEtapas.length>0 &&
                        <>
                        <h5 className="text-xs font-weight-400">Próxima Etapa</h5>
                        <h5 className="text-small font-weight-400">{`${proximasEtapas[0].etapa}`}</h5>
                        </>
                    }
                    {fechaEstimada.rango_inferior.length > 1 &&
                        <>
                        <h5 className="text-xs font-weight-400">Fecha estimada de Entrega</h5>
                        <h5 className="text-small font-weight-400">{`${ fechaEstimada.rango_inferior } - ${ fechaEstimada.rango_superior }`}</h5>
                        </>
                    }
                </div>
            </div>

            <div className='col-12'>
            </div>
        </div>
    )
}

export default ProgressBar
