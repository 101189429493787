import { element } from 'prop-types';
import React, { Children } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Router } from 'react-router';
const Usuario = JSON.parse(localStorage.getItem('userSession'));
const PrivateRoute = (props) => {
    console.log(props);

    const { roles } = useSelector(({ auth }) => auth);
    return (
        <>
            { roles && roles.length > 0 && roles.some((element) => { return element.fk_rol === 1 || element.fk_rol === 4} ) ? props.children : "401 Unauthorized"}
        </>
    );
}

export default PrivateRoute;