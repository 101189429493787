import {
    SET_MESSAGE_SOLIC_AGENDAMIENTO,
    GET_SERVICE_SOLIC_AGENDAMIENTO_SUCCESS,
    GET_REGIONES_SOLIC_AGENDAMIENTO_SUCCESS,
    GET_COMUNAS_SOLIC_AGENDAMIENTO_SUCCESS,
    GET_DIRECCIONES_SOLIC_AGENDAMIENTO_SUCCESS,
    GET_CONFIG_DESPACHO_SOLIC_AGENDAMIENTO_SUCCESS,
    GET_CONFIG_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS,
    GET_DISPONIBILIDAD_DESPACHO_SOLIC_AGENDAMIENTO_SUCCESS,
    GET_BLOQUEOS_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS,
    GET_DISPONIBILIDAD_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS
} from './Constants';
import lodash from 'lodash';

const INIT_STATE = {
    loader:false,
    showMessage:false,
    alertMessage:{type:'',text:''},
    servicio:null,
    direcciones:[],
    regiones:[],
    comunas:[],
    comerciales:[],
    config_despacho:null,
    config_retiro:null,
    bloqueos_retiro_horario:[],
    bloqueos_retiro_calendario:[],
    bloques_ocupados:[],
    bloqueos_despacho_calendario:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_MESSAGE_SOLIC_AGENDAMIENTO:{
            return{
                ...state,
                showMessage:action.payload.show,
                alertMessage:action.payload.message
            }
        }
        case GET_SERVICE_SOLIC_AGENDAMIENTO_SUCCESS:{
            return{
                ...state,
                servicio:action.payload
            }
        }
        case GET_REGIONES_SOLIC_AGENDAMIENTO_SUCCESS:{
            return{
                ...state,
                regiones:action.payload
            }
        }
        case GET_COMUNAS_SOLIC_AGENDAMIENTO_SUCCESS:{
            return{
                ...state,
                comunas:action.payload
            }
        }
        case GET_DIRECCIONES_SOLIC_AGENDAMIENTO_SUCCESS:{
            return{
                ...state,
                direcciones:action.payload
            }
        }
        case GET_CONFIG_DESPACHO_SOLIC_AGENDAMIENTO_SUCCESS:{
            return{
                ...state,
                config_despacho:action.payload
            }
        }
        case GET_CONFIG_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS:{
            return{
                ...state,
                config_retiro:action.payload
            }
        }
        case GET_DISPONIBILIDAD_DESPACHO_SOLIC_AGENDAMIENTO_SUCCESS:{
            return{
                ...state,
                bloqueos_despacho_calendario:action.payload
            }
        }
        case GET_BLOQUEOS_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS:{
            return{
                ...state,
                bloqueos_retiro_horario:action.payload
            }
        }
        case GET_DISPONIBILIDAD_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS:{
            let ocupado=[];let grouped=[];
            if(action.payload && action.payload.length>0){
                action.payload.map(item=>{
                    if(item.detalle && item.detalle.length>0){
                        item.detalle.map(el=>{
                            if(grouped.length>0){
                                let findIndex=grouped.findIndex(x=>x.start===el.start && x.end===el.end);
                                if(findIndex>=0){
                                    grouped[findIndex].cantidad++;
                                }else{
                                    grouped.push({...el,cantidad:1});
                                }
                            }else{
                                grouped.push({...el, cantidad:1});
                            }
                        }) 
                    }
                })
            }
            
            if(grouped.length>0){
                grouped.map(el=>{
                    if(el.cantidad>=state.config_retiro.cantidad){
                        if(ocupado.length>0){
                            let findIndex=ocupado.findIndex(x=>x.start==el.start && x.end===el.end);
                            if(findIndex<0){
                                ocupado.push(el);
                            }
                        }else{
                            ocupado.push(el);
                        }   
                    }
                })
            }
            return{
                ...state,
                bloqueos_retiro_calendario:action.payload,
                bloques_ocupados:ocupado
            }
        }
        default:
            return state;
    }
}