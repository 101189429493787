import {
    SET_MESSAGE_SOLIC_AGENDAMIENTO,
    GET_SERVICE_SOLIC_AGENDAMIENTO,
    GET_SERVICE_SOLIC_AGENDAMIENTO_SUCCESS,
    GET_DIRECCIONES_SOLIC_AGENDAMIENTO,
    GET_DIRECCIONES_SOLIC_AGENDAMIENTO_SUCCESS,
    GET_REGIONES_SOLIC_AGENDAMIENTO,
    GET_REGIONES_SOLIC_AGENDAMIENTO_SUCCESS,
    GET_COMUNAS_SOLIC_AGENDAMIENTO,
    GET_COMUNAS_SOLIC_AGENDAMIENTO_SUCCESS,
    GET_CONFIG_DESPACHO_SOLIC_AGENDAMIENTO,
    GET_CONFIG_DESPACHO_SOLIC_AGENDAMIENTO_SUCCESS,
    GET_CONFIG_RETIRO_SOLIC_AGENDAMIENTO,
    GET_CONFIG_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS,
    POST_DESPACHO_RETIRO_SOLIC_AGENDAMIENTO,
    GET_DISPONIBILIDAD_DESPACHO_SOLIC_AGENDAMIENTO,
    GET_DISPONIBILIDAD_DESPACHO_SOLIC_AGENDAMIENTO_SUCCESS,
    GET_BLOQUEOS_RETIRO_SOLIC_AGENDAMIENTO,
    GET_BLOQUEOS_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS,
    GET_DISPONIBILIDAD_RETIRO_SOLIC_AGENDAMIENTO,
    GET_DISPONIBILIDAD_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS
} from './Constants';

export const setMessageSolicAgendamiento= (payload) => {
    return {
        type: SET_MESSAGE_SOLIC_AGENDAMIENTO,
        payload: payload
    };
};

export const getServiceSolicAgendamiento= (payload) => {
    return {
        type: GET_SERVICE_SOLIC_AGENDAMIENTO,
        payload: payload
    };
};

export const getServiceSolicAgendamientoSuccess= (payload) => {
    return {
        type: GET_SERVICE_SOLIC_AGENDAMIENTO_SUCCESS,
        payload: payload
    };
};

export const getDireccionesSolicAgendamiento= (payload) => {
    return {
        type: GET_DIRECCIONES_SOLIC_AGENDAMIENTO,
        payload: payload
    };
};

export const getDireccionesSolicAgendamientoSuccess= (payload) => {
    return {
        type: GET_DIRECCIONES_SOLIC_AGENDAMIENTO_SUCCESS,
        payload: payload
    };
};

export const getRegionesSolicAgendamiento= (payload) => {
    return {
        type: GET_REGIONES_SOLIC_AGENDAMIENTO,
        payload: payload
    };
};

export const getRegionesSolicAgendamientoSuccess= (payload) => {
    return {
        type: GET_REGIONES_SOLIC_AGENDAMIENTO_SUCCESS,
        payload: payload
    };
};

export const getComunasSolicAgendamiento= (payload) => {
    return {
        type: GET_COMUNAS_SOLIC_AGENDAMIENTO,
        payload: payload
    };
};

export const getComunasSolicAgendamientoSuccess= (payload) => {
    return {
        type: GET_COMUNAS_SOLIC_AGENDAMIENTO_SUCCESS,
        payload: payload
    };
};

export const getConfigDespachoSolicAgendamiento= (payload) => {
    return {
        type: GET_CONFIG_DESPACHO_SOLIC_AGENDAMIENTO,
        payload: payload
    };
};

export const getConfigDespachoSolicAgendamientoSuccess= (payload) => {
    return {
        type: GET_CONFIG_DESPACHO_SOLIC_AGENDAMIENTO_SUCCESS,
        payload: payload
    };
};

export const getConfigRetiroSolicAgendamiento= (payload) => {
    return {
        type: GET_CONFIG_RETIRO_SOLIC_AGENDAMIENTO,
        payload: payload
    };
};

export const getConfigRetiroSolicAgendamientoSuccess= (payload) => {
    return {
        type: GET_CONFIG_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS,
        payload: payload
    };
};

export const postDespachoRetiroSolicAgendamiento= (payload) => {
    return {
        type: POST_DESPACHO_RETIRO_SOLIC_AGENDAMIENTO,
        payload: payload
    };
};

export const getDisponibilidadDespachoSolicAgendamiento= (payload) => {
    return {
        type: GET_DISPONIBILIDAD_DESPACHO_SOLIC_AGENDAMIENTO,
        payload: payload
    };
};

export const getDisponibilidadDespachoSolicAgendamientoSuccess= (payload) => {
    return {
        type: GET_DISPONIBILIDAD_DESPACHO_SOLIC_AGENDAMIENTO_SUCCESS,
        payload: payload
    };
};

export const getBloqueosRetiroSolicAgendamiento= (payload) => {
    return {
        type: GET_BLOQUEOS_RETIRO_SOLIC_AGENDAMIENTO,
        payload: payload
    };
};

export const getBloqueosRetiroSolicAgendamientoSuccess= (payload) => {
    return {
        type: GET_BLOQUEOS_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS,
        payload: payload
    };
};

export const getDisponibilidadRetiroSolicAgendamiento= (payload) => {
    return {
        type: GET_DISPONIBILIDAD_RETIRO_SOLIC_AGENDAMIENTO,
        payload: payload
    };
};

export const getDisponibilidadRetiroSolicAgendamientoSuccess= (payload) => {
    return {
        type: GET_DISPONIBILIDAD_RETIRO_SOLIC_AGENDAMIENTO_SUCCESS,
        payload: payload
    };
};





