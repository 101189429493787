import React, { Fragment, useState } from 'react'
import StepConnector from '@material-ui/core/StepConnector';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';

export default function StepperDown({
  history, stepNow, stepNowMobile
}) {

  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 50,
      height: 50,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
  });

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      left: 'calc(-50% + 12px)',
      right: 'calc(50% + 12px)',
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 136deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 136deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    line: {
      width: 3,
      border: 0,
      marginLeft: 10,
      marginTop: 5,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
      height: '100px !important',
    },
  })(StepConnector);

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        <CheckIcon />
      </div>
    );
  }

  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

  const [showAll, setShowAll] = useState(false)

  return (
    <Fragment>

      <Stepper activeStep={showAll ? stepNow : stepNowMobile} connector={<ColorlibConnector />} className='stepper-icon' orientation='vertical' >
        {/* {history.map((item, index) => ( */}
        {
          showAll ? history.map((item, index) => (
            <Step key={index} style={{
              // display: item.showMobile ? undefined : 'none'
              position: 'relative'
            }}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>
                {item.texto}
                <br />
                {item.fechaEstimada}
              </StepLabel>

              {
                item.icon ?
                <img src={item.icon} alt="icon" style={{width: '50px', height: '50px', position: 'absolute', zIndex: 9, top: '75px'}} />
                :
                null
              }
            </Step>
          )) :
            history.filter(item => item.showMobile).map((item, index) => (
              <Step key={index} style={{
                // display: item.showMobile ? undefined : 'none'
                position: 'relative'
              }}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                  {item.texto}
                  <br />
                  {item.fechaEstimada}
                </StepLabel>

                {
                  item.icon && index < 1 ?
                  <img src={item.icon} alt="icon" style={{width: '50px', height: '50px', position: 'absolute', zIndex: 9, top: '75px'}} />
                  :
                  null
                }
              </Step>
            ))
        }
      </Stepper>

      <Button
        className="button mt-3"
        style={{
          marginTop: '10px !important',
          maxWidth: '100% !important',
          display: 'block !important',
        }} fullWidth
        onClick={() => { setShowAll(!showAll) }}
      >{showAll ? 'Ver menos' : 'Ver más'}</Button>

    </Fragment>
  )
}