import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { motion } from "framer-motion"
import lodash from 'lodash';
import Loading from './loading/Loading.jsx';

import {
    InputLabel,
    Button,
    TextField,
    makeStyles,
    CircularProgress,
    Hidden,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    Avatar,
    IconButton
} from "@material-ui/core";

import {
    validateEmail
    , clearIsValid
    , showLoadingRestorePassword
    , Set_Error_Email
} from '../actions/Auth.js';

const useStyles = makeStyles((theme) => ({
    link: {
        fontSize: "14px",
        color: "#263238",
        fontWeight: "500",
        marginRight: "16px",
        "&:hover": {
            color: "#8F8F8F"
        }
    },
    titleLogin: {
        paddingLeft:"12px",
        marginBottom: "16px"
    },
    dialogStyle: {
        color: "RED",
        "& .MuiDialogActions-root ": {
            justifyContent: "center",

        }
    }
}));

const RecoverPassword = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({
        error:"errorDefault"
    });
    const [emailIsValidLocal, setEmailIsValidLocal] = useState({});
    
    const { emailIsValid, loadingRestorePassword, ErrorEmail } = useSelector(({ auth }) => auth);

    const [loading, setLoading] = useState(false);
    
    useEffect(()=>{
        if(loadingRestorePassword==1)
        {
            console.log('MOSTRAR LOADING '+loadingRestorePassword);
            setLoading(true);
        }
        else
        {
            console.log('MOSTRAR LOADING '+loadingRestorePassword);
            setLoading(false);
        }
        
      },[loadingRestorePassword]);
    /*useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 100);
        }
        if (authUser !== null) {
            props.history.push('/');
        }
    }, [showMessage, authUser, props.history, dispatch]);*/

    useEffect(() => {       
        if(emailIsValid.success === true)
        {
            setOpen(true);
            setEmailIsValidLocal({});
            dispatch(clearIsValid());
        }
        else
        {
            setEmailIsValidLocal(emailIsValid);
            
        }
    },[emailIsValid]);

    //  dialog de confrimación de registro
    const [open, setOpen] = useState(false);
    const [openErrorEmail, setOpenErrorEmail] = useState(ErrorEmail);

    useEffect(() => {       

        setOpenErrorEmail(ErrorEmail);

    },[ErrorEmail]);

    const onSubmitForm = (e) => {
        dispatch(showLoadingRestorePassword(1));
        e.preventDefault();
        setErrors(validateForm({ email }));
        if(Object.keys(errors).length === 0 )
        {
            dispatch(validateEmail({ email }));
        }
        else
        {
            dispatch(showLoadingRestorePassword(0));
        }
    }

    const handleClose = () => {
        setOpen(false);
        history.push("/signin");
        
    };

    const handleCloseErrorEmail = () => {
        setOpenErrorEmail(false);
        dispatch(Set_Error_Email(false));
    };

    const onBlurInput = () => {
        setErrors(validateForm({ email }));
    }

    const validateForm = (form) =>{
        let error = {};
        let rgxValidarEmail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if(!form.email.trim())
        {
            error.email = "Campo obligatorio";
            setLoading(false);
        }
        else if(!rgxValidarEmail.test(form.email))
        {
            error.email = "No es un email valido.";
            setLoading(false);
        }
        return error;
    }

    return (
        <div className="app-login-container">
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: .5, duration: 1.3 }}
                className="app-login justify-center"
            >
                <div className="app-login-content">
                    <div className="align-item-center mb-2">
                        <Link to="/signin" className={classes.link}><ArrowBackIcon /></Link>
                        <Hidden only='xs'><h1 className="title-h1 font-weight-500">Recuperar contraseña</h1></Hidden>
                        <Hidden smUp><h1 className="title-h2 font-weight-500">Recuperar contraseña</h1></Hidden>
                    </div>

                    <div className="app-login-form">
                        <h2 className={`text-small font-weight-400 mb-3 ${classes.titleLogin}`}>Ingresa tu email asociado a tu cuenta de WS cargo y te enviaremos las instrucciones para que puedas crear una nueva contraseña.</h2>
                        
                        <form className="input-material-ui" onSubmit={ (e) => {onSubmitForm(e)}}>
                            <InputLabel className="Label_Input_Format">Email</InputLabel>
                            <TextField
                                fullWidth
                                onChange={(event) => setEmail(event.target.value)}
                                defaultValue={email}
                                placeholder="email@wscargo.cl"
                                className="mb-3"
                                onBlur={onBlurInput}
                                error={errors.email && true}
                                helperText={errors.email && errors.email}
                            />        
                            <Button
                                type='submit'
                                className="button button-outline-danger button-100-mobile"
                            >
                                recuperar contraseña
                            </Button>
                        </form>
                    </div>
                </div>
            </motion.div>

            <div className="app-logo-content d-flex align-items-center justify-content-center">
                <Link className="logo-lg" to="/" title="Jambo">
                    <img src={require("../assets/images/wscargologo.png")} alt="jambo" title="jambo"/>
                </Link>
            </div>

            {
               /* loader &&
                <div className="loader-view">
                    <CircularProgress />
                </div>*/
            }
            { /*showMessage && NotificationManager.error(alertMessage)*/}
            {/*<NotificationContainer />*/}

            {/* dialog de confirmación para la creación de usuario */}
            <Dialog
                open={openErrorEmail}
                onClose={handleCloseErrorEmail}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.dialogStyle}
            >
                <DialogTitle id="alert-dialog-slide-title" className="text-center mt-3 mx-0 mx-md-3">{"Recuperar contraseña"}</DialogTitle>

                <DialogContent className="text-center mx-0 mx-md-3">
                    <DialogContentText>
                        El email ingresado no esta registrado en el sistema.
                    </DialogContentText>
                </DialogContent>
                
                <DialogActions className="mb-3">
                    <Button className="button button-danger" onClick={handleCloseErrorEmail}>
                        ok
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.dialogStyle}
            >
                <DialogTitle id="alert-dialog-slide-title" className="text-center mt-3 mx-0 mx-md-3">{"Recuperar contraseña"}</DialogTitle>

                <DialogContent className="text-center mx-0 mx-md-3">
                    <DialogContentText>
                        Has recuperado tu contraseña, revisa la bandeja de entrada de tu correo y sigue las instrucciones.
                    </DialogContentText>
                </DialogContent>
                
                <DialogActions className="mb-3">
                    <Button className="button button-danger" onClick={handleClose}>
                        ok
                    </Button>
                </DialogActions>
            </Dialog>
            {loading && <Loading />}
        </div>
    );
};


export default RecoverPassword;

