import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CheckIn from './SignUp'
import RecoverPassword from './RecoverPassword'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { motion } from "framer-motion"

import { Clear_All_ExpDig_DetalleServicio_Success } from '../../src/modules/detalleServicio/Actions';
import { Clear_All_ExpDig_Dashboar_Success } from '../../src/modules/dashboard/Actions';
import { Clear_All_ExpDig_Notificaciones_Success } from '../../src/modules/notificaciones/Actions';
import { Clear_All_ExpDig_Empresas_Success } from '../../src/modules/empresas/Actions';
import { Clear_All_ExpDig_Importador_Success } from '../../src/modules/empresaImportador/Actions';
import { Clear_All_ExpDig_Cotizador_Success } from '../../src/modules/cotizador/Actions';

import {
  InputLabel,
  Button,
  TextField,
  makeStyles,
  IconButton,
  InputAdornment,
  CircularProgress,
  FormControl,
  FormHelperText,
  Hidden
} from "@material-ui/core";

import {
  hideMessage,
  showAuthLoader,
  clearIsValid,
  clearEmailIsValid,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn,
} from '../actions/Auth.js';
import { AirlineSeatFlat, ContactSupportOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  link: {
    fontSize: "14px",
    color: "#8F8F8F",
    fontWeight: "500",
    "&:hover": {
      color: "#FC565C"
    }
  },
  titleLogin: {
    paddingLeft: "12px",
    margin: "16px 0"
  },
  iconButtom: {
    "&:hover": {
      background: "transparent"
    }
  }
}));


const list = {
  hidden: {
    opacity: 0,
    transition: { when: "afterChildren" }
  }
}

const item = {
  hidden: {
    opacity: 0,
    transition: { duration: 2 }
  }
}


const SignIn = (props) => {
  const classes = useStyles();

  // input pass para mostrar contraseña
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const { loader, alertMessage, showMessage, authUser } = useSelector(({ auth }) => auth);

  dispatch(Clear_All_ExpDig_DetalleServicio_Success());
  dispatch(Clear_All_ExpDig_Dashboar_Success());
  dispatch(Clear_All_ExpDig_Notificaciones_Success());
  dispatch(Clear_All_ExpDig_Empresas_Success());
  dispatch(Clear_All_ExpDig_Importador_Success());
  dispatch(Clear_All_ExpDig_Cotizador_Success());
  
  const [errors, setErrors] = useState({
    error: "errorDefault"
  });
  const [alertMessageLocal, setAlertMessageLocal] = useState('');

  /*useEffect(() => {
      if (showMessage) {
          setTimeout(() => {
              dispatch(hideMessage());
          }, 100);
      }
      if (authUser !== null) {
          props.history.push('/');
      }
  }, [showMessage, authUser, props.history, dispatch]);*/

  useEffect(() => {
    if (showMessage) {
      setAlertMessageLocal(alertMessage);
      dispatch(hideMessage());
    }
    if (authUser !== null) {
      props.history.push('/');
    }
  }, [showMessage, authUser]);

  const SendSingIn = (e) => {
    e.preventDefault();
    setAlertMessageLocal('');
    setErrors(validateForm({ email, password }));
    if (Object.keys(errors).length === 0) {
      dispatch(userSignIn({ email, password }));
    }
  }

  const onBlurInput = () => {
    setErrors(validateForm({ email, password }));
  }

  const validateForm = (form) => {

    let error = {};
    let rgxValidarEmail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    if (!form.email.trim()) {
      error.email = "Campo obligatorio";
    } else if (!rgxValidarEmail.test(form.email)) {
      error.email = "No es un email valido.";
    }

    if (!form.password.trim()) {
      error.password = "Campo obligatorio";
    }

    return error;

  }

  // const container = {
  //   hidden: { opacity: 0 },
  //   show: {
  //     opacity: 1,
  //     transition: {
  //       delayChildren: 0.5,
  //       staggerDirection: -1
  //     }
  //   }
  // }

  // const item = {
  //   hidden: { opacity: 0 },
  //   show: { opacity: 1 }
  // }

  const list = {
    hidden: {
      opacity: 0,
      transition: { when: "afterChildren" }
    }
  }

  const item = {
    hidden: {
      opacity: 0,
      transition: { duration: 2 }
    }
  }

  return (
    <div className="app-login-container">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: .5, duration: 1.3 }}
        className="app-login"
      >
        <div className="app-login-content">
          <h1 className="title-h1 font-weight-600">Bienvenido a WS Cargo</h1>
          <h2 className={`text font-weight-500 ${classes.titleLogin}`}>Iniciar Sesión</h2>
          <form
            className="input-material-ui"
            onSubmit={(e) => SendSingIn(e)}
          >
            <InputLabel className="Label_Input_Format">Email</InputLabel>
            <TextField
              // fullWidth
              onChange={(event) => setEmail(event.target.value)}
              onBlur={onBlurInput}
              placeholder="User"
              defaultValue={email}
              className="mb-4"
              error={errors.email && true}
              helperText={errors.email && errors.email}
            />

            <InputLabel className="Label_Input_Format">
              Contraseña
            </InputLabel>
            <TextField
              type={showPassword ? "text" : "password"}
              //fullWidth
              placeholder="Password"
              onChange={(event) => setPassword(event.target.value)}
              onBlur={onBlurInput}
              defaultValue={password}
              maxLength="16"
              className="mb-3"
              error={errors.password && true}
              //error
              helperText={errors.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                     // onMouseDown={handleMouseDownPassword}
                      className={classes.iconButtom}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {
              alertMessageLocal != '' ? (
                <FormControl error required classsName="mb-3">
                  <FormHelperText>{alertMessageLocal}</FormHelperText>
                </FormControl>
              ) : ''
            }

            <div className="d-flex align-items-center justify-content-between mt-4">
              <Button
                className="button button-outline-danger button-100-mobile"
                //onClick={ SendSingIn }
                type="submit"
                variant="contained"
                color="default"
              >
                Iniciar Sesión
              </Button>

              <Hidden only='xs'>
                <motion.div whileHover={{ scale: 1 }}>
                  <Link
                    to="/signup"
                    className={classes.link}
                    onClick={() => {
                      dispatch(clearEmailIsValid());
                    }}
                  >
                    Crear cuenta
                  </Link>
                  <span className="slash">/</span>
                  {/* importante */}
                  {/* page para ver el diseño de restabecer contraseña, debes activar el link que esta en App.js*/}
                  {/*<Link to="/restore-password" className={classes.link}>Restablecer</Link>*/}
                  <Link
                    to="/recover-password"
                    className={classes.link}
                    onClick={() => { dispatch(clearIsValid()) }}
                  >
                    Recuperar contraseña
                  </Link>
                </motion.div>
              </Hidden>
            </div>

            <Hidden smUp>
              <div className="d-flex align-items-center justify-content-between mt-4">
                <Link to="/signup" className={classes.link}>
                  Crear cuenta
                </Link>
                <Link to="/recover-password" className={classes.link}>
                  Recuperar contraseña
                </Link>
              </div>
            </Hidden>
          </form>
        </div>
      </motion.div>

      <div className="app-logo-content justify-center">
        <Link className="logo-lg" to="/" title="Jambo">
          <img
            src={require("../assets/images/wscargologo.png")}
            alt="jambo"
            title="jambo"
          />
        </Link>
      </div>

      {loader && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}

      {showMessage && NotificationManager.error(alertMessage)}
      {/*<NotificationContainer />*/}
    </div>
  );
};


export default SignIn;
