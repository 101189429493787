import React, { useState } from 'react';
import { Link } from 'react-router-dom'

import {
  Hidden,
  makeStyles,
  Button
} from "@material-ui/core";
// import CheckCircleIcon from '@material-ui/icons/CheckCircle';
// import AddIcon from '@material-ui/icons/Add';
// import RemoveIcon from '@material-ui/icons/Remove';
// import CheckIcon from '@material-ui/icons/Check';
// import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import moment from 'moment';

// const bultosInital = [
//   {
//     fecha: '22/01/2022',
//     descripcion: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
//   },
//   {
//     fecha: '25/01/2022',
//     descripcion: 'It is a long established fact that a reader will be distracted',
//   },
//   {
//     fecha: '2/02/2022',
//     descripcion: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots',
//   },
//   {
//     fecha: '27/02/2022',
//     descripcion: 'There are many variations of passages',

//   },
//   {
//     fecha: '05/03/2022',
//     descripcion: 'text of the printing and typesetting industry. Lorem Ipsum has been the industry',

//   }
// ];

const useStyles = makeStyles((theme) => ({
  prueba: {

    '& .MuiSvgIcon-root': {
      width: "2em !import",
      height: "2em !import"
    }
  },

  listItem: {
    display: "flex",
    flexDirection: "column"
  },
}));

const Historial = (props) => {
  const classes = useStyles();
  const [serviceDetail, setServiceDetail] = useState(null)

  const showDetail = (index) => {
    if (serviceDetail === index) {
      return setServiceDetail(null)
    }
    setServiceDetail(index);
  }

  return (
    <div className="row">
      <div className="col-12 col-lg-12">
        <div className="cards card-list">
          <div className='cards-header'>
            <h4 className="text font-weight-500">Historial de carga</h4>
            <div className="border-title"></div>
          </div>

          <div className="cards-body pt-0">
            {/* Data versión desktop */}
            <Hidden xsDown>
              {
                props.history && props.history.length > 0 ?
                props.history.map((item, index) => (
                  <div className="list_item" index={index}>
                        <div className="list_tittle">
                          <h4 className="text-small font-weight-400">{moment(item.fecha).format('DD/MM/YYYY')}</h4>
                        </div>
                        <div className="list_desciption">
                          <h5 className="text-small font-weight-400">{item.texto}</h5>
                        </div>
                    </div>
                ))
                :
                <div className="list_item" >
                   <h4 className="text-small font-weight-400">Sin historial de carga.</h4>
                </div>
              }
            </Hidden>

            {/* Data versión mobile */}
            <Hidden smUp>
              {
                props.history && props.history.length>0 && props.history.map((item, index) => (
                  <div className={`list_item ${classes.listItem}`} key={index}>
                        <div className="list-data">
                          <h4 className="text-small font-weight-400">{moment(item.fecha).format('DD/MM/YYYY')}</h4>
                        </div>
                        <div className="list-data">
                          <h5 className="text-small font-weight-400">{item.texto}</h5>
                        </div>
                    </div>
                ))
              }
            </Hidden>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Historial