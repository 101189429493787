import React,{useEffect, useState, useRef} from 'react';
import {useDispatch} from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IntlMessages from '../../util/IntlMessages';
import EtiquetaPdf2 from './EtiquetaPdf2';
import ReactToPrint from "react-to-print";

const ModalPropuestaPdf =(props)=>{
    const componentRef = useRef();
    const dispatch = useDispatch();
    return(
        <Dialog
    maxWidth="xl"
    style={{zIndex: 1300}}
    open={props.open}
    onClose={()=>{props.onToggle()}}
    aria-labelledby="form-dialog-title"
    disableEnforceFocus
    disableEscapeKeyDown
    disableBackdropClick
    overflowY='visible'
    toggle={()=>{ }}>
      <DialogTitle
      style={{height:60}}
      id="customized-dialog-title"
      onClose={()=>{props.onToggle()}}
      className="modal-box-header bg-primary Form_Cabecera text-white"
      >
        <ReactToPrint 
        trigger={() => {
        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
        // to the root node of the returned component as it will be overwritten.
        return  <div className="row mt-0"><div className="BotonPrimary">IMPRIMIR</div></div>
        }}
        content={() => componentRef.current}
        />
        {/* <IntlMessages id="label.descargarPdf"/> */}
        <IconButton className="text-white" onClick={()=>{props.onToggle()}}>
            <CloseIcon/>
        </IconButton>
      </DialogTitle>
          <DialogContent ref={componentRef} style={{backgroundColor:'white'}} className="p-0">
             <EtiquetaPdf2 />
          </DialogContent>
    </Dialog>
    )
};
export default ModalPropuestaPdf;