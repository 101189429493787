import{
    PAGONLINE_GET_DETALLE_PAGO,
    PAGONLINE_GET_DETALLE_PAGO_SUCCESS,
} from './Constants';

export const PAGONLINE_Get_Detalle_Pago = (payload) => {
    return {
        type: PAGONLINE_GET_DETALLE_PAGO,
        payload: payload
    };
};

export const PAGONLINE_Get_Detalle_Pago_Success= (payload) => {
    return {
        type: PAGONLINE_GET_DETALLE_PAGO_SUCCESS,
        payload: payload
    };
};