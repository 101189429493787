import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/Help';
import { helpHttp } from '../helpers/helpHttp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import LaunchIcon from '@material-ui/icons/Launch';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CardOne from '../stepper/One'
import CardTwo from '../stepper/Two'
import { Theme, createStyles } from '@material-ui/core/styles';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StepConnector from '@material-ui/core/StepConnector';

import {
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    InputLabel,
    TextField,
    Tooltip,
    InputAdornment,
    FormGroup,
    FormControlLabel,
    Button,
    Checkbox,
    Typography,
    IconButton,
    Avatar,
    Stepper,
    Step,
    StepLabel

} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    listItem: {
        display: "flex",
        flexDirection: "column"
    },
}));

const initailNotificaciones = [
    { titulo_notificacion: 'Pago del servicio atrasado', descripcion: '', link: '' },
    { titulo_notificacion: 'Documentación faltante del cliente', descripcion: 'Cédula de identidad', link: '' },
    { titulo_notificacion:'Documentación del cliente rechazada', descripcion:'Verificar - Cédula de identidad', link:''},
    { titulo_notificacion:'Documentación del proveedor', descripcion:'El "nombre" no ha sido aprobada, favor verificar - Packing List', link:''},
    { titulo_notificacion:'Factura del servicio disponible para descarga', descripcion:'', link:''},
]

const AccionRequerida = ({ openAccion, handleCloseAccion }) => {
    const classes = useStyles();

    return (
        <Dialog open={openAccion} onClose={handleCloseAccion} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className="mt-4 pb-1">Acción Requerida</DialogTitle>
            <DialogContent className="my-4 pb-3 px-0 px-md-4">
                <DialogContentText className="px-4 px-md-0">
                    Estimado, para poder avanzar en tu importación, debes resolver los siguiente items.
                </DialogContentText>

                {
                    initailNotificaciones.map((item, index) => (
                        <div className={`list_item ${classes.listItem}`} index={index}>
                            <div className="list-data justify-space-between">
                                <h4 className="text-small font-weight-400 mr-2">{item.titulo_notificacion}</h4>

                                <IconButton
                                    // component={Link} to={`/app/atrina-perfil/${item.id}`}
                                    aria-label="abrir notificacion"
                                    className="icon-button icon-button-small"
                                >
                                    <LaunchIcon />
                                </IconButton>

                            </div>
                            <div className="list-data">
                                <h5 className="text-xs font-weight-400">{item.descripcion}</h5>
                            </div>
                        </div>
                    ))
                }

                <div className="justify-end mt-4 px-4 px-md-0">
                    <Button
                        className='button button-danger button-small button-100-mobile'
                        onClick={handleCloseAccion}
                    >
                        Cancelar
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )

}

export default AccionRequerida