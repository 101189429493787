export const SHOW_MESSAGE_EMPRESA_IMPORTADOR='SHOW_MESSAGE_EMPRESA_IMPORTADOR';
export const HIDE_MESSAGE_EMPRESA_IMPORTADOR='HIDE_MESSAGE_EMPRESA_IMPORTADOR';
export const GLOBAL_CHANGE_STATES_EMPRESA_IMPORTADOR='GLOBAL_CHANGE_STATES_EMPRESA_IMPORTADOR';
export const CLEAN_STATES_EMPRESA_IMPORTADOR='CLEAN_STATES_EMPRESA_IMPORTADOR';
export const GET_REI1='GET_REI1';
export const GET_REI1_SUCCESS='GET_REI1_SUCCESS';
export const GET_REI2='GET_REI2';
export const GET_REI2_SUCCESS='GET_REI2_SUCCESS';
export const GET_REI3='GET_REI3';
export const GET_REI3_SUCCESS='GET_REI3_SUCCESS';
export const POST_REI4='POST_REI4';
export const PUT_REI5='PUT_REI5';
export const PUT_REI5_SUCCESS='PUT_REI5_SUCCESS';
export const GET_REI6='GET_REI6';
export const GET_REI6_SUCCESS='GET_REI6_SUCCESS';
export const GET_REI7='GET_REI7';
export const GET_REI7_SUCCESS='GET_REI7_SUCCESS';
export const PUT_REI8='PUT_REI8';
export const RUT_EXISTE='RUT_EXISTE';
export const RUT_EXISTE_SUCCESS='RUT_EXISTE_SUCCESS';
export const EMAIL_EXISTE='EMAIL_EXISTE';
export const EMAIL_EXISTE_SUCCESS='EMAIL_EXISTE_SUCCESS';
export const LIST_MOSTRAR='LIST_MOSTRAR';
export const LIST_OCULTAR='LIST_OCULTAR';
export const GET_ROLES_AGREGAR_CONTACTO = 'GET_ROLES_AGREGAR_CONTACTO';
export const GET_ROLES_AGREGAR_CONTACTO_SUCCESS = 'GET_ROLES_AGREGAR_CONTACTO_SUCCESS';
export const GET_MODULOS = 'GET_MODULOS';
export const GET_MODULOS_SUCCESS = 'GET_MODULOS_SUCCESS';
export const POST_ROLES = 'POST_ROLES';
export const POST_ROLES_SUCCESS = 'POST_ROLES_SUCCESS';
export const PUT_ROLES = 'PUT_ROLES';
export const PUT_ROLES_SUCCESS = 'PUT_ROLES_SUCCESS';
export const DELETE_ROLES = 'DELETE_ROLES';
export const DELETE_ROLES_SUCCESS = 'DELETE_ROLES_SUCCESS';
export const VALIDATE_NAME_ROLES = 'VALIDATE_NAME_ROLES';
export const VALIDATE_NAME_ROLES_SUCCESS = 'VALIDATE_NAME_ROLES_SUCCESS';

export const CLEAR_ALL_EXPDIG_IMPORTADOR_SUCCESS='CLEAR_ALL_EXPDIG_IMPORTADOR_SUCCESS';