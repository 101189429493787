import React from 'react';
import Form from './index.js';
import IntlMessages from '../../../util/IntlMessages';

// class atrinaDashBoard extends React.Component (){

//   render({setStep}) {
//     return (
//       <div className="app-wrapper">
//         <Form setStep={setStep} match={this.props.match} title={<IntlMessages id="label.atrina"/>}/>
//       </div>
//     );
//   }
// }

// cons

// export default atrinaDashBoard;


const Routes = ({setStep, match}) => {
  return (
    <div className="app-wrapper">
        <Form setStep={setStep} match={match} title={<IntlMessages id="label.atrina"/>}/>
    </div>
  )
}

export default Routes