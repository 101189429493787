import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
// import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import IntlMessages from 'util/IntlMessages';

import {
    InputLabel,
    Button,
    TextField,
    makeStyles,
    IconButton,
    InputAdornment,
    // CircularProgress,
    FormControl,
    FormHelperText,
    // Hidden,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";

import {
    putPassword
} from '../actions/Auth.js';

const useStyles = makeStyles((theme) => ({
    link: {
        fontSize: "14px",
        color: "#263238",
        fontWeight: "500",
        marginRight: "16px",
        "&:hover": {
            color: "#8F8F8F"
        }
    },
    titleLogin: {
        paddingLeft: "12px",
        marginBottom: "16px"
    },
    dialogStyle: {
        color: "RED",
        "& .MuiDialogActions-root ": {
            justifyContent: "center",

        }
    },
    iconButtom: {
        "&:hover": {
            background: "transparent"
        }
    }
}));

const RestorePassword = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const { passwordIsRestore } = useSelector(({ auth }) => auth);
    // input pass para mostrar contraseña
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);

    // mostrar contraseña
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const handleClickShowRepeatPassword = () => setShowRepeatPassword(!showRepeatPassword);
    const handleMouseDownRepeatPassword = () => setShowRepeatPassword(!showRepeatPassword);

    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [repetPassword, setRepetPassword] = useState('');
    const [errors, setErrors] = useState({
        error:"errorDefault"
    });
    const [passwordIsRestoreLocal, setPasswordIsRestoreLocal] = useState({});
   
    /*useEffect(() => {
        if (showMessage) {
            setTimeout(() => {
                dispatch(hideMessage());
            }, 100);
        }
        if (authUser !== null) {
            props.history.push('/');
        }
    }, [showMessage, authUser, props.history, dispatch]);*/

    useEffect(() => {
       
        if(passwordIsRestore.success){
            setPassword('');
            setRepetPassword('');
            setOtp('')
            setErrors({
                error:"errorDefault"
            })
            //setPasswordIsRestoreLocal(passwordIsRestore);
            setOpen(true);
        }else{
            setPasswordIsRestoreLocal(passwordIsRestore);
        }
    }, [passwordIsRestore]);

    const onBlurInput = () => {
        setErrors(validateForm({ otp, password, repetPassword }));
    }

    const validateForm = (form) =>{
        let { otp, password, repetPassword } = form;
        let error = {};
        let rgxValidarEmail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        let rgxMayusculas = /([A-Z])/;
        let rgxMinusculas = /([a-z])/; 
        let rgxNumero = /([0-9])/; 
        let rgxCaracteresEspeciales = /[\@\#\$\%\^\&\*\(\)\_\+\!\'\"]/;
        let rgxNumeroCaracteres = /.{8,}/;
        
        if(!otp.trim()){
            error.otp = "Campo obligatorio";
        }else if(parseInt(otp.toString().length) != 6 ){
            error.otp = "El codigo no es valido";
        }
        if(!password.trim()){
            error.password = "Campo obligatorio";
        }else if(!rgxMayusculas.test(password)){
            error.password = "Debe tener Mayuscula.";
        }else if(!rgxMinusculas.test(password)){
            error.password = "Debe tener Minuscula.";
        }else if(!rgxNumero.test(password)){
            error.password = "Debe tener Numeros.";
        }else if(rgxCaracteresEspeciales.test(password)){
            error.password = "No debe tener \@\#\$\%\^\&\*\(\)\_\+\!\'\".";
        }else if(!rgxNumeroCaracteres.test(password)){
            error.password = "Debe ser de minimo 8 caracteres.";
        }

        if(!repetPassword.trim()){
            error.repetPassword = "Campo obligatorio";
        }else if( password != repetPassword){
            error.repetPassword = "Las contraseñas deben ser iguales";
        }

        return error;
    }

    //  dialog de confirmación 
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const onSubmitForm = (e) => {
        e.preventDefault();
        setPasswordIsRestoreLocal({})
        setErrors(validateForm({otp, password, repetPassword}));

        if(Object.keys(errors).length === 0 ){
            dispatch(putPassword({otp, password, repetPassword}));
        }
    }

    const handleClose = () => {
        setOpen(false);
        history.push("/signin");
    };

    return (
        <div className="app-login-container">

            <div className="app-login justify-center">
                <div className="app-login-content">
                    <div className="login-header mb-3">
                        <h1 className="title-h1 font-weight-600">Nueva Contraseña</h1>
                    </div>
                    
                    <div className="app-login-form">
                        <h2 className={`text-small font-weight-400 mb-3 ${classes.titleLogin}`}>Estimado(a), debes ingresar tu nueva contraseña</h2>
                        
                        <form className="input-material-ui" onSubmit={(e) => {onSubmitForm(e)}}>
                            <fieldse >
                                <InputLabel className="Label_Input_Format">Ingrese el codigo enviado a su email</InputLabel>
                                <TextField
                                    type="number"
                                    fullWidth
                                    placeholder="Codigo"
                                    onChange={(event) => setOtp(event.target.value)}
                                    value={otp}
                                    defaultValue=""
                                    maxLength="6"
                                    className="mb-3"
                                    onBlur={onBlurInput}
                                    error={errors.otp && true}
                                    helperText={errors.otp && errors.otp}

                                />

                                <InputLabel className="Label_Input_Format">Nueva Contraseña</InputLabel>
                                <TextField
                                    type={showPassword ? "text" : "password"}
                                    fullWidth
                                    placeholder="Contraseña"
                                    onChange={(event) => setPassword(event.target.value)}
                                    value={password}
                                    defaultValue=""
                                    maxLength="16"
                                    className="mb-3"
                                    onBlur={onBlurInput}
                                    error={errors.password && true}
                                    helperText={errors.password && errors.password}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    className={classes.iconButtom}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <InputLabel className="Label_Input_Format">Repetir Contraseña</InputLabel>
                                <TextField
                                    id="repetir_contraseña"
                                    name="repetir_contraseña"
                                    placeholder="Repetir Contraseña"
                                    defaultValue=""
                                    type={showRepeatPassword ? "text" : "password"}
                                    onChange={(event) => setRepetPassword(event.target.value)}
                                    value={repetPassword}
                                    onBlur={onBlurInput}
                                    error={errors.repetPassword && true}
                                    helperText={errors.repetPassword && errors.repetPassword}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowRepeatPassword}
                                                    onMouseDown={handleMouseDownRepeatPassword}
                                                    className={classes.iconButtom}
                                                >
                                                    {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                {
                                    Object.keys(passwordIsRestoreLocal).length > 0 ? (
                                        <FormControl error required>
                                            <FormHelperText>{passwordIsRestoreLocal.message}</FormHelperText>
                                        </FormControl> 
                                    ): ''
                                }

                                <h3 className="title-h5 font-weight-500 mt-4">Tu Contraseña debe tener:</h3>
                                <div className='d-flex align-items-center'>
                                    <p className='text-small'>Mayúscula</p>
                                    <span className='slash'>/</span>
                                    <p className='text-small'>Minuscula</p>
                                    <span className='slash'>/</span>
                                    <p className='text-small'>Números</p>
                                    <span className='slash'>/</span>
                                    <p className='text-small'>Min. 8 caract</p>
                                </div>
                                <Button
                                    //onClick={() => handleClickOpen()}
                                    type="submit"
                                    className="button button-outline-danger button-100-mobile mt-4"
                                >
                                    Confirmar
                                </Button>
                            </fieldse>
                        </form>
                    </div>
                </div>
            </div>
        
            <div className="app-logo-content justify-center">
                <Link className="logo-lg" to="/" title="Jambo">
                    <img src={require("../assets/images/wscargologo.png")} alt="jambo" title="jambo" />
                </Link>
            </div>

            

            {/* dialog de confirmacion */}
             <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.dialogStyle}
            >
                <DialogTitle id="alert-dialog-slide-title" className="text-center mt-3 mx-0 mx-md-3">{"¡Excelente!"}</DialogTitle>

                <DialogContent className="text-center mx-0 mx-md-3">
                    <DialogContentText>
                        Has modificado con éxito tu contraseña, ahora te redirigiremos a la página principal para que puedas iniciar sesión.
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="mb-3">
                        <Button 
                            className="button button-danger" 
                            onClick={handleClose}
                            component={Link} 
                            to="/signin"
                        >
                            ok
                        </Button>
                    </DialogActions>
            </Dialog> 
        </div>
    );
};


export default RestorePassword;
