import {
    HIDE_MESSAGE,
    INIT_URL,
    ON_HIDE_LOADER,
    ON_SHOW_LOADER,
    SHOW_MESSAGE,
    SIGNIN_FACEBOOK_USER_SUCCESS,
    SIGNIN_GITHUB_USER_SUCCESS,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_TWITTER_USER_SUCCESS,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS,
    SIGNUP_USER_SUCCESS,
    VALIDATE_EMAIL_SUCCESS,
    CLEAR_IS_VALID,
    PUT_PASSWORD_SUCCESS,
    GET_REGIONES_REGISTRAR_CLIENTE_SUCCESS,
    GET_COMUNAS_REGISTRAR_CLIENTE_SUCCESS,
    POST_REGISTRAR_CLIENTE_EMPRESA_SUCCESS,
    POST_REGISTRAR_CLIENTE_PERSONA_SUCCESS,
    CLEAR_REGISTROS,
    GET_ACTIVAR_ACCOUNT_SUCCESS,
    CLEAR_IS_ACTIVE,
    ACTIVE_LOADING,
    DEACTIVE_LOADING,
    VALIDATE_EMAIL_REGISTRO_CLIENTE_SUCCESS,
    CLEAR_EMAIL_IS_VALID,
    GET_COMERCIALES_REGISTRAR_CLIENTES_SUCCESS,
    POST_RESERVE_ID_REGISTRAR_CLIENTES_SUCCESS,
    SET_GLOBAL_COMERCIALES_AUTH,
    VALIDATE_RUT_REGISTRO_CLIENTE_SUCCESS,
    SHOW_LOADING_RESTORE_PASSWORD,
    SET_ERROR_EMAIL,
} from "../constants/ActionTypes";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    authUser: localStorage.getItem('user_id'),
    emailIsValid:{},
    passwordIsRestore:{},
    regiones:[],
    comunas:[],
    registroEmpresa:{},
    registroPersona:{},
    isActive:{},
    emailIsValid:{},
    comerciales:[],
    reserveId:{},
    comercialesList:[],
    rutExiste:{},
    roles: JSON.parse(localStorage.getItem('userRoles')),
    loadingRestorePassword:0,
    ErrorEmail:false,
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
       
        case SET_ERROR_EMAIL:{
            return{
                ...state,
                ErrorEmail:action.payload
            }
        }

        case SHOW_LOADING_RESTORE_PASSWORD:{
            return{
                ...state,
                loadingRestorePassword:action.payload
            }
        }

        case SIGNUP_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                initURL: '/',
                authUser: action.payload.authUser,
                roles: action.payload.roles
            }
        }
        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            return {
                ...state,
                authUser: null,
                initURL: '/app/inicio',
                loader: false,
                roles:[]
            }
        }

        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }

        case SIGNIN_GOOGLE_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_FACEBOOK_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_TWITTER_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGNIN_GITHUB_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case ON_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case ON_HIDE_LOADER: {
            return {
                ...state,
                loader: false
            }
        }
        case VALIDATE_EMAIL_SUCCESS: {
            return {
                ...state,
                emailIsValid: action.payload
            }
        }
        case CLEAR_IS_VALID: {
            return {
                ...state,
                emailIsValid: {}
            }
        }
        case PUT_PASSWORD_SUCCESS: {
            return {
                ...state,
                passwordIsRestore: action.payload
            }
        }
        case GET_REGIONES_REGISTRAR_CLIENTE_SUCCESS: {
            return {
                ...state,
                regiones: action.payload
            }
        }
        case GET_COMUNAS_REGISTRAR_CLIENTE_SUCCESS: {
            return {
                ...state,
                comunas: action.payload
            }
        }
        case POST_REGISTRAR_CLIENTE_EMPRESA_SUCCESS: {
            return {
                ...state,
                registroEmpresa: action.payload
            }
        }
        case POST_REGISTRAR_CLIENTE_PERSONA_SUCCESS: {
            return {
                ...state,
                registroPersona: action.payload
            }
        }
        
        case CLEAR_REGISTROS: {
            return {
                ...state,
                registroPersona: {},
                registroEmpresa: {}
            }
        }

        case GET_ACTIVAR_ACCOUNT_SUCCESS: {
            return {
                ...state,
                loading: false,
                isActive: action.payload
            }
        }

        case ACTIVE_LOADING: {
            return {
                ...state,
                loading: true,
            }
        }

        case DEACTIVE_LOADING: {
            return {
                ...state,
                loading: false,
            }
        }

        case VALIDATE_EMAIL_REGISTRO_CLIENTE_SUCCESS: {
            return {
                ...state,
                emailIsValid: action.payload,
            }
        }

        case CLEAR_EMAIL_IS_VALID: {
            return {
                ...state,
                emailIsValid: false,
            }
        }

        case GET_COMERCIALES_REGISTRAR_CLIENTES_SUCCESS: {
            return {
                ...state,
                comerciales: action.payload,
            }
        }

        
        case POST_RESERVE_ID_REGISTRAR_CLIENTES_SUCCESS: {
            return {
                ...state,
                reserveId: action.payload,
            }
        }

        case SET_GLOBAL_COMERCIALES_AUTH:{
            return{
                ...state,
                comercialesList:action.payload
            }
        }

        case VALIDATE_RUT_REGISTRO_CLIENTE_SUCCESS:{
            return{
                ...state,
                rutExiste:action.payload
            }
        }

        case CLEAR_IS_ACTIVE:{
            return{
                ...state,
                initURL: '/',
                isActive:{}
            }
        }

        default:
            return state;
    }
}
