import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    IconButton,
    makeStyles,
    Button,
    InputLabel,
    TextField,
    InputAdornment,
    FormHelperText
} from "@material-ui/core";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Alert from '@material-ui/lab/Alert';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import MomentUtils from "@date-io/moment";
import {Paper, Grid} from "@material-ui/core";
import moment from "moment";
import "moment/locale/es";
import { payloadServicioDespachoRetiro } from '../../models';

import {
    postDespachoRetiroSolicAgendamiento,
    setMessageSolicAgendamiento,
    getDisponibilidadDespachoSolicAgendamiento
} from './Actions';
import lodash, { fromPairs } from 'lodash';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { findFont } from '@amcharts/amcharts4/.internal/core/utils/DOM';
import { addDays } from 'date-fns';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from "date-fns/locale/es";
registerLocale('es', es);


const useStyles = makeStyles((theme) => ({

    listItem: {
        display: "flex",
        flexDirection: "column"
    },

    border: {
        borderLeft: "solid 6px  #EA5259"
    },
    border2: {
        borderLeft: "solid 6px #F8C96A"
    },
    borderRight: {
        borderRight: "solid 1px rgba(0, 0, 0, 0.103)",
    },
    icon: {
        fontSize: "28px",
        color: "8F8F8F",
        marginRight: "8px"
    },
    contentAvatar: {
        width: "100%"
    },
    notInThisMonthDayPaper: {
        width: "35px",
        height: "35px",
        backgroundColor: "#eeeeee",
        margin: "3px",
        boxShadow: "none",
        borderRadius: "10%",
        padding: "1px",
    },
    normalDayPaper: {
        width: "35px",
        height: "35px",
        backgroundColor: "#e8f5e9",
        margin: "3px",
        boxShadow: "none",
        borderRadius: "10%",
        padding: "1px",
        cursor: "pointer",
    },
    enabledDayPaper: {
        width: "35px",
        height: "35px",
        backgroundColor: "#2BD82B",
        margin: "3px",
        boxShadow: "none",
        borderRadius: "10%",
        padding: "1px",
        cursor: "pointer",
    },
    blockedDayPaper: {
        width: "35px",
        height: "35px",
        backgroundColor: "#FD564F",
        margin: "3px",
        boxShadow: "none",
        borderRadius: "10%",
        padding: "1px",
        cursor: "pointer",
    },
    warningDayPaper: {
        width: "35px",
        height: "35px",
        backgroundColor: "#F0F924",
        margin: "3px",
        boxShadow: "none",
        borderRadius: "10%",
        padding: "1px",
        cursor: "pointer",
    },
    selectedDayPaper: {
        width: "35px",
        height: "35px",
        backgroundColor: "#1B8FEA",
        margin: "3px",
        boxShadow: "none",
        borderRadius: "10%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderColor: "#1B8FEA",
        padding: "1px",
        cursor: "pointer",
        color:"white"
    },
    todayPaper: {
        width: "35px",
        height: "35px",
        backgroundColor: "#46BD02",
        margin: "3px",
        boxShadow: "none",
        borderRadius: "10%",
        padding: "1px",
        cursor: "pointer",
        color: " white",
    },
    weekendDayPaper:{
        width: "35px",
          height: "35px",
          backgroundColor: "#D8D8D8",
          margin: "3px",
          boxShadow: "none",
          borderRadius: "10%",
          padding: "1px",
          cursor: "pointer",
      }
}));


const initialForm = {
    fk_region: '',
    fk_comuna: '',
    direccion: '',
    numero: '',
    fecha: moment(),
    fk_direcciones: '',
    observaciones: '',
    preagendamiento:false
}


// moment(new Date())
//       .format("YYYY-MM-DD")
//       .toString()
const ComunasNoIncluidasStgo=[49,50,51,53,57,59,61,62,64,65,66,69,71,72,76,82,88,91];

export const FormDespacho = ({ setLoading, setResponse, direcciones, fk_servicio, regiones, comunas, fk_cliente, retiroDespacho, comerciales, fk_comercial, visible,config_despacho,bloqueos,blocked, selectedItem, config_retiro }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [localComunas, setLocalComunas] = useState(comunas);
    const [localDirecciones, setLocalDirecciones] = useState(direcciones);
    const [valueDireccion, setValueDireccion] = useState('');
    const [inputValueDireccion, setInputValueDireccion] = useState('');
    const [valueRegion, setValueRegion] = useState('');
    const [inputValueRegion, setInputValueRegion] = useState('');
    const [valueComuna, setValueComuna] = useState('');
    const [inputValueComuna, setInputValueComuna] = useState('');
    const [selectedComercial, setSelectedComercial] = useState(null);
    const [form, setForm] = useState(initialForm);
    const [openInfoAdicional, setOpenInfoAdicional] = useState(false);
    const [nuevaDireccion, setNuevaDireccion] = useState(false);
    const [regionNoPermitida,setRegionNoPermitida]=useState(false);
    const [fechaMinima,setFechaMinima]=useState(moment());
    const [errors, setErrors] = useState({
        error: "errorDefault"
    });


    console.log("localDirecciones", localDirecciones)

    useEffect(() => {
        setLocalComunas(comunas);
    }, [comunas]);

    useEffect(()=>{
        handleReset()
    },[visible])
    

    useEffect(() => {
        if (comerciales.length > 0 && fk_comercial > 0) {
            let find = comerciales.find(x => x.id == fk_comercial);
            if (find) {
                setSelectedComercial(find);
            }
        }
    }, [comerciales, fk_comercial]);

    useEffect(() => {
        let arr = [];
        if (direcciones && direcciones.length > 0) {
            direcciones.map(function (item) {
                //if (item.fk_tipo == 2) {
                    arr.push(item);
               // }
            })
        }
        setLocalDirecciones(arr);
    }, [direcciones]);



    const handleChange = (e) => {
        const { name, value } = e.target;
        setForm({
            ...form,
            [name]: value,
        })
    }


    // const [inputValue2, setInputValue2] = React.useState('');
    const [selectedDate, setDate] = useState(moment().format("YYYY-MM-DD"));
    const [startDate, setStartDate] = useState(null);
  
    const isWeekday = (date) => {
      const day = date.getDay();
      return day !== 0 && day !== 6;
    };

    const getMinDate = () => {
        const day = new Date().getDay();
        if (day == 5) {
            return addDays(new Date(), 4)
        } else if (day == 4) {
            return addDays(new Date(), 3)
        } else {
            return addDays(new Date(), 2)
        }
    };


    const postDataDespacho = () => {
        const payload = lodash.cloneDeep(payloadServicioDespachoRetiro);
        setErrors(validationsForm(form));
        if (Object.keys(errors).length === 0) {
            
            payload.fecha = moment(form.fecha).format('YYYYMMDD HHmmss');
            payload.fk_servicio = parseInt(selectedItem.fk_servicio);
            payload.fk_cliente = selectedItem.fk_cliente;
            payload.fk_consolidado=selectedItem.id;
            payload.fk_proforma=selectedItem.fk_proforma;
            payload.observaciones = form.observaciones;

            if (valueDireccion != '' && valueDireccion.id) {
                payload.fk_registro_direccion = valueDireccion.id;
                payload.nueva_direccion = null;
            } else {
                payload.nueva_direccion = form;
                payload.nueva_direccion={
                    ...payload.nueva_direccion,
                    fk_comuna:valueComuna.id
                }
                payload.fk_registro_direccion = null;
            }

           if(regionNoPermitida==true){
            payload.preagendamiento=true;
            dispatch(setMessageSolicAgendamiento({show:true,message:{type:'warning',text:'SU AGENDAMIENTO ES REFERENCIAL Y SERÁ VALIDADO FINALMENTE CON SU EJECUTIVO DE CUENTA'}}));
           }else{
            payload.preagendamiento=false;
           }
           setTimeout(() => {setLoading(true);
            if (retiroDespacho != null) {
                payload.id = retiroDespacho.id;
                //dispatch(putServicioDespachoRetiroDetalleServicio(payload));
                handleReset();
            } else {
                dispatch(postDespachoRetiroSolicAgendamiento(payload));
                handleReset()
            }
        }, payload.preagendamiento ? "3000":"0");
           
        }

        // if (valueDireccion == '' && (valueRegion == '' || valueComuna == '' || form.direccion.length == 0 || form.length == 0)) {
        //     dispatch(dispatch(showMessageDetalleServicio({ type: 'error', text: 'Faltan campos requeridos' })));
        // } else {

        //     payload.fecha = moment(form.fecha).format('YYYYMMDD HHmmss');
        //     payload.fk_servicio = parseInt(fk_servicio);
        //     payload.fk_cliente = fk_cliente;
        //     payload.observaciones = form.observaciones;
        //     if (valueDireccion != '' && valueDireccion.id) {
        //         payload.fk_registro_direccion = valueDireccion.id;
        //         payload.nueva_direccion = null;
        //     } else {
        //         payload.nueva_direccion = form;
        //         payload.fk_registro_direccion = null;
        //     }
        //     if (retiroDespacho != null) {
        //         payload.id = retiroDespacho.id;
        //         dispatch(putServicioDespachoRetiroDetalleServicio(payload));
        //         handleReset()
        //     } else {
        //         dispatch(postServicioDespachoRetiroDetalleServicio(payload));
        //         handleReset()
        //     }
        // }
    }

    const openNuevaDireccion = () => {
        setNuevaDireccion(!nuevaDireccion);
    }

    const validationsForm = (form) => {
        const {
            fk_region,
            fk_comuna,
            direccion,
            fecha,
            fk_direcciones,
            numero
        } = form;
        let errors = {}
        var rgxSoloNumeros = /^[0-9]+$/;

        if (!fk_direcciones.toString().trim()) {
            errors.fk_direcciones = "Campo obligatorio";
            if (!fk_region.toString().trim() || !fk_comuna.toString().trim() || !direccion.trim()) {
                errors.fk_region = "Campo obligatorio";
                errors.fk_comuna = "Campo obligatorio";
                errors.direccion = "Campo obligatorio";

            } 

            if (!numero.trim()) {
                errors.numero = "Campo obligatorio";
            } else if (!rgxSoloNumeros.test(numero)) {
                errors.numero = "Solo numeros";
            } else {
                errors = {};
            }
        }

        // if (!fecha.trim()) {
        //     errors.fecha = "Campo obligatorio";
        // } else if (fecha < moment().add(48, 'h').format('YYYY-MM-DD')) {
        //     errors.fecha = "Fecha debe ser mayor a 48hrs";
        // }

        return errors;
    }

    const handleBlur = (e) => {
        handleChange(e)

        setErrors(validationsForm(form));
    }

    const handleReset = () => {
        // setFormContactoCliente(initialForm)
        setForm(initialForm);
        setValueDireccion('');
        setOpenInfoAdicional(false)
        setNuevaDireccion(false)
        setValueRegion('')
        setInputValueRegion('')
        setErrors({ error: "errorDefault" })
        setDate(moment().format('YYYY-MM-DD'))
        setInputValueComuna('')
        setValueComuna('')
        // setInputValue(moment().add(48, 'h').format('YYYYMMDD'))
        setStartDate(null)
    }

    useEffect(() => {
        if(startDate !== null){
          setForm({ ...form, fecha: startDate });
        }
        setErrors(validationsForm(form));
    }, [startDate]);

    const dateFormatter = str => {
        return str;
    };

    const onChangeMonth=(value)=>{
        dispatch(getDisponibilidadDespachoSolicAgendamiento({fecha:moment(value).format('YYYYMMDD')}))
    }

    const getDayElement=(day, selectedDate, isInCurrentMonth, dayComponent) =>{
        //generate boolean 
        const today = new Date(); // just Date object of today
       // const sunnyDays = [1, 6, 10, 24, 15]
        //const isSunny = sunnyDays.includes(day.getDate()); 
        const isSelected = day.getDate() === selectedDate.getDate();
        const isToday = day.getDate() === today.getDate() && day.getMonth() === today.getMonth();

        let dateTile
        if (isInCurrentMonth) { 
            let porc=0;
            let dayx=day.getDate()<10 ? '0'+day.getDate():day.getDate();
            let month=day.getMonth()<10 ? '0'+(parseInt(day.getMonth())+1):parseInt(day.getMonth())+1;
            let dateString=dayx+'-'+month;
            let find=bloqueos.find(x=>x.fecha==dateString);
            let dispDay=0;
            if(find){
                porc=(parseFloat(find.servicios)*100)/parseInt(find.disponibilidad);
                dispDay=parseInt(find.disponibilidad)-parseFloat(find.servicios);
            }

            
            porc=parseFloat(porc);
            if(isSelected){
                dateTile = (
                    <Paper className={classes.selectedDayPaper}>   
                      <Grid item> {day.getDate()}</Grid>
                  </Paper>)
            }else{
                if(blocked){
                    let find=blocked.findIndex(x=>day.getDay()==x);
                    if(find>=0){
                        dateTile = (
                            <Paper className={classes.weekendDayPaper}>   
                            < Grid item> {day.getDate()}</Grid>
                          </Paper>)
                    }else{
                        if(today>day){
                            dateTile = (
                              <Paper className={classes.weekendDayPaper}>   
                              < Grid item> {day.getDate()}</Grid>
                            </Paper>)
                          }else{
                            if(dispDay<selectedItem.volumen){
                                dateTile = (
                                    <Paper className={classes.weekendDayPaper}>   
                                    < Grid item> {day.getDate()}</Grid>
                                  </Paper>)
                            }else{
                                if(porc>=0 && porc<=70){
                                    dateTile = (
                                        <Paper className={classes.enabledDayPaper}>   
                                        <Grid item> {day.getDate()}</Grid>
                                    </Paper>)
                                }else if(porc>70 && porc<=99.99){
                                    dateTile = (
                                        <Paper className={classes.warningDayPaper}>   
                                        
                                        <Grid item> {day.getDate()}</Grid>
                                    </Paper>)
                                }else{
                                    dateTile = (
                                        <Paper className={classes.blockedDayPaper}>   
                                        <Grid item> {day.getDate()}</Grid>
                                    </Paper>)
                                }
                            }
                                
                    }
                    }
                }
                
            }
               /* dateTile = (
                  <Paper className={isSelected ? classes.selectedDayPaper : isToday ? classes.todayPaper : classes.blockedDayPaper}>   
                  
                    <Grid item> {day.getDate()}</Grid>
                </Paper>)*/
        } else {
            dateTile = (<Paper className={classes.notInThisMonthDayPaper}>
                <Grid item><br/></Grid>
                <Grid item style={{color: "lightGrey"}}>
                    {day.getDate()}
                </Grid>
            </Paper>)
        }
        return dateTile
    }

    function disableWeekends(date) {
        if(blocked){
            let find=blocked.findIndex(x=>date.getDay()==x);
            if(find>=0){
                return true;
            }else{
                return false;
            }
        }
       // return date.getDay() === 0 || date.getDay() === 6;
    }

    const onDateChange = (value) => {

        let aux=moment(value).format('DD-MM');
        let aux2=moment(value);
        let today=moment();
        let diff=aux2.diff(today, 'days'); 
        let next=true;
      /*  if(valueDireccion!='' && valueDireccion.id){
            let findIndex=direcciones.findIndex(x=>x.id==valueDireccion.id);
            if(findIndex>=0){
                let aux=direcciones[findIndex];
                if(aux.fk_region!=12){
                    if(diff<=config_despacho.dias_no_regiones){
                        dispatch(showMessageEntregaExpress({type:'error',text:'NO ES POSIBLE AGENDAR EN DICHA FECHA, YA QUE DEBE SER DESPUÉS DE '+config_despacho.dias_no_regiones+' DÍAS A LA FECHA DE HOY'}));
                        next=false;
                    }
                }else{
                    let findIndex=ComunasNoIncluidasStgo.findIndex(x=>x==aux.fk_comuna);
                    if(findIndex>=0){
                        if(diff<=config_despacho.dias_no_stgo){
                            dispatch(showMessageEntregaExpress({type:'error',text:'NO ES POSIBLE AGENDAR EN DICHA FECHA, YA QUE DEBE SER DESPUÉS DE '+config_despacho.dias_no_stgo+' DÍAS A LA FECHA DE HOY'}));
                            next=false;
                        }
                    }
                }
            }
        }*/

        let find=bloqueos.find(x=>x.fecha==aux);
        let x=selectedItem;
        if(find){
            if(find.disponibilidad<selectedItem.volumen){
               // dispatch(showMessageDetalleServicio({type:'error',text:'NO ES POSIBLE AGENDAR EN DICHA FECHA, NO EXISTE DISPONIBILIDAD'}));
                next=false;
               // dispatch(getDisponibilidadDespachoSolicAgendamiento({fecha:moment(form.fecha).format('YYYYMMDD')}))
            }
        }

        if(next==true){
            setForm({
                ...form,
                fecha: moment(value)
            }
            )
            //setSelectedDate(moment(value));
        }
    };
    const getBlocked=()=>{
        if(valueDireccion==''){
            if(valueRegion==''){
                return true;
            }else{
                if(valueComuna==''){
                    return true;
                }else{
                    return false;
                }
            }
        }else{
            return false;
        }
    }

    const getFechaMinima=(dir,reg,com)=>{
        let fecha=moment();
        if(dir!='' && dir.id){
            let findIndex=localDirecciones.findIndex(x=>x.id==dir.id);
            if(findIndex>=0){
                let aux=localDirecciones[findIndex];
                if(aux.fk_region!=12){
                    fecha=moment().add(config_despacho.dias_no_regiones,'d');
                }else{
                    let findIndex=ComunasNoIncluidasStgo.findIndex(x=>x==aux.fk_comuna);
                    if(findIndex>=0){
                        fecha=moment().add(config_despacho.dias_no_regiones,'d');   
                    }else{
                        fecha=moment().add(config_despacho.dias_no_stgo,'d'); 
                    }
                }
            }
        }else if(dir=='' && reg!='' && com!=''){
            if(reg.id!=12){
                fecha=moment().add(config_despacho.dias_no_regiones,'d');
            }else{
                let findIndex=ComunasNoIncluidasStgo.findIndex(x=>x==com.id);
                if(findIndex>=0){
                    fecha=moment().add(config_despacho.dias_no_regiones,'d');   
                }else{
                    fecha=moment().add(config_despacho.dias_no_stgo,'d'); 
                }
            }
        }
        let day=fecha.day();
        if(day==6 && config_despacho.sabado!=true){
            fecha.add(2,'d');
        }else if(day==7 && config_despacho.domingo!=true){
            fecha.add(1,'d');
        }

        if(config_retiro!=null && config_retiro.hora_limite && config_retiro.hora_limite!=null){
            let now=moment().format('YYYY-MM-DD HH:mm');
            let hora=moment(config_retiro.hora_limite).format('HH:mm');
            let day=moment().format('YYYY-MM-DD '+hora);
            let Nowx=new Date(now);let Dayx=new Date(day);
            if(Dayx<Nowx){
                fecha.add(1,'d');
            }
        }

        setForm({
            ...form,
            fecha:fecha
        });
        setFechaMinima(fecha);
               // return fecha;
    }
    return (
        <div className="cards-body px-4 px-md-5">
            <form className="input-material-ui" /*onSubmit={handleSubmit}*/>
                <div className="row">
                   {/* <div className="col-12 mb-4">
                        <Alert severity="warning">Recuerda, Disponible para agendar desde 48 hrs hábiles en adelante</Alert>
                    </div>*/}

                    <div className={`col-12 col-md-6 col-lg-6 mb-3 mb-0`}>
                        <InputLabel className="Label_Input_Format">Seleccione dirección despacho </InputLabel>
                        <Autocomplete
                            options={localDirecciones}
                            getOptionLabel={(option) => option.direccioncompleta}
                            onChange={(event, newValue) => {
                               
                                if (newValue != null && newValue.id != undefined) {
                                    setValueDireccion(newValue);
                                    setForm({
                                        ...form,
                                        fk_direcciones: newValue.id,
                                    })

                                    let findDir=localDirecciones.find(x=>x.id==newValue.id);
                                    if(findDir && findDir.fk_region && findDir.fk_region!=12){
                                        setRegionNoPermitida(true);
                                    }else if(findDir && findDir.fk_region && findDir.fk_region==12){
                                        //FALTA AGREGAR COMUNA PARA DETERMINAR SI ES PREAGENDAMIENTO
                                        setRegionNoPermitida(false);
                                    }
                                    getFechaMinima(newValue,'','');
                                } else {
                                    setValueDireccion('');
                                    setForm({
                                        ...form,
                                        fk_direcciones: ''
                                    })
                                    getFechaMinima('','','');
                                }
                            }}
                            value={valueDireccion}
                            inputValue={inputValueDireccion}
                            onInputChange={(event, inputValueDireccion) => {
                                setInputValueDireccion(inputValueDireccion);
                                if (inputValueDireccion != null && inputValueDireccion.id != undefined) {
                                    /* const obj=lodash.cloneDeep(usuarioPersona);
                                         const newPayload={
                                             ...obj,
                                             fk_region : inputValueRegion.id
                                         }
                                         setUsuarioPersona(newPayload);*/
                                    setForm({
                                        ...form,
                                        fk_direcciones: inputValueDireccion.id
                                    })
                                } else {
                                    setForm({
                                        ...form,
                                        fk_direcciones: ''
                                    })
                                }
                            }}
                            onBlur={handleBlur}
                            disabled={form.fk_region || form.fk_comuna || form.direccion || form.numero ? true : false}
                            renderInput={(params) => <TextField {...params} placeholder="Dirección" error={errors.fk_direcciones && true} helperText={errors.fk_direcciones} />}
                            noOptionsText={'No hay resultado...'}
                        />
                    </div>

                    <div className="col-12 col-md-6 col-lg-6">

                        <InputLabel className="Label_Input_Format">Fecha</InputLabel>
                        {/*<DatePicker
                        locale="es"
                        name="fecha"
                        dateFormat="dd/MM/yy"
                        selected={startDate}
                        className={ errors.startDate ? "error":''}
                        onChange={(date) => {
                            setStartDate(date);
                            //setForm({...form, fecha: date})
                          }}
                        filterDate={isWeekday}
                        placeholderText={"Fecha Despacho"}
                        minDate={new Date().getDay()==5?addDays(new Date(), 4):new Date().getDay()==4?addDays(new Date(), 3):addDays(new Date(), 2)}
                        />*/}
                        <MuiPickersUtilsProvider locale={deLocale} utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                disableToolbar
                                format="dd-MM-yy"
                                value={form.fecha}
                                onChange={onDateChange}
                                /*onChange={(date) => {
                                    setStartDate(date);
                                  }}*/
                                rifmFormatter={dateFormatter}
                                className={classes.inputAdornment}
                                minDate={fechaMinima}
                                disabled={getBlocked()}
                                onMonthChange={onChangeMonth}
                                shouldDisableDate={disableWeekends}
                                renderDay={(day, selectedDate, isInCurrentMonth, dayComponent) => getDayElement(day, selectedDate, isInCurrentMonth, dayComponent)}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </MuiPickersUtilsProvider>
                        <FormHelperText>{errors.startDate && errors.startDate}</FormHelperText>



                        {/* <InputLabel className="Label_Input_Format">Fecha</InputLabel>
                        <TextField
                            name="fecha"
                            type="date"
                            format="DD-MM-YY"
                            onChange={handleChange}
                            // className="form-control"
                            InputProps={{ inputProps: { min: selectedDate } }}
                            value={form.fecha && form.fecha}
                            onBlur={handleBlur}
                            helperText={errors.fecha && errors.fecha}
                            required
                            error={errors.fecha && true}
                        /> */}
                    </div>




                </div>

                <div className={`row ${form.fk_direcciones ? 'button-disable' : ''}`}>
                    <div className="col-12 mt-4 mt-md-5" onClick={openNuevaDireccion}>
                        <div className="align-item-center">
                            {nuevaDireccion ? <ExpandMoreIcon className="icon-color-default" /> : <ChevronRightIcon className="icon-color-default" />}
                            <h4 className="text-small font-weight-500">¿Deseas agregar otra dirección de despacho?</h4>
                        </div>
                    </div>
                </div>

                {
                    nuevaDireccion &&
                    <div className={`row mt-3 ${form.fk_direcciones ? 'button-disable' : ''}`}>
                        <div className="col-12 col-md-6 col-lg-6">
                            <InputLabel className="Label_Input_Format">Región</InputLabel>
                            <Autocomplete
                                id="fk_region"
                                name="fk_region"
                                options={regiones}
                                getOptionLabel={(option) => option ? option.nombre : ""}
                                defaultValue={regiones[0]}

                                // getOptionLabel={(options) => {
                                //     console.log("options")
                                //     console.log(options)


                                //     if(options === ''){
                                //         console.log('prueba iniiii')
                                //     }


                                //     // return options.nombre
                                //     if (
                                //       options != null &&
                                //       options.nombre != null
                                //     ) {
                                //       return (options.nombre);
                                //     } else {
                                //       return "";
                                //     }
                                //   }}
                                value={valueRegion}
                                onChange={(event, newValue) => {
                                    try {
                                        if(newValue==null){
                                            setValueRegion('');
                                        }else{
                                            setValueRegion(newValue);
                                        }
                                        
                                        if (newValue != null && newValue.id != undefined) {
                                            setForm({
                                                ...form,
                                                fk_region: newValue.id
                                            })
                                            let filterComunas = comunas.filter(x => x.fk_region === newValue.id);
                                            if (filterComunas) {
                                                setLocalComunas(filterComunas)
                                            }

                                            if(newValue.id!=12){
                                                setRegionNoPermitida(true);
                                            }
                                            /*const obj=lodash.cloneDeep(usuarioPersona);
                                            const newPayload={
                                                ...obj,
                                                fk_region : newValue.id
                                            }
                                            setUsuarioPersona(newPayload);
                                            dispatch(getComunasCotizadorCliente({id:newValue.id}));*/
                                        }
                                    } catch (error) {
                                        console.log(error.message)
                                    }
                                }}
                                inputValue={inputValueRegion}
                                onBlur={handleBlur}
                                onInputChange={(event, inputValueRegion) => {
                                    try {
                                        setInputValueRegion(inputValueRegion);
                                        if (inputValueRegion != null && inputValueRegion.id != undefined) {
                                            setForm({
                                                ...form,
                                                fk_region: inputValueRegion.id
                                            })
                                            let filterComunas = comunas.filter(x => x.fk_region === inputValueRegion.id);
                                            if (filterComunas) {
                                                setLocalComunas(filterComunas)
                                            }
                                            /* const obj=lodash.cloneDeep(usuarioPersona);
                                                 const newPayload={
                                                     ...obj,
                                                     fk_region : inputValueRegion.id
                                                 }
                                                 setUsuarioPersona(newPayload);*/
                                        }
                                    } catch (error) {
                                        console.log(error.message)
                                    }

                                }}
                                renderInput={(params) => <TextField {...params} placeholder="Selecciona tu región" error={errors.fk_region && true} helperText={errors.fk_region} />}
                                noOptionsText={'No hay resultado...'}
                            />
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 mt-3 mt-md-0">
                            <InputLabel className="Label_Input_Format">Comuna</InputLabel>
                            <Autocomplete
                                options={localComunas}
                                getOptionLabel={(option) => option.nombre}
                                onChange={(event, newValue) => {
                                    if(newValue==null){
                                        setValueComuna('');
                                    }else{
                                        setValueComuna(newValue);
                                    }
   
                                    if (newValue != null && newValue.id != undefined) {
                                        setForm({
                                            ...form,
                                            fk_comuna: newValue.id
                                        });
                                        let findIndex=ComunasNoIncluidasStgo.findIndex(x=>x==newValue.id);
                                        if(findIndex>=0){
                                            setRegionNoPermitida(true);
                                        }

                                        getFechaMinima(valueDireccion,valueRegion,newValue);
                                    }
                                }}
                                value={valueComuna}
                                inputValue={inputValueComuna}
                                onInputChange={(event, inputValueComuna) => {
                                    setInputValueComuna(inputValueComuna);
                                    if (inputValueComuna != null && inputValueComuna.id != undefined) {
                                        setForm({
                                            ...form,
                                            fk_comuna: inputValueComuna.id
                                        })
                                    }
                                }}
                                onBlur={handleBlur}
                                disabled={!form.fk_region ? true : false}
                                renderInput={(params) => <TextField {...params} placeholder="Selecciona tu comuna" error={errors.fk_comuna && true} helperText={errors.fk_comuna} />}
                                noOptionsText={'No hay resultado...'}
                            />
                        </div>

                        <div className="col-12 col-md-6 col-lg-6 mt-3 mt-md-4">
                            <InputLabel className="Label_Input_Format">Calle</InputLabel>
                            <TextField
                                name="direccion"
                                onChange={handleChange}
                                value={form.direccion}
                                onBlur={handleBlur}
                                error={errors.direccion && true}
                                helperText={errors.direccion}
                                placeholder="Nombre de la calle o avenida"
                            />
                        </div>


                        <div className="col-12 col-md-6 col-lg-6 mt-3 mt-md-4">
                            <InputLabel className="Label_Input_Format">Númeración / Depto</InputLabel>
                            <TextField
                                name="numero"
                                onChange={handleChange}
                                value={form.numero}
                                placeholder="Numero o departamento"
                                className={classes.inputAdornment}
                                onBlur={handleBlur}
                                error={errors.numero && true}
                                helperText={errors.numero}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">#</InputAdornment>
                                }}
                            />
                        </div>
                    </div>
                }

                <div className='border my-4'></div>

                <div className='row'>
                    <div className="col-12" onClick={() => { setOpenInfoAdicional(!openInfoAdicional) }}>
                        <div className="align-item-center">
                            {openInfoAdicional ? <ExpandMoreIcon className="icon-color-default" /> : <ChevronRightIcon className="icon-color-default" />}
                            <h4 className="text-small font-weight-500">Información adicional</h4>
                        </div>
                    </div>
                </div>

                {openInfoAdicional &&
                    <div className="row">
                        <div className="col-12 mt-3">
                            <TextareaAutosize
                                name="observaciones"
                                placeholder="Ingresa tu comentario"
                                onChange={handleChange}
                                value={form.observaciones && form.observaciones}
                            />
                        </div>
                    </div>
                }

                {/*
                    (valueRegion != "" && valueRegion.id != 12) || regionNoPermitida &&
                    <div className='row'>
                        <div className="col-12 my-3">
                            <div className="align-item-center">
                                <ReportProblemIcon className="icon-color-danger mr-2" />
                                <h4 className="text-small text-danger font-weight-500">Su dirección está fuera de rango de la provincia de Santiago</h4>
                            </div>

                            <p className="text-small font-weight-300 my-3"> por lo que el despacho gratuito no está disponible en esta zona. Favor contacta a tu ejecutivo para programar tu despacho.</p>

                            <div className="align-item-center">
                                <div className={classes.borderRight}>
                                    <h5 className="text font-weight-500 text-capitalize mr-4">{selectedComercial != null ? selectedComercial.nombre + ' ' + selectedComercial.apellidos : ''}</h5>
                                    <p className="text-xs font-weight-400">Ejecutivo(a) Comercial </p>
                                </div>
                                <a
                                    //href={selectedComercial != null ? "https://wa.me/" + selectedComercial.telefono : ''} 
                                    className="link-phone"
                                    //target="_blank"
                                    onClick={() => {
                                        console.log(selectedComercial)
                                        if (selectedComercial != null) {
                                            let msj = `Hola, necesito tu ayuda para cotizar servicio de transporte de mi carga fuera de Santiago`;
                                            window.open(`https://api.whatsapp.com/send?phone=${selectedComercial.telefono}&text=${msj}`);
                                        }

                                    }}
                                >
                                    <IconButton
                                        className="icon-button icon-button-success ml-4"
                                    >
                                        <WhatsAppIcon />
                                    </IconButton>
                                </a>
                            </div>
                        </div>
                    </div>*/
                }
                
                    <Button
                        onClick={() => {
                            
                            postDataDespacho();
                        }}
                        /*className={`button button-outline-danger button-small button-100-mobile mt-4 ${ (valueRegion != "" && valueRegion.id != 12) || regionNoPermitida ? 'button-disable' : ''}`}*/
                        className={`button button-outline-danger button-small button-100-mobile mt-4 `}
                        >
                        Confirmar Despacho
                    </Button>
                
            </form>
        </div>
    )
}
export default FormDespacho