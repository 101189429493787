import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import CloseIcon from '@material-ui/icons/Close';
import IntlMessages from '../../util/IntlMessages';
import IconButton from '@material-ui/core/IconButton';
import MaterialTable from 'material-table';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import './StyleModal.scss';
import ReactToPrint from "react-to-print";
import Button from '@material-ui/core/Button';
import moment from 'moment';

const PropuestaComercialPdf = (props) => {

  const dispatch = useDispatch();
  const {
    payload,
    propuestaBase,
    comerciales,
    zonasTarifarias
  } = useSelector(({ CotizadorCliente }) => CotizadorCliente);
  const componentRef = useRef();

  /*useEffect(() => {
      
          document.title = "PROPUESTA ";
  }, []);*/
  const [origen, setOrigen] = useState('');
  const [destino, setDestino] = useState('');

  let selectedComer = comerciales.find(x => x.id === payload.fk_comercial);
  //var Total_Tar = 0;
  let Pb_unidadesACobrar = propuestaBase.Pb_unidadesACobrar <= 0 ? propuestaBase.unidadesACobrar : propuestaBase.Pb_unidadesACobrar;
  var Total_SerAd = 0;

  console.log('payload-cotizador', payload)
  console.log('payload-zonasTarifarias', zonasTarifarias)

  // useEffect para actulizar la data 
  // fk_origen, fk_destino
  useEffect(() => {
    const { fk_origen, fk_destino } = payload;
    let findOrigen = zonasTarifarias.find((x) => x.id === fk_origen);
    let findDestino = zonasTarifarias.find((x) => x.id === fk_destino);
    setOrigen(findOrigen.nombre);
    setDestino(findDestino.nombre);

  }, [payload]);

  return (
    <div className='prueba-etiqueta'>
      <CardContent ref={componentRef} style={{ backgroundColor: 'white' }}>

        {/* pag */}
        <div className="propuesta_page ">
          {/* cabecera */}
          <div className="justify-start mb-5">
            <img width="100px" src="../../Logo.jpg" />
            <div className="text-cabecera">
              <h3 className="text-xs fw-bold">
                <strong>WS Cargo SPA</strong>
              </h3>
              <h4 className="text-xs">
                Oficina: Av Andrés Bello 2299, Of. 202, Providencia
              </h4>
              <h4 className="text-xs">
                Bodega: Camino el Noviciado #1945, Pudahuel
              </h4>
            </div>
          </div>

          <h2 className="title-h4 fw-bold text-danger mb-3"><u>BORRADOR DE PROPUESTA COMERCIAL, NO VALIDO COMO ACUERDO COMERCIAL{" "}</u></h2>


          <div className="row mt-3">
            <div className="col-12 justify-space-between">
              <p className="text">
                <strong>CLIENTE: </strong> {payload.nombre}
              </p>
              <p className="text">
                <strong>FECHA: {moment().format("DD-MM-YYYY")}</strong>
              </p>
            </div>
            <div className="col-6">
              <p className="text">
                <strong>ATENCIÓN A: </strong>
                {/* {props.selectedItem.atenciona} */}
              </p>
            </div>
          </div>
          <div className="border"></div>

          <div className="row mt-3">
            <div className="col-12 text-justify">
              <p className="text">En atención a vuestra gentil solicitud, tenemos el agrado de hacer llegar a usted nuestra propuesta de servicios:</p>
            </div>
          </div>

          <div className="row mt-3">
            <div className='col-12 mb-3'>
              <h4 className="text fw-bold"><u>I. DATOS DE LA CARGA INFORMADA POR CLIENTE</u></h4>
            </div>
          </div>

          <div className="row mt-1">
            <div className='col-12'>
              <table className="table_propuesta">
                <thead>
                  <tr>
                    <th className="pl-2 fw-bold" colSpan="2">Antecedentes</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="pl-2">SERVICIO</td>
                    <td className="pl-2">{"IMPORTACIÓN LCL"}</td>
                  </tr>
                  <tr>
                    <td className="pl-2">DIRECCIÓN DE DESPACHO EN CHILE</td>
                    <td className="pl-2">{"SANTIAGO"}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="row mt-3">
            <div className='col-12 mb-3'>
              <h4 className="text fw-bold"><u>II. TARIFA SERVICIO WS CARGO CARGA LCL/PARCIAL DE IMPORTACIÓN</u></h4>
            </div>
          </div>

          <div className="row mt-1">
            <div className='col-12'>
              <table className="table_propuesta">
                <thead>
                  {/* <tr>
                    <th className="text-center" style={{ width: '100px' }}>TIPO<br></br>CARGA</th>
                    <th className="text-center" style={{ width: '70px' }}>KG<br></br>ESTIMADO</th>
                    <th className="text-center" style={{ width: '70px' }}>M3<br></br>ESTIMADO</th>
                    <th className="text-center" style={{ width: '80px' }}>ORIGEN</th>
                    <th className="text-center" style={{ width: '100px' }}>DESCONSOLIDADO</th>
                    <th className="text-center" style={{ width: '80px' }}>DESTINO</th>
                    <th className="text-center" style={{ width: '30px' }}>BASE</th>
                    <th className="text-center" style={{ width: '50px' }}>KG/M3<br></br>MAXIMO</th>
                    <th className="text-center" style={{ width: '60px' }}>UNIDADES<br></br>A COBRAR<br></br>(A)</th>
                    <th className="text-center" style={{ width: '50px' }}>VALOR<br></br>UNITARIO USD<br></br>(B)</th>
                    <th className="text-center" style={{ width: '50px' }}>BASE *<br></br>(C)</th>
                    <th className="text-center" style={{ width: '90px' }}>TARIFA USD<br></br>(D)=(A)*(B)+(C)</th>
                  </tr> */}
                  <tr>
                    <th className="text-center border-lp" >Tipo  <br /> carga</th>
                    <th className="text-center border-lp">KG  <br /> Estimado</th>
                    <th className="text-center border-lp">M3  <br /> Estimado</th>
                    <th className="text-center border-lp">Origen</th>
                    <th className="text-center border-lp">Destino</th>
                    <th className="text-center border-lp px-3">Unidades a cobrar (A)</th>
                    <th className="text-center border-lp px-3">Valor unitario USD (B)</th>
                    <th className="text-center border-lp">Base * USD <br></br>(C)</th>
                    <th className="text-center fw-bold">Tarifa usd (D)<br></br>(D)=(A)*(B)+(C)</th>
                  </tr>
                </thead>

                <tbody>


									<tr>
										<td className="text-center">{payload.descripcion}</td>
										<td className="text-center">{new Intl.NumberFormat("de-DE").format(payload.peso)}</td>
										<td className="text-center">{new Intl.NumberFormat("de-DE").format(payload.volumen)}</td>
										<td className="text-center">{origen && origen}</td>
										<td className="text-center">{destino && destino}</td>
										<td className="text-center">{new Intl.NumberFormat("de-DE").format(payload.unidadesACobrar)}</td>
										<td className="text-center">{new Intl.NumberFormat("de-DE").format(payload.tarifa)}</td>
										<td className="text-center">{Number(payload.unidadesACobrar) >= Number(Pb_unidadesACobrar) ? 0 : new Intl.NumberFormat("de-DE").format(propuestaBase.valorBaseUsd)}</td>
										<td className="text-center">{new Intl.NumberFormat("de-DE").format(payload.total)}</td>
									</tr>
                </tbody>
              </table>

              <p className="text-xs py-1">* El <b>BASE USD (C)</b> aplica si volumen o peso equivalente esta en el rango mencionado</p>
              <p className="text-xs py-1">* <b>Valor unitario USD (B)</b> se divide en dos partes: una parte exenta de $122 dólares y una parte afecta a IVA de $33 dólares.</p>
							<div className='border-red'></div>
							<div className='valor-servicio mt-2'>
								<div className='box-service py-2 border-right'>
									<h3 className="text-small fw-bold">TOTAL (Valor USD)</h3>
								</div>
								<div className='box-service py-2'>
									<h4 className="text-small fw-bold">{new Intl.NumberFormat("de-DE").format(payload.total)}</h4>
								</div>
							</div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 text-justify">
              {/* <p className="text-small"><strong>Condiciones de tarifa anterior:</strong></p> */}
              <h4 className="text fw-bold mb-3">Condiciones de tarifa anterior:</h4>
              <p className="text-small"><strong>1. Tarifa válida para cargas a ser recepcionadas dentro de los siguientes 10 corridos.</strong></p>
              <p className="text-small"><strong>2. CBM y peso final serán determinados una vez arribe la carga a bodega en China y emitido el Packing List y BL de la carga recepcionada.</strong></p>
              <p className="text-small"><strong>3. Para consolidar un embarque, se aceptan máximo 5 proveedores cuyas cargas
                arriben dentro de un periodo de 15 días. Debe informar con anticipación el número de
                proveedores a consolidar antes de la llegada de la primera carga a nuestra bodega
                en China, de esta forma se despachará la carga desde China una vez arribado el
                número completo de proveedores informados previamente.</strong></p>
              <p className="text-small"><strong>4. Si pago se realiza en pesos, se considera el dólar observado al día de elaboración de
                la nota de cobro más quince pesos por dólar.</strong></p>
                <p className="text-small"><strong>5. Considera el pago de IVA de los productos importados. Éste se calcula en función del valor CIF de la mercancía.</strong></p>
                <p className="text-small"><strong>6. El precio de 155 (USD/m3) se divide en dos partes: una parte exenta de $122 dólares y una parte afecta a IVA de $33 dólares.</strong></p>
                <p className="text-small"><strong>7. Es esencial notar que tenemos un límite de peso máximo por metro cúbico, que es de 500 kilogramos (kg). Esto significa que incluso si tu carga es menor de 1 m³ pero su peso excede los 500 kg, se calculará como 1 m³ o fracción.</strong></p>
                <p className="text-small"><strong>8. Debes tener en cuenta que la unidad de cobro mínima es de 1 metro cúbico (m³) o su peso equivalente.</strong></p>
                <p className="text-small"><strong>9. Valor incluye despacho hasta lugar indicado por cliente dentro de la provincia de Santiago más las comunas de Puente Alto, San Bernardo y Quilicura. Para otros destinos favor solicitar cotización.</strong></p>
            </div>
          </div>
        </div>

        <div className="salto_pagina">
          <div className='border'></div>
        </div>

        {/* pag */}
        <div className="propuesta_page">
          {/* cabecera */}
          <div className="justify-start mb-5">
            <img width="100px" src="../../Logo.jpg" />
            <div className="text-cabecera">
              <h3 className="text-xs fw-bold">
                <strong>WS Cargo SPA</strong>
              </h3>
              <h4 className="text-xs">
                Oficina: Av Andrés Bello 2299, Of. 202, Providencia
              </h4>
              <h4 className="text-xs">
                Bodega: Camino el Noviciado #1945, Pudahuel
              </h4>
            </div>
          </div>

          <h2 className="title-h4 fw-bold text-danger mb-3"><u>BORRADOR DE PROPUESTA COMERCIAL, NO VALIDO COMO ACUERDO COMERCIAL{" "}</u></h2>

          <div className="row mb-3">
            {/* <div className="d-none d-l-block col-12 d-flex flex-column text-left text_sin_salto PropuestaPdfTexto"> */}
            <div className="col-12">
              <h4 className="text fw-bold">Servicio incluye en China:</h4>
              <ul>
                <li className='text-small'>Recepción de carga en bodega YIWU</li>
                <li className='text-small'>Consolidación de carga</li>
                <li className='text-small'>Despacho de Carga en Puertos de China</li>
                <li className='text-small'>Confirmación de recepción de Carga en China</li>
                <li className='text-small'>Almacenaje en Bodega en China</li>
                <li className='text-small'>Consolidación de contenedor junto a otras cargas</li>
                <li className='text-small'>Agente de aduanas.</li>
                <li className='text-small'>Documentación de aduana.</li>
                <li className='text-small'>Gastos de aduana.</li>
                <li className='text-small fw-bold'>Certificado de origen para hacer valer el TLC en Chile si valor de la carga supera 1.000 USD.</li>
                <li className='text-small'>Transporte marítimo con naviera.</li>
                <li className='text-small'>Seguros de Transporte de USD 1.000 por M3</li>
              </ul>
            </div>

          </div>

          <div className="row mb-3">
            {/* <div className="d-none d-l-block col-12 d-flex flex-column text-left text_sin_salto PropuestaPdfTexto"> */}
            <div className="col-12">
              <h4 className="text fw-bold">Servicio incluye en Chile:</h4>
              <ul>
                <li className='text-small'>Agencia de aduanas.</li>
                <li className='text-small'>Documentación de aduana.</li>
                <li className='text-small'>Tramite pago de IVA importación en Chile (Monto deberá ser depositado por cliente una vez reciba notificación).</li>
                <li className='text-small'>Gastos de aduana en Chile.</li>
                <li className='text-small'>Gastos portuarios en Chile.</li>
                <li className='text-small'>Desconsolidación en Chile.</li>
                <li className='text-small'>Gastos por eventual Aforo Físico.</li>
                <li className='text-small'>Almacenaje en Chile.</li>
                <li className='text-small'>Transporte a Bodega en Santiago.</li>
                <li className='text-small'>Seguros de Transporte de USD 1.000 por M3.</li>
              </ul>
            </div>
          </div>
          {/* <div className="row mt-3">
              <div className="d-none d-l-block col-12 d-flex flex-column text-left PropuestaPdfTexto">
                <p className="text_sin_salto"><strong><u>III. SERVICIOS ADICIONALES (SOLO SI APLICA)</u></strong></p>
              </div>
            </div> */}
          <div className="row mb-3">
            <div className="col-12">
              <h4 className="text fw-bold"><u>III. SERVICIOS ADICIONALES (SOLO SI APLICA)</u></h4>
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <table className="table_propuesta">
                <thead>
                  <tr>
                    <th className="pl-2 fw-bold fw-bold border-lp">Detalle</th>
                    <th className="pl-2 fw-bold">Tarifa CLP + IVA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="pr-2 text-right">IVA (19%)</td>
                    <td className="pr-2 text-right">{new Intl.NumberFormat("de-DE").format(Number(0) * 0.19)}</td>
                  </tr>
                  <tr>
                    <td className="pr-2 text-right">TOTAL CON IVA</td>
                    <td className="pr-2 text-right">{new Intl.NumberFormat("de-DE").format(Number(0) * 1.19)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="salto_pagina">
          <div className='border'></div>
        </div>
        {/* <div className="propuesta_page">
            <div className="row mt-5">
              <div className="d-none d-l-block col-6 d-flex flex-column">
                <img width="200px" src="../../Logo.jpg" />
              </div>
              <div className="d-none d-l-block col-6 d-flex flex-column">
                <h1 className="text_sin_salto"><strong>WS Cargo SPA</strong></h1>
                <h4 className="text_sin_salto">Oficina: Av Andrés Bello 2299, Of 701, Providencia</h4>
                <h4 className="text_sin_salto">Bodega: Camino el Noviciado #1945, Pudahuel</h4>
              </div>
            </div>

            <div className="row mt-3 col-12 text-center">
              <h1 className="text-danger"><u><strong>BORRADOR DE PROPUESTA COMERCIAL, NO VALIDO COMO ACUERDO COMERCIAL </strong></u></h1>
            </div>

            <div className="row mt-3">
              <div className="d-none d-l-block col-12 d-flex flex-column text-left PropuestaPdfTexto">
                <p className="text_sin_salto"><strong><u>IV. CONDICIONES DEL SERVICIO.</u></strong></p>
              </div>
            </div>
            <div className="row">

              <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto">
                <p >WS Cargo facilita al Cliente sus casillas internacionales ubicadas en la ciudades de Guangzhou y Yiwu en China, en adelante indistintamente el “país extranjero”, para que el Cliente direccione sus envíos a dichas casillas y WS Cargo proceda a recibirlos, clasificarlos, consolidarlos, transportarlos vía aérea o marítima desde China a Chile, presentarlos envíos ante el Servicio Nacional de Aduanas de Chile, pagar los impuestos y aranceles por cuenta del cliente que sean necesarios y luego transportarlos a la dirección que el Cliente designe en Chile según las condiciones comerciales establecidas en la propuesta comercial.</p>
                <p className="">El Cliente se hace responsable de la veracidad, exactitud e integridad de la información que proporcione a WS Cargo para importar sus envíos y para efectuar las entregas en Chile a la dirección que éste le señale.</p>
              </div>

            </div>
            <div className="row mt-3">
              <div className="d-none d-l-block col-12 d-flex flex-column text-left PropuestaPdfTexto">
                <p className="text_sin_salto"><strong><u>V. CASILLAS.</u></strong></p>
              </div>
            </div>
            <div className="row">

              <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto">
                <p  >Las compras que efectúe el Cliente deberán ser dirigidas a la Casilla internacional de WS Cargo en China, que éste expresamente elija y singularice.</p>
                <p  >Todos los envíos y correspondencia, sin distinción alguna, que llegue a nombre del Cliente a esta casilla se entenderán que han sido enviados por éste, autorizando desde ya a WS Cargo para que, a cargo del Cliente, los interne, transporte y entregue en Chile.</p>
                <p  >Las partes acuerdan que por regla general todos los envíos que lleguen a WS Cargo a nombre del Cliente se presumirán que han sido solicitados por éste.</p>
              </div>

            </div>
            <div className="row mt-3">
              <div className="d-none d-l-block col-12 d-flex flex-column text-left PropuestaPdfTexto">
                <p className="text_sin_salto"><strong><u>VI. CUMPLIMIENTO NORMATIVAS Y AUTORIZACIONES.</u></strong></p>
              </div>
            </div>
            <div className="row">
              <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto">
                <p  >El Cliente declara conocer la legislación chilena y del país extranjero que regula la importación a Chile de productos y mercancías. Producto de lo anterior, éste declara conocer que sus envíos podrán ser objeto de revisiones tanto en Chile como en el extranjero a objeto de verificar el cumplimiento de la normativa vigente de los países de origen y destino de las piezas. En www.WSCargo.cl el Cliente podrá encontrar un listado meramente referencial de los productos cuya internación está prohibida y/o restringida por la legislación chilena. WS Cargo se reserva el derecho a no admitir los envíos que vulneren la legislación vigente o puedan derivar en multas, sanciones y/o delitos tanto para WS Cargo como para el Cliente. El no ejercicio de ese derecho no acarreará responsabilidad o culpa alguna a WS Cargo.</p>
                <p  >El Cliente libera de toda responsabilidad a WS Cargo por la confiscación de productos que puedan realizar las autoridades del país extranjero o de Chile y del gravamen del cual puedan ser objeto producto de ello o de cualquier otra circunstancia. El Cliente será el único responsable del incumplimiento de dichas normas y se obliga, en todo caso, a mantener indemne a WS Cargo de todo perjuicio que para ella deriven de los procedimientos de importación de los envíos del Cliente.</p>
              </div>
            </div>
          </div> */}

        {/* pag */}
        <div className="propuesta_page">
          {/* cabecera */}
          <div className="justify-start mb-5">
            <img width="100px" src="../../Logo.jpg" />
            <div className="text-cabecera">
              <h3 className="text-xs fw-bold">
                <strong>WS Cargo SPA</strong>
              </h3>
              <h4 className="text-xs">
                Oficina: Av Andrés Bello 2299, Of. 202, Providencia
              </h4>
              <h4 className="text-xs">
                Bodega: Camino el Noviciado #1945, Pudahuel
              </h4>
            </div>
          </div>
          <h2 className="title-h4 fw-bold text-danger mb-3"><u>BORRADOR DE PROPUESTA COMERCIAL, NO VALIDO COMO ACUERDO COMERCIAL{" "}</u></h2>

          <div className="row mb-3">
            <div className='col-12 mb-3'>
              <h4 className="text fw-bold"><u>IV. CONDICIONES DEL SERVICIO.</u></h4>
            </div>
            <div className="col-12 text-justify">
              <p className='text-small mb-2'>WS Cargo facilita al Cliente sus casillas internacionales ubicadas en la ciudades de Guangzhou y Yiwu en China, en adelante indistintamente el “país extranjero”, para que el Cliente direccione sus envíos a dichas casillas y WS Cargo proceda a recibirlos, clasificarlos, consolidarlos, transportarlos vía aérea o marítima desde China a Chile, presentarlos envíos ante el Servicio Nacional de Aduanas de Chile, pagar los impuestos y aranceles por cuenta del cliente que sean necesarios y luego transportarlos a la dirección que el Cliente designe en Chile según las condiciones comerciales establecidas en la propuesta comercial.</p>
              <p className="text-small">El Cliente se hace responsable de la veracidad, exactitud e integridad de la información que proporcione a WS Cargo para importar sus envíos y para efectuar las entregas en Chile a la dirección que éste le señale.</p>
            </div>
          </div>

          <div className="row mb-3">
            <div className='col-12 mb-3'>
              <h4 className="text fw-bold"><u>V. CASILLAS.</u></h4>
            </div>
            {/* <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto"> */}
            <div className="col-12 text-justify">
              <p className='text-small mb-2'>Las compras que efectúe el Cliente deberán ser dirigidas a la Casilla internacional de WS Cargo en China, que éste expresamente elija y singularice.</p>
              <p className='text-small mb-2'>Todos los envíos y correspondencia, sin distinción alguna, que llegue a nombre del Cliente a esta casilla se entenderán que han sido enviados por éste, autorizando desde ya a WS Cargo para que, a cargo del Cliente, los interne, transporte y entregue en Chile.</p>
              <p className='text-small'>Las partes acuerdan que por regla general todos los envíos que lleguen a WS Cargo a nombre del Cliente se presumirán que han sido solicitados por éste.</p>
            </div>
          </div>

          <div className="row">
            <div className='col-12 mb-3'>
              <h4 className="text fw-bold"><u>VI. CUMPLIMIENTO NORMATIVAS Y AUTORIZACIONES.</u></h4>
            </div>
            {/* <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto"> */}
            <div className="col-12 text-justify">
              <p className='text-small mb-2'>El Cliente declara conocer la legislación chilena y del país extranjero que regula la importación a Chile de productos y mercancías. Producto de lo anterior, éste declara conocer que sus envíos podrán ser objeto de revisiones tanto en Chile como en el extranjero a objeto de verificar el cumplimiento de la normativa vigente de los países de origen y destino de las piezas. En www.WSCargo.cl el Cliente podrá encontrar un listado meramente referencial de los productos cuya internación está prohibida y/o restringida por la legislación chilena. WS Cargo se reserva el derecho a no admitir los envíos que vulneren la legislación vigente o puedan derivar en multas, sanciones y/o delitos tanto para WS Cargo como para el Cliente. El no ejercicio de ese derecho no acarreará responsabilidad o culpa alguna a WS Cargo.</p>
              <p className='text-small'>El Cliente libera de toda responsabilidad a WS Cargo por la confiscación de productos que puedan realizar las autoridades del país extranjero o de Chile y del gravamen del cual puedan ser objeto producto de ello o de cualquier otra circunstancia. El Cliente será el único responsable del incumplimiento de dichas normas y se obliga, en todo caso, a mantener indemne a WS Cargo de todo perjuicio que para ella deriven de los procedimientos de importación de los envíos del Cliente.</p>
            </div>
          </div>
        </div>

        <div className="salto_pagina">
          <div className='border'></div>
        </div>

        {/* <div className="propuesta_page">
            <div className="row mt-5">
              <div className="d-none d-l-block col-6 d-flex flex-column">
                <img width="200px" src="../../Logo.jpg" />
              </div>
              <div className="d-none d-l-block col-6 d-flex flex-column">
                <h1 className="text_sin_salto"><strong>WS Cargo SPA</strong></h1>
                <h4 className="text_sin_salto">Oficina: Av Andrés Bello 2299, Of 701, Providencia</h4>
                <h4 className="text_sin_salto">Bodega: Camino el Noviciado #1945, Pudahuel</h4>
              </div>
            </div>

            <div className="row mt-3 col-12 text-center">
              <h1 className="text-danger"><u><strong>BORRADOR DE PROPUESTA COMERCIAL, NO VALIDO COMO ACUERDO COMERCIAL </strong></u></h1>
            </div>
            <div className="row mt-3">
              <div className="d-none d-l-block col-12 d-flex flex-column text-left PropuestaPdfTexto">
                <p className="text_sin_salto"><strong><u>VII. DOCUMENTACIÓN.</u></strong></p>
              </div>
            </div>
            <div className="row">

              <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto">
                <p  >Para efectos de la internación de los envíos, éstos deberán venir debidamente acompañados de sus facturas o de otros antecedentes que revelen indubitadamente el valor de compra o adquisición de dichos productos.</p>
                <p  >En caso que los envíos del Cliente no contengan la correspondiente factura u otros documentos necesarios para su importación, como certificados, autorizaciones administrativas, resoluciones, etc., WS Cargo le requerirá al Cliente la presentación de éstos. WS Cargo no se hace responsable por los envíos del Cliente que requieran antecedentes y documentos adicionales a los que el Cliente creía que le afectaban de acuerdo a la legislación chilena y del país extranjero.</p>
                <p  >Si transcurridos 30 días corridos desde su solicitud no han sido presentados los antecedentes requeridos, WS Cargo declarará abandonados los productos y procederá a realizarlos para cubrirse de los créditos devengados en su favor.</p>
              </div>

            </div>
            <div className="row mt-3">
              <div className="d-none d-l-block col-12 d-flex flex-column text-left PropuestaPdfTexto">
                <p className="text_sin_salto"><strong><u>VIII. TARIFAS, COSTOS Y PAGO.</u></strong></p>
              </div>

            </div>
            <div className="row">

              <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto">
                <p  >A través del ejecutivo comercial y/o sitio www.WSCargo.cl el Cliente podrá acceder a una cotización referencial de sus envíos, para lo cual deberá proveer las características exactas del producto que se desea importar. El Cliente declara conocer y aceptar que en caso que los datos aportados no sean ajustados a las características, dimensiones, peso, origen, precio y destinación de los productos, el valor final del servicio ofrecido por WS Cargo podría variar, ya sea en contra o en favor del Cliente.</p>
                <p  >Serán de cargo del Cliente los aranceles, cargos, impuestos aduaneros, IVA y el transporte de sus envíos, cargos todos que por cuenta del Cliente pagará WS Cargo con la finalidad de facilitar la importación y entrega de los envíos. En particular serán de costo y cargo del Cliente: cargos por el transporte internacional de acuerdo con las tarifas establecidas por WS Cargo; impuestos y tasas aduaneras por concepto de importación; certificaciones especiales que requiera la carga tanto en el extranjero como en Chile; y, servicios adicionales solicitados previamente por el cliente: seguros, reembalajes, gestión de compra, etc.</p>
                <p  >El Cliente se obliga a pagar el importe de los servicios de WS Cargo antes de la entrega de los envíos. WS Cargo no estará obligado a despachar los envíos del Cliente si éste no ha pagado el valor total de los servicios. Antes de</p>
                <p  >ser liberados los envíos del Cliente del Servicio Nacional de Aduanas de Chile, se le informará vía correo electrónico a éste el importe de los servicios de WS Cargo más los recargos que deba pagar por cuenta del cliente.</p>
                <p  >Los pagos podrán realizarse (i) transferencia bancaria; (ii) Depósito en efectivo, o bien, mediante (ii) pago en oficina.</p>
                <p  >Si transcurridos 30 días corridos desde la solicitud de pago, éste no se ha efectuado, WS Cargo declarará abandonados los productos y procederá a venderlos para cubrirse de los créditos devengados en su favor.</p>
              </div>
            </div>

          </div> */}

        {/* pag */}
        <div className="propuesta_page">
          {/* cabecera */}
          {/* cabecera */}
          <div className="justify-start mb-5">
            <img width="100px" src="../../Logo.jpg" />
            <div className="text-cabecera">
              <h3 className="text-xs fw-bold">
                <strong>WS Cargo SPA</strong>
              </h3>
              <h4 className="text-xs">
                Oficina: Av Andrés Bello 2299, Of. 202, Providencia
              </h4>
              <h4 className="text-xs">
                Bodega: Camino el Noviciado #1945, Pudahuel
              </h4>
            </div>
          </div>
          <h2 className="title-h4 fw-bold text-danger mb-3"><u>BORRADOR DE PROPUESTA COMERCIAL, NO VALIDO COMO ACUERDO COMERCIAL{" "}</u></h2>

          <div className="row mb-3">
            <div className='col-12 mb-3'>
              <h4 className="text fw-bold"><u>VII. DOCUMENTACIÓN.</u></h4>
            </div>
            <div className="col-12 text-justify">
              <p className='text-small mb-2'>Para efectos de la internación de los envíos, éstos deberán venir debidamente acompañados de sus facturas o de otros antecedentes que revelen indubitadamente el valor de compra o adquisición de dichos productos.</p>
              <p className='text-small mb-2'>En caso que los envíos del Cliente no contengan la correspondiente factura u otros documentos necesarios para su importación, como certificados, autorizaciones administrativas, resoluciones, etc., WS Cargo le requerirá al Cliente la presentación de éstos. WS Cargo no se hace responsable por los envíos del Cliente que requieran antecedentes y documentos adicionales a los que el Cliente creía que le afectaban de acuerdo a la legislación chilena y del país extranjero.</p>
              <p className='text-small'>Si transcurridos 30 días corridos desde su solicitud no han sido presentados los antecedentes requeridos, WS Cargo declarará abandonados los productos y procederá a realizarlos para cubrirse de los créditos devengados en su favor.</p>
            </div>
          </div>

          <div className="row">
            <div className='col-12 mb-3'>
              <h4 className="text fw-bold"><u>VIII. TARIFAS, COSTOS Y PAGO.</u></h4>
            </div>
            <div className="col-12 text-justify">
              <p className='text-small mb-2'>A través del ejecutivo comercial y/o sitio www.WSCargo.cl el Cliente podrá acceder a una cotización referencial de sus envíos, para lo cual deberá proveer las características exactas del producto que se desea importar. El Cliente declara conocer y aceptar que en caso que los datos aportados no sean ajustados a las características, dimensiones, peso, origen, precio y destinación de los productos, el valor final del servicio ofrecido por WS Cargo podría variar, ya sea en contra o en favor del Cliente.</p>
              <p className='text-small mb-2'>Serán de cargo del Cliente los aranceles, cargos, impuestos aduaneros, IVA y el transporte de sus envíos, cargos todos que por cuenta del Cliente pagará WS Cargo con la finalidad de facilitar la importación y entrega de los envíos. En particular serán de costo y cargo del Cliente: cargos por el transporte internacional de acuerdo con las tarifas establecidas por WS Cargo; impuestos y tasas aduaneras por concepto de importación; certificaciones especiales que requiera la carga tanto en el extranjero como en Chile; y, servicios adicionales solicitados previamente por el cliente: seguros, reembalajes, gestión de compra, etc.</p>
              <p className='text-small mb-2'>El Cliente se obliga a pagar el importe de los servicios de WS Cargo antes de la entrega de los envíos. WS Cargo no estará obligado a despachar los envíos del Cliente si éste no ha pagado el valor total de los servicios. Antes de</p>
              <p className='text-small mb-2'>ser liberados los envíos del Cliente del Servicio Nacional de Aduanas de Chile, se le informará vía correo electrónico a éste el importe de los servicios de WS Cargo más los recargos que deba pagar por cuenta del cliente.</p>
              <p className='text-small mb-2'>Los pagos podrán realizarse (i) transferencia bancaria; (ii) Depósito en efectivo, o bien, mediante (ii) pago en oficina.</p>
              <p className='text-small'>Si transcurridos 30 días corridos desde la solicitud de pago, éste no se ha efectuado, WS Cargo declarará abandonados los productos y procederá a venderlos para cubrirse de los créditos devengados en su favor.</p>
            </div>
          </div>
        </div>

        <div className="salto_pagina">
          <div className='border'></div>
        </div>

        {/* <div className="propuesta_page">
            <div className="row mt-5">
              <div className="d-none d-l-block col-6 d-flex flex-column">
                <img width="200px" src="../../Logo.jpg" />
              </div>
              <div className="d-none d-l-block col-6 d-flex flex-column">
                <h1 className="text_sin_salto"><strong>WS Cargo SPA</strong></h1>
                <h4 className="text_sin_salto">Oficina: Av Andrés Bello 2299, Of 701, Providencia</h4>
                <h4 className="text_sin_salto">Bodega: Camino el Noviciado #1945, Pudahuel</h4>
              </div>
            </div>
            <div className="row mt-3 col-12 text-center">
              <h1 className="text-danger"><u><strong>BORRADOR DE PROPUESTA COMERCIAL, NO VALIDO COMO ACUERDO COMERCIAL </strong></u></h1>
            </div>
            <div className="row mt-3">
              <div className="d-none d-l-block col-12 d-flex flex-column text-left PropuestaPdfTexto">
                <p className="text_sin_salto"><strong><u>IX. COSTOS DEL TRANSPORTE.</u></strong></p>
              </div>
            </div>
            <div className="row">
              <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto">
                <p className="text_sin_salto">La tarifa del flete aéreo y terrestre se aplicará en base al mayor valor entre su peso real expresado en kilos dividido por 500 y su peso volumétrico, siendo el peso volumétrico calculado de la siguiente forma: largo, por ancho, por alto, lo anterior expresado en centímetros, y dicho resultado dividido por diez mil. La unidad de cobro mínima es de una unidad.</p>
              </div>
            </div>
            <div className="row mt-3">

              <div className="d-none d-l-block col-12 d-flex flex-column text-left PropuestaPdfTexto">
                <p className="text_sin_salto"><strong><u>X. RESPONSABILIDAD.</u></strong></p>
              </div>

            </div>
            <div className="row">

              <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto">
                <p className="text_sin_salto">WS Cargo no asume ninguna responsabilidad respecto de la calidad, marca o cualquier otro aspecto de las mercancías que el Cliente haya adquirido y enviado a través de los servicios de WS Cargo y/o que sean transportadas a través de los servicios de WS Cargo. Cualquier reclamo, disconformidad o disputa respecto a la calidad, marca, o cualquier otro aspecto inherente a los productos adquiridos por el Cliente deberán ser consultados y reclamados directamente con su proveedor, no teniendo WS Cargo relación alguna con éste. Asimismo, WS Cargo no será responsable de los retrasos de las entregas de las mercaderías enviadas a WS Cargo, (multas o retención de la carga en caso de aforo, por incumplimiento a la norma).</p>
              </div>

            </div>
            <div className="row mt-3">

              <div className="d-none d-l-block col-12 d-flex flex-column text-left PropuestaPdfTexto">
                <p className="text_sin_salto"><strong><u>XI. CONSOLIDACIÓN DE ENVÍOS MÚLTIPLES Y EMBARQUES.</u></strong></p>
              </div>

            </div>
            <div className="row">

              <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto">
                <p  >WS Cargo podrá consolidar los envíos del Cliente de modo de optimizar los distintos envíos ingresados a WS Cargo.</p>
                <p  >Una vez arribados los productos a la dirección de WS Cargo en China, y cuando éstos estén en condiciones de embarcarse en dirección a Chile, para los envíos aéreos se embarcarán automáticamente en el primer vuelo programado por WS Cargo disponible a Chile y, para envíos marítimos en el primer contenedor consolidado listo para envío a puerto.</p>
                <p  >Las encomiendas serán embarcadas una vez que el Cliente haya acompañado todos los documentos necesarios para su debido transporte y se cumpla con los requisitos establecidos por las legislaciones competentes.</p>
              </div>

            </div>
            <div className="row mt-3">

              <div className="d-none d-l-block col-12 d-flex flex-column text-left PropuestaPdfTexto">
                <p className="text_sin_salto"><strong><u>XII. PLAZOS Y ENTREGA.</u></strong></p>
              </div>

            </div>
            <div className="row">
              <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto">
                <p  >Los despachos materia del presente contrato que no presenten reparos y/o solicitudes asociadas, se realizarán en un plazo promedio de 2 a 7 días hábiles desde su recepción en China, siempre y cuando los envíos arriben con packing list, factura y la documentación cumpla con todos los requisitos y normas aduaneras y condiciones indicadas en este contrato. El Cliente declara conocer que el plazo anterior será contabilizado una vez que su envío haya sido recepcionado en WS Cargo sin incidencias.</p>
                <p  >WS Cargo no se responsabiliza por retrasos ocasionados por causa de fuerza mayor, caso fortuito o problemas ajenos a su control, como es el caso de huelgas, imprevistos de líneas aéreas, en aeropuertos o puertos marítimos, cierre de fronteras, problemas aduaneros o de la documentación que se adjunta al envío.</p>
              </div>
            </div>
          </div> */}

        {/* pag */}
        <div className="propuesta_page">
          {/* cabecera */}
          <div className="justify-start mb-5">
            <img width="100px" src="../../Logo.jpg" />
            <div className="text-cabecera">
              <h3 className="text-xs fw-bold">
                <strong>WS Cargo SPA</strong>
              </h3>
              <h4 className="text-xs">
                Oficina: Av Andrés Bello 2299, Of. 202, Providencia
              </h4>
              <h4 className="text-xs">
                Bodega: Camino el Noviciado #1945, Pudahuel
              </h4>
            </div>
          </div>

          <h2 className="title-h4 fw-bold text-danger mb-3"><u>BORRADOR DE PROPUESTA COMERCIAL, NO VALIDO COMO ACUERDO COMERCIAL{" "}</u></h2>

          <div className="row mb-3">
            <div className='col-12 mb-3'>
              <h4 className="text fw-bold"><u>IX. COSTOS DEL TRANSPORTE.</u></h4>
            </div>
            <div className="col-12 text-justify">
              <p className="text-small">La tarifa del flete aéreo y terrestre se aplicará en base al mayor valor entre su peso real expresado en kilos dividido por 500 y su peso volumétrico, siendo el peso volumétrico calculado de la siguiente forma: largo, por ancho, por alto, lo anterior expresado en centímetros, y dicho resultado dividido por diez mil. La unidad de cobro mínima es de una unidad.</p>
            </div>
          </div>

          <div className="row mb-3">
            <div className='col-12 mb-3'>
              <h4 className="text fw-bold"><u>X. RESPONSABILIDAD.</u></h4>
            </div>
            <div className="col-12 text-justify">
              <p className="text-small">WS Cargo no asume ninguna responsabilidad respecto de la calidad, marca o cualquier otro aspecto de las mercancías que el Cliente haya adquirido y enviado a través de los servicios de WS Cargo y/o que sean transportadas a través de los servicios de WS Cargo. Cualquier reclamo, disconformidad o disputa respecto a la calidad, marca, o cualquier otro aspecto inherente a los productos adquiridos por el Cliente deberán ser consultados y reclamados directamente con su proveedor, no teniendo WS Cargo relación alguna con éste. Asimismo, WS Cargo no será responsable de los retrasos de las entregas de las mercaderías enviadas a WS Cargo, (multas o retención de la carga en caso de aforo, por incumplimiento a la norma).</p>
            </div>
          </div>

          <div className="row mb-3">
            <div className='col-12 mb-3'>
              <h4 className="text fw-bold"><u>XI. CONSOLIDACIÓN DE ENVÍOS MÚLTIPLES Y EMBARQUES.</u></h4>
            </div>
            <div className="col-12 text-justify">
              <p className="text-small mb-2">WS Cargo podrá consolidar los envíos del Cliente de modo de optimizar los distintos envíos ingresados a WS Cargo.</p>
              <p className="text-small mb-2">Una vez arribados los productos a la dirección de WS Cargo en China, y cuando éstos estén en condiciones de embarcarse en dirección a Chile, para los envíos aéreos se embarcarán automáticamente en el primer vuelo programado por WS Cargo disponible a Chile y, para envíos marítimos en el primer contenedor consolidado listo para envío a puerto.</p>
              <p className="text-small">Las encomiendas serán embarcadas una vez que el Cliente haya acompañado todos los documentos necesarios para su debido transporte y se cumpla con los requisitos establecidos por las legislaciones competentes.</p>
            </div>
          </div>

          <div className="row">
            <div className='col-12 mb-3'>
              <h4 className="text fw-bold"><u>XII. PLAZOS Y ENTREGA.</u></h4>
            </div>
            <div className="col-12 text-justify">
              <p className="text-small mb-2">Los despachos materia del presente contrato que no presenten reparos y/o solicitudes asociadas, se realizarán en un plazo promedio de 2 a 7 días hábiles desde su recepción en China, siempre y cuando los envíos arriben con packing list, factura y la documentación cumpla con todos los requisitos y normas aduaneras y condiciones indicadas en este contrato. El Cliente declara conocer que el plazo anterior será contabilizado una vez que su envío haya sido recepcionado en WS Cargo sin incidencias.</p>
              <p className="text-small">WS Cargo no se responsabiliza por retrasos ocasionados por causa de fuerza mayor, caso fortuito o problemas ajenos a su control, como es el caso de huelgas, imprevistos de líneas aéreas, en aeropuertos o puertos marítimos, cierre de fronteras, problemas aduaneros o de la documentación que se adjunta al envío.</p>
            </div>
          </div>
        </div>

        <div className="salto_pagina">
          <div className='border'></div>
        </div>

        {/* <div className="propuesta_page">
            <div className="row mt-5">
              <div className="d-none d-l-block col-6 d-flex flex-column">
                <img width="200px" src="../../Logo.jpg" />
              </div>
              <div className="d-none d-l-block col-6 d-flex flex-column">
                <h1 className="text_sin_salto"><strong>WS Cargo SPA</strong></h1>
                <h4 className="text_sin_salto">Oficina: Av Andrés Bello 2299, Of 701, Providencia</h4>
                <h4 className="text_sin_salto">Bodega: Camino el Noviciado #1945, Pudahuel</h4>
              </div>
            </div>
            <div className="row mt-3 col-12 text-center">
              <h1 className="text-danger"><u><strong>BORRADOR DE PROPUESTA COMERCIAL, NO VALIDO COMO ACUERDO COMERCIAL </strong></u></h1>
            </div>
            <div className="row">
              <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto">
                <p  >Una vez llegada la mercancía a Chile y efectuados todos los trámites de internación, WS Cargo realizará un intento de entrega de los envíos en el destino de la dirección registrada por el cliente. Después del primer intento fallido de entrega, el producto quedará retenido en las bodegas de WS Cargo por un período de 30 días corridos. Si dentro de dicho período el producto no es reclamado por el Cliente, WS Cargo declarará abandonados los productos y procederá a realizarlos para cubrirse de los créditos devengados en su favor.</p>
                <p  >Considera despacho por una vez al lugar indicado por el cliente. Si no nadie recepciona la carga, el siguiente despacho dentro de Santiago tiene un costo de $3.000 por m3 o la proporción con mínimo de $10.000.</p>
                <p  >Entre llegada de carga (Notificación de correo) y despacho se debe coordinar dentro de los siguientes 4 días. Luego se considera almacenaje de $1.500/m3 por día.</p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="d-none d-l-block col-12 d-flex flex-column text-left PropuestaPdfTexto">
                <p className="text_sin_salto"><strong><u>XIII. COBERTURA ADICIONAL.</u></strong></p>
              </div>
            </div>
            <div className="row">
              <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto">
                <p  >Recibiendo WS Cargo el envío encomendado transportar ya embalado, ella cumple su cometido con entregarlo en la dirección indicada sin lesión alguna exterior, entrega que se hará a su destinatario o, en su ausencia, a una persona mayor de edad.</p>
                <p  >La responsabilidad de WS Cargo por pérdidas, desfalcos y averías, se extingue, entre otras causas:</p>
                <p  >a) Por la recepción del envío a su destino, salvo que su destinatario efectuare la competente reserva;</p>
                <p  >b) Si el consignatario recibiere el envío con señales exteriores de faltas o averías, y no protestare en el acto de recibirlo, lo que produce la renuncia a usar de su derecho;</p>
                <p  >c) Si notándose sustracción o daño al tiempo de abrir el envío, el consignatario no hiciere reclamación alguna dentro de las 24 horas siguientes a la recepción.</p>
                <p  >Asimismo, WS Cargo no responderá por los desperfectos técnicos, mecánicos, eléctricos, magnéticos, electrónicos, fotográficos o de grabaciones, que pudieran tener los envíos transportados.</p>
                <p  >WS Cargo ofrece una cobertura gratuita de hasta USD 1.000 por metro cúbico por pérdida de los envíos que han sido enviados a través de WS Cargo, siempre que estas pérdidas sean por causa imputable a WS Cargo con ocasión del transporte o a su internación a Chile. Además, WS Cargo pone a disposición de sus Clientes una cobertura adicional, previa contratación de ésta, por un valor del 0,3% del valor declarado y respaldado del producto, con unmínimo de $15.000 + IVA, cubriendo el valor total del producto hasta un máximo de USD 10.000 por metro cúbico. El Cliente declara conocer y acepta expresamente que WS Cargo no se hará responsable por pérdidas, daños y mermas de los envíos ingresados a WS Cargo ni de cualquier otro daño que derive de ello, por sobre las cifras indicadas anteriormente, tratándose ya sea de la cobertura base o de la adicional en caso que el Cliente la haya contratado.</p>
              </div>
            </div>
          </div> */}

        {/* pag */}
        <div className="propuesta_page">
          {/* cabecera */}
          <div className="justify-start mb-5">
            <img width="100px" src="../../Logo.jpg" />
            <div className="text-cabecera">
              <h3 className="text-xs fw-bold">
                <strong>WS Cargo SPA</strong>
              </h3>
              <h4 className="text-xs">
                Oficina: Av Andrés Bello 2299, Of. 202, Providencia
              </h4>
              <h4 className="text-xs">
                Bodega: Camino el Noviciado #1945, Pudahuel
              </h4>
            </div>
          </div>

          <h2 className="title-h4 fw-bold text-danger mb-3"><u>BORRADOR DE PROPUESTA COMERCIAL, NO VALIDO COMO ACUERDO COMERCIAL{" "}</u></h2>

          <div className="row mb-3">
            {/* <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto"> */}
            <div className="col-12 text-justify">
              <p className='text-small mb-2'>Una vez llegada la mercancía a Chile y efectuados todos los trámites de internación, WS Cargo realizará un intento de entrega de los envíos en el destino de la dirección registrada por el cliente. Después del primer intento fallido de entrega, el producto quedará retenido en las bodegas de WS Cargo por un período de 30 días corridos. Si dentro de dicho período el producto no es reclamado por el Cliente, WS Cargo declarará abandonados los productos y procederá a realizarlos para cubrirse de los créditos devengados en su favor.</p>
              <p className='text-small mb-2'>Considera despacho por una vez al lugar indicado por el cliente. Si no nadie recepciona la carga, el siguiente despacho dentro de Santiago tiene un costo de $3.000 por m3 o la proporción con mínimo de $10.000.</p>
              <p className='text-small'>Entre llegada de carga (Notificación de correo) y despacho se debe coordinar dentro de los siguientes 4 días. Luego se considera almacenaje de $1.500/m3 por día.</p>
            </div>
          </div>

          {/* XIII. COBERTURA ADICIONAL */}
          <div className="row">
            <div className='col-12 mb-3'>
              <h4 className="text fw-bold"><u>XIII. COBERTURA ADICIONAL</u></h4>
            </div>
            <div className="col-12 text-justify">
              <p className='text-small mb-2 text-justify'>Recibiendo WS Cargo el envío encomendado transportar ya embalado, ella cumple su cometido con entregarlo en la dirección indicada sin lesión alguna exterior, entrega que se hará a su destinatario o, en su ausencia, a una persona mayor de edad.</p>
              <p className='text-small mb-2'>La responsabilidad de WS Cargo por pérdidas, desfalcos y averías, se extingue, entre otras causas:</p>
              <p className='text-small mb-2'>a) Por la recepción del envío a su destino, salvo que su destinatario efectuare la competente reserva;</p>
              <p className='text-small mb-2'>b) Si el consignatario recibiere el envío con señales exteriores de faltas o averías, y no protestare en el acto de recibirlo, lo que produce la renuncia a usar de su derecho;</p>
              <p className='text-small mb-2'>c) Si notándose sustracción o daño al tiempo de abrir el envío, el consignatario no hiciere reclamación alguna dentro de las 24 horas siguientes a la recepción.</p>
              <p className='text-small mb-2'>Asimismo, WS Cargo no responderá por los desperfectos técnicos, mecánicos, eléctricos, magnéticos, electrónicos, fotográficos o de grabaciones, que pudieran tener los envíos transportados.</p>
              <p className='text-small'>WS Cargo ofrece una cobertura gratuita de hasta USD 1.000 por metro cúbico por pérdida de los envíos que han sido enviados a través de WS Cargo, siempre que estas pérdidas sean por causa imputable a WS Cargo con ocasión del transporte o a su internación a Chile. Además, WS Cargo pone a disposición de sus Clientes una cobertura adicional, previa contratación de ésta, por un valor del 0,3% del valor declarado y respaldado del producto, con unmínimo de $15.000 + IVA, cubriendo el valor total del producto hasta un máximo de USD 10.000 por metro cúbico. El Cliente declara conocer y acepta expresamente que WS Cargo no se hará responsable por pérdidas, daños y mermas de los envíos ingresados a WS Cargo ni de cualquier otro daño que derive de ello, por sobre las cifras indicadas anteriormente, tratándose ya sea de la cobertura base o de la adicional en caso que el Cliente la haya contratado.</p>
            </div>
          </div>
        </div>

        <div className="salto_pagina">
          <div className='border'></div>
        </div>

        {/* <div className="propuesta_page">
            <div className="row mt-5">
              <div className="d-none d-l-block col-6 d-flex flex-column">
                <img width="200px" src="../../Logo.jpg" />
              </div>
              <div className="d-none d-l-block col-6 d-flex flex-column">
                <h1 className="text_sin_salto"><strong>WS Cargo SPA</strong></h1>
                <h4 className="text_sin_salto">Oficina: Av Andrés Bello 2299, Of 701, Providencia</h4>
                <h4 className="text_sin_salto">Bodega: Camino el Noviciado #1945, Pudahuel</h4>
              </div>
            </div>
            <div className="row mt-3 col-12 text-center">
              <h1 className="text-danger"><u><strong>BORRADOR DE PROPUESTA COMERCIAL, NO VALIDO COMO ACUERDO COMERCIAL </strong></u></h1>
            </div>
            <div className="row mt-3">
              <div className="d-none d-l-block col-12 d-flex flex-column text-left PropuestaPdfTexto">
                <p className="text_sin_salto"><strong><u>XIV. INDEMNIZACIÓN</u></strong></p>
              </div>
            </div>
            <div className="row">
              <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto">
                <p className="text_sin_salto">El Cliente acepta expresamente que WS Cargo pagará, por cuenta y cargo del Cliente, los aranceles, cargos, tasas, e impuestos que sean necesarios para ingresar los envíos a Chile. El Cliente ha declarado en virtud del presente contrato conocer la legislación tributaria y aduanera que rige las importaciones a Chile, en virtud de lo anterior, sicon ocasión de la importación de sus envíos derivaren multas o sanciones atribuibles al Cliente, éste mantendrá en todo caso indemne a WS Cargo de todo reclamo, multa, sanción, demanda y cualquier acción que producto de los envíos o su internación sea condenado WS Cargo.</p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="d-none d-l-block col-12 d-flex flex-column text-left PropuestaPdfTexto">
                <p className="text_sin_salto"><strong><u>XV. LEY APLICABLE</u></strong></p>
              </div>
            </div>
            <div className="row">
              <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto">
                <p className="text_sin_salto">Este contrato y los derechos y deberes de las partes, emanados del mismo, estarán regidos por y serán interpretados de acuerdo con las leyes chilenas.</p>
              </div>
            </div>
            <div className="row mt-3">
              <div className="d-none d-l-block col-12 d-flex flex-column text-left PropuestaPdfTexto">
                <p className="text_sin_salto"><strong><u>XVI. DOMICILIO.</u></strong></p>
              </div>
            </div>
            <div className="row">
              <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto">
                <p className="text_sin_salto">Para todos los efectos derivados del presente contrato, las partes fijan su domicilio en la ciudad y comuna de Santiago, República de Chile.</p>
              </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <div className="row mt-5">
              <div className="d-none d-l-block col-6 d-flex flex-column text-center text_sin_salto PropuestaPdfTextoFirma">
                <p className="text_sin_salto"><u>____________________________</u></p>
                <p className="text_sin_salto"><u>FIRMA CLIENTE</u></p>
              </div>
              <div className="d-none d-l-block col-6 d-flex flex-column text-center text_sin_salto PropuestaPdfTextoFirma">
                <p className="text_sin_salto"><u>____________________________</u></p>
                <p className="text_sin_salto"><u>{selectedComer.nombre} {selectedComer.apellidos}</u></p>
                <p className="text_sin_salto"><u>EJECUTIVO COMERCIAL</u></p>
                <p className="text_sin_salto"><u>WS CARGO</u></p>
                <p className="text_sin_salto"><u>{selectedComer.telefono}</u></p>
              </div>

            </div>
          </div> */}

        {/* pag */}
        <div className="propuesta_page">
          {/* cabecera */}
          <div className="justify-start mb-5">
            <img width="100px" src="../../Logo.jpg" />
            <div className="text-cabecera">
              <h3 className="text-xs fw-bold">
                <strong>WS Cargo SPA</strong>
              </h3>
              <h4 className="text-xs">
                Oficina: Av Andrés Bello 2299, Of. 202, Providencia
              </h4>
              <h4 className="text-xs">
                Bodega: Camino el Noviciado #1945, Pudahuel
              </h4>
            </div>
          </div>
          <h2 className="title-h4 fw-bold text-danger mb-3"><u>BORRADOR DE PROPUESTA COMERCIAL, NO VALIDO COMO ACUERDO COMERCIAL{" "}</u></h2>

          {/* XIV. INDEMNIZACIÓN */}
          <div className="row mb-3">
            <div className='col-12 mb-3'>
              <h4 className="text fw-bold"><u>XIV. INDEMNIZACIÓN</u></h4>
            </div>
            <div className="col-12">
              <p className="text-small text-justify">El Cliente acepta expresamente que WS Cargo pagará, por cuenta y cargo del Cliente, los aranceles, cargos, tasas, e impuestos que sean necesarios para ingresar los envíos a Chile. El Cliente ha declarado en virtud del presente contrato conocer la legislación tributaria y aduanera que rige las importaciones a Chile, en virtud de lo anterior, sicon ocasión de la importación de sus envíos derivaren multas o sanciones atribuibles al Cliente, éste mantendrá en todo caso indemne a WS Cargo de todo reclamo, multa, sanción, demanda y cualquier acción que producto de los envíos o su internación sea condenado WS Cargo.</p>
            </div>
          </div>

          {/* XV. LEY APLICABLE */}
          <div className="row mb-3">
            <div className='col-12 mb-3'>
              <h4 className="text fw-bold"><u>XV. LEY APLICABLE</u></h4>
            </div>
            <div className="col-12">
              <p className="text-small text-justify">Este contrato y los derechos y deberes de las partes, emanados del mismo, estarán regidos por y serán interpretados de acuerdo con las leyes chilenas.</p>
            </div>
          </div>

          {/* XVI. DOMICILIO */}
          <div className="row mb-3">
            <div className='col-12 mb-3'>
              <h4 className="text-small fw-bold"><u>XVI. DOMICILIO</u></h4>
            </div>
            {/* <div className="d-none d-l-block col-12 d-flex flex-column text-adjust text_sin_salto PropuestaPdfTexto"></div> */}
            <div className="col-12">
              <p className="text-small text-justify">Para todos los efectos derivados del presente contrato, las partes fijan su domicilio en la ciudad y comuna de Santiago, República de Chile.</p>
            </div>
          </div>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>

          <div className="row mt-5">
            <div className="d-none d-l-block col-6 d-flex flex-column text-center text_sin_salto PropuestaPdfTextoFirma">
              <p className="text_sin_salto"><u>____________________________</u></p>
              <p className="text fw-bold">FIRMA CLIENTE</p>
            </div>

            <div className="d-none d-l-block col-6 d-flex flex-column text-center text_sin_salto PropuestaPdfTextoFirma">
              <p className="text_sin_salto"><u>____________________________</u></p>
              <p className="text fw-bold"><u>{selectedComer.nombre} {selectedComer.apellidos}</u></p>
              <p className="text-small">Ejecutivo comercial</p>
              <p className="text-small">WS CARGO</p>
              <p className="text-small"><u>{selectedComer.telefono}</u></p>
            </div>
          </div>
        </div>
      </CardContent>
    </div>
  )
};
export default PropuestaComercialPdf;