import { 
    GET_TRACK,
    GET_TRACK_SUCCESS,
    GET_TRACK_HISTORY,
    GET_TRACK_HISTORY_SUCCESS,
    GET_TRACK_DOCUMENTS,
    GET_TRACK_DOCUMENTS_SUCCESS,
    GET_TRACK_FECHAS_ESTIMADAS,
    GET_TRACK_FECHAS_ESTIMADAS_SUCCESS
    , TCK_SETMOSTRARMODALPAGARSERVICIO
    , TCK_MODALPAGARSERVICIO_GETINFO
    , TCK_MODALPAGARSERVICIO_GETINFO_SUCCESS
    , TCK_SETMOSTRARLOADINGPAGOS
    , TCK_SETMOSTRARLOADINGPAGOS_SUCCESS,
    GET_TRACK_LIST_MODIFY_CNSLD,
    GET_TRACK_LIST_MODIFY_CNSLD_SUCCESS,
    GET_TIME_LINE,
    GET_TIME_LINE_SUCCESS
} from '../constants/Track';

export const Tck_SetMostrarLoadingPagos= (payload) => {
    return {
        type: TCK_SETMOSTRARLOADINGPAGOS,
        payload: payload,
    };
};

export const Tck_SetMostrarLoadingPagos_Success= (payload) => {
    return {
        type: TCK_SETMOSTRARLOADINGPAGOS_SUCCESS,
        payload: payload,
    };
};

export const Tck_ModalPagarServicio_GetInfo_Success= (payload) => {
    return {
        type: TCK_MODALPAGARSERVICIO_GETINFO_SUCCESS,
        payload: payload,
    };
};

export const Tck_ModalPagarServicio_GetInfo= (payload) => {
    return {
        type: TCK_MODALPAGARSERVICIO_GETINFO,
        payload: payload,
    };
};

export const Tck_SetMostrarModalPagarServicio= (payload) => {
    return {
        type: TCK_SETMOSTRARMODALPAGARSERVICIO,
        payload: payload,
    };
};



export const getTrack = (payload) => {
    return {
        type: GET_TRACK,
        payload: payload
    };
};

export const getTrackSuccess = (payload ) => {
    return {
        type: GET_TRACK_SUCCESS,
        payload: payload    
    };
};

export const getTrackHistory = (payload) => {
    return {
        type: GET_TRACK_HISTORY,
        payload: payload
    };
};

export const getTrackHistorySuccess = (payload ) => {
    return {
        type: GET_TRACK_HISTORY_SUCCESS,
        payload: payload    
    };
};

export const getTrackDocuments = (payload) => {
    return {
        type: GET_TRACK_DOCUMENTS,
        payload: payload
    };
};

export const getTrackDocumentsSuccess = (payload ) => {
    return {
        type: GET_TRACK_DOCUMENTS_SUCCESS,
        payload: payload    
    };
};

export const getTrackFechasEstimadas = (payload ) => {
    return {
        type: GET_TRACK_FECHAS_ESTIMADAS,
        payload: payload    
    };
};

export const getTrackFechasEstimadasSuccess = (payload ) => {
    return {
        type: GET_TRACK_FECHAS_ESTIMADAS_SUCCESS,
        payload: payload    
    };
};

export const getTrackListModifyCnsld = (payload ) => {
    return {
        type: GET_TRACK_LIST_MODIFY_CNSLD,
        payload: payload    
    };
};

export const getTrackListModifyCnsldSuccess = (payload ) => {
    return {
        type: GET_TRACK_LIST_MODIFY_CNSLD_SUCCESS,
        payload: payload    
    };
};


export const getTimeLine = (payload) => {
    return {
        type: GET_TIME_LINE,
        payload: payload
    };
};

export const getTimeLineSuccess = (payload ) => {
    return {
        type: GET_TIME_LINE_SUCCESS,
        payload: payload
    };
};

