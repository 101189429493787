import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';
import {
 getInfoTrackConsolidadoRapidoExpress
} from './uploadDocsExpress/Actions'; 

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import GetAppIcon from '@material-ui/icons/GetApp';
import ImageIcon from '@material-ui/icons/Image';
import Gallery from 'modules/detalleServicio/modulos/Logistica/GalleryProveedor';

import NotInterestedIcon from '@material-ui/icons/NotInterested';
import moment from 'moment';

import {
    InputLabel,
    Button,
    TextField,
    makeStyles,
    IconButton,
    InputAdornment,
    CircularProgress,
    FormControl,
    FormHelperText,
    Hidden,
    Tooltip,
    withStyles
  } from "@material-ui/core";

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#263238',
      color: '#fff',
      padding: "6px 8px",
      fontSize: 11,
    },
  }))(Tooltip);

  const useStyles = makeStyles((theme) => ({
    link: {
      fontSize: "14px",
      color: "#8F8F8F",
      fontWeight: "500",
      "&:hover": {
        color: "#FC565C"
      }
    },
    titleLogin: {
      paddingLeft: "12px",
      margin: "16px 0"
    },
    iconButtom: {
      "&:hover": {
        background: "transparent"
      }
    }
  }));
const CondicionesRecepcion=(props)=>{
    const classes = useStyles();
    const [hash, setHash] = useState(props.match.params.hash);
    const { item} = useSelector(({ UploadDocumentsExpress }) => UploadDocumentsExpress);
    const [dataGallery, setDataGallery] = useState(false);
    const [gallery, setGallery] = useState(false);
    const handleClickOpen = () => {
        setGallery(true);
    };
    const handleClickClose = () => {
      setGallery(false);
    };
    const dispatch = useDispatch();
    useEffect(() => {
      if(hash!=null && hash.length>0){
        dispatch(getInfoTrackConsolidadoRapidoExpress({hash:hash}));
      }
   }, [hash]);

    return (
        <div className="app-login-container">
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: .5, duration: 1.3 }}
            className="app-login"
        >
            <div className="app-login-content">
            {item!=null &&
            <div className="cards-body pt-0">
                  <div className="row">
                    <div className="col-12 col-lg-12 mb-4">
                      <div className="cards-header">
                        <h4 className="text-small font-weight-400">Detalle</h4>
                        <div className="border-title"></div>
                      </div>

                      <div className="list-data">
                      <div className="list_item">
                          <div className="list_tittle">
                            <h4 className="text-small font-weight-400">Proveedor</h4>
                          </div>
                          <div className="list_desciption">
                            <h5 className="text-small font-weight-400">{item.fk_proveedor_nombre}</h5>
                          </div>
                        </div>
                        <div className="list_item">
                          <div className="list_tittle">
                            <h4 className="text-small font-weight-400">Bultos arrivados</h4>
                          </div>
                          <div className="list_desciption">
                            <h5 className="text-small font-weight-400">{item.bultos_completos}</h5>
                          </div>
                        </div>

                        <div className="list_item">
                          <div className="list_tittle">
                            <h4 className="text-small font-weight-400">Bultos esperados</h4>
                          </div>
                          <div className="list_desciption">
                            <h5 className="text-small font-weight-400">{item.cantidad_bultos}</h5>
                          </div>
                        </div>

                        <div className="list_item">
                          <div className="list_tittle">
                            <h4 className="text-small font-weight-400">Peso Esperado</h4>
                          </div>
                          <div className="list_desciption">
                            <h5 className="text-small font-weight-400">{item.peso!=null ? Number(item.peso).toFixed(2):0} Kg</h5>
                          </div>
                        </div>

                        <div className="list_item">
                          <div className="list_tittle">
                            <h4 className="text-small font-weight-400">Vol. esperado</h4>
                          </div>
                          <div className="list_desciption">
                            <h5 className="text-small font-weight-400">{item.volumen!=null ? Number(item.volumen).toFixed(2):0} M³</h5>
                          </div>
                        </div>

                        <div className="list_item">
                          <div className="list_tittle">
                            <h4 className="text-small font-weight-400">pri. arribo carga</h4>
                          </div>
                          <div className="list_desciption">
                            <h5 className="text-small font-weight-400">{item.primera_recepcion && item.primera_recepcion != null ? moment(item.primera_recepcion).format('DD-MM-YYYY') : 'Sin información'}</h5>
                          </div>
                        </div>

                        <div className="list_item">
                          <div className="list_tittle">
                            <h4 className="text-small font-weight-400">últ. arribo carga</h4>
                          </div>
                          <div className="list_desciption">
                            <h5 className="text-small font-weight-400">{item.ultima_recepcion && item.ultima_recepcion != null ? moment(item.ultima_recepcion).format('DD-MM-YYYY') : 'Sin información'}</h5>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-12 col-lg-12 mb-4">
                      <div className="cards-header">
                        <h4 className="text-small font-weight-400">Estado de carga</h4>
                        <div className="border-title"></div>
                      </div>

                      <div className='list-data'>
                        {
                          item.grouped && item.grouped.length > 0 ?
                            item.grouped.map((item2, index) => (
                              <>
                                <Hidden xsDown>
                                  <div className="list_item" key={index}>
                                    <div className="list_tittle">
                                      <h4 className="text-small font-weight-400">{/*item2.producto == '111' || item2.producto == 'NN' ? ' carga OK ' : item2.producto*/} {(item2.estado_carga == 0 || item2.estado_carga ==null) && '(OK)'} {item2.estado_carga == 1 && `(DAÑADO)`} {item2.estado_carga == 2 && `(HUMEDO)`}</h4>
                                    </div>

                                    <div className="list_desciption justify-space-between">
                                      <h5 className="text-small font-weight-400">
                                        {item2.cantidad_bultos} Bultos / {item2.volumen!=null ? Number(item2.volumen).toFixed(2):0} m³ / {item2.peso!=null ? Number(item2.peso).toFixed(2):0} kg
                                      </h5>

                                      {( item2.foto1 == 'TRUE' || item2.foto2 == 'TRUE' || item2.foto3 == 'TRUE') ?
                                        // <IconButton
                                        //   aria-label="detalle estado de carga"
                                        //   onClick={() => { setDataGallery(item2); handleClickOpen(); }}
                                        //   className="icon-button icon-button-small"
                                        // >
                                        //   <ImageIcon />
                                        // </IconButton>

                                        <ImageIcon onClick={() => { setDataGallery(item2); handleClickOpen(); }} className={`icon-color-success ${classes.iconDocs}`}/>

                                        :
                                        <LightTooltip title="Imagen no disponible"><ImageIcon className={`icon-color-default ${classes.iconDocs}` } /></LightTooltip>
                                      }
                                    </div>
                                  </div>
                                </Hidden>

                                <Hidden smUp>
                                  <div className={`list_item ${classes.listItem}`}>
                                    <div className="list-data justify-space-between">
                                      <h4 className="text-small font-weight-400">
                                        {/*item2.producto == '111' || item2.producto == 'NN' ? ' carga OK' : item2.producto*/}  {(item2.estado_carga == 0 || item2.estado_carga ==null) && '(OK)'} {item2.estado_carga == 1 && `(Dañado)`} {item2.estado_carga == 2 && `(Humedad)`}
                                      </h4>

                                      {/* <IconButton
                                        aria-label="detalle estado de carga"
                                        onClick={handleClickOpen}
                                        className="icon-button icon-button-small"
                                      >
                                        <ImageIcon />
                                      </IconButton> */}

                                      {(item2.foto1 == 'TRUE' || item2.foto2 == 'TRUE' || item2.foto3 == 'TRUE') ?
                                        <IconButton
                                          aria-label="detalle estado de carga"
                                          onClick={() => { setDataGallery(item2); handleClickOpen(); }}
                                          className="icon-button icon-button-small"
                                        >
                                          <ImageIcon />
                                        </IconButton>

                                        :
                                        <LightTooltip title="Imagen no disponible">
                                          <span>
                                            <Button className='button button-small button-disable'><ImageIcon /></Button>
                                          </span>
                                        </LightTooltip>
                                      }
                                    </div>

                                    <div className="list-data">
                                      <h5 className="text-small font-weight-400">{item2.cantidad_bultos} Bultos / {item2.volumen!=null ? Number(item2.volumen).toFixed(2):0} m³ / {item2.peso!=null ? Number(item2.peso).toFixed(2):0} kg</h5>
                                    </div>
                                  </div>
                                  
                                </Hidden>
                              </>
                            )) :
                            <div className="px-4 px-md-0">
                              <h4 className="text-small font-weight-400">No hay cargas recepcionadas</h4>
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
            }

          <Gallery
                  gallery={gallery}
                  data={dataGallery}
                  handleClickClose={handleClickClose}
                />
            </div>
        </motion.div>
        <div className="app-logo-content justify-center">
        <Link className="logo-lg" to="/" title="Jambo">
          <img
            src={require("../assets/images/wscargologo.png")}
            alt="jambo"
            title="jambo"
          />
        </Link>
        </div>
        </div>
    );
}

export default CondicionesRecepcion;