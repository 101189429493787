import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/Help';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { nanoid } from 'nanoid'
import { helpHttp } from '../helpers/helpHttp';


import {
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    InputLabel,
    TextField,
    Tooltip,
    InputAdornment,
    Button,
    Typography,
    IconButton,
    Avatar,
    Hidden,
    Input

} from "@material-ui/core";

// toolpip
const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#263238',
        color: '#fff',
        maxWidth: 300,
        padding: "12px 16px",
        "& .MuiTypography-h5": {
            fontSize: "14px"
        },
        "& .MuiTypography-h6": {
            fontSize: "10px"
        }
    }
}))(Tooltip);



const useStyles = makeStyles((theme) => ({
    inputAdornment: {
        "& .MuiTypography-body1": {
            fontSize: "12px"
        }
    },
    icon: {
        fontSize: "16px",
        color: '#828282'
    },
    avatar: {
        width: "40px !important",
        height: "40px !important",
    },
    title: {
        paddingLeft: "12px"
    }
}));

// 
const initialForm = {
    nombre_chino: '',
    nombre_espanol_ingles: '',
    volumen_esperado: '',
    peso_esperado: '',
    bultos_esperado: '',
    id: null
}

const IniciarServicio = ({ setResponse, setLoading, setIniciarServicio, iniciarServicio, handleCloseIniciarServicio }) => {
    const classes = useStyles();
    const [form, setForm] = useState(initialForm)
    const [proveedor, setProveedor] = useState([])
    const [editProveedor, setEditProveedor] = useState(null)
    const [dataToProveedor, setDataToProveedor] = useState(null)

    const handleChange = (e) => {
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        })
    }


    const handleSubmit = (e) => {
        e.preventDefault()

        // if (!form.name || !form.apellido) {
        //     alert("vacio");
        //     return;
        // }

        if (form.id === null) {
            createData(form);
        } else {
            updateData(form);
        }

        handleReset();
    }

    const handleReset = (e) => {
        // setFormContactoCliente(initialForm)
        setForm(initialForm)
        setEditProveedor(null)

    }

    const createData = (data) => {
        data.id = Date.now();
        // setProveedor([...proveedor, data]);
        setProveedor([...proveedor, {
            id: nanoid(),
            nombre_chino: form.nombre_chino,
            nombre_espanol_ingles: form.nombre_espanol_ingles,
            volumen_esperado: form.volumen_esperado,
            peso_esperado: form.peso_esperado,
            bultos: form.bultos_esperado

        }]);
    }

    const updateData = (data) => {
        let newData = proveedor.map(el => el.id === data.id ? data : el);
        setProveedor(newData);
    }

    const deleteData = (id) => {
        let newData = proveedor.filter(el => el.id !== id);
        setProveedor(newData);
    }

    useEffect(() => {
        if (editProveedor) {
            setForm(editProveedor);
        } else {
            setForm(initialForm)
        }
    }, [editProveedor])

    //
    const enviar = () => {
        // console.log(proveedor)
        handleReset();
        // handleCloseIniciarServicio();


        // setErrors(validationsForm(form))
        // console.log(Object.keys(errors).length === 0)
        setIniciarServicio(false);
        setLoading(true)
        helpHttp()
            .post("https://formsubmit.co/ajax/alonsotrina22@gmail.com", {
                body: form,
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                }
            })
            .then((res) => {
                setLoading(false)
                setResponse(true)
                setTimeout(() => setResponse(false), 4000);
                setForm(initialForm)
            });

        // if (Object.keys().length === 0) {
        //     // handleCloseIniciarServicio();
        //     // handleReset();
        //     setIniciarServicio(false);
        //     setLoading(true)
        //     helpHttp()
        //         .post("https://formsubmit.co/ajax/alonsotrina22@gmail.com", {
        //             body: form,
        //             headers: {
        //                 "Content-Type": "application/json",
        //                 Accept: "application/json",
        //             }
        //         })
        //         .then((res) => {
        //             setLoading(false)
        //             setResponse(true)
        //             setTimeout(() => setResponse(false), 4000);
        //             setForm(initialForm)
        //         });
        // }
        // return;
    }

    return (
        <Dialog open={iniciarServicio} onClose={handleCloseIniciarServicio} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className="mt-4 pb-1">Iniciar Servicio (Tarifa actual 405 USD/m3)</DialogTitle>
            <DialogContent className="mb-4 pb-3">

                <form className="input-material-ui" onSubmit={handleSubmit}>
                    <div className="row">
                        <div className="col-12 mb-3">
                            <div className='align-item-center'>

                                <h5 className="text font-weight-500"> {editProveedor ? "Actualizar Proveedor" : "Agregar Proveedor"}</h5>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography variant="h5" color="inherit" className='mb-2'>- Máximo 3 proveedores si trae menos de 5 m3.</Typography>
                                            <Typography variant="h5" color="inherit">- Máximo 5 proveedores si trae sobre de 5 m3, con previa autorización por contenedor.</Typography>
                                        </React.Fragment>
                                    }
                                >
                                    <HelpIcon className={`ml-1 ${classes.icon}`} />
                                </HtmlTooltip>
                            </div>
                            <p className="text-small font-weight-300">Máximo 3 proveedores si trae menos de 5 m3.</p>
                        </div>
                    </div>

                    {/* Nombre proveedor */}
                    <div className="row">
                        <div className="col-12 mb-3">
                            <p className={`text-small font-weight-500 ${classes.title}`}>Nombre Proveedor</p>
                        </div>

                        <div className="col-12 col-lg-6 mb-3 mb-md-0">
                            <InputLabel className="Label_Input_Format">Nombre Chino</InputLabel>
                            <TextField
                                name="nombre_chino"
                                placeholder="nombre chino del proveedor"
                                onChange={handleChange}
                                value={form.nombre_chino}
                            />
                        </div>

                        <div className="col-12 col-lg-6">
                            <InputLabel className="Label_Input_Format">Nombre español / ingles</InputLabel>
                            <TextField
                                name="nombre_espanol_ingles"
                                placeholder="nombre español / ingles del proveedor"
                                onChange={handleChange}
                                value={form.nombre_espanol_ingles}
                            />
                        </div>
                    </div>

                    <div className="border my-4"></div>

                    {/* Datos de carga  */}
                    <div className="row">
                        <div className="col-12 mb-3">
                            <p className={`text-small font-weight-500 ${classes.title}`}>Datos de carga</p>
                        </div>

                        <div className="col-12 col-lg-6 mb-3">
                            <InputLabel className="Label_Input_Format">Volumen M3 (esperado)</InputLabel>
                            <TextField
                                name="volumen_esperado"
                                placeholder="80"
                                type="number"
                                onChange={handleChange}
                                value={form.volumen_esperado}
                                className={classes.inputAdornment}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">M3</InputAdornment>
                                }}
                            />
                        </div>

                        <div className="col-12 col-lg-6 mb-3 mb-md-0">
                            <InputLabel className="Label_Input_Format">Peso Kg (esperado)</InputLabel>
                            <TextField
                                name="peso_esperado"
                                placeholder="80"
                                type="number"
                                onChange={handleChange}
                                value={form.peso_esperado}
                                className={classes.inputAdornment}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">Kg</InputAdornment>
                                }}
                            />
                        </div>

                        <div className="col-12 col-lg-6">
                            <InputLabel className="Label_Input_Format">Nº bultos</InputLabel>
                            <TextField
                                name="bultos_esperado"
                                placeholder="80"
                                type="number"
                                onChange={handleChange}
                                value={form.bultos_esperado}
                            />
                        </div>
                    </div>

                    <div className="border my-4"></div>

                    {/* Documentos */}
                    <div className="row">
                        <div className="col-12 mb-3">
                            <p className={`text-small font-weight-500 ${classes.title}`}>Documentos</p>
                        </div>

                        <div className="col-12 mb-3 input-file-ui">
                            <InputLabel className="Label_Input_Format">Packing List (Chino)</InputLabel>

                            <Input
                                className="my-0 input-file"
                                type="file"
                                name="Packing_List_Chino"
                            />
                        </div>

                        <div className="col-12 input-file-ui">
                            <InputLabel className="Label_Input_Format">Packing List (Ingles)</InputLabel>
                            <Input
                                className="my-0 input-file"
                                type="file"
                                name="Packing_List_Ingles"
                            />
                        </div>
                    </div>

                    {/* acción para agregar proveedor */}
                    <Button
                        className="button button-gray button-small mt-4"
                        type="submit"
                    >
                        {editProveedor ? "Actualizar Proveedor" : "agregra proveedor"}
                    </Button>
                </form>

                <div className="border my-4"></div>

                {/* Listados de proveedores  */}
                <h5 className="text font-weight-500">Proveedores Registrados</h5>

                {proveedor.length > 0 ?

                    (
                        proveedor.map((item, index) => (

                            <div className="list_item app-lista-empresa" key={index}>
                                <Avatar className={`app-lista-avatar ${classes.avatar}`}>
                                    {item.nombre_chino.slice(0, 2)}
                                </Avatar>

                                <div className="app-lista-content">
                                    <div className="app-lista-data">
                                        <h4 className="text-small font-weight-700 text-capitalize">{item.nombre_chino}</h4>

                                        {/* data versión desktop */}
                                        <Hidden smDown>
                                            <div className="app-lista-item">
                                                <p className="text-xs font-weight-300">Vol. {item.volumen_esperado} M³</p>
                                                <p className="text-xs font-weight-300 mx-3">Peso {item.peso_esperado} kg</p>
                                                <p className="text-xs font-weight-300">Bultos Esperados {item.bultos_esperado}</p>
                                            </div>
                                        </Hidden>

                                        {/* data versión mobile */}
                                        <Hidden smUp>
                                            <div className="app-lista-item">
                                                <p className="text-xs font-weight-300">
                                                    Vol. {item.volumen_esperado} M³<span className="mx-2">/</span>Peso {item.peso_esperado}kg
                                                </p>
                                                <p className="text-xs font-weight-300">Bultose Esperados {item.bultos_esperado}</p>
                                            </div>
                                        </Hidden>
                                    </div>


                                    <div className='app-lista-acciones'>
                                        <IconButton
                                            onClick={() => {
                                                setEditProveedor(item);
                                            }}
                                            aria-label="editar"
                                            className="icon-button"
                                        >
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => {
                                                deleteData(item.id);
                                            }}
                                            aria-label="Eliminar"
                                            className="icon-button"
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) :
                    (
                        <div className="list_item align-item-center">
                            <p className="text-small font-weight-300">No hay proveedores registrado.</p>
                        </div>
                    )

                }

                <div className="justify-end mt-3">
                    <Button
                        className='button button-outline-danger button-100-mobile mr-1'
                        onClick={() => {
                            handleReset();
                            handleCloseIniciarServicio();
                        }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        className="button button-danger button-100-mobile ml-1"
                        onClick={enviar}
                    >
                        Iniciar Servicio
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default IniciarServicio