import{
    GET_TYPES_NOTIFICATIONS_SUCCESS,
    GET_GLOBAL_LIST_NOTIFICATIONS_SUCCESS,
    GET_LIST_BY_USER_NOTIFICATIONS_SUCCESS,
    GET_USER_ALL_NOTIFICATIONS_SUCCESS,
    CLEAR_ALL_EXPDIG_NOTIFICACIONES_SUCCESS,
} from './Constants';

const INIT_STATE = {
    loader:false,
    showMessage:false,
    alertMessage:{type:'',text:''},
    types:[],
    notifications:[],
    settings:[],
    dataList:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_ALL_EXPDIG_NOTIFICACIONES_SUCCESS:{
            return{
                ...INIT_STATE
            }
        }
        case GET_TYPES_NOTIFICATIONS_SUCCESS:{
            return{
                ...state,
                types:action.payload
            }
        }
       case GET_GLOBAL_LIST_NOTIFICATIONS_SUCCESS:{
           return{
               ...state,
               notifications:action.payload
           }
       }
       case GET_LIST_BY_USER_NOTIFICATIONS_SUCCESS:{
            return{
                ...state,
                settings:action.payload
            }
        }   
        case GET_USER_ALL_NOTIFICATIONS_SUCCESS:{
            return{
                ...state,
                dataList:action.payload
            }
        }
       default:
            return state;
    }
}