import {all, call, fork, put, takeEvery,takeLatest,spawn} from "redux-saga/effects";
import { putRequest, postRequest, getRequest, getErrorRequest, deleteRequest,uploadFilesRequest } from '../../util/apiFetch';
import lodash from 'lodash';
import{
    GET_INFO_SERVICIO_DETALLE_SERVICIO,
    GET_DOCUMENTS_CLIENT_DETALLE_SERVICIO,
    POST_PAGO_DETALLE_SERVICIO,
    GET_PAGOS_DETALLE_SERVICIO,
    GET_HISTORY_DETALLE_SERVICIO,
    GET_DATA_COSTOS_DETALLE_SERVICIO,
    GET_PROPUESTA_COMERCIAL_DETALLE_SERVICIO,
    GET_DIRECCIONES_CLIENTE_DETALLE_SERVICIO,
    POST_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO,
    GET_REGIONES_DETALLE_SERVICIO,
    GET_COMUNAS_DETALLE_SERVICIO,
    GET_RETIRO_DESPACHO_DETALLE_SERVICIO,
    PUT_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO,
    GET_PAGOS_QIAO_DETALLE_SERVICIO,
    GET_COMERCIALES_DETALLE_SERVICIO,
    PUT_UPLOAD_DOCUMENTS_DETALLE_SERVICIO,
    GET_CONFIG_DESPACHO_DETALLE_SERVICIO,
    GET_CONFIG_RETIRO_DETALLE_SERVICIO,
    GET_BLOQUEOS_RETIRO_BY_DAY_DETALLE_SERVICIO,
    GET_DISPONIBILIDAD_DESPACHO_CALENDARIO_DETALLE_SERVICIO,
    POST_AGENDA_RETIRO_BY_MONTH_DETALLE_SERVICIO,

    FINDETAIL_MODALPAGARSERVICIO_GETINFO,
    FINDETAIL_REALIZARPAGOSERVICIO,
    GET_TIMELINE_DETALLE_SERVICIO

} from './Constants';
import{
    showMessageDetalleServicio,
    getInfoServicioDetalleServicioSuccess,
    getDocumentsClientDetalleServicioSuccess,
    postPagoDetalleServicioSuccess,
    getPagosDetalleServicioSuccess,
    getHistoryDetalleServicioSuccess,
    getDataCostosDetalleServicioSuccess,
    getPropuestaComercialDetalleServicioSuccess,
    getDireccionesClienteDetalleServicioSuccess,
    getComunasDetalleServicioSuccess,
    getRegionesDetalleServicioSuccess,
    getRetiroDespachoDetalleServicioSuccess,
    getPagosQiaoDetalleServicioSuccess,
    getComercialesDetalleServicioSuccess,
    postServicioDespachoRetiroDetalleServicioSuccess,
    getConfigRetiroDetalleServicioSuccess,
    getConfigDespachoDetalleServicioSuccess,
    getBloqueosRetiroByDayDetalleServicioSuccess,
    getDisponibilidadDespachoCalendarioDetalleServicioSuccess,
    postAgendaRetiroByMonthDetalleServicioSuccess,
    closeDespachoRetiroSuccessDetalleServicio,

    FINDETAIL_ModalPagarServicio_GetInfo_Success,
    FINDETAIL_RealizarPagoServicio_Success,
    FINDETAIL_SetMostrarLoadingPagos,
    getTimeLineDetalleServicioSuccess
} from './Actions';

function* getSagaInfoServicioDetalleServicioExecute({payload}){
    const requestURL = `servicios/get_info_service/${payload.id}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getInfoServicioDetalleServicioSuccess(obj));
    }
}

function* getSagaDocumentsClientDetalleServicioExecute({payload}){
    const requestURL = `clientes/get-info-documents/${payload.id}`;
    const response= yield call(getRequest,requestURL,null,false);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getDocumentsClientDetalleServicioSuccess(obj));
    }
}

function* postSagaPagoDetalleServicioExecute({payload}){
    const fd=new FormData();

    if(payload.file!=null){
        fd.append('file',payload.file,payload.file.name);
    }
    fd.append('monto',payload.monto);
    fd.append('fecha',payload.fecha);
    const requestURL = `servicios/upload_pagos/${payload.fk_servicio}`;
    const response= yield call(uploadFilesRequest,requestURL,fd);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        //yield put(showMessageDetalleServicio({type:'success',text:'PAGO REGISTRADO CON EXITO'}));
        yield put(postPagoDetalleServicioSuccess(obj));
        yield getSagaPagosDetalleServicioExecute({payload:{id:payload.fk_servicio}})
    }
}

function* getSagaPagosDetalleServicioExecute({payload}){
    const requestURL = `servicios/pagos/${payload.id}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getPagosDetalleServicioSuccess(obj));
    }
}

function* getSagaHistoryDetalleServicioExecute({payload}){
    const requestURL = `servicios/get_service_history/${payload.id}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getHistoryDetalleServicioSuccess(obj));
    }
}

function* getSagaDataCostosDetalleServicioExecute({payload}){
    const requestURL = `servicios/get_service_cost/${payload.id}/${payload.fk_cliente}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getDataCostosDetalleServicioSuccess(obj));
    }
}

function* getSagaPropuestaComercialDetalleServicioExecute({payload}){
    const requestURL = `servicios/get_service_propuesta_comercial/${payload.id}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getPropuestaComercialDetalleServicioSuccess(obj));
    }
}

function* getSagaDireccionesClienteDetalleServicioExecute({payload}){
    const requestURL = `registro-cliente/direcciones/${payload.fk_cliente}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getDireccionesClienteDetalleServicioSuccess(obj));
    }
}

function* postSagaServicioDespachoRetiroDetalleServicioExecute({payload}){
    const requestURL = `servicios/despacho-retiro/${payload.fk_servicio}`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
        yield put(closeDespachoRetiroSuccessDetalleServicio());
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        //yield put(showMessageDetalleServicio({type:'success',text:'Información de despacho retiro ingresada correctamente'}));
        yield put(postServicioDespachoRetiroDetalleServicioSuccess(obj));
        yield getSagaRetiroDespachoDetalleServicioExecute({payload:{id:payload.fk_servicio}});
    }
}

function* getSagaComunasDetalleServicioExecute({payload}){
    const requestURL = `comuna_get_list`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getComunasDetalleServicioSuccess(obj));
    }
}

function* getSagaRegionesDetalleServicioExecute({payload}){
    const requestURL = `region_get_list`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getRegionesDetalleServicioSuccess(obj));
    }
}


function* getSagaRetiroDespachoDetalleServicioExecute({payload}){
    const requestURL = `servicios/retiro-despacho/${payload.id}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getRetiroDespachoDetalleServicioSuccess(obj));
    }
}


function* putSagaServicioDespachoRetiroDetalleServicioExecute({payload}){
    const requestURL = `servicios/despacho-retiro/${payload.fk_servicio}/${payload.id}`;
    const response= yield call(putRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
       // yield put(showMessageDetalleServicio({type:'success',text:'Información de despacho retiro actualizada correctamente'}));
       yield put(postServicioDespachoRetiroDetalleServicioSuccess(obj));
        yield getSagaRetiroDespachoDetalleServicioExecute({payload:{id:payload.fk_servicio}});
    }
}

function* getSagaPagosQiaoDetalleServicioExecute({payload}){
    const requestURL = `servicios/pagos-qiao/${payload.id}/${payload.fk_cliente}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getPagosQiaoDetalleServicioSuccess(obj));
    }
}

function* getSagaComercialesDetalleServicioExecute({payload}){
    const requestURL = `clientes_get_list_comerciales_public`;
    const response= yield call(getRequest,requestURL,null,false);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getComercialesDetalleServicioSuccess(obj));
    }
}


function* putUploadDocsDetalleServicioExecute({payload}){
    if(payload){
        const fd=new FormData();

        if(payload.packingList1!=null){
            fd.append('packingList1',payload.packingList1,payload.packingList1.name);
        }

        if(payload.packingList2!=null){
            fd.append('packingList2',payload.packingList2,payload.packingList2.name);
        }

        fd.append('reset',payload.reset);

        const requestURL = `servicios/upload_docs/${payload.id}`;
        const response= yield call(uploadFilesRequest,requestURL,fd);
        const { error, message } =yield call(getErrorRequest,response);
        if(error){
           // yield put(showMessageTrackingFullEdicion({type:'error',text:message}));
        }else{
            yield put(showMessageDetalleServicio({type:'success',text:'Archivos subidos correctamente'}));
            yield getSagaInfoServicioDetalleServicioExecute({payload:{id:payload.fk_servicio}})
           // alert('Archivos subidos correctamente');
            //yield getTrackingManualDataExecute({flagModal:true,id:action.payload.id});
        }
    }
}

function* getSagaConfigDespachoDetalleServicioExecute(){
    const requestURL = `agenda/despacho/config`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getConfigDespachoDetalleServicioSuccess(obj));
    }
}

function* getSagaConfigRetiroDetalleServicioExecute(){
    const requestURL = `agenda/retiro/config`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getConfigRetiroDetalleServicioSuccess(obj));
    }
}

function* getSagaBloqueosRetiroByDayDetalleServicioExecute({payload}){
    const requestURL = `agenda/retiro/month`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getBloqueosRetiroByDayDetalleServicioSuccess(obj));
    }
 }

 function* getSagaDisponibilidadDespachoCalendarioDetalleServicioExecute(action){
    const requestURL = `agenda/despacho/gestion_logistica/${action.payload.fecha}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getDisponibilidadDespachoCalendarioDetalleServicioSuccess(obj));
    }
}

function* postSagaAgendaRetiroByMonthDetalleServicioExecute({payload}){
    const requestURL = `agenda/retiro/gestion_logistica/${payload.fecha}`;
    const response= yield call(getRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(postAgendaRetiroByMonthDetalleServicioSuccess(obj));
    }
 }

 function* FINDETAIL_ModalPagarServicio_GetInfoExecute(payload){
    const requestURL = `getinfopagoservicio/`+payload.payload;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error)
    {
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }
    else
    {
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(FINDETAIL_ModalPagarServicio_GetInfo_Success(obj));
    }
}

function* FINDETAIL_RealizarPagoServicioExecute({payload}){

    const requestURL = `realizar_pago_servicio`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error)
    {
        yield put(FINDETAIL_SetMostrarLoadingPagos(false));
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }
    else
    {
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);

        if( payload.MedioPago==='WEBPAY')
        {
            var form = document.createElement("form");
            var element1 = document.createElement("input"); 
            form.method = "POST";
            form.action = obj.message.url;
            element1.value=obj.message.token;
            element1.type='hidden';
            element1.name="token_ws";
            form.appendChild(element1);  
            document.body.appendChild(form);
            form.submit();
        }
        else if( payload.MedioPago==='KHIPU')
        {
            window.location.href = obj.message;
        }
        yield put(FINDETAIL_SetMostrarLoadingPagos(false));
    }
}

function* getSagaTimelineDetalleServicioExecute(action){
    const requestURL = `servicios/get-timeline`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(showMessageDetalleServicio({type:'error',text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getTimeLineDetalleServicioSuccess(obj));
    }
}



export function* FINDETAIL_ModalPagarServicio_GetInfo(){
    yield takeLatest(FINDETAIL_MODALPAGARSERVICIO_GETINFO, FINDETAIL_ModalPagarServicio_GetInfoExecute);
 }
 
 export function* FINDETAIL_RealizarPagoServicio(){
    yield takeLatest(FINDETAIL_REALIZARPAGOSERVICIO, FINDETAIL_RealizarPagoServicioExecute);
 }
 
export function* postSagaAgendaRetiroByMonthDetalleServicio(){
    yield takeLatest(POST_AGENDA_RETIRO_BY_MONTH_DETALLE_SERVICIO, postSagaAgendaRetiroByMonthDetalleServicioExecute);
}

export function* getSagaDisponibilidadDespachoCalendarioDetalleServicio(){
    yield takeLatest(GET_DISPONIBILIDAD_DESPACHO_CALENDARIO_DETALLE_SERVICIO, getSagaDisponibilidadDespachoCalendarioDetalleServicioExecute);
}

export function* getSagaBloqueosRetiroByDayDetalleServicio(){
    yield takeLatest(GET_BLOQUEOS_RETIRO_BY_DAY_DETALLE_SERVICIO, getSagaBloqueosRetiroByDayDetalleServicioExecute);
}

export function* getSagaConfigDespachoDetalleServicio(){
    yield takeLatest(GET_CONFIG_DESPACHO_DETALLE_SERVICIO, getSagaConfigDespachoDetalleServicioExecute);
}

export function* getSagaConfigRetiroDetalleServicio(){
    yield takeLatest(GET_CONFIG_RETIRO_DETALLE_SERVICIO, getSagaConfigRetiroDetalleServicioExecute);
}
export function* getSagaInfoServicioDetalleServicio(){
   yield takeLatest(GET_INFO_SERVICIO_DETALLE_SERVICIO, getSagaInfoServicioDetalleServicioExecute);
}

export function* getSagaDocumentsClientDetalleServicio(){
    yield takeLatest(GET_DOCUMENTS_CLIENT_DETALLE_SERVICIO, getSagaDocumentsClientDetalleServicioExecute);
 }

 export function* postSagaPagoDetalleServicio(){
    yield takeLatest(POST_PAGO_DETALLE_SERVICIO, postSagaPagoDetalleServicioExecute);
 }

 export function* getSagaPagosDetalleServicio(){
    yield takeLatest(GET_PAGOS_DETALLE_SERVICIO, getSagaPagosDetalleServicioExecute);
 }

 export function* getSagaHistoryDetalleServicio(){
    yield takeLatest(GET_HISTORY_DETALLE_SERVICIO, getSagaHistoryDetalleServicioExecute);
 }

 export function* getSagaDataCostosDetalleServicio(){
    yield takeLatest(GET_DATA_COSTOS_DETALLE_SERVICIO, getSagaDataCostosDetalleServicioExecute);
 }

 export function* getSagaPropuestaComercialDetalleServicio(){
    yield takeLatest(GET_PROPUESTA_COMERCIAL_DETALLE_SERVICIO, getSagaPropuestaComercialDetalleServicioExecute);
 }

 export function* getSagaDireccionesClienteDetalleServicio(){
    yield takeLatest(GET_DIRECCIONES_CLIENTE_DETALLE_SERVICIO, getSagaDireccionesClienteDetalleServicioExecute);
 }

 export function* postSagaServicioDespachoRetiroDetalleServicio(){
    yield takeLatest(POST_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO, postSagaServicioDespachoRetiroDetalleServicioExecute);
 }

 export function* getSagaRegionesDetalleServicio(){
    yield takeLatest(GET_REGIONES_DETALLE_SERVICIO, getSagaRegionesDetalleServicioExecute);
 }

 export function* getSagaComunasDetalleServicio(){
    yield takeLatest(GET_COMUNAS_DETALLE_SERVICIO, getSagaComunasDetalleServicioExecute);
 }

 export function* getSagaRetiroDespachoDetalleServicio(){
    yield takeLatest(GET_RETIRO_DESPACHO_DETALLE_SERVICIO, getSagaRetiroDespachoDetalleServicioExecute);
 }

 export function* putSagaServicioDespachoRetiroDetalleServicio(){
    yield takeLatest(PUT_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO, putSagaServicioDespachoRetiroDetalleServicioExecute);
 }

 export function* getSagaPagosQiaoDetalleServicio(){
    yield takeLatest(GET_PAGOS_QIAO_DETALLE_SERVICIO, getSagaPagosQiaoDetalleServicioExecute);
 }

 export function* getSagaComercialesDetalleServicio(){
    yield takeLatest(GET_COMERCIALES_DETALLE_SERVICIO, getSagaComercialesDetalleServicioExecute);
 }

 export function* putUploadDocsDetalleServicio(){
    yield takeLatest(PUT_UPLOAD_DOCUMENTS_DETALLE_SERVICIO, putUploadDocsDetalleServicioExecute);
 }

 export function* getSagaTimelineDetalleServicio(){
    yield takeLatest(GET_TIMELINE_DETALLE_SERVICIO, getSagaTimelineDetalleServicioExecute);
 }


 
 
 
 
 
 
export default function* rootSaga() {

    yield spawn(FINDETAIL_RealizarPagoServicio);
    yield spawn(FINDETAIL_ModalPagarServicio_GetInfo);
    
    yield spawn(getSagaInfoServicioDetalleServicio);
    yield spawn(getSagaDocumentsClientDetalleServicio);
    yield spawn(postSagaPagoDetalleServicio);
    yield spawn(getSagaPagosDetalleServicio);
    yield spawn(getSagaHistoryDetalleServicio);
    yield spawn(getSagaDataCostosDetalleServicio);
    yield spawn(getSagaPropuestaComercialDetalleServicio);
    yield spawn(getSagaDireccionesClienteDetalleServicio);
    yield spawn(postSagaServicioDespachoRetiroDetalleServicio);
    yield spawn(getSagaRegionesDetalleServicio);
    yield spawn(getSagaComunasDetalleServicio);
    yield spawn(getSagaRetiroDespachoDetalleServicio);
    yield spawn(putSagaServicioDespachoRetiroDetalleServicio);
    yield spawn(getSagaPagosQiaoDetalleServicio);
    yield spawn(getSagaComercialesDetalleServicio);
    yield spawn(putUploadDocsDetalleServicio);
    yield spawn(getSagaConfigDespachoDetalleServicio);
    yield spawn(getSagaConfigRetiroDetalleServicio);
    yield spawn(postSagaAgendaRetiroByMonthDetalleServicio);
    yield spawn(getSagaBloqueosRetiroByDayDetalleServicio);
    yield spawn(getSagaDisponibilidadDespachoCalendarioDetalleServicio);
    yield spawn(getSagaTimelineDetalleServicio);
}