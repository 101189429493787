import React,{ useEffect, useState, useRef }  from 'react'
import 'date-fns';

import CloseIcon from '@material-ui/icons/Close';
import {
    makeStyles,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
    IconButton
} from "@material-ui/core";
// import img from './img.jpeg';
// import img1 from './img-2.jpeg';
import envConfig from '../../../../envConfig';




import { Theme, useTheme, createStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';


// import DatePicker, { registerLocale } from "react-datepicker";
// import el from "date-fns/locale/el"; // the locale you want
// registerLocale("el", el); // register it with the name you want

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";



import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/es";



const tutorialSteps = [
    {
      imgPath:
        'http://47.99.124.159:3000/api/tracking_detalle/photo2/NxbLoya8DpFG69SBNa5qp21wHiw17CpsYo5LmhXyr9M6l6KEm0QdVPO4CQOfYuEsR5DYxikpu1iUCrrm24405/+rKm97011130',
    },
    {
     imgPath: 'http://47.99.124.159:3000/api/tracking_detalle/photo1/NxbLoya8DpFG69SBNa5qp21wHiw17CpsYo5LmhXyr9M6l6KEm0QdVPO4CQOfYuEsR5DYxikpu1iUCrrm24405/+rKm97011130',
    },
  ];

const useStyles = makeStyles((theme) => ({
    inputAdornment: {
        "& .MuiTypography-body1": {
            fontSize: "12px"
        }
    },
    icon: {
        fontSize: "16px",
    },
    label: {
        fontSize: "12px",
        fontWeight: "400",
        paddingLeft: "12px",
        color: "#828282"
    },
    root: {
        width: "100%",
        flexGrow: 1,

        "& .MuiMobileStepper-root": {
            background:"#fff",
            padding:"8px 0"
        }
      },
    //   header: {
    //     display: 'flex',
    //     alignItems: 'center',
    //     height: 50,
    //     paddingLeft: theme.spacing(4),
    //     backgroundColor: theme.palette.background.default,
    //   },
      img: {
        height: 400,
        Width: "100%",
        overflow: 'hidden',
        display: 'block',
        width: '100%',
        objectFit:"cover"
        // object-fit: cover;
      },
}));



const Gallery = ({ gallery, handleClickClose,data }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const [localData, setLocalData] = React.useState(data);
    const [images,setImages] = React.useState([]);

    useEffect(() => {
        setLocalData(data);
        let array=[];
        if(data!=null && data.foto1=='TRUE'){
            array.push({
                path:envConfig.enviroment.urlApiExternal+'trackingDetalle/photo1/'+data.id
            });
        }

        if(data!=null && data.foto2=='TRUE'){
            array.push({
                path:envConfig.enviroment.urlApiExternal+'trackingDetalle/photo2/'+data.id
            });
        }

        if(data!=null && data.foto3=='TRUE'){
            array.push({
                path:envConfig.enviroment.urlApiExternal+'trackingDetalle/photo3/'+data.id
            });
        }
        setImages(array);
    }, [data]);

    const maxSteps = images.length;

    const handleNext = () => {
        if(activeStep<=images.length){
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if(activeStep>=1){
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };


    return (
        <Dialog open={gallery} onClose={()=>{setActiveStep(0);handleClickClose();}} aria-labelledby="form-dialog-title" fullWidth
        maxWidth="sm">
            <DialogTitle id="form-dialog-title" className="mt-4 pb-1">Detalle carga
            </DialogTitle>
            
            <DialogContent className="mb-4 pb-3">
                <DialogContentText>
                    A continuación se muestra un registro visual de tu carga recepcionada en nuestras instalaciones.
                    {/* Para valorizar el servicio, debes ingresar el volumen, peso y tipo de producto a importar. */}
                </DialogContentText>
                <div className={classes.root}>
                    {/* <Paper square elevation={0} className={classes.header}>
                        <Typography>{tutorialSteps[activeStep].label}</Typography>
                    </Paper> */}
                    {images && images.length>0 &&
                    <img
                        className={classes.img}
                        //src={"./"+imgPath+".jpeg"}
                        src={images[activeStep].path}
                        alt={`Foto ${activeStep}`}
                    />}

                    <MobileStepper
                        steps={images.length}
                        position="static"
                        variant="text"
                        activeStep={activeStep}
                        nextButton={
                        <Button
                            className="button button-light button-smal"
                            onClick={handleNext} disabled={activeStep === images.length - 1}
                        >
                            
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft className={classes.ico} /> : <KeyboardArrowRight className={classes.ico} />}
                        </Button>
                        }
                        backButton={
                        <Button
                            className="button button-light button-smal"
                            onClick={handleBack} disabled={activeStep === 0}
                        >
                            {theme.direction === 'rtl' ? <KeyboardArrowRight className={classes.ico} /> : <KeyboardArrowLeft className={classes.ico} />}
                            
                        </Button>
                        }
                    />
                </div>
                {/* <div className="justify-end mt-4">
                        <Button
                            className='button button-outline-danger button-100-mobile mr-1'
                            onClick={() => {
                                handleClickClose();
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button
                            className="button button-danger button-100-mobile ml-1"
                            type="submit"
                        >
                            Registrar pago
                        </Button>
                    </div> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{setActiveStep(0);handleClickClose();}}>Cerrar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default Gallery