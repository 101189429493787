import{
    SHOW_MESSAGE_DETALLE_SERVICIO,
    HIDE_MESSAGE_DETALLE_SERVICIO,
    GET_INFO_SERVICIO_DETALLE_SERVICIO_SUCCESS,
    GET_DOCUMENTS_CLIENT_DETALLE_SERVICIO_SUCCESS,
    POST_PAGO_DETALLE_SERVICIO_SUCCESS,
    GET_PAGOS_DETALLE_SERVICIO_SUCCESS,
    GET_HISTORY_DETALLE_SERVICIO_SUCCESS,
    GET_DATA_COSTOS_DETALLE_SERVICIO_SUCCESS,
    GET_PROPUESTA_COMERCIAL_DETALLE_SERVICIO_SUCCESS,
    GET_DIRECCIONES_CLIENTE_DETALLE_SERVICIO_SUCCESS,
    GET_REGIONES_DETALLE_SERVICIO_SUCCESS,
    GET_COMUNAS_DETALLE_SERVICIO_SUCCESS,
    GET_RETIRO_DESPACHO_DETALLE_SERVICIO_SUCCESS,
    GET_PAGOS_QIAO_DETALLE_SERVICIO_SUCCESS,
    CLEAN_STATES_DETALLE_SERVICIO,
    GET_COMERCIALES_DETALLE_SERVICIO_SUCCESS,
    CLOSE_PAGO_SUCCESS_DETALLE_SERVICIO,
    POST_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO_SUCCESS,
    CLOSE_DESPACHO_RETIRO_SUCCESS_DETALLE_SERVICIO,
    CLEAR_DATA_DETALLE_SERVICIO,
    CLEAR_ALL_EXPDIG_DETALLESERVICIO_SUCCESS,
    GET_CONFIG_DESPACHO_DETALLE_SERVICIO_SUCCESS,
    GET_CONFIG_RETIRO_DETALLE_SERVICIO_SUCCESS,
    GET_BLOQUEOS_RETIRO_BY_DAY_DETALLE_SERVICIO_SUCCESS,
    GET_DISPONIBILIDAD_DESPACHO_CALENDARIO_DETALLE_SERVICIO_SUCCESS,
    POST_AGENDA_RETIRO_BY_MONTH_DETALLE_SERVICIO_SUCCESS,

    FINDETAIL_SETMOSTRARMODALPAGARSERVICIO,
    FINDETAIL_MODALPAGARSERVICIO_GETINFO_SUCCESS,
    FINDETAIL_SETMOSTRARLOADINGPAGOS,
    GET_TIMELINE_DETALLE_SERVICIO_SUCCESS

} from './Constants';

import lodash from 'lodash';
import {getDateOrderBy} from '../../util/functions';

const INIT_STATE = {
    MostrarModalPagarServicio:false,
    MostrarLoadingPagos:false,
    ModalPagarServicioInfo:[],
    EnviarPagoServicio:{
        Nc:0,
        Carpeta:'',
        MedioPago:'',
        MedioPagoComision:0,
        ValorInicial:0,
        ValorPago:0,
        ValorComision:0,
        ValorComisionFijo:0,
        ValorComisionFijoIva:0,
        ValorComisionNeto:0,
        ValorComisionIva:0,
        ValorTotal:0,
        Usuario:'',
        Email:'',
        Estado:'PENDIENTE',
    },

    loader:false,
    showMessage:false,
    alertMessage:{type:'',text:''},
    servicio:null,
    documents:null,
    pagos:[],
    history:[],
    costos:null,
   /* costos:{
        n_carpeta:'H20TR345',
        servicio:2007000,
        impuestos:380000,
        otros_cobros:100000
    },*/
    propuesta:null,
    direcciones:[],
    regiones:[],
    comunas:[],
    retiroDespacho:null,
    pagosQiao:[],
    comerciales:[],
    successPago:false,
    successDespachoRetiro:false,
    config_despacho:null,
    config_retiro:null,
    bloqueos_retiro_horario:[],
    bloqueos_retiro_calendario:[],
    bloques_ocupados:[],
    bloqueos_despacho_calendario:[],
    timeline:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FINDETAIL_MODALPAGARSERVICIO_GETINFO_SUCCESS:{
            return {
                ...state,
                ModalPagarServicioInfo: action.payload
            }
        }

        case FINDETAIL_SETMOSTRARLOADINGPAGOS:{
            return {
                ...state,
                MostrarLoadingPagos: action.payload
            }
        }
        
        case FINDETAIL_SETMOSTRARMODALPAGARSERVICIO:{
            return {
                ...state,
                MostrarModalPagarServicio: action.payload
            }
        }

        case CLEAR_ALL_EXPDIG_DETALLESERVICIO_SUCCESS:{
            return{
                ...INIT_STATE
            }
        }
        
       case SHOW_MESSAGE_DETALLE_SERVICIO:{
           return{
               ...state,
               alertMessage:action.payload,
               showMessage:true
           }
       }
       case HIDE_MESSAGE_DETALLE_SERVICIO:{
            return{
                ...state,
                alertMessage:{type:'',text:''},
                showMessage:false
            }
        }
        case GET_INFO_SERVICIO_DETALLE_SERVICIO_SUCCESS:{
            let obj=lodash.cloneDeep(action.payload);
            let primera_recepcion=getDateOrderBy(obj.trackings,'primera_recepcion','asc');
            let ultima_recepcion=getDateOrderBy(obj.trackings,'ultima_recepcion','desc');

            if(obj.trackings && obj.trackings.length>0){
                obj.trackings.map(function(item){
                    let grouped=[];
                    if(item.tracking_detalle && item.tracking_detalle.length>0){
                        item.tracking_detalle.map(function(el){
                            if(grouped.length===0){
                                grouped.push({...el,cantidad_bultos:1});
                            }else{
                                const find=grouped.findIndex(x=>(x.codigo_interno==el.codigo_interno && x.producto==el.producto));
                                if(find>=0){
                                    let newItem=lodash.cloneDeep(grouped[find]);
                                    newItem.peso= newItem.peso+el.peso;
                                    newItem.volumen= newItem.volumen+el.volumen;
                                    newItem.peso.toFixed(3);
                                    newItem.volumen.toFixed(3);
                                    newItem.cantidad_bultos= newItem.cantidad_bultos+1;
                                    grouped[find]=newItem;
                                }else{
                                    grouped.push({...el,cantidad_bultos:1});
                                }
                            }
                        })
                    }
                    item.grouped=grouped;
                })
            }
            return{
                ...state,
                servicio:{
                    ...obj,
                    primera_recepcion:primera_recepcion,
                    ultima_recepcion:ultima_recepcion
                }
            }
        }
        case GET_DOCUMENTS_CLIENT_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                documents:action.payload
            }
        }
        case POST_PAGO_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                loader:false,
                successPago:true
            }
        }
        case GET_PAGOS_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                pagos:action.payload
            }
        }
        case GET_HISTORY_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                history:action.payload
            }
        }
        case GET_DATA_COSTOS_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                costos:action.payload
            }
        }
        case GET_PROPUESTA_COMERCIAL_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                propuesta:action.payload
            }
        }
        case GET_DIRECCIONES_CLIENTE_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                direcciones:action.payload
            }
        }
        case GET_REGIONES_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                regiones:action.payload
            }
        }
        case GET_COMUNAS_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                comunas:action.payload
            }
        }
        case GET_RETIRO_DESPACHO_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                retiroDespacho:action.payload
            }
        }
        case GET_PAGOS_QIAO_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                pagosQiao:action.payload
            }
        }
        case CLEAN_STATES_DETALLE_SERVICIO:{
            return{
                ...INIT_STATE
            }
        }

        case GET_COMERCIALES_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                comerciales:action.payload
            }
        }
        case CLOSE_PAGO_SUCCESS_DETALLE_SERVICIO:{
            return{
                ...state,
                successPago:false
            }
        }
        case CLOSE_DESPACHO_RETIRO_SUCCESS_DETALLE_SERVICIO:{
            return{
                ...state,
                successDespachoRetiro:false
            }
        }
        case POST_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO_SUCCESS:{
            
            return{
                ...state,
                successDespachoRetiro:action.payload
            }
        }
        case CLEAR_DATA_DETALLE_SERVICIO:{
            return{
                ...INIT_STATE
            }
        }
        case GET_CONFIG_DESPACHO_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                config_despacho:action.payload
            }
        }
        case GET_CONFIG_RETIRO_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                config_retiro:action.payload
            }
        }
        case GET_BLOQUEOS_RETIRO_BY_DAY_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                bloqueos_retiro_horario:action.payload
            }
        }
        case POST_AGENDA_RETIRO_BY_MONTH_DETALLE_SERVICIO_SUCCESS:{
            let ocupado=[];let grouped=[];
            if(action.payload && action.payload.length>0){
                action.payload.map(item=>{
                    if(item.detalle && item.detalle.length>0){
                        item.detalle.map(el=>{
                            if(grouped.length>0){
                                let findIndex=grouped.findIndex(x=>x.start===el.start && x.end===el.end);
                                if(findIndex>=0){
                                    grouped[findIndex].cantidad++;
                                }else{
                                    grouped.push({...el,cantidad:1});
                                }
                            }else{
                                grouped.push({...el, cantidad:1});
                            }
                        }) 
                    }
                })
            }
            
            if(grouped.length>0){
                grouped.map(el=>{
                    if(el.cantidad>=state.config_retiro.cantidad){
                        if(ocupado.length>0){
                            let findIndex=ocupado.findIndex(x=>x.start==el.start && x.end===el.end);
                            if(findIndex<0){
                                ocupado.push(el);
                            }
                        }else{
                            ocupado.push(el);
                        }   
                    }
                })
            }
            return{
                ...state,
                bloqueos_retiro_calendario:action.payload,
                bloques_ocupados:ocupado
            }
        }

        case GET_DISPONIBILIDAD_DESPACHO_CALENDARIO_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                bloqueos_despacho_calendario:action.payload
            }
        }

        case GET_TIMELINE_DETALLE_SERVICIO_SUCCESS:{
            return{
                ...state,
                timeline:action.payload
            }
        }
        
        default:
            return state;
    }
}