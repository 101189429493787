import React, { useState } from "react";
import AppLayouts from "./AppLayouts";
import Routes from "../../app/routes";
import { useSelector } from "react-redux";
import { TourProvider } from "@reactour/tour";
import { useHistory } from "react-router-dom";
import { keyframes } from '@emotion/react'
import { components } from '@reactour/tour'
import CloseIcon from '@material-ui/icons/Close';

const steps = [
    {
        selector: "[data-tour='uno']",
        content: (
            <>
                <h2 className="title-h4 font-weight-700 mt-3">Menú</h2>
                <p>En tu menú usuario podrás ver y editar tus datos personales.</p>
            </>

        )
    },
    {
        selector: "[data-tour='dos']",
        content: (
            <>
                <h2 className="title-h4 font-weight-700 mt-3">Notificaciones</h2>
                <p>Las notificaciones con respecto a tu servicios, serán visualizadas acá</p>
            </>
        )
    },
    {
        selector: "[data-tour='tres']",
        content: (
            <>
                <h2 className="title-h4 font-weight-700 mt-3">Ejecutivo</h2>
                <p>Tendrás un ejecutivo asignado que te acompañara y podrás contactar directamente</p>
            </>
        )
    },
    {
        selector: "[data-tour='cuatro']",
        content: (
            <>
                <h2 className="title-h4 font-weight-700 mt-3">Panel Servicios</h2>
                <p>Podrás visualziar el listado completo de servicios que hayas realziado en WS Cargo y filtrarlo por activos o los que requieran alguna acción de tu parte</p>
            </>
        )
    },
    {
        selector: "[data-tour='cinco']",
        content: (
            <>
                <h2 className="title-h4 font-weight-700 mt-3">Detalle Servicio</h2>
                <p>Podrás visualizar bultos, volumen, peso, estado de cargo o acciones que requieran de tu parte </p>
            </>
        )
    },
];

function Badge({ children }) {
    return (
        <components.Badge
            styles={{ badge: base => ({ ...base, backgroundColor: '#EA5259' }) }}
        >
            👉 {children} 👈
        </components.Badge>
    )
}

function Close({ onClick }) {
    return (
        <button
            className="button-light"
            onClick={onClick}
            style={{ position: 'absolute', right: 12, top: 12 }}
        >
            <CloseIcon />
        </button>
    )
}

const AppLayout = () => {
    // const disableBody = target => disableBodyScroll(target)
    // const enableBody = target => enableBodyScroll(target)

    const horizontalNavPosition = useSelector(({ settings }) => settings.horizontalNavPosition);
    const navigationStyle = useSelector(({ settings }) => settings.navigationStyle);

    // const history = useHistory();
    const history = useHistory();

    // const redirect = useNavigate();

    const onGetLayout = (layout) => {
        switch (layout) {
            case "inside_the_header":
                return "InsideHeaderNav";

            case "above_the_header":
                return "AboveHeaderNav";

            case "below_the_header":
                return "BelowHeaderNav";
            default:
                return "Vertical";
        }
    };

    const Layout = AppLayouts[navigationStyle === "vertical_navigation" ? "Vertical" : onGetLayout(horizontalNavPosition)];

    // const redirect = useNavigate();
    const [step, setStep] = useState(0);

    const setCurrentStep = (step) => {
        switch (step) {
            case 0:
                // history.push("/", true);
                break;
            case 1:
                // history.push("/", true);
                break;

            case 2:
                // history.push("/", true);
                break;
            case 3:
                // history.push("/app/atrina-registro");
                break;
            default:
                break;
        }
        setStep(step);
    };

    const demoId = 'custom-styles'
    // const steps = doSteps(demoId)
    const radius = 10

    const keyframesRotate = keyframes`
        50% {
            transform: translateY(-5px  );
        }
    }`

    return (
        <TourProvider
            steps={steps}
            currentStep={step}
            components={{ Badge, Close }}
            setCurrentStep={setCurrentStep}
            styles={{
                popover: base => ({
                    ...base,
                    '--reactour-current': '#ef5a3d',
                    '--reactour-accent': '#EA5259',
                    borderRadius: radius,
                }),
                dot: (base, { current }) => ({
                    ...base,
                    background: current ? '#7c89ad' : '#cce3ff',
                    border: current ? '0' : '1px solid #cce3ff',
                }),
                maskArea: base => ({ ...base, rx: radius }),
                maskWrapper: base => ({ ...base, color: '#000' }),
                controls: base => ({ ...base, marginTop: 80 }),
                dot: base => ({
                    ...base,
                    animationDuration: '1s',
                    animationName: keyframesRotate,
                    animationIterationCount: 'infinite',
                    '&:nth-of-type(1)': {
                        animationDelay: '.3s',
                    },
                    '&:nth-of-type(2)': {
                        animationDelay: '.6s',
                    },
                }),
            }}
        >
            <Layout>
                <Routes setStep={setStep} />
            </Layout>
        </TourProvider>

    );
};

export default AppLayout;
