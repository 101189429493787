import {all,fork,call, put,takeLatest,spawn} from "redux-saga/effects";
import {
   VALIDATE_CURRENT_PASSWORD,
   PUT_PASSWORD_USER
} from "./Constants";
import {
    validateCurrentPasswordSuccess,
    putPasswordUserSuccess
} from "./Actions";
import {
    postRequest,
    getRequest,
    getErrorRequest,
    deleteRequest,
    putRequest
} from '../../util/apiFetch';

function* postSagaValidateCurrentPasswordExecute({payload}){
    console.log(payload);
    const requestURL = `user/validate-current-password`;
    const response= yield call(postRequest,requestURL, payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageUserAux({type:'error',text:message}));
        console.log({type:'error',text:message});
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(validateCurrentPasswordSuccess(obj));
    }
}

function* putSagaPutPasswordUserExecute({payload}){
    const requestURL = `user/${payload.id}/password`;
    const response= yield call(putRequest,requestURL, payload);
    const { error, message } =yield call(getErrorRequest,response);

    if(error){
        //yield put(showMessageUserAux({type:'error',text:message}));
        yield put(putPasswordUserSuccess({success:false,text:message}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        console.log(obj);
        yield put(putPasswordUserSuccess(obj));
    }
}


export function* postSagaValidateCurrentPassword(){
    yield takeLatest(VALIDATE_CURRENT_PASSWORD, postSagaValidateCurrentPasswordExecute);
}
export function* putSagaPutPasswordUser(){
    yield takeLatest(PUT_PASSWORD_USER, putSagaPutPasswordUserExecute);
}

export default function* rootSaga() {
    yield all([
        fork(postSagaValidateCurrentPassword),
        fork(putSagaPutPasswordUser),
    ]);
}