export const ON_CHANGE_PAYLOAD = 'ON_CHANGE_PAYLOAD';
export const ON_CHANGE_PAYLOAD_ADDRESS = 'ON_CHANGE_PAYLOAD_ADDRESS';
export const ON_LOAD_DATA_USER='ON_LOAD_DATA_USER';
export const ON_LOAD_DATA_USER_SUCCESS='ON_LOAD_DATA_USER_SUCCESS';
export const POST_REGISTER_USER = 'POST_REGISTER_USER';
export const POST_REGISTER_USER_SUCCESS= 'POST_REGISTER_USER_SUCCESS';
export const GET_REGISTER_USERSLIST='GET_REGISTER_USERSLIST';
export const GET_REGISTER_USERSLIST_SUCCESS='GET_REGISTER_USERSLIST_SUCCESS';
export const DELETE_REGISTER_USER='DELETE_REGISTER_USER';
export const DELETE_REGISTER_USER_SUCCESS='DELETE_REGISTER_USER_SUCCESS';
export const SHOW_MESSAGE_USER_AUX='SHOW_MESSAGE_USER_AUX';
export const HIDE_MESSAGE_USER_AUX='HIDE_MESSAGE_USER_AUX';
export const PUT_REGISTER_USER = 'PUT_REGISTER_USER';
export const PUT_REGISTER_USER_SUCCESS= 'PUT_REGISTER_USER_SUCCESS';
export const CLEAN_REGISTER_USER = 'CLEAN_REGISTER_USER';
export const GET_ADDRESS_TYPES='GET_ADDRESS_TYPES';
export const GET_ADDRESS_TYPES_SUCCESS='GET_ADDRESS_TYPES_SUCCESS';
export const GET_CONTACT_TYPES='GET_CONTACT_TYPES';
export const GET_CONTACT_TYPES_SUCCESS='GET_CONTACT_TYPES_SUCCESS';
export const GET_BANKS='GET_BANKS';
export const GET_BANKS_SUCCESS='GET_BANKS_SUCCESS';
export const GET_BANKS_ACCOUNTS_TYPES='GET_BANKS_ACCOUNTS_TYPES';
export const GET_BANKS_ACCOUNTS_TYPES_SUCCESS='GET_BANKS_ACCOUNTS_TYPES_SUCCESS';
export const POST_ADDRESS_USER='POST_ADDRESS_USER';
export const POST_ADDRESS_USER_SUCCESS='POST_ADDRESS_USER_SUCCESS';
export const ON_CHANGE_COLLAPSE='ON_CHANGE_COLLAPSE';
export const TOGGLE_MODAL_USER_DATA='TOGGLE_MODAL_USER_DATA';
export const UPDATE_ADDRESS_USER='UPDATE_ADDRESS_USER';
export const UPDATE_ADDRESS_USER_SUCCESS='UPDATE_ADDRESS_USER_SUCCESS';
export const DELETE_ADDRESS_USER='DELETE_ADDRESS_USER';
export const DELETE_ADDRESS_USER_SUCCESS='DELETE_ADDRESS_USER_SUCCESS';
export const EMPTY_DATA_INIT_USERS='EMPTY_DATA_INIT_USERS';
export const ONCHANGE_VALIDATIONS_USER='ONCHANGE_VALIDATIONS_USER';
export const GET_ROLES='GET_ROLES';
export const GET_ROLES_SUCCESS='GET_ROLES_SUCCESS';

