import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';
import {
    DialogContent,
    Dialog,
    DialogTitle,
    DialogContentText,
    DialogActions,
    Button,
    Select,
    MenuItem,
    FormControl,
    makeStyles,
} from '@material-ui/core';
import IntlMessages from 'util/IntlMessages';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import { Alert, AlertTitle } from '@material-ui/lab';
import CardContent from '@material-ui/core/CardContent';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import produce from 'immer';
import Radio from '@material-ui/core/Radio';
/**********************************************************************************/
import {
    CustSurv_Set_ShowCargando
    , CustSurv_Set_Dialog

    , CustSurv_Get_EncuestaData
    , CustSurv_Set_DialogConfirmar
    , CustSurv_Post_Encuesta
} from './Actions.js';
import { blue } from '@material-ui/core/colors';
/**********************************************************************************/

/**********************************************************************************/
import {
    IconRequerido1x,
  } from '../Diccionario.js';
import { setClienteActivo } from 'modules/dashboard/Actions.js';
import { Col } from 'reactstrap';
const useStyles = makeStyles((theme) => ({
    stepper: {
        background: 'transparent',
        padding: '0'
    },
    stepLabel: {
        background: '#fff',
    },
    iconLabel: {
        background: 'rgba(248, 201, 106, 0.471)',
        color: '#F8C96A',
        padding: '16px',
        marginRight: '16px'
    },
    iconLabelAtive: {
        background: 'rgba(111, 207, 151, 0.471)',
        color: '#6FCF97',
        padding: '16px',
        marginRight: '16px'
    },
    ButtonNav: {
        borderRadius: "100%",
        padding: '6px 12px',
        color: "#8F8F8F",
        fontSize: "20px",
        "&:hover": {
            backgroundColor: "transparent",
            color: "#44403f",
        }
    },
    icon: {
        fontSize: "16px"
    },
    avatarXl: {
        width: "80px",
        height: "80px",
        marginRight: "16px",
    },
    avatarXxl: {
        width: "180px",
        height: "180px",
        margin: "0 auto",

    },
    contentAvatar: {
        width: "100%"
    },
    paddingBottom: {
        padding: "0 24px",
        '@media screen and (max-width: 767px)': {
            padding: "0"
        }
    },
    closeButton: {
        position: 'absolute',
        right: "12px",
        top: "12px",
        color: theme.palette.grey[500],
    },
    titleDialog: {
        lineHeight: "24px",
        color: "#263238",
    },
    imagenHovered: {
        maxWidth: 310,
        transition: "transform 0.15s ease-in-out",
        "&:hover": { transform: "scale3d(1.15, 1.15, 1)" },
        cursor: 'pointer',
    },
    imagenSelected:{
        border: "3px solid green",
    },
    messageBox: {
        maxHeight: 300,
        minHeight: 300,
        width: '100%',
        borderRadius: 5,
        padding: 15,
        color: '#575757',
        border: '1px solid #D8D8D8',
        fontSize: '18px',
        "line-height": '150%',
        "&:focus": {
            border: '3px solid #0101DF !important',
        }
    },
    StyleNombreCheckBox: {
        maxHeight: 60,
        minHeight: 60,
    }
}));
/**********************************************************************************/

const EncuestaForm = (props) => {

    /**********************************************************************************/
    const dispatch = useDispatch();
    const CryptoJS = require("crypto-js");
    const classes = useStyles();
    const [cargando, setCargando] = useState(false)
    const [openCUSTSURV_ShowDialogMessage, setOpenCUSTSURV_ShowDialogMessage] = React.useState(false);
    const [openCUSTSURV_ShowDialogConfirmar, setOpenCUSTSURV_ShowDialogConfirmar] = React.useState(false);

    const StyleGrillaSatisfaccion = ' col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2 text-center ';
    const StyleGrillaTextoEstrella = ' col-12 col-sm-12 col-md-12 col-lg-12 col-xl-3 text-left ';
    const StyleGrillaEstrella = ' col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1 text-center ';
    
    const StyleTextoCheckRadio =                ' row text font-weight-100 m-0 text-dark text-center pt-2 col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 ';
    const StyleTextoCheckRadioDificil =         ' row text font-weight-100 m-0 text-dark text-center pt-2 col-6 col-sm-4 col-md-3 col-lg-1 col-xl-1 ';
    const StyleTextoCheckRadioIndiferente =     ' row text font-weight-100 m-0 text-dark text-center pt-2 col-6 col-sm-4 col-md-3 col-lg-2 col-xl-2 ';
    
    const StyleBotonNumero = ' m-3 ';

    
    const {
        CUSTSURV_ShowCargando,
        CUSTSURV_Dialog,
        CUSTSURV_EncuestaCabecera,
        CUSTSURV_Encuesta1Detalle,
        CUSTSURV_Encuesta2Detalle,
        CUSTSURV_DialogConfirmar,
    } = useSelector(({CustomerSurveySaga}) => CustomerSurveySaga);

    const [encuestaData, setEncuestaData] = useState(props.match.params.id);
    const [ctrl_CUSTSURV_EncuestaCabecera, setCtrl_CUSTSURV_EncuestaCabecera] = useState(CUSTSURV_EncuestaCabecera);
    const [ctrl_CUSTSURV_Encuesta1Detalle, setCtrl_CUSTSURV_Encuesta1Detalle] = useState(CUSTSURV_Encuesta1Detalle);
    const [ctrl_CUSTSURV_Encuesta2Detalle, setCtrl_CUSTSURV_Encuesta2Detalle] = useState(CUSTSURV_Encuesta2Detalle);
    const [ctrl_Temp_CUSTSURV_Encuesta1Detalle, setCtrl_Temp_CUSTSURV_Encuesta1Detalle] = useState(CUSTSURV_Encuesta1Detalle);
    const [ctrl_Temp_CUSTSURV_Encuesta2Detalle, setCtrl_Temp_CUSTSURV_Encuesta2Detalle] = useState(CUSTSURV_Encuesta2Detalle);

    const [faceSurvey, setFaceSurvey] = useState(0);

    const [enviarEncuesta, setEnviarEncuesta] = useState([]);

    const [errores1Mostrar, setErrores1Mostrar] = useState(0);
    const [errores1Mensaje, setErrores1Mensaje] = useState('');
    const [errores2Mostrar, setErrores2Mostrar] = useState(0);
    const [errores2Mensaje, setErrores2Mensaje] = useState('');

    const [face1Encuesta1, setFace1Encuesta1] = useState(0);
    const [face2Encuesta1, setFace2Encuesta1] = useState(0);
    const [face3Encuesta1, setFace3Encuesta1] = useState(0);
    const [face4Encuesta1, setFace4Encuesta1] = useState(0);
    const [face5Encuesta1, setFace5Encuesta1] = useState(0);

    const [face1Encuesta2, setFace1Encuesta2] = useState(0);
    const [face2Encuesta2, setFace2Encuesta2] = useState(0);
    const [face3Encuesta2, setFace3Encuesta2] = useState(0);
    const [face4Encuesta2, setFace4Encuesta2] = useState(0);
    const [face5Encuesta2, setFace5Encuesta2] = useState(0);

    const [star1_1Encuesta2, setStar1_1Encuesta2] = useState(0);
    const [star1_2Encuesta2, setStar1_2Encuesta2] = useState(0);
    const [star1_3Encuesta2, setStar1_3Encuesta2] = useState(0);
    const [star1_4Encuesta2, setStar1_4Encuesta2] = useState(0);
    const [star1_5Encuesta2, setStar1_5Encuesta2] = useState(0);

    const [star2_1Encuesta2, setStar2_1Encuesta2] = useState(0);
    const [star2_2Encuesta2, setStar2_2Encuesta2] = useState(0);
    const [star2_3Encuesta2, setStar2_3Encuesta2] = useState(0);
    const [star2_4Encuesta2, setStar2_4Encuesta2] = useState(0);
    const [star2_5Encuesta2, setStar2_5Encuesta2] = useState(0);

    const [star3_1Encuesta2, setStar3_1Encuesta2] = useState(0);
    const [star3_2Encuesta2, setStar3_2Encuesta2] = useState(0);
    const [star3_3Encuesta2, setStar3_3Encuesta2] = useState(0);
    const [star3_4Encuesta2, setStar3_4Encuesta2] = useState(0);
    const [star3_5Encuesta2, setStar3_5Encuesta2] = useState(0);

    const [star4_1Encuesta2, setStar4_1Encuesta2] = useState(0);
    const [star4_2Encuesta2, setStar4_2Encuesta2] = useState(0);
    const [star4_3Encuesta2, setStar4_3Encuesta2] = useState(0);
    const [star4_4Encuesta2, setStar4_4Encuesta2] = useState(0);
    const [star4_5Encuesta2, setStar4_5Encuesta2] = useState(0);

    const [star5_1Encuesta2, setStar5_1Encuesta2] = useState(0);
    const [star5_2Encuesta2, setStar5_2Encuesta2] = useState(0);
    const [star5_3Encuesta2, setStar5_3Encuesta2] = useState(0);
    const [star5_4Encuesta2, setStar5_4Encuesta2] = useState(0);
    const [star5_5Encuesta2, setStar5_5Encuesta2] = useState(0);

    const [star6_1Encuesta2, setStar6_1Encuesta2] = useState(0);
    const [star6_2Encuesta2, setStar6_2Encuesta2] = useState(0);
    const [star6_3Encuesta2, setStar6_3Encuesta2] = useState(0);
    const [star6_4Encuesta2, setStar6_4Encuesta2] = useState(0);
    const [star6_5Encuesta2, setStar6_5Encuesta2] = useState(0);    

    const ValidateEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    const [clienteInformacion, setClienteInformacion] = useState([]);

    const [selectedChecked12, setSelectedChecked12] = React.useState(0);
    const [selectedChecked22, setSelectedChecked22] = React.useState('0');
    
    const [variantButton02Nps, setVariantButton02Nps] = React.useState('outlined');
    const [variantButton12Nps, setVariantButton12Nps] = React.useState('outlined');
    const [variantButton22Nps, setVariantButton22Nps] = React.useState('outlined');
    const [variantButton32Nps, setVariantButton32Nps] = React.useState('outlined');
    const [variantButton42Nps, setVariantButton42Nps] = React.useState('outlined');
    const [variantButton52Nps, setVariantButton52Nps] = React.useState('outlined');
    const [variantButton62Nps, setVariantButton62Nps] = React.useState('outlined');
    const [variantButton72Nps, setVariantButton72Nps] = React.useState('outlined');
    const [variantButton82Nps, setVariantButton82Nps] = React.useState('outlined');
    const [variantButton92Nps, setVariantButton92Nps] = React.useState('outlined');
    const [variantButton102Nps, setVariantButton102Nps] = React.useState('outlined');

    /**********************************************************************************/

    /**********************************************************************************/
    useEffect(()=>{
        if(CUSTSURV_Dialog.show===1)
        {
            setOpenCUSTSURV_ShowDialogMessage(true);
        }
        else
        {
            setOpenCUSTSURV_ShowDialogMessage(false);
        }
    },[CUSTSURV_Dialog]);

    useEffect(()=>{
        if(CUSTSURV_DialogConfirmar.show===1)
        {
            setOpenCUSTSURV_ShowDialogConfirmar(true);
        }
        else
        {
            setOpenCUSTSURV_ShowDialogConfirmar(false);
        }
    },[CUSTSURV_DialogConfirmar]);

    useEffect(() => {
        if ( typeof encuestaData!=='undefined' && encuestaData!=='' && encuestaData.length>0)
        {
            //U2FsdGVkX1|74+DgbeWoSmjJD9goq0hL2jRFZX98Njo=
            dispatch(CustSurv_Get_EncuestaData(encuestaData));
        }
        else
        {
            setEncuestaData('SINENCUESTA');
            dispatch(CustSurv_Get_EncuestaData('SINENCUESTA'));
        }
    }, [encuestaData]);

    useEffect(() => {
        setCtrl_CUSTSURV_Encuesta1Detalle(CUSTSURV_EncuestaCabecera);
    }, [CUSTSURV_EncuestaCabecera]);

    const handleCloseCUSTSURV_ShowDialogMessage = () => { dispatch(CustSurv_Set_Dialog({background:CUSTSURV_Dialog.background, content:CUSTSURV_Dialog.content, title:CUSTSURV_Dialog.title, show:0})); };
    const handleCloseCUSTSURV_ShowDialogConfirmar = () => { dispatch(CustSurv_Set_DialogConfirmar({background:CUSTSURV_DialogConfirmar.background, content:CUSTSURV_DialogConfirmar.content, title:CUSTSURV_DialogConfirmar.title, show:0})); };
    /**********************************************************************************/

    function OnChange_ctrl_Temp_CUSTSURV_Encuesta1Detalle(event) {
        event.persist();
        const tempControls = produce(ctrl_Temp_CUSTSURV_Encuesta1Detalle, draft => {
          draft[event.target.id] = event.target.value.toUpperCase();
        });
        setCtrl_Temp_CUSTSURV_Encuesta1Detalle(tempControls);
    }

    function OnBlur_ctrl_Temp_CUSTSURV_Encuesta1Detalle(event) {
        event.persist();
        const tempControls = produce(ctrl_CUSTSURV_Encuesta1Detalle, draft => {
          draft[event.target.id] = event.target.value.toUpperCase();
        });
        setCtrl_CUSTSURV_Encuesta1Detalle(tempControls);
    }

    function OnChange_p5r1(event) {
        event.persist();
        const tempControls = produce(ctrl_Temp_CUSTSURV_Encuesta2Detalle, draft => {
          draft[event.target.id] = event.target.value.toUpperCase();
        });
        setCtrl_Temp_CUSTSURV_Encuesta2Detalle(tempControls);
    }

    function FaceActivated(Face, Encuesta)
    {
        if(Encuesta===1)
        {
            setFace1Encuesta1(0);
            setFace2Encuesta1(0);
            setFace3Encuesta1(0);
            setFace4Encuesta1(0);
            setFace5Encuesta1(0);
            if(Face===1) { setFaceSurvey(1); setFace1Encuesta1(1); }
            else if(Face===2) { setFaceSurvey(2); setFace2Encuesta1(1); }
            else if(Face===3) { setFaceSurvey(3); setFace3Encuesta1(1); }
            else if(Face===4) { setFaceSurvey(4); setFace4Encuesta1(1); }
            else if(Face===5) { setFaceSurvey(5); setFace5Encuesta1(1); }
        }
        else if(Encuesta===2)
        {
            setFace1Encuesta2(0);
            setFace2Encuesta2(0);
            setFace3Encuesta2(0);
            setFace4Encuesta2(0);
            setFace5Encuesta2(0);
            if(Face===1) { setFaceSurvey(1); setFace1Encuesta2(1); }
            else if(Face===2) { setFaceSurvey(2); setFace2Encuesta2(1); }
            else if(Face===3) { setFaceSurvey(3); setFace3Encuesta2(1); }
            else if(Face===4) { setFaceSurvey(4); setFace4Encuesta2(1); }
            else if(Face===5) { setFaceSurvey(5); setFace5Encuesta2(1); }
        }

        if(Encuesta===1)
        {
            const tempControls = produce(ctrl_Temp_CUSTSURV_Encuesta1Detalle, draft => {
                draft['satisfaccion'] = Face;
            });
            setCtrl_Temp_CUSTSURV_Encuesta1Detalle(tempControls);

            const Controls = produce(ctrl_CUSTSURV_Encuesta1Detalle, draft => {
                draft['satisfaccion'] = Face;
            });
            setCtrl_CUSTSURV_Encuesta1Detalle(Controls);
        }
        else if(Encuesta===2)
        {
            const tempControls = produce(ctrl_Temp_CUSTSURV_Encuesta2Detalle, draft => {
                draft['satisfaccion'] = Face;
            });
            setCtrl_Temp_CUSTSURV_Encuesta2Detalle(tempControls);

            const Controls = produce(ctrl_CUSTSURV_Encuesta2Detalle, draft => {
                draft['satisfaccion'] = Face;
            });
            setCtrl_CUSTSURV_Encuesta2Detalle(Controls);
        }
    }

    function StarActivated(Linea,Columna,Encuesta)
    {
        if(Linea===1)
        {
            setStar1_1Encuesta2(0); setStar1_2Encuesta2(0); setStar1_3Encuesta2(0); setStar1_4Encuesta2(0); setStar1_5Encuesta2(0);
            if(Columna===5) { setStar1_1Encuesta2(1); setStar1_2Encuesta2(1); setStar1_3Encuesta2(1); setStar1_4Encuesta2(1); setStar1_5Encuesta2(1); }
            else if(Columna===4) { setStar1_1Encuesta2(1); setStar1_2Encuesta2(1); setStar1_3Encuesta2(1); setStar1_4Encuesta2(1); }
            else if(Columna===3) { setStar1_1Encuesta2(1); setStar1_2Encuesta2(1); setStar1_3Encuesta2(1); }
            else if(Columna===2) { setStar1_1Encuesta2(1); setStar1_2Encuesta2(1); }
            else if(Columna===1) { setStar1_1Encuesta2(1); }
        }
        else if(Linea===2)
        {
            setStar2_1Encuesta2(0); setStar2_2Encuesta2(0); setStar2_3Encuesta2(0); setStar2_4Encuesta2(0); setStar2_5Encuesta2(0);
            if(Columna===5) { setStar2_1Encuesta2(1); setStar2_2Encuesta2(1); setStar2_3Encuesta2(1); setStar2_4Encuesta2(1); setStar2_5Encuesta2(1); }
            else if(Columna===4) { setStar2_1Encuesta2(1); setStar2_2Encuesta2(1); setStar2_3Encuesta2(1); setStar2_4Encuesta2(1); }
            else if(Columna===3) { setStar2_1Encuesta2(1); setStar2_2Encuesta2(1); setStar2_3Encuesta2(1); }
            else if(Columna===2) { setStar2_1Encuesta2(1); setStar2_2Encuesta2(1); }
            else if(Columna===1) { setStar2_1Encuesta2(1); }
        }
        else if(Linea===3)
        {
            setStar3_1Encuesta2(0); setStar3_2Encuesta2(0); setStar3_3Encuesta2(0); setStar3_4Encuesta2(0); setStar3_5Encuesta2(0);
            if(Columna===5) { setStar3_1Encuesta2(1); setStar3_2Encuesta2(1); setStar3_3Encuesta2(1); setStar3_4Encuesta2(1); setStar3_5Encuesta2(1); }
            else if(Columna===4) { setStar3_1Encuesta2(1); setStar3_2Encuesta2(1); setStar3_3Encuesta2(1); setStar3_4Encuesta2(1); }
            else if(Columna===3) { setStar3_1Encuesta2(1); setStar3_2Encuesta2(1); setStar3_3Encuesta2(1); }
            else if(Columna===2) { setStar3_1Encuesta2(1); setStar3_2Encuesta2(1); }
            else if(Columna===1) { setStar3_1Encuesta2(1); }
        }
        else if(Linea===4)
        {
            setStar4_1Encuesta2(0); setStar4_2Encuesta2(0); setStar4_3Encuesta2(0); setStar4_4Encuesta2(0); setStar4_5Encuesta2(0);
            if(Columna===5) { setStar4_1Encuesta2(1); setStar4_2Encuesta2(1); setStar4_3Encuesta2(1); setStar4_4Encuesta2(1); setStar4_5Encuesta2(1); }
            else if(Columna===4) { setStar4_1Encuesta2(1); setStar4_2Encuesta2(1); setStar4_3Encuesta2(1); setStar4_4Encuesta2(1); }
            else if(Columna===3) { setStar4_1Encuesta2(1); setStar4_2Encuesta2(1); setStar4_3Encuesta2(1); }
            else if(Columna===2) { setStar4_1Encuesta2(1); setStar4_2Encuesta2(1); }
            else if(Columna===1) { setStar4_1Encuesta2(1); }
        }
        else if(Linea===5)
        {
            setStar5_1Encuesta2(0); setStar5_2Encuesta2(0); setStar5_3Encuesta2(0); setStar5_4Encuesta2(0); setStar5_5Encuesta2(0);
            if(Columna===5) { setStar5_1Encuesta2(1); setStar5_2Encuesta2(1); setStar5_3Encuesta2(1); setStar5_4Encuesta2(1); setStar5_5Encuesta2(1); }
            else if(Columna===4) { setStar5_1Encuesta2(1); setStar5_2Encuesta2(1); setStar5_3Encuesta2(1); setStar5_4Encuesta2(1); }
            else if(Columna===3) { setStar5_1Encuesta2(1); setStar5_2Encuesta2(1); setStar5_3Encuesta2(1); }
            else if(Columna===2) { setStar5_1Encuesta2(1); setStar5_2Encuesta2(1); }
            else if(Columna===1) { setStar5_1Encuesta2(1); }
        }
        else if(Linea===6)
        {
            setStar6_1Encuesta2(0); setStar6_2Encuesta2(0); setStar6_3Encuesta2(0); setStar6_4Encuesta2(0); setStar6_5Encuesta2(0);
            if(Columna===5) { setStar6_1Encuesta2(1); setStar6_2Encuesta2(1); setStar6_3Encuesta2(1); setStar6_4Encuesta2(1); setStar6_5Encuesta2(1); }
            else if(Columna===4) { setStar6_1Encuesta2(1); setStar6_2Encuesta2(1); setStar6_3Encuesta2(1); setStar6_4Encuesta2(1); }
            else if(Columna===3) { setStar6_1Encuesta2(1); setStar6_2Encuesta2(1); setStar6_3Encuesta2(1); }
            else if(Columna===2) { setStar6_1Encuesta2(1); setStar6_2Encuesta2(1); }
            else if(Columna===1) { setStar6_1Encuesta2(1); }
        }
    }

    function handleChangeSelectRadio12(valor)
    {
        setSelectedChecked12(valor);
    };
    
    function ActivateButtonNps(Boton,Encuesta){
        setVariantButton02Nps('outlined');
        setVariantButton12Nps('outlined');
        setVariantButton22Nps('outlined');
        setVariantButton32Nps('outlined');
        setVariantButton42Nps('outlined');
        setVariantButton52Nps('outlined');
        setVariantButton62Nps('outlined');
        setVariantButton72Nps('outlined');
        setVariantButton82Nps('outlined');
        setVariantButton92Nps('outlined');
        setVariantButton102Nps('outlined');
        if(Boton===0) { setVariantButton02Nps('contained'); }
        else if(Boton===1) { setVariantButton12Nps('contained'); }
        else if(Boton===2) { setVariantButton22Nps('contained'); }
        else if(Boton===3) { setVariantButton32Nps('contained'); }
        else if(Boton===4) { setVariantButton42Nps('contained'); }
        else if(Boton===5) { setVariantButton52Nps('contained'); }
        else if(Boton===6) { setVariantButton62Nps('contained'); }
        else if(Boton===7) { setVariantButton72Nps('contained'); }
        else if(Boton===8) { setVariantButton82Nps('contained'); }
        else if(Boton===9) { setVariantButton92Nps('contained'); }
        else if(Boton===10) { setVariantButton102Nps('contained'); }
    }

    function SetRespuesta(Pregunta, Respuesta, Encuesta)
    {
        const Controls = produce(ctrl_Temp_CUSTSURV_Encuesta2Detalle, draft => {
            draft[Pregunta] = Respuesta;
        });
        setCtrl_Temp_CUSTSURV_Encuesta2Detalle(Controls);
    }

    function ValidarEncuesta1()
    {
        if(faceSurvey==0)
        {
            setErrores1Mensaje('1.- ¿De manera general, cómo te sientes con nuestro servicio?');
            setErrores1Mostrar(1);
        }
        else if( ctrl_Temp_CUSTSURV_Encuesta1Detalle.mensaje=='' || ctrl_Temp_CUSTSURV_Encuesta1Detalle.mensaje.length<=0 )
        {
            setErrores1Mensaje('2.- Coméntenos cómo podemos mejorar su experiencia actual?');
            setErrores1Mostrar(1);
        }
        else
        {
            dispatch(CustSurv_Set_DialogConfirmar({background:'bg-info', content:'DESEA ENVIAR LA ENCUESTA?', title:'label.EnviarEncuesta', show:1}));
        }
    }


    function ValidarEncuesta2()
    {
        var Errores = 0;
        if(ctrl_Temp_CUSTSURV_Encuesta2Detalle.p1r1==='' )
        {
            setErrores2Mensaje('1.- ¿De manera general, cómo te sientes con nuestro servicio?');
            setErrores2Mostrar(1);
            Errores++;
        }
        else if(
        ctrl_Temp_CUSTSURV_Encuesta2Detalle.p2r1===''
        || ctrl_Temp_CUSTSURV_Encuesta2Detalle.p2r2===''
        || ctrl_Temp_CUSTSURV_Encuesta2Detalle.p2r3===''
        || ctrl_Temp_CUSTSURV_Encuesta2Detalle.p2r4===''
        || ctrl_Temp_CUSTSURV_Encuesta2Detalle.p2r5===''
        || ctrl_Temp_CUSTSURV_Encuesta2Detalle.p2r6===''
        )
        {
            setErrores2Mensaje('2.- ¿Cómo calificarías los siguientes aspectos del servicio?');
            setErrores2Mostrar(1);
            Errores++;
        }
        else if(
        ctrl_Temp_CUSTSURV_Encuesta2Detalle.p3r1===''
        || ctrl_Temp_CUSTSURV_Encuesta2Detalle.p3r2===''
        )
        {
            setErrores2Mensaje('3.- ¿Cómo le resultó la gestión en las siguientes etapas?');
            setErrores2Mostrar(1);
            Errores++;
        }
        else if(
        ctrl_Temp_CUSTSURV_Encuesta2Detalle.p4r1===''
        )
        {
            setErrores2Mensaje('4.- Considerando la experiencia completa con nuestra empresa, ¿cuán dispuesto estaría usted a recomendar nuestros servicios a otras personas?');
            setErrores2Mostrar(1);
            Errores++;
        }
        else if(
        ctrl_Temp_CUSTSURV_Encuesta2Detalle.p5r1==='' || ctrl_Temp_CUSTSURV_Encuesta2Detalle.p5r1.length<=0
        )
        {
            setErrores2Mensaje('5.- Coméntenos cómo podemos mejorar su experiencia actual');
            setErrores2Mostrar(1);
            Errores++;
        }

        if(Errores===0)
        {
            dispatch(CustSurv_Set_DialogConfirmar({background:'bg-info', content:'DESEA ENVIAR LA ENCUESTA?', title:'label.EnviarEncuesta', show:1}));
        }
    }


    function PostEncuesta()
    {
        dispatch(CustSurv_Set_DialogConfirmar({background:'bg-info', content:'.', title:'label.EnviarEncuesta', show:0}));
        dispatch(CustSurv_Post_Encuesta(enviarEncuesta));
    }

    function CerrarConfirmar()
    {
        setEnviarEncuesta([]);
        dispatch(CustSurv_Set_DialogConfirmar({background:'bg-info', content:'DESEA ENVIAR LA ENCUESTA SIN AGREGAR UN MENSAJE?', title:'label.EnviarEncuesta', show:0}));
    }

    return (
        <div className={`app-container new-template fixed-drawer`}>
            <div className='app-main-container'>
                <div className="app-main-container" style={{ backgroundColor: 'red !important' }}>
                    <div className='app-header-cotizador'>
                        <div className='main-title'>
                            <h1 className="title-h2 text-light font-weight-700">
                                { CUSTSURV_EncuestaCabecera.nombre!='' ? CUSTSURV_EncuestaCabecera.nombre : 'SISTEMA DE ENCUESTAS WSC' }
                            </h1>
                        </div>
                    </div>
                </div>
                <div className='container-fuid paddingContainer' style={{'marginTop':'30px'}}>
                    { CUSTSURV_EncuestaCabecera.id!==1 && CUSTSURV_EncuestaCabecera.id!==2 ?
                    <h3 className="title-h3 font-weight-700 mb-2">Sistema de encuentas para saber la satisfacción del cliente, de WSCARGO</h3>
                    :
                    CUSTSURV_EncuestaCabecera.id==1 ?
                    <div>
                        <Card>
                            <CardContent>
                                <div style={{'marginTop':'10px'}}>
                                    <div className="col-12">
                                        <h1 className="font-weight-500">1.- ¿De manera general, cómo te sientes con nuestro servicio? <i title="Solo una opción" className={IconRequerido1x}></i></h1>
                                    </div>
                                    <div className='col-12 row text-center' style={{'marginTop':'30px'}}>
                                        <div
                                        className={`${StyleGrillaSatisfaccion} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            FaceActivated(1,1);
                                            SetRespuesta('1',1,2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.MuyInsatisfecho'/></div>
                                            <div className={`MuiFormControl-root MuiTextField-root`}>
                                                <div className="justify-center">
                                                    { face1Encuesta1==0 ? <img src={require("../../assets/images/Face1_1.png")} width="50px" />
                                                    : <img src={require("../../assets/images/Face1_2.png")} width="50px" /> }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                        className={`${StyleGrillaSatisfaccion} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            FaceActivated(2,1);
                                            SetRespuesta('1',2,2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.Insatisfecho'/></div>
                                            <div className="MuiFormControl-root MuiTextField-root">
                                                <div className="justify-center">
                                                    { face2Encuesta1==0 ? <img src={require("../../assets/images/Face2_1.png")} width="50px" />
                                                    : <img src={require("../../assets/images/Face2_2.png")} width="50px" /> }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                        className={`${StyleGrillaSatisfaccion} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            FaceActivated(3,1);
                                            SetRespuesta('1',3,2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.Neutral'/></div>
                                            <div className="MuiFormControl-root MuiTextField-root">
                                                <div className="justify-center">
                                                    { face3Encuesta1==0 ? <img src={require("../../assets/images/Face3_1.png")} width="50px" />
                                                    : <img src={require("../../assets/images/Face3_2.png")} width="50px" /> }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                        className={`${StyleGrillaSatisfaccion} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            FaceActivated(4,1);
                                            SetRespuesta('1',4,2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.Satisfecho'/></div>
                                            <div className="MuiFormControl-root MuiTextField-root">
                                                <div className="justify-center">
                                                    { face4Encuesta1==0 ? <img src={require("../../assets/images/Face4_1.png")} width="50px" />
                                                    : <img src={require("../../assets/images/Face4_2.png")} width="50px" /> }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                        className={`${StyleGrillaSatisfaccion} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            FaceActivated(5,1);
                                            SetRespuesta('1',5,2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.MuySatisfecho'/></div>
                                            <div className="MuiFormControl-root MuiTextField-root">
                                                <div className="justify-center">
                                                    { face5Encuesta1==0 ? <img src={require("../../assets/images/Face5_1.png")} width="50px" />
                                                    : <img src={require("../../assets/images/Face5_2.png")} width="50px" /> }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{'marginTop':'30px'}}>
                                    <div className="col-12">
                                        <h1 className="font-weight-500 mb-3">2.- Coméntenos cómo podemos mejorar su experiencia actual</h1>
                                    </div>
                                    <div className={`col-12 col-lg-12`}>
                                    <TextareaAutosize
                                        id="mensaje"
                                        name="mensaje"
                                        rows={10}
                                        className={` col-12 ${classes.messageBox}`}
                                        onChange={OnChange_ctrl_Temp_CUSTSURV_Encuesta1Detalle}
                                        onBlur={OnBlur_ctrl_Temp_CUSTSURV_Encuesta1Detalle}
                                    />
                                    </div>
                                </div>
                            {
                                errores1Mostrar==1?
                                <Alert severity="error"  style={{'marginTop':'30px'}}><AlertTitle>FALTA RESPUESTA EN:</AlertTitle>{errores1Mensaje}</Alert>
                                :null
                            }
                            <Button
                                onClick={() => {
                                    setEnviarEncuesta([CUSTSURV_EncuestaCabecera, ctrl_Temp_CUSTSURV_Encuesta1Detalle])
                                    setErrores1Mensaje('');
                                    setErrores1Mostrar(0);
                                    ValidarEncuesta1();
                                }}
                                className={`button button-outline-danger button-medium button-100-mobile mt-4 `}
                                >
                                ENVIAR
                            </Button>
                            </CardContent>
                        </Card>
                    </div>
                    :
                    CUSTSURV_EncuestaCabecera.id==2 ?
                    <div>
                        <Card>
                            <CardContent>
                                <div style={{'marginTop':'10px'}}>
                                    <div className="col-12">
                                        <h1 className="font-weight-500">1.- ¿De manera general, cómo te sientes con nuestro servicio? <i title="Solo una opción" className={IconRequerido1x}></i></h1>
                                    </div>
                                    <div className='col-12 row text-center' style={{'marginTop':'30px'}}>
                                        <div
                                        className={`${StyleGrillaSatisfaccion} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            FaceActivated(1,2);
                                            SetRespuesta('p1r1','1',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.MuyInsatisfecho'/></div>
                                            <div className={`MuiFormControl-root MuiTextField-root`}>
                                                <div className="justify-center">
                                                    { face1Encuesta2==0 ? <img src={require("../../assets/images/Face1_1.png")} width="50px" />
                                                    : <img src={require("../../assets/images/Face1_2.png")} width="50px" /> }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                        className={`${StyleGrillaSatisfaccion} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            FaceActivated(2,2);
                                            SetRespuesta('p1r1','2',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.Insatisfecho'/></div>
                                            <div className="MuiFormControl-root MuiTextField-root">
                                                <div className="justify-center">
                                                    { face2Encuesta2==0 ? <img src={require("../../assets/images/Face2_1.png")} width="50px" />
                                                    : <img src={require("../../assets/images/Face2_2.png")} width="50px" /> }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                        className={`${StyleGrillaSatisfaccion} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            FaceActivated(3,2);
                                            SetRespuesta('p1r1','3',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.Neutral'/></div>
                                            <div className="MuiFormControl-root MuiTextField-root">
                                                <div className="justify-center">
                                                    { face3Encuesta2==0 ? <img src={require("../../assets/images/Face3_1.png")} width="50px" />
                                                    : <img src={require("../../assets/images/Face3_2.png")} width="50px" /> }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                        className={`${StyleGrillaSatisfaccion} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            FaceActivated(4,2);
                                            SetRespuesta('p1r1','4',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.Satisfecho'/></div>
                                            <div className="MuiFormControl-root MuiTextField-root">
                                                <div className="justify-center">
                                                    { face4Encuesta2==0 ? <img src={require("../../assets/images/Face4_1.png")} width="50px" />
                                                    : <img src={require("../../assets/images/Face4_2.png")} width="50px" /> }
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                        className={`${StyleGrillaSatisfaccion} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            FaceActivated(5,2);
                                            SetRespuesta('p1r1','5',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.MuySatisfecho'/></div>
                                            <div className="MuiFormControl-root MuiTextField-root">
                                                <div className="justify-center">
                                                    { face5Encuesta2==0 ? <img src={require("../../assets/images/Face5_1.png")} width="50px" />
                                                    : <img src={require("../../assets/images/Face5_2.png")} width="50px" /> }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{'marginTop':'30px'}}>
                                    <div className="col-12">
                                        <h1 className="font-weight-500 mb-3">2.- ¿Cómo calificarías los siguientes aspectos del servicio? <i title="Debe responder todas las opciones" className={IconRequerido1x}></i></h1>
                                    </div>
                                    <div className={`row col-12`}>
                                        <div className={` ${StyleGrillaTextoEstrella}`}>
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.AtencionDelEjecutivo'/></div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(1,1,2);
                                            SetRespuesta('p2r1','1',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star1_1Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(1,2,2);
                                            SetRespuesta('p2r1','2',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star1_2Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(1,3,2);
                                            SetRespuesta('p2r1','3',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star1_3Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(1,4,2);
                                            SetRespuesta('p2r1','4',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star1_4Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(1,5,2);
                                            SetRespuesta('p2r1','5',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star1_5Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`row col-12`}>
                                        <div className={` ${StyleGrillaTextoEstrella}`}>
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.TransparienciaDelProceso'/></div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(2,1,2);
                                            SetRespuesta('p2r2','1',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star2_1Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(2,2,2);
                                            SetRespuesta('p2r2','2',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star2_2Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(2,3,2);
                                            SetRespuesta('p2r2','3',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star2_3Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(2,4,2);
                                            SetRespuesta('p2r2','4',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star2_4Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(2,5,2);
                                            SetRespuesta('p2r2','5',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star2_5Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`row col-12`}>
                                        <div className={` ${StyleGrillaTextoEstrella}`}>
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.EntregaenLosPlazosEsperados'/></div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(3,1,2);
                                            SetRespuesta('p2r3','1',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star3_1Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(3,2,2);
                                            SetRespuesta('p2r3','2',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star3_2Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(3,3,2);
                                            SetRespuesta('p2r3','3',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star3_3Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(3,4,2);
                                            SetRespuesta('p2r3','4',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star3_4Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(3,5,2);
                                            SetRespuesta('p2r3','5',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star3_5Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`row col-12`}>
                                        <div className={` ${StyleGrillaTextoEstrella}`}>
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.InformacionAcercaDeLasEtapasDelServicio'/></div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(4,1,2);
                                            SetRespuesta('p2r4','1',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star4_1Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(4,2,2);
                                            SetRespuesta('p2r4','2',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star4_2Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(4,3,2);
                                            SetRespuesta('p2r4','3',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star4_3Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(4,4,2);
                                            SetRespuesta('p2r4','4',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star4_4Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(4,5,2);
                                            SetRespuesta('p2r4','5',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star4_5Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`row col-12`}>
                                        <div className={` ${StyleGrillaTextoEstrella}`}>
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.EstadoDeSuCargaRecibida'/></div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(5,1,2);
                                            SetRespuesta('p2r5','1',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star5_1Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(5,2,2);
                                            SetRespuesta('p2r5','2',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star5_2Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(5,3,2);
                                            SetRespuesta('p2r5','3',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star5_3Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(5,4,2);
                                            SetRespuesta('p2r5','4',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star5_4Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(5,5,2);
                                            SetRespuesta('p2r5','5',2);
                                        }}
                                        >
                                        <div className="text font-weight-100 m-0 text-dark">
                                            { star5_5Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                            : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                        </div>
                                    </div>

                                    <div className={`row col-12`}>
                                        <div className={` ${StyleGrillaTextoEstrella}`}>
                                            <div className="text font-weight-100 m-0 text-dark"><IntlMessages id='label.TiempoDeRespuestaAnteConsultas'/></div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(6,1,2);
                                            SetRespuesta('p2r6','1',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star6_1Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(6,2,2);
                                            SetRespuesta('p2r6','2',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star6_2Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(6,3,2);
                                            SetRespuesta('p2r6','3',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star6_3Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(6,4,2);
                                            SetRespuesta('p2r6','4',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star6_4Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                        <div
                                        className={` ${StyleGrillaEstrella} ${classes.imagenHovered}`}
                                        style={{'marginBottom':'20px'}}
                                        onClick={() => {
                                            StarActivated(6,5,2);
                                            SetRespuesta('p2r6','5',2);
                                        }}
                                        >
                                            <div className="text font-weight-100 m-0 text-dark">
                                                { star6_5Encuesta2==0 ? <img src={require("../../assets/images/Estrella_1.png")} width="50px" />
                                                : <img src={require("../../assets/images/Estrella_2.png")} width="50px" /> }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div style={{'marginTop':'30px'}}>
                                </div>
                                <div className="col-12">
                                    <h1 className="font-weight-500 mb-3">3.- ¿Cómo le resultó la gestión en las siguientes etapas? <i title="Debe responder todas las opciones" className={IconRequerido1x}></i></h1>
                                </div>
                                <div className={`text-left col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12`}>
                                    <div className="text font-weight-100 m-0 text-dark"><strong><IntlMessages id='label.ProcesoDePago'/></strong></div>
                                </div>
                                <div
                                className={`row`}
                                sx={{ justifyContent: 'center' }}
                                >
                                    <div className={`${StyleTextoCheckRadio}`} style={{'margin':'0px'}}>
                                        <div className='col-12'><IntlMessages id='label.ExtraDificil'/></div>
                                        <div className='col-12'><Radio
                                        checked={selectedChecked12 === '1'}
                                        value="1"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': '1' }}
                                        onChange={() => {
                                            setSelectedChecked12('1');
                                            SetRespuesta('p3r1','1',2);
                                        }}
                                        /></div>
                                    </div>

                                    <div className={`${StyleTextoCheckRadio}`} style={{'margin':'0px'}}>
                                        <div className='col-12'><IntlMessages id='label.MuyDificil'/></div>
                                        <div className='col-12'><Radio
                                        checked={selectedChecked12 === '2'}
                                        value="1"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': '2' }}
                                        onChange={() => {
                                            setSelectedChecked12('2');
                                            SetRespuesta('p3r1','2',2);
                                        }}
                                        /></div>
                                    </div>

                                    <div className={`${StyleTextoCheckRadioDificil}`} style={{'margin':'0px'}}>
                                        <div className='col-12'><IntlMessages id='label.Dificil'/></div>
                                        <div className='col-12'><Radio
                                        checked={selectedChecked12 === '3'}
                                        value="3"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': '3' }}
                                        onChange={() => {
                                            setSelectedChecked12('3');
                                            SetRespuesta('p3r1','3',2);
                                        }}
                                        /></div>
                                    </div>

                                    <div className={`${StyleTextoCheckRadioIndiferente}`}>
                                        <div className='col-12'><IntlMessages id='label.Indiferente'/></div>
                                        <div className='col-12'><Radio
                                        checked={selectedChecked12 === '4'}
                                        value="4"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': '4' }}
                                        onChange={() => {
                                            setSelectedChecked12('4');
                                            SetRespuesta('p3r1','4',2);
                                        }}
                                        /></div>
                                    </div>

                                    <div className={`${StyleTextoCheckRadioDificil}`}>
                                        <div className='col-12'><IntlMessages id='label.Facil'/></div>
                                        <div className='col-12'><Radio
                                        checked={selectedChecked12 === '5'}
                                        value="5"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': '5' }}
                                        onChange={() => {
                                            setSelectedChecked12('5');
                                            SetRespuesta('p3r1','5',2);
                                        }}
                                        /></div>
                                    </div>

                                    <div className={`${StyleTextoCheckRadio}`}>
                                        <div className='col-12'><IntlMessages id='label.MuyFacil'/></div>
                                        <div className='col-12'><Radio
                                        checked={selectedChecked12 === '6'}
                                        value="6"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': '6' }}
                                        onChange={() => {
                                            setSelectedChecked12('6');
                                            SetRespuesta('p3r1','6',2);
                                        }}
                                        /></div>
                                    </div>

                                    <div className={`${StyleTextoCheckRadio}`}>
                                        <div className='col-12'><IntlMessages id='label.ExtraFacil'/></div>
                                        <div className='col-12'><Radio
                                        checked={selectedChecked12 === '7'}
                                        value="7"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': '7' }}
                                        onChange={() => {
                                            setSelectedChecked12('7');
                                            SetRespuesta('p3r1','7',2);
                                        }}
                                        /></div>
                                    </div>
                                </div>

                                <div className={`text-left col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12`}>
                                    <div className="text font-weight-100 m-0 text-dark"><strong><IntlMessages id='label.CoordinacionDeDespachoORetiro'/></strong></div>
                                </div>
                                <div
                                className={`row`}
                                sx={{ justifyContent: 'center' }}
                                >
                                    <div className={`${StyleTextoCheckRadio}`} style={{'margin':'0px'}}>
                                        <div className='col-12'><IntlMessages id='label.ExtraDificil'/></div>
                                        <div className='col-12'><Radio
                                        checked={selectedChecked22 === '1'}
                                        value="1"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': '1' }}
                                        onChange={() => {
                                            setSelectedChecked22('1');
                                            SetRespuesta('p3r2','1',2);
                                        }}
                                        /></div>
                                    </div>

                                    <div className={`${StyleTextoCheckRadio}`} style={{'margin':'0px'}}>
                                        <div className='col-12'><IntlMessages id='label.MuyDificil'/></div>
                                        <div className='col-12'><Radio
                                        checked={selectedChecked22 === '2'}
                                        value="1"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': '2' }}
                                        onChange={() => {
                                            setSelectedChecked22('2');
                                            SetRespuesta('p3r2','2',2);
                                        }}
                                        /></div>
                                    </div>

                                    <div className={`${StyleTextoCheckRadioDificil}`} style={{'margin':'0px'}}>
                                        <div className='col-12'><IntlMessages id='label.Dificil'/></div>
                                        <div className='col-12'><Radio
                                        checked={selectedChecked22 === '3'}
                                        value="3"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': '3' }}
                                        onChange={() => {
                                            setSelectedChecked22('3');
                                            SetRespuesta('p3r2','3',2);
                                        }}
                                        /></div>
                                    </div>

                                    <div className={`${StyleTextoCheckRadioIndiferente}`}>
                                        <div className='col-12'><IntlMessages id='label.Indiferente'/></div>
                                        <div className='col-12'><Radio
                                        checked={selectedChecked22 === '4'}
                                        value="4"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': '4' }}
                                        onChange={() => {
                                            setSelectedChecked22('4');
                                            SetRespuesta('p3r2','4',2);
                                        }}
                                        /></div>
                                    </div>

                                    <div className={`${StyleTextoCheckRadioDificil}`}>
                                        <div className='col-12'><IntlMessages id='label.Facil'/></div>
                                        <div className='col-12'><Radio
                                        checked={selectedChecked22 === '5'}
                                        value="5"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': '5' }}
                                        onChange={() => {
                                            setSelectedChecked22('5');
                                            SetRespuesta('p3r2','5',2);
                                        }}
                                        /></div>
                                    </div>

                                    <div className={`${StyleTextoCheckRadio}`}>
                                        <div className='col-12'><IntlMessages id='label.MuyFacil'/></div>
                                        <div className='col-12'><Radio
                                        checked={selectedChecked22 === '6'}
                                        value="6"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': '6' }}
                                        onChange={() => {
                                            setSelectedChecked22('6');
                                            SetRespuesta('p3r2','6',2);
                                        }}
                                        /></div>
                                    </div>

                                    <div className={`${StyleTextoCheckRadio}`}>
                                        <div className='col-12'><IntlMessages id='label.ExtraFacil'/></div>
                                        <div className='col-12'><Radio
                                        checked={selectedChecked22 === '7'}
                                        value="7"
                                        name="radio-button-demo"
                                        inputProps={{ 'aria-label': '7' }}
                                        onChange={() => {
                                            setSelectedChecked22('7');
                                            SetRespuesta('p3r2','7',2);
                                        }}
                                        /></div>
                                    </div>
                                </div>

                                <div style={{'marginTop':'30px'}}>
                                    <div className="col-12">
                                        <h1 className="font-weight-500 mb-3">4.- Considerando la experiencia completa con nuestra empresa, ¿cuán dispuesto estaría usted a recomendar nuestros servicios a otras personas? <i title="Debe seleccionar una opción" className={IconRequerido1x}></i></h1>
                                    </div>
                                    <div
                                    className={`row`}
                                    >
                                        <div className={`${StyleBotonNumero}`}>
                                            <Button
                                            onClick={() => {
                                                ActivateButtonNps(0,2);
                                                SetRespuesta('p4r1','0',2);
                                            }}
                                            variant={variantButton02Nps}
                                            color="primary"
                                            style={{'marginBottom':'20px'}}
                                            >0</Button>
                                        </div>
                                        <div className={`${StyleBotonNumero}`}>
                                            <Button
                                            onClick={() => {
                                                ActivateButtonNps(1,2);
                                                SetRespuesta('p4r1','1',2);
                                            }}
                                            variant={variantButton12Nps}
                                            color="primary"
                                            style={{'marginBottom':'20px'}}
                                            >1</Button>
                                        </div>
                                        <div className={`${StyleBotonNumero}`}>
                                            <Button
                                            onClick={() => {
                                                ActivateButtonNps(2,2);
                                                SetRespuesta('p4r1','2',2);
                                            }}
                                            variant={variantButton22Nps}
                                            color="primary"
                                            style={{'marginBottom':'20px'}}
                                            >2</Button>
                                        </div>
                                        <div className={`${StyleBotonNumero}`}>
                                            <Button
                                            onClick={() => {
                                                ActivateButtonNps(3,2);
                                                SetRespuesta('p4r1','3',2);
                                            }}
                                            variant={variantButton32Nps}
                                            color="primary"
                                            style={{'marginBottom':'20px'}}
                                            >3</Button>
                                        </div>
                                        <div className={`${StyleBotonNumero}`}>
                                            <Button
                                            onClick={() => {
                                                ActivateButtonNps(4,2);
                                                SetRespuesta('p4r1','4',2);
                                            }}
                                            variant={variantButton42Nps}
                                            color="primary"
                                            style={{'marginBottom':'20px'}}
                                            >4</Button>
                                        </div>
                                        <div className={`${StyleBotonNumero}`}>
                                            <Button
                                            onClick={() => {
                                                ActivateButtonNps(5,2);
                                                SetRespuesta('p4r1','5',2);
                                            }}
                                            variant={variantButton52Nps}
                                            color="primary"
                                            style={{'marginBottom':'20px'}}
                                            >5</Button>
                                        </div>
                                        <div className={`${StyleBotonNumero}`}>
                                            <Button
                                            onClick={() => {
                                                ActivateButtonNps(6,2);
                                                SetRespuesta('p4r1','6',2);
                                            }}
                                            variant={variantButton62Nps}
                                            color="primary"
                                            style={{'marginBottom':'20px'}}
                                            >6</Button>
                                        </div>
                                        <div className={`${StyleBotonNumero}`}>
                                            <Button
                                            onClick={() => {
                                                ActivateButtonNps(7,2);
                                                SetRespuesta('p4r1','7',2);
                                            }}
                                            variant={variantButton72Nps}
                                            color="primary"
                                            style={{'marginBottom':'20px'}}
                                            >7</Button>
                                        </div>
                                        <div className={`${StyleBotonNumero}`}>
                                            <Button
                                            onClick={() => {
                                                ActivateButtonNps(8,2);
                                                SetRespuesta('p4r1','8',2);
                                            }}
                                            variant={variantButton82Nps}
                                            color="primary"
                                            style={{'marginBottom':'20px'}}
                                            >8</Button>
                                        </div>
                                        <div className={`${StyleBotonNumero}`}>
                                            <Button
                                            onClick={() => {
                                                ActivateButtonNps(9,2);
                                                SetRespuesta('p4r1','9',2);
                                            }}
                                            variant={variantButton92Nps}
                                            color="primary"
                                            style={{'marginBottom':'20px'}}
                                            >9</Button>
                                        </div>
                                        <div className={`${StyleBotonNumero}`}>
                                            <Button
                                            onClick={() => {
                                                ActivateButtonNps(10,2);
                                                SetRespuesta('p4r1','10',2);
                                            }}
                                            variant={variantButton102Nps}
                                            color="primary"
                                            style={{'marginBottom':'20px'}}
                                            >10</Button>
                                        </div>
                                    </div>
                                    <div style={{'marginTop':'30px'}}>
                                        <div className="col-12">
                                            <h1 className="font-weight-500 mb-3">5.- Coméntenos cómo podemos mejorar su experiencia actual</h1>
                                        </div>
                                        <div className={`col-12 col-lg-12`}>
                                        <TextareaAutosize
                                            id="p5r1"
                                            name="p5r1"
                                            rows={10}
                                            value={setCtrl_Temp_CUSTSURV_Encuesta2Detalle.p5r1}
                                            className={` col-12 ${classes.messageBox}`}
                                            onChange={OnChange_p5r1}
                                            onBlur={OnChange_p5r1}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                errores2Mostrar==1?
                                <Alert severity="error"  style={{'marginTop':'30px'}}><AlertTitle>FALTA RESPUESTA EN:</AlertTitle><p>{errores2Mensaje}</p></Alert>
                                :null
                            }
                            <br></br>
                            <br></br>
                            <br></br>
                            <div>
                                <Button
                                    onClick={() => {
                                        setEnviarEncuesta([CUSTSURV_EncuestaCabecera, ctrl_Temp_CUSTSURV_Encuesta2Detalle])
                                        setErrores2Mensaje('');
                                        setErrores2Mostrar(0);
                                        ValidarEncuesta2();
                                    }}
                                    className={`button button-outline-danger button-medium button-100-mobile mt-4 `}
                                    >
                                    ENVIAR
                                </Button>
                            </div>
                            </CardContent>
                        </Card>
                    </div>
                    :null
                    }
                </div>

            </div>
            <Dialog
            open={openCUSTSURV_ShowDialogMessage}
            onClose={handleCloseCUSTSURV_ShowDialogMessage}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{zIndex: 2600}}
            >
                <div className={CUSTSURV_Dialog.background}>
                    <DialogTitle id="alert-dialog-title" className='text-white font-weight-bol'>
                        <IntlMessages id={CUSTSURV_Dialog.title}/> !
                    </DialogTitle>
                </div>
                <DialogContent className='mt-4 text-black font-weight-bol'>
                    <DialogContentText id="alert-dialog-description" className='text-black font-weight-bol'>{CUSTSURV_Dialog.content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                    variant="contained"
                    className='bg-danger text-white'
                    size="small"
                    style={{'marginRight':'5px'}}
                    onClick={handleCloseCUSTSURV_ShowDialogMessage}
                    >
                    OK
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
            open={openCUSTSURV_ShowDialogConfirmar}
            onClose={handleCloseCUSTSURV_ShowDialogConfirmar}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{zIndex: 2600}}
            >
                <div className={CUSTSURV_DialogConfirmar.background}>
                    <DialogTitle id="alert-dialog-title" className='text-white font-weight-bol'>
                        <IntlMessages id={CUSTSURV_DialogConfirmar.title}/> !
                    </DialogTitle>
                </div>
                <DialogContent className='mt-4 text-black font-weight-bol'>
                    <DialogContentText id="alert-dialog-description" className='text-black font-weight-bol'>{CUSTSURV_DialogConfirmar.content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <div className='col-12 row'>
                        <div className='col-6 text-center'>
                            <Button
                            variant="contained"
                            className='col-1 bg-info text-white'
                            size="small"
                            style={{'marginRight':'10px'}}
                            onClick={() => {
                                PostEncuesta();
                            }}
                            >
                                <IntlMessages id='label.Si'/>
                            </Button>
                        </div>
                        <div className='col-6 text-center'>
                            <Button
                            variant="contained"
                            className='col-1 bg-danger text-white'
                            size="small"
                            style={{'marginRight':'10px'}}
                            onClick={() => {
                                CerrarConfirmar();
                            }}
                            >
                                <IntlMessages id='label.No'/>
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>

        </div>
    );
}

export default EncuestaForm;