import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import HelpIcon from '@material-ui/icons/Help';
import { helpHttp } from '../helpers/helpHttp';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat';
import LaunchIcon from '@material-ui/icons/Launch';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CardOne from '../stepper/One'
import CardTwo from '../stepper/Two'
import { Theme, createStyles } from '@material-ui/core/styles';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StepConnector from '@material-ui/core/StepConnector';

import {
    makeStyles,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    InputLabel,
    TextField,
    Tooltip,
    InputAdornment,
    FormGroup,
    FormControlLabel,
    Button,
    Checkbox,
    Typography,
    IconButton,
    Avatar,
    Stepper,
    Step,
    StepLabel

} from "@material-ui/core";

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#263238',
        color: '#fff',
        maxWidth: 300,
        padding: "12px 16px",
        "& .MuiTypography-h5": {
            fontSize: "14px"
        },
        "& .MuiTypography-h6": {
            fontSize: "10px"
        }
    }
}))(Tooltip);


const ColorlibConnector = withStyles({
    // alternativeLabel: {
    //   top: 22,
    // },
    active: {
      '& $line': {
        background: '#EA5259'
        //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    completed: {
      '& $line': {
        background: '#EA5259'
        //   'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    line: {
      height: 1.5,
      border: 0,
      backgroundColor: '#BDBDBD',
    //   borderRadius: 1,
    },
  })(StepConnector);

const useStyles = makeStyles((theme) => ({
    inputAdornment: {
        "& .MuiTypography-body1": {
            fontSize: "12px"
        }
    },
    icon: {
        fontSize: "16px",
    },
    // root: {
    //     '& .MuiStepper-root': {
    //         padding: '16px 24px'
    //     }
    // }
}));

const initialForm = {
    volume: '',
    weight: '',
    description: '',
    puertoOrigen: '',
    puertoDestino: ''
}

// origen y destino cargar
const originCarga = [
    { id: '1', puerto: 'uno' },
    { id: '2', puerto: 'dos' }
];

const destinoCarga = [
    { id: '1', puerto: 'Santiago' },
    { id: '2', puerto: 'San Antonio' }
];


function getSteps() {
    return ['Cotizar', 'Proveedores'];
}

function getStepContent(stepIndex) {
    switch (stepIndex) {
        case 0:
            return <CardOne />;
        case 1:
            return <CardTwo />;
        default:
            return 'Unknown stepIndex';
    }
}

const NuevoServicio = ({nuevoServicio, handleCloseNuevoServicio }) => {
    const classes = useStyles();
    

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Dialog open={nuevoServicio} onClose={handleCloseNuevoServicio} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title" className="mt-4 pb-1">Nuevo Servicio (Tarifa actual 405 USD/m3)</DialogTitle>
            <DialogContent className="mb-4 pb-3">
                {/* <DialogContentText className="m-0">
                    
                    Tarifa 405 USD/m3
                    Para valorizar el servicio, debes ingresar el volumen, peso y tipo de producto a importar.
                </DialogContentText> */}
            
                <div className={classes.root}>
                    <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                        {steps.map((label, index) => (
                            <Step key={label} className="prueba">
                                <div className={activeStep < index  ? "icon-label" : "icon-label-ative"}>
                                    {activeStep < index + 1 ? (<FiberManualRecordIcon />) : (<FiberManualRecordIcon />)}
                                </div> 
                                
                                <StepLabel>
                                    
                                    {label}
                                </StepLabel>

                                {/* <StepLabel>
                                <div className="justify-center-center">
                                    <div className={activeStep < index + 1 ? "icon-label" : "icon-label-ative"}>
                                    {activeStep < index + 1 ? (<LabelImportantIcon />) : (<EditIcon className={classes.icon} />)}
                                    </div>
                                    {label}
                                </div>
                                </StepLabel> */}
                                {/* <StepLabel>{label}</StepLabel> */}
                            </Step>
                        ))}
                    </Stepper>
                    <div>
                        {activeStep === steps.length ? (
                            <div>
                                <Typography className={classes.instructions}>All steps completed</Typography>
                                <Button onClick={handleReset}>Reset</Button>
                            </div>
                        ) : (
                            <>
                                <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                                <div className="justify-end  mt-4">
                                    <Button
                                        // disabled={activeStep === 0}
                                        // onClick={handleBack}
                                        // className={classes.backButton}
                                        onClick={() => {
                                            handleReset();
                                            handleCloseNuevoServicio();
                                        }}
                                        className="button button-outline-danger button-100-mobile mr-1"
                                    >
                                        Cancelar
                                    </Button>
                                    <Button className="button button-danger button-100-mobile ml-1" onClick={handleNext}>
                                        {activeStep === steps.length - 1 ? 'Iniciar Servicio' : 'Siguiente'}
                                    </Button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default NuevoServicio