
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help';
import lodash from 'lodash';
import { validate, clean, format, getCheckDigit } from 'rut.js'
import {globalChangeStatesCotizadorCliente,showMessageCotizadorCliente} from '../../../../modules/cotizador/Actions';
import {validationErrorsCotizacion} from '../../../../models';
import {
    InputLabel,
    TextField,
    IconButton,
    InputAdornment,
    makeStyles,
} from "@material-ui/core";
import {useLocation, useParams} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    ButtonNav: {
        padding: '0',
        height: '13px',
        width: '2%',
        color: "#8F8F8F",
        fontSize: "14px",
        "&:hover": {
            backgroundColor: "transparent",
            color: "#44403f",
        }
    },
    icon: {
        fontSize: "16px",
        marginLeft: "-30px"
    },
    label: {
        color: "#8F8F8F"
    },
    inputAdornment: {
        "& .MuiTypography-body1":{
            fontSize: "12px"
        }
    },
}));

let rgxValidarEmail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
let rgxNumero = /([0-9])/;
const One = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
  
    let {search} = useLocation();
    let params = new URLSearchParams(search);
    //console.log(params.get("CaseNumber"), params.get("IDCase"), params.get("Contacto"), params.get("Cliente"));

    const {
       validations
    } = useSelector(({ CotizadorCliente }) => CotizadorCliente);

    //const [controls, setControls] = useState(props.payload);
    const [controls, setControls] = useState({
        ...props.payload,
        email: params.get("Contacto") == null ? '': params.get("Contacto"),
        idCase: params.get("IDCase") == null ? '': params.get("IDCase"),
        caseNumber: params.get("CaseNumber") == null ? '': params.get("CaseNumber")
    });

    const onChange = (event)=>{
        const obj=lodash.cloneDeep(controls);
        const newPayload={
            ...obj,
            [event.target.id ? event.target.id:event.target.name] : event.target.value
        }
        setControls(newPayload);   
    }

    const onBlur = (event)=>{
        event.persist();
        let obj=lodash.cloneDeep(controls);
            if(event.target.id==='rut'){
                if( validate(format(event.target.value.toUpperCase().trim()))==true)
                {
                    var rut = format(event.target.value.toUpperCase().trim());
                    const newPayload={
                        ...obj,
                        rut: rut
                    };
                    setControls(newPayload);
                    dispatch(globalChangeStatesCotizadorCliente('payload',newPayload))
                }
                else if(event.target.value.toUpperCase().trim().length>0)
                {
                    var rut = '';
                    dispatch(showMessageCotizadorCliente({type:'error',text:'Rut inválido'}));
                    const newPayload={
                        ...obj,
                        rut : rut
                    };
                    setControls(newPayload);
                    dispatch(globalChangeStatesCotizadorCliente('payload',newPayload))
                }
            }if(event.target.id==='email'){
                if(!rgxValidarEmail.test(event.target.value)){
                    //errors.email = "El Email debe ser valido";
                    const newPayload={
                        ...obj,
                        email: ''
                    };

                    setControls(newPayload);
                    let newValidate=lodash.cloneDeep(validations);
                    newValidate.email.error=true;
                    newValidate.email.helperText='El formato del email es incorrecto';
                    dispatch(globalChangeStatesCotizadorCliente('validations',newValidate));
                    dispatch(globalChangeStatesCotizadorCliente('payload',newPayload))
                }else{
                    obj={
                        ...obj,
                        usuario:{
                            ...obj.usuario,
                            email:controls.email
                        }
                    }
                    dispatch(globalChangeStatesCotizadorCliente('validations',validationErrorsCotizacion));
                    dispatch(globalChangeStatesCotizadorCliente('payload',obj))
                }
            }if(event.target.id==='telefono'){
                if (!rgxNumero.test(event.target.value)) {
                    const newPayload={
                        ...obj,
                        telefono: ''
                    };

                    setControls(newPayload);
                    let newValidate=lodash.cloneDeep(validations);
                    newValidate.telefono.error=true;
                    newValidate.telefono.helperText='El teléfono solo debe contener números';
                    dispatch(globalChangeStatesCotizadorCliente('validations',newValidate));
                    dispatch(globalChangeStatesCotizadorCliente('payload',newPayload))
                } else if (event.target.value.toString().length != 8) {
                    const newPayload={
                        ...obj,
                        telefono: ''
                    };

                    setControls(newPayload);
                    let newValidate=lodash.cloneDeep(validations);
                    newValidate.telefono.error=true;
                    newValidate.telefono.helperText='No es un telfono válido.';
                    dispatch(globalChangeStatesCotizadorCliente('validations',newValidate));
                    dispatch(globalChangeStatesCotizadorCliente('payload',newPayload))
                }else{
                    obj={
                        ...obj,
                        usuario:{
                            ...obj.usuario,
                            email:controls.email
                        }
                    }
                    dispatch(globalChangeStatesCotizadorCliente('validations',validationErrorsCotizacion));
                    dispatch(globalChangeStatesCotizadorCliente('payload',obj))
                }
            }else{
                obj={
                    ...obj,
                    usuario:{
                        ...obj.usuario,
                        email:controls.email
                    }
                }
                dispatch(globalChangeStatesCotizadorCliente('validations',validationErrorsCotizacion));
                dispatch(globalChangeStatesCotizadorCliente('payload',obj))
            }    
    }
    return (
        <>
            <div className="form-content input-material-ui">
                <div className="row">
                    <div className='col-12 mb-2'>
                        <h4 className="text font-weight-500 mb-2">Con estos datos te entregaremos una oferta personalizada.</h4>
                    </div>
                    {/* input */}
                    <div className="col-12 col-lg-4">
                        <InputLabel className="Label_Input_Format">Nombre</InputLabel>
                        <TextField
                            id="nombre"
                            name="nombre"
                            value={controls.nombre}
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder="Ingresa Nombre"
                            error={validations.nombre.error}
                            helperText={validations.nombre.helperText}
                        />
                    </div>

                    {/* input */}
                    <div className="col-12 col-lg-4 my-3 my-md-0">
                        <InputLabel className="Label_Input_Format">Email</InputLabel>
                        <TextField
                            id="email"
                            name="email"
                            value={controls.email }
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder="ejemplo@gmail.com"
                            error={validations.email.error}
                            helperText={validations.email.helperText}
                        />
                    </div>

                    {/* input */}
                    <div className="col-12 col-lg-4">
                        <InputLabel className="Label_Input_Format">Teléfono</InputLabel>
                        <TextField
                            id="telefono"
                            name="telefono"
                            value={controls.telefono}
                            onBlur={onBlur}
                            onChange={onChange}
                            placeholder="90234567"
                            size="small"
                            type="tel"
                            error={validations.telefono.error}
                            helperText={validations.telefono.helperText}
                            className={classes.inputAdornment}
                            InputProps={{
                                startAdornment: <InputAdornment  position="start">+569</InputAdornment>
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default One;
