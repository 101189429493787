import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { nanoid } from 'nanoid'
import {
  makeStyles,
  InputLabel,
  TextField,
  Tooltip,
  InputAdornment,
  Button,
  Typography,
  IconButton,
  Avatar,
  Hidden,
  Input,

} from "@material-ui/core";

import HelpIcon from '@material-ui/icons/Help';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

// Tooltip
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#263238',
    color: '#fff',
    maxWidth: 350,
    padding: "12px 16px",
    "& .MuiTypography-h5": {
      fontSize: "14px"
    },
    "& .MuiTypography-h6": {
      fontSize: "10px"
    }
  }
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  inputAdornment: {
    "& .MuiTypography-body1": {
      fontSize: "12px"
    }
  },
  icon: {
    fontSize: "16px",
    color: '#828282'
  },
  avatar: {
    width: "40px !important",
    height: "40px !important",
  },
  title: {
    paddingLeft: "12px"
  }
}));


///
const initialForm = {
  nombre_chino: '',
  nombre_espanol_ingles: '',
  volumen_esperado: '',
  peso_esperado: '',
  bultos_esperado: '',
  id: null
}

const Two = () => {
  const classes = useStyles();
  const [form, setForm] = useState(initialForm)
  const [proveedor, setProveedor] = useState([])
  const [editProveedor, setEditProveedor] = useState(null)
  const [dataToProveedor, setDataToProveedor] = useState(null)


  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }


  const handleSubmit = (e) => {
    e.preventDefault()

    // if (!form.name || !form.apellido) {
    //     alert("vacio");
    //     return;
    // }

    if (form.id === null) {
      createData(form);
    } else {
      updateData(form);
    }

    handleReset();
  }

  const handleReset = (e) => {
    setForm(initialForm)
    setEditProveedor(null)

  }

  const createData = (data) => {
    data.id = Date.now();
    setProveedor([...proveedor, {
      id: nanoid(),
      nombre_chino: form.nombre_chino,
      nombre_espanol_ingles: form.nombre_espanol_ingles,
      volumen_esperado: form.volumen_esperado,
      peso_esperado: form.peso_esperado,
      bultos_esperado: form.bultos_esperado

    }]);
  }

  const updateData = (data) => {
    let newData = proveedor.map(el => el.id === data.id ? data : el);
    setProveedor(newData);
  }

  const deleteData = (id) => {
    let newData = proveedor.filter(el => el.id !== id);
    setProveedor(newData);
  }

  useEffect(() => {
    if (editProveedor) {
      setForm(editProveedor);
    } else {
      setForm(initialForm)
    }
  }, [editProveedor])

  return (
    <>
      <form className="input-material-ui" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-12 mb-3">
            <div className='align-item-center'>
              <h5 className="text font-weight-500"> {editProveedor ? "Actualizar Proveedor" : "Agregar Proveedor"}</h5>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography variant="h5" color="inherit" className='mb-2'>- Máximo 3 proveedores si trae menos de 5 m3.</Typography>
                    <Typography variant="h5" color="inherit">- Máximo 5 proveedores si trae sobre de 5 m3, con previa autorización por contenedor.</Typography>
                  </React.Fragment>
                }
              >
                <HelpIcon className={`ml-1 ${classes.icon}`} />
              </HtmlTooltip>
            </div>
            <p className="text-small font-weight-300">Máximo 3 proveedores si trae menos de 5 m3.</p>
          </div>
        </div>

        {/* Nombre proveedor */}
        <div className="row">
          <div className="col-12 mb-3">
            <p className={`text-small font-weight-500 ${classes.title}`}>Nombre Proveedor</p>
          </div>

          <div className="col-12 col-lg-6 mb-3 mb-md-0">
            <InputLabel className="Label_Input_Format">Nombre Chino</InputLabel>
            <TextField
              name="nombre_chino"
              placeholder="nombre chino del proveedor"
              onChange={handleChange}
              value={form.nombre_chino}
            />
          </div>

          <div className="col-12 col-lg-6">
            <InputLabel className="Label_Input_Format">Nombre español / ingles</InputLabel>
            <TextField
              name="nombre_espanol_ingles"
              placeholder="nombre español / ingles del proveedor"
              onChange={handleChange}
              value={form.nombre_espanol_ingles}
            />
          </div>
        </div>

        <div className="border my-4"></div>

        {/* Datos carga */}
        <div className="row">
          <div className="col-12 mb-3">
            <p className={`text-small font-weight-500 ${classes.title}`}>Datos de carga</p>
          </div>

          <div className="col-12 col-lg-6 mb-3">
            <InputLabel className="Label_Input_Format">Volumen M3 (esperado)</InputLabel>
            <TextField
              name="volumen_esperado"
              placeholder="80"
              type="number"
              onChange={handleChange}
              value={form.volumen_esperado}
              className={classes.inputAdornment}
              InputProps={{
                startAdornment: <InputAdornment position="start">M3</InputAdornment>
              }}
            />
          </div>

          <div className="col-12 col-lg-6 mb-3 mb-md-0">
            <InputLabel className="Label_Input_Format">Peso Kg (esperado)</InputLabel>
            <TextField
              name="peso_esperado"
              placeholder="80"
              type="number"
              onChange={handleChange}
              value={form.peso_esperado}
              className={classes.inputAdornment}
              InputProps={{
                startAdornment: <InputAdornment position="start">Kg</InputAdornment>
              }}
            />
          </div>

          <div className="col-12 col-lg-6">
            <InputLabel className="Label_Input_Format">Nº bultos</InputLabel>
            <TextField
              name="bultos_esperado"
              placeholder="80"
              type="number"
              onChange={handleChange}
              value={form.bultos_esperado}
            />
          </div>
        </div>

        <div className="border my-4"></div>

        {/* Documentos */}
        <div className="row">
          <div className="col-12 mb-3">
            <p className={`text-small font-weight-500 ${classes.title}`}>Documentos</p>
          </div>

          <div className="col-12 mb-3 input-file-ui">
            <InputLabel className="Label_Input_Format">Packing List (Chino)</InputLabel>
  
            <Input
              className="my-0 input-file"
              type="file"
              name="Packing_List_Chino"
            />
          </div>

          <div className="col-12 input-file-ui">
            <InputLabel className="Label_Input_Format">Packing List (Ingles)</InputLabel>
            <Input
              className="my-0 input-file"
              type="file"
              name="Packing_List_Ingles"
            />
          </div>
        </div>

        <Button
          className="button button-gray button-small mt-4"
          type="submit"
        >
          {editProveedor ? "Actualizar Proveedor" : "agregra proveedor"}
        </Button>
      </form>

      <div className="border my-4"></div>

      {/* Listados de proveedores  */}
      <h5 className="text font-weight-500">Proveedores Registrados</h5>

      {proveedor.length > 0 ?

        (
          proveedor.map((item, index) => (

            <div className="list_item app-lista-empresa" key={index}>
              <Avatar className={`app-lista-avatar ${classes.avatar}`}>
                {item.nombre_chino.slice(0, 2)}
              </Avatar>

              <div className="app-lista-content">
                <div className="app-lista-data">
                  <h4 className="text-small font-weight-700 text-capitalize">{item.nombre_chino}</h4>
                  {/* data versión desktop */}
                  <Hidden smDown>
                    <div className="app-lista-item">
                      <p className="text-xs font-weight-300">Vol. {item.volumen_esperado} M³</p>
                      <p className="text-xs font-weight-300 mx-3">Peso {item.peso_esperado} kg</p>
                      <p className="text-xs font-weight-300">Bultos Esperados {item.bultos_esperado}</p>
                    </div>
                  </Hidden>

                  {/* data versión mobile */}
                  <Hidden smUp>
                    <div className="app-lista-item">
                      <p className="text-xs font-weight-300">
                        Vol. {item.volumen_esperado} M³<span className="mx-2">/</span>Peso {item.peso_esperado}kg
                      </p>
                      <p className="text-xs font-weight-300">Bultose Esperados {item.bultos_esperado}</p>
                    </div>
                  </Hidden>
                </div>


                <div className='app-lista-acciones'>
                  <IconButton
                    onClick={() => {
                      setEditProveedor(item);
                    }}
                    aria-label="editar"
                    className="icon-button"
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    onClick={() => {
                      deleteData(item.id);
                    }}
                    aria-label="Eliminar"
                    className="icon-button"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>
            </div>
          ))
        ) :
        (
          <div className="list_item align-item-center">
            <p className="text-small font-weight-300">No hay proveedores registrado.</p>
          </div>
        )

      }
    </>
  )
}

export default Two