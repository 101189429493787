import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import asyncComponent from '../../util/asyncComponent';
import RouterDashboard from '../../modules/atrina/dashBoard/Routes.js';
import PrivateRoute from './PrivateRoute';
// const AsyncComponent = React.lazy(() => import('../../modules/atrina/dashBoard/Routes.js'));
const Routes = ({match, setStep}) =>

    <Switch>
        <Route path={`${match.url}/inicio`} component={asyncComponent(() => import('../../modules/dashboard/Routes'))} exact />
        <Route path={`${match.url}/registro-empresa-importador`} component={asyncComponent(() => import('../../modules/empresaImportador/Route'))} exact />
        <Route path={`${match.url}/detalle-servicio/:id`} component={asyncComponent(() => import('../../modules/detalleServicio/Route'))} exact />
        <Route path={`${match.url}/perfil`} component={asyncComponent(() => import('../../modules/usuario/Routes.js'))} exact />
        <Route path={`${match.url}/notificaciones`} component={asyncComponent(() => import('../../modules/notificaciones/Route.js'))} exact />
        <Route path={`${match.url}/cotizador`} component={asyncComponent(() => import('../../modules/cotizador/Route.js'))} exact />
        <Route path={`${match.url}/consolidado-rapido`} component={asyncComponent(() => import('../../modules/ConsolidadoRapido.js'))} exact />
        <Route path={`${match.url}/mantenedor-usuarios`} component={asyncComponent(() => import('../../modules/usuarios/Routes.js'))} exact />
        <Route path={`${match.url}/atrina-usuario`} component={asyncComponent(() => import('../../modules/atrina/usuario/Routes.js'))} exact />
        <Route path={`${match.url}/atrina-loading`} component={asyncComponent(() => import('../../modules/atrina/loading/Routes.js'))} exact />
        <Route path={`${match.url}/atrina-cotizador`} component={asyncComponent(() => import('../../modules/atrina/cotizador/Routes.js'))} exact />
        <Route path={`${match.url}/atrina-registro-empresa`} component={asyncComponent(() => import('../../modules/atrina/registro_empresa/Routes.js'))} exact />
        <Route path={`${match.url}/atrina-registro-persona`} component={asyncComponent(() => import('../../modules/atrina/registro_persona/Routes.js'))} exact />
        <Route path={`${match.url}/atrina-registro`} component={asyncComponent(() => import('../../modules/atrina/registro/Routes.js'))} exact />
        <Route path={`${match.url}/atrina-dashboard`} render={(props) => <RouterDashboard setStep={setStep} />} />
        <Route path={`${match.url}/atrina-detalle-servicio`} component={asyncComponent(() => import('../../modules/atrina/detalle_servicio/Routes.js'))} exact />
        <Route path={`${match.url}/atrina-notificaciones`} component={asyncComponent(() => import('../../modules/atrina/notificaciones/Routes.js'))} exact />
        <Route path={`${match.url}/atrina-dashboard`} component={asyncComponent(() => import('../../modules/atrina/dashBoard/Routes.js'))} exact />
        <Route path={`${match.url}/atrina-detalle-servicio`} component={asyncComponent(() => import('../../modules/atrina/detalle_servicio/Routes.js'))} exact />
    </Switch>;
// render={(props) => <ActivarCuenta {...props} />}
export default withRouter(Routes);

