import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router'
import Settings from './Settings';
import Auth from './Auth';
import Track from './Track';
import Common from "./Common";
import UsuariosRdc from '../modules/usuarios/Reducer';

//import Roles_Rdc from '../modules/roles/Reducers.js';
import Empresas_Rdc from '../modules/empresas/Reducers.js';
import ConfiguracionRdc from '../modules/configuracion/Reducer';
import DashboardRdc from '../modules/dashboard/Reducer';
import CotizadorRdc from '../modules/atrina/cotizador/Reducer';
import CotizadorClienteRdc from '../modules/cotizador/Reducer';
import EmpresaImportadorRdc from '../modules/empresaImportador/Reducer';
import DetalleServicioRdc from '../modules/detalleServicio/Reducer';
import NotificationsRdc from '../modules/notificaciones/Reducer';
import UserRdc from '../modules/usuario/Reducer';
import CustomerSurveyRdc from '../modules/customer_survey/Reducer.js';
import UploadDocsExpressRdc from '../modules/uploadDocsExpress/Reducer';
import SolicAgendamientoRdc from '../modules/solicitudAgendamiento/Reducer';
import PagoOnlineDetalleRdc from '../modules/detalle_pago_online/Reducer';

export default (history) => combineReducers({
    router: connectRouter(history),
    PagoOnlineDetalle_Maintainer:PagoOnlineDetalleRdc,
    Empresas_Maintainer:Empresas_Rdc,
    settings: Settings,
    auth: Auth,
    common: Common,
    usersMaintainer:UsuariosRdc,
    //Roles_Maintainer:Roles_Rdc,
    Configuracion:ConfiguracionRdc,
    Dashboard:DashboardRdc,
    Cotizador:CotizadorRdc,
    CotizadorCliente:CotizadorClienteRdc,
    EmpresaImportador:EmpresaImportadorRdc,
    DetalleServicio:DetalleServicioRdc,
    Notificaciones:NotificationsRdc,
    Track:Track,
    User:UserRdc,
    CustomerSurveySaga:CustomerSurveyRdc,
    UploadDocumentsExpress:UploadDocsExpressRdc,
    SolicAgendamiento:SolicAgendamientoRdc
});
