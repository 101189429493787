import{
    PAGONLINE_GET_DETALLE_PAGO_SUCCESS,
    
} from './Constants';

import lodash from 'lodash';
import {getDateOrderBy} from '../../util/functions';

const INIT_STATE = {
    DetallePago:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        
        case PAGONLINE_GET_DETALLE_PAGO_SUCCESS:{
            return{
                ...state,
                DetallePago:action.payload
            }
        }

        default:
            return state;
    }
}