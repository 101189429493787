import React, { Fragment, useState } from 'react'
import StepConnector from '@material-ui/core/StepConnector';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export default function StepperUp ({
    history, stepNow
}) {

  const useColorlibStepIconStyles = makeStyles({
    root: {
      backgroundColor: '#ccc',
      zIndex: 1,
      color: '#fff',
      width: 40,
      height: 40,
      display: 'flex',
      borderRadius: '50%',
      justifyContent: 'center',
      alignItems: 'center',
    },
    active: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
      boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
  });

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 22,
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
          'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
      },
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#eaeaf0',
      borderRadius: 1,
    },
  })(StepConnector);

  function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
          [classes.completed]: completed,
        })}
      >
        <CheckIcon />
      </div>
    );
  }
  
  ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
  };

    return (
        <Fragment>
        <Stepper alternativeLabel activeStep={stepNow} connector={<ColorlibConnector />} className='stepper-icon stepper-display' style={{
            maxWidth: '100%',
            overflowX: 'scroll',
            overflowY: 'hidden',
            scrollbarWidth: 'thin',
        }} >
            {history.map((item, index) => (
            <Step key={index}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>
                    {item.texto}
                    <br />
                    {item.fechaEstimada}
                </StepLabel>
                {
                  item.icon && history.length <= 11 ?
                  // <img src={item.icon} alt="icon" style={{width: '50px', height: '50px', position: 'absolute', zIndex: 9, left: '90%', top: 0}} />
                  <img src={item.icon} alt="icon" style={{width: history.length <= 8 ? '40px' : '20px', height: history.length <= 8 ? '40px' : '20px', 
                    position: 'absolute', zIndex: 9, left: history.length <= 8 ? '85%' : '85%', top: history.length <= 8 ? 0 : 10}} />
                  :
                  null
                }
            </Step>
            ))}
        </Stepper>
        </Fragment>
    )
}