
import Edit from '@material-ui/icons/Edit';
import{
    SHOW_MESSAGE_EMPRESA_IMPORTADOR,
    HIDE_MESSAGE_EMPRESA_IMPORTADOR,
    GLOBAL_CHANGE_STATES_EMPRESA_IMPORTADOR,
    CLEAN_STATES_EMPRESA_IMPORTADOR,
    GET_REI1,
    GET_REI1_SUCCESS,
    GET_REI2,
    GET_REI2_SUCCESS,
    GET_REI3,
    GET_REI3_SUCCESS,
    POST_REI4,
    PUT_REI5,
    PUT_REI5_SUCCESS,
    GET_REI6,
    GET_REI6_SUCCESS,
    GET_REI7,
    GET_REI7_SUCCESS,
    PUT_REI8,
    RUT_EXISTE,
    RUT_EXISTE_SUCCESS,
    EMAIL_EXISTE,
    EMAIL_EXISTE_SUCCESS,
    LIST_OCULTAR,
    GET_ROLES_AGREGAR_CONTACTO,
    GET_ROLES_AGREGAR_CONTACTO_SUCCESS,
    GET_MODULOS,
    GET_MODULOS_SUCCESS,
    POST_ROLES,
    POST_ROLES_SUCCESS,
    PUT_ROLES,
    PUT_ROLES_SUCCESS,
    DELETE_ROLES,
    DELETE_ROLES_SUCCESS,
    VALIDATE_NAME_ROLES,
    VALIDATE_NAME_ROLES_SUCCESS,
    CLEAR_ALL_EXPDIG_IMPORTADOR_SUCCESS
} from './Constants';

export const Clear_All_ExpDig_Importador_Success = () => {
    return {
        type: CLEAR_ALL_EXPDIG_IMPORTADOR_SUCCESS,
    };
};

export const showMessageEmpresaImportador = (payload) => {
    return {
        type: SHOW_MESSAGE_EMPRESA_IMPORTADOR,
        payload:payload
    };
};

export const hideMessageEmpresaImportador = (payload) => {
    return {
        type: HIDE_MESSAGE_EMPRESA_IMPORTADOR,
        payload:payload
    };
};

export const getREI1 = (payload) => {
    return {
        type: GET_REI1,
        payload:payload
    };
};

export const getREI1Success = (payload) => {
    return {
        type: GET_REI1_SUCCESS,
        payload:payload
    };
};

export const getREI2 = (payload) => {
    return {
        type: GET_REI2,
        payload:payload
    };
};

export const getREI2Success = (payload) => {
    return {
        type: GET_REI2_SUCCESS,
        payload:payload
    };
};

export const getREI3 = (payload,edit) => {
    return {
        type: GET_REI3,
        payload:payload,
        edit:edit
    };
};

export const getREI3Success = (payload,edit) => {
    return {
        type: GET_REI3_SUCCESS,
        payload:payload,
        edit:edit
    };
};

export const postREI4 = (payload) => {
    return {
        type: POST_REI4,
        payload:payload
    };
};

export const globalChangeStateEmpresaImportador = (atributte,payload) => {
    return {
        type: GLOBAL_CHANGE_STATES_EMPRESA_IMPORTADOR,
        atributte:atributte,
        payload:payload
    };
};

export const putREI5 = (payload,fk_usuario) => {
    return {
        type: PUT_REI5,
        payload:payload,
        fk_usuario:fk_usuario
    };
};

export const putREI5Success = (payload) => {
    return {
        type: PUT_REI5_SUCCESS,
        payload:payload
    };
};

export const getREI6 = (payload,edit) => {
    return {
        type: GET_REI6,
        payload:payload,
        edit:edit
    };
};

export const getREI6Success = (payload,edit) => {
    return {
        type: GET_REI6_SUCCESS,
        payload:payload,
        edit:edit
    };
};

export const getREI7 = (payload) => {
    return {
        type: GET_REI7,
        payload:payload
    };
};

export const getREI7Success = (payload) => {
    return {
        type: GET_REI7_SUCCESS,
        payload:payload
    };
};

export const putREI8 = (payload) => {
    return {
        type: PUT_REI8,
        payload:payload
    };
};

export const cleanStatesEmpresaImportador = (payload) => {
    return {
        type: CLEAN_STATES_EMPRESA_IMPORTADOR,
        payload:payload
    };
};

export const validateRutEmpresaImportador = (payload) => {
    return {
        type: RUT_EXISTE,
        payload:payload
    };
};

export const validateRutEmpresaImportadorSuccess = (payload) => {
    return {
        type: RUT_EXISTE_SUCCESS,
        payload:payload
    };
};


export const validateEmailEmpresaImportador = (payload) => {
    return {
        type: EMAIL_EXISTE,
        payload:payload
    };
};

export const validateEmailEmpresaImportadorSuccess = (payload) => {
    return {
        type: EMAIL_EXISTE_SUCCESS,
        payload:payload
    };
};

export const ocultarList  = (payload) => {
    return {
        type: LIST_OCULTAR,
        payload:payload
    };
};

export const getRolesAgregarContacto  = (payload) => {
    return {
        type: GET_ROLES_AGREGAR_CONTACTO,
        payload:payload
    };
};

export const getRolesAgregarContactoSuccess  = (payload) => {
    return {
        type: GET_ROLES_AGREGAR_CONTACTO_SUCCESS,
        payload:payload
    };
};

export const getModulos  = (payload) => {
    return {
        type: GET_MODULOS,
        payload:payload
    };
};

export const getModulosSuccess  = (payload) => {
    return {
        type: GET_MODULOS_SUCCESS,
        payload:payload
    };
};

export const postRole = (payload) => {
    return {
        type: POST_ROLES ,
        payload:payload
    };
};

export const postRolesSuccess  = (payload) => {
    return {
        type: POST_ROLES_SUCCESS,
        payload: payload
    };
};

export const putRoles = (payload) => {
    return {
        type: PUT_ROLES ,
        payload:payload
    };
};

export const putRolesSuccess  = (payload) => {
    return {
        type: PUT_ROLES_SUCCESS,
        payload: payload
    };
};

export const deleteRoles = (payload) => {
    return {
        type: DELETE_ROLES ,
        payload:payload
    };
};

export const deleteRolesSuccess  = (payload) => {
    return {
        type: DELETE_ROLES_SUCCESS,
        payload: payload
    };
};

export const validateNameRoles = (payload) => {
    return {
        type: VALIDATE_NAME_ROLES ,
        payload:payload
    };
};

export const validateNameRolesSuccess  = (payload) => {
    return {
        type: VALIDATE_NAME_ROLES_SUCCESS,
        payload: payload
    };
};



