import React, { useEffect, useState, Component } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
    makeStyles,
    Button

} from "@material-ui/core";

import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import ErrorIcon from '@material-ui/icons/Error';
import ModalEtiqueta from '../ModalEtiqueta';
import ModalEtiqueta2 from '../ModalEtiqueta2';
import CheckIcon from '@material-ui/icons/Check';



const useStyles = makeStyles((theme) => ({
    iframe: {
        width: "100%",
        height: "500px",
        '@media screen and (max-width: 767px)': {
            height: "315px"
        }
    },
}));


const CardThree = () => {
    const classes = useStyles();
    const {
        userExists
    } = useSelector(({ CotizadorCliente }) => CotizadorCliente);
    //const navigate = useNavigate();
    // Radio Button
    const [value, setValue] = useState('1');
    const [openEtiqueta, setOpenEtiqueta] = useState(false);
    const [openEtiqueta2, setOpenEtiqueta2] = useState(false);
    const handleChange = (event) => {
        setValue(event.target.value);
    };

    // input pass para mostrar contraseña
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const handleClickShowRepeatPassword = () => setShowRepeatPassword(!showRepeatPassword);
    const handleMouseDownRepeatPassword = () => setShowRepeatPassword(!showRepeatPassword);
    // input pass para mostrar contraseña

    // sidebar fixed
    const [show, setShow] = useState(false);
    const handleShow = () => {
        const seccion = document.getElementsByClassName('aside');
        if (typeof window !== seccion) {
            if (window.pageYOffset > 278) {
                if (!show) {
                    console.log("set to true");
                    setShow(true);
                }
            }
            if (window.pageYOffset < 278) {
                console.log("set to false");
                setShow(false);
            }
        }
    };

    React.useEffect(() => {
        const seccion = document.getElementsByClassName('aside');
        if (typeof window !== seccion) {
            window.addEventListener("scroll", handleShow);
        }
        return () => {
            if (typeof window !== seccion) {
                window.removeEventListener("scroll", handleShow);
            }
        };
    }, []);
    // sidebar fixed

    // efecto top
    useEffect(() => {
        const body = document.querySelector('#top');

        body.scrollIntoView({
            behavior: 'smooth'
        }, 500)

    }, []);

    const onToggleEtiqueta = () => {
        setOpenEtiqueta(false);
    }

    const onToggleEtiqueta2 = () => {
        setOpenEtiqueta2(false);
    }

    return (
        <div className="main-flex" id="top">
            {/* aside */}
            <aside className={show ? "aside aside-fixed" : "aside"}>
                <div className="cards mb-3">
                    <div className="cards-body">
                        <h4 className="title-h4 font-weight-600 mb-3">Recuerda que debes:</h4>

                        <div className="align-item-center">
                            <CheckIcon className='icon-color-success mr-2' />
                            <h4 className="text-small font-weight-500">Descargar tus etiquetas.</h4>
                        </div>

                        <div className="align-item-center my-3">
                            <CheckIcon className='icon-color-success mr-2' />
                            <h4 className="text-small font-weight-500">Envíar las etiquetas a tu proveedor</h4>
                        </div>
                    </div>
                </div>
            </aside>
            {/* fin aside */}

            {/* contenido right */}
            <section className="section">
                <h3 className="title-h3 font-weight-700 mb-2">!Ya puedes comenzar con tu importación!</h3>
                <p className="text font-weight-400 mb-4">Toda información sobre el uso de etiquetas, lo encontrarás en el siguiente video.</p>
                <iframe className={classes.iframe} src="https://www.youtube.com/embed/e51yyvQPxhI" title="WS Cargo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                <div className="cards my-3">
                    <div className="cards-body">
                        <div className="card-data">
                            {/*<h5 className="title-h5 font-weight-700 mb-2">Estimado(a) recuerda que:</h5>*/}
                            <p className="text-small font-weight-400 mb-2 mb-md-3">Tu proveedor debe identificar los bultos con nuestras etiquetas, sin ellas no podemos confirmar la recepción de tu producto en tiempo y forma.</p>

                            {!userExists &&
                                <div className='app-buttons-content'>
                                    <Button
                                        className="button button-outline button-small"
                                        onClick={() => {
                                            setOpenEtiqueta(true);
                                        }}
                                    >
                                        Descargar etiquetas yiwu
                                    </Button>

                                    <Button
                                        className="button button-outline button-small my-3 my-md-0 mx-0 mx-md-3"
                                        onClick={() => {
                                            setOpenEtiqueta2(true);
                                        }}
                                    >
                                        Descargar etiquetas guangzhou
                                    </Button>

                                    <Button
                                        className="button button-danger button-small "
                                        component={Link}
                                        to="/"
                                    >
                                        Ir al Sitio
                                    </Button>
                                </div>
                            }
                            {/* <div className='app-buttons-content'>
                                <Link 
                                    className="button button-outline mt-3 mt-md-0" 
                                    to="/"
                                >
                                    Ir al Sitio
                                </Link>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            <ModalEtiqueta open={openEtiqueta} onToggle={onToggleEtiqueta} />
            <ModalEtiqueta2 open={openEtiqueta2} onToggle={onToggleEtiqueta2} />

        </div>
    )
};

export default CardThree;