import {
    SHOW_MESSAGE_DASHBOARD,
    HIDE_MESSAGE_DASHBOARD,
    SET_LOADER_DASHBOARD,
    GET_COTIZACIONES_DASHBOARD,
    GET_COTIZACIONES_DASHBOARD_SUCCESS,
    GET_ZONASTARIFARIAS_DASHBOARD,
    GET_ZONASTARIFARIAS_DASHBOARD_SUCCESS,
    GET_PROPUESTABASE_DASHBOARD,
    GET_PROPUESTABASE_DASHBOARD_SUCCESS,
    POST_COTIZACION_DASHBOARD,
    POST_COTIZACION_DASHBOARD_SUCCESS,
    DELETE_COTIZACION_DASHBOARD,
    GET_EMPRESAS_DASHBOARD,
    GET_EMPRESAS_DASHBOARD_SUCCESS,
    GET_REGIONES_DASHBOARD,
    GET_REGIONES_DASHBOARD_SUCCESS,
    GET_COMUNAS_DASHBOARD_SUCCESS,
    GET_COMUNAS_DASHBOARD,
    GET_SERVICIOS_DASHBOARD,
    GET_SERVICIOS_DASHBOARD_SUCCESS,
    POST_SERVICIO_DASHBOARD,
    POST_SERVICIO_DASHBOARD_SUCCESS,
    SET_PAYLOAD_SERVICIO_DASHBOARD,
    POST_TRACKINGS_INCOMPLETE_DASHBOARD,
    POST_TRACKINGS_INCOMPLETE_DASHBOARD_SUCCESS,
    GET_COMERCIALES_DASHBOARD,
    GET_COMERCIALES_DASHBOARD_SUCCESS,
    GET_HISTORY_SERVICES_DASHBOARD,
    GET_HISTORY_SERVICES_DASHBOARD_SUCCESS,
    GET_STATES_COMPLETE_SERVICES_DASHBOARD,
    GET_STATES_COMPLETE_SERVICES_DASHBOARD_SUCCESS,
    SET_RESPONSE_POST_DASHBOARD,
    PUT_REFERENCIA_DASHBOARD,
    PUT_REFERENCIA_DASHBOARD_SUCCESS,
    GET_IS_FIRST_ACCESS_DASHBOARD,
    GET_IS_FIRST_ACCESS_DASHBOARD_SUCCESS,
    CLEAR_IS_FIRST_ACCESS_DASHBOARD,
    GET_CLIENTS_SUPERADMIN_DASHBOARD,
    GET_CLIENTS_SUPERADMIN_DASHBOARD_SUCCESS,
    GET_SERVICES_SUPERADMIN_DASHBOARD,
    GET_SERVICES_SUPERADMIN_DASHBOARD_SUCCESS,
    GET_FECHAS_ESTIMADAS,
    GET_FECHAS_ESTIMADAS_SUCCESS,
    GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE,
    GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE_SUCCESS,
    SET_CLIENTE_ACTIVO,
    CLEAR_CLIENTE_ACTIVO,
    GET_EMPRESA_DASHBOARD_BY_FKCLIENTE,
    GET_EMPRESA_DASHBOARD_BY_FKCLIENTE_SUCCESS,
    GET_COTIZACIONES_BY_FKCLIENTE_DASHBOARD,
    GET_COTIZACIONES__BY_FKCLIENTE_DASHBOARD_SUCCESS,
    CLEAR_ALL_EXPDIG_DASHBOAR_SUCCESS,
    GET_PROVEEDORES_CNSLD_RAPIDA_DASHBOARD,
    GET_PROVEEDORES_CNSLD_RAPIDA_DASHBOARD_SUCCESS,
    SET_MODAL_CNSLD_RAPIDO_DASHBOARD,
    GETENCUESTASPENDIENTES,
    GETENCUESTASPENDIENTES_SUCCESS,
    HOME_SETMOSTRARMODALPAGARSERVICIO,
    HOME_MODALPAGARSERVICIO_GETINFO,
    HOME_MODALPAGARSERVICIO_GETINFO_SUCCESS,
    HOME_REALIZARPAGOSERVICIO_SUCCESS,
    HOME_REALIZARPAGOSERVICIO,
    GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE_INTERVALS,
    HOME_SETMOSTRARLOADINGPAGOS,
    DASHBOARD_SEND_INVITATION_PROVIDER,
    GET_TIMELINE_DASHBOARD,
    GET_TIMELINE_DASHBOARD_SUCCESS
} from './Constants';


export const Home_SetMostrarLoadingPagos= (payload) => {
    return {
        type: HOME_SETMOSTRARLOADINGPAGOS,
        payload: payload,
    };
};

export const Home_RealizarPagoServicio_Success= (payload) => {
    return {
        type: HOME_REALIZARPAGOSERVICIO_SUCCESS,
        payload: payload,
    };
};

export const Home_RealizarPagoServicio= (payload) => {
    return {
        type: HOME_REALIZARPAGOSERVICIO,
        payload: payload,
    };
};

export const Home_ModalPagarServicio_GetInfo_Success= (payload) => {
    return {
        type: HOME_MODALPAGARSERVICIO_GETINFO_SUCCESS,
        payload: payload,
    };
};

export const Home_ModalPagarServicio_GetInfo= (payload) => {
    return {
        type: HOME_MODALPAGARSERVICIO_GETINFO,
        payload: payload,
    };
};

export const Home_SetMostrarModalPagarServicio= (payload) => {
    return {
        type: HOME_SETMOSTRARMODALPAGARSERVICIO,
        payload: payload,
    };
};

export const GetEncuestasPendientes= (payload) => {
    return {
        type: GETENCUESTASPENDIENTES,
        payload: payload,
    };
};

export const GetEncuestasPendientes_Success= (payload) => {
    return {
        type: GETENCUESTASPENDIENTES_SUCCESS,
        payload: payload,
    };
};

export const Clear_All_ExpDig_Dashboar_Success= () => {
    return {
        type: CLEAR_ALL_EXPDIG_DASHBOAR_SUCCESS,
    };
};

export const showMessageDashboard= (payload) => {
    return {
        type: SHOW_MESSAGE_DASHBOARD,
        payload: payload
    };
};

export const hideMessageDashboard= (payload) => {
    return {
        type: HIDE_MESSAGE_DASHBOARD,
        payload: payload
    };
};

export const setLoaderDashboard= (payload) => {
    return {
        type: SET_LOADER_DASHBOARD,
        payload: payload
    };
};

export const getCotizacionesDashboard= (payload) => {
    return {
        type: GET_COTIZACIONES_DASHBOARD,
        payload: payload
    };
};

export const getCotizacionesDashboardSuccess= (payload) => {
    return {
        type: GET_COTIZACIONES_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const getZonasTarifariasDashboard= (payload) => {
    return {
        type: GET_ZONASTARIFARIAS_DASHBOARD,
        payload: payload
    };
};

export const getZonasTarifariasDashboardSuccess= (payload) => {
    return {
        type: GET_ZONASTARIFARIAS_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const getPropuestaBaseDashboard= (payload) => {
    return {
        type: GET_PROPUESTABASE_DASHBOARD,
        payload: payload
    };
};

export const getPropuestaBaseDashboardSuccess= (payload) => {
    return {
        type: GET_PROPUESTABASE_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const postCotizacionDashboard= (payload) => {
    return {
        type: POST_COTIZACION_DASHBOARD,
        payload: payload
    };
};

export const postCotizacionDashboardSuccess= (payload) => {
    return {
        type: POST_COTIZACION_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const deleteCotizacionDashboard= (payload) => {
    return {
        type: DELETE_COTIZACION_DASHBOARD,
        payload: payload
    };
};

export const getEmpresasDashboard= (payload) => {
    return {
        type: GET_EMPRESAS_DASHBOARD,
        payload: payload
    };
};

export const getEmpresasDashboardSuccess= (payload) => {
    return {
        type: GET_EMPRESAS_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const getRegionesDashboard= (payload) => {
    return {
        type: GET_REGIONES_DASHBOARD,
        payload: payload
    };
};

export const getRegionesDashboardSuccess= (payload) => {
    return {
        type: GET_REGIONES_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const getComunasDashboard= (payload) => {
    return {
        type: GET_COMUNAS_DASHBOARD,
        payload: payload
    };
};

export const getComunasDashboardSuccess= (payload) => {
    return {
        type: GET_COMUNAS_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const getServiciosDashboard= (payload) => {
    return {
        type: GET_SERVICIOS_DASHBOARD,
        payload: payload
    };
};

export const getServiciosDashboardSuccess= (payload) => {
    return {
        type: GET_SERVICIOS_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const postServicioDashboard= (payload) => {
    return {
        type: POST_SERVICIO_DASHBOARD,
        payload: payload
    };
};

export const postServicioDashboardSuccess= (payload) => {
    return {
        type: POST_SERVICIO_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const setPayloadServicioDashboard= (payload) => {
    return {
        type: SET_PAYLOAD_SERVICIO_DASHBOARD,
        payload: payload
    };
};

export const postTrackingsIncompleteDashboard= (payload) => {
    return {
        type: POST_TRACKINGS_INCOMPLETE_DASHBOARD,
        payload: payload
    };
};

export const postTrackingsIncompleteDashboardSuccess= (payload) => {
    return {
        type: POST_TRACKINGS_INCOMPLETE_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const getComercialesDashboard= (payload) => {
    return {
        type: GET_COMERCIALES_DASHBOARD,
        payload: payload
    };
};

export const getComercialesDashboardSuccess= (payload) => {
    return {
        type: GET_COMERCIALES_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const getHistoryServicesDashboard= (payload) => {
    return {
        type: GET_HISTORY_SERVICES_DASHBOARD,
        payload: payload
    };
};

export const getHistoryServicesDashboardSuccess= (payload) => {
    return {
        type: GET_HISTORY_SERVICES_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const getStatesCompleteServicesDashboard= (payload) => {
    return {
        type: GET_STATES_COMPLETE_SERVICES_DASHBOARD,
        payload: payload
    };
};

export const getStatesCompleteServicesDashboardSuccess= (payload) => {
    return {
        type: GET_STATES_COMPLETE_SERVICES_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const setResponsePostDashboard= (payload) => {
    return {
        type: SET_RESPONSE_POST_DASHBOARD,
        payload: payload
    };
};

export const putReferenciaDashboard= (payload) => {
    return {
        type: PUT_REFERENCIA_DASHBOARD,
        payload: payload
    };
};

export const putReferenciaDashboardSuccess= (payload) => {
    return {
        type: PUT_REFERENCIA_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const getIsFirstAccessDashboard= (payload) => {
    return {
        type: GET_IS_FIRST_ACCESS_DASHBOARD,
        payload: payload
    };
};

export const getIsFirstAccessDashboardSuccess= (payload) => {
    return {
        type: GET_IS_FIRST_ACCESS_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const clearIsFirstAccessDashboard= (payload) => {
    return {
        type: CLEAR_IS_FIRST_ACCESS_DASHBOARD,
        payload: payload
    };
};

export const getClientsSuperadminDashboard= (payload) => {
    return {
        type: GET_CLIENTS_SUPERADMIN_DASHBOARD,
        payload: payload
    };
};

export const getClientsSuperadminDashboardSuccess= (payload) => {
    return {
        type: GET_CLIENTS_SUPERADMIN_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const getServicesSuperadminDashboard= (payload) => {
    return {
        type: GET_SERVICES_SUPERADMIN_DASHBOARD,
        payload: payload
    };
};

export const getServicesSuperadminDashboardSuccess= (payload) => {
    return {
        type: GET_SERVICES_SUPERADMIN_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const getFechasEstimadas= (payload) => {
    return {
        type: GET_FECHAS_ESTIMADAS,
        payload: payload
    };
};

export const getFechasEstimadasSuccess= (payload) => {
    return {
        type: GET_FECHAS_ESTIMADAS_SUCCESS,
        payload: payload
    };
};

export const getServiciosDashboardByFkCliente= (payload) => {
    return {
        type: GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE,
        payload: payload
    };
};

export const getServiciosDashboardByFkClienteSuccess= (payload) => {
    return {
        type: GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE_SUCCESS,
        payload: payload
    };
};

export const setClienteActivo = (payload) => {
    return {
        type: SET_CLIENTE_ACTIVO,
        payload: payload
    };
};

export const clearClienteActivo = (payload) => {
    return {
        type: CLEAR_CLIENTE_ACTIVO,
        payload: payload
    };
};

export const getEmpresaDashboardByFkCliente= (payload) => {
    return {
        type: GET_EMPRESA_DASHBOARD_BY_FKCLIENTE,
        payload: payload
    };
};

export const getEmpresaDashboardByFkClienteSuccess= (payload) => {
    return {
        type: GET_EMPRESA_DASHBOARD_BY_FKCLIENTE_SUCCESS,
        payload: payload
    };
};

export const getCotizacionesDashboardByFkCliente= (payload) => {
    return {
        type: GET_COTIZACIONES_BY_FKCLIENTE_DASHBOARD,
        payload: payload
    };
};

export const getCotizacionesDashboardByFkClienteSuccess= (payload) => {
    return {
        type: GET_COTIZACIONES__BY_FKCLIENTE_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const getProveedoresCnsldRapidaDashboard= (payload) => {
    return {
        type: GET_PROVEEDORES_CNSLD_RAPIDA_DASHBOARD,
        payload: payload
    };
};

export const getProveedoresCnsldRapidaDashboardSuccess= (payload) => {
    return {
        type: GET_PROVEEDORES_CNSLD_RAPIDA_DASHBOARD_SUCCESS,
        payload: payload
    };
};

export const setModalCnsldRapidoDashboard= (payload) => {
    return {
        type: SET_MODAL_CNSLD_RAPIDO_DASHBOARD,
        payload: payload
    };
};

export const getServiciosDashboardByFkClienteIntervals= (payload) => {
    return {
        type: GET_SERVICIOS_DASHBOARD_BY_FKCLIENTE_INTERVALS,
        payload: payload
    };
};

export const cli_send_invitacion_provider= (payload) => {
    return {
        type: DASHBOARD_SEND_INVITATION_PROVIDER,
        payload: payload
    };
};

export const getTimelimeDashboard= (payload) => {
    return {
        type: GET_TIMELINE_DASHBOARD,
        payload: payload
    };
};

export const getTimelimeDashboardSuccess= (payload) => {
    return {
        type: GET_TIMELINE_DASHBOARD_SUCCESS,
        payload: payload
    };
};









































