import {all, call, fork, put, takeEvery,takeLatest,spawn} from "redux-saga/effects";
import { putRequest, postRequest, getRequest, getErrorRequest, deleteRequest,uploadFilesRequest } from '../../util/apiFetch';
import {
    GET_SERVICE_SOLIC_AGENDAMIENTO,
    GET_REGIONES_SOLIC_AGENDAMIENTO,
    GET_COMUNAS_SOLIC_AGENDAMIENTO,
    GET_DIRECCIONES_SOLIC_AGENDAMIENTO,
    GET_CONFIG_DESPACHO_SOLIC_AGENDAMIENTO,
    GET_CONFIG_RETIRO_SOLIC_AGENDAMIENTO,
    POST_DESPACHO_RETIRO_SOLIC_AGENDAMIENTO,
    GET_DISPONIBILIDAD_DESPACHO_SOLIC_AGENDAMIENTO,
    GET_BLOQUEOS_RETIRO_SOLIC_AGENDAMIENTO,
    GET_DISPONIBILIDAD_RETIRO_SOLIC_AGENDAMIENTO
} from './Constants';
import {
    setMessageSolicAgendamiento,
    getServiceSolicAgendamientoSuccess,
    getRegionesSolicAgendamientoSuccess,
    getComunasSolicAgendamientoSuccess,
    getDireccionesSolicAgendamientoSuccess,
    getConfigDespachoSolicAgendamientoSuccess,
    getConfigRetiroSolicAgendamientoSuccess,
    getDisponibilidadDespachoSolicAgendamientoSuccess,
    getBloqueosRetiroSolicAgendamientoSuccess,
    getDisponibilidadRetiroSolicAgendamientoSuccess
} from './Actions';



function* getSagaServiceSolicAgendamientoExecute({payload}){
    const requestURL = `public/servicio/${payload.hash}`;
    const response= yield call(getRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(setMessageSolicAgendamiento({show:true,message:{type:'error',text:message}}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getServiceSolicAgendamientoSuccess(obj));
    }
}

function* getSagaRegionesSolicAgendamientoExecute({payload}){
    const requestURL = `region_get_list`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(setMessageSolicAgendamiento({show:true,message:{type:'error',text:message}}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getRegionesSolicAgendamientoSuccess(obj));
    }
}

function* getSagaComunasSolicAgendamientoExecute({payload}){
    const requestURL = `comuna_get_list`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(setMessageSolicAgendamiento({show:true,message:{type:'error',text:message}}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getComunasSolicAgendamientoSuccess(obj));
    }
}

function* getSagaDireccionesSolicAgendamientoExecute({payload}){
    const requestURL = `public/direcciones/cliente/${payload.fk_cliente}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(setMessageSolicAgendamiento({show:true,message:{type:'error',text:message}}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getDireccionesSolicAgendamientoSuccess(obj));
    }
}

function* getSagaConfigDespachoSolicAgendamientoExecute({payload}){
    const requestURL = `public/config-despacho`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(setMessageSolicAgendamiento({show:true,message:{type:'error',text:message}}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getConfigDespachoSolicAgendamientoSuccess(obj));
    }
}

function* getSagaConfigRetiroSolicAgendamientoExecute({payload}){
    const requestURL = `public/config-retiro`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(setMessageSolicAgendamiento({show:true,message:{type:'error',text:message}}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getConfigRetiroSolicAgendamientoSuccess(obj));
    }
}

function* postSagaDespachoRetiroSolicAgendamientoExecute({payload}){
    const requestURL = `public/tracking-despacho-retiro`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(setMessageSolicAgendamiento({show:true,message:{type:'error',text:message}}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(setMessageSolicAgendamiento({show:true,message:{type:'success',text:'AGENDAMIENTO GUARDADO CORRECTAMENTE'}}));
       // yield put(getConfigRetiroSolicAgendamientoSuccess(obj));
    }
}

function* getSagaDisponibilidadDespachoSolicAgendamientoExecute(action){
    const requestURL = `public/despacho/disponibilidad/${action.payload.fecha}`;
    const response= yield call(getRequest,requestURL);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(setMessageSolicAgendamiento({show:true,message:{type:'error',text:message}}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getDisponibilidadDespachoSolicAgendamientoSuccess(obj));
    }
}

function* getSagaBloqueosRetiroSolicAgendamientoExecute({payload}){
    const requestURL = `public/retiro/month`;
    const response= yield call(postRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(setMessageSolicAgendamiento({show:true,message:{type:'error',text:message}}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getBloqueosRetiroSolicAgendamientoSuccess(obj));
    }
 }

 function* getSagaDisponibilidadRetiroSolicAgendamientoExecute({payload}){
    const requestURL = `public/retiro/disponibilidad/${payload.fecha}`;
    const response= yield call(getRequest,requestURL,payload);
    const { error, message } =yield call(getErrorRequest,response);
    if(error){
        yield put(setMessageSolicAgendamiento({show:true,message:{type:'error',text:message}}));
    }else{
        const respTxt=yield response.text();
        const obj=JSON.parse(respTxt);
        yield put(getDisponibilidadRetiroSolicAgendamientoSuccess(obj));
    }
 }


export function* getSagaServiceSolicAgendamiento(){
    yield takeLatest(GET_SERVICE_SOLIC_AGENDAMIENTO, getSagaServiceSolicAgendamientoExecute);
}

export function* getSagaRegionesSolicAgendamiento(){
    yield takeLatest(GET_REGIONES_SOLIC_AGENDAMIENTO, getSagaRegionesSolicAgendamientoExecute);
}

export function* getSagaComunasSolicAgendamiento(){
    yield takeLatest(GET_COMUNAS_SOLIC_AGENDAMIENTO, getSagaComunasSolicAgendamientoExecute);
}

export function* getSagaDireccionesSolicAgendamiento(){
    yield takeLatest(GET_DIRECCIONES_SOLIC_AGENDAMIENTO, getSagaDireccionesSolicAgendamientoExecute);
}

export function* getSagaConfigDespachoSolicAgendamiento(){
    yield takeLatest(GET_CONFIG_DESPACHO_SOLIC_AGENDAMIENTO, getSagaConfigDespachoSolicAgendamientoExecute);
}

export function* getSagaConfigRetiroSolicAgendamiento(){
    yield takeLatest(GET_CONFIG_RETIRO_SOLIC_AGENDAMIENTO, getSagaConfigRetiroSolicAgendamientoExecute);
}

export function* postSagaDespachoRetiroSolicAgendamiento(){
    yield takeLatest(POST_DESPACHO_RETIRO_SOLIC_AGENDAMIENTO, postSagaDespachoRetiroSolicAgendamientoExecute);
}

export function* getSagaDisponibilidadDespachoSolicAgendamiento(){
    yield takeLatest(GET_DISPONIBILIDAD_DESPACHO_SOLIC_AGENDAMIENTO, getSagaDisponibilidadDespachoSolicAgendamientoExecute);
}

export function* getSagaBloqueosRetiroSolicAgendamiento(){
    yield takeLatest(GET_BLOQUEOS_RETIRO_SOLIC_AGENDAMIENTO, getSagaBloqueosRetiroSolicAgendamientoExecute);
}

export function* getSagaDisponibilidadRetiroSolicAgendamiento(){
    yield takeLatest(GET_DISPONIBILIDAD_RETIRO_SOLIC_AGENDAMIENTO, getSagaDisponibilidadRetiroSolicAgendamientoExecute);
}




export default function* rootSaga() {
    yield spawn(getSagaServiceSolicAgendamiento);
    yield spawn(getSagaRegionesSolicAgendamiento);
    yield spawn(getSagaComunasSolicAgendamiento);
    yield spawn(getSagaDireccionesSolicAgendamiento);
    yield spawn(getSagaConfigDespachoSolicAgendamiento);
    yield spawn(getSagaConfigRetiroSolicAgendamiento);
    yield spawn(postSagaDespachoRetiroSolicAgendamiento);
    yield spawn(getSagaDisponibilidadDespachoSolicAgendamiento);
    yield spawn(getSagaBloqueosRetiroSolicAgendamiento);
    yield spawn(getSagaDisponibilidadRetiroSolicAgendamiento);
}