export const SHOW_MESSAGE_DETALLE_SERVICIO='SHOW_MESSAGE_DETALLE_SERVICIO';
export const HIDE_MESSAGE_DETALLE_SERVICIO='HIDE_MESSAGE_DETALLE_SERVICIO';
export const GET_INFO_SERVICIO_DETALLE_SERVICIO='GET_INFO_SERVICIO_DETALLE_SERVICIO';
export const GET_INFO_SERVICIO_DETALLE_SERVICIO_SUCCESS='GET_INFO_SERVICIO_DETALLE_SERVICIO_SUCCESS';
export const GET_DOCUMENTS_CLIENT_DETALLE_SERVICIO='GET_DOCUMENTS_CLIENT_DETALLE_SERVICIO';
export const GET_DOCUMENTS_CLIENT_DETALLE_SERVICIO_SUCCESS='GET_DOCUMENTS_CLIENT_DETALLE_SERVICIO_SUCCESS';
export const POST_PAGO_DETALLE_SERVICIO='POST_PAGO_DETALLE_SERVICIO';
export const POST_PAGO_DETALLE_SERVICIO_SUCCESS='POST_PAGO_DETALLE_SERVICIO_SUCCESS';
export const GET_PAGOS_DETALLE_SERVICIO='GET_PAGOS_DETALLE_SERVICIO';
export const GET_PAGOS_DETALLE_SERVICIO_SUCCESS='GET_PAGOS_DETALLE_SERVICIO_SUCCESS';
export const GET_HISTORY_DETALLE_SERVICIO='GET_HISTORY_DETALLE_SERVICIO';
export const GET_HISTORY_DETALLE_SERVICIO_SUCCESS='GET_HISTORY_DETALLE_SERVICIO_SUCCESS';
export const GET_DATA_COSTOS_DETALLE_SERVICIO='GET_DATA_COSTOS_DETALLE_SERVICIO';
export const GET_DATA_COSTOS_DETALLE_SERVICIO_SUCCESS='GET_DATA_COSTOS_DETALLE_SERVICIO_SUCCESS';
export const GET_PROPUESTA_COMERCIAL_DETALLE_SERVICIO='GET_PROPUESTA_COMERCIAL_DETALLE_SERVICIO';
export const GET_PROPUESTA_COMERCIAL_DETALLE_SERVICIO_SUCCESS='GET_PROPUESTA_COMERCIAL_DETALLE_SERVICIO_SUCCESS';
export const GET_DIRECCIONES_CLIENTE_DETALLE_SERVICIO='GET_DIRECCIONES_CLIENTE_DETALLE_SERVICIO';
export const GET_DIRECCIONES_CLIENTE_DETALLE_SERVICIO_SUCCESS='GET_DIRECCIONES_CLIENTE_DETALLE_SERVICIO_SUCCESS';
export const POST_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO='POST_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO';
export const POST_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO_SUCCESS='POST_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO_SUCCESS';
export const GET_REGIONES_DETALLE_SERVICIO='GET_REGIONES_DETALLE_SERVICIO';
export const GET_REGIONES_DETALLE_SERVICIO_SUCCESS='GET_REGIONES_DETALLE_SERVICIO_SUCCESS';
export const GET_COMUNAS_DETALLE_SERVICIO='GET_COMUNAS_DETALLE_SERVICIO';
export const GET_COMUNAS_DETALLE_SERVICIO_SUCCESS='GET_COMUNAS_DETALLE_SERVICIO_SUCCESS';
export const GET_RETIRO_DESPACHO_DETALLE_SERVICIO='GET_RETIRO_DESPACHO_DETALLE_SERVICIO';
export const GET_RETIRO_DESPACHO_DETALLE_SERVICIO_SUCCESS='GET_RETIRO_DESPACHO_DETALLE_SERVICIO_SUCCESS';
export const PUT_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO='PUT_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO';
export const PUT_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO_SUCCESS='PUT_SERVICIO_DESPACHO_RETIRO_DETALLE_SERVICIO_SUCCESS';
export const GET_PAGOS_QIAO_DETALLE_SERVICIO='GET_PAGOS_QIAO_DETALLE_SERVICIO';
export const GET_PAGOS_QIAO_DETALLE_SERVICIO_SUCCESS='GET_PAGOS_QIAO_DETALLE_SERVICIO_SUCCESS';
export const CLEAN_STATES_DETALLE_SERVICIO='CLEAN_STATES_DETALLE_SERVICIO';
export const GET_COMERCIALES_DETALLE_SERVICIO='GET_COMERCIALES_DETALLE_SERVICIO';
export const GET_COMERCIALES_DETALLE_SERVICIO_SUCCESS='GET_COMERCIALES_DETALLE_SERVICIO_SUCCESS';
export const CLOSE_PAGO_SUCCESS_DETALLE_SERVICIO='CLOSE_PAGO_SUCCESS_DETALLE_SERVICIO';
export const CLOSE_DESPACHO_RETIRO_SUCCESS_DETALLE_SERVICIO='CLOSE_DESPACHO_RETIRO_SUCCESS_DETALLE_SERVICIO';
export const PUT_UPLOAD_DOCUMENTS_DETALLE_SERVICIO='PUT_UPLOAD_DOCUMENTS_DETALLE_SERVICIO';
export const CLEAR_DATA_DETALLE_SERVICIO='CLEAR_DATA_DETALLE_SERVICIO';

export const CLEAR_ALL_EXPDIG_DETALLESERVICIO_SUCCESS='CLEAR_ALL_EXPDIG_DETALLESERVICIO_SUCCESS';
export const GET_CONFIG_DESPACHO_DETALLE_SERVICIO='GET_CONFIG_DESPACHO_DETALLE_SERVICIO';
export const GET_CONFIG_DESPACHO_DETALLE_SERVICIO_SUCCESS='GET_CONFIG_DESPACHO_DETALLE_SERVICIO_SUCCESS';
export const GET_CONFIG_RETIRO_DETALLE_SERVICIO='GET_CONFIG_DESPACHO_DETALLE_SERVICIO';
export const GET_CONFIG_RETIRO_DETALLE_SERVICIO_SUCCESS='GET_CONFIG_RETIRO_DETALLE_SERVICIO_SUCCESS';
export const GET_BLOQUEOS_RETIRO_BY_DAY_DETALLE_SERVICIO='GET_BLOQUEOS_RETIRO_BY_DAY_DETALLE_SERVICIO';
export const GET_BLOQUEOS_RETIRO_BY_DAY_DETALLE_SERVICIO_SUCCESS='GET_BLOQUEOS_RETIRO_BY_DAY_DETALLE_SERVICIO_SUCCESS';
export const GET_DISPONIBILIDAD_DESPACHO_CALENDARIO_DETALLE_SERVICIO='GET_DISPONIBILIDAD_DESPACHO_CALENDARIO_DETALLE_SERVICIO';
export const GET_DISPONIBILIDAD_DESPACHO_CALENDARIO_DETALLE_SERVICIO_SUCCESS='GET_DISPONIBILIDAD_DESPACHO_CALENDARIO_DETALLE_SERVICIO_SUCCESS';
export const POST_AGENDA_RETIRO_BY_MONTH_DETALLE_SERVICIO='POST_AGENDA_RETIRO_BY_MONTH_DETALLE_SERVICIO';
export const POST_AGENDA_RETIRO_BY_MONTH_DETALLE_SERVICIO_SUCCESS='POST_AGENDA_RETIRO_BY_MONTH_DETALLE_SERVICIO_SUCCESS';

export const FINDETAIL_SETMOSTRARMODALPAGARSERVICIO='FINDETAIL_SETMOSTRARMODALPAGARSERVICIO';
export const FINDETAIL_MODALPAGARSERVICIO_GETINFO='FINDETAIL_MODALPAGARSERVICIO_GETINFO';
export const FINDETAIL_MODALPAGARSERVICIO_GETINFO_SUCCESS='FINDETAIL_MODALPAGARSERVICIO_GETINFO_SUCCESS';
export const FINDETAIL_REALIZARPAGOSERVICIO_SUCCESS='FINDETAIL_REALIZARPAGOSERVICIO_SUCCESS';
export const FINDETAIL_REALIZARPAGOSERVICIO='FINDETAIL_REALIZARPAGOSERVICIO';
export const FINDETAIL_SETMOSTRARLOADINGPAGOS = 'FINDETAIL_SETMOSTRARLOADINGPAGOS';
export const GET_TIMELINE_DETALLE_SERVICIO='GET_TIMELINE_DETALLE_SERVICIO';
export const GET_TIMELINE_DETALLE_SERVICIO_SUCCESS='GET_TIMELINE_DETALLE_SERVICIO_SUCCESS';
