import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    IconButton,
    makeStyles,
    Button,
    Avatar,
    Hidden
} from "@material-ui/core";
import FormDespacho from './FormDespacho';
import FormRetiro from './FormRetiro';

import Nav from "containers/AppLayout/Vertical/Nav/index";
import Header from "containers/header/Header";
import moment from 'moment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
    setMessageSolicAgendamiento,
    getServiceSolicAgendamiento,
    getRegionesSolicAgendamiento,
    getComunasSolicAgendamiento,
    getDireccionesSolicAgendamiento,
    getConfigDespachoSolicAgendamiento,
    getConfigRetiroSolicAgendamiento,
    getDisponibilidadDespachoSolicAgendamiento,
    getBloqueosRetiroSolicAgendamiento,
    getDisponibilidadRetiroSolicAgendamiento
} from './Actions';

const useStyles = makeStyles((theme) => ({
    listItem: {
        display: "flex",
        flexDirection: "column"
        //alignItems: "center"
    },

    border: {
        borderLeft: "solid 6px  #EA5259"
    },
    border2: {
        borderLeft: "solid 6px #F8C96A"
    },
    borderSuccess: {
        borderLeft: "solid 6px #6FCF97"
    },
    borderWarning: {
        borderLeft: "solid 6px #F8C96A"
    },
    borderPrimary: {
        borderLeft: "solid 6px #43ADF3"
    },
    icon: {
        fontSize: "32px",
        color: "8F8F8F",
        marginRight: "16px",
        '@media screen and (max-width: 576px) ': {
            fontSize: "28px",
        }
    },
}));

const SolicitudAgendamiento = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const {
        loader,
        servicio,
        showMessage,
        alertMessage,
        direcciones,
        regiones,
        comunas,
        comerciales,
        config_despacho,
        config_retiro,
        bloqueos_retiro_horario,
        bloqueos_retiro_calendario,
        bloques_ocupados,
        bloqueos_despacho_calendario
    } = useSelector(({ SolicAgendamiento }) => SolicAgendamiento);

    const [selectedDate,setSelectedDate] = React.useState(moment().format('YYYYMMDD 000000'));
    const [loading, setLoading] = useState(false);
    const [habilitado, setHabilitado] = useState(false);
    const [response, setResponse] = useState(null);
    const [selectedRetiroDespacho, setSelectedRetiroDespacho] = useState(null);
    const [visible, setVisible] = useState({
        retiro_bodega: true,
        despacho_domicilio: true,
        resumen: true,
        menu_entrega: false,
    });

    const [blocked, setBlocked]=useState([]);   
      useEffect(() => {
        if(config_despacho!=null){
          let days=[];
          if(config_despacho.sabado!=true){
              days.push(6);
          }

          if(config_despacho.domingo!=true){
              days.push(0);
          }
          setBlocked(days);
        }
      }, [config_despacho]);


    useEffect(() => {
        dispatch(getServiceSolicAgendamiento({ hash: props.match.params.hash }));
        dispatch(getRegionesSolicAgendamiento());
        dispatch(getComunasSolicAgendamiento());
        dispatch(getConfigDespachoSolicAgendamiento());
        dispatch(getConfigRetiroSolicAgendamiento());
      }, []);
    
    useEffect(() => {
       if(servicio!=null){
        dispatch(getDireccionesSolicAgendamiento({fk_cliente:servicio.fk_cliente}));
        if(servicio.estado_pago!='SI'){
            setHabilitado(false);
        }else{
            setHabilitado(true);
        }
       }
    }, [servicio]);

    useEffect(() => {
        if (showMessage) {
            toast[`${alertMessage.type}`](alertMessage.text);
            setTimeout(() => {
                dispatch(setMessageSolicAgendamiento({show:false, alertMessage:{type:'',text:''}}));
            }, 100);
        }
    }, [showMessage]);

    const getDatesQuery=(option)=>{
        let fecha=moment(selectedDate).startOf('month').format('YYYYMMDD');
        let fecha1=moment(selectedDate).format('YYYYMMDD 00:00:00');
        let fecha2=moment(selectedDate).format('YYYYMMDD 23:59:59');
        if(option!=1 && option!=0){
           dispatch(getDisponibilidadDespachoSolicAgendamiento({fecha:fecha}));
        }else{
            dispatch(getDisponibilidadRetiroSolicAgendamiento({fecha:fecha}));
            dispatch(getBloqueosRetiroSolicAgendamiento({fecha1:fecha1,fecha2:fecha2}));
        }
    }

    const handleChangeView = (newValue) => {
        switch (newValue) {
            case 0:
                setVisible({
                    retiro_bodega: true,
                    despacho_domicilio: true,
                    resumen: true,
                    menu_entrega: false
                });
                break;
            case 1:
                getDatesQuery(1);
                setVisible({
                    retiro_bodega: false,
                    despacho_domicilio: true,
                    resumen: true,
                    menu_entrega: true
                });
                break;
            case 2:
                getDatesQuery(2);
                setVisible({
                    retiro_bodega: true,
                    despacho_domicilio: false,
                    resumen: true,
                    menu_entrega: true
                });
                break;
            case 3:
                setVisible({
                    retiro_bodega: true,
                    despacho_domicilio: true,
                    resumen: false,
                    menu_entrega: true
                });
               
                break;
            case 4:
                setVisible({
                    retiro_bodega: true,
                    despacho_domicilio: true,
                    resumen: true,
                    menu_entrega: false
                });
                
                break;
        }
    };
    const handleBack = () => {
        handleChangeView(0)
    }
  return (
    <>
      <div className="app-container new-template">
        {/* <Tour />
            <SideBar2 /> */}
        <div
          className="app-main-container"
          style={{ backgroundColor: "red !important" }}
        >
          <main className="app-main-content-wrapper">
            <Nav />
            <div className="app-main-content template">
              <div className="app-wrapper">
                <Header />
                    <div className={classes.root}>
                    {!habilitado &&
                        <div className='text-center app-menssage'>
                            <img src={require("assets/images/recepcion/icon-rep-incompleta.png")} alt="recepciones incompletas" title="recepciones incompletas" />
                            {/* <h4 className="text font-weight-500 mt-2">Lo sentimos, tu carga aún no está disponible para despacho o retiro.</h4> */}
                            <h4 className="text font-weight-500 mt-2">Lo sentimos, el despacho o retiro de tu carga no puede ser programado</h4>
                            <h4 className="text font-weight-500 mt-2">debido a que no está disponible o tienes un pago pendiente.</h4>
                        </div>
                    }
                    {servicio && servicio.causa_especial!=null &&
                    <div className="row justify-content-md-center">
                    <div className='col-12 col-md-12 col-lg-10 col-xl-8  mb-5'>
                        <div className={`cards ${classes.border}`} >
                            <div className="cards-body">
                                <p><b>Información importante</b></p>
    
                            <p>Tu servicio se encuentra con las siguientes observaciones: </p>
                                {servicio.causa_especial==1 && <p><b>Requiere de un equipo especial para la carga o descarga</b></p>}
                                {servicio.causa_especial==2 && <p><b>Carga faltante o dañados del servicio</b></p>}
                                {servicio.causa_especial==3 && <p><b>Otro</b></p>}
    
                                <p>Mientras tanto, {/*podrás realizar el preagendamiento de tu servicio mientras*/}un ejecutivo se contactará contigo dentro de las próximas 24 horas hábiles.</p>
                            </div>
                        </div>
                    </div>
                </div>
                        
                    }
                    {habilitado && servicio && servicio.causa_especial==null &&
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ delay: 0.5, duration: 1.3 }}
                    >
                        <div className='row justify-content-md-center'>
                        <div hidden={visible.menu_entrega} className="col-12 col-md-12 col-lg-10 col-xl-8">
                            <div className="cards mb-2">
                                <div className='cards-header'>
                                    <div className='align-item-center'>
                                        <h4 className="text font-weight-500">Selecciona un tipo de entrega</h4>
                                    </div>
                                    <div className="border-title"></div>
                                </div>
                                <div className="cards-body pt-0">
                                    <div className={`justify-space-between py-3 ${classes.cards}`} onClick={(e) => handleChangeView(1)}>
                                        <div className="align-item-center">
                                            <DateRangeIcon className={`${classes.icon}`} />
                                            <div>
                                                <h4 className="text-small font-weight-400">Retiro Bodega</h4>
                                                <p className='text-small font-weight-300'>Retira tus bultos sin costo adicionalen nuestras instalaciones.</p>
                                            </div>
                                        </div>
                                        <ArrowForwardIcon />
                                    </div>
                                    <div className="border"></div>
                                    <div className={`justify-space-between py-3 ${classes.cards}`} onClick={(e) => handleChangeView(2)}>
                                        <div className="align-item-center">
                                            <LocationOnIcon className={` ${classes.icon}`} />
                                            <div>
                                                <h4 className="text-small font-weight-400">Despacho a domicilio</h4>
                                                <p className='text-small font-weight-300'>ingresa tu dirección y recibe tus productos</p>
                                            </div>
                                        </div>
                                        <ArrowForwardIcon />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-md-center">
                {/* form retiro bodega */}
                <div hidden={visible.retiro_bodega} className="col-12 col-md-12 col-lg-10 col-xl-8  mb-2">
                    <div className="cards card-list">
                        <div className='cards-header'>
                            <div className="justify-space-between">
                                <div className="align-item-center">
                                    <DateRangeIcon className={`${classes.icon}`} />
                                    <h4 className="title-h5 font-weight-600">Retiro Bodega</h4>
                                </div>

                                <Hidden only='xs'>
                                    <Button
                                        onClick={(e) => handleChangeView(2)}
                                        className="button button-outline button-small">
                                        Cambiar a despacho
                                    </Button>
                                </Hidden>
                            </div>

                            {/* <div className="border-title"></div> */}
                        </div>

                        <FormRetiro
                            visible={visible}
                            setLoading={setLoading}
                            setResponse={setResponse}
                            retiroDespacho={selectedRetiroDespacho}
                            handleChangeView={handleChangeView()}
                            config_retiro={config_retiro}
                            bloqueos_horario={bloqueos_retiro_horario}
                            bloques_ocupados={bloques_ocupados}
                            bloqueos={bloqueos_retiro_calendario}
                            selectedItem={servicio}
                        />


                        <Hidden smUp>
                            <div className='cards-body px-4 px-md-5 pt-0'>
                                <Button
                                    onClick={(e) => handleChangeView(2)}
                                    className="button button-outline button-small button-100-mobile">
                                    Cambiar a despacho
                                </Button>
                            </div>
                        </Hidden>
                    </div>
                </div>

                {/* form despacho */}
                <div hidden={visible.despacho_domicilio} className="col-12 col-md-12 col-lg-10 col-xl-8  mb-2">
                    <div className="cards card-list">
                        <div className='cards-header'>
                            <div className="justify-space-between">
                                <div className="align-item-center">
                                    <LocationOnIcon className={` ${classes.icon}`} />
                                    <h4 className="title-h5 font-weight-600">Despacho</h4>
                                </div>

                                <Hidden only='xs'>
                                    <Button
                                        onClick={(e) => handleChangeView(1)}
                                        className="button button-outline button-small">
                                        Cambiar a Retiro
                                    </Button>
                                </Hidden>
                            </div>
                            {/* <div className="border my-4"></div> */}
                        </div>

                        <FormDespacho
                            retiroDespacho={selectedRetiroDespacho}
                            visible={visible}
                            setLoading={setLoading}
                            setResponse={setResponse}
                            direcciones={direcciones}
                            regiones={regiones}
                            comunas={comunas}
                            comerciales={comerciales}
                            config_despacho={config_despacho}
                            selectedItem={servicio}
                            bloqueos={bloqueos_despacho_calendario}
                            blocked={blocked}
                            config_retiro={config_retiro}
                        />
                        <Hidden smUp>
                            <div className='cards-body px-4 px-md-5 pt-0'>
                                <Button
                                    onClick={(e) => handleChangeView(1)}
                                    className="button button-outline button-small button-100-mobile">
                                    Cambiar a Retiro
                                </Button>
                            </div>
                        </Hidden>
                    </div>
                </div>
            </div>
                    </motion.div>}
                    </div>
              </div>
            </div>
        </main>
        </div>
        </div>
        <ToastContainer />
        </>)
}

export default SolicitudAgendamiento;
