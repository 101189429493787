import React from 'react'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';

import {
  makeStyles,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  DialogTitle
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  iconSuccess: {
    width: "80px",
    height: "80px",
    border: "solid 3px #6FCF97",
    color: "#6FCF97",
    margin: "0 auto",
    borderRadius: "100%",
    fontsize: "400px",

    "& .MuiSvgIcon-root": {
      width: "48px",
      height: "48px",
    }
  },
  iconDanger: {
    width: "80px",
    height: "80px",
    border: "solid 3px #EA5259",
    color: "#EA5259",
    margin: "0 auto",
    borderRadius: "100%",
    fontsize: "400px",

    "& .MuiSvgIcon-root": {
      width: "48px",
      height: "48px",
    }
  },
  alertDanger: {
    color: "#EA5259",
    margin: "0 auto",

    "& .MuiSvgIcon-root": {
      width: "64px",
      height: "64px",
    }
  },
  alertWarning: {
    color: "#F8C96A",
    margin: "0 auto",

    "& .MuiSvgIcon-root": {
      width: "64px",
      height: "64px",
    }
  },
  dialog: {
    "& .MuiDialogActions-root": {
      justifyContent: "center"
    }
  }
}));

const Message = ({ open, tituloMensaje, mensaje, icon, onClose }) => {
  const classes = useStyles();

  return (
    // <Alert color={props.bgColor}>{props.msg}</Alert>
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={onClose}
      className={classes.dialog}
    >
      <DialogContent className="mt-3">
        <DialogContentText className="text-center mb-0">
          {icon == "iconSuccess" ?
            <div className={`justify-center ${classes.iconSuccess}`}>
              <CheckIcon />
            </div> : ""
          }
          {icon == "iconDanger" ?
            <div className={`justify-center ${classes.iconDanger}`}>
              <CloseIcon />
            </div> : ""
          }

          {icon == "alertWarning" ?
            <div className={`justify-center ${classes.alertWarning}`}>
              <ReportProblemIcon />
            </div> : ""
          }
          {icon == "alertDanger" ?
            <div className={`justify-center ${classes.alertDanger}`}>
              <ReportProblemIcon />
            </div> : ""
          }
        </DialogContentText>
      </DialogContent>

      <DialogContent className="text-center mb-3">
        <DialogTitle className='pt-0'>{tituloMensaje}</DialogTitle>

        {/* <DialogContentText>{tituloMensaje}</DialogContentText> */}
        <DialogContentText className="px-0 px-md-5">{mensaje}</DialogContentText>
        <DialogContentText className="px-0 px-md-5">¿Deseas continuar?</DialogContentText>

        {/* <DialogContentText className="px-0 px-md-5">¡Muchas Gracias por tu confianza¡, Equipo de WS cargo.</DialogContentText> */}

      </DialogContent>

      <DialogActions className="mb-3">
        <Button
          className="button button-gray button-small mr-1"
          onClick={onClose}
        >
          No
        </Button>
        <Button
          className="button button-danger button-small ml-1"
          onClick={() => {
            // handlerReagendar();
            window.open("https://docs.google.com/forms/d/e/1FAIpQLSf-zqOag5zU5PPxNc5FLR150YFBoEfdJm3MTVtunxgidT7bgQ/viewform?usp=sf_link", "_blank")
            onClose();
          }}
        >
          si
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default Message