import React, { useEffect, useState } from "react";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import URLSearchParams from "url-search-params";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import { Redirect, Route, Switch } from "react-router-dom";
//import { BrowserRouter as useNavigate } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import asyncComponent from '../util/asyncComponent';
import { useDispatch, useSelector } from "react-redux";
import { IntlProvider } from "react-intl";
import "../assets/vendors/style";
import indigoTheme from "./themes/indigoTheme";
import cyanTheme from "./themes/cyanTheme";
import orangeTheme from "./themes/orangeTheme";
import amberTheme from "./themes/amberTheme";
import pinkTheme from "./themes/pinkTheme";
import blueTheme from "./themes/blueTheme";
import purpleTheme from "./themes/purpleTheme";
import greenTheme from "./themes/greenTheme";
import AppLocale from "../lngProvider";
import NotFound from "./NotFound";
import {
    AMBER,
    BLUE,
    CYAN,
    DARK_AMBER,
    DARK_BLUE,
    DARK_CYAN,
    DARK_DEEP_ORANGE,
    DARK_DEEP_PURPLE,
    DARK_GREEN,
    DARK_INDIGO,
    DARK_PINK,
    DEEP_ORANGE,
    DEEP_PURPLE,
    GREEN,
    INDIGO,
    PINK
} from "../constants/ThemeColors";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import CotizadorCliente from "../modules/cotizador/index";
import UploadDocsExpress from "../modules/uploadDocsExpress/index";
import ConsolidadoRapido from "../modules/ConsolidadoRapido";
import CondicionesRecepcion from "../modules/CondicionesRecepcion";
import CustomerSurvey from "../modules/customer_survey/index";
import RecoverPassword from "./RecoverPassword";
import RestorePassword from "./RestorePassword";
import ActivarCuenta from "./ActivarCuenta";
import Track from "./Track";
import DetallePagoOnline from "modules/detalle_pago_online/index.js";
import DetallePagoOnlineError from "modules/detalle-pago-error-proceso/index.js"
import Cnsldmodifylist from "./CnsldList";
import SolicitudAgendamiento from "../modules/solicitudAgendamiento/index";
import { setInitUrl } from "../actions/Auth";
import RTL from "../util/RTL";
import { setDarkTheme, setThemeColor } from "../actions/Setting";
import AppLayout from "./AppLayout";

const RestrictedRoute = ({ component: Component, authUser, ...rest }) =>
    <Route
        {...rest}
        render={props =>
            authUser
                ? <Component {...props} />
                : <Redirect
                    to={{
                        pathname: '/signin',
                        state: { from: props.location }
                    }}
                />}
    />;

const App = (props) => {
    const dispatch = useDispatch();
    const { themeColor, darkTheme, locale, isDirectionRTL } = useSelector(({ settings }) => settings);
    const { authUser, initURL } = useSelector(({ auth }) => auth);
    const isDarkTheme = darkTheme;
    const { match, location } = props;

    useEffect(() => {
        window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
        if (initURL === '') {
            dispatch(setInitUrl(props.history.location.pathname));
        }
        const params = new URLSearchParams(props.location.search);
        if (params.has("theme-name")) {
            dispatch(setThemeColor(params.get('theme-name')));
        }
        if (params.has("dark-theme")) {
            dispatch(setDarkTheme());
        }
    }, [dispatch, initURL, props.history.location.pathname, props.location.search]);


    const getColorTheme = (themeColor, applyTheme) => {
        switch (themeColor) {
            case INDIGO: {
                applyTheme = createMuiTheme(indigoTheme);
                break;
            }
            case CYAN: {
                applyTheme = createMuiTheme(cyanTheme);
                break;
            }
            case AMBER: {
                applyTheme = createMuiTheme(amberTheme);
                break;
            }
            case DEEP_ORANGE: {
                applyTheme = createMuiTheme(orangeTheme);
                break;
            }
            case PINK: {
                applyTheme = createMuiTheme(pinkTheme);
                break;
            }
            case BLUE: {
                applyTheme = createMuiTheme(blueTheme);
                break;
            }
            case DEEP_PURPLE: {
                applyTheme = createMuiTheme(purpleTheme);
                break;
            }
            case GREEN: {
                applyTheme = createMuiTheme(greenTheme);
                break;
            }
            case DARK_INDIGO: {
                applyTheme = createMuiTheme({ ...indigoTheme, direction: 'rtl' });
                break;
            }
            case DARK_CYAN: {
                applyTheme = createMuiTheme(cyanTheme);
                break;
            }
            case DARK_AMBER: {
                applyTheme = createMuiTheme(amberTheme);
                break;
            }
            case DARK_DEEP_ORANGE: {
                applyTheme = createMuiTheme(orangeTheme);
                break;
            }
            case DARK_PINK: {
                applyTheme = createMuiTheme(pinkTheme);
                break;
            }
            case DARK_BLUE: {
                applyTheme = createMuiTheme(blueTheme);
                break;
            }
            case DARK_DEEP_PURPLE: {
                applyTheme = createMuiTheme(purpleTheme);
                break;
            }
            case DARK_GREEN: {
                applyTheme = createMuiTheme(greenTheme);
                break;
            }
            default:
                createMuiTheme(indigoTheme);
        }
        return applyTheme;
    };

    let applyTheme = createMuiTheme(indigoTheme);
    if (isDarkTheme) {
        document.body.classList.add('dark-theme');
        applyTheme = createMuiTheme(darkTheme)
    } else {
        applyTheme = getColorTheme(themeColor, applyTheme);
    }
    if (location.pathname === '/') {
        if (authUser === null) {
            return (<Redirect to={'/signin'} />);
        } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
            return (<Redirect to={'/app/inicio'} />);
        } else {
            return (<Redirect to={initURL} />);
        }
    }
    if (isDirectionRTL) {
        applyTheme.direction = 'rtl';
        document.body.classList.add('rtl')
    } else {
        document.body.classList.remove('rtl');
        applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];

    return (
        <ThemeProvider theme={applyTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}>
                    <RTL>
                        <div className="app-main">
                            <Switch>
                                <RestrictedRoute path={`${match.url}app`} authUser={authUser} component={AppLayout} />
                                <Route path='/signin' component={SignIn} />
                                <Route path='/signup' component={SignUp} />
                                <Route path='/cotizador' component={CotizadorCliente} />
                                <Route path='/upload_documents/:hash' component={UploadDocsExpress} />
                                <Route path='/consolidado-rapido/:query/:hash' component={ConsolidadoRapido} />
                                <Route path='/condiciones_recepcion/:hash' component={CondicionesRecepcion} />
                                <Route path='/customer_survey/:id' component={CustomerSurvey} />
                                <Route path='/customer_survey' component={CustomerSurvey} />
                                <Route path='/recover-password' component={RecoverPassword} />
                                <Route path='/restore-password' component={RestorePassword} />
                                <Route path='/registro-cliente/activar/:id' render={(props) => <ActivarCuenta {...props} />} />
                                <Route path='/track/:id' render={(props) => <Track {...props} />}  />
                                <Route path='/track_pago/:id' render={(props) => <Track {...props} />}  />
                                <Route path='/detalle-pago-online/:id' render={(props) => <DetallePagoOnline {...props} />}  />
                                <Route path='/detalle-pago-error-proceso/:id' render={(props) => <DetallePagoOnlineError {...props} />}  />
                                <Route path='/cnsldmodifylist/:id' render={(props) => <Cnsldmodifylist {...props} />}  />
                                <Route path='/solicitud_agendamiento/:hash' component={SolicitudAgendamiento} />
                                {/*<Route*/}
                                <Route path='*' exact={true} render={(props) => <NotFound />}  />
                            </Switch>
                        </div>
                    </RTL>
                </IntlProvider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};


export default App;
